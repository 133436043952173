import React, { useContext } from 'react';
import { useLocalStore } from 'mobx-react';
import Config from '../config';
import ServiceStore from './ServiceStore';
import SpaceStore from './SpaceStore';
import FriendStore from './FriendStore';

export const StoreContext = React.createContext(null);

export const StoreProvider = ({ children, config, store }) => {
  const value = useLocalStore(() => ({
    serviceStore: store?.serviceStore || ServiceStore,
    spaceStore: store?.spaceStore || SpaceStore,
    friendStore: store?.friendStore || FriendStore,
  }));

  Config.setConfig(config);

  return (
    <StoreContext.Provider value={value}>{children}</StoreContext.Provider>
  );
};

export const useStores = () => {
  const store = useContext(StoreContext);

  if (!store) {
    throw new Error('useStores must be used within a StoreProvider');
  }
  return store;
};

export const withStore = Component => props => {
  return <Component {...props} store={useStores()} />;
};

export { SpaceStore };

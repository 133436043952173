import React from 'react';
import styled, { css } from 'styled-components';
import { isMobile } from 'react-device-detect';
import { useTranslation } from 'react-i18next';
import feeTitleImg from '../../assets/landing/fee/main_2.png';
import groupImg from '../../assets/landing/fee/group.svg';
import talkImg from '../../assets/landing/fee/talk.svg';
import driveImg from '../../assets/landing/fee/drive.svg';
import noteImg from '../../assets/landing/fee/note.svg';
import calendarImg from '../../assets/landing/fee/calendar.svg';
import meetingImg from '../../assets/landing/fee/meeting.svg';
import adminImg from '../../assets/landing/fee/admin.svg';
import worksImg from '../../assets/landing/fee/works.svg';
import feeTitleImg_En from '../../assets/landingEn/fee/main_2_En.png';
import mobilefeeTitleImg from '../../assets/landing/mobile/fee/title.png';
import mobilefeeTitleImg_En from '../../assets/landingEn/mobile/fee/title.png';

const ServiceFeeContent = () => {
  const { t, i18n } = useTranslation('service');
  const itemList = [
    {
      image: groupImg,
      title: t(`SP_HOME_11`),
      txt: t(`SP_HOME_12`),
    },
    {
      image: talkImg,
      title: t(`SP_SP_INTRO_TAP_07`),
      txt: t(`SP_HOME_13`),
    },
    {
      image: driveImg,
      title: t(`SP_SP_INTRO_TAP_08`),
      txt: t(`SP_HOME_14`),
    },
    {
      image: noteImg,
      title: t(`SP_SP_INTRO_TAP_11`),
      txt: t(`SP_HOME_15`),
    },
    {
      image: calendarImg,
      title: t(`SP_SP_INTRO_TAP_10`),
      txt: t(`SP_HOME_16`),
    },
    {
      image: meetingImg,
      title: t(`SP_SP_INTRO_TAP_09`),
      txt: t(`SP_HOME_17`),
    },
    {
      image: adminImg,
      title: t(`SP_SP_INTRO_TAP_13`),
      txt: t(`SP_HOME_18`),
    },
    {
      image: worksImg,
      title: t(`SP_SP_INTRO_TAP_14`),
      txt: t(`SP_HOME_19`),
    },
  ];

  return (
    <ServiceFeeWrapper ismobile={isMobile}>
      <ServiceFeeTitle
        src={
          i18n.language === 'en'
            ? isMobile
              ? mobilefeeTitleImg_En
              : feeTitleImg_En
            : isMobile
            ? mobilefeeTitleImg
            : feeTitleImg
        }
        data-aos="fade-up"
        data-aos-duration="750"
        data-aos-offset="40"
        data-aos-easing="ease"
        isen={i18n.language}
      />
      <ServiceFeeMain isen={i18n.language}>
        {itemList.map(item => (
          <ServiceFeeItem {...item} key={item.title} />
        ))}
      </ServiceFeeMain>
      <ServiceFeeSub>{t(`SP_HOME_09`)}</ServiceFeeSub>
      <ServiceFeeSecondSub>{t(`SP_HOME_10`)}</ServiceFeeSecondSub>
    </ServiceFeeWrapper>
  );
};

export default ServiceFeeContent;

const ServiceFeeItem = ({ image, title, txt }) => {
  return (
    <ServiceFeeItemWrapper ismobile={isMobile}>
      <FeeItemImage src={image} ismobile={isMobile} />
      <FeeItemTitle ismobile={isMobile}>{title}</FeeItemTitle>
      <FeeItemText ismobile={isMobile}>{txt}</FeeItemText>
    </ServiceFeeItemWrapper>
  );
};

const ServiceFeeWrapper = styled.div`
  height: 23.75rem;
  display: flex;
  flex-direction: column;
  align-items: center;
  background-color: #faf8f7;
  ${isMobile &&
  css`
    height: 45.45rem;
    padding: 2.35rem;
  `}
`;

const ServiceFeeTitle = styled.img`
  margin-top: 3.75rem;
  ${props =>
    isMobile
      ? css`
          width: 17.81rem;
          height: 3.69rem;
          margin-bottom: 1.24rem;
          margin-top: 0.32rem;
        `
      : props.isen === 'en'
      ? css`
          width: 62.215rem;
          height: 2.375rem;
        `
      : css`
          width: 45.313rem;
          height: 2.375rem;
        `};
`;

const ServiceFeeMain = styled.div`
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  flex-direction: row;

  ${props =>
    isMobile
      ? props.isen === 'en'
        ? css`
            width: 18rem;
          `
        : css`
            width: 16rem;
          `
      : props.isen === 'en'
      ? css`
          width: 79rem;
          flex-wrap: nowrap;
        `
      : css`
          width: 70rem;
          flex-wrap: nowrap;
        `}
`;

const ServiceFeeItemWrapper = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
  height: 7.947rem;
  margin-top: 2.813rem;
  ${isMobile &&
  css`
    width: 5rem;
    height: 6.75rem;
    margin: 0.7rem 1.35rem;
  `}
`;

const ServiceFeeSub = styled.div`
  font-size: 0.813rem;
  color: #8a8a8a;
  text-align: center;
  ${isMobile
    ? css`
        margin-top: 1.38rem;
        font-size: 0.63rem;
        width: 19rem;
      `
    : css`
        margin-top: 2.25rem;
      `}
`;

const ServiceFeeSecondSub = styled.div`
  font-size: 0.813rem;
  color: #8a8a8a;
  text-align: center;
  ${isMobile &&
  css`
    font-size: 0.63rem;
    width: 19rem;
  `}
`;

const FeeItemImage = styled.img`
  width: 1.875rem;
  height: 1.875rem;
  ${isMobile &&
  css`
    width: 1.88rem;
    height: 1.88rem;
  `}
`;

const FeeItemTitle = styled.div`
  color: #3d3d3d;
  font-size: 1.25rem;
  font-weight: 500;
  ${isMobile &&
  css`
    color: #80695a;
    font-size: 1rem;
  `}
`;

const FeeItemText = styled.pre`
  color: #3d3d3d;
  text-align: center;
  font-family: NotoSansCJKkr-DemiLight;
  margin: 0;
  font-weight: 300;
  ${isMobile
    ? css`
        font-size: 0.8rem;
      `
    : css`
        font-size: 0.938rem;
        line-height: normal;
      `}
`;

import React, { useEffect, useRef, useState, useCallback } from 'react';
import { Link } from 'react-router-dom';
import styled, { css } from 'styled-components';
import { useTranslation } from 'react-i18next';
import { Radio } from 'antd';
import { useObserver } from 'mobx-react';
import { useCoreStores, WaplAuthStore } from 'teespace-core';
import AccountPasswordForm from './AccountPasswordForm';
import AccountNameForm from './AccountNameForm';
import AccountItem from './AccountItem';

function AccountContent() {
  const { waplAuthStore, waplUserStore } = useCoreStores();
  const query = new URLSearchParams(window.location.search);
  const [open, setOpen] = useState(query.get('open'));
  const { t } = useTranslation('common');
  const accountRef = useRef(null);
  const nameRef = useRef(null);
  const passwordRef = useRef(null);

  useEffect(() => {
    if (open === 'password') {
      passwordRef.current.scrollIntoView();
    } else if (open === 'name') {
      nameRef.current.scrollIntoView();
    }
  }, [open]);
  const getPopupContainer = useCallback(
    triggerNode => triggerNode.parentNode.parentNode,
    [],
  );
  const handleOpenName = useCallback(value => {
    if (value) {
      setOpen('name');
    } else {
      setOpen('');
    }
  }, []);

  const handleOpenPassword = useCallback(value => {
    if (value) {
      setOpen('password');
    } else {
      setOpen('');
    }
  }, []);

  const handleChangeAdvertise = useCallback(
    e => {
      waplAuthStore.updateTerms({
        isAdvertiseAgree: e.target.value,
        isOptionalTermsAgree: waplAuthStore.sessionInfo.isOptionalTermsAgree,
      });
    },
    [waplAuthStore],
  );

  return useObserver(() => (
    <>
      <SpaceTitle>{t('CM_EDIT_MYPAGE_01')}</SpaceTitle>
      <Content>
        <SpaceHeader />
        <UserInfo>
          <ProfileInfo>
            <ThumbImage src={waplUserStore.myProfile.defaultPhotoUrl} />
            <ProfileTextInfo>
              <ProfileTitle>{waplUserStore.myProfile.displayName}</ProfileTitle>
              <ProfileSubtitle>
                {waplUserStore.myProfile.loginId}
              </ProfileSubtitle>
            </ProfileTextInfo>
          </ProfileInfo>
        </UserInfo>
        <AccountWrapper open={!!open} ref={accountRef}>
          <AccountItem
            title={t('CM_EDIT_MYPAGE_02')}
            value={waplUserStore.myProfile.displayName}
            ref={nameRef}
            component={AccountNameForm}
            open={open === 'name'}
            onChangeOpen={handleOpenName}
            getPopupContainer={getPopupContainer}
            changable
          />
          {/* <AccountItem
          title="휴대폰 번호"
          value={convertPhone(waplUserStore.myProfile.phone)}
          component={AccountPhoneForm}
          changable
        /> */}
          <AccountItem
            title={t('CM_PWD')}
            value={t('CM_EDIT_MYPAGE_03', {
              value: waplUserStore.myProfile.pwModiDate || '-',
            })}
            ref={passwordRef}
            component={AccountPasswordForm}
            open={open === 'password'}
            onChangeOpen={handleOpenPassword}
            getPopupContainer={getPopupContainer}
            changable
          />
          <AccountItem
            title={t('CM_EDIT_MYPAGE_04')}
            subtitle={
              <>
                {t('CM_EDIT_MYPAGE_05')}{' '}
                <AccountWithdrawLink to="/withdrawal/select-type">
                  {t('CM_EDIT_MYPAGE_06')}
                </AccountWithdrawLink>
              </>
            }
            getPopupContainer={getPopupContainer}
            value={null}
            changable={false}
          />
          <AccountItem title={t('CM_ADVERTISE_AGREE_POLICY')} value={null}>
            <StyledRadioGroup
              onChange={handleChangeAdvertise}
              value={WaplAuthStore.sessionInfo.isAdvertiseAgree}
            >
              <Radio value="y"> {t('CM_AGREE_BUTTON_01')}</Radio>
              <Radio value="n"> {t('CM_AGREE_BUTTON_02')}</Radio>
            </StyledRadioGroup>
          </AccountItem>
        </AccountWrapper>
        <GoToSpaceListLink to="/spaces" shortmargin="true">
          {t('CM_GO_SPACES')}
        </GoToSpaceListLink>
      </Content>
    </>
  ));
}

export default AccountContent;

const Content = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  width: 35rem;
  padding-bottom: 1.6em;
`;

const handleAccountWrapperOpenStyle = isOpen => {
  if (isOpen) {
    return css`
      max-height: 26.75rem;
      overflow: auto;
    `;
  }
  return css``;
};
const AccountWrapper = styled.div`
  display: flex;
  align-items: center;
  flex-direction: column;
  width: 100%;
  ${({ open }) => handleAccountWrapperOpenStyle(open)}
`;

const UserInfo = styled.div`
  flex-direction: row;
  align-items: center;
`;

const ProfileInfo = styled.div`
  flex: 1;
  display: flex;
  flex-direction: row;
  margin-bottom: 1.25rem;
`;

const ThumbImage = styled.img`
  border-radius: 50%;
  width: 3.75rem;
  height: 3.75rem;
  border: 1px solid rgba(0, 0, 0, 0.05);
`;

const ProfileTextInfo = styled.div`
  flex-direction: column;
  margin-left: 1.25rem;
`;

const ProfileTitle = styled.h1`
  font-size: 1.25rem;
  font-weight: 500;
  line-height: 1.8125rem;
  color: ${props => props.theme.TextMain};
`;

const ProfileSubtitle = styled.h2`
  margin: 0;
  font-weight: 400;
  font-size: 1rem;
  line-height: 1.5rem;
  color: ${props => props.theme.TextSub};
  margin-top: 0.0625rem;
`;

const SpaceHeader = styled.div`
  align-items: center;
  flex: 1;
  width: 100%;
  display: flex;
  flex-direction: row;
`;

const AccountWithdrawLink = styled(Link)`
  font-size: 1rem;
  line-height: 1.5rem;
  font-weight: 500;
  color: ${props => props.theme.MyHomeTextLink};
  text-decoration: underline;
  margin-top: 0.625rem;
  &:hover,
  &:visited {
    color: ${props => props.theme.MyHomeTextLink};
  }
`;
const GoToSpaceListLink = styled(Link)`
  font-size: 0.8125rem;
  line-height: 1.1875rem;
  color: ${props => props.theme.MyHomeTextLink};
  text-decoration: underline;
  margin-top: ${({ shortmargin }) => (shortmargin ? '1.875rem' : '6.125rem')};
  &:hover,
  &:visited {
    color: ${props => props.theme.MyHomeTextLink};
  }
`;

const SpaceTitle = styled.h1`
  font-weight: 500;
  line-height: 2.5625rem;
  display: inline-block;
  color: ${props => props.theme.TextMain};
  width: 35rem;
  font-size: 1.75rem;
  margin: 4.125rem 0 2.5rem 0;
  text-align: center;
`;

const StyledRadioGroup = styled(Radio.Group)`
  margin-top: 0.25rem;
  line-height: 1.5rem;
  span {
    line-height: 1.5rem;
    font-size: 1rem;
    font-weight: 500;
    color: ${props => props.theme.TextMain};
  }
`;

import React, { useEffect } from 'react';
import styled, { css } from 'styled-components';
import { isMobile } from 'react-device-detect';
import { Swiper, SwiperSlide } from 'swiper/react';
import SwiperCore, { Autoplay } from 'swiper';
import { useTranslation } from 'react-i18next';
import downLoad01 from '../../assets/landing/download/1.png';
import downLoad02 from '../../assets/landing/download/2.png';
import downLoad03 from '../../assets/landing/download/PC_WAPL_MOCKUP_DOWNLOAD.png';
import downLoad04 from '../../assets/landing/download/4.png';
import downLoad05 from '../../assets/landing/download/5.png';
import downloadTitle from '../../assets/landing/download/main.png';
import appStore from '../../assets/landing/download/appstore.png';
import googlePlay from '../../assets/landing/download/googleplay.png';
import appStoreQR from '../../assets/landing/download/IOS.png';
import googlePlayQR from '../../assets/landing/download/AOS.png';
import main_En from '../../assets/landingEn/download/main_En.png';
import autoImg1_En from '../../assets/landingEn/download/1_En.png';
import autoImg2_En from '../../assets/landingEn/download/2_En.png';
import autoImg3_En from '../../assets/landingEn/download/PC_EN_WAPL_MOCKUP_DOWNLOAD.png';
import autoImg4_En from '../../assets/landingEn/download/4_En.png';
import autoImg5_En from '../../assets/landingEn/download/5_En.png';
import mobileTitle from '../../assets/landing/mobile/download/txt.png';
import mobileTitle_En from '../../assets/landingEn/mobile/download/main.png';

SwiperCore.use([Autoplay]);

const ServiceDownloadContent = props => {
  const { refs, startDownload } = props;
  const { i18n } = useTranslation(['common', 'service']);

  useEffect(() => {
    if (startDownload) {
      window.scrollTo({
        top:
          refs.downloadRef.current.offsetTop -
          refs.headerRef.current.clientHeight,
        left: 0,
        behavior: 'smooth',
      });
    }
  }, [startDownload, refs.downloadRef, refs.headerRef]);

  const goMobileStart = () => {
    window.location.href = `https://waplai.page.link/main`;
  };

  const DownloadSlides = [];

  const setDownLoadImage = () => {
    DownloadSlides.push(
      <SwiperSlide key="slide0" tag="li">
        <ServiceDownloadImageWrapper
          src={i18n.language === 'en' ? autoImg1_En : downLoad01}
        />
      </SwiperSlide>,
      <SwiperSlide key="slide1" tag="li">
        <ServiceDownloadImageWrapper
          src={i18n.language === 'en' ? autoImg2_En : downLoad02}
        />
      </SwiperSlide>,
      <SwiperSlide key="slide2" tag="li">
        <ServiceDownloadImageWrapper
          src={i18n.language === 'en' ? autoImg3_En : downLoad03}
        />
      </SwiperSlide>,
      <SwiperSlide key="slide3" tag="li">
        <ServiceDownloadImageWrapper
          src={i18n.language === 'en' ? autoImg4_En : downLoad04}
        />
      </SwiperSlide>,
      <SwiperSlide key="slide4" tag="li">
        <ServiceDownloadImageWrapper
          src={i18n.language === 'en' ? autoImg5_En : downLoad05}
        />
      </SwiperSlide>,
    );
  };

  setDownLoadImage();

  return (
    <>
      <ServiceDownloadyHeadWrapper
        id="mobileDownloadHeader"
        ref={refs.downloadRef}
      >
        <>
          <ServiceDownloadImage
            src={
              i18n.language === 'en'
                ? isMobile
                  ? mobileTitle_En
                  : main_En
                : isMobile
                ? mobileTitle
                : downloadTitle
            }
            data-aos="fade-up"
            data-aos-offset="40"
            data-aos-duration="750"
            data-aos-easing="ease"
            isen={i18n.language === 'en'}
          />
          <QRcodeWrapper>
            <QRImg
              id="appStore"
              src={appStoreQR}
              data-aos="fade-up"
              data-aos-offset="40"
              data-aos-duration="750"
              data-aos-easing="ease"
            />
            <QRImg
              id="googlePlay"
              src={googlePlayQR}
              data-aos="fade-up"
              data-aos-offset="40"
              data-aos-duration="750"
              data-aos-easing="ease"
            />
          </QRcodeWrapper>
          <StoreButtonWrapper id="webDownloadButtons">
            <AppStoreButton
              src={appStore}
              onClick={isMobile ? goMobileStart : undefined}
              data-aos="fade-up"
              data-aos-offset="40"
              data-aos-duration="750"
              data-aos-easing="ease"
            />
            <GooglePlayButton
              src={googlePlay}
              onClick={isMobile ? goMobileStart : undefined}
              data-aos="fade-up"
              data-aos-offset="40"
              data-aos-duration="750"
              data-aos-easing="ease"
            />
          </StoreButtonWrapper>
        </>
      </ServiceDownloadyHeadWrapper>
      <ServiceDownloadyMainWrapper>
        <SwiperFrame
          id="downloadSlides"
          loop
          centeredSlides
          autoplay
          slidesPerView="auto"
          spaceBetween={isMobile ? 12 : 48}
        >
          {DownloadSlides}
        </SwiperFrame>
      </ServiceDownloadyMainWrapper>
    </>
  );
};

export default ServiceDownloadContent;

const ServiceDownloadyHeadWrapper = styled.div`
  height: 27.188rem;
  display: flex;
  align-items: center;
  flex-direction: column;
`;

const ServiceDownloadImage = styled.img`
  ${isMobile
    ? css`
        margin-top: 4.63rem;
        width: 18.75rem;
        height: 7.94rem;
      `
    : css`
        margin-top: 5rem;
        width: 32.313rem;
        height: 9.063rem;
      `};
  ${props =>
    props.isen &&
    !isMobile &&
    css`
      width: 39.313rem;
    `}
`;

const StoreButtonWrapper = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  ${isMobile
    ? css`
        margin-top: 1rem;
        width: 21.35rem;
      `
    : css`
        margin-top: 1.875rem;
        width: 30rem;
      `}
`;

const AppStoreButton = styled.img`
  ${isMobile
    ? css`
        width: 10.23rem;
        height: 2.4rem;
        margin-right: 1rem;
      `
    : css`
        width: 14.375rem;
        height: 3.5rem;
      `}
`;

const GooglePlayButton = styled.img`
  ${isMobile
    ? css`
        width: 10.23rem;
        height: 2.4rem;
      `
    : css`
        width: 14.375rem;
        height: 3.5rem;
      `}
`;

const ServiceDownloadyMainWrapper = styled.div`
  height: auto;
  width: 100%;
  display: flex;
  overflow-x: hidden;
  justify-content: center;
  align-items: start;
  margin-top: 3.75rem;
  padding-bottom: 5rem;
  ${!isMobile &&
  css`
    padding-right: 20rem;
    padding-left: 20rem;
  `}
`;

const SwiperFrame = styled(Swiper)`
  &.swiper-container {
    position: relative;
    width: auto;
    margin-left: auto;
    margin-right: auto;
    list-style: none;
    padding: 0;
    z-index: 1;
    overflow: hidden;
    max-height: 100%;
  }

  &.swiper-wrapper {
    padding-inline-start: 0;
    max-height: 100%;
    height: 100%;
    display: flex;
    bottom: -25px;
  }

  .swiper-slide {
    align-items: center;
    width: auto !important;
    display: flex;
    flex-direction: column;
    justify-content: center;
    opacity: 0.5;
    color: #ebe6df;
    flex-shrink: 0;
    max-height: 100%;
  }

  .swiper-slide-active {
    opacity: 1;
    border: 3px solid black;
    border-radius: 1rem;
    box-shadow: 0 2px 80px 0 rgba(0, 0, 0, 0.1);
  }
`;

const QRcodeWrapper = styled.div`
  display: flex;
  justify-content: space-between;
  ${isMobile
    ? css`
        margin-top: 1rem;
        width: 14.75rem;
      `
    : css`
        margin-top: 1.25rem;
        width: 22.125rem;
      `}
`;

const QRImg = styled.img`
  ${isMobile
    ? css`
        width: 3.75rem;
        height: 3.75rem;
      `
    : css`
        width: 6.5rem;
        height: 6.5rem;
      `}
`;

const ServiceDownloadImageWrapper = styled.img`
  width: 13.563rem;
  height: 29.25rem;
  border: 3px solid;
  border-radius: 1rem;
`;

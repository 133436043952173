import React, { useEffect, useState, useCallback } from 'react';
import { useCoreStores } from 'teespace-core';
import { useTranslation } from 'react-i18next';
import moment from 'moment';
import styled from 'styled-components';
import { getQuotes } from '../../libs/quotes';

export default function () {
  const { waplUserStore } = useCoreStores();
  const { t } = useTranslation('common');
  const [dayInt, setDayInt] = useState(parseInt(moment().format('D'), 10));
  const getIntroState = useCallback(() => {
    const hour = parseInt(moment().format('HH'), 10);
    if (hour >= 6 && hour < 12) {
      return 'morning';
    }
    if (hour >= 12 && hour < 18) {
      return 'afternoon';
    }
    if (hour >= 18 && hour < 22) {
      return 'evening';
    }
    return 'night';
  }, []);

  const getIntroQuotes = useCallback(() => {
    const month = parseInt(moment().format('M'), 10);
    const day = parseInt(moment().format('D'), 10);
    return getQuotes({ month, day });
  }, []);
  const [introState, setIntroState] = useState(getIntroState());

  const getIntroMessage = useCallback(() => {
    switch (introState) {
      case 'morning':
        return t('CM_MYHOME_04', {
          value: waplUserStore.myProfile?.displayName,
        });
      case 'afternoon':
        return t('CM_MYHOME_05', {
          value: waplUserStore.myProfile?.displayName,
        });
      case 'evening':
        return t('CM_MYHOME_06', {
          value: waplUserStore.myProfile?.displayName,
        });
      case 'night':
        return t('CM_MYHOME_07', {
          value: waplUserStore.myProfile?.displayName,
        });
      default:
        return '';
    }
  }, [introState, t, waplUserStore.myProfile.displayName]);

  useEffect(() => {
    const introInterval = setInterval(() => {
      if (introState !== getIntroState()) {
        setIntroState(getIntroState());
      }
      if (dayInt !== parseInt(moment().format('D'), 10)) {
        setDayInt(moment().format('D'), 10);
      }
    }, [60000]);
    return () => {
      clearInterval(introInterval);
    };
  }, [dayInt, getIntroState, introState]);

  return (
    <UserInfo>
      <ProfileTextInfo>
        <ProfileTitle>{getIntroMessage()}</ProfileTitle>
        <ProfileSubtitle>{getIntroQuotes()}</ProfileSubtitle>
      </ProfileTextInfo>
    </UserInfo>
  );
}

const UserInfo = styled.div`
  flex: 1;
  display: flex;
  flex-direction: row;
  align-items: center;
`;

const ProfileTextInfo = styled.div`
  flex-direction: column;
  text-align: center;
`;

const ProfileTitle = styled.h1`
  font-size: 1.25rem;
  line-height: 1.8125rem;
  margin: 0;
  color: ${props => props.theme.TextMain};
`;

const ProfileSubtitle = styled.h2`
  font-size: 1rem;
  line-height: 1.5rem;
  margin: 0;
  color: ${props => props.theme.TextSub};
  font-weight: 400;
  margin-top: 0.25rem;
`;

// @flow
import ComAPI from '../../libs/ComAPI';
import {
  convertFromDtoItemToPlatformItem,
  convertFromPlatformItemToDtoItem,
} from '../models/FriendModel';

export const convertFromDtoObjToPlatformObj = (
  dtoObj: $Shape<FriendInfoDto>,
): $Shape<FriendInfo> => {
  const result: $Shape<FriendInfo> = {};

  Object.keys(dtoObj).forEach((key: string) => {
    if (
      {}.hasOwnProperty.call(dtoObj, key) &&
      typeof dtoObj[key] !== 'undefined'
    ) {
      Object.assign(
        result,
        convertFromDtoItemToPlatformItem({ [key]: dtoObj[key] }),
      );
    }
  });
  return result;
};

export const convertFromPlatformObjToDtoObj = (
  dtoObj: $Shape<FriendInfo>,
): $Shape<FriendInfoDto> => {
  const result: $Shape<FriendInfoDto> = {};

  Object.keys(dtoObj).forEach((key: string) => {
    if (
      {}.hasOwnProperty.call(dtoObj, key) &&
      typeof dtoObj[key] !== 'undefined'
    ) {
      Object.assign(
        result,
        convertFromPlatformItemToDtoItem({ [key]: dtoObj[key] }),
      );
    }
  });
  return result;
};

class FriendRepository {
  // eslint-disable-next-line class-methods-use-this
  async listFriends({
    userId,
  }: {
    userId: string,
  }): Promise<Array<$Shape<FriendInfo>>> {
    try {
      const response = await ComAPI.get(
        `common-api/users/${userId}/domainsfriends`,
      );
      return (response.data.dto.userList || []).map(userDto =>
        convertFromDtoObjToPlatformObj(userDto),
      );
    } catch (error) {
      throw Error(JSON.stringify(error.response.data.exception));
    }
  }
}

export default new FriendRepository();

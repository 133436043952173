import React, { useEffect, useRef, useState, useCallback } from 'react';
import styled, { css } from 'styled-components';
import { Link } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { useCoreStores, WaplAuthStore } from 'teespace-core';
import { useObserver } from 'mobx-react';
import { Radio } from 'antd';
import SettingModalContent from './SettingModalContent';
import AccountItemModal from './AccountItemModal';
import AccountPasswordFormModal from './AccountPasswordFormModal';
import AccountNameFormModal from './AccountNameFormModal';

const AccountModalContent = () => {
  const { waplAuthStore, waplUserStore } = useCoreStores();
  const query = new URLSearchParams(window.location.search);
  const [open, setOpen] = useState(query.get('open'));
  const { t } = useTranslation('common');
  const accountRef = useRef(null);
  const nameRef = useRef(null);
  const passwordRef = useRef(null);

  useEffect(() => {
    if (open === 'password') {
      passwordRef.current.scrollIntoView();
    } else if (open === 'name') {
      nameRef.current.scrollIntoView();
    }
  }, [open]);
  const getPopupContainer = useCallback(
    triggerNode => triggerNode.parentNode.parentNode,
    [],
  );
  const handleOpenName = useCallback(value => {
    if (value) {
      setOpen('name');
    } else {
      setOpen('');
    }
  }, []);

  const handleOpenPassword = useCallback(value => {
    if (value) {
      setOpen('password');
    } else {
      setOpen('');
    }
  }, []);

  const handleChangeAdvertise = useCallback(
    e => {
      waplAuthStore.updateTerms({
        isAdvertiseAgree: e.target.value,
        isOptionalTermsAgree: waplAuthStore.sessionInfo.isOptionalTermsAgree,
      });
    },
    [waplAuthStore],
  );

  const styles = {
    fontSize: '0.75rem',
    fontWeight: 'normal',
    color: `${props => props.theme.TextSub}`,
  };

  return useObserver(() => (
    <>
      <SettingModalContent
        title={t(`CM_EDIT_MYPAGE_01`)}
        subTitle={t(`CM_SETTING_GENERAL_06`)}
        isAccountInfo
      />
      <AccountWrapper open={!!open} ref={accountRef}>
        <BorderLineUp />
        <AccountItemModal
          title={t(`CM_SETTING_GENERAL_07`)}
          value={waplUserStore.myProfile.loginId}
        />
        <AccountItemModal
          title={t('CM_EDIT_MYPAGE_02')}
          value={waplUserStore.myProfile.displayName}
          ref={nameRef}
          component={AccountNameFormModal}
          open={open === 'name'}
          onChangeOpen={handleOpenName}
          getPopupContainer={getPopupContainer}
          changeable
        />
        <AccountItemModal
          title={t('CM_PWD')}
          value={t('CM_EDIT_MYPAGE_03', {
            value: waplUserStore.myProfile.pwModiDate?.split(' ')[0] || '-',
          })}
          ref={passwordRef}
          component={AccountPasswordFormModal}
          open={open === 'password'}
          onChangeOpen={handleOpenPassword}
          getPopupContainer={getPopupContainer}
          changeable
        />
        <BlankDiv />
        <AccountItemModal
          title={t('CM_EDIT_MYPAGE_04')}
          subtitle={
            <>
              {t('CM_EDIT_MYPAGE_05')}{' '}
              <AccountWithdrawLink to="/withdrawal/select-type">
                {t('CM_EDIT_MYPAGE_06')}
              </AccountWithdrawLink>
            </>
          }
          getPopupContainer={getPopupContainer}
          value={null}
        />
        <BorderLineDown />
        <AccountItemModal title={t('CM_ADVERTISE_AGREE_POLICY')} value={null}>
          <StyledRadioGroup
            onChange={handleChangeAdvertise}
            value={WaplAuthStore.sessionInfo.isAdvertiseAgree}
          >
            <Radio value="Y"> {t('CM_AGREE_BUTTON_01')}</Radio>
            <Radio value="N"> {t('CM_AGREE_BUTTON_02')}</Radio>
          </StyledRadioGroup>
        </AccountItemModal>
        <AccountItemModal
          // 초대코드
          title={t('CM_SETTING_INVITE_CODE_03')}
          value={waplUserStore.myProfile.inviterCode}
          ref={null}
          component={AccountPasswordFormModal}
          getPopupContainer={getPopupContainer}
          copyable
        />
        <AccountItemModal
          // 이벤트 누적 초대 수
          title={t('CM_SETTING_INVITE_CODE_06')}
          value={
            waplUserStore.myProfile.termeventDate
              ? waplUserStore.myProfile.inviteCount
              : '-'
          }
          ref={null}
          getPopupContainer={getPopupContainer}
        />
        {waplUserStore.myProfile.termeventDate && (
          <EventDuration>
            ({waplUserStore.myProfile.eventBeginDate.replaceAll('/', '.')}~
            {waplUserStore.myProfile.eventEndDate.replaceAll('/', '.')})
          </EventDuration>
        )}
        <AccountItemModal
          smalltext={
            !waplUserStore.myProfile.termeventDate &&
            t('CM_SETTING_INVITE_CODE_10')
          }
          value={null}
          grey
        />
      </AccountWrapper>
    </>
  ));
};

export default AccountModalContent;

const handleAccountWrapperOpenStyle = isOpen => {
  if (isOpen) {
    return css`
      max-height: 26.75rem;
      overflow: auto;
    `;
  }
  return css``;
};

const AccountWrapper = styled.div`
  display: flex;
  align-items: flex-start;
  flex-direction: column;
  width: 100%;
  ${({ open }) => handleAccountWrapperOpenStyle(open)}
`;

const BorderLineUp = styled.div`
  border-top: 0.063rem solid ${props => props.theme.LineSub};
  height: 1.313rem;
  width: 100%;
`;

const BorderLineDown = styled.div`
  border-bottom: 0.063rem solid ${props => props.theme.LineSub};
  height: 1.5rem;
  width: 100%;
  margin-bottom: 0.75rem;
`;

const BlankDiv = styled.div`
  height: 0.25rem;
`;

const AccountWithdrawLink = styled(Link)`
  font-size: 1rem;
  line-height: 1.5rem;
  font-weight: 500;
  color: ${props => props.theme.MyHomeTextLink};
  text-decoration: underline;
  margin-top: 0.625rem;
  &:hover {
    color: ${props => props.theme.MyHomeTextLinkHover};
  }
`;

const StyledRadioGroup = styled(Radio.Group)`
  margin-top: 0.25rem;
  line-height: 1.5rem;
  span {
    font-size: 0.813rem;
    line-height: 1.188rem;
    font-weight: 500;
    color: ${props => props.theme.TextMain};
  }
`;

const EventDuration = styled.div`
  width: auto;
  height: 1.125rem;
  font-family: NotoSansCJKkr;
  font-size: 0.75rem;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  color: #aeaeae;
`;

export const getDomainWithoutProtocol = domain => {
  return domain;
};

export const getDomain = domain => {
  return `${window.location.protocol}//${domain}`;
};

export default {
  getDomainWithoutProtocol,
  getDomain,
};

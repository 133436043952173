import React from 'react';
import styled from 'styled-components';
import FirstLoginContent from '../components/firstlogin/FirstLoginContent';
import FirstLoginHeader from '../components/firstlogin/FirstLoginHeader';
import TermsFooter from '../components/TermsFooter';

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  min-height: 100%;
`;
const Content = styled.div`
  display: flex;
  align-items: center;
  flex-direction: column;
  justify-content: center;
  flex-grow: 1;
`;

function FirstLoginPage() {
  return (
    <Wrapper>
      <FirstLoginHeader />
      <Content>
        <FirstLoginContent />
      </Content>
      <TermsFooter/>
    </Wrapper>
  );
}

export default FirstLoginPage;

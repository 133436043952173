import React from 'react';
import { Link } from 'react-router-dom';
import styled from 'styled-components';
import { useTranslation } from 'react-i18next';
import NoUrlImg from '../../assets/no url.svg';
import WorkspaceInvitationItem from './InvitationItem';

function InvalidSpaceUrlContent() {
  const { t } = useTranslation('common');
  return (
    <>
      <Content>
        <StyledWorkspaceInvitationItem
          title={t('CM_INVITE_PEOPLE_URL_03')}
          subtitle={t('CM_INVITE_PEOPLE_URL_04')}
        >
          <NoUrlIcon />
          <GoToSpaceListLink to="/spaces">
            {t('CM_GO_SPACES')}
          </GoToSpaceListLink>
        </StyledWorkspaceInvitationItem>
      </Content>
    </>
  );
}

export default InvalidSpaceUrlContent;

const Content = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
`;

const NoUrlIcon = styled.div`
  width: 9.375rem;
  height: 9.375rem;
  background: url('${NoUrlImg}') 0 0 no-repeat;
  background-size: contain;
`;

const GoToSpaceListLink = styled(Link)`
  font-size: 0.8125rem;
  line-height: 1.1875rem;
  color: ${props => props.theme.MyHomeTextLink};
  text-decoration: underline;
  margin-top: 1.25rem;
  &:hover {
    color: ${props => props.theme.MyHomeTextLink};
  }
`;

const StyledWorkspaceInvitationItem = styled(WorkspaceInvitationItem)`
  .divider {
    margin-bottom: 0.9375rem;
  }
`;

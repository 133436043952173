// @flow
import { observable, action, set, computed } from 'mobx';
import autobind from 'autobind-decorator';
import moment from 'moment';
import {
  convertFromDtoItemToPlatformItem as convertFromAlarmListDtoItemToPlatformItem,
  convertFromPlatformItemToDtoItem as convertFromAlarmListPlatformItemToDtoItem,
} from './AlarmListModel';

@autobind
class SpaceModel {
  @observable
  id: string;

  @observable
  name: string;

  @observable
  thumbPhoto: string;

  @observable
  domain: string;

  @observable
  domainKey: string;

  @observable
  domainRandomNumber: string;

  @observable
  plan: string;

  @observable
  type: string; // B2B or B2C

  @observable
  userCount: number;

  @observable
  maxUserCount: number;

  @observable
  userId: string;

  @observable
  adminId: string;

  @observable
  adminLoginId: string;

  @observable
  groupId: string;

  @observable
  regiUserLoginId: string;

  @observable
  modiUserLoginId: string;

  @observable
  userLoginId: string;

  @observable
  regiDate: string;

  @observable
  modiDate: string;

  @observable
  alarmList: Array<AlarmListInfo>;

  @observable
  unreadMailCount: number;

  @observable
  maxStorage: number;

  @observable
  maxMailStorage: number;

  @observable
  useMail: boolean;

  @observable
  spec: string;

  @observable
  memGrade: string;

  constructor(data: Object) {
    this.setValues(data);
  }

  @computed
  get profilePhotoURL() {
    const cacheString = this.modiDate
      ? `?${moment(this.modiDate, 'YYYY-MM-DD HH:mm:ss.S Z').valueOf()}`
      : '';
    if (this.thumbPhoto && this.thumbPhoto !== 'default') {
      return `https://${this.domain}/photo/profile/${this.domainKey}/profile/profile.${this.thumbPhoto}${cacheString}`;
    }
    return null;
  }

  @computed
  get fullDomain() {
    return this.domain + (this.domainRandomNumber || '');
  }

  @computed
  get joinable() {
    return !(this.userCount >= this.maxUserCount && this.spec === 'BASIC');
  }

  @computed
  get unreadSpaceCount() {
    return this.totalUnreadRoomCount; // + this.unreadMailCount;
  }

  @computed
  get totalUnreadRoomCount() {
    return this.alarmList
      ?.map(x => x.unreadSpaceCount)
      .reduce((a, b) => a + b, 0);
  }

  @action
  setValues(data: Object) {
    set(this, data);
  }

  @action
  setId(data: string) {
    this.id = data;
  }

  @action
  setName(data: string) {
    this.name = data;
  }

  @action
  setThumbPhoto(data: string) {
    this.thumbPhoto = data;
  }

  @action
  setDomain(data: string) {
    this.domain = data;
  }

  @action
  setDomainKey(data: string) {
    this.domainKey = data;
  }

  @action
  setDomainRandomNumber(data: string) {
    this.domainRandomNumber = data;
  }

  @action
  setPlan(data: string) {
    this.plan = data;
  }

  @action
  setType(data: string) {
    this.type = data;
  }

  @action
  setUserCount(data: number) {
    this.userCount = data;
  }

  @action
  setMaxUserCount(data: number) {
    this.maxUserCount = data;
  }

  @action
  setUserId(data: string) {
    this.userId = data;
  }

  @action
  setUserLoginId(data: string) {
    this.userLoginId = data;
  }

  @action
  setAdminId(data: string) {
    this.adminId = data;
  }

  @action
  setAdminLoginId(data: string) {
    this.adminLoginId = data;
  }

  @action
  setGroupId(data: string) {
    this.groupId = data;
  }

  @action
  setModiUserLoginId(data: string) {
    this.modiUserLoginId = data;
  }

  @action
  setRegiUserLoginId(data: string) {
    this.regiUserLoginId = data;
  }

  @action
  setRegiDate(data: string) {
    this.regiDate = data;
  }

  @action
  setModiDate(data: string) {
    this.modiDate = data;
  }

  @action
  setAlarmList(data: Array<AlarmListInfo>) {
    this.alarmList = data;
  }

  @action
  setUnreadMailCount(data: number) {
    this.unreadMailCount = data;
  }

  @action
  setMaxStorage(data: number) {
    this.maxStorage = data;
  }

  @action
  setMaxMailStorage(data: number) {
    this.maxMailStorage = data;
  }

  @action
  setUseMail(data: boolean) {
    this.useMail = data;
  }

  @action
  setSpec(data: string) {
    this.spec = data;
  }
}

export default SpaceModel;

export const convertFromDtoItemToPlatformItem = (
  dtoItem: $Shape<SpaceInfoDto>,
): ?$Shape<SpaceInfo> => {
  if (dtoItem.DOMAIN_UUID) {
    return {
      id: dtoItem.DOMAIN_UUID,
    };
  }
  if (dtoItem.DOMAIN) {
    return {
      domain: dtoItem.DOMAIN,
    };
  }
  if (dtoItem.DOMAIN_KEY) {
    return {
      domainKey: dtoItem.DOMAIN_KEY,
    };
  }
  if (dtoItem.DOMAIN_RANDOM_NUMBER) {
    return {
      domainRandomNumber: dtoItem.DOMAIN_RANDOM_NUMBER,
    };
  }
  if (dtoItem.DOMAIN_NAME) {
    return {
      name: dtoItem.DOMAIN_NAME,
    };
  }
  if (dtoItem.PLAN_TYPE) {
    return {
      plan: dtoItem.PLAN_TYPE,
    };
  }
  if (dtoItem.DOMAIN_TYPE) {
    return {
      type: dtoItem.DOMAIN_TYPE,
    };
  }
  if (dtoItem.USER_COUNT) {
    return {
      userCount: dtoItem.USER_COUNT,
    };
  }
  if (dtoItem.MAX_USER_COUNT) {
    return {
      maxUserCount: dtoItem.MAX_USER_COUNT,
    };
  }
  if (dtoItem.USER_LOGIN_ID) {
    return {
      userLoginId: dtoItem.USER_LOGIN_ID,
    };
  }
  if (dtoItem.USER_ID) {
    return {
      userId: dtoItem.USER_ID,
    };
  }
  if (dtoItem.DOMAIN_IMG_EXTENSION) {
    return {
      thumbPhoto: dtoItem.DOMAIN_IMG_EXTENSION,
    };
  }
  if (dtoItem.GROUP_ID) {
    return {
      groupId: dtoItem.GROUP_ID,
    };
  }
  if (dtoItem.ADMIN_ID) {
    return {
      adminId: dtoItem.ADMIN_ID,
    };
  }
  if (dtoItem.ADMIN_LOGIN_ID) {
    return {
      adminLoginId: dtoItem.ADMIN_LOGIN_ID,
    };
  }
  if (dtoItem.REGI_USER_LOGIN_ID) {
    return {
      regiUserLoginId: dtoItem.REGI_USER_LOGIN_ID,
    };
  }
  if (dtoItem.MODI_USER_LOGIN_ID) {
    return {
      modiUserLoginId: dtoItem.MODI_USER_LOGIN_ID,
    };
  }
  if (dtoItem.REGI_DATE) {
    return {
      regiDate: dtoItem.REGI_DATE,
    };
  }
  if (dtoItem.MODI_DATE) {
    return {
      modiDate: dtoItem.MODI_DATE,
    };
  }
  if (typeof dtoItem.mailCount !== 'undefined' /* for number value 0 */) {
    return {
      unreadMailCount: dtoItem.mailCount,
    };
  }
  if (typeof dtoItem.MAX_STORAGE !== 'undefined') {
    return {
      maxStorage: dtoItem.MAX_STORAGE,
    };
  }
  if (typeof dtoItem.MAX_MAIL_STORAGE !== 'undefined') {
    return {
      maxMailStorage: dtoItem.MAX_MAIL_STORAGE,
    };
  }
  if (dtoItem.USE_MAIL_YN) {
    return {
      useMail: dtoItem.USE_MAIL_YN === 'Y',
    };
  }
  if (dtoItem.ALARM_LIST) {
    return {
      alarmList: dtoItem.ALARM_LIST.map(item => {
        const obj = {};
        Object.keys(item).forEach(prop => {
          const propObj = {};
          propObj[prop] = item[prop];
          Object.assign(
            obj,
            convertFromAlarmListDtoItemToPlatformItem(propObj),
          );
        });
        return obj;
      }),
    };
  }
  if (dtoItem.SPEC_OPTION) {
    return {
      spec: dtoItem.SPEC_OPTION,
    };
  }
  if (dtoItem.MEM_GRADE) {
    return {
      memGrade: dtoItem.MEM_GRADE,
    };
  }

  return undefined;
};

export const convertFromPlatformItemToDtoItem = (
  platformItem: $Shape<SpaceInfo>,
): ?$Shape<SpaceInfoDto> => {
  if (platformItem.unreadMailCount) {
    return {
      mailCount: platformItem.unreadMailCount,
    };
  }
  if (platformItem.id) {
    return {
      DOMAIN_UUID: platformItem.id,
    };
  }
  if (platformItem.domain) {
    return {
      DOMAIN: platformItem.domain,
    };
  }
  if (platformItem.domainKey) {
    return {
      DOMAIN_KEY: platformItem.domainKey,
    };
  }
  if (platformItem.domainRandomNumber) {
    return {
      DOMAIN_RANDOM_NUMBER: platformItem.domainRandomNumber,
    };
  }
  if (platformItem.name) {
    return {
      DOMAIN_NAME: platformItem.name,
    };
  }
  if (platformItem.thumbPhoto) {
    return {
      DOMAIN_IMG_EXTENSION: platformItem.thumbPhoto,
    };
  }
  if (platformItem.plan) {
    return {
      PLAN_TYPE: platformItem.plan,
    };
  }
  if (platformItem.type) {
    return {
      DOMAIN_TYPE: platformItem.type,
    };
  }
  if (platformItem.userCount) {
    return {
      USER_COUNT: platformItem.userCount,
    };
  }
  if (platformItem.maxUserCount) {
    return {
      MAX_USER_COUNT: platformItem.maxUserCount,
    };
  }
  if (platformItem.userLoginId) {
    return {
      USER_LOGIN_ID: platformItem.userLoginId,
    };
  }
  if (platformItem.userId) {
    return {
      USER_ID: platformItem.userId,
    };
  }
  if (platformItem.adminId) {
    return {
      ADMIN_ID: platformItem.adminId,
    };
  }
  if (platformItem.adminLoginId) {
    return {
      ADMIN_LOGIN_ID: platformItem.adminLoginId,
    };
  }
  if (platformItem.groupId) {
    return {
      GROUP_ID: platformItem.groupId,
    };
  }
  if (platformItem.regiUserLoginId) {
    return {
      REGI_USER_LOGIN_ID: platformItem.regiUserLoginId,
    };
  }
  if (platformItem.modiUserLoginId) {
    return {
      MODI_USER_LOGIN_ID: platformItem.modiUserLoginId,
    };
  }
  if (platformItem.regiDate) {
    return {
      REGI_DATE: platformItem.regiDate,
    };
  }
  if (platformItem.modiDate) {
    return {
      MODI_DATE: platformItem.modiDate,
    };
  }
  if (platformItem.maxStorage) {
    return {
      MAX_STORAGE: platformItem.maxStorage,
    };
  }
  if (platformItem.maxMailStorage) {
    return {
      MAX_MAIL_STORAGE: platformItem.maxMailStorage,
    };
  }
  if (typeof platformItem.useMail !== 'undefined') {
    return {
      USE_MAIL_YN: platformItem.useMail ? 'Y' : 'N',
    };
  }
  if (platformItem.alarmList) {
    return {
      ALARM_LIST: platformItem.alarmList.map(item => {
        const obj = {};
        Object.keys(item).forEach(prop => {
          const propObj = {};
          propObj[prop] = item[prop];
          Object.assign(
            obj,
            convertFromAlarmListPlatformItemToDtoItem(propObj),
          );
        });
        return obj;
      }),
    };
  }
  if (platformItem.spec) {
    return {
      SPEC_OPTION: platformItem.spec,
    };
  }
  if (platformItem.memGrade) {
    return {
      MEM_GRADE: platformItem.memGrade,
    };
  }
  return undefined;
};

// @flow
/* eslint-disable no-underscore-dangle */
import { observable } from 'mobx';
import { WaplUserStore } from 'teespace-core';
import { persist } from 'mobx-persist';
import SpaceModel from './models/SpaceModel';
import SpaceRepo from './repositories/SpaceRepository';

const store = observable({
  __spaces: observable.map({}),
  hasAdminSpace() {
    return !!(
      this.spaceList.find(space => space.memGrade === 'admin') ||
      this.spaceList.find(space => space.adminId === WaplUserStore.myProfile.id)
    );
  },
  hasPremiumSpace() {
    return !!this.spaceList.find(space => space.spec === 'PREMIUM');
  },
  checkWithdrawalPossible() {
    return (
      this.spaceList.filter(space => {
        switch (space.memGrade) {
          case 'guest':
            return space.type === 'B2C'; // type이 B2C 일 때에만 탈퇴 가능
          case 'member':
            return space.spec === 'BASIC'; // plan 이 'BASIC' 일 때에만 탈퇴 가능
          case 'admin':
            return false; // admin 은 탈퇴 불가
          default:
            return true;
        }
      }).length === this.spaceList.length
    );
  },
  get totalUnreadSpaceCount() {
    return this.spaceList.reduce(
      (total, spaceInfo) => total + spaceInfo.unreadSpaceCount,
      0,
    );
  },
  get spaceList() {
    return [...this.__spaces.values()];
  },
  initHydratedSpaceList() {
    const hydration = this.spaceList.map(space => {
      return new SpaceModel(space);
    });
    console.log(hydration);

    this.setSpaces(hydration);
  },
  /**
   * space id(uuid)로 이미 참가중인 스페이스인지 검사
   * @alias module:SpaceStore
   * @param {string} id - (필수) 참가 여부를 검사하고자 하는 space id
   * @return {boolean} - 스페이스 참가 여부
   */
  getAdminSpaces() {
    return this.spaceList.filter(space => space.memGrade === 'admin');
  },
  checkAlreadyJoined({ id }) {
    return this.__spaces.has(id);
  },
  /**
   * 스페이스 조회
   * @alias module:SpaceStore
   * @param {string} userId - (필수) 자신의 userId
   * @return {Promise<Array<SpaceModel>>} - 스페이스 목록
   */
  async fetchSpaces({
    userId,
  }: {
    userId: string,
  }): Promise<Array<SpaceModel>> {
    try {
      const spaces = await SpaceRepo.listSpaces({ userId });
      this.setSpaces(spaces);
      console.log(this.spaceList);
      return this.spaceList;
    } catch (err) {
      throw Error(err.message);
    }
  },
  increaseUnreadRoomCount({ domainKey, roomId, qty }) {
    this.increaseUnreadSpaceCount({ domainKey, roomId, qty });
  },
  // 삭제 예정 (increaseUnreadRoomCount로 변경 예정)
  increaseUnreadSpaceCount({ domainKey, roomId, qty }) {
    const spaceInfo = this.spaceList.find(
      space => space.domainKey === domainKey,
    );
    const roomInfo = spaceInfo.alarmList.find(room => room.roomId === roomId);
    if (roomInfo) {
      roomInfo.unreadSpaceCount += qty;
    }
  },
  increaseUnreadMailCount({ domainKey, qty }) {
    const spaceInfo = this.spaceList.find(
      space => space.domainKey === domainKey,
    );
    if (spaceInfo) {
      spaceInfo.setUnreadMailCount(spaceInfo.unreadMailCount + qty);
    }
  },
  decreaseUnreadMailCount({ domainKey, qty }) {
    const spaceInfo = this.spaceList.find(
      space => space.domainKey === domainKey,
    );
    if (spaceInfo) {
      spaceInfo.setUnreadMailCount(spaceInfo.unreadMailCount - qty);
    }
  },
  // 삭제 예정 (decreaseUnreadRoomCount로 변경 예정)
  decreaseUnreadSpaceCount({ domainKey, qty }) {
    const spaceInfo = this.spaceList.find(
      space => space.domainKey === domainKey,
    );
    if (spaceInfo) {
      spaceInfo.unreadSpaceCount -= qty;
    }
  },
  setUnreadRoomCountZero({ domainKey, roomId }) {
    this.setUnreadSpaceCountZero({ domainKey, roomId });
  },
  // 삭제 예정 (setUnreadRoomCountZero로 이름 변경해야 함)
  setUnreadSpaceCountZero({ domainKey, roomId }) {
    const spaceInfo = this.spaceList.find(
      space => space.domainKey === domainKey,
    );
    const roomInfo = spaceInfo?.alarmList?.find(room => room.roomId === roomId);
    if (roomInfo) {
      roomInfo.unreadSpaceCount = 0;
    }
  },
  /**
   * 스페이스 생성
   * @alias module:SpaceStore
   * @param {string} name - (필수) 스페이스 이름
   * @param {string} domain - (필수) 스페이스 도메인 (서브도메인)
   * @param {string} type - (필수)  'B2C' or 'B2B'
   * @param {string} plan - (필수) 'BASIC'
   * @param {string} userId - (필수) 스페이스를 관리자 userId
   * @return {Promise<SpaceModel>}
   */
  async createSpace({
    name,
    domain,
    plan,
    type,
    userCount,
    userLoginId,
    userId,
    userName,
  }: {
    name: string,
    domain: string,
    plan: string,
    type: string,
    userCount: number,
    userLoginId: string,
    userId: string,
    userName: string,
  }): Promise<SpaceModel> {
    try {
      const space: $Shape<SpaceInfo> = await SpaceRepo.createSpace({
        name,
        domain,
        plan,
        type,
        userCount,
        userLoginId,
        userId,
        userName,
      });
      this.setSpace(space);
      return new SpaceModel(space);
    } catch (err) {
      throw Error(err.message);
    }
  },
  /**
   * 도메인(서브도메인) 으로 스페이스 단건 검색
   * @alias module:SpaceStore
   * @param {string} domain - (필수) 검색할 도메인(서브도메인)
   * @return {Promise<SpaceModel | null>}
   */
  async searchSpaceByDomain({
    domain,
  }: {
    domain: string,
  }): Promise<SpaceModel | null> {
    try {
      const space = await SpaceRepo.getDomainSearchedSpace({ domain });
      if (space) {
        return new SpaceModel(space);
      }
      return null;
    } catch (error) {
      throw Error(error.message);
    }
  },
  /**
   * domainKey로 스페이스 단건 검색
   * @alias module:SpaceStore
   * @param {string} domainKey - (필수) DOM_타입_숫자 로 구성된 값
   * @return {Promise<SpaceModel | null>}
   */
  async searchSpaceByDomainKey({
    domainKey,
  }: {
    domainKey: string,
  }): Promise<SpaceModel | null> {
    try {
      const space = await SpaceRepo.getDomainKeySearchedSpace({ domainKey });
      if (space) {
        return new SpaceModel(space);
      }
      return null;
    } catch (error) {
      throw Error(error.message);
    }
  },
  /**
   * 스페이스 참가
   * @alias module:SpaceStore
   * @param {string} id - (필수) 참가하고자 하는 스페이스 id
   * @param {string} domain - (필수) 참가하고자 하는 스페이스의 domain
   * @param {string} userId - (필수) 참가하고자 하는 유저의 userId
   * @param {string} userName - (필수) 참가하고자 하는 유저의 별명
   * @param {string} adminId - (필수) 나를 초대한 유저의 userId (자동으로 친구가 맺어짐)
   * @param {string} memGrade - (필수) 초대할 유저의 권한 (게스트, 멤버)
   * @return {Promise<boolean>} - 참가 성공 여부
   */
  async joinSpace({
    id,
    domain,
    userId,
    userLoginId,
    userName,
    userPhone,
    adminId,
    recommenderLoginId,
    memGrade,
    isInvitedUser,
  }: {
    id: string,
    domain: string,
    userId: string,
    userLoginId: string,
    userName: string,
    userPhone: string,
    adminId: string,
    recommenderLoginId: string,
    memGrade: string,
    isInvitedUser: boolean,
  }): Promise<boolean> {
    try {
      return await SpaceRepo.joinSpace({
        id,
        domain,
        userId,
        userLoginId,
        userName,
        userPhone,
        adminId,
        recommenderLoginId,
        memGrade,
        isInvitedUser,
      });
    } catch (error) {
      throw Error(error.message);
    }
  },
  /**
   * 스페이스 정보 변경
   * @alias module:SpaceStore
   * @param {string} id - (필수) 정보를 변경할 스페이스 id
   * @param {object} updatedInfo - (필수) 변경될 정보를 담고 있는 object
   * @param {string} updatedInfo.name - 변경될 스페이스 이름
   * @param {string} updatedInfo.domain - 변경될 스페이스 도메인
   * @return {Promise<SpaceModel>} - 변경된 스페이스 모델
   */
  async updateSpace({
    id,
    updatedInfo: { name, domain, plan, type, userCount, userLoginId, userId },
  }: {
    id: string,
    updatedInfo: $Shape<SpaceInfo>,
  }): Promise<SpaceModel> {
    const space = this.__spaces.get(id);
    try {
      const updatedObj = await SpaceRepo.updateSpace({
        id,
        updatedInfo: {
          name,
          domain,
          plan,
          type,
          userCount,
          userLoginId,
          userId,
        },
      });
      this.setSpace({
        ...space,
        ...updatedObj,
        id,
      });
      return new SpaceModel({ ...space, ...updatedObj });
    } catch (err) {
      throw Error(err.message);
    }
  },
  /* 이하 함수는 내부용 함수 */
  setSpaces(spaceList: Array<SpaceModel>): void {
    const obj = {};
    spaceList.forEach(space => {
      obj[space.id] = new SpaceModel(space);
    });
    if (Object.keys(obj).length === 0) {
      this.__spaces = observable.map({});
    } else {
      this.__spaces.replace(obj);
    }
  },
  setSpace(space: SpaceModel, deleted: boolean = false): void {
    if (deleted) {
      this.__spaces.delete(space.id);
    } else {
      this.__spaces.set(space.id, new SpaceModel({ ...space }));
    }
  },
});

const schema = {
  __spaces: {
    type: 'map',
    schema: {
      id: true,
      name: true,
      thumbPhoto: true,
      domain: true,
      domainKey: true,
      domainRandomNumber: true,
      plan: true,
      type: true,
      userCount: true,
      maxUserCount: true,
      userId: true,
      adminId: true,
      regiUserLoginId: true,
      modiUserLoginId: true,
      userLoginId: true,
      regiDate: true,
      modiDate: true,
      alarmList: {
        type: 'list',
      },
      unreadMailCount: true,
      maxStorage: true,
      useMail: true,
      spec: true,
      memGrade: true,
    },
  },
};

const SpaceStore = persist(schema)(store);
export default SpaceStore;

import React, { useEffect } from 'react';
import { Link } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { Loader } from 'teespace-core';
import styled, { css } from 'styled-components';
import { isMobile } from 'react-device-detect';
import WaplSupportImgEN from '../../assets/Wapl_support_logo_full_En.svg';
import WaplSupportImgDarkEN from '../../assets/Wapl_support_logo_full_En_dark.svg';
import WaplSupportImg from '../../assets/wapl_support_logo_full.png';
import WaplSupportImgDark from '../../assets/wapl_support_logo_full_dark.svg';
import WorkspaceProfile from './SpaceHeaderProfile';
import LoadingComponent from '../Loading';
import WaplLogoComp from '../WaplLogo';

function SpaceHeader({ support = false }) {
  const [loader] = Loader.useLoader();
  const { i18n } = useTranslation('common');
  useEffect(() => {
    loader.stop();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  return (
    <Loader loader={loader} component={<LoadingComponent />}>
      {!isMobile && (
        <Header>
          <LogoWrapper>
            {support ? (
              <WaplSupportLogo to="/support/home" isen={i18n.language}>
                <span>WAPL 고객센터</span>
              </WaplSupportLogo>
            ) : (
              <Link to="/spaces">
                <WaplLogo />
                <span>WAPL</span>
              </Link>
            )}
          </LogoWrapper>
          <WorkspaceProfile loader={loader} support={support} />
        </Header>
      )}
    </Loader>
  );
}

export default SpaceHeader;

const Header = styled.div`
  height: 3.125rem;
  padding-left: 1.25rem;
  border-bottom: 1px solid ${props => props.theme.LineSub};
  display: flex;
  align-items: center;
  justify-content: space-between;
`;

const LogoWrapper = styled.h1`
  line-height: 0;

  span {
    position: absolute;
    clip: rect(0 0 0 0);
    width: 1px;
    height: 1px;
    margin: -1px;
    overflow: hidden;
  }
`;

const WaplLogo = styled(WaplLogoComp)`
  height: 1.75rem;
  width: 6.2rem;
`;

const handleSupportLogoImg = ({ isen, themeName }) => {
  if (isen === 'en' && themeName === 'dark') {
    return WaplSupportImgDarkEN;
  }
  if (isen === 'en' && themeName !== 'dark') {
    return WaplSupportImgEN;
  }
  if (isen !== 'en' && themeName === 'dark') {
    return WaplSupportImgDark;
  }
  return WaplSupportImg;
};
const WaplSupportLogo = styled(Link)`
  height: 1.75rem;
  width: 11.44rem;
  background-size: contain;
  background-repeat: no-repeat;
  display: inline-block;
  background-image: url('${({ isen, theme }) =>
    handleSupportLogoImg({ isen, themeName: theme.name })}');
`;

import React from 'react';
import 'swiper/swiper-bundle.css';
import { useTranslation } from 'react-i18next';
import { Swiper, SwiperSlide } from 'swiper/react';
import SwiperCore, { Pagination, Autoplay } from 'swiper';
import styled, { css } from 'styled-components';
import { isMobile } from 'react-device-detect';
import slide0 from '../../assets/landing/topbanner/topbanner_01.png';
import slide1 from '../../assets/landing/topbanner/topbanner_02.png';
import bannertext1 from '../../assets/landing/topbanner/topbanner_text_01.png';
import bannertext2 from '../../assets/landing/topbanner/topbanner_text_02.png';

SwiperCore.use([Pagination, Autoplay]);

const ServiceTopBanner = () => {
  const { t, i18n } = useTranslation('service');
  return (
    <TopBannerWrapper id="topbannerwrapper">
      <SwiperWrapper>
        <Swiper
          id="topbanner"
          pagination={{ clickable: true }}
          loop
          autoplay={{ delay: 6000 }}
        >
          <SwiperSlide key="slide0" tag="li" data-key="slide0">
            <ColMain>
              <ColLeft>
                <BannerSub>{t('SP_BANNER_01')}</BannerSub>
                <BannerMain isen={i18n.language}>
                  {t('SP_BANNER_02')}
                </BannerMain>
                <BannerTextWrapper>
                  <BannerTextImg src={bannertext1} alt="bannertext1" />
                  <BannerTextDescWrapper>
                    <DescText>{t('SP_WORKS_01')}</DescText>
                  </BannerTextDescWrapper>
                </BannerTextWrapper>
              </ColLeft>
              <ColRight>
                <BannerImg src={slide0} alt="slide0" />
              </ColRight>
            </ColMain>
          </SwiperSlide>
          <SwiperSlide key="slide1" tag="li" data-key="slide1">
            <ColMain>
              <ColLeft>
                <BannerSub>{t('SP_BANNER_04')}</BannerSub>
                <BannerMain isen={i18n.language}>
                  {t('SP_BANNER_05')}
                </BannerMain>
                <BannerTextImg src={bannertext2} alt="bannertext2" />
              </ColLeft>
              <ColRight>
                <BannerImg src={slide1} alt="slide1" />
              </ColRight>
            </ColMain>
          </SwiperSlide>
        </Swiper>
      </SwiperWrapper>
    </TopBannerWrapper>
  );
};
export default ServiceTopBanner;

const TopBannerWrapper = styled.div`
  ${isMobile
    ? css`
        height: 37.13rem;
      `
    : css`
        display: flex;
        flex-direction: row;
        height: 31.25rem;
        justify-content: center;
        align-items: center;
        .swiper-pagination-bullet {
          background: #205855;
        }
      `}
`;

const ColMain = styled.div`
  ${isMobile
    ? css`
        display: flex;
        flex-direction: column-reverse;
        height: 37.13rem;
        justify-content: flex-end;
      `
    : css`
        display: flex;
        flex-direction: row;
        margin-left: 20rem;
        margin-right: 20rem;
        height: 100%;
      `}
`;

const ColLeft = styled.div`
  ${isMobile
    ? css``
    : css`
        height: 100%;
        padding-top: 8.125rem;
        width: 39.75rem;
        padding-left: 4.125rem;
      `}
`;

const ColRight = styled.div`
  ${isMobile
    ? css``
    : css`
        flex: 1;
        display: flex;
      `}
`;

const SwiperWrapper = styled.div`
  ${isMobile
    ? css`
        margin-top: 0;
        display: flex;

        .swiper-slide[data-key='slide0'] {
          background-color: #dbe7e6;
        }

        .swiper-slide[data-key='slide1'] {
          background-color: #ffeee8;
        }
        & .swiper-pagination {
          margin-bottom: 2.5rem;
        }
        & .swiper-pagination-bullet-active {
          background-color: #205855;
        }
      `
    : css`
        .swiper-container {
          height: 31.25rem;
          width: 119.5rem; //우측 스크롤 공간 감안

          .swiper-slide[data-key='slide0'] {
            background-color: #dbe7e6;
          }

          .swiper-slide[data-key='slide1'] {
            background-color: #ffeee8;
          }
        }
        .swiper-pagination {
          bottom: 1.875rem;
          left: 0;
          width: 100%;
        }
      `}
`;

const BannerImg = styled.img`
  object-fit: contain;
  ${props =>
    props.alt === 'slide0'
      ? isMobile
        ? css`
            width: 15.375rem;
            height: 13.1875rem;
            margin-top: 5rem;
            margin-left: 4.5rem;
            margin-bottom: 3.125rem;
          `
        : css`
            width: 25.561rem;
            height: 21.938rem;
            margin-top: 5.313rem;
            margin-left: 10rem;
          `
      : isMobile
      ? css`
          object-fit: contain;
          width: 19.875rem;
          height: 19.875rem;
          margin-top: 1rem;
          margin-left: 1.5rem;
        `
      : css`
          width: 41.8rem;
          height: 41.8rem;
          position: relative;
          top: -5.5rem;
          left: -1.5rem;
        `}
`;

const BannerSub = styled.div`
  width: auto;
  ${isMobile
    ? css`
        font-size: 0.75rem;
        margin-left: 2.5rem;
      `
    : css`
        height: 1.813rem;
        font-size: 1.25rem;
      `}
`;

const BannerMain = styled.pre`
  object-fit: contain;
  font-family: NotoSansCJKkr;
  font-weight: bold;
  ${isMobile
    ? css`
        display: flex;
        height: 4.5rem;
        margin: 0.75rem 0 0.75rem 2.5rem;
        font-size: 1.35rem;
        ${props =>
          props.isen === 'en' &&
          css`
            font-size: 1.2rem;
          `}
      `
    : css`
        height: 7.25rem;
        margin: 1.25rem 0 1.25rem 0;
        font-size: 2.5rem;
        ${props =>
          props.isen === 'en' &&
          css`
            font-size: 2rem;
          `}
        font-stretch: normal;
        font-style: normal;
        line-height: 1.45;
        letter-spacing: normal;
      `}
`;

const BannerTextImg = styled.img`
  width: auto;
  ${isMobile
    ? css`
        height: 2rem;
        margin-left: 2.5rem;
        ${props =>
          props.id === 'bannertext2' &&
          css`
            height: 1.6rem;
          `}
      `
    : css`
        height: 3.5rem;

        ${props =>
          props.id === 'bannertext2' &&
          css`
            width: 15.313rem;
            height: 2.65rem;
          `}
      `}
`;

const BannerTextWrapper = styled.div`
  display: flex;
  flex-direction: row;
`;
const BannerTextDescWrapper = styled.div`
  display: flex;
  flex-direction: column;
  margin: 0.3rem 0 0 0.75rem;
`;

const DescText = styled.div`
  display: flex;
  margin-top: 0.813rem;
  font-size: 1.375rem;
  font-weight: 500;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: -0.035rem;
  color: #39605c;
  ${isMobile &&
  css`
    font-size: 0.813rem;
    margin-top: 0.625rem;
  `};
`;

// @flow
/* eslint-disable class-methods-use-this */
import ComAPI from '../../libs/ComAPI';
import {
  convertFromDtoItemToPlatformItem,
  convertFromPlatformItemToDtoItem,
} from '../models/SpaceModel';
import { convertFromDtoItemToPlatformItem as alarmListConverter } from '../models/AlarmListModel';

export const convertFromDtoObjToPlatformObj = (
  dtoObj: $Shape<SpaceInfoDto>,
): $Shape<SpaceInfo> => {
  const result: $Shape<SpaceInfo> = {};

  Object.keys(dtoObj).forEach((key: string) => {
    if (
      {}.hasOwnProperty.call(dtoObj, key) &&
      typeof dtoObj[key] !== 'undefined'
    ) {
      Object.assign(
        result,
        convertFromDtoItemToPlatformItem({ [key]: dtoObj[key] }),
      );
    }
  });
  return result;
};

export const convertFromPlatformObjToDtoObj = (
  dtoObj: $Shape<SpaceInfo>,
): $Shape<SpaceInfoDto> => {
  const result: $Shape<SpaceInfoDto> = {};

  Object.keys(dtoObj).forEach((key: string) => {
    if (
      {}.hasOwnProperty.call(dtoObj, key) &&
      typeof dtoObj[key] !== 'undefined'
    ) {
      Object.assign(
        result,
        convertFromPlatformItemToDtoItem({ [key]: dtoObj[key] }),
      );
    }
  });
  return result;
};

export const convertAlarmFromDtoObjToPlatformObj = (
  dtoObj: $Shape<AlarmListInfoDto>,
): $Shape<AlarmListInfo> => {
  const result: $Shape<AlarmListInfo> = {};

  Object.keys(dtoObj).forEach((key: string) => {
    if (
      {}.hasOwnProperty.call(dtoObj, key) &&
      typeof dtoObj[key] !== 'undefined'
    ) {
      Object.assign(result, alarmListConverter({ [key]: dtoObj[key] }));
    }
  });
  return result;
};

class SpaceRepository {
  async listSpaces({
    userId,
  }: {
    userId: string,
  }): Promise<Array<$Shape<SpaceInfo>>> {
    try {
      const response = await ComAPI.get(
        `common-api/domains/${userId}?action=list`,
      );
      return (response.data.dto.domainList || []).map(domainDto =>
        convertFromDtoObjToPlatformObj(domainDto),
      );
    } catch (error) {
      throw Error(JSON.stringify(error.response.data.exception));
    }
  }

  async createSpace({
    name,
    domain,
    plan = 'BASIC',
    type = 'B2C',
    userCount,
    userId,
    userLoginId,
    userName,
  }: {
    name: string,
    domain: string,
    plan: string,
    type: string,
    userCount: number,
    userId: string,
    userLoginId: string,
    userName: string,
  }): Promise<$Shape<SpaceInfo>> {
    const queryObj = { dto: {} };
    queryObj.dto.DOMAIN_NAME = name;
    queryObj.dto.DOMAIN = domain;
    queryObj.dto.PLAN_TYPE = plan;
    queryObj.dto.DOMAIN_TYPE = type;
    queryObj.dto.USER_COUNT = userCount;
    queryObj.dto.USER_ID = userId;
    queryObj.dto.USER_LOGIN_ID = userLoginId;
    queryObj.dto.USER_NAME = userName;
    try {
      const response = await ComAPI.post('common-api/domains', queryObj);
      return convertFromDtoObjToPlatformObj(response.data.dto);
    } catch (error) {
      throw Error(JSON.stringify(error.response.data.exception));
    }
  }

  async updateSpace({
    id,
    updatedInfo: { domain, name, userId, userName, userLoginId, type, plan },
  }: {
    id: string,
    updatedInfo: Object,
  }): Promise<$Shape<SpaceInfo>> {
    const queryObj = { dto: {} };
    queryObj.dto = convertFromPlatformObjToDtoObj({
      domain,
      name,
      userId,
      userName,
      userLoginId,
      type,
      plan,
    });
    try {
      const response = await ComAPI.put(`common-api/domains/${id}`, queryObj);
      return convertFromDtoObjToPlatformObj(response.data.dto);
    } catch (error) {
      throw Error(JSON.stringify(error.response.data.exception));
    }
  }

  async getDomainSearchedSpace({
    domain,
  }: {
    domain: string,
  }): Promise<$Shape<SpaceInfo>> {
    try {
      const response = await ComAPI.get(
        `common-api/domains/${domain}?action=keyGet`,
      );
      if (response.data.dto) {
        return convertFromDtoObjToPlatformObj(response.data.dto);
      }
      return null;
    } catch (error) {
      if (error.response.data.exception.code === 'NON-0100') {
        return null;
      }
      throw Error(JSON.stringify(error.response.data.exception));
    }
  }

  async getDomainKeySearchedSpace({
    domainKey,
  }: {
    domainKey: string,
  }): Promise<$Shape<SpaceInfo>> {
    try {
      const response = await ComAPI.get(
        `common-api/domainkey/${domainKey}/domains`,
      );
      if (response.data.dto) {
        return convertFromDtoObjToPlatformObj(response.data.dto);
      }
      return null;
    } catch (error) {
      if (error.response.data.exception.code === 'NON-0100') {
        return null;
      }
      throw Error(JSON.stringify(error.response.data.exception));
    }
  }

  async joinSpace({
    id,
    domain,
    userId,
    userLoginId,
    userName,
    userPhone,
    adminId,
    recommenderLoginId,
    memGrade,
    isInvitedUser = false,
  }: {
    id: string,
    domain: string,
    userId: string,
    userLoginId: string,
    userName: string,
    userPhone: string,
    adminId: string,
    recommenderLoginId: string,
    memGrade: string,
    isInvistedUser: boolean,
  }): Promise<$Shape<SpaceInfo>> {
    const queryObj = { dto: {} };

    queryObj.dto.DOMAIN = domain;
    queryObj.dto.USER_LOGIN_ID = userLoginId;
    queryObj.dto.USER_NAME = userName;
    queryObj.dto.USER_ID = userId;
    queryObj.dto.USER_PHONE = userPhone;
    queryObj.dto.ADMIN_ID = adminId;
    queryObj.dto.RECOMMENDER_LOGIN_ID = recommenderLoginId;
    queryObj.dto.MEM_GRADE = memGrade;
    queryObj.dto.NEW_YN = isInvitedUser ? 'Y' : 'N';
    try {
      await ComAPI.post(`common-api/domains/${id}/domainuser`, queryObj);
      return true;
    } catch (error) {
      throw Error(JSON.stringify(error.response.data.exception));
    }
  }
}

export default new SpaceRepository();

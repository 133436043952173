import React, { useState, useCallback, useContext } from 'react';
import styled, { css, ThemeContext } from 'styled-components';
import { useTranslation } from 'react-i18next';
import { WaplSearch } from 'teespace-core';
import { useObserver } from 'mobx-react';
import { useStores } from '../../stores';
import FavFriendItem from './FavFriendItem';

export default function (props) {
  const { friendStore, spaceStore } = useStores();
  const [keyword, setKeyword] = useState('');
  const { t } = useTranslation('common');
  const handleSpaceSearchChange = useCallback(value => {
    setKeyword(value);
  }, []);

  const handleSpaceSearchClear = useCallback(() => {
    setKeyword('');
  }, []);
  const theme = useContext(ThemeContext);

  return useObserver(() => (
    <FavFriendListWrapper tall={!!spaceStore.spaceList.length}>
      <FriendTitle>{t('CM_BOOKMARK')}</FriendTitle>
      {!friendStore.friendList.length && (
        <EmptyFriendText>
          <EmptyFriendTitle>{t('CM_MYHOME_02')}</EmptyFriendTitle>
          <EmptyFriendSubtitle>{t('CM_MYHOME_03')}</EmptyFriendSubtitle>
        </EmptyFriendText>
      )}
      {!!friendStore.friendList.length && (
        <>
          <FriendSearch
            isCountExist={false}
            searchIconColor={{
              active: theme.IconActive,
              default: theme.IconActive,
            }}
            clearIconColor={{ active: '#17202B', default: '#C6CED6' }}
            onChange={handleSpaceSearchChange}
            onClear={handleSpaceSearchClear}
            placeholder={t('CM_SEARCH_FRIEND')}
          />
          <FavFriendList>
            {friendStore.friendList
              .filter(
                friendInfo =>
                  !keyword || friendInfo.displayName.includes(keyword),
              )
              .map(friend => (
                <FavFriendItem key={friend.id} friendInfo={friend} />
              ))}
          </FavFriendList>
        </>
      )}
    </FavFriendListWrapper>
  ));
}

const FavFriendList = styled.div`
  overflow-y: auto;
  overflow-x: hidden;
  width: calc(100% + 1.125rem);
  align-self: flex-start;
  flex-grow: 1;
  height: 25.75rem;
`;

const FavFriendListWrapper = styled.div`
  margin-left: 1.875rem;
  display: flex;
  align-items: center;
  flex-direction: column;
  background: ${props => props.theme.StateNormal};
  border: 1px solid ${props => props.theme.LineMain};
  border-radius: 15px;
  border-radius: 15px;
  margin-top: 2.5rem;
  padding: 1.875rem 1.25rem 0 1.25rem;
  width: 21.25rem;
  ${props =>
    props.tall
      ? css`
          min-height: 28.125rem;
        `
      : css`
          height: 23.125rem;
        `}
`;

const FriendTitle = styled.h1`
  font-weight: 500;
  font-size: 0.8125rem;
  line-height: 1.1875rem;
  text-align: left;
  width: 100%;
  margin-left: 0.625rem;
  color: ${props => props.theme.TextMain};
`;

const FriendSearch = styled(WaplSearch)`
  color: ${props => props.theme.TextMain};
  width: calc(100% - 1.25rem);
  padding: 0;
  margin-top: 1rem;
  height: 1.88rem;
  margin-bottom: 0.625rem;
  margin-left: 0.625rem;
  margin-right: 0.625rem;
  border-bottom: 0.125rem solid ${props => props.theme.CoreDark};
`;

const EmptyFriendText = styled.div`
  margin-top: -3.0625rem;
  flex-grow: 1;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  display: flex;
  text-align: center;
`;

const EmptyFriendTitle = styled.h1`
  font-size: 0.875rem;
  line-height: 1.25rem;
  color: ${props => props.theme.TextSub};
  font-weight: 500;
`;
const EmptyFriendSubtitle = styled.h2`
  font-size: 0.75rem;
  color: ${props => props.theme.TextSub2};
  font-weight: 400;
  margin-top: 0.375rem;
  line-height: 1.125rem;
  letter-spacing: 0;
`;

import React, { useState, useCallback } from 'react';
import { useTranslation } from 'react-i18next';
import { Toast } from 'teespace-core';
import { Button } from 'antd';
import styled from 'styled-components';

const AccountItemModal = React.forwardRef(
  (
    {
      title,
      subtitle,
      smalltext,
      value = '-',
      changeable = false,
      open = false,
      onChangeOpen = () => undefined,
      component: Component = null,
      getPopupContainer = () => undefined,
      children,
      copyable = false,
    },
    ref,
  ) => {
    const { t } = useTranslation('common');
    const [visibleToast, setVisibleToast] = useState(false);

    const handleShowForm = useCallback(
      () => onChangeOpen(true),
      [onChangeOpen],
    );
    const handleShowOriginal = useCallback(
      () => onChangeOpen(false),
      [onChangeOpen],
    );
    const handleShowToast = useCallback(() => {
      setVisibleToast(true);
      onChangeOpen(false);
    }, [onChangeOpen]);
    const handleCloseToast = useCallback(() => setVisibleToast(false), []);

    const handleInviteCodeCopy = useCallback(() => {
      const el = document.createElement('textarea');
      el.value = value;
      document.body.appendChild(el);
      el.select();
      document.execCommand('copy');
      document.body.removeChild(el);
      handleShowToast();
    }, []);

    return (
      <>
        {(changeable || copyable) && (
          <Toast visible={visibleToast} onClose={handleCloseToast}>
            {changeable && t('CM_CHANGE_SAVE')}
            {copyable && t('CM_EDIT_MYPAGE_10')}
          </Toast>
        )}

        <AccountBox ref={ref}>
          {open && (
            <Component
              onCancel={handleShowOriginal}
              onSave={handleShowToast}
              defaultValue={value}
              getPopupContainer={getPopupContainer}
            />
          )}
          {!open && (
            <AccountWrapper>
              <AccountTitleValueBox>
                <AccountItemTitle>{title}</AccountItemTitle>
                {subtitle && (
                  <AccountItemSubtitle>{subtitle}</AccountItemSubtitle>
                )}
                {smalltext && (
                  <AccountItemSmalltext>{smalltext}</AccountItemSmalltext>
                )}
                <AccountItemValue>{value}</AccountItemValue>
                {children}
              </AccountTitleValueBox>
              {changeable && (
                <ChangeButton type="system" onClick={handleShowForm}>
                  {t('CM_CHANGE')}
                </ChangeButton>
              )}
              {copyable && (
                <CopyButton
                  type="system"
                  value={value}
                  onClick={handleInviteCodeCopy}
                >
                  {t('CM_COPY')}
                </CopyButton>
              )}
            </AccountWrapper>
          )}
        </AccountBox>
      </>
    );
  },
);

export default AccountItemModal;

const AccountBox = styled.div`
  width: 100%;
  margin-top: 0.5rem;
`;

const AccountWrapper = styled.div`
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
`;

const AccountTitleValueBox = styled.div`
  display: flex;
  flex-direction: row;
  flex: 1;
  flex-grow: 1;
  align-items: center;
`;

const AccountItemTitle = styled.div`
  font-size: 0.813rem;
  line-height: 1.188rem;
  min-width: 13.063rem;
  max-width: 13.063rem;
  color: ${props => props.theme.TextSub};
`;

const AccountItemSubtitle = styled.div`
  font-size: 0.813rem;
  line-height: 1.188rem;
  font-weight: 500;
  color: ${props => props.theme.TextMain};
`;

const AccountItemSmalltext = styled.div`
  font-size: 0.75rem;
  font-weight: normal;
  color: ${props => props.theme.TextSub2};
`;

const AccountItemValue = styled.div`
  font-size: 0.813rem;
  line-height: 1.188rem;
  font-weight: 500;
  color: ${props => props.theme.TextMain};
`;

const ChangeButton = styled(Button)`
  min-width: 0 !important;
  padding-left: 0.84375rem !important;
  padding-right: 0.84375rem !important;
  padding: 0;
  margin-right: 8.563rem;
`;
const CopyButton = styled(Button)`
  min-width: 0 !important;
  padding-left: 0.84375rem !important;
  padding-right: 0.84375rem !important;
  padding: 0;
  margin-right: 8.563rem;
`;

import React, { useState, useEffect } from 'react';
import styled from 'styled-components';
import { Loader, useCoreStores } from 'teespace-core';
import { useParams } from 'react-router-dom';
import Cookies from 'js-cookie';
import { useStores } from '../stores';
import MaxUserSpaceContent from '../components/space/MaxUserSpaceContent';
import JoinExpiredLinkSpaceContent from '../components/space/ExpiredSpaceLinkContent';
import JoinInvitedNotLoggedInSpaceContent from '../components/space/InvitedSpaceNotLoggedInContent';
import InvalidSpaceUrlContent from '../components/space/InvalidSpaceUrlContent';
import TermsFooter from '../components/TermsFooter';

function InviteLinkPage() {
  const [isValid, setIsValid] = useState(false);
  const [spaceInfo, setSpaceInfo] = useState(null);
  const [inviterName, setInviterName] = useState('');
  const [senderId, setSenderId] = useState('');
  const [senderLoginId, setSenderLoginId] = useState('');
  const [loader] = Loader.useLoader();

  const { inviteId } = useParams();
  const { spaceStore } = useStores();
  const { waplUserStore } = useCoreStores();

  useEffect(() => {
    loader.loading();
    const checkInvitationAndSpaceInfo = async () => {
      const { success, userLoginId, userName, userId } =
        await waplUserStore.validateLink({
          inviteId,
        });
      const domainKey = (() => {
        try {
          return inviteId.split('-')[0].toUpperCase();
        } catch (err) {
          return undefined;
        }
      })();
      const space = await spaceStore.searchSpaceByDomainKey({ domainKey });
      if (space) {
        setSpaceInfo(space);
      }
      if (Cookies.get('inviteId')) {
        Cookies.set('isInvitedUser', 'true');
      }
      Cookies.remove('inviteId');
      setIsValid(success);
      setSenderId(userId);
      setSenderLoginId(userLoginId);
      setInviterName(userName);
    };
    checkInvitationAndSpaceInfo().then(() => {
      loader.stop();
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [inviteId, loader, spaceStore, waplUserStore]);

  return (
    <Loader loader={loader}>
      <Wrapper>
        <Content>
          {isValid && spaceInfo && !spaceInfo.joinable && (
            <MaxUserSpaceContent />
          )}
          {isValid && spaceInfo && spaceInfo.joinable && (
            <JoinInvitedNotLoggedInSpaceContent
              inviterName={inviterName}
              inviteId={inviteId}
              senderId={senderId}
              senderLoginId={senderLoginId}
              spaceInfo={spaceInfo}
            />
          )}
          {!isValid && spaceInfo && <JoinExpiredLinkSpaceContent />}
          {!spaceInfo && <InvalidSpaceUrlContent />}
        </Content>
        <TermsFooter autoMarginTop />
      </Wrapper>
    </Loader>
  );
}

export default InviteLinkPage;

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  min-height: 100%;
`;
const Content = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 1.68125rem 0;
  flex-direction: column;
  flex-grow: 1;
`;

import React, { useCallback, useEffect, useState, useContext } from 'react';
import styled from 'styled-components';
import { useTranslation } from 'react-i18next';
import ServiceRepository from '../../stores/repositories/ServiceRepository';
import newsTitleImg from '../../assets/landing/news/main.png';
import arrowFront from '../../assets/landing/news/arrow_front_1_green.svg';
import { Context } from '../../pages/ContextPage';
import defaultImage from '../../assets/landing/news/symbol.svg';

const ServiceNews = props => {
  const { t, i18n } = useTranslation('service');
  const { refs, startNews, showNews } = props;
  const [feedList, setFeedList] = useState('');
  const newsWrapperRef = useContext(Context);

  useEffect(() => {
    if (startNews) {
      window.scrollTo({
        top:
          refs.newsRef.current.offsetTop - refs.headerRef.current.clientHeight,
        left: 0,
        behavior: 'smooth',
      });
    }
  }, [startNews, refs.newsRef, refs.headerRef]);

  useEffect(() => {
    if (showNews) {
      const getBlogLogic = async () => {
        const feedItems = await ServiceRepository.getBlogContents();
        setFeedList(feedItems);
      };
      getBlogLogic();
    }
  }, [showNews]);

  const onHandleLinkBlog = useCallback(() => {
    window.open(
      `https://blog.naver.com/prologue/PrologueList.nhn?blogId=wapl_ai&skinType=&skinId=&from=menu&userSelectMenu=true`,
    );
  }, []);

  if (i18n.language === 'en') {
    return null;
  }

  return (
    <ServiceNewsWrapper ref={refs.newsRef}>
      <ServiceNewsTitle src={newsTitleImg} ref={newsWrapperRef.loadBlogEvent} />
      <ServiceNewsList>
        {feedList &&
          feedList.map(item => <WaplNewsComponent {...item} key={item.url} />)}
      </ServiceNewsList>
      <ServiceNewsFooter onClick={onHandleLinkBlog}>
        {t(`SP_NEWS_04`)}
      </ServiceNewsFooter>
    </ServiceNewsWrapper>
  );
};

export default ServiceNews;

const WaplNewsComponent = ({
  url,
  title,
  description,
  image,
  extension = `png`,
}) => {
  const { t } = useTranslation('service');

  const onHandleCardClick = useCallback(() => {
    window.open(url);
  }, [url]);

  return (
    <WaplNewsWrapper onClick={onHandleCardClick}>
      {!image ? (
        <WaplNewsDefault>
          <DefaultImageWrapper src={defaultImage} />
        </WaplNewsDefault>
      ) : (
        <WaplNewsImage src={`data:image/${extension};base64,${image}`} />
      )}
      <WaplNewsTitle>{title}</WaplNewsTitle>
      <WaplNewsContent>
        {description.replace(/&quot;/g, '"').replace(/&#x27;/g, "'")}
      </WaplNewsContent>
      <WaplNewsDetail>
        {t(`SP_NEWS_03`)}
        <ArrowImg src={arrowFront} />
      </WaplNewsDetail>
    </WaplNewsWrapper>
  );
};

const ServiceNewsWrapper = styled.div`
  width: 100%;
  height: 58.125rem;
  background: #faf8f7;
  display: flex;
  flex-direction: column;
  align-items: center;
`;

const ServiceNewsFooter = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 1.125rem;
  color: #00493d;
  font-weight: 500;
  text-decoration: underline;
  cursor: pointer;
  margin-top: 3.25rem;
  margin-bottom: 5rem;
`;

const ServiceNewsList = styled.div`
  display: flex;
  flex-direction: row;
  width: auto;
  justify-content: space-between;
  margin-top: 4.375rem;
`;

const ServiceNewsTitle = styled.img`
  width: 21.5rem;
  height: 6.063rem;
  margin-top: 5rem;
`;

const WaplNewsWrapper = styled.div`
  display: flex;
  flex-direction: column;
  width: 20.625rem;
  height: 32.75rem;
  border-radius: 0.938rem;
  cursor: pointer;
  box-shadow: 0 1px 10px 0 rgba(0, 0, 0, 0.1);
  background: #fff;
  margin: 0 1.25rem;
`;

const WaplNewsImage = styled.img`
  border-top-left-radius: 0.938rem;
  border-top-right-radius: 0.938rem;
  width: 20.625rem;
  height: 20.625rem;
`;

const WaplNewsTitle = styled.div`
  display: inline-block;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  font-size: 1.25rem;
  font-weight: 500;
  letter-spacing: 0;
  color: #232d3b;
  padding-left: 1.875rem;
  padding-right: 1.875rem;
  padding-top: 1.875rem;
`;

const WaplNewsContent = styled.div`
  white-space: normal;
  line-height: 1.2;
  text-align: left;
  word-wrap: break-word;
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
  overflow: hidden;
  font-size: 1rem;
  color: #666666;
  font-weight: 300;
  padding-left: 1.875rem;
  padding-right: 1.875rem;
  padding-top: 1.125rem;
`;

const WaplNewsDetail = styled.div`
  font-weight: 500;
  font-size: 1.125rem;
  color: #00493d;
  padding-left: 1.875rem;
  padding-top: 1.25rem;
  display: flex;
  align-items: center;
  line-height: 5px;
`;

const ArrowImg = styled.img``;

const WaplNewsDefault = styled.div`
  border-top-left-radius: 0.938rem;
  border-top-right-radius: 0.938rem;
  width: 20.625rem;
  height: 20.625rem;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: #f7f4ef;
`;

const DefaultImageWrapper = styled.img`
  width: 4.375rem;
  height: 4.375rem;
`;

// @flow
import { observable, action, set } from 'mobx';
import autobind from 'autobind-decorator';

@autobind
class AlarmListModel {
  @observable
  roomId: string;

  @observable
  unreadSpaceCount: number;

  @observable
  type: string;

  constructor(data: Object) {
    this.setValues(data);
  }

  @action
  setValues(data: Object) {
    set(this, data);
  }

  @action
  setRoomId(data: string) {
    this.roomId = data;
  }

  @action
  setUnreadSpaceCount(data: number) {
    this.unreadSpaceCount = data;
  }

  @action
  setType(data: string) {
    this.type = data;
  }
}

export default AlarmListModel;

export const convertFromDtoItemToPlatformItem = (
  dtoItem: $Shape<AlarmListInfoDto>,
): ?$Shape<AlarmListInfo> => {
  if (dtoItem.WS_ID) {
    return {
      roomId: dtoItem.WS_ID,
    };
  }

  if (typeof dtoItem.UNREAD_SPACE_COUNT !== 'undefined') {
    return {
      unreadSpaceCount: dtoItem.UNREAD_SPACE_COUNT,
    };
  }

  if (dtoItem.WS_TYPE) {
    return {
      type: dtoItem.WS_TYPE,
    };
  }
  return undefined;
};

export const convertFromPlatformItemToDtoItem = (
  platformItem: $Shape<AlarmListInfo>,
): ?$Shape<AlarmListInfoDto> => {
  if (platformItem.roomId) {
    return {
      WS_ID: platformItem.roomId,
    };
  }
  if (typeof platformItem.unreadSpaceCount !== 'undefined') {
    return {
      UNREAD_SPACE_COUNT: platformItem.unreadSpaceCount,
    };
  }
  if (platformItem.type) {
    return {
      WS_TYPE: platformItem.type,
    };
  }
  return undefined;
};

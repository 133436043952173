import { observable } from 'mobx';
import EventRepo from './repositories/EventRepository';

const EventStore = observable({
  async joinEvent({ eventInfo }) {
    try {
      const res = await EventRepo.joinEvent({ eventInfo });
      return res;
    } catch (error) {
      throw Error(error);
    }
  },

  async getJoinedEvent({ eventInfo }) {
    try {
      const res = await EventRepo.getJoinedEvent({ eventInfo });
      return res;
    } catch (error) {
      throw Error(error);
    }
  },
});
export default EventStore;

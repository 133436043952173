import React from 'react';
import styled from 'styled-components';
import GoogleLogin from 'react-google-login';
import { useParams } from 'react-router-dom';
import axios from 'axios';
import { useTranslation } from 'react-i18next';

function GoogleMailPage() {
  const { i18n } = useTranslation('service');
  const { subDomain, accountId } = useParams();

  const googleClientId = window.env.GOOGLE_CLIENT_ID
    ? window.env.GOOGLE_CLIENT_ID
    : '496588556823-vejipl6i4pckt55tgeknnd1jdl67jqik.apps.googleusercontent.com'; // 실환경용
  // '410574833422-n0tvso08lq6n3r9co9r7av0ab8fo0a0u.apps.googleusercontent.com'; // 팀장님 googleAuth ClientId(운영)

  const handleFailureGoogle = data => {
    console.log('Fail data', data);
  };

  const postGoogleMailAPI = async googleCode => {
    const inputDTO = {
      dto: {
        id: googleCode,
        password: googleCode,
        connectionType: 'oauth',
        mailCount: '100',
        protocol: 'POP3',
      },
    };
    // 서비스 콜
    axios.defaults.withCredentials = true;
    await axios.post(
      // `/CMS/mail-api/accounts`,
      // `https://${subDomain}.teespace.com/mail-api/accounts`,
      `${window.location.protocol}//${subDomain}.${window.location.hostname}/${process.env.REACT_APP_SERVICE_PATH}/mail-api/accounts`,
      inputDTO,
    );
  };

  const putGoogleMailData = async (googleCode, targetAccountId) => {
    const inputDTO = {
      dto: {
        id: googleCode,
        password: googleCode,
        connectionType: 'oauth',
        protocol: 'POP3',
      },
    };
    // 서비스 콜
    axios.defaults.withCredentials = true;
    await axios.put(
      `${window.location.protocol}//${subDomain}.${window.location.hostname}/${process.env.REACT_APP_SERVICE_PATH}/mail-api/accounts/${targetAccountId}`,
      inputDTO,
    );
  };
  const postSyncPop3API = async targetAccountId => {
    const inputDTO = {
      dto: {
        requestType: 'make',
        mailCount: '100',
      },
    };
    await axios.post(
      `/CMS/mail-api/accountId/${targetAccountId}/sync-pop3`,
      inputDTO,
    );
  };

  const handleSuccessGoogle = async data => {
    // data.code : 구글 로그인 code
    console.log('login Success', data.code);

    try {
      if (accountId) {
        const response = putGoogleMailData(data.code, accountId);
        console.log('google-login account modified success:', response);
      } else {
        const response = postGoogleMailAPI(data.code);
        console.log('google-login account register success:', response);
        if (response.status === 200 || response.status === 201) {
          const targetAccountId = response.data?.dto?.accountId;
          postSyncPop3API(targetAccountId);
        }
      }
    } catch (e) {
      console.log(e.response);
    }
  };

  return (
    <Wrapper>
      {i18n.language === 'en' && (
        <GoogleLogin
          clientId={googleClientId}
          buttonText="Sync with Gmail"
          accessType="offline"
          responseType="code"
          onSuccess={handleSuccessGoogle}
          onFiliure={handleFailureGoogle}
          ookiePolicy="single_host_origin"
          scope="https://mail.google.com"
          prompt="consent"
          redirectUri={`${window.location.protocol}//${window.location.hostname}`}
          // redirectUri="http://localhost:3000/login"
        />
      )}
      {i18n.language === 'ko' && (
        <GoogleLogin
          clientId={googleClientId}
          buttonText="구글 메일 연동"
          accessType="offline"
          responseType="code"
          onSuccess={handleSuccessGoogle}
          onFiliure={handleFailureGoogle}
          ookiePolicy="single_host_origin"
          scope="https://mail.google.com"
          prompt="consent"
          redirectUri={`${window.location.protocol}//${window.location.hostname}`}
          // redirectUri="http://localhost:3000/login"
        />
      )}
    </Wrapper>
  );
}
export default GoogleMailPage;

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  min-height: 100%;

  & > button {
    & div {
      margin-right: 0px !important;
      padding: 5px 10px 0 10px !important;
    }
    & span {
      padding: 5px 0px 5px 0px !important;
    }
  }
`;

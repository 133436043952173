import React from 'react';
import styled from 'styled-components';
import WorkspaceHeader from '../components/space/SpaceHeader';
import WorkspaceCreate from '../components/space/CreateSpaceContent';
import TermsFooter from '../components/TermsFooter';

function SpaceCreatePage() {
  return (
    <Wrapper>
      <WorkspaceHeader />
      <Content>
        <WorkspaceCreate />
      </Content>
      <TermsFooter />
    </Wrapper>
  );
}

export default SpaceCreatePage;

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  min-height: 100%;
`;
const Content = styled.div`
  display: flex;
  align-items: center;
  justify-content: flex-start;
  flex-direction: column;
  flex-grow: 1;
`;

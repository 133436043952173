import React, { useState, useCallback, useEffect } from 'react';
import styled from 'styled-components';
import { useCoreStores, logEvent } from 'teespace-core';
import { Button, Checkbox } from 'antd';
import { DownOutlined, UpOutlined } from '@ant-design/icons';
import { useHistory } from 'react-router-dom';
import { useTranslation } from 'react-i18next';

const AuthFirstLoginContent = () => {
  const { waplAuthStore } = useCoreStores();
  const { t } = useTranslation('service', 'common');
  const history = useHistory();
  const CheckboxGroup = Checkbox.Group;
  const plainOptions = [
    'check_1',
    'check_2',
    'check_3',
    'check_4',
    'check_5',
    'check_6',
    'check_7',
  ];

  const query = new URLSearchParams(window.location.search);
  const redirectUri = query.get('redirect_uri');

  useEffect(() => {
    if (waplAuthStore.sessionInfo?.isHyperAuthTermAgree === 'Y') {
      if (redirectUri) {
        history.replace(decodeURIComponent(redirectUri));
      } else {
        history.replace('/spaces');
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  // Checkbox
  const [checkedList, setCheckedList] = useState('');
  const [checkAll, setCheckAll] = useState(
    checkedList.length === plainOptions.length,
  );
  // Accordian Tab
  const [clickAuth2, setClickAuth2] = useState(false);
  const [clickAuth3, setClickAuth3] = useState(false);
  const [clickAuth4, setClickAuth4] = useState(false);
  const [clickAuth5, setClickAuth5] = useState(false);
  const [clickAuth6, setClickAuth6] = useState(false);

  const onHandleAccordionAuth2_Click = useCallback(() => {
    setClickAuth2(!clickAuth2);
  }, [clickAuth2]);

  const onHandleAccordionAuth3_Click = useCallback(() => {
    setClickAuth3(!clickAuth3);
  }, [clickAuth3]);

  const onHandleAccordionAuth4_Click = useCallback(() => {
    setClickAuth4(!clickAuth4);
  }, [clickAuth4]);

  const onHandleAccordionAuth5_Click = useCallback(() => {
    setClickAuth5(!clickAuth5);
  }, [clickAuth5]);

  const onHandleAccordionAuth6_Click = useCallback(() => {
    setClickAuth6(!clickAuth6);
  }, [clickAuth6]);

  const onCheckAllChange = e => {
    setCheckedList(e.target.checked ? plainOptions : []);
    setCheckAll(e.target.checked);
  };
  const onChange = checkedList => {
    setCheckedList(checkedList);
    checkedList.length === plainOptions.length
      ? setCheckAll(true)
      : setCheckAll(false);
  };

  const onHandleAgreeClick = async () => {
    let isTermAdvertiseAgree;
    if (checkedList.includes('check_7')) {
      isTermAdvertiseAgree = 'Y';
    } else {
      isTermAdvertiseAgree = 'N';
    }
    // 서비스
    const success = await waplAuthStore.authUpdateTerms({
      isTermAdvertiseAgree,
    });
    console.log(success);
    if (success) {
      // logEvent('login', 'clickServiceAgreementBtn');
      history.replace(`/first-login?redirect_uri=${redirectUri}`, {
        tutorial: true,
      });
    }
  };

  return (
    <>
      <Content>
        <ContentHeader>{t(`service:SP_AUTH_AGREE_TITLE`)}</ContentHeader>
        <Text>{t(`service:SP_AUTH_AGREE_SUB`)}</Text>

        <CheckboxWrapper>
          <CheckAll>
            <Checkbox checked={checkAll} onChange={onCheckAllChange}>
              {t(`service:SP_AUTH_AGREE_CHECK_01`)}
            </Checkbox>
          </CheckAll>

          <CheckboxGroup value={checkedList} onChange={onChange}>
            <NoAccordian>
              <Checkbox value="check_1">
                {t(`service:SP_AUTH_AGREE_CHECK_02`)}
                <TextRed>{t(`common:CM_SERVICE_AREE_CHECKBOX_03`)}</TextRed>
              </Checkbox>
            </NoAccordian>
            <Accordion>
              <Checkbox value="check_2">
                {t(`service:SP_AUTH_AGREE_CHECK_03`)}
                <TextRed>{t(`common:CM_SERVICE_AREE_CHECKBOX_03`)}</TextRed>
              </Checkbox>
              <AccordianSpan>
                {clickAuth2 ? (
                  <UpOutlined onClick={onHandleAccordionAuth2_Click} />
                ) : (
                  <DownOutlined onClick={onHandleAccordionAuth2_Click} />
                )}
              </AccordianSpan>
            </Accordion>
            {clickAuth2 && (
              <AuthDiv>
                <TermContent>
                  <Title>{t(`service:SP_AUTH_AGREE_CHECK_03`)}</Title>
                  <PreTag>{t(`service:SP_AUTH_AGREE_CHECK_03_01`)}</PreTag>
                </TermContent>
              </AuthDiv>
            )}
            <Accordion>
              <Checkbox value="check_3">
                {t(`service:SP_AUTH_AGREE_CHECK_04`)}
                <TextRed>{t(`common:CM_SERVICE_AREE_CHECKBOX_03`)}</TextRed>
              </Checkbox>
              <AccordianSpan>
                {clickAuth3 ? (
                  <UpOutlined onClick={onHandleAccordionAuth3_Click} />
                ) : (
                  <DownOutlined onClick={onHandleAccordionAuth3_Click} />
                )}
              </AccordianSpan>
            </Accordion>
            {clickAuth3 && (
              <AuthDiv>
                <TermContent>
                  <PreTag>{t(`service:SP_AUTH_AGREE_CHECK_04_01`)}</PreTag>
                </TermContent>
              </AuthDiv>
            )}
            <Accordion>
              <Checkbox value="check_4">
                {t(`service:SP_AUTH_AGREE_CHECK_05`)}
                <TextRed>{t(`common:CM_SERVICE_AREE_CHECKBOX_03`)}</TextRed>
              </Checkbox>
              <AccordianSpan>
                {clickAuth4 ? (
                  <UpOutlined onClick={onHandleAccordionAuth4_Click} />
                ) : (
                  <DownOutlined onClick={onHandleAccordionAuth4_Click} />
                )}
              </AccordianSpan>
            </Accordion>
            {clickAuth4 && (
              <AuthDiv>
                <TermContent>
                  <PreTag>{t(`service:SP_AUTH_AGREE_CHECK_05_01`)}</PreTag>
                </TermContent>
              </AuthDiv>
            )}
            <Accordion>
              <Checkbox value="check_5">
                {t(`service:SP_AUTH_AGREE_CHECK_06`)}
                <TextRed>{t(`common:CM_SERVICE_AREE_CHECKBOX_03`)}</TextRed>
              </Checkbox>
              <AccordianSpan>
                {clickAuth5 ? (
                  <UpOutlined onClick={onHandleAccordionAuth5_Click} />
                ) : (
                  <DownOutlined onClick={onHandleAccordionAuth5_Click} />
                )}
              </AccordianSpan>
            </Accordion>
            {clickAuth5 && (
              <AuthDiv>
                <TermContent>
                  <Title>{t(`service:SP_AUTH_AGREE_CHECK_06_01`)}</Title>
                  <MainText>{t(`service:SP_AUTH_AGREE_CHECK_06_02`)}</MainText>
                  <SubChildTitle>
                    {t(`service:SP_AUTH_AGREE_CHECK_06_03`)}
                  </SubChildTitle>
                  <TableAgree>
                    <colgroup>
                      <col />
                      <col />
                      <col />
                    </colgroup>
                    <thead>
                      <tr>
                        <TableTh>
                          {t(`service:SP_AUTH_AGREE_CHECK_06_04`)}
                        </TableTh>
                        <TableTh>
                          {t(`service:SP_AUTH_AGREE_CHECK_06_05`)}
                        </TableTh>
                      </tr>
                    </thead>
                    <tbody>
                      <tr>
                        <TableTd>
                          {t(`service:SP_AUTH_AGREE_CHECK_06_06`)}
                        </TableTd>
                        <TableTd>
                          {t(`service:SP_AUTH_AGREE_CHECK_06_07`)}
                        </TableTd>
                      </tr>
                      <tr>
                        <TableTd>
                          {t(`service:SP_AUTH_AGREE_CHECK_06_08`)}
                        </TableTd>
                        <TableTd>
                          {t(`service:SP_AUTH_AGREE_CHECK_06_09`)}
                        </TableTd>
                      </tr>
                      <tr>
                        <TableTd>
                          {t(`service:SP_AUTH_AGREE_CHECK_06_20`)}
                        </TableTd>
                        <TableTd>
                          {t(`service:SP_AUTH_AGREE_CHECK_06_21`)}
                        </TableTd>
                      </tr>
                    </tbody>
                  </TableAgree>
                  <TableAgree>
                    <thead>
                      <tr>
                        <TableTh>
                          {t(`service:SP_AUTH_AGREE_CHECK_06_10`)}
                        </TableTh>
                        <TableTh>
                          {t(`service:SP_AUTH_AGREE_CHECK_06_11`)}
                        </TableTh>
                        <TableTh>
                          {t(`service:SP_AUTH_AGREE_CHECK_06_12`)}
                        </TableTh>
                      </tr>
                    </thead>
                    <tbody>
                      <tr>
                        <TableTd>
                          {t(`service:SP_AUTH_AGREE_CHECK_06_13`)}
                        </TableTd>
                        <TableTd>
                          {t(`service:SP_AUTH_AGREE_CHECK_06_14`)}
                        </TableTd>
                        <TableTd>
                          {t(`service:SP_AUTH_AGREE_CHECK_06_15`)}
                        </TableTd>
                      </tr>
                    </tbody>
                  </TableAgree>
                  <SubChildTitle>
                    {t(`service:SP_AUTH_AGREE_CHECK_06_16`)}
                  </SubChildTitle>
                  <SubChildTitle>
                    {t(`service:SP_AUTH_AGREE_CHECK_06_17`)}{' '}
                    <a href="https://www.tmaxcloud.com/term/TmaxOneAccount_Privacy_Policy.pdf">
                      {t(`service:SP_AUTH_AGREE_CHECK_06_18`)}
                    </a>
                    {t(`service:SP_AUTH_AGREE_CHECK_06_19`)}
                  </SubChildTitle>
                </TermContent>
              </AuthDiv>
            )}
            <Accordion>
              <Checkbox value="check_6">
                {t(`service:SP_AUTH_AGREE_CHECK_07`)}{' '}
                <TextRed>{t(`common:CM_SERVICE_AREE_CHECKBOX_03`)}</TextRed>
              </Checkbox>
              <AccordianSpan>
                {clickAuth6 ? (
                  <UpOutlined onClick={onHandleAccordionAuth6_Click} />
                ) : (
                  <DownOutlined onClick={onHandleAccordionAuth6_Click} />
                )}
              </AccordianSpan>
            </Accordion>
            {clickAuth6 && (
              <AuthDiv>
                <TermContent>
                  <Title>{t(`service:SP_AUTH_AGREE_CHECK_07_01`)}</Title>
                  <MainText>{t(`service:SP_AUTH_AGREE_CHECK_07_02`)}</MainText>
                  <br />
                  <MainText>{t(`service:SP_AUTH_AGREE_CHECK_07_03`)}</MainText>
                  <TableAgree>
                    <thead>
                      <tr>
                        <TableTh>
                          {t(`service:SP_AUTH_AGREE_CHECK_07_04`)}
                        </TableTh>
                        <TableTh>
                          {t(`service:SP_AUTH_AGREE_CHECK_07_05`)}
                        </TableTh>
                        <TableTh>
                          {t(`service:SP_AUTH_AGREE_CHECK_07_06`)}
                        </TableTh>
                        <TableTh>
                          {t(`service:SP_AUTH_AGREE_CHECK_07_07`)}
                        </TableTh>
                      </tr>
                    </thead>
                    <tbody>
                      <tr>
                        <TableTd>
                          {t(`service:SP_AUTH_AGREE_CHECK_07_08`)}
                        </TableTd>
                        <TableTd>
                          <MainText>
                            {t(`service:SP_AUTH_AGREE_CHECK_07_09`)}
                          </MainText>
                          <MainText>
                            {t(`service:SP_AUTH_AGREE_CHECK_07_10`)}
                          </MainText>
                          <MainText>
                            {t(`service:SP_AUTH_AGREE_CHECK_07_11`)}
                          </MainText>
                          <MainText>
                            {t(`service:SP_AUTH_AGREE_CHECK_07_12`)}
                          </MainText>
                          <MainText>
                            {t(`service:SP_AUTH_AGREE_CHECK_07_13`)}
                          </MainText>
                        </TableTd>
                        <TableTd>
                          {t(`service:SP_AUTH_AGREE_CHECK_07_14`)}
                        </TableTd>
                        <TableTd>
                          {t(`service:SP_AUTH_AGREE_CHECK_07_15`)}
                        </TableTd>
                      </tr>
                    </tbody>
                  </TableAgree>
                  <br />
                  <MainText>{t(`service:SP_AUTH_AGREE_CHECK_07_16`)}</MainText>
                  <MainText>{t(`service:SP_AUTH_AGREE_CHECK_07_17`)}</MainText>
                  <MainText>{t(`service:SP_AUTH_AGREE_CHECK_07_18`)}</MainText>
                  <br />
                </TermContent>
              </AuthDiv>
            )}
            <AdvertiseAccordian>
              <Checkbox value="check_7">
                {t(`service:SP_AUTH_AGREE_CHECK_08`)}{' '}
                <TextBlue>{t(`common:CM_SELECT`)}</TextBlue>
              </Checkbox>
            </AdvertiseAccordian>
          </CheckboxGroup>
        </CheckboxWrapper>
        <ButtonWrapper>
          <Buttons
            type="solid"
            disabled={
              !(
                checkedList.includes('check_1') &&
                checkedList.includes('check_2') &&
                checkedList.includes('check_3') &&
                checkedList.includes('check_4') &&
                checkedList.includes('check_5') &&
                checkedList.includes('check_6')
              )
            }
            onClick={onHandleAgreeClick}
          >
            {t(`common:CM_AGREE_BUTTON_03`)}
          </Buttons>
        </ButtonWrapper>
      </Content>
    </>
  );
};

export default AuthFirstLoginContent;

const Content = styled.div`
  display: flex;
  align-items: center;
  flex-direction: column;
  background: ${props => props.theme.StateNormal};
  border: 1px solid #e1e1e2;
  border-radius: 15px;
  border-radius: 15px;
  padding: 2.5rem 3.81rem 3.13rem 3.75rem;
  width: 28.13rem;
`;

const ContentHeader = styled.div`
  font-size: 1.5rem;
  color: ${props => props.theme.TextMain};
  font-weight: bold;
  margin-bottom: 2.5rem;
  text-align: center;
`;

const Text = styled.div`
  margin-bottom: 0.56rem;
  font-size: 0.81rem;
  color: ${props => props.theme.TextSub};
`;

const TextRed = styled.span`
  color: #ff4646;
`;

const TextBlue = styled.span`
  color: #042a54;
`;

const CheckAll = styled.div`
  border-bottom: 1px solid #d3d3d3;
  padding-bottom: 1.25rem;
`;

const CheckboxWrapper = styled.div`
  display: flex;
  width: 100%;
  flex-direction: column;
  color: ${props => props.theme.TextMain};
  font-size: 0.88rem;
`;

const Accordion = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  margin-top: 1.55rem;
  justify-content: space-between;
  width: 100%;
`;

const NoAccordian = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  margin-top: 1.25rem;
  justify-content: space-between;
  width: 100%;
`;

const AdvertiseAccordian = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  margin-top: 1.55rem;
  justify-content: space-between;
  width: 100%;
`;

const AccordianSpan = styled.span`
  cursor: pointer;
  color: #696969;
`;

const ButtonWrapper = styled.div`
  display: flex;
  flex-direction: row;
  width: 100%;
  justify-content: center;
  margin-top: 1.88rem;
`;

const Buttons = styled(Button)`
  width: 10rem;
  height: 1.88rem;
`;

const AuthDiv = styled.div`
  // background: #f4f5f5;
  // border: 1px solid #d3d3d3;
  // width: 20.75rem;
  // height: 6.63rem;
  // font-size: 0.75rem;
  // color: #333333;
  // letter-spacing: 0;
  // line-height: 1.25rem;
  // margin-top: 0.63rem;
  margin-top: 10px;
  max-width: 380px;
  height: 200px;
  width: 100%;
  border: 1px solid #d3d3d3;
  background: ${props => props.theme.StateNormal};
  padding: 7px 0px 7px 7px;
  resize: none;
  overflow-y: scroll;
  // font-size: 12px;
`;

const TermContent = styled.div`
  text-align: left;
  font-weight: 300;
  color: ${props => props.theme.TextMain};
  letter-spacing: 0;
  font-size: 12px;
`;

const Title = styled.h1`
  font-weight: 500;
  line-height: 1.1;
  margin: 0.67em 0;
  color: ${props => props.theme.TextMain};
  font-size: 13px;
`;

const SubChildTitle = styled.h6`
  font-size: 11px;
  margin-top: 10px;
  margin-bottom: 10px;
  color: ${props => props.theme.TextMain};
`;

const MainText = styled.p`
  color: ${props => props.theme.TextMain};
  margin-bottom: 10px;
`;

const TableAgree = styled.table`
  width: 100%;
  margin-top: 15px;
  table-layout: auto;
  border-collapse: collapse;
  border-spacing: 0;
  border-bottom: 1px solid #ccc;
  background-color: transparent;
`;

const TableTh = styled.th`
  height: 50px;
  padding: 11px 17px;
  font-size: 12px;
  text-align: center;
  border: solid #ccc;
  border-width: 1px 0 0 1px;
  background-color: ${props => props.theme.LineSub};

  &:first-child {
    border-left: 0 none;
  }
`;

const TableTd = styled.td`
  padding: 15px 10px;
  border: solid #ccc;
  border-width: 1px 0 0 1px;
  font-size: 10px;
  vertical-align: top;
  text-align: left;
  word-spacing: -1px;
  line-height: 111%;

  &:first-child {
    border-left: 0 none;
  }
`;

const PreTag = styled.pre`
  white-space: pre-line;
`;

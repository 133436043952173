import React, { useEffect } from 'react';
import { useHistory } from 'react-router-dom';
import { useStores } from '../../stores';

function WithdrawalWrapper({ children }) {
  const { spaceStore } = useStores();
  const history = useHistory();
  useEffect(() => {
    if (!spaceStore.checkWithdrawalPossible() || spaceStore.hasAdminSpace()) {
      history.replace('/withdrawal/select-type?limit=true');
    }
  }, [history, spaceStore]);

  return children;
}

export default function WithdrawalHOC(Component) {
  return () => (
    <WithdrawalWrapper>
      <Component />
    </WithdrawalWrapper>
  );
}

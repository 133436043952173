/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from 'react';
import { useObserver } from 'mobx-react';
import { Message } from 'teespace-core';
import styled from 'styled-components';
import { useHistory } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import WithdrawalTypeItem from './WithdrawalTypeItem';

import { useStores } from '../../stores';

function WithdrawalTypeSelectContent() {
  const history = useHistory();
  const { spaceStore } = useStores();
  const [adminWarningVisible, setAdminWarningVisible] = useState(false);
  const [premiumWarningVisible, setPremiumWarningVisible] = useState(false);

  const { t } = useTranslation('common');
  const query = new URLSearchParams(window.location.search);
  const limit = query.get('limit');

  useEffect(() => {
    if (limit) {
      history.replace('/withdrawal/select-type');
      setAdminWarningVisible(true);
    }
  }, []);

  const handleServiceWithdrawal = () => {
    if (spaceStore.hasAdminSpace()) {
      setAdminWarningVisible(true);
      return;
    }
    if (spaceStore.hasPremiumSpace()) {
      setPremiumWarningVisible(true);
      return;
    }
    history.push('/withdrawal/terms');
  };

  const handleMessageClose = () => {
    setAdminWarningVisible(false);
    setPremiumWarningVisible(false);
  };

  const handleHyperAuthWithdrawl = () => {
    if (spaceStore.hasAdminSpace()) {
      setAdminWarningVisible(true);
      return;
    }
    if (spaceStore.hasPremiumSpace()) {
      setPremiumWarningVisible(true);
      return;
    }
    window.location.href =
      window.env.REACT_APP_HYPERAUTH_WITHDRAWAL_URL ===
      '%HYPERAUTH_WITHDRAWAL_URL%'
        ? process.env.REACT_APP_HYPERAUTH_WITHDRAWAL_URL
        : window.env.REACT_APP_HYPERAUTH_WITHDRAWAL_URL ||
          process.env.REACT_APP_HYPERAUTH_WITHDRAWAL_URL;
  };

  return useObserver(() => (
    <Content>
      <Message
        visible={adminWarningVisible}
        title={t('CM_SELECT_DELETE_OPTION_05')}
        btns={[
          {
            type: 'solid',
            text: t('CM_LOGIN_POLICY_03'),
            onClick: handleMessageClose,
          },
        ]}
      />
      <Message
        visible={premiumWarningVisible}
        title={t('CM_SELECT_DELETE_OPTION_06')}
        btns={[
          {
            type: 'solid',
            text: t('CM_LOGIN_POLICY_03'),
            onClick: handleMessageClose,
          },
        ]}
      />
      <WithdrawalTypeTitle>
        {t('CM_SELECT_DELETE_OPTION_01')}
      </WithdrawalTypeTitle>
      <WithdrawalTypeWrapper>
        <WithdrawalTypeItem
          onClick={handleServiceWithdrawal}
          typeName={t('CM_CANCEL_SERVICE')}
          description={t('CM_SELECT_DELETE_OPTION_02')}
          disabled={
            !spaceStore.checkWithdrawalPossible() &&
            !spaceStore.hasAdminSpace() &&
            !spaceStore.hasPremiumSpace()
          }
        />
        <WithdrawalTypeItem
          onClick={handleHyperAuthWithdrawl}
          typeName={t('CM_SELECT_DELETE_OPTION_03')}
          description={t('CM_SELECT_DELETE_OPTION_04')}
          disabled={
            !spaceStore.checkWithdrawalPossible() &&
            !spaceStore.hasAdminSpace() &&
            !spaceStore.hasPremiumSpace()
          }
        />
      </WithdrawalTypeWrapper>
    </Content>
  ));
}

export default WithdrawalTypeSelectContent;

const Content = styled.div`
  margin-top: 6.1875rem;
  padding-bottom: 1.68125rem;
`;

const WithdrawalTypeTitle = styled.div`
  font-weight: 500;
  margin-bottom: 0.125rem;
  margin: 0 0 7.375rem 0;
  font-size: 1.5rem;
  line-height: 2.25rem;
  text-align: center;
  color: ${props => props.theme.TextMain};
`;

const WithdrawalTypeWrapper = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: center;
`;

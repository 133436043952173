import React, { useCallback } from 'react';
import { useTranslation } from 'react-i18next';
import styled from 'styled-components';
import { Button } from 'antd';
import WorkspaceItem from './SpaceItem';
import WorkspaceInvitationItem from './InvitationItem';
import { getDomain } from '../../libs/domain';

function AlreadyJoinedSpaceContent({ spaceInfo }) {
  const { t } = useTranslation('common');
  const handleSpaceMove = useCallback(() => {
    window.location.href = getDomain(spaceInfo.domain);
  }, [spaceInfo.domain]);
  return (
    <>
      <Content>
        <WorkspaceInvitationItem title={t('CM_INVITE_PEOPLE_MAIL_LINK_07')}>
          <SpaceWrapper>
            <WorkspaceItem
              workspace={spaceInfo}
              displayName={t('CM_INVITE_PEOPLE_MAIL_LINK_01', {
                value: spaceInfo.name,
              })}
              readOnly
            />
          </SpaceWrapper>
          <MoveToSpaceButton
            type="solid"
            size="large"
            onClick={handleSpaceMove}
          >
            {t('CM_INVITE_PEOPLE_MAIL_LINK_08')}
          </MoveToSpaceButton>
        </WorkspaceInvitationItem>
      </Content>
    </>
  );
}

export default AlreadyJoinedSpaceContent;

const Content = styled.div`
  display: flex;
  flex-direction: column;
  margin: 2.8125rem 0;
  justify-content: center;
  align-items: center;
`;

const MoveToSpaceButton = styled(Button)`
  width: calc(100% - 1.375rem);
  margin-left: 0.6875rem;
  margin-right: 0.6875rem;
  margin-top: 2.25rem;
  margin-bottom: 0.75rem;
  border-radius: 0.5rem !important;
`;

const SpaceWrapper = styled.div`
  max-width: 100%;
  margin-top: 0.0625rem;
`;

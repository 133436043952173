import React, { useState, useCallback } from 'react';
import { useTranslation } from 'react-i18next';
import { useHistory } from 'react-router-dom';
import styled from 'styled-components';
import { Checkbox, Button } from 'antd';
import WithdrawalHOC from './WithdrawalHOC';

function WithdrawalTermsContent() {
  const [checked, setChecked] = useState(false);
  const history = useHistory();
  const { t } = useTranslation('common');
  const handleChangeChecked = useCallback(e => {
    setChecked(e.target.checked);
  }, []);
  return (
    <Content>
      <WithdrawalTitle>{t('CM_CANCEL_SERVICE')}</WithdrawalTitle>
      <WithdrawalSubtitle>{t('CM_DEL_WAPL_02')}</WithdrawalSubtitle>
      <WithdrawalDivider />
      <WithdrawalContent>
        <WithdrawalHeader>{t('CM_SETTING_DELETE_SPACE_04')}</WithdrawalHeader>
        <WithdrawalSubcontent>
          <ListHeader>{t('CM_DEL_WAPL_03')}</ListHeader>
          <ul>
            <ListItem>{t('CM_DEL_WAPL_04')}</ListItem>
            <ListItem>{t('CM_DEL_WAPL_05')}</ListItem>
          </ul>
          <WithdrawalTermsButtonArea>
            <a href="/term-and-conditions" target="new">
              <Button>{t('CM_TERMS_OF_USE')}</Button>
            </a>
            <a href="/privacy-policy" target="new">
              <Button>{t('CM_PRIVACY_POLICY')}</Button>
            </a>
          </WithdrawalTermsButtonArea>
          <ListHeader>{t('CM_DEL_WAPL_07')}</ListHeader>
          <ul>
            <ListItem>{t('CM_DEL_WAPL_08')}</ListItem>
            <ListItem>{t('CM_DEL_WAPL_09')}</ListItem>
          </ul>
        </WithdrawalSubcontent>
        <StyledCheckbox onChange={handleChangeChecked}>
          {t('CM_ALL_READ_AGREE_POLICY')}
        </StyledCheckbox>
      </WithdrawalContent>
      <Button
        type="solid"
        disabled={!checked}
        onClick={() => history.push('/withdrawal/confirm')}
      >
        {t('CM_SETTING_DELETE_SPACE_07')}
      </Button>
    </Content>
  );
}

export default WithdrawalHOC(WithdrawalTermsContent);

const Content = styled.div`
  padding-bottom: 1.68125rem;
  margin-top: 4.3125rem;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
`;
const WithdrawalTitle = styled.h1`
  font-size: 1.75rem;
  line-height: 2.5625rem;
  font-weight: 500;
  color: ${props => props.theme.TextMain};
`;

const WithdrawalSubtitle = styled.h2`
  font-weight: 400;
  font-size: 0.8125rem;
  color: ${props => props.theme.TextSub};
  line-height: 1.1875rem;
  margin-top: 0.375rem;
`;

const WithdrawalDivider = styled.hr`
  width: 41.875rem;
  border: none;
  height: 1px;
  background-color: ${props => props.theme.LineSub};
  color: ${props => props.theme.LineSub};
  margin: 1.25rem 0;
`;

const WithdrawalContent = styled.div`
  width: 41.875rem;
  margin-bottom: 3.125rem;
`;

const WithdrawalSubcontent = styled.div`
  margin-left: 1.25rem;
  margin-bottom: 1.25rem;
`;

const WithdrawalTermsButtonArea = styled.div`
  margin-top: 1.1875rem;
  a {
    :first-child {
      margin-left: 1.25rem;
    }
    :not(:first-child) {
      margin-left: 0.375rem;
    }
  }
  button {
    height: 1.6875rem;
    line-height: 1.075rem;
    padding: 0.3125rem 0.75rem;
  }
`;

const WithdrawalHeader = styled.h3`
  font-weight: 500;
  font-size: 0.75rem;
  line-height: 1.125rem;
  color: ${props => props.theme.TextMain};
`;

const ListHeader = styled.h4`
  font-weight: 500;
  font-size: 0.75rem;
  line-height: 1.125rem;
  margin-top: 1.25rem;
  margin-bottom: 0.375rem;
  color: ${props => props.theme.TextMain};
`;

const ListItem = styled.li`
  font-size: 0.75rem;
  color: ${props => props.theme.TextSub};
  line-height: 1.125rem;
  list-style-type: disc;
  white-space: pre-line;
  margin-left: 0.875rem;
  &:not(:first-child) {
    margin-top: 0.625rem;
  }
`;

const StyledCheckbox = styled(Checkbox)`
  font-weight: 500;
  font-size: 0.75rem;
  line-height: 1.125rem;
  color: ${props => props.theme.TextMain};
`;

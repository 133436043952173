import axios from 'axios';
import Config from '../config';

function ComAPI() {}

ComAPI.prototype = {
  $instance: null,
  $refreshTokenHandler: () => {
    return Promise.resolve(false);
  },

  get baseURL() {
    return Config.comURL;
  },

  set baseURL(url) {
    Config.setConfig({ comURL: url });
    this.$instance = null;
  },

  // token을 refresh하고 cookie에 set 하는 custom handler
  set refreshTokenHandler(handler) {
    this.$refreshTokenHandler = handler;
  },
  get refreshTokenHandler() {
    return this.$refreshTokenHandler;
  },
  /**
   * API 인스턴스
   * @return AxiosInstance
   */
  get instance() {
    if (this.$instance === null) {
      this.$instance = axios.create({
        baseURL: Config.comURL,
        withCredentials: true,
      });
      // this.$instance.interceptors.request.use(request => {
      //   console.log('req', request);
      //   return request;
      // });
      this.$instance.interceptors.response.use(
        response => {
          return response;
        },
        async error => {
          const originalReq = error.config;
          if (originalReq && !originalReq.retry) {
            const res = await this.refreshTokenHandler();
            originalReq.retry = true;
            return res ? this.$instance(originalReq) : Promise.reject(error);
          }
          return Promise.reject(error);
        },
      );
    }

    if (Config.cache === false) {
      this.$instance.defaults.params = {
        _ts: Date.now(),
      };
    }

    return this.$instance;
  },

  /**
   * HTTP Get 요청
   * @deprecated
   * @alias module:API
   * @param  {string} url
   * @param  {object} params
   * @return {Promise}
   */
  async Get(url, params) {
    return this.get(url, params);
  },
  /**
   * HTTP Post 요청
   * @deprecated
   * @alias module:API
   * @param  {string} url
   * @param  {object} params
   * @param  {object} config
   * @return {Promise}
   */
  async Post(url, params, config) {
    return this.post(url, params, config);
  },
  /**
   * HTTP Put 요청
   * @deprecated
   * @alias module:API
   * @param  {string} url
   * @param  {object} params
   * @param  {object} config
   * @return {Promise}
   */
  async Put(url, params, config) {
    return this.put(url, params, config);
  },
  /**
   * HTTP Delete 요청
   * @deprecated
   * @alias module:API
   * @param  {string} url
   * @param  {object} params
   * @return {Promise}
   */
  async Delete(url, params) {
    return this.delete(url, params);
  },

  /**
   * HTTP Get 요청
   * @alias module:API
   * @param  {string} url
   * @param  {object} params
   * @return {Promise}
   */
  async get(url, params) {
    return this.instance.get(url, params || undefined);
  },
  /**
   * HTTP Post 요청
   * @alias module:API
   * @param  {string} url
   * @param  {object} params
   * @param  {object} config
   * @return {Promise}
   */
  async post(url, params, config) {
    return this.instance.post(url, params || undefined, config || undefined);
  },
  /**
   * HTTP Put 요청
   * @alias module:API
   * @param  {string} url
   * @param  {object} params
   * @param  {object} config
   * @return {Promise}
   */
  async put(url, params, config) {
    return this.instance.put(url, params || undefined, config || undefined);
  },
  /**
   * HTTP Delete 요청
   * @alias module:API
   * @param  {string} url
   * @param  {object} params
   * @return {Promise}
   */
  async delete(url, params) {
    return this.instance.delete(url, params || undefined);
  },
};

/**
 * ComAPI 모듈
 * @module ComAPI
 */
export default new ComAPI();

export class ComApiResponse {
  status = null; /* fulfilled | rejected */

  data = null; /* fulfilled 상태 일 때 얻은 응답 데이터 */

  error = null; /* rejected 상태 일 때 설정된 오류 데이터 */

  constructor({ status = null, data = null, error = null }) {
    this.status = status;
    this.data = data;
    this.error = error;
  }
}

import React, { useEffect, useState, useCallback } from 'react';
import { useTranslation } from 'react-i18next';
import styled from 'styled-components';
import { useHistory } from 'react-router-dom';
import { useCoreStores, Loader, Message } from 'teespace-core';
import { Button } from 'antd';
import Cookies from 'js-cookie';
import { logout } from '../../libs/keycloak';
import WorkspaceItem from './SpaceItem';
import { useStores } from '../../stores';
import { getDomain } from '../../libs/domain';
import EllipsisableTitle from '../EllipsisableTitle';
import WorkspaceInvitationItem from './InvitationItem';

function InvitedSpaceLoggedInContent({
  inviteId,
  spaceInfo,
  senderId,
  senderLoginId,
  grade,
}) {
  const [isLoading, setIsLoading] = useState(false);
  const { spaceStore } = useStores();
  const { waplAuthStore, waplUserStore } = useCoreStores();
  const [errorMessageVisible, setErrorMessageVisible] = useState(false);
  const [loader] = Loader.useLoader();
  const history = useHistory();
  const { t, i18n } = useTranslation('common');

  const handleJoinSpace = useCallback(async () => {
    setIsLoading(true);
    try {
      await spaceStore.joinSpace({
        id: spaceInfo.id,
        domain: spaceInfo.domain,
        userName: waplUserStore.myProfile.name,
        userId: waplUserStore.myProfile.id,
        userLoginId: waplUserStore.myProfile.loginId,
        adminId: senderId,
        recommenderLoginId: senderLoginId,
        memGrade: grade,
        isInvitedUser: waplUserStore.myProfile.isInvitedUser,
      });
      Cookies.remove('isInvitedUser');
      window.location.href = getDomain(spaceInfo.domain);
    } catch (error) {
      try {
        const { message } = JSON.parse(error.message);
        if (message === 'over max users') {
          history.go(0);
        } else {
          setErrorMessageVisible(true);
          setIsLoading(false);
        }
      } catch (e) {
        setErrorMessageVisible(true);
        setIsLoading(false);
      }
    }
  }, [
    grade,
    history,
    senderId,
    senderLoginId,
    spaceInfo.domain,
    spaceInfo.id,
    spaceStore,
    waplUserStore.myProfile.id,
    waplUserStore.myProfile.isInvitedUser,
    waplUserStore.myProfile.loginId,
    waplUserStore.myProfile.name,
  ]);

  const handleLogout = useCallback(async () => {
    loader.loading();
    await waplAuthStore.logout({ deviceType: 'PC' });
    await logout({
      redirectUri: window.location.href,
      locale: i18n.language,
    });
    Cookies.set('inviteId', inviteId);
    Cookies.set('inviteType', 'join-space');
    loader.stop();
  }, [inviteId, loader, waplAuthStore]);

  useEffect(() => {
    loader.stop();
  }, [loader]);
  return (
    <Loader loader={loader}>
      <Message
        title={`오류가 발생하였습니다.\n다시 시도해 주세요.`}
        visible={errorMessageVisible}
        btns={[
          {
            type: 'solid',
            text: t('CM_LOGIN_POLICY_03'),
            onClick: () => {
              setErrorMessageVisible(false);
            },
          },
        ]}
      />
      <Content>
        <SpaceWrapper>
          <StyledWorkspaceItem
            workspace={spaceInfo}
            name={spaceInfo.name}
            readOnly
          />
        </SpaceWrapper>
        <WorkspaceInvitationItem title={t('CM_INVITE_PEOPLE_MAIL_LINK_02')}>
          <ProfileWrapper>
            <Profile src={waplUserStore.myProfile.defaultPhotoUrl} />
            <ProfileTextWrapper>
              <ProfileTitle title={waplUserStore.myProfile.loginId} />
              <ProfileSubtitle>
                {t('CM_INVITE_PEOPLE_MAIL_LINK_03')}
              </ProfileSubtitle>
            </ProfileTextWrapper>
          </ProfileWrapper>
          <JoinButton
            type="solid"
            size="large"
            loading={isLoading}
            onClick={handleJoinSpace}
          >
            {t('CM_INVITE_PEOPLE_MAIL_LINK_04')}
          </JoinButton>
          <ChangeAccountLink onClick={handleLogout}>
            {t('CM_INVITE_PEOPLE_MAIL_LINK_05')}
          </ChangeAccountLink>
        </WorkspaceInvitationItem>
      </Content>
    </Loader>
  );
}

export default InvitedSpaceLoggedInContent;

const Content = styled.div`
  width: 35rem;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
`;

const ProfileWrapper = styled.div`
  max-width: 100%;
  display: flex;
  flex-direction: row;
  margin-bottom: 2.25rem;
`;
const Profile = styled.img`
  align-self: flex-start;
  width: 3.75rem;
  height: 3.75rem;
  cursor: default;
`;

const ProfileTextWrapper = styled.div`
  overflow: hidden;
  margin-left: 1.25rem;
  flex: 1;
  display: flex;
  flex-direction: column;
  justify-content: center;
`;

const ProfileTitle = styled(EllipsisableTitle)`
  font-weight: 500;
  margin-bottom: 0.1875rem;
  font-size: 1rem;
  line-height: 1.5rem;
  color: ${props => props.theme.TextMain};
`;

const ProfileSubtitle = styled.h1`
  font-weight: 400;
  margin: 0;
  color: ${props => props.theme.TextSub};
  font-size: 1rem;
  line-height: 1.5rem;
`;

const JoinButton = styled(Button)`
  width: 100%;
  font-weight: 500;
  border-radius: 0.5rem !important;
`;

const ChangeAccountLink = styled.a`
  font-size: 0.875rem;
  line-height: 1.25rem;
  color: ${props => props.theme.MyHomeTextLink};
  text-decoration: underline;
  margin-top: 0.8125rem;
  font-weight: 500;
`;

const StyledWorkspaceItem = styled(WorkspaceItem)`
  margin-bottom: 2.625rem;
  .space-content {
    margin-left: 0;
  }
  .space-title {
    line-height: 1.8125rem;
    font-size: 1.25rem;
    font-weight: 500;
  }
  .space-domain {
    font-size: 1rem;
    line-height: 1.5rem;
    font-weight; 400;
  }
`;

const SpaceWrapper = styled.div`
  margin-top: -9.875rem;
  max-width: 100%;
  margin-bottom: 1.875rem;
`;

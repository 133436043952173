import React from 'react';
import styled from 'styled-components';
import { NoticeWrapper } from 'teespace-core';

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  min-height: 100%;
`;
const Content = styled.div`
  display: flex;
  align-items: center;
  flex-direction: column;
  justify-content: center;
  flex-grow: 1;
`;

function MaintenancePage() {
  console.log(window.notice.title);
  return (
    <Wrapper>
      <Content>
        <NoticeWrapper
          title={
            window.notice.title === '%UPDATE_NOTI_TITLE%'
              ? ''
              : window.notice.title
          }
          content={
            window.notice.contents === '%UPDATE_CONTENTS%'
              ? ''
              : window.notice.contents
          }
          themeNum={
            window.notice.theme === '%UPDATE_THEME_NUM%'
              ? '1'
              : window.notice.theme
          }
          closePopupTodayVisible={false}
          closeButtonVisible={false}
        />
      </Content>
    </Wrapper>
  );
}

export default MaintenancePage;

import React, { useCallback, useEffect, useState, useContext } from 'react';
import styled from 'styled-components';
import { useTranslation } from 'react-i18next';
import { Swiper, SwiperSlide } from 'swiper/react';
import SwiperCore, { Pagination } from 'swiper';
import ServiceRepository from '../../stores/repositories/ServiceRepository';
import newsTitleImg from '../../assets/landing/news/main.png';
import newsTitleImg_En from '../../assets/landingEn/news/main.png';
import arrowFront from '../../assets/landing/news/arrow_front_1_green.svg';
import { Context } from '../../pages/ContextPage';
import defaultImage from '../../assets/landing/news/symbol.svg';

SwiperCore.use([Pagination]);

const ServiceNewsSwiperContent = props => {
  const { t, i18n } = useTranslation('service');
  const { refs, startNews, showNews } = props;
  const [feedList, setFeedList] = useState('');
  const newsWrapperRef = useContext(Context);

  useEffect(() => {
    if (startNews) {
      window.scrollTo({
        top:
          refs.newsRef.current.offsetTop - refs.headerRef.current.clientHeight,
        left: 0,
        behavior: 'smooth',
      });
    }
  }, [startNews, refs.newsRef, refs.headerRef]);

  useEffect(() => {
    if (showNews) {
      const getBlogLogic = async () => {
        const feedItems = await ServiceRepository.getBlogContents();
        setFeedList(feedItems);
      };
      getBlogLogic();
    }
  }, [showNews]);

  const onHandleLinkBlog = useCallback(() => {
    window.open(
      `https://blog.naver.com/prologue/PrologueList.nhn?blogId=wapl_ai&skinType=&skinId=&from=menu&userSelectMenu=true`,
    );
  }, []);

  if (i18n.language === 'en') {
    return null;
  }
  return (
    <ServiceNewsWrapper ref={refs.newsRef}>
      <ServiceNewsTitle
        src={i18n.language === 'en' ? newsTitleImg_En : newsTitleImg}
        ref={newsWrapperRef.loadBlogEvent}
      />
      <SwiperWrapper>
        {feedList && (
          <NewsSwiper
            pagination={{ clickable: true }}
            loop
            centeredSlides
            slidesPerView="auto"
            spaceBetween={30}
            longSwipes={false}
            style={{ height: '25.75rem', borderRadius: '0.88rem' }}
          >
            {feedList.map(item => (
              <SwiperSlide key={item.url} tag="li">
                <WaplNewsWrapper onClick={() => window.open(item.url)}>
                  {!item.image ? (
                    <WaplNewsDefault>
                      <DefaultImageWrapper src={defaultImage} />
                    </WaplNewsDefault>
                  ) : (
                    <WaplNewsImage
                      src={`data:image/${item.extension};base64,${item.image}`}
                    />
                  )}
                  <WaplNewsTitle>{item.title}</WaplNewsTitle>
                  <WaplNewsContent>
                    {item.description
                      .replace(/&quot;/g, '"')
                      .replace(/&#x27;/g, "'")}
                  </WaplNewsContent>
                  <WaplNewsDetail>
                    {t(`SP_NEWS_03`)}
                    <ArrowImg src={arrowFront} />
                  </WaplNewsDetail>
                </WaplNewsWrapper>
              </SwiperSlide>
            ))}
          </NewsSwiper>
        )}
      </SwiperWrapper>
      <ServiceNewsFooter onClick={onHandleLinkBlog}>
        {t(`SP_NEWS_04`)}
      </ServiceNewsFooter>
    </ServiceNewsWrapper>
  );
};

export default ServiceNewsSwiperContent;

const SwiperWrapper = styled.div`
  margin-top: 1.68rem;
  height: 25.75rem;
  width: auto;

  & .swiper-pagination {
    bottom: 0;
  }
  & .swiper-pagination-bullet-active {
    background-color: #205855;
  }
`;

const NewsSwiper = styled(Swiper)`
  &.swiper-container {
    position: relative;
    width: 100vw;
    list-style: none;
    margin-left: auto;
    margin-right: auto;
    padding: 0;
    z-index: 1;
    overflow: hidden;
    height: 23.75rem;
  }

  .swiper-slide {
    align-items: center;
    width: auto !important;
    display: flex;
    flex-direction: column;
    justify-content: center;
    flex-shrink: 0;
    height: 23.75rem;
  }
`;

const ServiceNewsWrapper = styled.div`
  width: 100%;
  height: 41.37rem;
  background: #faf8f7;
  display: flex;
  flex-direction: column;
  align-items: center;
`;

const ServiceNewsFooter = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 1rem;
  color: #00493d;
  font-weight: 500;
  text-decoration: underline;
  cursor: pointer;
  margin-top: 1rem;
  margin-bottom: 1.75rem;
`;

const ServiceNewsTitle = styled.img`
  width: 15.3rem;
  height: 4.31rem;
  margin-top: 3.55rem;
`;

const WaplNewsWrapper = styled.div`
  display: flex;
  flex-direction: column;
  width: 15rem;
  height: 23.75rem;
  border-radius: 0.88rem;
  cursor: pointer;
  background: #fff;
`;

const WaplNewsImage = styled.img`
  border-top-left-radius: 0.88rem;
  border-top-right-radius: 0.88rem;
  width: 15rem;
  height: 15rem;
`;

const WaplNewsTitle = styled.div`
  display: inline-block;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  font-size: 0.88rem;
  font-weight: 500;
  letter-spacing: 0;
  color: #232d3b;
  padding-left: 1.33rem;
  padding-right: 1.33rem;
  padding-top: 1.33rem;
`;

const WaplNewsContent = styled.div`
  white-space: normal;
  line-height: 1.2;
  text-align: left;
  word-wrap: break-word;
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
  overflow: hidden;
  font-size: 0.71rem;
  color: #666666;
  font-weight: 300;
  padding-left: 1.33rem;
  padding-right: 1.33rem;
  padding-top: 0.67rem;
`;

const WaplNewsDetail = styled.div`
  font-weight: 500;
  font-size: 0.8rem;
  color: #00493d;
  padding-left: 1.33rem;
  padding-top: 0.89rem;
  display: flex;
  align-items: center;
  line-height: 5px;
`;

const ArrowImg = styled.img``;

const WaplNewsDefault = styled.div`
  border-top-left-radius: 0.88rem;
  border-top-right-radius: 0.88rem;
  width: 15rem;
  height: 15rem;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: #f7f4ef;
`;

const DefaultImageWrapper = styled.img`
  width: 4.89rem;
  height: 4.89rem;
`;

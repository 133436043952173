import React, { useState, useEffect } from 'react';
import { useObserver } from 'mobx-react';
import styled from 'styled-components';
import { Loader, useCoreStores } from 'teespace-core';
import { useParams } from 'react-router-dom';
import Cookies from 'js-cookie';
import { useKeycloak } from '@react-keycloak/web';
import { useStores } from '../stores';
import WorkspaceHeader from '../components/space/SpaceHeader';
import JoinInvitedSpaceContent from '../components/space/InvitedSpaceLoggedInContent';
import JoinAlreadySpaceContent from '../components/space/AlreadyJoinedSpaceContent';
import InvalidSpaceUrlContent from '../components/space/InvalidSpaceUrlContent';
import JoinExpiredLinkSpaceContent from '../components/space/ExpiredSpaceLinkContent';
import MaxUserSpaceContent from '../components/space/MaxUserSpaceContent';
import TermsFooter from '../components/TermsFooter';
import { PrivateContainer } from '../libs/auth';

const PrivateComponent = ({
  senderId,
  senderLoginId,
  spaceInfo,
  grade,
  inviteId,
}) => {
  const { spaceStore } = useStores();
  const { waplUserStore } = useCoreStores();
  const [loader] = Loader.useLoader();
  useEffect(() => {
    if (Cookies.get('inviteId')) {
      Cookies.set('isInvitedUser', 'true');
    }
    Cookies.remove('inviteId');
    loader.loading();
    const fetchSpaces = async () => {
      await spaceStore.fetchSpaces({ userId: waplUserStore.myProfile.id });
      loader.stop();
    };
    fetchSpaces();
  }, [loader, spaceStore, waplUserStore.myProfile.id]);

  return useObserver(() => (
    <Loader loader={loader}>
      {!spaceStore.checkAlreadyJoined({ id: spaceInfo.id }) &&
        !spaceInfo.joinable && <MaxUserSpaceContent />}
      {!spaceStore.checkAlreadyJoined({ id: spaceInfo.id }) &&
        spaceInfo.joinable && (
          <JoinInvitedSpaceContent
            inviteId={inviteId}
            senderId={senderId}
            senderLoginId={senderLoginId}
            spaceInfo={spaceInfo}
            grade={grade}
          />
        )}
      {spaceStore.checkAlreadyJoined({ id: spaceInfo.id }) && (
        <JoinAlreadySpaceContent spaceInfo={spaceInfo} />
      )}
    </Loader>
  ));
};
function SpaceJoinPage() {
  const [isValid, setIsValid] = useState(false);
  const [spaceInfo, setSpaceInfo] = useState(null);
  const [grade, setGrade] = useState('');
  const [senderId, setSenderId] = useState('');
  const [senderLoginId, setSenderLoginId] = useState('');
  const [loader] = Loader.useLoader();
  const { inviteId } = useParams();
  const { spaceStore } = useStores();
  const { waplUserStore, waplAuthStore } = useCoreStores();
  const { keycloak } = useKeycloak();

  useEffect(() => {
    loader.loading();
    const checkInvitationAndFetchSpaceInfo = async () => {
      const {
        success,
        userId,
        userLoginId,
        grade: memGrade,
      } = await waplUserStore.validateLink({
        inviteId,
      });
      const domainKey = (() => {
        try {
          return inviteId.split('-')[0].toUpperCase();
        } catch (err) {
          return undefined;
        }
      })();
      const space = await spaceStore.searchSpaceByDomainKey({ domainKey });
      if (space) {
        setSpaceInfo(space);
      }
      if (!keycloak.authenticated) {
        Cookies.set('inviteId', inviteId);
        Cookies.set('inviteType', 'join-space');
      }
      setSenderId(userId);
      setSenderLoginId(userLoginId);
      setIsValid(success);
      setGrade(memGrade);
    };

    checkInvitationAndFetchSpaceInfo().then(() => {
      loader.stop();
    });
  }, [inviteId, keycloak.authenticated, loader, spaceStore, waplUserStore]);

  // 없는 스페이스: InvalidSpaceUrl
  // 만료된 초대: JoinExpiredLinkSpace
  // 이미 가입: JoinAlreadySpace
  // 최대 인원:
  return useObserver(() => (
    <Loader loader={loader}>
      <Wrapper>
        {isValid && waplAuthStore.isAuthenticated && <WorkspaceHeader />}
        <Content>
          {/* {spaceInfo &&
            !spaceInfo.joinable &&
            !waplAuthStore.isAuthenticated && <MaxUserSpaceContent />} */}
          {!spaceInfo && <InvalidSpaceUrlContent />}
          {!isValid && spaceInfo && <JoinExpiredLinkSpaceContent />}
          {isValid && spaceInfo && (
            <PrivateContainer>
              <PrivateComponent
                inviteId={inviteId}
                senderId={senderId}
                senderLoginId={senderLoginId}
                spaceInfo={spaceInfo}
                grade={grade}
              />
            </PrivateContainer>
          )}
        </Content>
        <TermsFooter autoMarginTop />
      </Wrapper>
    </Loader>
  ));
}

export default SpaceJoinPage;

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  min-height: 100%;
`;
const Content = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  flex-grow: 1;
`;

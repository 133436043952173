import React, { useEffect } from 'react';

export default props => {
  useEffect(() => {
    if (window.env.REACT_APP_HYPERAUTH_CLIENT_ID === 'hyperspace_qa') {
      window.location.href = 'waplqa://main.login/room';
    } else {
      window.location.href = 'wapl://main.login/room';
    }
  }, []);
  return <></>;
};

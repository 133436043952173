import React, { useState, useCallback } from 'react';
import { useTranslation } from 'react-i18next';
import { Toast } from 'teespace-core';
import { Button } from 'antd';
import styled from 'styled-components';

const AccountItem = React.forwardRef(
  (
    {
      title,
      subtitle,
      value = '-',
      changable = false,
      open = false,
      onChangeOpen = () => undefined,
      component: Component = null,
      getPopupContainer = () => undefined,
      children,
    },
    ref,
  ) => {
    const { t } = useTranslation('common');
    const [visibleToast, setVisibleToast] = useState(false);

    const handleShowForm = useCallback(() => onChangeOpen(true), [
      onChangeOpen,
    ]);
    const handleShowOriginal = useCallback(() => onChangeOpen(false), [
      onChangeOpen,
    ]);
    const handleShowToast = useCallback(() => {
      setVisibleToast(true);
      onChangeOpen(false);
    }, [onChangeOpen]);
    const handleCloseToast = useCallback(() => setVisibleToast(false), []);
    return (
      <>
        <Toast visible={visibleToast} onClose={handleCloseToast}>
          {t('CM_CHANGE_SAVE')}
        </Toast>
        <AccountBox ref={ref}>
          {open && (
            <Component
              onCancel={handleShowOriginal}
              onSave={handleShowToast}
              defaultValue={value}
              getPopupContainer={getPopupContainer}
            />
          )}
          {!open && (
            <AccountWrapper>
              <AccountTitleValueBox>
                <AccountItemTitle>{title}</AccountItemTitle>
                {subtitle && (
                  <AccountItemSubtitle>{subtitle}</AccountItemSubtitle>
                )}
                {value && <AccountItemValue>{value}</AccountItemValue>}
                {children}
              </AccountTitleValueBox>
              {changable && (
                <ChangeButton type="system" onClick={handleShowForm}>
                  {t('CM_CHANGE')}
                </ChangeButton>
              )}
            </AccountWrapper>
          )}
        </AccountBox>
      </>
    );
  },
);

export default AccountItem;

const AccountBox = styled.div`
  width: 100%;
  &:not(:last-child) {
    border-bottom: 1px solid ${props => props.theme.LineSub};
  }
  &:first-child {
    border-top: 1px solid ${props => props.theme.LineSub};
  }
`;

const AccountWrapper = styled.div`
  width: 100%;
  padding: 1.25rem 0.9375rem 1.3125rem 0.9375rem;
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
`;

const AccountTitleValueBox = styled.div`
  display: flex;
  flex-direction: column;
  flex: 1;
  flex-grow: 1;
`;

const AccountItemTitle = styled.h1`
  font-weight: 400;
  font-size: 0.8125rem;
  line-height: 1.1875rem;
  color: ${props => props.theme.TextSub};
`;

const AccountItemSubtitle = styled.h2`
  margin-top: 0.25rem;
  font-weight: 500;
  font-size: 1rem;
  line-height: 1.5rem;
  color: ${props => props.theme.TextMain};
`;

const AccountItemValue = styled.h2`
  margin-top: 0.25rem;
  font-weight: 500;
  font-size: 1rem;
  line-height: 1.5rem;
  color: ${props => props.theme.TextMain};
`;

const ChangeButton = styled(Button)`
  min-width: 0 !important;
  padding-left: 0.84375rem !important;
  padding-right: 0.84375rem !important;
  padding: 0;
`;

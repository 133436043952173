import React from 'react';
import styled from 'styled-components';
import { useCoreStores } from 'teespace-core';

const SettingModalContent = ({ title, subTitle, isAccountInfo = false }) => {
  const { waplUserStore } = useCoreStores();

  return (
    <>
      <ModalContentWrapper>
        <AccountWrapper>
          <ModalContentTitle>{title}</ModalContentTitle>
          {isAccountInfo && (
            <ThumbImage src={waplUserStore.myProfile.defaultPhotoUrl} />
          )}
        </AccountWrapper>

        <ModalContentSubTitle>{subTitle}</ModalContentSubTitle>
      </ModalContentWrapper>
    </>
  );
};

export default SettingModalContent;

const ModalContentWrapper = styled.div`
  padding-top: 1.25rem;
  display: flex;
  flex-direction: column;
  margin-bottom: 1.25rem;
`;

const ModalContentTitle = styled.span`
  font-size: 1.25rem;
  font-weight: 500;
  line-height: 1.813rem;
  color: ${props => props.theme.TextMain};
`;

const ModalContentSubTitle = styled.span`
  font-size: 0.75rem;
  font-weight: normal;
  line-height: 1.125rem;
  margin-top: 0.375rem;
  color: ${props => props.theme.TextSub2};
`;

const AccountWrapper = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
`;

const ThumbImage = styled.img`
  border-radius: 50%;
  width: 2.125rem;
  height: 2.125rem;
  border: 1px solid rgba(0, 0, 0, 0.05);
  margin-right: 1.25rem;
`;

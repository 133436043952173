import React from 'react';
import styled from 'styled-components';
import WorkspaceHeader from '../components/space/SpaceHeader';
import WithdrawalSelectContent from '../components/withdrawal/WithdrawalTypeSelectContent';
import TermsFooter from '../components/TermsFooter';

function WithdrawalTypeSelectPage() {
  return (
    <Wrapper>
      <WorkspaceHeader />
      <Content>
        <WithdrawalSelectContent />
      </Content>
      <TermsFooter autoMarginTop />
    </Wrapper>
  );
}

export default WithdrawalTypeSelectPage;

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  min-height: 100%;
`;
const Content = styled.div`
  display: flex;
  align-items: center;
  flex-direction: column;
  flex-grow: 1;
`;

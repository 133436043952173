import React from 'react';
import { useTranslation } from 'react-i18next';
import SettingModalContent from './SettingModalContent';
import { Select } from 'antd';
import { useCoreStores } from 'teespace-core';

const LanguageModalContent = () => {
  const { i18n, t } = useTranslation(['common']);
  const { waplUserStore } = useCoreStores();
  const { Option } = Select;

  const handleChangeLanguage = async language => {
    await waplUserStore.updateLanguage({ language });
    i18n.changeLanguage(language);
  };

  return (
    <>
      <SettingModalContent
        title={t(`CM_SETTING_GENERAL_04`)}
        subTitle={t(`CM_SETTING_GENERAL_05`)}
      />
      <Select
        defaultValue={waplUserStore.myProfile.language}
        style={{ width: `10rem` }}
        onChange={handleChangeLanguage}
      >
        <Option value="ko">한국어</Option>
        <Option value="en">English</Option>
      </Select>
    </>
  );
};

export default LanguageModalContent;

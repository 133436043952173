import React from 'react';
import styled from 'styled-components';
import WorkspaceHeader from '../components/space/SpaceHeader';
import AccountContent from '../components/account/AccountContent';
import TermsFooter from '../components/TermsFooter';

function AccountPage() {
  return (
    <Wrapper>
      <WorkspaceHeader />
      <Content>
        <AccountContent />
      </Content>
      <TermsFooter autoMarginTop />
    </Wrapper>
  );
}

export default AccountPage;

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  min-height: 100%;
`;
const Content = styled.div`
  display: flex;
  align-items: center;
  justify-content: flex-start;
  flex-direction: column;
  flex-grow: 1;
`;

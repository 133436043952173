const env = {
  serviceURL: null,
  comURL: null,
  websocketURL: null,
  hsmURL: null,
};

export const getEnv = () => {
  return env;
};

export const setEnv = ({
  serviceURL = null,
  comURL = null,
  websocketURL = null,
  hsmURL = null,
}) => {
  env.serviceURL = serviceURL || env.serviceURL;
  env.comURL = comURL || env.comURL;
  env.websocketURL = websocketURL || env.websocketURL;
  env.hsmURL = hsmURL || env.hsmURL;
};

import React from 'react';
import styled from 'styled-components';
import { Button } from 'antd';
import { useHistory } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import InvitationItem from './space/InvitationItem';
import NoUrlImg from '../assets/no url.svg';

export default () => {
  const history = useHistory();
  const { t } = useTranslation('common');
  return (
    <Content>
      <InvitationItem title={t('CM_ERROR_NOT_FOUND_01')}>
        <NoUrlIcon />
        <StyledButton type="solid" onClick={() => history.push('/spaces')}>
          {t('CM_ERROR_NOT_FOUND_02')}
        </StyledButton>
      </InvitationItem>
    </Content>
  );
};

const Content = styled.div`
  padding: 1.68125rem 0;
`;

const NoUrlIcon = styled.div`
  width: 9.375rem;
  height: 9.375rem;
  background: url('${NoUrlImg}') 0 0 no-repeat;
  background-size: contain;
`;

const StyledButton = styled(Button)`
  margin-top: 0.9375rem;
`;

import React from 'react';
import styled from 'styled-components';

export default function (props) {
  const { title, subtitle, children } = props;
  return (
    <TutorialWrapper>
      <TutorialTitle>{title}</TutorialTitle>
      <TutorialSubtitle>{subtitle}</TutorialSubtitle>
      <TutorialContent>{children}</TutorialContent>
    </TutorialWrapper>
  );
}

const TutorialWrapper = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  height: 100%;
`;
const TutorialTitle = styled.div`
  margin-top: 2.4375rem;
  font-size: 1rem;
  line-height: 1.5rem;
  color: ${props => props.theme.TextMain};
  letter-spacing: 0;
  text-align: center;
  font-weight: 500;
`;

const TutorialSubtitle = styled.div`
  opacity: 0.9;
  font-size: 0.8125rem;
  line-height: 1.125rem;
  color: ${props => props.theme.TextSub};
  text-align: center;
  white-space: pre-line;
  margin-top: 0.625rem;
`;

const TutorialContent = styled.div`
  flex-grow: 1;
  display: flex;
  justify-content: center;
`;

import React from 'react';
import styled from 'styled-components';
import WaplLoadingImg from '../assets/WAPL_Loading.gif';

export default () => {
  return (
    <Wrapper>
      <Logo />
    </Wrapper>
  );
};

const Wrapper = styled.div`
  z-index: 10000;
  background-color: ${props => props.theme.StateNormal};
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
`;

const Logo = styled.i`
  width: 4.0625rem;
  height: 4.0625rem;
  background: url('${WaplLoadingImg}');
  background-repeat: no-repeat;
  background-size: contain;
`;

import React, { useEffect, useState } from 'react';
import styled, { css } from 'styled-components';
import SwiperCore, { Pagination, Navigation, Autoplay } from 'swiper';
import { Swiper, SwiperSlide } from 'swiper/react';
import { Modal as Dialog } from 'antd';
import { NoticeWrapper } from 'teespace-core';
import { useCookies } from 'react-cookie';
import { isMobile } from 'react-device-detect';
import prevArrowImg from '../../assets/landing/media/arrow_back_1.svg';
import nextArrowImg from '../../assets/landing/media/arrow_front_1.svg';

SwiperCore.use([Navigation, Pagination, Autoplay]);

function ServiceNoticeDialog({ noticeObj }) {
  // Antd modal이 mobile 화면 크기 변경하는 이슈 처리
  useEffect(() => {
    if (isMobile)
      if (
        document.getElementsByClassName('ant-scrolling-effect').length !== 0
      ) {
        document.getElementsByClassName(
          'ant-scrolling-effect',
        )[0].style.width = `100%`;
      }
  }, []);
  const [visibleNotice, setVisibleNotice] = useState(true);
  // Android mobile 공지 창 팝업상태에서 스크롤 방지
  useEffect(() => {
    if (isMobile) {
      document.body.style.position = 'fixed';
    }
    if (!visibleNotice) {
      document.body.style.position = 'inherit';
    }
  }, [visibleNotice]);

  const [cookies, setCookie] = useCookies(['todayNoticeClose']);

  const onCancel = () => {
    setVisibleNotice(false);
  };

  const onHandleCheckboxClick = () => {
    setCookie('todayNoticeClose', true, { maxAge: 24 * 3600 * 7 });
    setVisibleNotice(false);
  };

  const [smoothBorderRadius, setSmoothBorderRadius] = useState(false);
  useEffect(() => {
    setSmoothBorderRadius(noticeObj?.[0]?.theme === '3');
  }, [noticeObj]);
  const swiperSlideChange = swiper => {
    const slide = swiper.slides[swiper.activeIndex];
    const theme = slide?.getAttribute('data-key');
    swiper.allowSlideNext = !swiper.isEnd;
    swiper.allowSlidePrev = !swiper.isBeginning;
    setSmoothBorderRadius(theme === '3');
  };

  if (noticeObj.length === 0) return null;

  return (
    <NoticeDialogWrapper
      smooth={smoothBorderRadius}
      visible={noticeObj && !cookies.todayNoticeClose ? visibleNotice : false}
      onCancel={onCancel}
      closable
      mask
      maskStyle={{ opacity: 0.8 }}
      maskClosable={false}
      centered
      transitionName=""
      maskTransitionName=""
      footer={null}
      // themeStyle={noticeObj?.theme}
    >
      {noticeObj && (
        <SwiperFrame
          id="Notice"
          navigation={noticeObj.length !== 1}
          pagination={noticeObj.length !== 1 ? { clickable: true } : false}
          autoplay={{ delay: 6000 }}
          initialSlide={0}
          slidesPerView={1}
          onSlideChange={swiperSlideChange}
          onInit={swiper => {
            swiper.allowSlideNext = !swiper.isEnd;
            swiper.allowSlidePrev = !swiper.isBeginning;
          }}
          resistance
          resistanceRatio={0}
          // eslint-disable-next-line
          spaceBetween={0.3}
        >
          {noticeObj.map(notice => (
            <SwiperSlide tag="li" key={notice?.title} data-key={notice?.theme}>
              <NoticeWrapper
                title={notice?.title}
                content={notice?.contents}
                themeNum={notice?.theme}
                setVisibleNotice={onHandleCheckboxClick}
                onCloseClicked={onCancel}
                mask
                key={notice?.title}
                closePopupTodayVisible={notice?.unlookButton === 1}
                closePopupText="7일간 이 창을 열지 않기"
              />
            </SwiperSlide>
          ))}
        </SwiperFrame>
      )}
    </NoticeDialogWrapper>
  );
}

export default ServiceNoticeDialog;

const NoticeDialogWrapper = styled(Dialog)`
  margin: 0;
  min-width: 0 !important;
  width: 25rem !important;
  overflow: hidden;
  //모서리 radius전역 설정, 템플릿 prop으로 덮어씌워짐
  .ant-modal-content {
    background: linear-gradient(to bottom, transparent 7%, white 7% 100%);
    border-radius: 0.4rem;
    //모서리 그림자
    box-shadow: 0 0 0 0 rgb(0 0 0 / 35%);
    overflow: hidden;
  }

  .ant-modal-body {
    padding: 0;
    overflow: hidden;
  }

  .ant-btn {
    display: none;
  }
  .ant-modal-close-x {
    font-size: 1.375rem;
    height: 2.5rem;
    width: 3.525rem;
  }
`;

const SwiperFrame = styled(Swiper)`
  .swiper-button-prev {
    content: url('${prevArrowImg}');
    display: block;
    left: 0.25rem;
    width: 2rem;
    height: 2rem;
  }
  .swiper-button-next {
    content: url('${nextArrowImg}');
    display: block;
    right: 0.25rem;
    width: 2rem;
    height: 2rem;
  }

  .swiper-pagination-bullet {
    background: #205855;
  }
  .swiper-pagination-bullets {
    bottom: 2.75rem !important;
  }
  max-height: 41.188rem;
`;

// @flow
import { observable, action, set, computed } from 'mobx';
import autobind from 'autobind-decorator';
import moment from 'moment';
import SpaceStore from '../SpaceStore';

@autobind
class FriendModel {
  @observable
  id: string;

  @observable
  loginId: string;

  @observable
  name: string;

  @observable
  domain: string;

  @observable
  modiDate: string;

  @observable
  domainKey: string;

  @observable
  thumbPhoto: string;

  @observable
  status: string;

  constructor(data: Object) {
    this.setValues(data);
  }

  @action
  setValues(data: Object) {
    set(this, data);
  }

  @action
  setId(data: string) {
    this.id = data;
  }

  @action
  setLoginId(data: string) {
    this.loginId = data;
  }

  @action
  setName(data: string) {
    this.name = data;
  }

  @action
  setDomain(data: string) {
    this.domain = data;
  }

  @action
  setModiDate(data: string) {
    this.modiDate = data;
  }

  @action
  setDomainKey(data: string) {
    this.domainKey = data;
  }

  @action
  setThumbPhoto(data: string) {
    this.thumbPhoto = data;
  }

  @action
  setStatus(data: string) {
    this.status = data;
  }

  @computed
  get spaceName() {
    const space = SpaceStore.spaceList.find(
      spaceInfo => spaceInfo.domainKey === this.domainKey,
    );
    return space?.name || '';
  }

  @computed
  get displayName() {
    const { spaceName } = this;
    if (spaceName) {
      return `${this.name} (${spaceName})`;
    }
    return this.name;
  }

  @computed
  get userProfilePhotoURL() {
    const cacheString = this.modiDate
      ? `?${moment(this.modiDate, 'YYYY-MM-DD HH:mm:ss.S Z').valueOf()}`
      : '';
    if (this.thumbPhoto) {
      return `https://${this.domain}/photo/profile/${this.domainKey}/small/${this.id}.${this.thumbPhoto}${cacheString}`;
    }
    return this.defaultPhotoUrl;
  }

  get defaultPhotoUrl() {
    const fields = this.id?.split('-');

    const faceIdxPattern = fields[0][0].match(/([0-5]+)?([6-a]+)?([b-f]+)?/);
    const faceIdx = faceIdxPattern
      ? faceIdxPattern.slice(1).findIndex(v => typeof v !== 'undefined')
      : '';
    const colorIdxPattern = fields[fields.length - 1][0].match(
      /([0-1]+)?([2-3]+)?([4]+)?([5]+)?([6-7]+)?([8-9]+)?([a]+)?([b]+)?([c-d]+)?([e-f]+)?/,
    );
    const colorIdx = colorIdxPattern
      ? colorIdxPattern.slice(1).findIndex(v => typeof v !== 'undefined')
      : '';
    return `/res/face/new/${faceIdx}${colorIdx}.png`;
  }
}

export default FriendModel;

export const convertFromDtoItemToPlatformItem = (
  dtoItem: $Shape<SessionInfoDto>,
): ?$Shape<SessionInfo> => {
  if (dtoItem.USER_LOGIN_ID) {
    return {
      loginId: dtoItem.USER_LOGIN_ID,
    };
  }
  if (dtoItem.USER_ID) {
    return {
      id: dtoItem.USER_ID,
    };
  }
  if (dtoItem.USER_NAME) {
    return {
      name: dtoItem.USER_NAME,
    };
  }
  if (dtoItem.DOMAIN) {
    return {
      domain: dtoItem.DOMAIN,
    };
  }
  if (dtoItem.MODI_DATE) {
    return {
      modiDate: dtoItem.MODI_DATE,
    };
  }
  if (dtoItem.DOMAIN_KEY) {
    return {
      domainKey: dtoItem.DOMAIN_KEY,
    };
  }
  if (dtoItem.THUMB_PHOTO) {
    return {
      thumbPhoto: dtoItem.THUMB_PHOTO,
    };
  }

  if (dtoItem.PROFILE_STATUS) {
    return {
      status: dtoItem.PROFILE_STATUS,
    };
  }

  return undefined;
};

export const convertFromPlatformItemToDtoItem = (
  platformItem: $Shape<SessionInfo>,
): ?$Shape<SessionInfoDto> => {
  if (platformItem.id) {
    return {
      USER_ID: platformItem.id,
    };
  }
  if (platformItem.loginId) {
    return {
      USER_LOGIN_ID: platformItem.loginId,
    };
  }
  if (platformItem.name) {
    return {
      USER_NAME: platformItem.name,
    };
  }
  if (platformItem.domain) {
    return {
      DOMAIN: platformItem.domain,
    };
  }
  if (platformItem.modiDate) {
    return {
      MODI_DATE: platformItem.modiDate,
    };
  }
  if (platformItem.domainKey) {
    return {
      DOMAIN_KEY: platformItem.domainKey,
    };
  }
  if (platformItem.thumbPhoto) {
    return {
      THUMB_PHOTO: platformItem.thumbPhoto,
    };
  }
  if (platformItem.status) {
    return {
      PROFILE_STATUS: platformItem.status,
    };
  }
  return undefined;
};

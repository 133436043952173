import React, { useEffect } from 'react';
import { useCoreStores } from 'teespace-core';
import { logout } from '../libs/keycloak';

const LogoutPage = () => {
  const { waplAuthStore } = useCoreStores();
  useEffect(() => {
    const logoutLogic = async () => {
      await waplAuthStore.logout({ deviceType: 'PC' });
      await logout({
        redirectUri: window.location.origin,
      });
    };
    logoutLogic();
  }, [waplAuthStore]);
  return <></>;
};
export default LogoutPage;

import React, { useRef, useState, useEffect, useCallback } from 'react';
import { useTranslation } from 'react-i18next';
import styled, { css } from 'styled-components';
import { Input, Form, Button, Message, useI18nInit } from 'teespace-core';
import axios from 'axios';
import { Checkbox, Input as AntdInput } from 'antd';
import { DownOutlined, UpOutlined } from '@ant-design/icons';
import { isMobile } from 'react-device-detect';
import { checkEmailValid } from '../../libs/Regex';
import { useStores } from '../../stores';
import back2 from '../../assets/service/r02.service_background.png';
import contactImage from '../../assets/landing/contact/main.png';
import TermsDialog from './TermsDialog';
import mainEn from '../../assets/landingEn/contact/main_En.png';
import mobileTitle from '../../assets/landing/mobile/contact/main.png';
import mobileMainEn from '../../assets/landingEn/mobile/contact/main.png';
import Table from './TableTemplate';

const { TextArea } = AntdInput;

const ServiceInquiryContent = props => {
  const { introduction, refs } = props;
  const { inquiryRef } = refs;
  const { t, i18n } = useTranslation('service');
  const [visibleDialog, setVisibleDialog] = useState(false);
  const [checkboxagree, setcheckboxAgree] = useState(0);
  const [enableButton, setEnableButton] = useState({
    name: false,
    email: false,
    mobile: false,
    time: false,
    checkbox: false,
  });
  // Accordion Tab
  const [clickPrivacy, setClickPrivacy] = useState(false);
  const [clickChoice, setClickChoice] = useState(false);

  const onHandleAccordionPrivacyClick = useCallback(() => {
    setClickPrivacy(!clickPrivacy);
  }, [clickPrivacy]);

  const onHandleAccordionChoiceClick = useCallback(() => {
    setClickChoice(!clickChoice);
  }, [clickChoice]);
  const { serviceStore } = useStores();
  const [form] = Form.useForm();
  const [messageVisible, setMessageVisible] = useState(false);
  const [successText, setSuccessText] = useState(false);
  const contactCard = useRef();
  const contactCardBot = useRef();
  const contactCardSection = useRef();

  const message = t('SP_INTRO_INFO_28');
  // `도입 문의 신청이 완료되었습니다. \n 빠른 시간 내에 연락드리도록 하겠습니다.`;

  useEffect(() => {
    if (introduction) {
      window.scrollTo({
        top: inquiryRef.current.offsetTop - refs.headerRef.current.clientHeight,
        left: 0,
        behavior: 'smooth',
      });
    }
  }, [introduction, inquiryRef, refs.headerRef]);

  const onHandleDialogClose = useCallback(() => {
    setVisibleDialog(false);
  }, []);
  const [optionalData, setOptionalData] = useState(false);
  const onFinish = async values => {
    const corpsObj = {
      name: values.nameInput,
      email: values.emailInput,
      mobile: values.mobileInput,
      time: values.timeInput,
      corpsName: values.corpsNameInput,
      corpsRank: values.corpsRankInput,
      corpsType: values.corpsTypeInput,
      corpsNumber: values.corpsNumberInput,
      corpsComment: values.corpsCommentInput,
      optional: optionalData ? 'Y' : 'N',
    };
    const resSubmit = await serviceStore.createCorps({
      corpsInfo: corpsObj,
    });
    if (resSubmit) {
      setMessageVisible(true);
      setSuccessText(true);
      contactCard.current.style.display = 'none';
      contactCardBot.current.style.height = 'auto';
      contactCardSection.current.style.height = '11rem';
      axios
        .get('/InquiryComplete', { headers: { Accept: 'text/html' } })
        .then(res => {
          console.log(res);
          window.scrollTo({
            top:
              refs.introRef.current.offsetTop -
              refs.headerRef.current.clientHeight,
            left: 0,
            behavior: 'smooth',
          });
        });
    }
  };
  const onFinishFailed = errorInfo => {
    console.log('Failed:', errorInfo);
  };

  const onBlurNameInput = e => {
    if (e.target.value) {
      setEnableButton({ ...enableButton, name: true });
    } else {
      setEnableButton({ ...enableButton, name: false });
    }
  };

  const onBlurEmailInput = e => {
    if (checkEmailValid(e.target.value)) {
      setEnableButton({ ...enableButton, email: true });
    } else {
      setEnableButton({ ...enableButton, email: false });
    }
  };

  const onBlurMobileInput = e => {
    if (e.target.value) {
      setEnableButton({ ...enableButton, mobile: true });
    } else {
      setEnableButton({ ...enableButton, mobile: false });
    }
  };

  const onBlurTimeInput = e => {
    if (e.target.value) {
      setEnableButton({ ...enableButton, time: true });
    } else {
      setEnableButton({ ...enableButton, time: false });
    }
  };

  const onHandleChangeCheckTerms = e => {
    if (e.target.checked) {
      setcheckboxAgree(checkboxagree + 1);
    } else {
      setcheckboxAgree(checkboxagree - 1);
    }
  };

  const onHandleChangeCheckOptional = () => {
    setOptionalData(!optionalData);
  };

  useEffect(() => {
    if (checkboxagree === 2) {
      setEnableButton({ ...enableButton, checkbox: true });
    } else {
      setEnableButton({ ...enableButton, checkbox: false });
    }
  }, [checkboxagree]);

  useEffect(() => {
    const inquiryFormEvent = ({ detail }) => {
      setTimeout(() => {
        detail.querySelectorAll('input, textarea').forEach(item => {
          if (item.type !== `checkbox`) {
            item.style.opacity = 1;
          }
        });
      }, 400);
    };

    const cardBot = contactCardBot.current;
    const transitionendEvent = e => {
      if (
        e.target.className.includes('aos-init') &&
        !e.target.className.includes('aos-animate')
      ) {
        e.target.querySelectorAll('input, textarea').forEach(item => {
          if (item.type !== `checkbox`) {
            item.style.opacity = 0;
          }
        });
      }
    };
    document.addEventListener('aos:in:inquiry-form', inquiryFormEvent);
    cardBot.addEventListener('transitionend', transitionendEvent);
    return () => {
      document.removeEventListener('aos:in:inquiry-form', inquiryFormEvent);
      cardBot.removeEventListener('transitionend', transitionendEvent);
    };
  }, []);

  return (
    <>
      <ServiceInquiryHeadWrapper id="mobileInquiryContainer" ref={inquiryRef}>
        <InquiryHeaderImage
          src={
            i18n.language === 'en'
              ? isMobile
                ? mobileMainEn
                : mainEn
              : isMobile
              ? mobileTitle
              : contactImage
          }
          data-aos-offset="40"
          data-aos="fade-up"
          data-aos-duration="750"
          data-aos-easing="ease"
        />
      </ServiceInquiryHeadWrapper>
      <ServiceInquiryBodyWrapper id="mobileInquiry" ref={contactCardSection}>
        <ServiceContactCardBodyWrapper
          ref={contactCardBot}
          data-aos-offset="40"
          data-aos="fade-up"
          data-aos-duration="750"
          data-aos-easing="ease"
          data-aos-id="inquiry-form"
          isen={i18n.language}
        >
          <ContactCardInnerWrapper id="mobileInquiryBox" ref={contactCard}>
            <ContactCardTitleWrapper>
              {t('SP_INTRO_INFO_01')}{' '}
              <HighlightWrapper>
                {t('common:CM_SERVICE_AREE_CHECKBOX_03')}
              </HighlightWrapper>
            </ContactCardTitleWrapper>
            <PreSpan>{t(`SP_INTRO_INFO_30`)}</PreSpan>
            {!isMobile && <Emptydiv />}
            <Form
              form={form}
              onFinish={onFinish}
              onFinishFailed={onFinishFailed}
              initialValues={{
                nameInput: '',
                emailInput: '',
                mobileInput: '',
                timeInput: '',
                corpsNameInput: '',
                corpsRankInput: '',
                corpsTypeInput: '',
                corpsNumberInput: '',
                corpsCommentInput: '',
              }}
            >
              <FormItem
                noStyle
                rules={[{ required: true, message: t('SP_INTRO_INFO_09') }]}
                name="nameInput"
              >
                <FormContainer>
                  <FormLabel>
                    {t('SP_INTRO_INFO_02')}{' '}
                    <HighlightWrapper>*</HighlightWrapper>
                  </FormLabel>
                  <FormInput>
                    <Input
                      style={{ width: `100%` }}
                      type="text"
                      onBlur={onBlurNameInput}
                      placement="topLeft"
                    />
                  </FormInput>
                </FormContainer>
              </FormItem>
              <FormItem
                noStyle
                rules={[
                  {
                    required: true,
                    message: t('SP_INTRO_INFO_12'),
                  },
                  {
                    type: 'email',
                    message: t('SP_INTRO_INFO_13'),
                  },
                ]}
                name="emailInput"
              >
                <FormContainer>
                  <FormLabel>
                    {t('SP_INTRO_INFO_04')}{' '}
                    <HighlightWrapper>*</HighlightWrapper>
                  </FormLabel>
                  <FormInput>
                    <Input
                      style={{ width: `100%` }}
                      type="text"
                      onBlur={onBlurEmailInput}
                      placement="topLeft"
                    />
                  </FormInput>
                </FormContainer>
              </FormItem>
              <FormItem
                noStyle
                rules={[
                  {
                    required: true,
                    message: t('SP_INTRO_INFO_14'),
                  },
                ]}
                name="mobileInput"
              >
                <FormContainer>
                  <FormLabel>
                    {t('SP_INTRO_INFO_03')}{' '}
                    <HighlightWrapper>*</HighlightWrapper>
                  </FormLabel>
                  <FormInput>
                    <Input
                      type="text"
                      style={{ width: `100%` }}
                      onBlur={onBlurMobileInput}
                      placeholder={t('SP_INTRO_INFO_06')}
                      placement="topLeft"
                    />
                  </FormInput>
                </FormContainer>
              </FormItem>
              <FormItem
                noStyle
                rules={[
                  {
                    required: true,
                    message: t('SP_INTRO_INFO_17'),
                  },
                ]}
                name="timeInput"
              >
                <FormContainer>
                  <FormLabel>
                    {t('SP_INTRO_INFO_05')}{' '}
                    <HighlightWrapper>*</HighlightWrapper>
                  </FormLabel>
                  <FormInput>
                    <Input
                      type="text"
                      onBlur={onBlurTimeInput}
                      style={{ width: `100%` }}
                      placement="topLeft"
                    />
                  </FormInput>
                </FormContainer>
              </FormItem>
              <ContactCardTitleWrapper optional>
                {t('SP_INTRO_INFO_18')}
              </ContactCardTitleWrapper>
              <FormContainer>
                <FormLabel>{t('SP_INTRO_INFO_19')}</FormLabel>
                <FormInput>
                  <FormItem noStyle name="corpsNameInput">
                    <Input
                      type="text"
                      data-key="CORP_NAME"
                      style={{ width: `100%` }}
                    />
                  </FormItem>
                </FormInput>
              </FormContainer>
              <FormContainer>
                <FormLabel>{t('SP_INTRO_INFO_21')}</FormLabel>
                <FormInput>
                  <FormItem noStyle name="corpsRankInput">
                    <Input
                      type="text"
                      data-key="CORP_RANK"
                      style={{ width: `100%` }}
                    />
                  </FormItem>
                </FormInput>
              </FormContainer>
              <FormContainer>
                <FormLabel>{t('SP_INTRO_INFO_20')}</FormLabel>
                <FormInput>
                  <FormItem noStyle name="corpsTypeInput">
                    <Input
                      type="text"
                      data-key="CORP_TYPE"
                      style={{ width: `100%` }}
                    />
                  </FormItem>
                </FormInput>
              </FormContainer>
              <FormContainer>
                <FormLabel>{t('SP_INTRO_INFO_22')}</FormLabel>
                <FormInput>
                  <FormItem noStyle name="corpsNumberInput">
                    <Input
                      type="text"
                      data-key="CORP_NUMBER"
                      style={{ width: `100%` }}
                    />
                  </FormItem>
                </FormInput>
              </FormContainer>
              <FormContainerArea>
                <FormLabel>{t('SP_INTRO_INFO_24')}</FormLabel>
                <FormInput>
                  <FormItem noStyle name="corpsCommentInput">
                    <EtcArea
                      data-key="CORP_COMMENT"
                      placeholder={t('SP_INTRO_INFO_23')}
                      showCount
                      maxLength={1000}
                    />
                  </FormItem>
                </FormInput>
              </FormContainerArea>
              <FormAgreement>
                <CheckboxWrapper>
                  <Checkbox
                    id="agreeInquiry1"
                    onChange={onHandleChangeCheckTerms}
                  >
                    <UnderlineSpan>{t(`SP_INTRO_INFO_41`)}</UnderlineSpan>{' '}
                    <HighlightUnderlineWrapper>
                      {t(`common:CM_SERVICE_AREE_CHECKBOX_03`)}
                    </HighlightUnderlineWrapper>
                  </Checkbox>
                  {!isMobile && (
                    <PreSpan
                      dangerouslySetInnerHTML={{
                        __html: t('SP_INTRO_INFO_38'),
                      }}
                      style={{ marginLeft: '1.5rem' }}
                    />
                  )}
                  <Accordion>
                    <Checkbox
                      id="agreeInquiry2"
                      onChange={onHandleChangeCheckTerms}
                    >
                      <UnderlineSpan>{t(`SP_INTRO_INFO_39`)}</UnderlineSpan>{' '}
                      <HighlightUnderlineWrapper>
                        {t(`common:CM_SERVICE_AREE_CHECKBOX_03`)}
                      </HighlightUnderlineWrapper>
                    </Checkbox>
                    <AccordionSpan>
                      {clickPrivacy ? (
                        <UpOutlined onClick={onHandleAccordionPrivacyClick} />
                      ) : (
                        <DownOutlined onClick={onHandleAccordionPrivacyClick} />
                      )}
                    </AccordionSpan>
                  </Accordion>
                  {clickPrivacy && (
                    <AuthDiv>
                      <TermContent>
                        <PreTag>
                          {i18n.language !== 'en' ? (
                            <Table
                              titles={[
                                '수집 목적',
                                '수집 항목',
                                '보유∙이용 기간',
                              ]}
                              rows={[
                                [
                                  { text: '고객 문의 관리' },
                                  {
                                    text: '· 이름, 이메일 주소, 연락처, 연락 가능 시간',
                                  },
                                  { text: '5년', highlight: true },
                                ],
                              ]}
                              descriptions={[
                                '위 사항에 대하여 동의를 거부할 권리가 있으나, 거부시 서비스 이용이 제한됩니다.',
                              ]}
                            />
                          ) : (
                            <Table
                              titles={[
                                'Purpose',
                                'Items to be collected',
                                'Period of retention',
                              ]}
                              rows={[
                                [
                                  { text: 'Management of customer inquires' },
                                  {
                                    text: '· Name, email address, contact details, available time',
                                  },
                                  { text: '5 years', highlight: true },
                                ],
                              ]}
                              descriptions={[
                                'You can refuse to provide the above information, in case of which, however, you will not be able to enjoy the full functionality of the services.',
                              ]}
                            />
                          )}
                        </PreTag>
                      </TermContent>
                    </AuthDiv>
                  )}
                  <Accordion>
                    <Checkbox
                      id="agreeInquiry3"
                      name="optionalDataInput"
                      onChange={onHandleChangeCheckOptional}
                    >
                      {t(`SP_INTRO_INFO_40`)}{' '}
                      <HighlightWrapper>
                        {t(`common:CM_SELECT`)}
                      </HighlightWrapper>
                    </Checkbox>
                    <AccordionSpan>
                      {clickChoice ? (
                        <UpOutlined onClick={onHandleAccordionChoiceClick} />
                      ) : (
                        <DownOutlined onClick={onHandleAccordionChoiceClick} />
                      )}
                    </AccordionSpan>
                  </Accordion>
                  {clickChoice && (
                    <AuthDiv>
                      <TermContent>
                        <PreTag>
                          {i18n.language !== 'en' ? (
                            <Table
                              titles={[
                                '수집 목적',
                                '수집 항목',
                                '보유∙이용 기간',
                              ]}
                              // rows={[[content={(text), (rowSpan), (columnSpan), (highlight)}],...]}
                              rows={[
                                [
                                  { text: '고객 문의 관리' },
                                  {
                                    text: '· 회사 또는 단체명, 직책, 업종 정보, 사용 예상 인원, 기타 문의사항',
                                  },
                                  { text: '5년', highlight: true },
                                ],
                              ]}
                              descriptions={[
                                '위 사항에 대하여 동의를 거부할 권리가 있으며, 거부하시더라도 서비스를 이용하실 수 있습니다.',
                              ]}
                            />
                          ) : (
                            <Table
                              titles={[
                                'Purpose',
                                'Items to be collected',
                                'Period of retention',
                              ]}
                              rows={[
                                [
                                  { text: 'Management of customer inquires' },
                                  {
                                    text: '· Company or organization’s name, position/title, business type, estimated number of users and other inquiries',
                                  },
                                  { text: '5 years', highlight: true },
                                ],
                              ]}
                              descriptions={[
                                'You can refuse to provide the above information, in case of which you will still be able to enjoy the services.',
                              ]}
                            />
                          )}
                        </PreTag>
                      </TermContent>
                    </AuthDiv>
                  )}
                </CheckboxWrapper>
              </FormAgreement>
              <div style={{ textAlign: 'center' }}>
                <FormItem noStyle>
                  {isMobile ? (
                    <MobileButton
                      disabled={
                        !(
                          enableButton.name &&
                          enableButton.email &&
                          enableButton.mobile &&
                          enableButton.time &&
                          enableButton.checkbox
                        )
                      }
                      htmlType="submit"
                      type="solid"
                      style={{}}
                    >
                      {t('SP_INTRO_INFO_26')}
                    </MobileButton>
                  ) : (
                    <InquiryButton
                      disabled={
                        !(
                          enableButton.name &&
                          enableButton.email &&
                          enableButton.mobile &&
                          enableButton.time &&
                          enableButton.checkbox
                        )
                      }
                      htmlType="submit"
                      type="solid"
                    >
                      {t('SP_INTRO_INFO_26')}
                    </InquiryButton>
                  )}
                </FormItem>
              </div>
            </Form>
          </ContactCardInnerWrapper>
          <Message
            visible={messageVisible}
            title={message}
            btns={[
              {
                type: 'solid',
                text: t('SP_INTRO_INFO_27'),
                onClick: () => {
                  setMessageVisible(false);
                },
              },
            ]}
          />
          {successText && (
            <SuccessSubmit id="submitButton">
              {t(`SP_INTRO_INFO_28`)}
            </SuccessSubmit>
          )}
        </ServiceContactCardBodyWrapper>
      </ServiceInquiryBodyWrapper>

      <TermsDialog visible={visibleDialog} onCancel={onHandleDialogClose} />
    </>
  );
};

export default ServiceInquiryContent;

const ServiceInquiryHeadWrapper = styled.div`
  ${isMobile
    ? css`
        display: flex;
        justify-content: center;
        align-items: center;
        background: #f2ede6;
        padding-bottom: 2.25rem;
        padding-top: 3.13rem;
      `
    : css`
        background: #f2ede6;
        display: flex;
        justify-content: center;
        align-items: center;
        height: 14.5rem;
      `}
`;

const InquiryHeaderImage = styled.img`
  ${isMobile
    ? css`
        width: 18.75rem;
        height: 6.63rem;
      `
    : css`
        width: 17.813rem;
        height: 7.688rem;
        margin: 0 27.5rem 0 28rem;
        object-fit: contain;
      `};
`;

const ServiceInquiryBodyWrapper = styled.div`
  ${isMobile
    ? css`
        padding-left: 0.63rem;
        padding-right: 0.63rem;
        background: #f2ede6;
        padding-bottom: 3.13rem;
      `
    : css`
        background-image: url(${back2});
        background-repeat: no-repeat;
        background-position-x: center;
        display: flex;
        justify-content: center;
        padding-bottom: 5rem;
        background-color: #f2ede6;
      `}
`;

const ServiceContactCardBodyWrapper = styled.div`
  ${props =>
    isMobile
      ? css`
          box-shadow: none;
          background-color: #fff;
          border-radius: 0.5rem;
          padding: 1.25rem auto;
        `
      : props.isen === 'en'
      ? css`
          width: 49.813rem;
          margin: 1.938rem 11.5rem 0 12rem;
          padding: 2.5rem 2.438rem 1.625rem 2.5rem;
          border-radius: 30px;
          box-shadow: 0 2px 15px 0 rgba(35, 45, 59, 0.1);
          background-color: #ffffff;
        `
      : css`
          width: 49.813rem;
          margin: 1.938rem 11.5rem 0 12rem;
          padding: 2.5rem 2.438rem 1.625rem 2.5rem;
          border-radius: 30px;
          box-shadow: 0 2px 15px 0 rgba(35, 45, 59, 0.1);
          background-color: #ffffff;
        `}
`;

const ContactCardInnerWrapper = styled.div`
  left: 0;
  margin-left: 0;
  height: 100%;
  ${isMobile
    ? css`
        padding: 1rem;
      `
    : css`
        padding: 0rem;
      `}
`;

const ContactCardTitleWrapper = styled.h3`
  font-weight: 500;
  font-size: 1.125rem;
  line-height: 1.688rem;
  ${props =>
    props.optional &&
    css`
       {
        margin: 1.875rem auto 1.25rem 0;
      }
    `}
`;

const HighlightWrapper = styled.span`
  color: #ed7e49;
  font-weight: 500;
  font-size: ${props => props.fontsize || '1rem'};
  letter-spacing: 0;
`;

const HighlightUnderlineWrapper = styled.span`
  color: #ed7e49;
  font-weight: 500;
  font-size: ${props => props.fontsize || '1rem'};
  letter-spacing: 0;
  text-decoration: underline;
  cursor: pointer;
`;

const PreSpan = styled.pre`
  font-family: NotoSansCJKkr;
  font-weight: 300;
  color: #666666;
  white-space: break-spaces;
  width: 100%;
  overflow-y: hidden;
  ${isMobile
    ? css`
        font-size: 0.8125rem;
        margin-top: 0.5rem;
        margin-bottom: 0.5rem;
      `
    : css`
        font-size: 0.875rem;
        margin: 0.25rem 0 0 0;
        line-height: 1.25rem;
      `}
`;

const Emptydiv = styled.div`
  height: 1.5rem;
`;

const FormContainer = styled.div`
  ${isMobile
    ? css`
        display: block !important;
        height: auto !important;
        margin-bottom: 0.8rem;
      `
    : css`
        display: flex;
        margin-bottom: 1rem;
        align-items: center;
        overflow: hidden;
        input.ant-input {
          height: 1.875rem;
        }
      `}
`;

const FormContainerArea = styled.div`
  ${isMobile
    ? css`
        display: block;
        height: auto;
      `
    : css`
        display: flex;
        overflow: visible;
        height: 6.2rem;
      `}
`;

const FormLabel = styled.div`
  flex: 1;
  font-size: 1rem;
  line-height: 1.5rem;
  color: #333;
  // font-weight: lighter;
  @media (max-width: 1024px) {
    display: block;
  }
`;
const FormInput = styled.div`
  flex: 2;
  display: flex;
  #mobileInquiryBox & input,
  #mobileInquiryBox & textarea {
    opacity: 0;
  }
  textarea {
    height: 100%;
  }
  @media (max-width: 1024px) {
    display: block;
    input,
    textarea,
    & {
      display: block !important;
    }
  }
`;

const FormAgreement = styled.div`
  ${isMobile
    ? css`
        .ant-checkbox span.ant-checkbox-inner {
          width: 20px;
          height: 20px;
        }
        .ant-checkbox .ant-checkbox-inner:after {
          top: 46%;
          left: 24%;
          height: 10px;
          width: 6px;
        }
      `
    : css`
        border-top: 0.063rem dashed #bdc6d3;
        border-bottom: 0.063rem dashed #bdc6d3;
        height: auto;
        margin-top: 1.875rem;
        }
      `}
`;

const CheckboxWrapper = styled.div`
  font-weight: normal !important;
  display: flex;
  flex-direction: column;
  margin-bottom: 1.875rem;
  margin-top: 1.813rem;
  line-height: 1.5rem;

  label {
    font-size: 1rem;
  }

  ${isMobile &&
  css`
    margin-top: 2rem;
  `}

  & .ant-checkbox + span {
    padding-right: 0.5rem;
    padding-left: 0.5rem;
    font-size: 1rem;
  }
`;

const Accordion = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  margin-top: 1.25rem;
  width: inherit;
  justify-content: space-between;
`;

const AccordionSpan = styled.span`
  display: flex;
  cursor: pointer;
  color: #696969;
  justify-content: flex-end;
`;

const AuthDiv = styled.div`
  margin-top: 0.625rem;
  height: auto;
  ${isMobile
    ? css`
        max-height: 7.625rem;
      `
    : css`
        max-height: 11rem;
      `};
  width: 100%;
  border: 1px solid #d3d3d3;
  background: ${props => props.theme.StateNormal};
  resize: none;
  overflow-y: scroll;
  font-size: 0.75rem;
  line-height: 1.25rem;
`;

const PreTag = styled.pre`
  white-space: pre-line;
  margin: 0px;
  padding: 0.625rem;
  line-height: 1.25rem;
  font-size: 0.75rem;
  letter-spacing: 0px;
  font-weight: 500;
`;

const TermContent = styled.div`
  text-align: left;
  font-weight: 300;
  color: ${props => props.theme.TextMain};
  letter-spacing: 0;
  font-size: 0.75rem;
`;

const UnderlineSpan = styled.span`
  text-decoration: underline;
  font-weight: 500;
  letter-spacing: 0;
  cursor: pointer;
  font-size: 1rem;
`;

const SuccessSubmit = styled.div`
  text-align: center;
`;

const FormItem = styled(Form.Item)`
  @media (max-width: 1024px) {
    & {
      display: block;
      height: auto !important;
    }
  }
`;

const MobileButton = styled(Button)`
  width: 100%;
  font-size: 0.81rem;
  line-height: 1.19rem;
  font-weight: 500;
  button {
    width: 100%;
    height: 2.25rem;
  }
`;

const InquiryButton = styled(Button)`
  width: 15rem;
  height: 3.125rem;
  margin: 1.813rem auto 0 auto;
  border-radius: 4px;
  font-size: 1.25rem;

  & :disabled {
    width: 15rem;
    height: 3.125rem;
    border-radius: 4px;
    font-size: 1.25rem;
  }
`;

const EtcArea = styled(TextArea)`
  width: 100%;
  height: 100%;
`;

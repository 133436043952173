// @flow
import { observable, action, set } from 'mobx';
import autobind from 'autobind-decorator';

@autobind
class ServiceModel {
  @observable
  noticeId: string;

  @observable
  location: string;

  @observable
  title: string;

  @observable
  contents: string;

  @observable
  theme: string;

  @observable
  state: number;

  @observable
  startDate: string;

  @observable
  endDate: string;

  @observable
  unlookButton: number;

  constructor(data: Object) {
    this.setValues(data);
  }

  @action
  setValues(data: Object) {
    set(this, data);
  }

  @action
  setNoticeId(data: string) {
    this.noticeId = data;
  }

  @action
  setLocation(data: string) {
    this.location = data;
  }

  @action
  setTitle(data: string) {
    this.title = data;
  }

  @action
  setContents(data: string) {
    this.contents = data;
  }

  @action
  setTheme(data: string) {
    this.theme = data;
  }

  @action
  setState(data: string) {
    this.state = data;
  }

  @action
  setStartDate(data: string) {
    this.startDate = data;
  }

  @action
  setEndDate(data: string) {
    this.endDate = data;
  }
}

export default ServiceModel;

export const convertFromDtoItemToServiceItem = (
  dtoItem: $Shape<ServiceInfoDto>,
): ?$Shape<ServiceInfo> => {
  if (dtoItem.NOTICE_ID) {
    return {
      noticeId: dtoItem.NOTICE_ID,
    };
  }

  if (dtoItem.LOCATION) {
    return {
      location: dtoItem.LOCATION,
    };
  }

  if (dtoItem.TITLE) {
    return {
      title: dtoItem.TITLE,
    };
  }

  if (dtoItem.CONTENTS) {
    return {
      contents: dtoItem.CONTENTS,
    };
  }

  if (dtoItem.THEME) {
    return {
      theme: dtoItem.THEME,
    };
  }

  if (dtoItem.STATE) {
    return {
      state: dtoItem.STATE,
    };
  }

  if (dtoItem.START_DATE) {
    return {
      startDate: dtoItem.START_DATE,
    };
  }

  if (dtoItem.END_DATE) {
    return {
      endDate: dtoItem.END_DATE,
    };
  }

  if (dtoItem.UNLOOK_BTN) {
    return {
      unlookButton: dtoItem.UNLOOK_BTN,
    };
  }

  return undefined;
};

export const convertFromServiceItemToDtoItem = (
  serviceItem: $Shape<ServiceInfo>,
): ?$Shape<ServiceInfoDto> => {
  if (serviceItem.noticeId) {
    return {
      NOTICE_ID: serviceItem.noticeId,
    };
  }

  if (serviceItem.location) {
    return {
      LOCATION: serviceItem.location,
    };
  }

  if (serviceItem.title) {
    return {
      TITLE: serviceItem.title,
    };
  }

  if (serviceItem.contents) {
    return {
      CONTENTS: serviceItem.contents,
    };
  }

  if (serviceItem.theme) {
    return {
      THEME: serviceItem.theme,
    };
  }

  if (serviceItem.state) {
    return {
      STATE: serviceItem.state,
    };
  }

  if (serviceItem.startDate) {
    return {
      START_DATE: serviceItem.startDate,
    };
  }

  if (serviceItem.endDate) {
    return {
      END_DATE: serviceItem.endDate,
    };
  }
  if (serviceItem.unlookButton) {
    return {
      UNLOOK_BTN: serviceItem.unlookButton,
    };
  }
  return undefined;
};

import React, { useEffect, useState, useRef } from 'react';
import styled from 'styled-components';
import { Tooltip } from 'antd';

export default ({ title, className, placement, children }) => {
  const [hasTitleTooltip, setHasTitleTooltip] = useState(false);
  const titleEl = useRef(null);
  useEffect(() => {
    setHasTitleTooltip(
      titleEl.current.offsetWidth < titleEl.current.scrollWidth,
    );
  }, []);

  return (
    <StyledTitle ref={titleEl} className={className}>
      {!!hasTitleTooltip && (
        <Tooltip title={title} placement={placement || 'topLeft'}>
          <span>{children || title}</span>
        </Tooltip>
      )}
      {!hasTitleTooltip && (children || title)}
    </StyledTitle>
  );
};

const StyledTitle = styled.div`
  max-width: 100%;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
`;

import React from 'react';
import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';
import styled from 'styled-components';
import WorkspaceItem from './SpaceItem';
import WorkspaceInvitationItem from './InvitationItem';

function NotAuthorizedSpaceContent({ spaceInfo }) {
  const { t } = useTranslation('common');
  return (
    <>
      <Content>
        <WorkspaceInvitationItem
          title={t('CM_LOGIN_POLICY_NOT_ACCESS_URL')}
          subtitle={t('CM_INVITE_PEOPLE_URL_02')}
        >
          <SpaceWrapper>
            <StyledWorkspaceItem
              workspace={spaceInfo}
              displayName={spaceInfo.name}
              readOnly
            />
          </SpaceWrapper>
          <GoToSpaceListLink to="/spaces">
            {t('CM_GO_SPACES')}
          </GoToSpaceListLink>
        </WorkspaceInvitationItem>
      </Content>
    </>
  );
}

export default NotAuthorizedSpaceContent;

const Content = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
`;

const GoToSpaceListLink = styled(Link)`
  font-size: 0.8125rem;
  line-height: 1.1875rem;
  color: ${props => props.theme.MyHomeTextLink}
  text-decoration: underline;
  margin-top: 5.375rem;
  &:hover {
    color: ${props => props.theme.MyHomeTextLink}
  }
`;

const StyledWorkspaceItem = styled(WorkspaceItem)`
  .space-profile {
    margin-top: 0.3125rem;
  }
`;

const SpaceWrapper = styled.div`
  max-width: 100%;
`;

import React, { useCallback, useState } from 'react';
import styled from 'styled-components';
import { useTranslation } from 'react-i18next';
import { useCoreStores } from 'teespace-core';
import { Button, Modal as Dialog } from 'antd';
import { useObserver } from 'mobx-react';
import EllipsisableTitle from '../EllipsisableTitle';
import { logout } from '../../libs/keycloak';
import AccountModal from '../setting/SettingModal';

function SpaceHeaderProfileDialog({
  visible,
  onCancel,
  loader,
  getContainer = () => document.body,
  support,
}) {
  const { waplAuthStore, waplUserStore } = useCoreStores();
  const { t, i18n } = useTranslation(['common', 'service']);
  const [accountModalVisible, setAccountModalVisible] = useState(false);
  const isLocal = process.env.REACT_APP_ENV === 'local';
  const handleLogout = useCallback(async () => {
    loader.loading();

    await waplAuthStore.logout({ deviceType: 'PC' });
    await logout({
      redirectUri: window.location.origin,
      locale: i18n.language,
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const onHandleAccountOpen = useCallback(() => {
    setAccountModalVisible(true);
    waplUserStore.fetchUserProfile({
      loginId: waplUserStore.myProfile.loginId,
    });
    onCancel();
  }, []);

  const onHandleAccountClose = useCallback(() => {
    setAccountModalVisible(false);
  }, []);

  const onHandleSupportOpen = useCallback(() => {
    let targetQnA = '';
    if (isLocal) {
      targetQnA = `${window.location.protocol}//${window.location.host}/support`;
    } else {
      targetQnA = `${window.location.protocol}//${window.location.hostname}/support`;
    }
    window.open(targetQnA);
    onCancel();
  }, []);
  const DialogFooter = (
    <FooterButtonWrapper>
      <FooterButton type="text" onClick={onHandleAccountOpen}>
        {t('common:CM_SETTING_05')}
      </FooterButton>
      {!support && (
        <FooterButton type="text" onClick={onHandleSupportOpen}>
          {t('service:SP_HOME_06')}
        </FooterButton>
      )}
    </FooterButtonWrapper>
  );

  return useObserver(() => (
    <>
      <ProfileDialog
        getContainer={getContainer}
        visible={visible}
        onCancel={onCancel}
        closable={false}
        mask={false}
        maskClosable
        footer={DialogFooter}
        transitionName=""
        maskTransitionName=""
        width="11.125rem"
        style={{ margin: 0, padding: 0 }}
      >
        <>
          <Profile src={waplUserStore.myProfile.defaultPhotoUrl} />
          <ProfileTitle title={waplUserStore.myProfile.displayName}>
            {waplUserStore.myProfile.displayName}
          </ProfileTitle>
          <ProfileSubtitle title={waplUserStore.myProfile.loginId}>
            {waplUserStore.myProfile.loginId}
          </ProfileSubtitle>
          <LogoutButton type="outlined" onClick={handleLogout}>
            {t('common:CM_LOGOUT_01')}
          </LogoutButton>
        </>
      </ProfileDialog>
      {accountModalVisible && (
        <AccountModal
          visible={accountModalVisible}
          onCancel={onHandleAccountClose}
        />
      )}
    </>
  ));
}

export default SpaceHeaderProfileDialog;

const ProfileDialog = styled(Dialog)`
  position: absolute;
  margin: 0;
  padding: 0;
  right: 0.6875em;
  top: 3.8125rem;
  min-width: 0 !important;

  div.ant-modal-content {
    background-color: ${props => props.theme.StateNormal};
    border: 1px solid ${props => props.theme.LineOut};
    box-shadow: 0 0.3125rem 0.625rem 0 rgba(0, 0, 0, 0.3);
    border-radius: 0.3125rem;

    .ant-modal-body {
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      border-radius: 0.25rem;
      background-color: ${props => props.theme.CoreDark};
      padding: 1.25rem 1.25rem 1rem 1.25rem;
    }

    .ant-modal-footer {
      border-top: none;
      border-radius: 0 0 0.25rem 0.25rem;
      padding-top: 0.625rem;
      padding-bottom: 0.625rem;
      background-color: ${props => props.theme.Profile};
    }
  }
`;

const Profile = styled.img`
  align-self: center;
  width: 3.75rem;
  height: 3.75rem;
  cursor: default;
`;

const ProfileTitle = styled(EllipsisableTitle)`
  margin: 0.625rem 0 0 0;
  font-size: 0.875rem;
  line-height: 1.25rem;
  color: #ffffff;
  font-weight: 500;
`;

const ProfileSubtitle = styled(EllipsisableTitle)`
  opacity: 0.8;
  margin: 0;
  font-weight: 400;
  font-size: 0.6875rem;
  line-height: 1.0625rem;
  color: #ffffff;
`;

const LogoutButton = styled(Button)`
  button[type='button']&.ant-btn.ant-btn-outlined {
    margin-top: 0.8125rem;
    width: 100%;
    background: transparent !important;
    font-weight: 400;
    color: #fff;

    &:hover {
      background: rgba(255, 255, 255, 0.2) !important;
      border: 1px solid #c6ced6;
      color: #cccccc;
    }
    &:focus,
    &:active {
      color: #ffffff;
      border: 1px solid #cccccc;
    }
  }
`;

const FooterButtonWrapper = styled.div`
  display: flex;
  width: 100%;
  justify-content: space-evenly;
`;

const FooterButton = styled(Button)`
  width: 4.375rem;
  font-weight: 500;
  color: ${props => props.theme.CoreNormal};
  &:hover {
    color: ${props => props.theme.CoreNormal};
  }
`;

import React from 'react';
import { BrowserRouter } from 'react-router-dom';
import ReactDOM from 'react-dom';
import { I18nextProvider } from 'react-i18next';
import { CoreStoreProvider } from 'teespace-core';
import { CookiesProvider } from 'react-cookie';
import i18n from './i18n';
import './index.css';
import { setEnv, getEnv } from './env';

import App from './App';
import * as serviceWorker from './serviceWorker';
import { StoreProvider } from './stores';
import MaintenancePage from './pages/MaintenancePage';
import ScrollPageTop from './components/ScrollPageTop';

if (process.env.NODE_ENV === 'production') {
  const script = document.createElement('script');
  script.src = '/tinymce/js/tinymce/tinymce.min.js';
  document.head.appendChild(script);
}

setEnv({
  serviceURL:
    global.env.REACT_APP_SERVICE_URL ||
    `${window.location.protocol}//${
      process.env.REACT_APP_SERVICE_DOMAIN || window.location.host
    }/${process.env.REACT_APP_SERVICE_PATH}`,
  comURL:
    global.env.REACT_APP_COMMON_URL ||
    `${window.location.protocol}//${
      process.env.REACT_APP_COM_DOMAIN || window.location.host
    }/${process.env.REACT_APP_COM_PATH}`,
  websocketURL:
    global.env.REACT_APP_WEBSOCKET_URL ||
    `${window.location.protocol === 'https:' ? 'wss:' : 'wss:'}//${
      process.env.REACT_APP_WEBSOCKET_DOMAIN || window.location.host
    }/${process.env.REACT_APP_WEBSOCKET_PATH}`,
  hsmURL:
    window.env.REACT_APP_HSM_URL === '%HSM_URL%'
      ? process.env.REACT_APP_HSM_URL
      : window.env.REACT_APP_HSM_URL || process.env.REACT_APP_HSM_URL,
});

ReactDOM.render(
  <CoreStoreProvider config={getEnv()}>
    <I18nextProvider i18n={i18n}>
      <CookiesProvider>
        <StoreProvider config={getEnv()}>
          {window.env.REACT_APP_MODE === 'maintenance' &&
          localStorage.getItem('maintenance') !== `true` ? (
            <MaintenancePage />
          ) : (
            // <PrivateRouteProvider>
            <BrowserRouter>
              <ScrollPageTop />
              <App />
            </BrowserRouter>
            // </PrivateRouteProvider>
          )}
        </StoreProvider>
      </CookiesProvider>
    </I18nextProvider>
  </CoreStoreProvider>,
  document.getElementById('root'),
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();

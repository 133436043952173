import React from 'react';
import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';
import styled from 'styled-components';
import NoInvitationImg from '../../assets/invitation_expired.svg';
import WorkspaceInvitationItem from './InvitationItem';
import WaplLogoComp from '../WaplLogo';

function ExpiredSpaceLinkContent() {
  const { t } = useTranslation('common');
  return (
    <>
      <Header>
        <WaplLogo />
      </Header>
      <Content>
        <WorkspaceInvitationItem title={t('CM_INVITE_PEOPLE_MAIL_LINK_06')}>
          <NoInvitationIcon />
          <GoToServiceLink to="/?force=true">
            {t('CM_SERVICE_INTRO')}
          </GoToServiceLink>
        </WorkspaceInvitationItem>
      </Content>
    </>
  );
}

export default ExpiredSpaceLinkContent;

const Header = styled.div`
  display: flex;
  flex-direction: column;
  height: 3.1875rem;
  justify-content: center;
  align-items: center;
`;

const Content = styled.div`
  display: flex;
  flex-direction: column;
  margin-top: 2.8125rem;
  justify-content: center;
  align-items: center;
`;

const WaplLogo = styled(WaplLogoComp)`
  flex: 1;
  width: 9.0625rem;
  height: 1.75rem;
`;

const NoInvitationIcon = styled.div`
  width: 9.375rem;
  height: 9.375rem;
  background: url('${NoInvitationImg}') 0 0 no-repeat;
  background-size: contain;
`;

const GoToServiceLink = styled(Link)`
  font-size: 0.8125rem;
  line-height: 1.1875rem;
  font-weight: 400;
  color: ${props => props.theme.MyHomeTextLink};
  text-decoration: underline;
  margin-top: 1.875rem;
  &:hover {
    color: ${props => props.theme.MyHomeTextLink};
  }
`;

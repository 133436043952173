import React, { useEffect, useRef } from 'react';
import { useKeycloak } from '@react-keycloak/web';
import { ComAPI as CoreComAPI, Loader, useCoreStores } from 'teespace-core';
import Cookies from 'js-cookie';
import { useStores } from '../stores';
import wwms from '../libs/wwms';
import ComAPI from '../libs/ComAPI';
import HyperAuthRepository from '../libs/HyperAuthRepository';
import { LogoutTimer, ssoType } from '../libs/auth';
import useColorScheme from '../libs/theme';
import Loading from '../components/Loading';

function MainPage({ children }) {
  const [loader] = Loader.useLoader();
  const { waplAuthStore, waplUserStore, themeStore } = useCoreStores();
  const { keycloak } = useKeycloak();
  const { spaceStore, friendStore } = useStores();
  const isLocal = process.env.REACT_APP_ENV === 'local';
  const isDark = useColorScheme('dark');
  const isMountSpaceFlag = useRef(false);
  const isMountFriendFlag = useRef(false);
  // theme
  useEffect(() => {
    if (waplUserStore.myProfile.theme !== 'system') {
      themeStore.setTheme(waplUserStore.myProfile.theme);
    }
  }, [themeStore, waplUserStore.myProfile.theme]);

  useEffect(() => {
    if (waplUserStore.myProfile.theme === 'system') {
      const themeName = isDark ? 'dark' : 'white';
      themeStore.setTheme(themeName);
    }
  }, [isDark, themeStore, waplUserStore.myProfile.theme]);

  // websocket 연결
  useEffect(() => {
    if (
      !wwms.isConnected &&
      keycloak.authenticated &&
      waplAuthStore.isAuthenticated
    ) {
      console.log('connect');
      wwms.connect(
        waplAuthStore.user.id,
        waplAuthStore.user.connectionId,
        'PC',
        waplUserStore.myProfile.loginId,
      );
    }
    // autologin
    if (!isLocal && ssoType !== 'keycloak') {
      HyperAuthRepository.getRememberMe({
        sessionState: keycloak.tokenParsed.session_state,
      }).then(result => {
        // on || off
        if (result === 'off') {
          LogoutTimer.start();
        }
      });
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    keycloak.authenticated,
    waplAuthStore.isAuthenticated,
    waplAuthStore.user.connectionId,
    waplAuthStore.user.id,
    waplAuthStore.sessionInfo.isTermAgree,
  ]);

  //
  useEffect(() => {
    // const query = new URLSearchParams(window.location.search);
    // const fetch = Boolean(location.state?.fetch || query.get('fetch'));

    const refreshTokenHandler = async () => {
      if (keycloak.authenticated) {
        const res = await keycloak.updateToken(60);
        Cookies.set(
          'ACCESS_TOKEN',
          keycloak.token,
          isLocal
            ? {}
            : {
                domain: `.${window.location.host}`,
              },
        );
        return res;
      }
      return false;
    };
    ComAPI.refreshTokenHandler = refreshTokenHandler;
    CoreComAPI.refreshTokenHandler = refreshTokenHandler;

    const fetchData = () => {
      Promise.resolve()
        .then(async () => {
          loader.loading();
          if (
            /* eslint no-underscore-dangle: 0 */
            !Object.keys(
              JSON.parse(localStorage.getItem('waplSpace'))?.__spaces,
            ).length
          ) {
            await spaceStore.fetchSpaces({
              userId: waplUserStore.myProfile.id,
            });
            isMountSpaceFlag.current = true;
          }
          if (
            !Object.keys(
              JSON.parse(localStorage.getItem('waplFriend'))?.__friends,
            ).length
          ) {
            await friendStore.fetchFriends({
              userId: waplUserStore.myProfile.id,
            });
            isMountFriendFlag.current = true;
          }
          loader.stop();
        })
        .then(() => {
          if (!isMountSpaceFlag.current) {
            spaceStore.fetchSpaces({ userId: waplUserStore.myProfile.id });
          }
          if (!isMountFriendFlag.current) {
            friendStore.fetchFriends({ userId: waplUserStore.myProfile.id });
          }
        });
    };
    fetchData();

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  return (
    <Loader loader={loader} component={<Loading />}>
      {children}
    </Loader>
  );
}

export default MainPage;

import React from 'react';
import styled from 'styled-components';

export default function ({ icon, typeName, title, description, onClick }) {
  return (
    <SpaceTypeItem onClick={onClick}>
      {icon}
      <SpaceTypeName>{typeName}</SpaceTypeName>
      <SpaceTypeTitle>{title}</SpaceTypeTitle>
      <SpaceTypeDescription>{description}</SpaceTypeDescription>
    </SpaceTypeItem>
  );
}

const SpaceTypeItem = styled.button`
  cursor: pointer;
  background: ${props => props.theme.StateNormal};
  flex: 1;
  width: 12.8125rem;
  border: 1px solid ${props => props.theme.LineMain};
  border-radius: 0.875rem;
  padding: 1.8125rem 0 1.25rem 0;
  display: flex;
  flex-direction: column;
  align-items: center;
  &:hover {
    background-color: ${props => props.theme.StateBright};
    border: 1px solid ${props => props.theme.StateBright};
  }
  &:active,
  &:focus {
    background-color: ${props => props.theme.StateDark};
    border: 1px solid ${props => props.theme.StateDark};
  }
  &:not(:first-child) {
    margin-left: 1.875rem;
  }
`;

const SpaceTypeName = styled.h1`
  margin: 1.375rem 0 0.625rem 0;
  font-size: 0.9375rem;
  line-height: 1.375rem;
  color: ${props => props.theme.TextMain};
  font-weight: 500;
`;

const SpaceTypeTitle = styled.h1`
  margin: 0 0 0.625rem 0;
  font-size: 0.75rem;
  line-height: 1rem;
  color: #7b7671;
  font-weight: 400;
`;

const SpaceTypeDescription = styled.span`
  margin: 0;
  display: flex;
  align-items: center;
  height: 3.125rem;
  font-size: 0.75rem;
  line-height: 1rem;
  text-align: center;
  color: #696969;
  font-weight: 400;
  white-space: pre-line;
`;

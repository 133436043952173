import React, { useContext } from 'react';
import { Tooltip } from 'antd';
import styled, { ThemeContext } from 'styled-components';
import talkImg from '../../assets/talk.svg';
import meetingImg from '../../assets/meeting.svg';
import { getDomain } from '../../libs/domain';
import EllipsisableTitle from '../EllipsisableTitle';

export default function ({ friendInfo }) {
  const theme = useContext(ThemeContext);
  return (
    <Wrapper>
      <FriendProfile src={friendInfo.userProfilePhotoURL} />
      <FriendName>{friendInfo.displayName}</FriendName>
      <Tooltip title="1:1 톡" placement="bottom" color={theme.CoreDark}>
        <IconButtonWrapper
          href={`${getDomain(friendInfo.domain)}/f/${
            friendInfo.id
          }?action=talk`}
        >
          <TalkButton />
        </IconButtonWrapper>
      </Tooltip>
      <Tooltip title="1:1 미팅" placement="bottom" color={theme.CoreDark}>
        <IconButtonWrapper
          href={`${getDomain(friendInfo.domain)}/f/${
            friendInfo.id
          }?action=meeting`}
        >
          <MeetingButton />
        </IconButtonWrapper>
      </Tooltip>
    </Wrapper>
  );
}

const Wrapper = styled.div`
  width: 18.75rem;
  height: 3rem;
  padding: 0.675rem 0.625rem 0.675rem 0.7375rem;
  display: flex;
  flex-direction: row;
  align-items: center;
`;

const FriendProfile = styled.img`
  width: 1.65rem;
  height: 1.65rem;
  border-radius: 50%;
  object-fit: cover;
`;

const FriendName = styled(EllipsisableTitle)`
  font-weight: 400;
  font-size: 0.8125rem;
  line-height: 1.1875rem;
  color: ${props => props.theme.TextMain};
  margin-left: 0.425rem;
  flex: 1;
`;

const IconButtonWrapper = styled.a`
  width: 1.5rem;
  height: 1.5rem;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 0.25rem;
  &:not(:first-child) {
    margin-left: 0.5rem;
  }
  &:hover {
    background-color: ${props => props.theme.StateBright};
  }

  &:active,
  &:focus {
    background: ${props => props.theme.StateDark};
  }
`;
const IconButton = styled.i`
  background-color: ${props => props.theme.TextSub};
  width: 1rem;
  height: 1rem;
`;
const TalkButton = styled(IconButton)`
  display: inline-block;
  mask-image: url('${talkImg}');
  mask-repeat: no-repeat;
  mask-size: 100%;
`;

const MeetingButton = styled(IconButton)`
  display: inline-block;
  mask-image: url('${meetingImg}');
  mask-repeat: no-repeat;
  mask-size: 100%;
`;

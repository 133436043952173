import {
  WaplAuthRepository,
  WaplUserStore,
  WaplAuthStore,
} from 'teespace-core';
import { SpaceStore } from './stores';
import { convertFromDtoObjToPlatformObj as spaceConverter } from './stores/repositories/SpaceRepository';

function updateDefaultStatus(WWMS, deviceType) {
  const isLocal = process.env.REACT_APP_ENV === 'local';
  WaplAuthRepository.updateUserStatus({
    userId: WaplAuthStore.user.id,
    userLoginId: WaplAuthStore.user.loginId,
    connectionId: WaplAuthStore.user.connectionId,
    deviceType,
    isLocal,
  });
}

function addWebsocketDefaultHandlers(WWMS) {
  /*
  WWMS.addHandler('CHN0002', 'SPACE_UNREAD_COUNT', msg => {
    let notiMsg;
    let unreadToRead;
    let readToUnread;
    let domainKey;
    try {
      notiMsg = JSON.parse(msg.NOTI_MSG);
      domainKey = SpaceStore.spaceList.find(spaceModel => {
        const searched = spaceModel.alarmList.find(alarm => {
          console.log(alarm.roomId, msg.ROOM_ID);
          return alarm.roomId === msg.ROOM_ID;
        });
        return searched;
      })?.domainKey;
    } catch (e) {
      notiMsg = null;
    }
    switch (msg.NOTI_TYPE) {
      case 'ML102':
      case 'tmail_ML102':
        if (domainKey && notiMsg.displayName === '받은 편지함') {
          SpaceStore.increaseUnreadMailCount({ domainKey, qty: 1 });
        }
        break;
      case 'ML201':
      case 'tmail_ML201':
        unreadToRead = notiMsg.unreadToRead;
        readToUnread = notiMsg.readToUnread;
        if (domainKey) {
          SpaceStore.decreaseUnreadMailCount({ domainKey, qty: unreadToRead });
          SpaceStore.increaseUnreadMailCount({ domainKey, qty: readToUnread });
        }
        break;
      default:
        break;
    }
  });
  */
  // 탈퇴

  WWMS.addHandler('CHN0001', 'SPACE_UNREAD_COUNT', msg => {
    let notiMsg;
    try {
      notiMsg = JSON.parse(msg.NOTI_ETC);
    } catch (e) {
      notiMsg = null;
    }
    switch (msg.NOTI_TYPE) {
      case 'ttalk_all':
      case 'secret':
        // 톡이 왔는데 본인이 보낸게 아닌 경우
        if (
          notiMsg.MSG_TYPE === 'create' &&
          msg.USER_LOGIN_ID !== WaplUserStore.myProfile.userLoginId
        ) {
          SpaceStore.increaseUnreadSpaceCount({
            domainKey: msg.SPACE_ID,
            roomId: msg.ROOM_ID,
            qty: 1,
          });
          // 내가 읽은 경우
        } else if (
          notiMsg.MSG_TYPE === 'read' &&
          msg.USER_LOGIN_ID === WaplUserStore.myProfile.userLoginId
        ) {
          // 본인이 보낸 거면 해당 스페이스의 unreadSpaceCount를 변경한다.
          SpaceStore.setUnreadSpaceCountZero({
            domainKey: msg.SPACE_ID,
            roomId: msg.ROOM_ID,
          });
        }
        break;
      default:
        break;
    }
  });
  WWMS.addHandler('SYSTEM', 'SYSTEM_0', msg => {
    switch (msg.NOTI_TYPE) {
      case 'domain_info_update_name':
      case 'domain_info_update_url':
        const spaceAlarmList = SpaceStore.spaceList.find(
          spaceInfo =>
            spaceInfo.domainKey === JSON.parse(msg.NOTI_MSG).DOMAIN_KEY,
        );
        const alarmListObj = spaceConverter(JSON.parse(msg.NOTI_MSG));
        alarmListObj.alarmList = spaceAlarmList.alarmList;
        SpaceStore.setSpace(alarmListObj);
        break;
      case 'userDelete':
      case 'domain_delete':
      case 'domain_create':
      case 'initRoom':
        // 스페이스 리스트 최신화
        SpaceStore.fetchSpaces({ userId: WaplUserStore.myProfile.id });
        break;
      default:
        break;
    }
  });
}
export { updateDefaultStatus };
export { addWebsocketDefaultHandlers };

import React, { useEffect, useRef } from 'react';
import { useTranslation } from 'react-i18next';
import styled, { css } from 'styled-components';
import { isMobile } from 'react-device-detect';
import waplLogoText from '../../assets/logo_Wapl_text.svg';
import arrowDownLineImg from '../../assets/arrow_down_line.svg';
import arrowDownLineWhiteImg from '../../assets/arrow_bottom_1_white.svg';

const PagePolicy = styled.div`
  ${props =>
    props.ismobile
      ? css`
          width: 100%;
          display: flex;
          flex-direction: column;
          overflow-x: hidden;
        `
      : css`
          width: 100%;
          display: flex;
          flex-direction: column;
        `}
`;

const PageHeader = styled.div`
  ${props =>
    props.ismobile
      ? css`
          background-color: #0a1d41;
          height: 4.69rem;
          color: #fff;
          display: flex;
          flex-shrink: 0;
          flex-flow: row wrap;
        `
      : css`
          background-color: #0a1d41;
          height: 5rem;
          color: #fff;
          display: flex;
          align-items: center;
          flex-shrink: 0;
        `}
`;

const PageHeaderImg = styled.img`
  ${props =>
    props.ismobile
      ? css`
          height: 1rem;
        `
      : css`
          width: 4.38rem;
          height: 100%;
          margin-right: 0.63rem;
        `}
`;

const PageHeaderLine = styled.h1`
  ${props =>
    props.ismobile
      ? css`
          font-size: 16px;
          color: #ffffff;
          letter-spacing: 0;
        `
      : css`
          margin: 0;
          font-size: 1.5rem;
          color: #ffffff;
          letter-spacing: 0;
          line-height: 2.25rem;
        `}
`;

const PageHeaderInner = styled.div`
  ${props =>
    props.ismobile
      ? css`
          width: 100%;
          height: 100%;
          display: flex;
          align-items: flex-start;
          justify-content: space-between;
        `
      : css`
          width: 59.31rem;
          margin: 0 auto;
          display: flex;
          align-items: center;
        `}
`;

const PageHeaderInnerWrapper = styled.div`
  ${props =>
    props.ismobile
      ? css`
          display: flex;
          flex-direction: column;
          font-size: 1rem;
          color: #ffffff;
          letter-spacing: 0;
          align-items: flex-start;
          padding-left: 1rem;
          padding-top: 1rem;
        `
      : css`
          display: flex;
          flex-direction: row;
          align-items: center;
        `}
`;

const HistoryContainer = styled.div`
  ${props =>
    props.ismobile
      ? css`
          margin-left: 0;
          display: flex;
          flex-direction: column-reverse;
          align-items: center;
          justify-content: space-between;
          font-size: 0.56rem;
          font-weight: 200;
          color: #ffffff;
          letter-spacing: 0;
          padding-top: 1rem;
          padding-right: 1rem;
        `
      : css`
          margin-left: auto;
          font-size: 0.75rem;
          display: flex;
          align-items: center;
        `}
`;

const HistoryContainerSpan = styled.span`
  ${props =>
    props.ismobile
      ? css`
          font-size: 0.56rem;
          font-weight: 200;
          color: #ffffff;
          letter-spacing: 0;
          text-decoration: underline;
        `
      : css`
          font-size: 0.56rem;
          font-weight: 200;
          color: #ffffff;
          letter-spacing: 0;
          text-decoration: underline;
        `}
`;

const HistoryContainerSelect = styled.select`
  cursor: pointer;
  ${props =>
    props.ismobile
      ? props.language === 'en'
        ? css`
            width: 9.75rem;
            height: 1.75rem;
            font-size: 10px;
            color: #ffffff;
            padding-left: 0.75rem;
            margin-left: 0rem;
            margin-bottom: 0.25rem;
            letter-spacing: 0;
            border: 1px solid #d0ccc7;
            border-radius: 4px;
            -webkit-appearance: none;
            -moz-appearance: none;
            appearance: none;
            outline: none;
            background: url(${arrowDownLineWhiteImg}) #0a1d41 no-repeat 95% 50%;
          `
        : css`
            width: 8.75rem;
            height: 1.75rem;
            font-size: 10px;
            color: #ffffff;
            padding-left: 0.75rem;
            margin-left: 0rem;
            margin-bottom: 0.25rem;
            letter-spacing: 0;
            border: 1px solid #d0ccc7;
            border-radius: 4px;
            -webkit-appearance: none;
            -moz-appearance: none;
            appearance: none;
            outline: none;
            background: url(${arrowDownLineWhiteImg}) #0a1d41 no-repeat 95% 50%;
          `
      : props.language === 'en'
        ? css`
          margin-left: 1rem;
          width: 10.38rem;
          height: 1.88rem;
          font-size: 0.75rem;
          color: #3b3b3b;
          padding-left: 0.75rem;
          border: 1px solid #c6ced6;
          border-radius: 1.56rem;
          -webkit-appearance: none;
          -moz-appearance: none;
          appearance: none;
          outline: none;
          background: url(${arrowDownLineImg}) #fff no-repeat 95% 50%;
        `
        : css`
          margin-left: 1rem;
          width: 8.88rem;
          height: 1.88rem;
          font-size: 0.75rem;
          color: #3b3b3b;
          padding-left: 0.75rem;
          border: 1px solid #c6ced6;
          border-radius: 1.56rem;
          -webkit-appearance: none;
          -moz-appearance: none;
          appearance: none;
          outline: none;
          background: url(${arrowDownLineImg}) #fff no-repeat 95% 50%;
        `}
`;

const PageBody = styled.div`
  flex: 1;
  overflow: hidden;
  display: flex;
`;

const PageBodyPre = styled.pre`
  overflow: auto;
  white-space: pre-wrap;
  font-size: 0.75rem;
  font-family: 'NotoSansKR', sans-serif;
  color: #232323;
  ${props =>
    props.ismobile
      ? css`
          margin: 20px;
        `
      : css`
          margin: 0;
        `}
`;

const PageInner = styled.div`
  ${props =>
    props.ismobile
      ? css`
          width: 59.31rem;
          margin: 0 0;
          overflow: hidden;
          padding: 0 auto;
        `
      : css`
          width: 59.31rem;
          margin: 0 auto;
          overflow: hidden;
          margin: 1.38rem auto 1.56rem auto;
        `}
`;

const PageInnerContents = styled.div`
  overflow: auto;
  height: 100%;
  padding-right: 0.5rem;
`;

const TermAndConditions = () => {
  const defaultValue = '2021-02-22';
  const { t, i18n } = useTranslation('service');
  const dropdownRef = useRef([]);

  const setShowContent = Id => {
    document.getElementById(Id).style.display = 'block';
  };
  const setHideContent = Id => {
    document.getElementById(Id).style.display = 'none';
  };

  useEffect(() => {
    for (const date of dropdownRef.current) {
      if (date.value !== defaultValue) {
        document.getElementById(date.value).style.display = 'none';
      }
    }
  }, []);

  const onHandleChangeSelect = e => {
    const k = e.target.value;

    for (const res of dropdownRef.current) {
      res.value !== k ? setHideContent(res.value) : setShowContent(res.value);
    }
  };

  return (
    <>
      <PagePolicy ismobile={isMobile}>
        <PageHeader ismobile={isMobile}>
          <PageHeaderInner ismobile={isMobile}>
            <PageHeaderInnerWrapper ismobile={isMobile}>
              <PageHeaderImg
                alt="waplLogo"
                src={waplLogoText}
                ismobile={isMobile}
              />
              <PageHeaderLine ismobile={isMobile}>
                {t('SP_USE_TERMS_01')}
              </PageHeaderLine>
            </PageHeaderInnerWrapper>
            <HistoryContainer ismobile={isMobile}>
              <HistoryContainerSpan ismobile={isMobile}>
                {t('SP_USE_TERMS_02')}
              </HistoryContainerSpan>
              <HistoryContainerSelect
                defaultValue={defaultValue}
                onChange={onHandleChangeSelect}
                ismobile={isMobile}
                language={i18n.language}
                ref={dropdownRef}
              >
                <option value="2021-02-22">
                  {t('SP_PRIVACY_POLICY_03', { value: '2021.02.22' })}
                </option>
                <option value="2020-12-14">
                  {t('SP_PRIVACY_POLICY_03', { value: '2020.12.14' })}
                </option>
                <option value="2020-11-09">
                  {t('SP_PRIVACY_POLICY_03', { value: '2020.11.09' })}
                </option>
                <option value="2020-06-22">
                  {t('SP_PRIVACY_POLICY_03', { value: '2020.06.22' })}
                </option>
                <option value="2020-05-25">
                  {t('SP_PRIVACY_POLICY_03', { value: '2020.05.25' })}
                </option>
                <option value="2019-08-15">
                  {t('SP_PRIVACY_POLICY_03', { value: '2019.08.15' })}
                </option>
              </HistoryContainerSelect>
            </HistoryContainer>
          </PageHeaderInner>
        </PageHeader>
        <PageBody>
          <PageInner ismobile={isMobile}>
            <PageInnerContents id="2021-02-22">
              <PageBodyPre ismobile={isMobile}>
                <br />
                {t('SP_USE_TERMS_02_22')}
              </PageBodyPre>
            </PageInnerContents>
            {i18n.language === 'en' ? (
              <PageInnerContents id="2020-12-14">
                <PageBodyPre ismobile={isMobile}>
                  {`
1. Purpose
These Terms of Use ("Terms") are intended to stipulate the rights, obligations and responsibilities of TmaxA&C Co., Ltd. (the "Company") and the User as well as to specify other requirements in using the services of WAPL provided by the Company.

2. Definition
2.1. The definitions of terms used herein are as follows.
“WAPL” means all the WAPL application services which include messenger, mail, scheduling, notetaking, office tools, video conferencing and file storage, and all other related services such as technical support to help users of WAPL.  
“User” means a person bound by the use agreement with the Company signed in accordance with these Terms, and thus qualified by the Company to use WAPL.
The following are the different types of authorities of WAPL user.
  - USER: A person eligible to use the services after having completed the email confirmation. 
  - ADMIN: A person who creates a new Space.
  - ROOM MANAGER: A person who creates a room, or a user who has been granted the right to manage the room.
  - MEMBER: A person invited to a Space and represented in the organizational chart. MEMBER is also the type of an authority given within the room. 
WAPL “ID”: An email address registered by the User for identification and use of WAPL. 
WAPL “Password”: A combination of letters, numbers and special characters set up by the User to protect his/her privacy.
2.2. All terms used in this document but not stipulated in the present Article comply with applicable laws and commercial practices.

3. Effect and Change of Terms
3.1. The content of these Terms is published on the WAPL website, and applicable to all parties agreeing to these Terms. 
3.2. For any other matters not stipulated herein, the following shall apply, in the order of priority: applicable laws, commercial practices.
3.3. If necessary, the Company can change these Terms to the extent that such change remains compliant with applicable laws.
Any updates to these Terms will be notified or announced 7 days prior to the effective date via the Service Notification. If any changes are deemed unfavorable to the User, the Company will inform the User of such modifications 30 days prior to the effective date by sending an email to the address registered in the WAPL account of the User, while also adding a pop-up notice in the website. 
3.4. In the absence of any explicit opposition from the User within the notice period even after the Company’s notification as specified in the section above, the changes of these Terms shall be deemed to have been agreed to.
3.5. If the User opposes to any changes, the updated Terms cannot come into effect, and the User can rescind the use agreement. Under particular circumstances however, where the existing version of these Terms cannot take effect, the Company may rescind the use agreement.


4. Conclusion of Use Agreement
4.1. The use agreement of WAPL is concluded when a person seeking to join the WAPL membership (“applicant for WAPL membership”) completes the membership application process as guided by the Company. 
4.2. If the capacity of WAPL infrastructures is not sufficient or there are any technical or business issues, the Company may reserve the membership admission.
4.3. If the membership application has been refused or reserved for the reason specified in the section above, the Company will as a rule inform the applicant of such decision.   
4.4. The time of conclusion of use agreement is the point of time at which the Company marked the completion of the membership application process.
4.5. After joining the membership, the User can use WAPL at no cost, but some information can be accessed only after the specified fees are paid. 


5. Protection of Personal Information
The User’s personal information is used only within the scope and purposes agreed by him/her to ensure a smooth operation of services. When obliged to share any piece of personal information with third parties beyond the extent allowed by applicable laws, the Company shall obtain an additional approval from the User.
Please refer to the WAPL Privacy Policy for further details.

6. Use of WAPL
6.1. WAPL is operated in accordance with the operational policy established by the Company. When required for any operational or technical reasons, the Company may change this operational policy or suspend the operation of WAPL. 
6.2. In the case where the operation of WAPL is suspended as specified in the section above, the Company will notify the User of the suspension in accordance with the procedures specified in Article 7 below. In the event of a suspension resulting from a force majeure event, however, there may be no prior notification.
6.3. In case of failure to comply with the requirements set forth in these Terms during the use of WAPL by the User based on these Terms, the Company may immediately suspend the operation of WAPL services provided to the User. 
6.4. In the case of suspension of services under the circumstance specified in the section above, the User shall send an inquiry regarding the resumption of services via email to the Customer Support (wapl_support@tmax.co.kr) in order to continue to use WAPL.

7. Notification of Company
7.1. The Company delivers notifications to the email address registered during the membership application or via the Service Notification in the Customer Support menu of the WAPL site. If the Company has notified the Admin of certain information, it shall be considered that the members have been made aware of such information as well. The User is held responsible for managing and correcting the information about the Admin as needed.  
7.2. Any notifications addressed to all users of WAPL will be posted in the noticeboard of the WAPL website for at least 7 days, which can replace the notification process specified in the section above.  
7.3. The Admin is responsible for forwarding all notifications he/she received from the Company to the members.

8. Cancellation of Use Agreement of WAPL by the Company
8.1. The Company can delete all data related to the User and terminate the use agreement in the cases where:
1) the User fails to comply with any obligations specified in these Terms
2) the User causes any disturbances to the proper operation of WAPL
3) the User uses WAPL by any illegal or unjustifiable means
4) the Company determines that termination of the use agreement with the User is necessary for any other reasons equally justified as above.
8.2. The present Article hereby specifies that, if the User is no longer able to use WAPL, the Company is not held liable for any damages incurred as a result of his/her inability to use WAPL.

9. Cancellation of Use Agreement of WAPL by the User
9.1. The User can cancel the WAPL use agreement at any time. 
9.2. When canceling the WAPL use agreement, the User must manually take a backup of all resources he/she has used during the service period before the cancellation. 
9.3. When the User cancels the WAPL use agreement as per 9.1, all resources that have been provided to the User are revoked, and all information stored in WAPL is deleted, which will not be recovered for any reason, except when the Company needs to retain the User’s information to comply with applicable laws and its Privacy Policy or when some of the User’s posts are maintained in accordance with 9.4.
9.4. Any posts or comments shared with third parties are not deleted. The User needs to delete them by him/herself before canceling the services.
9.5 If the User joined the WAPL membership directly in WAPL, he/she can cancel the use agreement in the WAPL website.

10. Withdrawal of Subscription, Cancellation of Agreement 
10.1. The User can request cancellation of the use agreement at any time by various means such as written request or email (wapl_support@tmax.co.kr). The Company will immediately process such requests in compliance with applicable laws. 
10.2. In compliance with applicable laws including 『Personal Information Protection Act』, the Company may terminate the use agreement and membership of the User if he/she has been confirmed not to use WAPL for the past 1 year by sending notification 30 days prior to the termination date in accordance with the process set forth in Article 7. In the case of termination of membership, the Company will immediately delete the User’s data.
10.3. In the case of a cancellation of use agreement of WAPL by the User, all data of the User will be kept for 7 days from the cancellation date before being subsequently deleted. However, the Company may retain the User’s data for the required period of time in accordance with applicable laws and the WAPL Privacy Policy.
10.4. When the User cancels the WAPL use agreement, member’s WAPL-related data will be entirely deleted as specified by 10.3. The User needs to take a backup of user and member data by him/herself before canceling the use agreement. The Company is not liable for any problems caused by not taking any backup of data by the User.   


11. Indemnification
11.1. The Company is not liable for any damages incurred to the User in connection with the operation of WAPL based on these Terms.
11.2. The User is held responsible for all losses and damages suffered by the Company as a result of his/her use of WAPL.


12. Miscellaneous
12.1. Severability
If any provisions of these Terms are deemed invalid by a court of competent jurisdiction or determined to be inconsistent with applicable laws, then such provisions will be, to that extent, deemed omitted and the remaining provisions will continue in full force and effect.
12.2. Acknowledgement of Agreement
The User hereby acknowledges that he/she has read, understood and agreed to all the details set forth in these Terms.
12.3. Settlement of Disputes
These Terms are governed and construed in accordance with the laws of the Republic of Korea, and Seoul Central District Court has exclusive jurisdiction over all disputes, controversies or claims arising under, out of, or related to the use of WAPL. 
12.4. Contact Information
For any questions about these Terms, send an email to:
wapl_support@tmax.co.kr
12.5 Consent of Member 
The Admin assures that the members agree to the application of these Terms and the WAPL Privacy Policy. The Company is not responsible for any dispute resulting from disagreement between the Admin and the members regarding particular provision.


TmaxA&C Co., Ltd. 
6 ~ 9F BS Tower, Hwangsaeul-ro, Bundang-gu, Seongnam-si, Gyeonggi-do
13595, South Korea
Website: https://tmaxanc.com
              `}
                </PageBodyPre>
              </PageInnerContents>
            ) : (
              <PageInnerContents id="2020-12-14">
                <PageBodyPre ismobile={isMobile}>
                  {`
제 1 조 목적
본 약관은 ㈜티맥스에이앤씨가 제공하는 WAPL의 이용과 관련하여 회사와 회원과의 권리, 의무 및 책임사항, 기타 필요한 사항을 규정함을 목적으로 합니다.

제 2 조 정의
2.1. 본 약관에서 사용하는 용어의 의미는 다음과 같습니다. 
    1) ‘WAPL’는 회사가 제공하는 메신저, 메일, 스케줄, 노트, 오피스, 화상회의, 
      파일저장소 등을 포함하는 WAPL앱 서비스와 WAPL이용을 위한    
      기술지원 등 관련 제반 서비스를 의미합니다.
    2) ‘회원’이라 함은 회사의 WAPL에 접속하여 본 약관에 따라 회사와 이용계약을 
       체결하고 회사로부터 WAPL이용 자격을 부여 받은 자를 의미합니다. 
3) WAPL의 회원 권한은 다음과 같이 정의합니다. 
-유저: 이메일 인증이 완료되어 서비스를 이용할 수 있는 상태
-어드민: 어드민 권한을 가진 구성원으로 도메인으로 최초로 만든 사람이 부여 받음
-룸관리자: 구성원 중 룸을 최초로 생성했거나, 해당 권한을 부여 받은 유저
-멤버: 서비스를 활용하는 조직도 기반 구성원으로서, 룸 내 권한을 지칭하기도 함
    4) WAPL ‘아이디(ID)’라 함은 회원의 식별과 WAPL의 이용을 위하여 
      회원이 설정한 이메일 주소를 의미합니다. 
    5) WAPL ‘비밀번호’라 함은 회원의 비밀보호를 위하여 WAPL
회원이 설정한 문자와 숫자, 특수문자의 조합을 말합니다. 


2.2. 본 약관에서 사용하는 용어 중 본 조에서 정하지 아니한 것은, 관계법령 및 상관례에 따릅니다.

제 3 조 약관의 효력 및 변경
3.1. 본 약관의 내용은 WAPL 홈페이지 서비스 화면에 게시되며, 
본 약관에 동의한 모두에게 효력이 있습니다.
3.2. 본 약관에 규정되지 않은 사항에 대해서는 관련 법령 및 상관례의 순서로 적용됩니다.
3.3. 회사는 필요한 경우 관련 법령을 위배하지 않는 범위 내에서 본 약관을 변경할 수 있습니다.
본 약관이 변경되는 경우 회사는 변경사항을 시행 일자 7일 전부터 서비스 공지사항에서 
공지 또는 통지하는 것을 원칙으로 하며, 회원에게 불리한 내용으로 변경될 경우에는 그 
시행일자 30일 전부터 WAPL 계정에 등록된 이메일 주소로 전자메일 발송을 통한 개별 공지 및 홈페이지내 알림 메시지를 통한 전체 공지를 하겠습니다.
3.4. 회사가 전항에 따라 개정약관을 공지 또는 통지하면서 회원에게 공지 기간 내에 거부의 의사표시를 하지 않으면 약관 개정에 동의한 것으로 본다는 뜻을 명확하게 통지 또는 공지하였음에도 회원이 명시적으로 거부의 의사표시를 하지 아니한 경우 회원이 개정약관에 동의한 것으로 봅니다.
3.5. 변경 사항에 동의하지 않는 경우 회사는 개정약관의 내용을 적용할 수 없으며, 이 경우 회원은 이용계약을 해지할 수 있습니다. 다만, 기존 약관을 적용할 수 없는 특별한 사정이 있는 경우에는 회사가 회원과의 이용계약을 해지할 수 있습니다.


제 4 조 이용계약 체결
4.1. WAPL이용계약은 WAPL회원이 되고자 하는 자(이하 “WAPL이용신청자”라 함)가 회사가 제공하는 회원가입 방법에 따라 WAPL회원가입 절차를 완료함으로써 체결됩니다. 
4.2. 회사는 WAPL 관련 설비의 여유가 없거나, 기술상 또는 업무상 문제가 있는 경우에는 승낙을 유보할 수 있습니다.
4.3. 본 조에 따라 회원가입신청의 승낙을 하지 아니하거나 유보한 경우, 회사는 원칙적으로 이를 이용신청자에게 알립니다.
4.4. WAPL 이용계약의 성립 시기는 회사가 회원가입신청에 대한 완료를 신청절차 상에서 표시한 시점으로 합니다.
4.5. WAPL은 가입 후 무료로 사용 가능합니다. 단, 향후 별도로 유료임을 명시한 정보에 대해서는 해당 정보에 명시된 요금을 지불하여야 사용이 가능합니다. 


제 5 조 개인정보의 보호 
회원의 개인정보는 서비스의 원활한 제공을 위하여 회원이 동의한 목적과 범위 내에서만 이용됩니다. 관련 법령에 의하지 아니하는 한 회사가 개인정보를 제 3자에게 제공하는 경우 회원의 별도 동의를 받아야 합니다. 

기타 자세한 사항은 WAPL개인정보처리방침을 참고해 주시기 바랍니다. 

제 6 조 WAPL의 이용
6.1. WAPL는 회사가 정한 운영정책에 따라 제공되는 것이며, 회사는 운영, 
기술 상의 필요에 따라 언제든 위 운영정책을 변경하거나 WAPL의 제공을 중단할 수 있습니다.
6.2. 전항에 따라 WAPL이용이 중단될 경우 회사는 제 8 조에서 정한 방법으로 WAPL 회원에게 사전에 통지해야 합니다. 단, 불가항력적인 사유로 중단될 경우에는 통지하지 않을 수 있습니다. 
6.3. 회원이 본 약관에 기초하여 WAPL를 이용하던 중 회사가 본 약관에서 정한 기준을 충족시키지 못하게 되는 경우, 회사는 즉시 해당 회원에게 제공하던 WAPL의 제공을 중단할 수 있습니다. 
6.4. 전항에 따라 이용이 중단된 회원이 계속하여 WAPL를 제공받기 위해서는 
별도로 마련된 고객문의 이메일(wapl_support@tmax.co.kr )을 통해서 서비스 이용과 관련된 
문의 절차를 거쳐야 합니다.

제 7 조 회사의 통지
회사가 회원에게 고지할 내용이 있는 경우 회원가입 시 기재한 이메일 주소 혹은 고객지원 내 서비스 공지로 통지 합니다. 회사가 어드민에게 통지한 경우 이는 회원에게 통지한 것으로 봅니다. 회원은 어드민 정보에 대한 관리 및 적절한 정보로의 변경 책임을 부담합니다.
회원 전체에 대한 통지의 경우 회사는 7일 이상 WAPL 홈페이지 게시판에 해당 내용을 게시함으로써 전항의 통지를 갈음할 수 있습니다.
어드민은 회사가 어드민에게 통지한 사항에 대하여 멤버에게 통지할 책임을 부담합니다.

제 8 조 회사의 WAPL이용 계약 해지 등
8.1 회사는 다음 각 호의 경우 해당 회원에 관련된 일체의 데이터를 삭제하고 회원과의 
WAPL이용계약을 해지할 수 있습니다.  
    1) 본 약관 상 의무를 위반하는 경우 
    2) 회사의 정상적인 WAPL운영을 방해한 경우 
    3) 불법 혹은 부당한 방법으로 WAPL를 이용하는 경우 
    4) 위 각 호에 준하는 사유로 WAPL이용계약의 해지가 필요하다고 회사가 
       판단한 경우 
8.2 본 조에 기초하여 회원이 WAPL를 이용하지 못하게 되는 경우에도 회사는 
회원에게 어떠한 손해배상의무도 부담하지 아니합니다.

제 9 조 회원의 WAPL이용 계약 해지
9.1. 회원은 언제든지 WAPL이용 계약을 해지할 수 있습니다. 
9.2. WAPL이용 계약 해지 시, 회원은 WAPL이용기간 동안 사용한 모든 자원에 대하여 해지 전 직접 백업하여야 합니다. 
9.3. 제 1 항에 따라 회원이 WAPL이용 계약을 해지할 경우 관계법령 및 개인정보처리방침에 따라 회사가 회원의 정보를 보유하거나 9.4.에 따라 회원의 게시물 정보를 보유하는 경우를 제외하고는 회원에게 제공된 모든 자원이 회수되며 WAPL상에 저장되어 있는 모든 정보는 삭제되고, 삭제된 자료는 어떠한 이유로도 복원되지 않습니다.
9.4. 제3자와 공유되고 있는 회원의 게시글 또는 댓글 등의 경우에는 삭제되지 않으므로 반드시 이용 종료 전에 스스로 삭제하시기 바랍니다.
9.5 WAPL에서 가입한 회원은 WAPL 홈페이지에서 계약 해지가 가능합니다.

제 10 조 청약철회, 계약해지
10.1. 회원은 언제든지 서면, 이메일(wapl_support@tmax.co.kr) 등의 방법을 통하여 WAPL 이용계약 해지 신청을 할 수 있으며, 회사는 관련 법령 등이 정하는 바에 따라 이를 즉시 처리하여야 합니다. 
10.2. 회사는 개인정보보호법 등 관련 법령이 정하는 바에 따라, 1년 동안 모든 멤버가 본 서비스를 이용하지 않는 등 회원이 해당 기간 동안 WAPL를 이용하지 않은 것을 확인하는 경우 기간 만료 30일 전 제7조의 방법으로 통지 후 회원과의 이용 계약을 해지하고 탈퇴 조치를 할 수 있습니다. 탈퇴 조치를 취하는 경우, 회사는 관련 법령에 따라 회원을 포함한 멤버의 데이터를 즉시 삭제합니다.
10.3. 회원이 WAPL 이용계약을 해지할 경우, 회원을 포함한 멤버의 모든 데이터는 이용계약의 해지 후 7일간 보관되며, 7일이 지난 후 즉시 삭제됩니다. 다만, 회사는 관련 법령 및 개인정보처리방침에 따라 회원을 포함한 멤버의 데이터를 관련 법령 및 개인정보처리방침에서 정한 기간 동안 보유할 수 있습니다.
10.4. 회원의 WAPL 이용계약 해지 시 멤버의 WAPL 관련 데이터는 제3항에서 정한 바에 따라 모두 삭제됩니다. 회원은 WAPL 이용계약 해지 전 회원 및 멤버의 데이터를 스스로 백업하여야 하며, 회원이 데이터를 백업하지 않아 발생한 문제에 대해 회사는 책임을 부담하지 않습니다.




제 11 조 손해배상
11.1. 회사는 본 약관에 따른 WAPL의 제공과 관련하여, 회원에게 발생한 어떠한
손해에 대하여도 책임지지 않습니다. 
11.2. 회원은 자신의 WAPL의 이용과 관련하여 회사에 손해가 발생한 경우, 
회사에게 발생한 모든 손실, 손해를 책임지고 배상하여야 합니다.

제 12 조 기타
12.1. 분리가능
본 약관의 일부 조항이 관할 지역의 관련 법률에 의하여 무효로 해석되거나 법률에 위반하게 되는 경우 해당 조항은 관련 법률에 따라 합법적인 범위 내로 해석되거나 무효가 될 수 있습니다. 해당 조항의 무효 등은 본 약관의 유효성 및 다른 조항의 내용에 영향을 미치지 않습니다.
12.2. 계약서의 인정
회원은 본 약관에 명시된 모든 내용을 읽고 이해, 동의한 것임을 인정합니다.
12.3. 분쟁의 해결
본 약관은 대한민국의 법률에 따라 규율 되며, WAPL사용에 관련된 소송 등 모든 법적인 문제에 대해서는 대한민국 서울중앙지방법원에 전속관할권이 있습니다.
12.4. 본 약관에 대한 문의 사항
본 약관에 대한 의문 사항은 아래 연락처를 참고하시기 바랍니다.
wapl_support@tmax.co.kr 
12.5 멤버의 동의 
어드민(Admin)은 본 약관, 개인정보처리방침 적용에 대해 멤버들의 동의를 얻고 있다는 것을 보장합니다. 해당 항에 대하여 멤버의 일부 또는 전부가 동의를 하지 않는 경우에도 이로 인한 어드민(Admin)과 멤버 간 분쟁 발생 시 회사는 책임을 지지 않습니다.



㈜티맥스에이앤씨 
경기도 성남시 분당구 황새울로 BS 타워 6 ~ 9층
홈페이지: https://tmaxanc.com/
              `}
                </PageBodyPre>
              </PageInnerContents>
            )}
            {i18n.language === 'en' ? (
              <PageInnerContents id="2020-11-09">
                <PageBodyPre ismobile={isMobile}>
                  {`
1. Purpose
These Terms of Use ("Terms") are intended to stipulate the rights, obligations and responsibilities of TmaxA&C Co., Ltd. (the "Company") and the User as well as to specify other requirements in using the services of TeeSpace provided by the Company.

2. Definition
2.1. The definitions of terms used herein are as follows.
    1) “TeeSpace” means all the Teespace application services which include messenger, mail, scheduling, notetaking, office tools, video conferencing and file storage, and all other related services such as technical support for TeeSpace users. 
    2) “User” means a person bound by the use agreement with the Company signed in accordance with these Terms, and thus qualified by the Company to use TeeSpace.
3) Authorities of TeeSpace user are defined as follows:
- USER: A person eligible to use the services after having completed the email authentication.
- ADMIN: A person who creates a new Space.
- ROOM MANAGER: A person who creates a room, or a user who has been granted the right to manage the room. 
- MEMBER: A person who is represented in the organizational chart and uses the services. MEMBER also represents an authority within the room.
    4) TeeSpace “ID”: An email address set up by the User for identification and use of TeeSpace
    5) TeeSpace “Password”: A combination of letters, numbers and special characters set up by the User to protect his/her privacy. 
2.2. Any other terms used herein but not stipulated in the present Article comply with applicable laws and commercial practices.

3. Effect and Change of Terms 
3.1. The content of these Terms is published on the TeeSpace website, and applicable to all parties agreeing to these Terms. 
3.2. For any other matters not stipulated herein, the following shall apply, in the order of priority: applicable laws, commercial practices. 
3.3. If necessary, the Company can change these Terms to the extent that such change remains compliant with applicable laws.
Any updates to these Terms will be announced or notified 7 days prior to the effective date via Service Notification. If any changes are deemed unfavorable to the User, the Company will inform the User of such modifications 30 days prior to the effective date by sending an email to the address registered in the TeeSpace account of the User, while also adding a pop-up notification in the website. 
3.4. In the absence of any explicit opposition from the User within the notice period even after the Company’s notification as specified in the section above, the changes of these Terms shall be deemed to have been agreed to.
3.5. If the User opposes to any changes, the updated Terms cannot come into effect, and the User can rescind the use agreement. Under particular circumstances however, where the existing version of these Terms cannot take effect, the Company may rescind the use agreement.

4. Conclusion of Use Agreement
4.1. The use agreement of TeeSpace is concluded when a person seeking to join the TeeSpace membership (“applicant for TeeSpace membership”) completes the membership application process as guided by the Company.
4.2. If the capacity of WAPL infrastructures is not sufficient or there are any technical or business issues, the Company may reserve the membership admission.
4.3. If the membership application has been refused or reserved for the reason specified in the section above, the Company will as a rule inform the applicant of such decision.
4.4. The time of conclusion of use agreement is the point of time at which the Company marked the completion of the membership application process.
4.5. After joining the membership, the User can enjoy TeeSpace at no cost. However, some charged services can be accessible only after the specified fees are paid.

5. Protection of Personal Information
The User’s personal information is used only within the scope and purposes agreed by him/her so as to ensure a smooth operation of services. When obliged to share any piece of personal information with third parties beyond the extent allowed by applicable laws, the Company shall obtain an additional approval from the User. 

Please refer to the TeeSpace Privacy Policy for further details.

6. Use of TeeSpace 
6.1. TeeSpace is operated in accordance with the operational policy established by the Company. When required for any operational and technical reasons, the Company may change this operational policy or suspend the operation of TeeSpace.
6.2. In the case where the operation of TeeSpace is suspended as specified in the section above, the Company will notify the User of the suspension in accordance with the procedures specified in Article 8 below. In the event of a suspension resulting from force majeure, however, there may be no prior notification.
6.3. If the Company fails to comply with the requirements set forth in these Terms while the User uses TeeSpace in accordance with these Terms, the Company may immediately suspend the operation of TeeSpace services that have been provided to the User. 
6.4. In the case of suspension of services under the circumstance specified in the section above, the User shall send an email to Customer Support (teesupport@tmax.co.kr) for any inquiry about the services operation.

7. Notification of Company
The Company delivers notifications to the email address of the User registered during the membership application or via Service Notification which is found in the Customer Support menu. If the Company has notified the Admin of certain information, it shall be considered as if the members have been made aware of such information. The User shall have responsibility for managing and correcting the information about the Admin as needed.  
Any notifications addressed to all users will be posted in the noticeboard of the TeeSpace website for at least 7 days, which substitutes the notification process specified in the section above. 
The Admin is responsible for forwarding all notifications he/she received from the Company to the members.

8. Cancellation of Use Agreement of TeeSpace by the Company
8.1 The Company can delete all data related to the User and terminate the use agreement of TeeSpace with the User in the cases where:
    1) the User fails to comply with any obligations specified in these Terms 
    2) the User causes any disturbance to the proper operation of TeeSpace
    3) the User uses TeeSpace by any illegal or unjustifiable means
    4) the Company determines that termination of the use agreement with the User is necessary for any other reasons equally justified as above. 
8.2 The present Article hereby specifies that, if the User becomes unable to use TeeSpace, the Company is not liable for any damage incurred due to the User’s inability to use TeeSpace. 

9. Cancellation of Use Agreement of TeeSpace by the User
9.1. The User can request cancellation of the use agreement of TeeSpace at any time.
9.2. The User needs to take a backup of all resources that he/she has used during the TeeSpace service period by him/herself before canceling the use agreement.
9.3. When the User cancels the TeeSpace use agreement as per 9.1, all resources that have been provided to the User are revoked, and all information stored in TeeSpace is deleted, which will not be recovered for any reason, except when the Company needs to retain the User’s information to comply with applicable laws and its Privacy Policy or when some of the User’s posts are maintained in accordance with 9.4.
9.4. Any posts or comments shared with third parties are not deleted. The User needs to delete them by him/herself before canceling the services.

10. Withdrawal of Subscription, Cancellation of Agreement 
10.1. The User can request cancellation of the use agreement by various means such as written request or email (teesupport@tmax.co.kr) at any time. The Company will immediately process such requests in compliance with applicable laws. 
10.2. In compliance with applicable laws including 『Personal Information Protection Act』, the Company may terminate the use agreement and membership of the User if he/she has been confirmed not to use TeeSpace for the past 1 year by sending notification 30 days prior to the termination date in accordance with the process set forth in Article 7. In the case of termination of membership, the Company will immediately delete the User’s data including that of members in accordance with applicable laws.
10.3. In the case of a cancellation of use agreement of TeeSpace by the User, all data of the User will be kept for 7 days from the cancellation date before being subsequently deleted. However, the Company may retain the User’s data for the required period of time in accordance with applicable laws and the TeeSpace Privacy Policy.
10.4. When the User cancels the TeeSpace use agreement, member’s TeeSpace-related data will be entirely deleted as specified by 10.3. The User needs to take a backup of user and member data by him/herself before canceling the use agreement. The Company is not liable for any problems caused by not taking any backup of data by the User.   


11. Indemnification
11.1. The Company is not liable for any damages incurred to the User in connection with the operation of TeeSpace based on these Terms.
11.2. The User is held responsible for all losses and damages suffered by the Company as a result of his/her use of TeeSpace.

12. Miscellaneous
12.1. Severability
If any provisions of these Terms are deemed invalid by a court of competent jurisdiction or determined to be inconsistent with applicable laws, then such provisions will be, to that extent, deemed omitted and the remaining provisions will continue in full force and effect.
12.2. Acknowledgement of Agreement
The User hereby acknowledges that he/she has read, understood and agreed to all the details set forth in these Terms.
12.3. Settlement of Disputes
These Terms are governed and construed in accordance with the laws of the Republic of Korea, and Seoul Central District Court has exclusive jurisdiction over all disputes, controversies or claims arising under, out of, or related to the use of TeeSpace. 
12.4. Contact Information
For any questions about these Terms, send an email to:
teesupport@tmax.co.kr
12.5 Consent of Member 
The Admin assures that the members agree to the application of these Terms and the Privacy Policy. The Company is not liable for any disputes resulting from disagreement between the Admin and the members regarding this provision.

TmaxA&C Co., Ltd. 
6 ~ 9F BS Tower, Hwangsaeul-ro, Bundang-gu, Seongnam-si, Gyeonggi-do
13595, South Korea
Website: https://tmaxanc.com
`}
                </PageBodyPre>
              </PageInnerContents>
            ) : (
              <PageInnerContents id="2020-11-09">
                <PageBodyPre ismobile={isMobile}>
                  {`
제 1 조 목적
본 약관은 ㈜티맥스에이앤씨가 제공하는 TeeSpace의 이용과 관련하여 회사와 회원과의 권리, 의무 및 책임사항, 기타 필요한 사항을 규정함을 목적으로 합니다.

제 2 조 정의
2.1. 본 약관에서 사용하는 용어의 의미는 다음과 같습니다. 
    1) ‘TeeSpace’는 회사가 제공하는 메신저, 메일, 스케줄, 노트, 오피스, 화상회의, 
      파일저장소 등을 포함하는 TeeSpace앱 서비스와 TeeSpace이용을 위한    
      기술지원 등 관련 제반 서비스를 의미합니다.
    2) ‘회원’이라 함은 회사의 TeeSpace에 접속하여 본 약관에 따라 회사와 이용계약을 
       체결하고 회사로부터 TeeSpace이용 자격을 부여 받은 자를 의미합니다. 
3) TeeSpace의 회원 권한은 다음과 같이 정의합니다. 
-유저: 이메일 인증이 완료되어 서비스를 이용할 수 있는 상태
-어드민: 어드민 권한을 가진 구성원으로 도메인으로 최초로 만든 사람이 부여 받음
-룸관리자: 구성원 중 룸을 최초로 생성했거나, 해당 권한을 부여 받은 유저
-멤버: 서비스를 활용하는 조직도 기반 구성원으로서, 룸 내 권한을 지칭하기도 함
    4) TeeSpace ‘아이디(ID)’라 함은 회원의 식별과 TeeSpace의 이용을 위하여 
      회원이 설정한 이메일 주소를 의미합니다. 
    5) TeeSpace ‘비밀번호’라 함은 회원의 비밀보호를 위하여 TeeSpace
회원이 설정한 문자와 숫자, 특수문자의 조합을 말합니다. 


2.2. 본 약관에서 사용하는 용어 중 본 조에서 정하지 아니한 것은, 관계법령 및 상관례에 따릅니다.

제 3 조 약관의 효력 및 변경
3.1. 본 약관의 내용은 TeeSpace 홈페이지 서비스 화면에 게시되며, 
본 약관에 동의한 모두에게 효력이 있습니다.
3.2. 본 약관에 규정되지 않은 사항에 대해서는 관련 법령 및 상관례의 순서로 적용됩니다.
3.3. 회사는 필요한 경우 관련 법령을 위배하지 않는 범위 내에서 본 약관을 변경할 수 있습니다.
본 약관이 변경되는 경우 회사는 변경사항을 시행 일자 7일 전부터 서비스 공지사항에서 
공지 또는 통지하는 것을 원칙으로 하며, 회원에게 불리한 내용으로 변경될 경우에는 그 
시행일자 30일 전부터 TeeSpace 계정에 등록된 이메일 주소로 전자메일 발송을 통한 개별 공지 및 홈페이지내 알림 메시지를 통한 전체 공지를 하겠습니다.
3.4. 회사가 전항에 따라 개정약관을 공지 또는 통지하면서 회원에게 공지 기간 내에 거부의 의사표시를 하지 않으면 약관 개정에 동의한 것으로 본다는 뜻을 명확하게 통지 또는 공지하였음에도 회원이 명시적으로 거부의 의사표시를 하지 아니한 경우 회원이 개정약관에 동의한 것으로 봅니다.
3.5. 변경 사항에 동의하지 않는 경우 회사는 개정약관의 내용을 적용할 수 없으며, 이 경우 회원은 이용계약을 해지할 수 있습니다. 다만, 기존 약관을 적용할 수 없는 특별한 사정이 있는 경우에는 회사가 회원과의 이용계약을 해지할 수 있습니다.


제 4 조 이용계약 체결
4.1. TeeSpace이용계약은 TeeSpace회원이 되고자 하는 자(이하 “TeeSpace이용신청자”라 함)가 회사가 제공하는 회원가입 방법에 따라 TeeSpace회원가입 절차를 완료함으로써 체결됩니다. 
4.2. 회사는 TeeSpace 관련 설비의 여유가 없거나, 기술상 또는 업무상 문제가 있는 경우에는 승낙을 유보할 수 있습니다.
4.3. 본 조에 따라 회원가입신청의 승낙을 하지 아니하거나 유보한 경우, 회사는 원칙적으로 이를 이용신청자에게 알립니다.
4.4. TeeSpace 이용계약의 성립 시기는 회사가 회원가입신청에 대한 완료를 신청절차 상에서 표시한 시점으로 합니다.
4.5. TeeSpace는 가입 후 무료로 사용 가능합니다. 단, 향후 별도로 유료임을 명시한 정보에 대해서는 해당 정보에 명시된 요금을 지불하여야 사용이 가능합니다. 


제 5 조 개인정보의 보호 
회원의 개인정보는 서비스의 원활한 제공을 위하여 회원이 동의한 목적과 범위 내에서만 이용됩니다. 관련 법령에 의하지 아니하는 한 회사가 개인정보를 제 3자에게 제공하는 경우 회원의 별도 동의를 받아야 합니다. 

기타 자세한 사항은 TeeSpace개인정보처리방침을 참고해 주시기 바랍니다. 

제 6 조 TeeSpace의 이용
6.1. TeeSpace는 회사가 정한 운영정책에 따라 제공되는 것이며, 회사는 운영, 
기술 상의 필요에 따라 언제든 위 운영정책을 변경하거나 TeeSpace의 제공을 중단할 수 있습니다.
6.2. 전항에 따라 TeeSpace이용이 중단될 경우 회사는 제 8 조에서 정한 방법으로 TeeSpace 회원에게 사전에 통지해야 합니다. 단, 불가항력적인 사유로 중단될 경우에는 통지하지 않을 수 있습니다. 
6.3. 회원이 본 약관에 기초하여 TeeSpace를 이용하던 중 회사가 본 약관에서 정한 기준을 충족시키지 못하게 되는 경우, 회사는 즉시 해당 회원에게 제공하던 TeeSpace의 제공을 중단할 수 있습니다. 
6.4. 전항에 따라 이용이 중단된 회원이 계속하여 TeeSpace를 제공받기 위해서는 
별도로 마련된 고객문의 이메일(teesupport@tmax.co.kr)을 통해서 서비스 이용과 관련된 
문의 절차를 거쳐야 합니다.

제 7 조 회사의 통지
회사가 회원에게 고지할 내용이 있는 경우 회원가입 시 기재한 이메일 주소 혹은 고객지원 내 서비스 공지로 통지 합니다. 회사가 어드민에게 통지한 경우 이는 회원에게 통지한 것으로 봅니다. 회원은 어드민 정보에 대한 관리 및 적절한 정보로의 변경 책임을 부담합니다.
회원 전체에 대한 통지의 경우 회사는 7일 이상 TeeSpace 홈페이지 게시판에 해당 내용을 게시함으로써 전항의 통지를 갈음할 수 있습니다.
어드민은 회사가 어드민에게 통지한 사항에 대하여 멤버에게 통지할 책임을 부담합니다.

제 8 조 회사의 TeeSpace이용 계약 해지 등
8.1 회사는 다음 각 호의 경우 해당 회원에 관련된 일체의 데이터를 삭제하고 회원과의 
TeeSpace이용계약을 해지할 수 있습니다.  
    1) 본 약관 상 의무를 위반하는 경우 
    2) 회사의 정상적인 TeeSpace운영을 방해한 경우 
    3) 불법 혹은 부당한 방법으로 TeeSpace를 이용하는 경우 
    4) 위 각 호에 준하는 사유로 TeeSpace이용계약의 해지가 필요하다고 회사가 
       판단한 경우 
8.2 본 조에 기초하여 회원이 TeeSpace를 이용하지 못하게 되는 경우에도 회사는 
회원에게 어떠한 손해배상의무도 부담하지 아니합니다.

제 9 조 회원의 TeeSpace이용 계약 해지
9.1. 회원은 언제든지 TeeSpace이용 계약을 해지할 수 있습니다. 
9.2. TeeSpace이용 계약 해지 시, 회원은 TeeSpace이용기간 동안 사용한 모든 자원에 대하여 해지 전 직접 백업하여야 합니다. 
9.3. 제 1 항에 따라 회원이 TeeSpace이용 계약을 해지할 경우 관계법령 및 개인정보처리방침에 따라 회사가 회원의 정보를 보유하거나 9.4.에 따라 회원의 게시물 정보를 보유하는 경우를 제외하고는 회원에게 제공된 모든 자원이 회수되며 TeeSpace상에 저장되어 있는 모든 정보는 삭제되고, 삭제된 자료는 어떠한 이유로도 복원되지 않습니다.
9.4. 제3자와 공유되고 있는 회원의 게시글 또는 댓글 등의 경우에는 삭제되지 않으므로 반드시 이용 종료 전에 스스로 삭제하시기 바랍니다.

제 10 조 청약철회, 계약해지
10.1. 회원은 언제든지 서면, 이메일(teesupport@tmax.co.kr ) 등의 방법을 통하여 TeeSpace 이용계약 해지 신청을 할 수 있으며, 회사는 관련 법령 등이 정하는 바에 따라 이를 즉시 처리하여야 합니다. 
10.2. 회사는 개인정보보호법 등 관련 법령이 정하는 바에 따라, 1년 동안 모든 멤버가 본 서비스를 이용하지 않는 등 회원이 해당 기간 동안 TeeSpace를 이용하지 않은 것을 확인하는 경우 기간 만료 30일 전 제7조의 방법으로 통지 후 회원과의 이용 계약을 해지하고 탈퇴 조치를 할 수 있습니다. 탈퇴 조치를 취하는 경우, 회사는 관련 법령에 따라 회원을 포함한 멤버의 데이터를 즉시 삭제합니다.
10.3. 회원이 TeeSpace 이용계약을 해지할 경우, 회원을 포함한 멤버의 모든 데이터는 이용계약의 해지 후 7일간 보관되며, 7일이 지난 후 즉시 삭제됩니다. 다만, 회사는 관련 법령 및 개인정보처리방침에 따라 회원을 포함한 멤버의 데이터를 관련 법령 및 개인정보처리방침에서 정한 기간 동안 보유할 수 있습니다.
10.4. 회원의 TeeSpace 이용계약 해지 시 멤버의 TeeSpace 관련 데이터는 제3항에서 정한 바에 따라 모두 삭제됩니다. 회원은 TeeSpace 이용계약 해지 전 회원 및 멤버의 데이터를 스스로 백업하여야 하며, 회원이 데이터를 백업하지 않아 발생한 문제에 대해 회사는 책임을 부담하지 않습니다.





제 11 조 손해배상
11.1. 회사는 본 약관에 따른 TeeSpace의 제공과 관련하여, 회원에게 발생한 어떠한
손해에 대하여도 책임지지 않습니다. 
11.2. 회원은 자신의 TeeSpace의 이용과 관련하여 회사에 손해가 발생한 경우, 
회사에게 발생한 모든 손실, 손해를 책임지고 배상하여야 합니다.

제 12 조 기타
12.1. 분리가능
본 약관의 일부 조항이 관할 지역의 관련 법률에 의하여 무효로 해석되거나 법률에 위반하게 되는 경우 해당 조항은 관련 법률에 따라 합법적인 범위 내로 해석되거나 무효가 될 수 있습니다. 해당 조항의 무효 등은 본 약관의 유효성 및 다른 조항의 내용에 영향을 미치지 않습니다.
12.2. 계약서의 인정
회원은 본 약관에 명시된 모든 내용을 읽고 이해, 동의한 것임을 인정합니다.
12.3. 분쟁의 해결
본 약관은 대한민국의 법률에 따라 규율 되며, TeeSpace사용에 관련된 소송 등 모든 법적인 문제에 대해서는 대한민국 서울중앙지방법원에 전속관할권이 있습니다.
12.4. 본 약관에 대한 문의 사항
본 약관에 대한 의문 사항은 아래 연락처를 참고하시기 바랍니다.
teesupport@tmax.co.kr
12.5 멤버의 동의 
어드민(Admin)은 본 약관, 개인정보처리방침 적용에 대해 멤버들의 동의를 얻고 있다는 것을 보장합니다. 해당 항에 대하여 멤버의 일부 또는 전부가 동의를 하지 않는 경우에도 이로 인한 어드민(Admin)과 멤버 간 분쟁 발생 시 회사는 책임을 지지 않습니다.



㈜티맥스에이앤씨 
경기도 성남시 분당구 황새울로 BS 타워 6 ~ 9층
홈페이지: https://tmaxanc.com/
`}
                </PageBodyPre>
              </PageInnerContents>
            )}
            {i18n.language === 'en' ? (
              <PageInnerContents id="2020-06-22">
                <PageBodyPre ismobile={isMobile}>
                  {`
1. Purpose
These Terms of Use ("Terms") are intended to stipulate the rights, obligations and responsibilities of TmaxA&C Co., Ltd. (the "Company") and the User as well as to specify other requirements in using the services of TeeSpace provided by the Company.

2. Definition
2.1. The definitions of terms used herein are as follows.
1) “TeeSpace” means all the Teespace application services which include messenger, mail, scheduling, notetaking, office tools, video conferencing and file storage, and all other related services such as technical support for TeeSpace users. 
2) “User” means a person bound by the use agreement with the Company signed in accordance with these Terms, and thus qualified by the Company to use TeeSpace.
    3) TeeSpace “ID” means a combination of letters, numbers, and special characters set up by the User for identification and use of TeeSpace.
    4) TeeSpace “Password” means a combination of letters, numbers and special characters set up by the User to protect his/her privacy.
    5) “Withdrawal from account” means to withdraw from the TeeSpace account independently of other accounts of individual Tmax A&C services which are managed independently.
6) “Withdrawal from service” means to withdraw selectively from an individual service of TeeSpace out of the integrated service. In this case, only the service from which the User chooses to withdraw will cease to be provided while other individual services remain available.
2.2. Any other terms used herein but not stipulated in the present Article comply with applicable laws and commercial practices.

3. Effect and Change of Terms 
3.1. The content of these Terms is published either on the TeeSpace service page or other individual services websites, and applicable to all parties agreeing to these Terms.
3.2. For any other matters not stipulated herein, the following shall apply, in the order of priority: applicable laws, commercial practices. 
3.3. If necessary, the Company can change these Terms to the extent that such change remains with applicable laws. Any updates to these Terms will be announced or notified 7 days prior to the effective date via Service Notification. If any changes are deemed unfavorable to the User, the Company will inform the User of such modifications 30 days prior to the effective date by sending an email to the address registered in the TeeSpace account of the User (if no email address is found, other electronic means such as the in-service mail or pop-up notification message will be used). 
If the User continues to use TeeSpace even after the service period expired, it will be considered that the User agree to the new Terms of Use. The new Terms of Use will apply to the current use of all services (including those used or purchased by the User previously) as well as the future use and purchase of services.
3.4. If the User does not agree to changes in the Terms, he/she can request to withdraw from the use agreement of TeeSpace via “Withdrawal from Account” or “Withdrawal from Service” which are found in the TeeSpace account settings menu. Withdrawal from account can be done in the TeeSpace website. After withdrawal from account, the use agreements for the other linked services will not be terminated automatically.
The User can revoke his/her consent on the collection and use of personal information by withdrawing from service. However, for destruction of personal information, the User needs to choose to withdraw from account, instead of withdraw from service.

4. Membership and Use of Services
4.1. The use agreement of TeeSpace is concluded when a person seeking to join the TeeSpace membership (“applicant for TeeSpace membership”) completes the membership application process as guided by the Company. However, for child protection purposes, minors under 14 years of age are not allowed to join the membership.
4.2. The applicant for TeeSpace membership provides information (name, date of birth, mobile phone number, email address, ID, password, profile photo, friends list, service usage records, etc.) requested by the Company during the membership application process and use of its services. The Company uses such information to provide services. 
4.3. If the applicant for TeeSpace membership wants to use services other than those provided by the Company, he/she needs to make a separate request for the use of such services or may be required to provide some additional information in the process.
4.4. After joining the membership, the User can enjoy TeeSpace at no cost. However, some charged services can be accessible only after the specified fees are paid.
4.5. To use a third-party application service after joining TeeSpace membership and while using its services, additional consent to a separate terms of use and providing additional information may be required.

5. Protection of Personal Information
The User’s personal information is used only within the scope and purposes agreed by him/her so as to ensure a smooth operation of services. When obliged to share any piece of personal information with third parties beyond the extent allowed by applicable laws, the Company shall obtain an additional approval from the User. 
Please refer to the TeeSpace Privacy Policy for further details.

6. Use of TeeSpace 
6.1. TeeSpace is operated in accordance with the operational policy established by the Company. When required for any operational and technical reasons, the Company may change this operational policy or stop providing TeeSpace.
6.2. In the case where the operation of TeeSpace is suspended as specified in the section above, the Company will notify the User of the suspension in accordance with the procedures specified in Article 8 below. In the event of a suspension resulting from force majeure, however, there may be no prior notification.
6.3. If the Company fails to comply with the requirements set forth in these Terms while the User uses TeeSpace in accordance with these Terms, the Company may immediately suspend the operation of TeeSpace services that have been provided to the User. 
6.4. In the case of suspension of services under the circumstance specified in the section above, the User shall send an email to Customer Support (teesupport@tmax.co.kr) for any inquiry about resumption of services.

7. Notification of Company
If there is any notification to be delivered to the User, the Company will send it to the email address the User registered during the membership application.

8. Cancellation of Use Agreement of TeeSpace by the Company
8.1 The Company can delete all data related to the User and terminate the use agreement of TeeSpace with the User in the cases where:
1) the User fails to comply with any obligations specified in these Terms 
2) the User causes any disturbance to the proper operation of TeeSpace
3) the User uses TeeSpace by any illegal or unjustifiable means
4) the Company determines that termination of the use agreement with the User is necessary for any other reasons equally justified as above. 
8.2 The present Article hereby specifies that, if the User becomes unable to use TeeSpace, the Company is not liable for any damage incurred due to the User’s inability to use TeeSpace. 

9. Cancellation of Use Agreement of TeeSpace by the User
9.1. The User can cancel the use agreement of TeeSpace at any time.
9.2. The User needs to take a backup of all resources that he/she has used during the TeeSpace service period by him/herself before canceling the use agreement.
9.3. When the User cancels the TeeSpace use agreement as per 9.1, all resources that have been provided to the User are revoked, and all information stored in TeeSpace is deleted, which will not be recovered for any reason, except when the Company needs to retain the User’s information to comply with applicable laws and its Privacy Policy or when some of the User’s posts are maintained in accordance with 9.4.
9.4. Any posts or comments shared with third parties are not deleted. The User needs to delete them by him/herself before canceling the services.
9.5. The User can withdraw in the TeeSpace website.

10. Inactive Account
10.1. After 1 year of inactivity (no login), the account is classified as inactive. 
10.2. 30 days before the account is classified as inactive, prior notification will be made via the email address the User registered when applying for the membership. If no particular objection is made during the 30 days after the notification, the account will be classified as inactive.
10.3. Once the account is classified as inactive, the services become unavailable unless the User completes the account deactivation process, which takes place through an email verification via the address the User registered when applying for the membership.
10.4. Once the account is classified as inactive, the personal information collected from the User will be migrated to a separate storage.
10.5. Once the account is classified as inactive, in the case of TeeSpace, the User’s data may be deleted. The Company is not liable for any potential damage incurred due to the deletion.

11. Indemnification
11.1. The Company is not liable for any damage incurred to the User in connection with the operation of TeeSpace based on these Terms. 
11.2. The User is held liable for all losses and damages incurred to the Company as a result of his/her use of TeeSpace.

12. Miscellaneous
12.1. Severability
If any provisions of these Terms are deemed invalid by a court of competent jurisdiction or determined to be illegal under applicable laws, then such provisions will be, to that extent, deemed omitted and the remaining provisions will continue in full force and effect.
12.2. Acknowledgement of Agreement
The User hereby acknowledges that he/she has read, understood and agreed to all the details set forth in these Terms.
12.3. Settlement of Disputes
These Terms are governed and construed in accordance with the laws of the Republic of Korea, and Seoul Central District Court has exclusive jurisdiction over all disputes, controversies or claims arising under, out of, or related to the use of TeeSpace. 
12.4. Contact Information
For any questions about these Terms, find a contact in the following.  


TmaxA&C Co., Ltd.
6 ~ 9F BS Tower, Hwangsaeul-ro, Bundang-gu, Seongnam-si, Gyeonggi-do
13595, South Korea
Website: https://tmaxanc.com

These Terms of Use will become effective as of June 22, 2020.
`}
                </PageBodyPre>
              </PageInnerContents>
            ) : (
              <PageInnerContents id="2020-06-22">
                <PageBodyPre ismobile={isMobile}>
                  {`
제 1 조 목적
본 약관은 ㈜티맥스에이앤씨가 제공하는 TeeSpace의 이용과 관련하여 회사와 회원과의 권리, 의무 및 책임사항, 기타 필요한 사항을 규정함을 목적으로 합니다.

제 2 조 정의
2.1. 본 약관에서 사용하는 용어의 의미는 다음과 같습니다. 
    1) TeeSpace는 회사가 제공하는 메신저, 메일, 스케줄, 노트, 오피스, 화상회의, 파일저장소 등을 포함하는 TeeSpace앱 서비스와 TeeSpace이용을 위한 기술지원 등 관련 제반 서비스를 의미합니다.
    2) 회원이라 함은 회사의 TeeSpace에 접속하여 본 약관에 따라 회사와 이용계약을 체결하고 회사로부터 TeeSpace이용 자격을 부여 받은 개인을 의미합니다. 
    3) TeeSpace아이디(ID)라 함은 회원의 식별과 TeeSpace의 이용을 위하여 회원이 설정한 문자와 숫자, 특수문자의 조합을 의미합니다. 
    4) TeeSpace비밀번호라 함은 회원의 비밀보호를 위하여 TeeSpace 회원이 설정한 문자와 숫자, 특수문자의 조합을 말합니다. 
    5) 계정 탈퇴라 함은 TeeSpace 계정을 탈퇴하는 것으로 ㈜티맥스에이앤씨 타 개별 서비스의 계정은 독립적으로 운영됩니다. 
6) 서비스 탈퇴라 함은 통합서비스 중 TeeSpace개별 서비스만 탈퇴하는 것으로  향후 해당 서비스만 사용할 수 없으며 나머지 개별서비스는 유효한 탈퇴 방식을 의미합니다.
2.2. 본 약관에서 사용하는 용어 중 본 조에서 정하지 아니한 것은, 관계법령 및 상관례에 따릅니다.

제 3 조 약관의 효력 및 변경
3.1. 본 약관의 내용은 TeeSpace서비스 화면에 게시하거나 개별 서비스 홈페이지에 공지하고, 본 약관에 동의한 모두에게 효력이 있습니다.
3.2. 본 약관에 규정되지 않은 사항에 대해서는 관련 법령 및 상관례의 순서로 적용됩니다.
3.3. 회사는 필요한 경우 관련 법령을 위배하지 않는 범위 내에서 본 약관을 변경할 수 있습니다. 본 약관이 변경되는 경우 회사는 변경사항을 시행 일자 15일 전부터 서비스 공지사항에서 공지 또는 통지하는 것을 원칙으로 하며, 회원에게 불리한 내용으로 변경될 경우에는 그  시행일자 30일 전부터 TeeSpace 계정에 등록된 이메일 주소로 전자메일(이메일 주소가 없는 경우 서비스 내 메일 및 알림 메시지를 띄우는 등의 별도의 전자적 수단)을 발송하는 방법 등으로 개별적으로 공지하겠습니다. 
회원이 기간이 지난 후에도 TeeSpace를 계속 사용하면 새 약관에 동의한 것으로 간주합니다. 새 약관은 회원의 모든 서비스(과거에 이용했거나 구매한 서비스 포함) 이용과 향후의 모든 이용 및 구매에 적용됩니다. 
3.4. 변경 사항에 동의하지 않는 경우에는 TeeSpace계정 설정 메뉴 안의 계정 탈퇴 및 서비스 탈퇴를 통해 TeeSpace의 이용계약을 해지신청을 할 수 있습니다. 계정 탈퇴는 TeeSpace홈페이지에서 가능하며, 계정 탈퇴 시 다른 연동 서비스의 이용계약은 자동해지 되지 않습니다.
서비스 탈퇴를 통해 개인정보의 제공 및 활용과 관련한 동의를 철회할 수 있습니다.개인 정보 파기를 위해서는 서비스 탈퇴가 아닌 계정 탈퇴를 선택하여야 합니다.

제 4 조 회원가입 및 서비스 이용 방법
4.1. TeeSpace이용계약은 TeeSpace회원이 되고자 하는 자(이하 “TeeSpace이용신청자”라 함)가 회사가 제공하는 회원가입 방법에 따라 TeeSpace회원가입 절차를 완료함으로써 체결됩니다. 단 아동의 보호를 위하여 만 14세 미만의 회원가입이 제한됩니다. 
4.2. TeeSpace이용신청자는 TeeSpace회원가입 및 서비스 이용 시 회사에서 요구하는 제반 정보(이름, 생년월일, 핸드폰 번호, 이메일 주소, 아이디, 비밀번호, 프로필 사진, 친구목록, 서비스 이용 내역 등)를 제공하고, 회사는 서비스 제공 등을 위해 이를 이용합니다. 
4.3. TeeSpace이용신청자가 회사가 제공하는 그 외 서비스를 제공받기 위해서는  별도의 이용신청을 거치거나 그 과정에서 추가적인 정보제공이 필요할 수 있습니다.
4.4. TeeSpace는 가입 후 무료로 사용 가능합니다. 단, 향후 별도로 유료임을 명시한 정보에 대해서는 해당 정보에 명시된 요금을 지불하여야 사용이 가능합니다.
4.5. TeeSpace가입 후 서비스 이용 중 제 3자의 앱 서비스에 대해서는 별도의 약관 동의 및 정보제공이 필요할 수 있습니다.

제 5 조 개인정보의 보호 
회원의 개인정보는 서비스의 원활한 제공을 위하여 회원이 동의한 목적과 범위 내에서만 이용됩니다. 관련 법령에 의하지 아니하는 한 회사가 개인정보를 제 3자에게 제공하는 경우 회원의 별도 동의를 받아야 합니다. 
 기타 자세한 사항은 TeeSpace개인정보처리방침 등을 참고해 주시기 바랍니다. 

제 6 조 TeeSpace의 이용
6.1. TeeSpace는 회사가 정한 운영정책에 따라 제공되는 것이며, 회사는 운영,  기술 상의 필요에 따라 언제든 위 운영정책을 변경하거나 TeeSpace의 제공을 중단할 수 있습니다.
6.2. 전항에 따라 TeeSpace이용이 중단될 경우 회사는 제 8 조에서 정한 방법으로 TeeSpace 회원에게 사전에 통지해야 합니다. 단, 불가항력적인 사유로 중단될 경우에는 통지하지 않을 수 있습니다. 
6.3. 회원이 본 약관에 기초하여 TeeSpace를 이용하던 중 회사가 본 약관에서 정한 기준을 충족시키지 못하게 되는 경우, 회사는 즉시 해당 회원에게 제공하던 TeeSpace의 제공을 중단할 수 있습니다. 
6.4. 전항에 따라 이용이 중단된 회원이 계속하여 TeeSpace를 제공받기 위해서는 별도로 마련된 고객문의 이메일(teesupport@tmax.co.kr)을 통해서 서비스 이용과 관련된 문의 절차를 거쳐야 합니다.

제 7 조 회사의 통지
회사가 회원에게 고지할 내용이 있는 경우 회원가입 시 기재한 이메일 주소로 통지를 합니다. 


제 8 조 회사의 TeeSpace이용 계약 해지 등
8.1 회사는 다음 각 호의 경우 해당 회원에 관련된 일체의 데이터를 삭제하고 회원과의 TeeSpace이용계약을 해지할 수 있습니다.  
    1) 본 약관 상 의무를 위반하는 경우 
    2) 회사의 정상적인 TeeSpace운영을 방해한 경우 
    3) 불법 혹은 부당한 방법으로 TeeSpace를 이용하는 경우 
    4) 위 각 호에 준하는 사유로 TeeSpace이용계약의 해지가 필요하다고 회사가 판단한 경우 
8.2 본 조에 기초하여 회원이 TeeSpace를 이용하지 못하게 되는 경우에도 회사는 회원에게 어떠한 손해배상의무도 부담하지 아니합니다.

제 9 조 회원의 TeeSpace이용 계약 해지
9.1. 회원은 언제든지 TeeSpace이용 계약을 해지할 수 있습니다. 
9.2. TeeSpace이용 계약 해지 시, 회원은 TeeSpace이용기간 동안 사용한 모든 자원에 대하여 해지 전 직접 백업하여야 합니다. 
9.3. 제 1 항에 따라 회원이 TeeSpace이용 계약을 해지할 경우 관계법령 및 개인정보처리방침에 따라 회사가 회원의 정보를 보유하거나 9.4.에 따라 회원의 게시물 정보를 보유하는 경우를 제외하고는 회원에게 제공된 모든 자원이 회수되며 TeeSpace상에 저장되어 있는 모든 정보는 삭제되고, 삭제된 자료는 어떠한 이유로도 복원되지 않습니다.
9.4. 제3자와 공유되고 있는 회원의 게시글 또는 댓글 등의 경우에는 삭제되지 않으므로 반드시 이용 종료 전에 스스로 삭제하시기 바랍니다.
9.5. 회원은 TeeSpace홈페이지에서 탈퇴가 가능합니다.

제 10 조 휴면계정
10.1. 서비스를 1년 동안 이용(로그인)하지 않을 경우 휴면계정으로 분리됩니다. 
10.2. 휴면계정으로 분리하기 30일 전 회원이 회원가입시 기재한 이메일 주소로 전자우편을 보내  사전 공지를 할 예정입니다. 사전공지 이후 30일 동안 별다른 이의신청이 없으면 휴면계정으로 분리됩니다.
10.3. 휴면계정으로 분리되면 별도의 휴면 해제 절차를 거쳐야 서비스 사용이 가능하며 휴면 해제 절차는 회원 가입 시 입력한 이메일 인증을 통하여 이루어집니다.
10.4. 휴면계정으로 분리되면 수집된 회원들의 개인정보가 분리 보관됩니다.
10.5. 휴면계정으로 분리되면 TeeSpace의 경우 해당 회원의 데이터가 삭제될 수 있습니다. 이로 인한 회원들의 손해에 대하여 회사는 어떠한 책임도 지지 않습니다.

제 11 조 손해배상
11.1. 회사는 본 약관에 따른 TeeSpace의 제공과 관련하여, 회원에게 발생한 어떠한 손해에 대하여도 책임지지 않습니다. 
11.2. 회원은 자신의 TeeSpace의이용과 관련하여 회사에 손해가 발생한 경우, 회사에게 발생한 모든 손실, 손해를 책임지고 배상하여야 합니다.

제 12 조 기타
12.1. 분리가능 본 약관의 일부 조항이 관할 지역의 관련 법률에 의하여 무효로 해석되거나 법률에 위반하게 되는 경우 해당 조항은 관련 법률에 따라 합법적인 범위 내로 해석되거나 무효가 될 수 있습니다. 해당 조항의 무효 등은 본 약관의 유효성 및 다른 조항의 내용에 영향을 미치지 않습니다.
12.2. 계약서의 인정
회원은 본 약관에 명시된 모든 내용을 읽고 이해, 동의한 것임을 인정합니다.
12.3. 분쟁의 해결
본 약관은 대한민국의 법률에 따라 규율되며, TeeSpace사용에 관련된 소송 등 모든 법적인 문제에 대해서는 대한민국 서울중앙지방법원에 전속관할권이 있습니다.
12.4. 본 약관에 대한 문의 사항
본 약관에 대한 의문 사항은 아래 연락처를 참고하시기 바랍니다.



㈜티맥스에이앤씨 
경기도 성남시 분당구 황새울로 BS 타워 6 ~ 9층
홈페이지: https://tmaxanc.com/

<부칙> 본 약관은 2020년 6월 22일부터 적용됩니다.`}
                </PageBodyPre>
              </PageInnerContents>
            )}
            {i18n.language === 'en' ? (
              <PageInnerContents id="2020-05-25">
                <PageBodyPre ismobile={isMobile}>
                  {`
1. Purpose
These Terms of Use ("Terms") are intended to stipulate the rights, obligations and responsibilities of TmaxA&C Co., Ltd. (the "Company") and the User as well as to specify other requirements in using the services of TeeSpace provided by the Company.

2. Definition
2.1. The definitions of terms used herein are as follows.
    1) Tmax TeeSpace means all the Tmax Teespace application services which include messenger, mail, scheduling, notetaking, office tools, video conferencing and file storage, and all other related services such as technical support for Tmax TeeSpace users.
2) “User” means a person bound by the use agreement with the Company signed in accordance with these Terms, and thus qualified by the Company to use Tmax TeeSpace.
    3) Tmax TeeSpace “ID” means a combination of letters, numbers, and special characters set up by the User for identification and use of Tmax TeeSpace. 
    4) Tmax TeeSpace “Password” means a combination of letters, numbers and special characters set up by the User to protect his/her privacy. 
    5) “Withdrawal from account” means to withdraw from the Tmax TeeSpace account independently of other accounts of individual Tmax A&C services which are managed independently. 
    6) “Withdrawal from Service” means to withdraw selectively from an individual service of Tmax TeeSpace out of the Integrated Service. In this case, only the service from which the User chooses to withdraw will cease to be provided while other individual services remain available.
2.2. Any other terms used herein but not stipulated in the present Article comply with applicable laws and commercial practices.

3. Effect and Change of Terms 
3.1. The content of these Terms is published either on the TeeSpace service page or other individual services websites, and applicable to all parties agreeing to these Terms.
3.2. For any other matters not stipulated herein, the following shall apply, in the order of priority: applicable laws, commercial practices. 
3.3. If necessary, the Company can change these Terms to the extent that such change remains compliant with applicable laws. Any updates to these Terms will be announced or notified 15 days prior to the effective date via Service Notification. If any changes are deemed unfavorable to the User, the Company will inform the User of such modifications 30 days prior to the effective date by sending an email to the address registered in the TeeSpace account of the User (if no email address is found, other electronic means such as the in-service mail or pop-up notification message will be used). If the User continues to use TeeSpace even after the service period expired, it will be considered that the User agree to the new Terms of Use. The new Terms of Use will apply to the current use of all services (including those used or purchased by the User previously) as well as the future use and purchase of services.
3.4. If the User does not agree to changes in the Terms, he/she can request to withdraw from the use agreement of Tmax TeeSpace via “Withdrawal from Account” or “Withdrawal from Service” which are found in the Tmax TeeSpace account settings menu. Withdrawal from account can be done in the TeeSpace website. After withdrawal from account, the use agreements for the other linked services will not be terminated automatically.
The User can revoke his/her consent on the collection and use of personal information by withdrawing from service. However, for destruction of personal information, the User needs to choose to withdraw from account, instead of withdraw from service.

4. Membership and Use of Services
4.1. The use agreement of Tmax TeeSpace is concluded when a person seeking to join the Tmax TeeSpace membership (“applicant for Tmax TeeSpace membership”) completes the membership application process as guided by the Company. However, for child protection purposes, minors under 14 years of age are not allowed to join the membership. 
4.2. The applicant for Tmax TeeSpace membership provides information (name, date of birth, mobile phone number, email address, etc.) requested by the Company during the membership application process.
4.3. If the applicant for Tmax TeeSpace membership wants to use services other than those provided by the Company, he/she needs to make a separate request for the use of such services or may be required to provide some additional information in the process. 
4.4. After joining the membership, the User can enjoy Tmax TeeSpace at no cost. However, some charged services can be accessible only after the specified fees are paid.
4.5. To use a third-party application service after joining Tmax TeeSpace membership and while using its services, additional consent to a separate terms of use and providing additional information may be required.

5. Protection of Personal Information
The User’s personal information is used only within the scope and purposes agreed by him/her so as to ensure a smooth operation of services. When obliged to share any piece of personal information with third parties beyond the extent allowed by applicable laws, the Company shall obtain an additional approval from the User. 
Please refer to the TeeSpace Privacy Policy for further details.

6. Use of Tmax TeeSpace 
6.1. Tmax TeeSpace is operated in accordance with the operational policy established by the Company. When required for any operational and technical reasons, the Company may change this operational policy or stop providing Tmax TeeSpace.
6.2. In the case where the operation of Tmax TeeSpace is suspended as specified in the section above, the Company will notify the User of the suspension in accordance with the procedures specified in Article 8 below. In the event of a suspension resulting from force majeure, however, there may be no prior notification.
6.3. If the Company fails to comply with the requirements set forth in these Terms while the User uses TeeSpace in accordance with these Terms, the Company may immediately suspend the operation of TeeSpace services that have been provided to the User. 
6.3. If the Company fails to comply with the requirements set forth in these Terms while the User uses Tmax TeeSpace in accordance with these Terms, the Company may immediately suspend the operation of Tmax TeeSpace services that have been provided to the User. 
6.4. In the case of suspension of services under the circumstance specified in the section above, the User shall send an email to Customer Support (teesupport@tmax.co.kr) for any inquiry about resumption of services.
6.4. In the case of suspension of services under the circumstance specified in the section above, the User shall send an email to Customer Support (teesupport@tmax.co.kr) for any inquiry about resumption of services.

7. Notification of Company
If there is any notification to be delivered to the User, the Company will send it to the email address the User registered during the membership application.


8. Cancellation of Use Agreement of Tmax TeeSpace by the Company
8.1 The Company can delete all data related to the User and terminate the use agreement of Tmax TeeSpace with the User in the cases where:
1) the User fails to comply with any obligations specified in these Terms 
2) the User causes any disturbance to the proper operation of Tmax TeeSpace
3) the User uses Tmax TeeSpace by any illegal or unjustifiable means
4) the Company determines that termination of the use agreement with the User is necessary for any other reasons equally justified as above. 
8.2 The present Article hereby specifies that, if the User becomes unable to use TeeSpace, the Company is not liable for any damage incurred due to the User’s inability to use Tmax TeeSpace. 

9. Cancellation of Use Agreement of TeeSpace by the User
9.1. The User can cancel the use agreement of Tmax TeeSpace at any time.
9.2. The User needs to take a backup of all resources that he/she has used during the Tmax TeeSpace service period by him/herself before canceling the use agreement.
9.3. When the User cancels the TeeSpace use agreement as per 9.1, all resources that have been provided to the User are revoked, and all information stored in Tmax TeeSpace is deleted, which will not be recovered for any reason, except when the Company needs to retain the User’s information to comply with applicable laws and its Privacy Policy or when some of the User’s posts are maintained in accordance with 9.4.
9.4. Any posts or comments shared with third parties are not deleted. The User needs to delete them by him/herself before canceling the services.
9.5. The User can withdraw in the TeeSpace website.

10. Inactive Account
10.1. After 1 year of inactivity (no login), the account is classified as inactive. 
10.2. 30 days before the account is classified as inactive, prior notification will be made via the email address the User registered when applying for the membership. If no particular objection is made during the 30 days after the notification, the account will be classified as inactive.
10.3. Once the account is classified as inactive, the services become unavailable unless the User completes the account deactivation process, which takes place through an email verification via the address the User registered when applying for the membership.
10.4. Once the account is classified as inactive, the personal information collected from the User will be migrated to a separate storage.
10.5. Once the account is classified as inactive, in the case of Tmax TeeSpace, the User’s data may be deleted. The Company is not liable for any potential damage incurred due to the deletion.

11. Indemnification
11.1. The Company is not liable for any damage incurred to the User in connection with the operation of Tmax TeeSpace based on these Terms. 
11.2. The User is held liable for all losses and damages incurred to the Company as a result of his/her use of Tmax TeeSpace.

12. Miscellaneous
12.1. Severability
If any provisions of these Terms are deemed invalid by a court of competent jurisdiction or determined to be illegal under applicable laws, then such provisions will be, to that extent, deemed omitted and the remaining provisions will continue in full force and effect.
12.2. Acknowledgement of Agreement
The User hereby acknowledges that he/she has read, understood and agreed to all the details set forth in these Terms.
12.3. Settlement of Disputes
These Terms are governed and construed in accordance with the laws of the Republic of Korea, and Seoul Central District Court has exclusive jurisdiction over all disputes, controversies or claims arising under, out of, or related to the use of Tmax TeeSpace. 
12.4. Contact Information
For any questions about these Terms, find a contact in the following.  



TmaxA&C Co., Ltd.
6 ~ 9F BS Tower, Hwangsaeul-ro, Bundang-gu, Seongnam-si, Gyeonggi-do
13595, South Korea
Website: https://tmaxanc.com

These Terms of Use will become effective as of May 25, 2020.
`}
                </PageBodyPre>
              </PageInnerContents>
            ) : (
              <PageInnerContents id="2020-05-25">
                <PageBodyPre ismobile={isMobile}>
                  {`
제 1 조 목적
본 약관은 ㈜티맥스에이앤씨가 제공하는 Tmax TeeSpace의 이용과 관련하여 회사와 회원과의 권리, 의무 및 책임사항, 기타 필요한 사항을 규정함을 목적으로 합니다.

제 2 조 정의
2.1. 본 약관에서 사용하는 용어의 의미는 다음과 같습니다. 
    1) Tmax TeeSpace는 회사가 제공하는 메신저, 메일, 스케줄, 노트, 오피스, 화상회의, 파일저장소 등을 포함하는 Tmax TeeSpace앱 서비스와 Tmax TeeSpace이용을 위한 기술지원 등 관련 제반 서비스를 의미합니다.
    2) 회원이라 함은 회사의 Tmax TeeSpace에 접속하여 본 약관에 따라 회사와 이용계약을 체결하고 회사로부터 Tmax TeeSpace이용 자격을 부여 받은 개인을 의미합니다. 
    3) Tmax TeeSpace아이디(ID)라 함은 회원의 식별과 Tmax TeeSpace의 이용을 위하여 회원이 설정한 문자와 숫자, 특수문자의 조합을 의미합니다. 
    4) Tmax TeeSpace비밀번호라 함은 회원의 비밀보호를 위하여 Tmax TeeSpace 회원이 설정한 문자와 숫자, 특수문자의 조합을 말합니다. 
    5) 계정 탈퇴라 함은 TeeSpace 계정을 탈퇴하는 것으로 ㈜티맥스에이앤씨 타 개별 서비스의 계정은 독립적으로 운영됩니다. 
    6) 서비스 탈퇴라 함은 통합서비스 중 Tmax TeeSpace개별 서비스만 탈퇴하는 것으로 향후 해당 서비스만 사용할 수 없으며 나머지 개별서비스는 유효한 탈퇴 방식을 의미합니다.
2.2. 본 약관에서 사용하는 용어 중 본 조에서 정하지 아니한 것은, 관계법령 및 상관례에 따릅니다.

제 3 조 약관의 효력 및 변경
3.1. 본 약관의 내용은 TeeSpace서비스 화면에 게시하거나 개별 서비스 홈페이지에 공지하고, 본 약관에 동의한 모두에게 효력이 있습니다.
3.2. 본 약관에 규정되지 않은 사항에 대해서는 관련 법령 및 상관례의 순서로 적용됩니다.
3.3. 회사는 필요한 경우 관련 법령을 위배하지 않는 범위 내에서 본 약관을 변경할 수 있습니다. 본 약관이 변경되는 경우 회사는 변경사항을 시행 일자 15일 전부터 서비스 공지사항에서 공지 또는 통지하는 것을 원칙으로 하며, 회원에게 불리한 내용으로 변경될 경우에는 그 시행일자 30일 전부터 TeeSpace 계정에 등록된 이메일 주소로 전자메일(이메일 주소가 없는 경우 서비스 내 메일 및 알림 메시지를 띄우는 등의 별도의 전자적 수단)을 발송하는 방법 등으로 개별적으로 공지하겠습니다. 회원이 기간이 지난 후에도 Tmax TeeSpace를 계속 사용하면 새 약관에 동의한 것으로 간주합니다. 새 약관은 회원의 모든 서비스(과거에 이용했거나 구매한 서비스 포함) 이용과 향후의 모든 이용 및 구매에 적용됩니다. 
3.4. 변경 사항에 동의하지 않는 경우에는 Tmax TeeSpace계정 설정 메뉴 안의 계정 탈퇴 및 서비스 탈퇴를 통해 Tmax TeeSpace의 이용계약을 해지신청을 할 수 있습니다. 
계정 탈퇴는 Tmax TeeSpace홈페이지에서 가능하며, 계정 탈퇴 시 다른 연동 서비스의 이용계약은 자동해지 되지 않습니다.
서비스 탈퇴를 통해 개인정보의 제공 및 활용과 관련한 동의를 철회할 수 있습니다.개인 정보 파기를 위해서는 서비스 탈퇴가 아닌 계정 탈퇴를 선택하여야 합니다.

제 4 조 회원가입 및 서비스 이용 방법
4.1. Tmax TeeSpace이용계약은 Tmax TeeSpace회원이 되고자 하는 자(이하 “Tmax TeeSpace이용신청자”라 함)가 회사가 제공하는 회원가입 방법에 따라 Tmax TeeSpace회원가입 절차를 완료함으로써 체결됩니다. 단 아동의 보호를 위하여 만 14세 미만의 회원가입이 제한됩니다. 
4.2. Tmax TeeSpace이용신청자는 Tmax TeeSpace회원가입 시 회사에서 요구하는 제반 정보(이름, 생년월일, 핸드폰 번호, 이메일 주소 등)를 제공하여야 합니다. 
4.3. Tmax TeeSpace이용신청자가 회사가 제공하는 그 외 서비스를 제공받기 위해서는 
별도의 이용신청을 거치거나 그 과정에서 추가적인 정보제공이 필요할 수 있습니다.
4.4. Tmax TeeSpace는 가입 후 무료로 사용 가능합니다. 단, 향후 별도로 유료임을 명시한 정보에 대해서는 해당 정보에 명시된 요금을 지불하여야 사용이 가능합니다.
4.5. Tmax TeeSpace가입 후 서비스 이용 중 제 3자의 앱 서비스에 대해서는 별도의 약관 동의 및 정보제공이 필요할 수 있습니다.

제 5 조 개인정보의 보호 
회원의 개인정보는 서비스의 원활한 제공을 위하여 회원이 동의한 목적과 범위 내에서만 이용됩니다. 관련 법령에 의하지 아니하는 한 회사가 개인정보를 제 3자에게 제공하는 경우 회원의 별도 동의를 받아야 합니다. 

기타 자세한 사항은 TeeSpace개인정보처리방침 등을 참고해 주시기 바랍니다. 

제 6 조 Tmax TeeSpace의 이용
6.1. Tmax TeeSpace는 회사가 정한 운영정책에 따라 제공되는 것이며, 회사는 운영, 기술 상의 필요에 따라 언제든 위 운영정책을 변경하거나 Tmax TeeSpace의 제공을 중단할 수 있습니다.
6.2. 전항에 따라 Tmax TeeSpace이용이 중단될 경우 회사는 제 8 조에서 정한 방법으로 Tmax TeeSpace 회원에게 사전에 통지해야 합니다. 단, 불가항력적인 사유로 중단될 경우에는 통지하지 않을 수 있습니다. 
6.3. 회원이 본 약관에 기초하여 Tmax TeeSpace를 이용하던 중 회사가 본 약관에서 정한 기준을 충족시키지 못하게 되는 경우, 회사는 즉시 해당 회원에게 제공하던 Tmax TeeSpace의 제공을 중단할 수 있습니다. 
6.4. 전항에 따라 이용이 중단된 회원이 계속하여 Tmax TeeSpace를 제공받기 위해서는 별도로 마련된 고객문의 이메일(teesupport@tmax.co.kr)을 통해서 서비스 이용과 관련된 문의 절차를 거쳐야 합니다.

제 7 조 회사의 통지
회사가 회원에게 고지할 내용이 있는 경우 회원가입 시 기재한 이메일 주소로 통지를 합니다. 


제 8 조 회사의 Tmax TeeSpace이용 계약 해지 등
8.1 회사는 다음 각 호의 경우 해당 회원에 관련된 일체의 데이터를 삭제하고 회원과의 Tmax TeeSpace이용계약을 해지할 수 있습니다.  
    1) 본 약관 상 의무를 위반하는 경우 
    2) 회사의 정상적인 Tmax TeeSpace운영을 방해한 경우 
    3) 불법 혹은 부당한 방법으로 Tmax TeeSpace를 이용하는 경우 
    4) 위 각 호에 준하는 사유로 Tmax TeeSpace이용계약의 해지가 필요하다고 회사가 판단한 경우 
8.2 본 조에 기초하여 회원이 Tmax TeeSpace를 이용하지 못하게 되는 경우에도 회사는 회원에게 어떠한 손해배상의무도 부담하지 아니합니다.

제 9 조 회원의 Tmax TeeSpace이용 계약 해지
9.1. 회원은 언제든지 Tmax TeeSpace이용 계약을 해지할 수 있습니다. 
9.2. Tmax TeeSpace이용 계약 해지 시, 회원은 Tmax TeeSpace이용기간 동안 사용한 모든 자원에 대하여 해지 전 직접 백업하여야 합니다. 
9.3. 제 1 항에 따라 회원이 Tmax TeeSpace이용 계약을 해지할 경우 관계법령 및 개인정보처리방침에 따라 회사가 회원의 정보를 보유하거나 9.4.에 따라 회원의 게시물 정보를 보유하는 경우를 제외하고는 회원에게 제공된 모든 자원이 회수되며 Tmax TeeSpace상에 저장되어 있는 모든 정보는 삭제되고, 삭제된 자료는 어떠한 이유로도 복원되지 않습니다.
9.4. 제3자와 공유되고 있는 회원의 게시글 또는 댓글 등의 경우에는 삭제되지 않으므로 반드시 이용 종료 전에 스스로 삭제하시기 바랍니다.
9.5. 회원은 Tmax TeeSpace홈페이지에서 탈퇴가 가능합니다.

제 10 조 휴면계정
10.1. 서비스를 1년 동안 이용(로그인)하지 않을 경우 휴면계정으로 분리됩니다. 
10.2. 휴면계정으로 분리하기 30일 전 회원이 회원가입시 기재한 이메일 주소로 전자우편을 보내 사전 공지를 할 예정입니다. 사전공지 이후 30일 동안 별다른 이의신청이 없으면 휴면계정으로 분리됩니다.
10.3. 휴면계정으로 분리되면 별도의 휴면 해제 절차를 거쳐야 서비스 사용이 가능하며 휴면 해제 절차는 회원 가입 시 입력한 이메일 인증을 통하여 이루어집니다.
10.4. 휴면계정으로 분리되면 수집된 회원들의 개인정보가 분리 보관됩니다.
10.5. 휴면계정으로 분리되면 Tmax TeeSpace의 경우 해당 회원의 데이터가 삭제될 수 있습니다. 이로 인한 회원들의 손해에 대하여 회사는 어떠한 책임도 지지 않습니다.

제 11 조 손해배상
11.1. 회사는 본 약관에 따른 Tmax TeeSpace의 제공과 관련하여, 회원에게 발생한 어떠한 손해에 대하여도 책임지지 않습니다. 
11.2. 회원은 자신의 Tmax TeeSpace의이용과 관련하여 회사에 손해가 발생한 경우, 회사에게 발생한 모든 손실, 손해를 책임지고 배상하여야 합니다.

제 12 조 기타
12.1. 분리가능
본 약관의 일부 조항이 관할 지역의 관련 법률에 의하여 무효로 해석되거나 법률에 위반하게 되는 경우 해당 조항은 관련 법률에 따라 합법적인 범위 내로 해석되거나 무효가 될 수 있습니다. 해당 조항의 무효 등은 본 약관의 유효성 및 다른 조항의 내용에 영향을 미치지 않습니다.
12.2. 계약서의 인정
회원은 본 약관에 명시된 모든 내용을 읽고 이해, 동의한 것임을 인정합니다.
12.3. 분쟁의 해결
본 약관은 대한민국의 법률에 따라 규율되며, Tmax TeeSpace사용에 관련된 소송 등 모든 법적인 문제에 대해서는 대한민국 서울중앙지방법원에 전속관할권이 있습니다.
12.4. 본 약관에 대한 문의 사항
본 약관에 대한 의문 사항은 아래 연락처를 참고하시기 바랍니다.



㈜티맥스에이앤씨 
경기도 성남시 분당구 황새울로 BS 타워 6 ~ 9층
홈페이지: https://tmaxanc.com/

<부칙> 본 약관은 2020년5월 25일부터 적용됩니다.
`}
                </PageBodyPre>
              </PageInnerContents>
            )}
            {i18n.language === 'en' ? (
              <PageInnerContents id="2019-08-15">
                <PageBodyPre ismobile={isMobile}>
                  {`
Tmax Cloud Account Terms of Use


Chapter 1
1. Purpose and Definition
1.1. Purpose
TmaxOS Co. Ltd. (the “Company”) will provide an integrated service that combines the product TmaxOS, TmaxOS Software Center, TmaxOS website and Tmax CloudSpace (the “Integrated Service” or the “Service”), and hereby presents the Tmax Cloud Account Terms of Use (“Integrated Terms of Use”), which have been developed to stipulate the rights, obligations and responsibilities as well as conditions of use and procedures required for using the Integrated Service. The applicant for membership can use the Integrated Service by agreeing to be bound by these Terms, which does not mean, however, that the applicant is also automatically subscribed to third party services (TmaxData, TmaxSoft, etc.) other than those of the Company.
1.2. Definition
Tmax Cloud User (the “User”) means a person who signed up for the Tmax Cloud account after providing his/her personal information, to be continually provided with the Integrated Service information and services that the Company provides. 
The Integrated Service includes the following products and services. 
    1) Product TmaxOS: The operating system provided to the User, which is a system software required to manage system hardware or to provide services to run application programs.
2) TmaxOS Software Center: An integrated management program for App Store use and application updates. It allows the user to download application program such as ToOffice.  
3) TmaxOS Website: The website operated by the Company, which includes a company overview, product presentation, download center, customer support and PR center.
    4) Tmax CloudSpace: An individual service included in the integrated service provided by the Company, which divides into Tmax CloudSpace app services such as messenger, email, scheduling, notetaking, office tools, and file storage and associated services to help use Tmax CloudSpace such as customer support. 

2. Effect and Changes of Terms 
2.1. The content of these Terms is published on the Integrated Service page or individual services websites, and applicable to all parties agreeing to these Terms.
2.2. If necessary, the Company can change these Terms to the extent that such change remains compliant with applicable laws. Any updates to these Terms will be communicated or notified 15 days prior to the effective date via the Service Notification. If any changes are deemed unfavorable to the User, the Company will inform the User of such modifications 30 days prior to the effective date by sending an email to the address registered in the Tmax Cloud account of the User.
2.3. Once notified, the User can refuse to agree to the modified Terms within 7 days of the modified Terms coming into effect. In the absence of any explicit opposition by the User, the modified Terms shall be deemed to have been accepted. 
2.4. By refusing to accept the modified Terms, the User can choose to terminate the use agreement.
2.5. Starting from the date of agreement, the User is bound by these Terms until the use agreement is terminated in accordance with Article 11 of these Terms, except certain provisions of these Terms (in connection with posts, restriction on rejoining membership, indemnification, etc.) which can continue to remain in effect even after termination of the use agreement.

3. Extra-Terms of Use Rules
Any matter that is not stipulated in these Terms will follow relevant laws or individual service-specific terms of use or commercial practices. If these Terms conflict with individual service-specific terms, the latter will take precedence.
    
Chapter 2. Use Agreement of Integrated Service
4. Establishment of Agreement 
The use agreement of Integrated Service is concluded when the user agrees upon these Terms of Use.

5. Restriction on Membership
5.1. Application for membership can be denied in any of the following cases.
    1) The applicant is under 14 years of age.
    2) The applicant uses another person’s name, email address or other personal information to join the Integrated Service membership.
    3) The equipment used to provide the Integrated Service has no sufficient practical capacity.
    4) A technical issue occurs.
    5) The Company decides to do so for financial and technical reasons.
    6) A suspended user with blocked access to the Integrated Service cancels the use agreement arbitrarily and reapplies for membership during the suspension period.    
    7) There is non-compliance with relevant laws or the Company’s internal rules.
5.2. In any of the above cases, the User may be suspended from the Integrated Service or his/her Tmax Cloud account may be deleted.
    
Chapter 3. Use of Integrated Service
6. Supply of Different Services
6.1. Once the use agreement of Integrated Service is concluded, the User has full access to the product TmaxOS, Software Center, TmaxOS website, Tmax CloudSpace and other individual services provided as part of the Service, without additional subscription for each service.
6.2. Certain subservices within the Integrated Service may require agreement to additional terms of use and providing additional information, completing email authentication or other verification procedures used by the Company.
6.3. When the Integrated Service account is deleted, all data including service usage records and posts are immediately deleted except some information that needs to be retained for a certain period as required by relevant laws. However, any of the User’s posts that have been shared to a third-party or the User’s comments on a third-party’s post are not deleted, thus the User must delete them by him/herself before deleting the account. 
6.4. The Company provides the User with various online services such as Smart Work, collaboration tool, newsletter, dashboard, online content, software download service that are available also on mobile devices. For further information about individual service usage, you are invited to check the Application Store and each service’s customer support, notifications and announcements in the website.  
6.5. The Company provides the User who joined the membership with a non-exclusive license that is non-transferable and non-commercial. However, the Company has exclusive use of its trademarks and logos.
6.6. To improve service quality, the Company may update the software used for the Integrated Service. The software update includes addition of new features or deletion of unnecessary functions.
6.7. For better service, the Company provides the User with information within the Integrated Service via notifications, administrative messages and various promotional communications or the User’s personal contact including email or phone registered in his/her Tmax Cloud account. The Company will send promotional communications via the User’s personal contact only when he/she has opted in.
6.8. If you encounter a system error or discover any issues while you use the Integrated Service, please report it to the respective service’s customer support. 

7. Change and Termination of Integrated Service 
7.1. The Integrated Service may be limited or suspended partially or entirely in any of the following cases.
    1) Routine maintenance or occasional inspection of the equipment used to operate the Integrated Service.
    2) Power shutdown, equipment failure or sudden spike in usage affects the service operation.
    3) Termination of agreement with partners, government orders/regulations and other reasons prevent the Company from maintaining the part of or the entire Integrated Service
    4) Natural disaster, national emergency or other force majeure events impair the service operation.
7.2. When the Integrated Service is suspended in any of the above cases, the User will be notified in accordance with Article 17. However, in cases where prior notification cannot be made due to an unpredictable or uncontrollable event (disk or server failure, system shutdown which are not attributable to the Company), this Article does not apply.

8. Management of Posts
8.1. If any of the User’s posts contains content that violates the Act on Promotion of Information and Communications Network Utilization and Information Protection, etc. (“Network Act”), the User may be requested to stop posting or delete such content as required by relevant laws, in accordance with which the Company may take necessary actions.
8.2. If the Company believes that there is any infringement to rights or violation of the Company’s policies or relevant laws, an intermediate corrective action may be taken on the content in question.

9. Copyright and Use of Content
9.1. The User can post contents which include, but not limited to, photographs, writings, information, images (video clips), or any personal opinion or suggestion with respect to the Integrated Service or the Company on the Service. Any intellectual property rights including copyrights for such contents will of course continue to be held by the person who posted them.     
9.2. The User shall not publish any contents that contain pornography or violence, or any other materials that go against the public order and morality or relevant laws and regulations.
9.3. The Company may delete or reject any contents that are determined to be non-compliant to the relevant laws, obscene or harmful for adolescents, of discriminatory nature, repetitive and intrusive ads, advertisements for transferring or buying accounts or any fabricated/imposter content.
9.4. The Integrated Service may contain some external contents that are not owned by the Company. The person who provided such contents will be held solely responsible for the contents. Using the Integrated Service does not grant the user any rights to another user’s contents. 

10. Usage of the Integrated Service and Restrictions
10.1. Violation of any of the following or non-compliance to the Terms of Use or relevant laws will lead to an investigation into the user’s act of violation by the Company and the content in question will be deleted permanently or temporarily and the person who posted such content will be restricted from rejoining the Integrated Service.
        1) Entering fake information or accessing illegally with another user’s Tmax Cloud account or password, using another person’s identity or performing email authentication with another person’s address without that person’s permission.
        2) Copying, distributing or commercially using any information obtained from the Company’s service information without the Company’s prior consent. 
        3) Defaming or disadvantaging another person.
        4) Publishing pornographic content or add links to pornography sites.
        5) Infringing the Company’s or a third party’s copyright or other rights (the act of infringement of an intellectual property of a third party within or outside Korea, which includes any attempts to access services through means of deceiving the Company, by bypassing security measures such as IP address blocking implemented by the Company to prevent infringement of rights.   
        6) Publishing misleading information about the Company or a third party
        7) Spreading any kind of disturbing content including text, image or audio data of that nature.
        8) Uploading or spreading virus-infected materials that can cause malfunction, data destruction or any other kind of disruption on the equipment or systems associated with the Integrated Service.
        9) Sending information that disturbs the proper operation of the Integrated Service or any unwanted emails containing promotional content or spam messages even if the recipient has opted-out of such emails.
        10) Copying, editing, releasing, selling, transferring, renting, mortgaging or giving the services or software without the Company’s consent, and reverse-engineering the software or attempting to extract its source code to copy, disassemble, imitate or modify the services.
        11) Pretending to be another person or faking a relationship with another person.
        12) Collecting, storing or making publicly available another user’s personal information.
        13) Distributing false information for personal or another’s financial interest, or to harm another person.
        14) Advertising gambling or any speculative activities.
        15) Advertising prostitution or distributing any information for the purpose of promoting an act of prostitution.
        16) Sending audio, video, images or text that causes someone to feel humiliated, threaten or hatred to a particular person in a continuous manner to interfere with the person’s daily life.
        17) Sending or publishing any kind of information (including a computer program) of which the transmission or posting is not allowed by applicable laws.  
        18) Impersonating an employee of the Company or of an affiliate to publish a post or send emails or messages by using another person’s ID.
        19) Publish or send via email or SMS some materials containing software viruses, computer code, files or any other programs designed to impair or destroy computer software, hardware or electronic communication devices.  
        20) Stalking, making false or malicious report or any other acts of harassing another user.
        21) Transferring, giving, or providing as collateral the ownership of subscription of the Integrated Service or other contractual rights to another person.
        22) A minor under 14 years of age signing up for subscription to the Integrated Service.
10.2. The User’s access to the services is, in general, restricted progressively based on the cumulative number of breaches committed by the User, starting from temporary to permanent suspension. However, in cases where the User publishes or spreads pornographic content or advertises gambling activities or performs any other illegal acts that are prohibited by relevant laws, or infringes another person’s rights, which requires immediate actions to contain any potential risk or damage, the User may face permanent suspension of access immediately regardless of the number of breaches.

11. Cancellation of Use Agreement
11.1. When the User withdraws from the integrated account, the use agreement for the Integrated Service will be automatically terminated.
11.2. Withdrawal from the integrated account can be done in the website of Tmax CloudSpace or TmaxOS.
11.3. The User can cancel the use agreement for the Integrated Service at any time, via the relevant menus within the Service.
11.4. Once the use agreement for the Integrated Service is canceled, all data including the User’s personal information and postings, except some pieces of information that need to be retained as required by the applicable laws and Privacy Policy, will be deleted. However, any postings that have been shared by a third party or comments or other additional postings that the User added on a third party’s posting are not deleted. The User needs to delete them by him/herself before canceling the Service.
11.5. Postings that have not been deleted by the User as specified above will remain undeleted within the Integrated Service, to the extent necessary to ensure uninterrupted service for other users. 
11.6. If none of the individual modules of the Integrated Service has been used for a certain period of time, the user’s personal information can be destroyed or migrated into a separate storage, and his/her access to Service may be restricted. Find further details in the Tmax Cloud Account Privacy Policy.
11.7. Even after the termination of the use agreement for the Integrated Service, you can request a new use agreement with the Company. However, in the case of a termination of use agreement resulting from the incompliance to a relevant law, to these Terms or to the respective service-specific terms, the application for a new use agreement may be refused. 

12. Inactive Account
12.1. After 1 year of inactivity in the Integrated Service, the user’s account is classified as inactive. In the case where the user has used any of Tmax Cloud Space, TmaxOS websites or Software Center among the four sub-services at least once during the last 1 year, this provision does not apply.
12.2. Before the account is classified as inactive, the user will be notified 30 days prior to the classification via the email address registered during the membership application process. If no particular objection is made during the 30 days after the notification, the account will be classified as inactive.
12.3. Once the account is classified as inactive, the services become unavailable unless the user completes the account deactivation procedure.
12.4. The user’s personal information collected will be separately stored.
12.5. Once the account is classified as inactive, in the case of Tmax Cloud Space, the user’s data may be deleted. The Company is not liable for any potential damage incurred due to the deletion.

13. Privacy
All users’ personal information is used within the scope and purposes agreed by the users to ensure proper operation of the Integrated Service. To share users’ personal information with a third party, unless otherwise required by the applicable laws, the Company shall seek separate consent by the users. Find further details in the Tmax Cloud Account Privacy Policy.
    
Chapter 4. Miscellaneous
14. Indemnification
14.1. The Company makes no commitment or guarantee for anything that is not specified in these Terms, to the extent consistent with applicable laws. The Company does not guarantee the credibility nor the accuracy of any information, documents or articles provided by content providers or posted by users on the Integrated Service. The Company is not responsible for any damage that is not attributed to the Company. 
14.2. The Company is not liable for any of the following damages caused by: 
        1) Force majeure such as natural disasters or other catastrophic events
        2) Disturbance to the service operation by the User
        3) Personal damages incurred to the User while accessing or using the Service
        4) Illegitimate access to or use of the Company’s server by a third party
        5) Any transmission or interference to transmission to the Company’s server by a third party
        6) Malware transmitted or spread by a third party
        7) Loss of transmitted data (omitted, missing or destroyed) or any damages incurred by a third party while using the Service
        8) Personal data loss or system failure while downloading or using the Service
        9) Any damages that are not attributable to or unintended by the Company
14.3. The Company is not liable for any damages that are indirect, special, consequential, compensatory, or punitive.
14.4. The Company has no obligations, as long as nothing is attributable to it, to intervene in a dispute between the users or a user and a third party over an issue arising out of the Integrated Service, nor any responsibilities to compensate any damages resulting from such conflict.

15. Severability
If any provisions of these Terms are deemed invalid by a court of competent jurisdiction or determined to be illegal under applicable laws, then such provisions will be, to that extent, deemed omitted and the remaining provisions will continue in full force and effect.

16. Acknowledgement of Terms
The User hereby acknowledges that he/she has read, understood and agreed to all the details set forth in these Terms.

17. Notifications and Announcements
The Company will inform the User of any critical issue via the email address (if none, in-service message or pop-up notification) registered on the User’s account.

18. Dispute Resolution
These Terms are governed and construed in accordance with the laws of the Republic of Korea. If any dispute occurs between the Company and users over the use of the Service, the Company will fully cooperate to resolve it. If the dispute resolution fails even after all such efforts made, a legal action can be taken before the competent court.

19. Contact for Inquiry
If you have any questions about these Terms, visit the website below. For any inquiries about individual services-specific terms, find the contact point in the respective Terms.

Tmax OS Co., Ltd.
6 ~ 9F BS Tower, Hwangsaeul-ro, Bundang-gu, Seongnam-si, Gyeonggi-do
13595, South Korea
Website: https://www. tmaxos.com

These Terms of Use will become effective as of August 15, 2019.







Product TmaxOS Terms of Use

1. Purpose and Applicable Scope
1.1.	Purpose
Product TmaxOS Terms of Use is a legally binding agreement between an individual and Tmax OS Co., Ltd. (the “Company”) or between a business and the Company in connection with software products developed, sold, and/or released by the Company.
1.2. Applicable Scope
    1) Software provided through these Terms means all versions of TmaxOS (“Products”) which are provided through the TmaxOS website (www.tmaxos.com).
    2) Products of the Company may include not only the computer software but also accompanying materials, media content, files, data, publications or online/electronic documents.

2. Definition
2.1 “User” means a member or non-member who uses the services provided by the Products in accordance with these Terms.
2.2. “Member” is a person who has joined the membership with Tmax Cloud account after providing his/her personal information, to be continuously supplied with information about the Products and services provided by the Company.
2.3. “Non-Member” is a person who uses services provided by the Products without joining the membership.
2.4. “Partner” means a company that has signed a partnership agreement with TmaxOS.

3. Effect of Terms
3.1. These Terms of Use are published on the website of the Integrated Service or on each service-specific website, and apply to all parties agreeing to these Terms.
3.2 For any other matters not stipulated herein, the following shall apply, in the order of priority: Integrated Terms of Use, applicable laws, and commercial practices. In the case where there is a conflict between these Terms and the Integrated Terms of Use, these Terms will take precedence. In the case of non-members, applicable laws and commercial practices precede these Terms.

4. Change of Terms
4.1. If necessary, the Company can change these Terms to the extent that such change remains compliant with applicable laws. 
    Any change made to these Terms will be announced or notified to the member via the Service Notifications 15 days prior to the effective date. If any change is likely to impact the member negatively, he/she will be individually informed of such change 30 days before the effective date via the email address registered on his/her Tmax Cloud account. In the case of non-members, who have not provided their email address, announcements on the website will substitute for individual notifications.
4.2. In the absence of any explicit opposition from the user within 7 days after the Company’s notification or announcement as specified in the section above, the change to these Terms shall be deemed to have been accepted.
4.3. The user can choose to terminate the use agreement by expressing opposition to the changed Terms.
4.4. These Terms start to apply, in principle, from the date of agreement to the termination of the use agreement. However, certain provisions of these Terms (in connection with personal information, indemnification, etc.) may remain effective even after the termination of the use agreement.

5. Membership and Use
5.1. Joining the Membership
    1) The Product Use Agreement is concluded when a person who intends to join the membership completes the required process as guided by the Company. If you are a non-member, the use agreement is concluded when you agree to these Terms of Use. However, for child protection purposes, minors under 14 years of age are not allowed to join the membership.
    2) To use the Software Center, certain pieces of personal information are required. If you are a non-member, you need to join a separate membership to use the Software Center.
5.2. Usage
    1) If the user installs, copies or runs the product partially or entirely on a computer or network, it will be considered that he/she has agreed to these Terms. 
    2) If the user does not agree to these Terms, he/she cannot use the Product.
3) The version for personal use can be used at no cost for a certain period of time. To continue to use the Product after this free trial period ends, you may be required to pay a specified fee.
    4) By using the Product in accordance with the requirements specified below, you agree to the transfer of certain pieces of standard computer information required for Internet-based services during the process of certifying authenticity and checking validity.
5.3. Permission for Use
    1) If you agree to these Terms, you are authorized to use the Product by purchasing it or downloading a free version via a Company-authorized route, with a scope and period of use limited to those agreed previously or specified in the certificate included in the Product.
    2) These Terms apply to all versions of the Product released by the Company. If there is a separate license agreement specific to each edition or version of the Product, the license agreement takes precedence over these Terms. Any other things that have not been specified are subject to these Terms.
    3) The scope of use of the Product includes all devices of the Desktop Virtualization Environment (“Virtualized Environment”), and the total number of units to use including the local computer and a device for Virtualized Environment cannot exceed the number of purchased licenses.  
    4) The user can download or copy to use the Product legitimately, and make a copy of the Product for a backup or other retention purposes. However, it is not allowed to install the Product on a network sever for distributed usage across multiple computers on streaming and/or on a single computer for multiple users.    
    5) The user shall agree that he/she can be subject to an audit by the Company for compliance with these Terms. The Company can perform an audit to verify whether the user complies with these Terms during normal business hours, after giving the user a prior notice reasonably in advance. The user shall ensure all access to his/her facilities, workplace and servers by the Company or its authorized agents and fully cooperate in the Company’s investigations by taking all commercial actions necessary to help verify clearly whether the user complies with this agreement. The Company and its authorized agents shall comply with the user’s security requirements in the customer’s workplace. 
    6) The audit will be performed in the form of a validity check for the Product, which is mandatory to prevent any unauthorized use of the Product.
        a. Validity check determines:
            ① the authenticity of the Product
            ② the legitimate use of the Product  
            ③ the validity and use of the Product as well as any non-authorized modifications to the authenticity certification feature.
            ④ the existence of any malware or unauthorized software for arbitrary modifications
        b. If you are acknowledged to have the legitimate use rights of the Product after the validity check, you can continue to use the Product or certain features of the Product or additional offers may be provided. 
        c. If the validity check suggests an illegal duplication of the Product, unauthorized use, counterfeit or arbitrarily modified version, the functionality and operation environment may be restricted as in the following examples.   
            ① The Company may remove, isolate or disable any unauthorized modifications made to the Product with the intention of bypassing the validity check and thus disturbing proper use of the Product.
            ② Malware or any unauthorized software intended to modify the Product can be eliminated once identified. 
            ③ Any identification of non-authorized use of the Product or counterfeit may be notified.
            ④ A message instructing the user to obtain an authorized copy of the Product may be displayed.
            ⑤ Certain Company rules may require reauthorization of the use and recertification of the authenticity of the Product. 
            ⑥ The Product or certain features of it may not be continuously available or some updates or upgrades may be limited.
    7) Authority for installation and use
        a. One copy of the Product can be installed on one computer. This computer becomes the authorized device for the Product use.
        b. Up to two processors can be simultaneously used on one computer with the authorized use. Unless otherwise specified in the use agreement, the Product cannot be used on another computer. 
        c. Unless otherwise specified in the use agreement, the Product can be used by one user at a time.
        d. The Product can include more than two versions (both 32-bit and 64-bit for example). You can install and use only one version at a time.
    8) Requirements for additional use and authority
        a. Using the Product’s built-in fonts for content display and print while running the Product is restricted to the following cases.
Including fonts on the content within the allowable scope.
            ② Temporarily downloading fonts on a printer or other printing devices to print the content.
        b. You can use, but not share, the Product’s icons, images, sound or media while running the Product.
            ① Any commercial use of sample images, sound and media provided along with the Product is not allowed. 
        c. Mandatory authenticity certification
            ① Authenticity certification is the process of concatenating the Product on a computer for the purpose of using the Product on that specific computer. 
During the process of authenticity certification, the Product transfers the computer information to the Company.
This information includes, but not limited to, the Product version, language, Product key, the computer’s IP address and hardware configuration details.
Upon using the Product, you agree to the transfer of such information.
If you have obtained the use rights in a legitimate way, you are qualified to use the version of Product installed during the installation process within the validity period of the authenticity certification.
Authenticity certification is a mandatory procedure to prevent any unauthorized use. Without authenticity certification, the Product will not be available after the validity period of the authenticity certification expired.
5.4. Rights of the Company and Prevention against Infringement
    1) All originals and copies of the Product must include the copyright information.
    2) All rights including, but not limited to, intellectual properties such as copyrights, patents and trademarks for the Product and its components, accompanied prints and copies are held by the Company.
    3) The user must comply with the intellectual property law of Republic of Korea, international conventions and treaties on IP or other relevant laws and regulations when using the Product. It is prohibited to perform any reverse-engineering, decompiling or disassembling of the Product beyond the extent expressly permitted by relevant laws as well as any duplication, modification or adaptation of all or certain components of the Product. Distribution of any copies of the Product and related prints that are not authorized by the Company is also prohibited.     
    4) In a case where the user violates the Company’s rights by committing any of those acts mentioned above, the Company can demand the user to indemnify the Company for all damages incurred by such violation.
5.5. Scope of Use Rights
    1) These Terms grant the user the rights to use the features included in the version of Product for which the use is authorized. Any other rights are held by the Company. The user can use the Product only under conditions that are expressly permitted in this agreement.
    2) In cases where the Product can be used only in accordance with specific methods, all technical restrictions must be complied with. The following acts are not allowed.
        a. Violating any technical restrictions of the Product
        b. Disabling or bypassing technical protection measures, security systems or limitations on certain features which are implemented by the Product’s technical restrictions or any attempts to perform such acts.
        c. Reverse-engineering, decompiling or disassembling the Product beyond the extent expressly permitted by relevant laws.
        d. Using the Product’s components with the intention of running an application program that cannot run on the Product. 
        e. Making more copies of the Product than specified in this agreement or allowed by relevant laws
        f. Making the Product available to public so that other people can copy it.
        g. Renting, lending or borrowing the Product
        h. Using the Product on a commercial software hosting service
5.6. Scope of Warranty
    1) If the user purchases the Product in a CD type, the Company offers a 30 days-warranty for any physical defect on the CD and other accompanying components. If any manufacturing defect is discovered in this period, the defective product will be replaced with new one.
    2) For a warranty replacement, the user needs to prove that it has been less than 30 days since the purchase of the product. If the product is damaged due to the user’s error, lack of care or inappropriate treatment, no replacement is offered. 
5.7. Automatic update
    1) The Product contains an embedded internet-based communication feature as a part of the normal operation for the routine update. During automatic update, certain files may be installed on the user’s computer as needed, and such behavior may change at any time. 
    2) Update is installed without user’s prior consent. It is considered that the user accepted that by agreeing to these Terms.

6. Protection of Personal Information 
6.1. To improve the quality of the Product, the Company collects non-personal information such as the user’s computer operating system, product version, error data which cannot be used to identify an individual’s identity.   
6.2. For statistical analysis on ads included in the Product, the Company collect data such as the frequency of ads and click-through rate.
6.3. Such collected data will be temporarily used for statistical purposes, without being retained permanently by the Company.
6.4. The Company collects the minimum amount of personal information that users provide during the membership application and does not use such information for any other purposes than those specified.
6.5. Find the Privacy Policy of the Company in the TmaxOS wensite.   
    (http://www.tmaxos.com)

7. End and Termination of Agreement
7.1. If the user fails to comply with these Terms, the Company can terminate the use agreement. 
7.2. If the user refuses to agree to updated Terms, he/she can terminate the use agreement by deleting the Product.
7.3. The user can withdraw from the agreement either in the Tmax CloudSpace or TmaxOS website.

8. Inactive Account
8.1. After 1 year of inactivity in the Integrated Service, the user’s account is classified as inactive. In the case where the user has used any of Tmax Cloud Space, TmaxOS websites or Software Center among the four sub-services at least once during the last 1 year, this provision does not apply.
8.2. Before the account is classified as inactive, the user will be notified 30 days prior to the classification via the email address registered during the membership application process. If no particular objection is made during the 30 days after the notification, the account will be classified as inactive.
8.3. Once the account is classified as inactive, the services become unavailable unless the user completes the account deactivation procedure.
8.4. The personal information collected from the user will be separately stored.
8.5. Once the account is classified as inactive, in the case of Tmax Cloud Space, the user’s data may be deleted. The Company is not liable for any damage incurred due to the deletion.

9. Indemnification
9.1. All results of the use of the Product fall under the responsibility of the user, and the Company does not provide any explicit guarantee for the Product. 
9.2. The Company is not responsible for any direct or indirect damages resulting from inability to use or incorrect use of the Product due to insufficient understanding of the usage of the Product.
9.3. The Company excludes any explicit or implicit guarantees against marketability, suitability for specific purposes and non-aggression.
9.4. The Company is not held responsible for any of the following damages incurred due to:
1) Force majeure such as natural disasters or other catastrophic events
        2) A system failure caused by the user error during use
        3) User’s access to or use of the Product
        4) A third party’s illegal access to or use of the Company’s server 
        5) Transmission or interference to transmission to the Company’s server by a third party
        6) Malware transmitted or spread by a third party
        7) Missing/lost/destroyed data or defamation suffered by a third party during use of the service.
        8) Loss of personal data or system failure during download or use of the service.
        9) Anything that is not attributable to or unintended by the Company
9.5. The Company is not liable for any indirect, special, consequential, compensatory, or punitive damages. 
9.6. The Company has no obligations, as long as nothing is attributable to it, to intervene in a dispute between users or a user and a third party over an issue arising out of the Product, nor any responsibilities to compensate any damages resulting from such dispute.

10. Notification of Company
If there is any information to be notified to the member, the Company will deliver it via the email address registered during the membership application process. For non-members, announcements on the TmaxOS website will substitute for individual notifications.

11. Miscellaneous
11.1. Severability
If any provisions of these Terms are deemed invalid by a court of competent jurisdiction or determined to be illegal under applicable laws, then such provisions will be, to that extent, deemed omitted and the remaining provisions will continue in full force and effect.
11.2. Acknowledgement of Agreement
The user hereby acknowledges that he/she has read, understood and agreed to all the details set forth in these Terms.
11.3. Settlement of Disputes
These Terms are governed and construed in accordance with the laws of the Republic of Korea, and Seoul Central District Court has exclusive jurisdiction over all disputes, controversies or claims arising under, out of, or related to the use of the Product. 
11.4. Contact Information
For any questions about these Terms, find a contact in the following.

Tmax OS Co., Ltd.
6 ~ 9F BS Tower, Hwangsaeul-ro, Bundang-gu, Seongnam-si, Gyeonggi-do
13595, South Korea
Website: https://tmaxos.com

These Terms of Use will become effective as of August 15, 2019.


Tmax Software Center Service Agreement/Terms of Use

1. Purpose
TmaxOS Software Center (hereinafter referred to as "Software Center") is a service provided by TmaxOS Co., Ltd. (hereinafter referred to as "Company") located in BS Tower, Hwangsaeul-ro, Bundang-gu, Seongnam-si, Gyeonggi-do, South Korea. These Terms of Use (hereinafter referred to as “these Terms” or “Agreement”) stipulate the rights, obligations and responsibilities of the Company and the service users.

2. Definition
The definitions of terms used herein are as follows.
2.1 “Software Center” means an integrated management program that manages the use of app stores and application updates, and a service that enables to download programs such as ToOffice.
2.2 “Member” means the customers who install and use the TmaxOS and the users who create the Tmax Cloud account.
2.3 “Contents” mean the various information and services provided to the user through TmaxOS and the Software Center.
2.4 “Provider” means third party provider who provides the contents.

3. Effect of Terms and Change to Terms
3.1. Effect of Terms
a) Software Center Terms of Use, the TmaxOS Terms of Use, and the Tmax Cloud Terms of Use (hereinafter referred to as the "Integrated Terms" and, collectively referred to as the “Terms”) shall be applied to the use of the digital contents or services (hereinafter referred to as the "Contents"), such as the Software Center and the applications, etc. provided by the Software Center. Software Center is a service as defined in the TmaxOS Terms of Use. In the event of any conflict between Terms, the order of the Integrated Terms, Software Terms of Use, and TmaxOS Terms of Use shall take effect.
b) Additional terms and conditions of the End User License Agreement between Members and the Provider shall be applied to the use of the applications. 
3.2. Changes to these Terms
a) If the Terms are changed, the Company shall notify the Member at least fifteen (15) days in advance, and if the Member continues to use the Software Center after this period, it shall be deemed to have agreed to the new terms of use. The new terms of use shall be applied to the Member’s use of all Contents, including the contents Members installed or purchased in the past, and to all installations and purchases in the future.
b) In the event that the Members do not agree to the changes, Members can download previously purchased or installed Contents and terminate the use of the Software Center. The Members will still be able to view previously downloaded Contents on the Members’ device pursuant to the terms and conditions the Members last accepted.

4. Joining Membership and Service Use
4.1. Sign Up
a) This Agreement shall be concluded by the person who wishes to become a Member to complete the procedure of the member registration provided by the Company. However, for the protection of children, membership under fourteen (14) years old shall be restricted.
b) In the event that the Members want to use the Software Center, the Members shall provide certain personal information.
4.2. Use of Service
a) Use of Contents
(ⅰ) Members can access the Software Center to browse and download Contents for use on supported devices such as computers (hereinafter referred to as “Devices”). Contents include the Company-provided contents and contents provided by third parties that are not affiliated with the Company.
(ⅱ) Access to the Software Center requires the Device, an internet connection, and compatible software that meets the system and compatibility requirements for the associated Contents.
b) Service Fee
(ⅰ) Contents without ‘charged’ mark in the Software Center is available free of charge. However, depending on the charging policy in the future, some Contents may be converted into a paid service.
(ⅱ) Members are responsible for the fee for access of third parties (internet providers or other contents providers) or charge for data in the use or navigation of the Contents and Software Center.
c) Update
(ⅰ) By accepting these Terms and using the Software Center, it shall be deemed to agree that the user receives updates automatically. To receive bug fixes, improvements, omitted plug-ins, and new versions (collectively referred to as “updates”), the user must update the related support libraries or Contents in the Software Center.
  (ⅱ) Updates related to specific Contents shall be managed in the settings in the Software Center. Provided, however, that if the Company determines that an update can resolve serious security vulnerabilities with respect to the Contents, the update may run regardless of the update settings on the Software Center or on the device.
d) Protection of Personal Information
Member's personal information shall be used only for the purpose and scope of Member's agreement in order to provide the integrated service smoothly. Unless the relevant laws and regulations provide the Company with personal information to third parties, the Company must obtain separate consent of Members.
For more information, refer to the Tmax Cloud Account Privacy Policy.
e) Malware Blocking
(ⅰ) To protect the Members from malicious software of third-party and other security issues, Company may receive information about network connections, potentially harmful URLs, operating systems and apps installed through the Software Center or other sources from the Member’s device. 
(ⅱ) If the Company judges that an app or URL is unsafe, the Company may display a warning message, and block the installation of the app or delete the app that is known to be harmful to the device, data, or users.

5. Rights and Restrictions
5.1 License for Contents
Members may store and use copies of the Contents only in the manner expressly stipulated in these Terms, and shall have a non-exclusive right to use only for personal and non-commercial purposes. All rights, authorities and interests of the Software Center and Contents not expressly granted to Members in these Terms shall be owned by the Company or its Provider.
 5.2 Violation of Terms
In the event that one of the following cases happens or the Members violate these Terms, the membership shall be restricted, and if this violation continues, the Member shall lose the rights and the Company may terminate the use of Software Center, Contents and Tmax Cloud integrated account. For some violations, the Member may lose its rights immediately.
Restrictions may prevent members from accessing Tmax Cloud account details or files or other Contents saved using the account.
a) Displaying any or all of the Contents as part of a performance or exhibition;
b) Selling, renting, leasing, redistributing, broadcasting, transmitting, delivering, modifying, sublicensing, reselling, transferring or handing over the Contents, including the download of Contents acquired through the Software Center.
c) Being shared, rented, used for multilateral use, or used as part of any service for any other purpose.
d) Attempting to bypass, disable or destroy any security feature or component that protects, encrypts or restricts access to the Content or Software Center, or assisting, approving or recommending such actions to other users.
e) Attempting to modify the Contents obtained through the Software Center, including by removing watermarks, labels, other legal or proprietary rights notices contained in the Contents, or by altering to disguise or alter the ownership or origin of the Contents.
f) When the username has multiple Tmax Cloud accounts, the transfer of the Contents from one account to another is only available when the Member is the owner of each account or the Company has enabled the relevant service functions to transfer the Contents. And it shall not be allowed when the content is not specified in these Terms.
g) Any service or Content is used in the operation of nuclear facilities, life support systems, emergency communications, air navigation or communications systems, air traffic control systems, or is used for the activities that may lead to death, bodily injury or serious physical or environmental damage due to service or content errors.
5.3 Limitation of Company’s Liability
The Company shall not be liable for the Contents provided through the Software Center by third parties other than the Company and makes no warranty.

6. Notice of Company
If the Company has anything to notify, it will be delivered via the e-mail address provided during the membership application process.

7. Cancellation and Termination
7.1 If the Member does not comply with the terms of this Agreement, the Company may terminate this Agreement.
7.2 The user may ask for cancellation at any time using the menu provided in the service.
7.3 Members may withdraw from the membership through the websites of Tmax CloudSpace and TmaxOS.

8. Inactive Account
8.1 After 1 year of inactivity in the Integrated Service, the Member’s account is classified as inactive. However, if the Member uses the service of Tmax CloudSpace, TmaxOS website and Software Center at least once among the four (4) sub-services within one (1) year, this provision shall not apply.
8.2 The Company shall give prior notice by e-mail to the Member thirty (30) days before deactivating the account. After thirty (30) days of the notice without no particular opposition from the Member, the account will become inactive.
8.3 If the Member’s account became inactive, the Member shall go through a separate procedure of reactivating the account to access the services again.
8.4 The collected personal information of Members is kept separately.
8.5 In the case of Tmax CloudSpace, the data of a Member with inactivate account may be deleted. The Company shall not be held responsible for any damages resulting from this.

9. Indemnification
9.1 The Company shall not be liable for any damages incurred by Members in connection with the use of the Software Center according to these Terms.
9.2 Members shall be responsible for compensation of any loss or damage incurred by the Company in the event of damage to the Company related to the use of their Software Center.

10. Miscellaneous
10.1 Severability
If any provision of these Terms is interpreted to be invalid or in violation of any applicable law in any jurisdiction, that provision may be interpreted or invalidated to the extent permitted by law. The invalidity of such provisions shall not affect the validity of these Terms and the contents of other provisions.
10.2 Acknowledgement of Agreement
The Members shall acknowledge that the Members have read, understood and agreed to all the terms set forth in this Agreement.
10.3 Settlement of Disputes
These Terms shall be governed by the laws of the Republic of Korea, and shall have exclusive jurisdiction in the Seoul Central District Court of Korea for all legal matters such as lawsuits related to the use of the Software Center.
10.4 Inquiries about these Terms
For questions about the terms and conditions of this Agreement: 
Tmax OS Co. Ltd.
6~9F BS Tower, Hwangsaeul-ro, Bundang-gu, Seongnam-si, Gyeonggi-do
13595, South Korea
Website: http://www.tmaxos.com
Announcement Date: August 15, 2019
Effective Date: August 15, 2019


[Tmax CloudSpace Terms of Use]


1. Purpose

These Terms of Use are intended to stipulate rights, obligations and responsibilities of Tmax OS Co.,Ltd. (the “Company”) and the User as well as other requirements in using Tmax CloudSpace, which is provided by the Company.

2. Definition

2.1. Definitions of terms used herein are as follows. 

1) Tmax CloudSpace means all the Tmax CloudSpace application services including messenger, mail, scheduling, notetaking, office tools and file storage, as well as other related services such as technical support for Tmax CloudSpace users.
2) “User” means a person bound by the use agreement with the Company signed in accordance with these Terms, and thus qualified by the Company to use Tmax CloudSpace.
3) Tmax CloudSpace “ID” means a combination of letters, numbers and special characters set up by the User for identification and use of Tmax CloudSpace. 
4) Tmax CloudSpace “Password” means a combination of letters, numbers and special characters to protect his/her privacy.
5) “Integrated Terms of Use” means the Tmax Cloud Account Terms of Use for the Integrated Service.

2.2. Any other terms used herein but not stipulated in the present Article comply with the Tmax Cloud Account Terms of Use, applicable laws and commercial practices.


3. Effect and Change of Terms

3.1. The content of these Terms is published on the Integrated Service web page or individual service websites, and applicable to all parties agreeing to these Terms.
3.2. For any other matters not stipulated herein, the following shall apply, in the order of priority: Integrated Terms of Use, applicable laws, and commercial practices. If there is any conflict between these Terms and the Integrated Terms, the former takes precedence.
3.3. If necessary, the Company can change these Terms to the extent that such change remains compliant with applicable laws.
Any updates to these Terms will be announced or notified 15 days prior to the effective date via Service Notification. If any changes are deemed unfavorable to the User, the Company will inform the User of such modifications 30 days prior to the effective date by sending an email to the address registered in the Tmax CloudSpace account of the User (if none, in-service messages or pop-up notifications). If the User continues to use Tmax CloudSpace even after the service period expired, it will be considered that he/she has agreed to the new Terms of Use. The new Terms of Use will apply to the use of all contents (including those used or purchased by the User previously) as well as the future use and purchase of services.
3.4. If the User does not agree to changes to these Terms, he/she can request to terminate the use agreement for Tmax CloudSpace by deleting his/her account in the Tmax CloudSpace custom settings menu. 


4. Membership and Use of Service

4.1. The use agreement of Tmax CloudSpace is concluded when a person who intends to join the Tmax CloudSpace membership (“applicant for Tmax CloudSpace membership”) completes the process of joining the Tmax CloudSpace membership as guided by the Company. However, for child protection purposes, minors under 14 years of age are not allowed to join the membership.

4.2. The applicant for Tmax CloudSpace membership needs to provide some basic personal information (name, date of birth, email address, etc.) required by the Company during the membership joining process. 

4.3. For the applicant for Tmax CloudSpace membership to use additional services other than those provided by the Company, he/she needs to go through a separate process to request for the use and may also have to provide additional information during the process.

4.4. Tmax CloudSpace is provided at no cost to users who joined the membership, but certain information can be accessed only when the specified fee is paid.

4.5. For the use of a third-party application service while using Tmax CloudSpace after having joined the membership, you may need to agree to the service-specific terms of use and provide additional information.


5. Protection of Personal Information 

The User’s personal information is used only within the scope and purposes agreed by him/her to ensure a smooth operation of services. When obliged to share any piece of personal information with a third party beyond the extent allowed by applicable laws, the Company shall obtain an additional approval from the User. For further details, refer to the Tmax Cloud Account Privacy Policy. 


6. Use of Tmax CloudSpace

6.1. Tmax CloudSpace is provided in accordance with the operational policy established by the Company. When required for any operational or technical reasons, the operational policy can be revised or the operation of Tmax Cloud may be suspended.

6.2. If the use of Tmax CloudSpace is suspended under conditions prescribed in 6.1, the Company shall notify the User of the suspension in advance as required by Article 7. However, there may be no notification if the suspension of the operation is due to a force majeure event.

6.3. If the User fails to meet any of the requirements specified in the Integrated Terms of Use and these Terms, the Company can stop providing the User with services of Tmax CloudSpace.

6.4. If the User wants to use Tmax CloudSpace again after the suspension of use in accordance with the previous section, he/she needs to send a request email to the Customer Support email address (cspsupport@tmax.co.kr).


7. Notification of Company

The Company notifies the User of any information that needs to be delivered via the email address registered during the membership application process.


8. Termination of Use Agreement by Company

8.1 In any of the following cases, the Company may terminate the use agreement of Tmax CloudSpace signed with the User after deleting all data related to the User.
1) The User fails to fulfill his/her obligations specified in these Terms.
2) The User disturbs the Company in properly operating Tmax CloudSpace.
3) The User uses Tmax CloudSpace illegally or with an unauthorized method.
4) The Company determines that termination of the use agreement for Tmax CloudSpace with the User is necessary for any other reasons equally justified as above.
    
8.2 The present Article hereby specifies that if the User is no longer able to use Tmax CloudSpace, the Company is not held liable for any damages incurred due to his/her inability to use the services.


9. Termination of Use Agreement by User

9.1. The User can terminate the use agreement for Tmax CloudSpace at any time. 

9.2. Before terminating the use agreement of Tmax CloudSpace, the User needs to take a backup of all resources he/she has used during the service period. 

9.3. When terminating the use agreement in accordance with 9.1, all resources that have been provided to the User will be retrieved and all information stored in the User’s Tmax CloudSpace account will be deleted except some data that needs to be retained by the Company as required by applicable laws and the Privacy Policy. Deleted data will not be recovered for any reasons.

9.4. The User can withdraw from the service through the Tmax CloudSpace or TmaxOS website.


10. Inactive Account

10.1. After 1 year of inactivity in the Integrated Service, the account is classified as inactive. In the case where the User has used any of Tmax CloudSpace, TmaxOS websites or Software Center among the four sub-services at least once during the last 1 year, this provision does not apply.

10.2. Before the account is classified as inactive, the User will be notified 30 days prior to the classification via the email address registered during the membership application process. If no particular objection is made during the 30 days after the notification, the account will be classified as inactive.

10.3. Once the account is classified as inactive, the services become unavailable unless the User completes the account deactivation procedure.
10.4. The User’s personal information collected will be separately stored.
10.5. Once the account is classified as inactive, in the case of Tmax CloudSpace, the User’s data may be deleted. The Company is not liable for any damage incurred due to the deletion.

11. Indemnification

11.1. The Company is not liable for any damages incurred to the User in connection with the operation of Tmax CloudSpace based on these Terms.
11.2. The User is held responsible for all losses and damages suffered by the Company as a result of his/her use of Tmax CloudSpace.

12. Miscellaneous

12.1. Severability
If any provisions of these Terms are deemed invalid by a court of competent jurisdiction or determined to be inconsistent with applicable laws, then such provisions will be, to that extent, deemed omitted and the remaining provisions will continue in full force and effect. 

12.2. Acknowledgement of Agreement
The User hereby acknowledges that he/she has read, understood and agreed to these Terms.

12.3. Dispute Resolution
These Terms are governed and construed in accordance with the laws of the Republic of Korea, and Seoul Central District Court has exclusive jurisdiction over all disputes, controversies or claims arising under, out of, or related to the use of Tmax CloudSpace.


12.4. Contact Information

For any questions about these Terms, find a contact in the following.  


Tmax OS Co., Ltd.
6 ~ 9F BS Tower, Hwangsaeul-ro, Bundang-gu, Seongnam-si, Gyeonggi-do
13595, South Korea
Website: https://tmaxanc.com

These Terms of Use will become effective as of August 15, 2019.


TmaxOS Website Service Agreement/Terms of Use

1. Purpose
These Terms of Use (hereinafter referred to as “Terms” or “Agreement”) stipulates the rights, obligations and responsibilities of the Company and the User in using the Internet-related services (hereinafter referred to as the "Services") provided by the website operated by TmaxOS (hereinafter referred to as "the Company"). 

2. Definition
The definitions of terms used herein are as follows.
2.1. “Site” means a fictitious place of business or a website operated by the Company to trade goods or services using information and communication facilities such as a computer to provide goods or services to users. The Site includes the following websites.
a) TmaxOS Co. Ltd.: http://www.tmaxos.com;
b) Overseas corporation operated by TmaxOS Co. Ltd.: http://www.tmaxsoft.com/us_en/home/
c) and other non-profit sites operated by TmaxOS Co. Ltd.
2.2. “User” means members and non-members who access the site and receive the services provided by the site according to these Terms.
2.3. “Member” means a person who joined the membership by providing personal information on the site, and is eligible for receiving information on the site and continuously using the services provided by the Company.
2.4. “Non-member” means a person who uses the services provided by the site without joining the membership.
2.5. “ID” means a combination of letters, numbers, and special characters designated by the member for the member's identification and service use.
2.6. “Password” means a combination of letters, numbers, and special characters selected by a member to protect his or her privacy.


3. Effect and Change of Terms
3.1 This Terms of Use shall be effective upon disclosure to the Member and consent of the User. However, if the User is a non-member, these Terms will be effective when the User starts using this site.
3.2 The Company may change these Terms to the extent that such change remains compliant with applicable laws such as the 『Regulation of Standardized Contracts Act』, the 『Digital Signature Act』, the 『Law regarding the promotion of information and communication network use and protection of information』, etc. In that case, the change, including effective date, reason for change, and changed contents, shall be displayed on the first page with the current terms and conditions from fifteen (15) days before the effective date to the day of the effective date.
3.3 The terms and conditions changed under Paragraph 3.2 shall be effective in the same way as Paragraph 3.1.
3.4 Matters not provided in this Agreement shall apply in the order of Tmax Cloud Account Terms and Conditions (hereinafter referred to as the "Integrated Terms"), relevant laws and regulations, and the commercial practices and in the event of any conflict between these Terms and the Integrated Terms, these Terms shall take precedence.

4. Establishment of Terms of Use
4.1. This Agreement is established by the User's application and agreement. However, for the protection of children, membership under fourteen (14) years old is restricted.
4.2. If the User wants to use the company's services by joining the membership, the User must provide the personal information requested by the Company.
4.3. Joining the membership may be restricted in the following cases:
a) When using another person’s name;
b) When using false, incorrect or missing information in the application form;
c) When having the purpose of hindering the stability or morals of society;
d) When a person whose membership has been terminated or suspended by the Company after committing the acts prescribed in Paragraph 12.4 of the Terms attempts to join the membership again.


5. Protection of Personal Information
Members' personal information will only be used within the purpose and scope agreed by the Members for the smooth provision of integrated services. Unless otherwise specified by relevant laws and regulations, the Company shall obtain the Member’s consent to provide personal information to third parties. The User may refer to the Tmax Cloud Account Privacy Policy for further details.

6. Service Use and Restrictions
6.1. Members can use the services of the Site with the ID issued when signing up for the membership through the Company. All sites listed in Paragraph 2.1 can be used after a separate sign-up procedure for each. In this case, the terms and conditions for the services of a specific site shall take precedence over this Agreement.
6.2. Even if new sites are created after the Member's initial sign-up for use of the service, the contents of Paragraph 6.1 shall still apply unless otherwise specified in the terms of use of the new sites. In this case, the information about the service launch of the new sites will be delivered to the Members via notice on the site or e-mail.
6.3. The service of the site shall be provided for 24/7 unless there is a particular issue in business or a technical error on the site.
6.4. The Company may suspend the provision of services in the event of regular inspections, repairs, replacements, breakdowns, or loss of communication, etc.
6.5. In the event of the suspension of service pursuant to Paragraph 6.4, the Company shall notify the User in accordance with Article 17. However, this shall not apply when notifying the service suspension is not possible due to a service interruption which is out of control of the Company.
6.6. The services provided by the Company are basically free of charge. However, some charged services can be accessible only after the specified fees are paid.
6.7. Copyrights and other intellectual property rights of the work provided by the Company shall belong to the Company.
6.8. The User shall not use any information obtained by using the site for commercial purposes or make it available to third parties by copying, transmitting, publishing, distributing, broadcasting or other means without the prior consent of the Company.
6.9. The Company shall not be liable for any damages incurred in connection with the use of the service provided for free.
6.10. All IDs of Members who request the use of the site with another person’s information (ID or e-mail) shall be deleted, and may be punished according to relevant laws.

7. Obligations of Company
7.1 The Company shall allow the service to be available on the date when the terms of use for the service requested by the Members is established, unless otherwise required.
7.2 The Company is obliged to provide the service continuously and stably according to the terms and conditions of this agreement.
7.3 The Company shall resolve the issues raised by the Users with the appropriate procedures, and if the processing of resolution takes a certain period of time, the Company shall inform the User of the reason and the schedule.
7.4 The Company shall keep the Member's information strictly secure, use it only for the purpose of operating or improving the quality of the service or for internal purposes such as product introduction, and shall not transfer it to other organizations and third parties for other purposes than specified herein.

8. Obligations of Members
8.1 Members are entirely responsible for the management of their ID and password.
8.2 Members shall not allow third parties to use their ID and password. When the Member’s ID and password are stolen or used by a third party, the Members shall notify it to the Company.
8.3 The Users, including Members, shall abide by the provisions of this Agreement and related laws.
8.4 The Users, including Members, shall not do the following:
a) Registering false information when signing up or changing;
b) Modifying the information published on the site;
c) Sending or posting information other than the information prescribed in the site (computer programs, for example.);
d) Infringing the intellectual property rights, such as copyrights of the Company and other third parties
e) Defaming the Company or other third parties or disrupting business
f) Disclosing or posting any obscene or violent message, video, audio or other information that offend the public morals;
g) Stealing other people's information
8.5 The User is responsible for any misuse of the ID and password by him/herself or a third party.

9. Users’ Posts
The Company may delete the contents posted by the User without prior notice in case of:
a) Defamation by insulting or slandering another user or a third party;
b) Violation of public order and morals;
c) Being related to criminal activity;
d) Exceeding the posting period prescribed by the Company;
e) Violation of other relevant laws;
f) Violation of other terms and conditions of service set by the Company

10. Copyright of Posts
10.1 The rights and responsibilities of the posts belong to the writer, and the Company cannot use them without the writer's consent.
10.2 The Users may not commercially use the materials posted on the service, such as processing or selling the information obtained using the service.

11. Termination and Cancellation of Agreement
11.1 When the Member wants to terminate this Agreement, the Member shall request the termination directly on the website. Membership withdrawal can be done on Tmax CloudSpace and TmaxOS sites.
11.2 In the case of the preceding paragraph, the Member shall enter his/her ID and password to confirm his/her identity, and then the Member’s membership and this Agreement will be terminated by checking the instructions of termination and re-entering the password.
11.3 If the User cannot log in with his/her existing ID and password, it means that the termination of membership and this Agreement is successfully done.
11.4 The Company may terminate this Agreement without prior notice or suspend the service for a certain period of time without prior notice in cases where the Member:
a) violates the public order and morals;
b) is involved in crimes;
c) plans or executes the use of the service for the purpose of undermining the national interests or social public interest;
d) steals other’s ID and password;
e) defames or disadvantages another person;
f) prevents proper use of services, such as causing harm to the service;
g) violates other related laws or conditions of use specified by the Company

12. Inactive Account
12.1 If the Member does not use the integrated service for one (1) year, the account shall be converted into an inactive account. If the Member has used the Tmax CloudSpace, TmaxOS Homepage and Software Center among the four sub-services at least once within one (1) year, this provision shall not be applied.
After 1 year of inactivity in the Integrated Service, the Member’s account is classified as inactive. However, if the Member uses the service of Tmax CloudSpace, TmaxOS website and Software Center at least once among the four (4) sub-services within one (1) year, this provision shall not apply.
12.2 The Company shall give prior notice by e-mail to the Member thirty (30) days before deactivating the account. After thirty (30) days of the notice without no particular opposition from the Member, the account will become inactive.
12.3 If the Member’s account is converted into an inactive account, the Member must go through a separate releasing account procedure in order to use the service.
12.4 The collected personal information of Members shall be retained separately.
12.5 If the account becomes inactive, the Member’s data may be deleted in Tmax CloudSpace. The Company shall not be held responsible for any damages caused by this.

13. Release of Service Suspension
13.1 If the Company intends to stop providing the service, the Company shall notify the Member or an agent of the reasons, date and time period by e-mail or etc. However, if the Company determines that it is urgently necessary to stop providing the service, the service provision may be suspended without any prior notice.
13.2 Members or their agents who are notified of the suspension of service in accordance with the preceding paragraph may raise an objection against the suspension of service.
13.3 The Company shall immediately release the suspension only when it is confirmed that the reasons for suspension have been resolved during the suspension period.

14. Provision of Information
14.1 The company can provide the Members with the information for the services and various events by e-mail, etc.
14.2 The Company may require additional personal information with the consent of the Member for the purpose of improving the service and introducing the service to the Member.

15. Advertisements
The Company may provide the product advertisements to Members by email with the consent of the Member.

16. Disclaimer
16.1 The Company shall not be liable for providing the services in the event that the Company is not able to provide services due to natural disasters or other force majeure events.
16.2 The Company shall not be liable for any failure to use the service due to the User's fault.
16.3 The Company shall not be liable for any loss of the User’s expected return from using the service or damages caused by the data obtained through the service.
16.4 The Company shall not be liable for any content, such as the information, data, reliability of the facts, accuracy, etc. posted by the User on the service.
16.5 The Company shall not be liable for damages caused by intention or negligence of the User in using the service.


17. Notice to Members and Users
17.1 In the event that the Company notifies the Member, the Company may notify via Member’s email submitted to the Company when applying for the service.
17.2 The Company may substitute individual notices by posting on the site bulletin board for more than one (1) week in case of notice to many and unspecified Users.

18. Miscellaneous
18.1 Severability
If any provision of this Agreement is contrary to, prohibited by or deemed invalid under applicable law or regulation, such provision shall be inapplicable and deemed omitted to the extent so contrary, prohibited or invalid, but the remainder of this Agreement shall not be invalidated thereby and shall be given full force and effect so far as possible.
18.2 Acknowledgement of Agreement
The User hereby acknowledges that the User has read, understood and agreed to all the terms and conditions set forth in this Agreement.
18.3 Settlement of Disputes
This Agreement shall be governed and construed in accordance with the laws of the Republic of Korea, and Seoul Central District Court has exclusive jurisdiction over all disputes, controversies or disagreements arising under, out of, or related to this Agreement.
18.4 Contact Information
For questions about the terms and conditions of this Agreement:
TmaxOS Co. Ltd.
6 ~ 9F BS Tower, Hwangsaeul-ro, Bundang-gu, Seongnam-si, Gyeonggi-do
13595, South Korea
Website: http://www.tmaxos.com

(Date of Enforcement) 
This Service Agreement shall be effective from August 15, 2019.

`}
                </PageBodyPre>
              </PageInnerContents>
            ) : (
              <PageInnerContents id="2019-08-15">
                <PageBodyPre ismobile={isMobile}>
                  {`
Tmax Cloud 계정 이용약관


제 1 장
제 1 조 (목적 및 정의)
1.1. 목적
㈜티맥스OS(이하 “회사”라 함)는 제품 TmaxOS, TmaxOS 소프트웨어센터, TmaxOS 홈페이지  
및 Tmax CloudSpace 를 통합하여 서비스(이하 “통합서비스”라 함)를 제공할 예정이며 이에   
Tmax Cloud 계정 이용약관(이하 “통합 약관”이라 함)을 마련하였습니다. 
본 약관은 통합서비스를 이용하는 데 필요한 권리, 의무 및 책임사항, 이용조건 및 절차 등 
기본적인 사항을 규정하고 있으며 회원가입 신청자는 본 약관에 동의함으로써 통합서비스를 
이용할 수 있습니다. 
단, 회사가 아닌 제 3자가 제공하는 서비스(TmaxData, TmaxSoft 등)에 가입되지는 않습니다.
1.2. 정의
Tmax Cloud 회원(이하 “회원”이라 함)은 Tmax Cloud 계정에 개인정보를 제공하여 회원등록을 한 자로서, 통합서비스의 정보를 지속적으로 제공 받으며, 회사가 제공하는 서비스를 계속적으로 이용할 수 있는 자를 말합니다.
통합서비스라 함은 아래의 제품 및 서비스를 포함합니다. 
    1) 제품 TmaxOS: 사용자에게 제공되는 운영체제를 의미하며, 시스템 하드웨어를 관리하거나,   
        응용 소프트웨어를 실행하기 위한 서비스를 제공하는 등의 시스템 소프트웨어
2) TmaxOS 소프트웨어센터: 앱스토어 이용 및 어플리케이션 업데이트를 주관하는 통합관리 
        프로그램으로, ToOffice 등의 프로그램을 다운로드 받을 수 있는 서비스
3) TmaxOS 홈페이지: 회사가 운영하는 웹사이트를 의미하며, 회사소개, 제품소개, 
        다운로드센터, 고객지원, 홍보센터 등을 사용자가 이용할 수 있도록 하는 사이트
    4) Tmax CloudSpace: 회사가 제공하는 통합서비스에 포함된 개별 서비스로서 메신저,   
        메일, 스케줄, 노트, 오피스, 파일저장소 등을 포함하는 Tmax CloudSpace 앱 서비스와 
        Tmax CloudSpace 이용을 위한 기술지원 등 관련 제반 서비스

제 2 조 (약관의 효력 및 변경)
2.1. 본 약관의 내용은 통합서비스의 화면에 게시하거나 개별 서비스 홈페이지에 공지하고, 
    본 약관에 동의한 모두에게 그 효력이 발생합니다. 
2.2. 회사는 필요한 경우 관련 법령을 위배하지 않는 범위 내에서 본 약관을 변경할 수 있습니다.
    본 약관이 변경되는 경우 회사는 변경사항을 시행 일자 15일 전부터 서비스 공지사항에서 
    공지 또는 통지하는 것을 원칙으로 하며, 회원에게 불리한 내용으로 변경될 경우에는 그 
    시행일자 30일 전부터 Tmax Cloud 계정에 등록된 이메일 주소로 전자메일을 발송하는 
    방법 등으로 개별적으로 공지하겠습니다. 
2.3. 회사가 전 항에 따라 공지 또는 통지를 하면서 공지 또는 통지일로부터 개정약관 시행일 
    7일 후까지 거부의사를 표시하지 아니하면 승인한 것으로 본다는 뜻을 명확하게 
    고지하였음에도 반대의 의사표시가 없는 경우에는 변경된 약관을 승인한 것으로 봅니다.
2.4. 변경된 약관에 대하여 거부의사를 표시함으로써 이용계약의 해지를 선택할 수 있습니다. 
2.5. 본 약관에 동의한 날로부터 본 약관 제11조에 따른 이용계약의 해지 시까지 적용하는 것을 
    원칙으로 합니다. 단, 본 약관의 일부 조항(게시물, 재가입 제한, 손해배상 조항 등)은 
    이용계약의 해지 후에도 유효하게 적용될 수 있습니다

제 3 조 (약관 외 준칙)
본 약관에 규정되지 않은 사항에 대해서는 관련 법령 또는 통합서비스를 구성하는 개별 서비스의 이용약관, 상관례에 따릅니다. 또한 본 약관과 개별 서비스 이용약관의 내용이 충돌할 경우 개별 서비스 이용약관의 효력이 우선합니다. 
    
제 2 장 통합서비스 이용계약
제 4 조 (계약의 성립)
통합서비스 이용계약은 회원들이 본 약관의 내용에 동의함으로써 체결됩니다.

제 5 조 (통합서비스 가입의 제한)
5.1. 아래 각 호의 경우에는 그 사유의 경우 가입이 제한될 수 있습니다.
    1) 만 14세 미만인 경우
    2) 다른 사람의 명의나 이메일 주소 등 개인정보를 이용하여 통합서비스에 가입하려고 한 
        경우
    3) 통합서비스 제공 설비 용량에 현실적인 여유가 없는 경우
    4) 통합서비스 제공을 위한 기술적인 부분에 문제가 있다고 판단되는 경우
    5) 기타 회사가 재정적, 기술적으로 필요하다고 인정하는 경우
    6) 회사로부터 통합서비스 이용정지 조치 등을 받은 자가 그 조치 기간에 통합서비스 
        이용계약을 임의로 해지하고 재가입을 신청하는 경우
    7) 기타 관련 법령에 위배되거나 세부지침 등 회사가 정한 기준에 반하는 경우
5.2. 만약, 회원이 위 조건에 위반하여 통합서비스에 가입한 것으로 판명된 때에는 회사는 
    즉시 통합서비스 이용을 정지시키거나 Tmax Cloud 계정을 삭제하는 등 적절한 제한을 할 수 
    있습니다.
    
제 3 장 통합서비스 이용
제 6 조 (다양한 서비스의 제공)
6.1. 통합서비스 이용계약이 성립되면 회원들은 통합서비스를 구성하는 제품 TmaxOS, 
    소프트웨어센터, TmaxOS 홈페이지 및 Tmax CloudSpace 등을 원하는 때에 자유롭게 이용할 
    수 있고, 개별 서비스에 대한 별도의 회원가입을 할 필요는 없습니다.
6.2. 통합서비스 내에서도 일부 세부 하위 서비스의 경우 별도의 이용약관에 동의해야 이용이 
    가능할 수 있으며 필요한 추가 정보를 기재하거나, 이메일 주소 승인 등 회사가 정한 인증   
    절차를 완료하여야 서비스 이용이 가능할 수 있습니다. 
6.3. 통합서비스 계정 삭제 시 관련 법령에서 정하는 바에 따라 일정기간 보관해야 하는 정보를 
    제외하고는 해당 서비스 이용기록, 게시물 등 모든 데이터는 즉시 삭제 처리됩니다. 
    다만, 회원이 작성한 게시물이 제 3자에 의하여 공유 기능 등으로 게시되거나, 
    회원이 제 3자의 게시물에 댓글 등 게시물을 추가하는 등의 경우에는 해당 게시물 및 댓글은     
    삭제되지 않으므로 반드시 이용 종료 전에 삭제하시기 바랍니다. 
6.4. 회사는 회원에게 스마트워크 및 협업 서비스, 뉴스레터 구독, 게시판 서비스, 
    온라인 콘텐츠 제공 서비스, 소프트웨어 다운로드 서비스 등 인터넷과 모바일로 즐길 수 
    있는 다양한 서비스를 제공하고 있으며 개별 서비스 이용 방법은 어플리케이션 스토어와 각 
    서비스의 고객지원, 해당 안내 및 공지사항에서 더 상세하게 안내하고 있으니 언제든지 
    확인하여 주시기 바랍니다.
6.5. 회원가입 시 회사는 회원에게 양도불가능하고 비상업적인 비독점적 라이선스를 제공합니다.  
    단, 회사의 상표 및 로고를 사용할 권리는 여전히 회사에게 있습니다.
6.6. 회사는 더 나은 통합서비스를 위하여 통합서비스에 필요한 소프트웨어의 업데이트 
    버전을 제공할 수 있습니다. 소프트웨어의 업데이트에는 중요한 기능의 추가 또는 불필요한  
    기능의 제거 등이 포함되어 있습니다. 
6.7. 회사는 더 나은 통합서비스의 제공을 위하여 회원들에게 통합서비스의 이용과 관련된 
    각종 고지, 관리 메시지 및 기타 광고를 비롯한 다양한 정보를 통합서비스 내에 표시하거나 
    Tmax Cloud 계정에 등록되어 있는 이메일 주소, 연락처 등으로 직접 발송할 수 있습니다. 
    단, 광고성 정보 전송의 경우에는 사전에 수신에 동의한 경우에만 전송합니다.
6.8. 통합서비스 이용 중 시스템 오류 등 문제점을 발견하신다면 개별 서비스의 고객 지원 
    채널을 통해 알려주시기 바랍니다.

제 7 조 (통합서비스의 변경 및 종료)	
7.1. 아래 각 호의 경우 통합서비스의 전부 또는 일부를 제한하거나 중지할 수 있습니다.
    1) 통합서비스용 설비의 유지·보수 등을 위한 정기 또는 임시 점검의 경우
    2) 정전, 제반 설비 장애 또는 이용량 폭주 등으로 정상적인 통합서비스 이용에 지장이 
        있는 경우
    3) 관계사와의 계약 종료, 정부의 명령/규제 등 회사의 제반 사정으로 통합서비스의 전부
        또는 일부를 유지할 수 없는 경우
    4) 기타 천재지변, 국가비상사태 등 불가항력적 사유가 있는 경우
7.2. 전항에 의한 통합서비스 중단의 경우에는 미리 제 17 조에서 정한 방법으로 회원에게 통지 
내지 공지하겠습니다. 다만, 회사로서도 예측할 수 없거나 통제할 수 없는 사유(회사의 과실이 없는 디스크 내지 서버 장애, 시스템 다운 등)로 인해 사전 통지 내지 
공지가 불가능한 경우에는 본 조의 적용이 없습니다. 

제 8 조 (게시물의 관리)
8.1. 회원의 게시물이 정보통신망 이용촉진 및 정보보호 등에 관한 법률(이하 “정보통신망법”
    이라 함)및 저작권법 등 관련 법령에 위반되는 내용을 포함하는 경우, 회사에 관련 법령이 
    정한 절차에 따라 해당 게시물의 게시중단 및 삭제 등을 요청할 수 있으며, 회사는 관련 
    법령에 따라 조치를 취합니다.
8.2. 회사는 회원의 요청이 없는 경우라도 권리침해가 인정될 만한 사유가 있거나 기타 
    회사의 정책 및 관련 법령에 위반되는 경우에는 관련 법령에 따라 해당 게시물에 대해 
    임시조치 등을 취할 수 있습니다.

제 9 조 (권리의 귀속 및 저작물의 이용)
9.1. 회원은 사진, 글, 정보, (동)영상, 통합서비스 또는 회사에 대한 의견이나 제안 등 콘텐츠
    (이하 “게시물”이라 함)를 통합서비스 내에 게시할 수 있으며, 이러한 게시물에 대한 
    저작권을 포함한 지적재산권은 당연히 게시자가 계속하여 보유합니다.
9.2. 회원은 음란하거나 폭력적이거나 기타 공서양속 및 법령에 위반하는 콘텐츠를 공개 또는 
    게시할 수 없습니다.
9.3. 회사는 회원의 콘텐츠가 관련 법령에 위반되거나 음란 또는 청소년에게 유해한 게시물, 
    차별 갈등을 조장하는 게시물, 도배 · 광고 · 홍보 · 스팸성 게시물, 계정을 양도 또는 
    거래하는 내용의 게시물, 타인을 사칭하는 게시물 등이라고 판단되는 경우 이를 삭제하거나 
    게시를 거부할 수 있습니다. 
9.4. 통합서비스에서는 회사가 보유하지 않은 일부 콘텐츠가 표시될 수 있습니다. 그러한 
    콘텐츠에 대해서는 콘텐츠를 제공한 주체가 단독으로 모든 책임을 부담하게 됩니다. 해당 
    통합서비스를 이용하더라도 다른 회원의 콘텐츠에 대하여 어떠한 권리를 가지게 되는 
    것은 아닙니다. 

제 10 조 (통합서비스 이용 방법 및 주의점)
10.1. 아래 각 호의 위반 행위를 하는 경우, 또는 회사의 약관 및 관계법령을 준수하지 않는 
        경우 회사는 회원의 위반행위 등을 조사할 수 있고, 해당 게시물 등을 삭제 또는 임시 
        삭제하거나 통합서비스 재가입에 제한을 둘 수 있습니다.
        1) 이용 신청 또는 변경 시 허위 사실을 기재하거나, 다른 사람의 Tmax Cloud 계정 및 
비밀번호를 도용, 부정하게 사용하거나, 다른 사람의 명의를 사용하거나 명의자의 허락 없이 이메일 인증 등을 수행하는 행위
        2) 회사의 서비스 정보를 이용하여 얻은 정보를 회사의 사전 승낙 없이 복제 또는 
        유통시키거나 상업적으로 이용하는 행위
        3) 타인의 명예를 손상시키거나 불이익을 주는 행위
        4) 게시판 등에 음란물을 게재하거나 음란사이트를 연결(링크)하는 행위
        5) 회사 또는 제 3자의 저작권 등 기타 권리를 침해하는 행위(국내외 제 3자의 저작권 등을 
        침해하는 행위로서 회사가 IP 접속 차단 등 기술적인 조치를 통하여 타인에 대한 권리   
        침해 방지 조치를 취하였음에도 불구하고 회원이 고의로 또는 회사를 기망하는 
        수단과 방법을 통하여 서비스에 접속하는 등 제3자의 저작권 등을 침해하는 행위를 
        포함합니다)
        6) 서비스 내에 회사나 제 3자 등에 대한 허위의 사실을 게시하는 행위
        7) 공공질서 및 미풍양속에 위반되는 내용의 정보, 문장, 도형, 음성 등을 타인에게 유포하는 
        행위
        8) 통합서비스와 관련된 설비의 오동작이나 정보 등의 파괴 및 혼란을 유발시키는 컴퓨터
        바이러스 감염 자료를 등록 또는 유포하는 행위
        9) 통합서비스의 운영을 고의로 방해하거나 안정적 운영을 방해할 수 있는 정보 및 
        수신자의 명시적인 수신거부의사에 반하여 광고성 정보 또는 스팸메일(Spam Mail)을 
        전송하는 행위
        10) 회사의 동의 없이 서비스 또는 이에 포함된 소프트웨어의 일부를 복사, 수정, 배포, 
판매, 양도, 대여, 담보제공하거나 타인에게 그 이용을 허락하는 행위와 소프트웨어를 
역설계하거나 소스 코드의 추출을 시도하는 등 서비스를 복제, 분해 또는 모방하거나 
기타 변형하는 행위
        11) 타인으로 가장하는 행위 및 타인과의 관계를 허위로 명시하는 행위
        12) 다른 회원의 개인정보를 수집, 저장, 공개하는 행위
        13) 자기 또는 타인에게 재산상의 이익을 주거나 타인에게 손해를 가할 목적으로 허위의 
            정보를 유통시키는 행위
        14) 재물을 걸고 도박하거나 사행행위를 하는 행위
        15) 윤락행위를 알선하거나 음행을 매개하는 내용의 정보를 유통시키는 행위
        16) 수치심이나 혐오감 또는 공포심을 일으키는 말이나 음향, 글이나 화상 또는 영상을 
            계속하여 상대방에게 도달하게 하여 상대방의 일상적 생활을 방해하는 행위
        17) 관련 법령에 의하여 그 전송 또는 게시가 금지되는 정보(컴퓨터 프로그램 포함)의 전송 
            또는 게시 행위
        18) 회사 또는 관계사의 직원이나 운영자를 가장하거나 사칭하여 또는 타인의 명의를 
            도용하여 글을 게시하거나 이메일, 메시지 등을 발송하는 행위
        19) 컴퓨터 소프트웨어, 하드웨어, 전기통신 장비의 정상적인 가동을 방해, 파괴할 목적으로 
            고안된 소프트웨어 바이러스, 기타 다른 컴퓨터 코드, 파일, 프로그램을 포함하고 있는 
            자료를 게시하거나 이메일, 메시지 등으로 발송하는 행위
        20) 스토킹(stalking), 허위 또는 악의적 신고 남용 등 다른 회원을 괴롭히는 행위
        21) 통합서비스의 이용 권한, 기타 이용계약상 지위를 타인에게 양도·증여하거나 담보 제공
            하는 행위
        22) 만 14세 미만의 회원이 회사 통합서비스 가입신청을 하는 행위
10.2. 이용 제한은 위반 활동의 누적 정도에 따라 한시적 제한에서 영구적 제한으로 단계적 제한
        하는 것을 원칙으로 하지만, 음란한 내용의 게시와 유포 및 사행성 도박 홍보 등 관련 법령
        에서 금지하는 명백한 불법행위나 타인의 권리침해로서 긴급한 위험 또는 피해 차단이 요구
        되는 사안에 대해서는 위반 활동 횟수의 누적 정도와 관계 없이 즉시 영구적으로 이용이 
        제한될 수 있습니다.

제 11 조 (이용계약 해지)
11.1. 통합계정 탈퇴를 하는 경우 통합서비스 이용계약도 자동으로 해지됩니다.
11.2. 통합계정 탈퇴는 Tmax CloudSpace나 TmaxOS홈페이지에서 가능합니다.
11.3. 통합서비스 이용계약 해지를 원하는 경우 언제든지 서비스 내 제공되는 메뉴를 이용하여 
        해지 신청을 할 수 있습니다. 
11.4. 통합서비스 이용계약이 해지되면 관련 법령 및 개인정보처리방침에 따라 일정 정보를 보유
        하는 경우를 제외하고는 회원들의 정보나 회원들이 작성한 게시물 등 모든 데이터는 
        삭제됩니다. 다만, 회원이 작성한 게시물이 제3 자에 의하여 공유 기능으로 게시되거나, 
        회원이 제3자의 게시물에 댓글 등 게시물을 추가하는 등의 경우에는 해당 게시물 및 
        댓글은 삭제되지 않으므로 반드시 해지 신청 전에 삭제하시기 바랍니다.
11.5. 전항에 따라 회원이 삭제하지 않은 게시물은 다른 회원의 정상적 서비스 이용을 위하여 
        필요한 범위 내에서 통합서비스 내에 삭제되지 않고 남아 있게 됩니다.
11.6. 통합서비스를 구성하는 모든 개별 서비스를 일정기간 동안 이용하지 않을 경우 회원들의 
        개인정보를 파기하거나 분리 보관할 수 있으며, 서비스를 이용할 수 없도록 제한할 수 
        있습니다. 자세한 사항은 Tmax Cloud 계정 개인정보처리방침에서 확인하실 수 있습니다.
11.7. 통합서비스 이용계약이 해지된 경우라도 다시 회사에 대하여 이용계약의 체결을 신청할 
        수 있습니다. 다만, 관련 법령, 본 약관 및 개별 서비스 약관을 준수하지 않아 서비스의 
        이용이 중단된 상태에서 이용계약을 해지한 후 다시 이용계약 체결을 신청하는 경우에는 재
        가입이 제한될 수 있습니다. 

제 12 조 휴면계정
12.1. 회원이 통합서비스를 1년 동안 이용하지 않을 경우 휴면계정으로 분리됩니다. 4개의 하부 서비스 중 Tmax CloudSpace, TmaxOS홈페이지 및 소프트웨어센터를 1년 이내에 한 번이라도 이용한 경우 본 조항은 적용되지 않습니다.
12.2. 휴면계정으로 분리하기 30일 전 회원이 회원가입시 기재한 이메일 주소로 전자우편을 보내
사전 공지를 할 예정입니다. 사전공지 이후 30일 동안 별다른 이의신청이 없으면 휴면계정으로 분리됩니다.
12.3. 휴면계정으로 분리되면 별도의 휴면해제절차를 거쳐야 서비스 사용이 가능합니다.
12.4. 수집된 회원들의 개인정보가 분리 보관됩니다.
12.5. 휴면계정으로 분리되면 Tmax CloudSpace의 경우 해당 회원의 데이터가 삭제될 수 
있습니다. 이로 인한 회원들의 손해에 대하여 회사는 어떠한 책임도 지지 않습니다.

제 13 조 (개인정보의 보호)
회원들의 개인정보는 통합서비스의 원활한 제공을 위하여 회원들이 동의한 목적과 범위 내에서만 이용됩니다. 관련 법령에 의하지 아니하는 한 회사가 개인정보를 제 3자에게 제공하는 경우 회원들의 별도의 동의를 받아야 합니다. 
기타 자세한 사항은 Tmax Cloud 계정 개인정보처리방침 등을 참고해 주시기 바랍니다. 
    
제 4 장 기타
제 14 조 (손해배상 등)
14.1. 회사는 관련 법령상 허용되는 한도 내에서 통합서비스와 관련하여 본 약관에 명시되지 
        않은 어떠한 구체적인 사항에 대한 약정이나 보증을 하지 않습니다. 또한, 회사는 콘텐츠 
        제공자가 공급하거나 회원이 직접 작성하는 등의 방법으로 통합서비스에 게재된 정보, 
        자료, 사실의 신뢰도, 정확성 등에 대해서는 보증을 하지 않으며, 회사의 과실 없이 발생된 
        손해에 대하여는 책임을 지지 않습니다.
14.2. 회사는 아래와 같은 손해에 대해서는 책임을 부담하지 않습니다. 
        1) 천재지변 또는 이에 준하는 불가항력의 상태에서 발생한 손해
        2) 회원의 귀책사유로 통합서비스 이용에 장애가 발생한 경우
        3) 통합서비스에 접속 또는 이용과정에서 발생하는 개인적인 손해
        4) 제 3자가 불법적으로 회사의 서버에 접속하거나 서버를 이용함으로써 발생하는 손해
        5) 제 3자가 회사 서버에 대한 전송 또는 회사 서버로부터의 전송을 방해함으로써 
        발생하는 손해
        6) 제 3자가 악성 프로그램을 전송 또는 유포함으로써 발생하는 손해
        7) 전송된 데이터의 생략, 누락, 파괴 등으로 발생한 손해, 명예훼손 등 제 3자가 서비스를 
        이용하는 과정에서 발생된 손해
        8) 서비스의 다운로드 및 이용 과정에서 발생하는 개인의 데이터 손실 또는 시스템 오작동 
        등의 손해
        9) 기타 회사의 고의 또는 과실이 없는 사유로 인해 발생한 손해
14.3. 회사는 간접 손해, 특별 손해, 결과적 손해, 징계적 손해, 및 징벌적 손해에 대한 책임을 
        부담하지 않습니다.
14.4. 회사는 회사의 과실이 없는 한 회원 상호간 또는 회원과 제 3자 상호간에 
        통합서비스를 매개로 발생한 분쟁에 대해서는 개입할 의무가 없으며 이로 인한 손해를 
        배상할 책임도 없습니다.

제 15 조 (분리가능)
본 약관의 일부 조항이 관할 지역의 관련 법률에 위하여 무효로 해석되거나 법률에 위반하게 되는 경우 해당 조항은 관련 법률에 따라 합법적인 범위 내로 해석되거나 무효가 될 수 있습니다. 그러한 경우라도 해당 조항의 무효 등은 본 약관의 유효성 및 다른 조항의 내용에 영향을 미치지 않습니다.

제 16 조 (약관의 인정)
회원은 본 약관에 명시된 내용을 모두 읽고 이해한 후 본 약관의 조항에 동의했다는 점을 확인합니다.

제 17 조(통지 및 공지)
회사는 회원을 포함한 회원에게 중대한 영향을 미치는 사항의 경우에는 통합 계정에 등록된 이메일 주소로 메일을(이메일 주소가 없는 경우 서비스 내 메시지 발송, 서비스 내 알림 메시지를 띄우는 등의 별도의 전자적 수단) 발송하는 방법 등으로 개별 고지하겠습니다.

제 18 조 (분쟁의 해결)
본 약관 또는 통합서비스는 대한민국법령에 의하여 규정되고 이행됩니다. 통합서비스 이용과 관련하여 회사와 회원들간에 분쟁이 발생하면 이의 해결을 위해 성실히 협의할 것입니다. 그럼에도 불구하고 해결되지 않으면 민사소송법의 관할법원에 소를 제기할 수 있습니다.

제 19 조 (본 약관에 대한 문의사항)
본 약관에 대한 문의사항은 아래 연락처를 참고하시기 바랍니다. 개별서비스 약관에 대한 문의사항은 해당 약관에 명시되어 있습니다.
㈜티맥스OS
경기도 성남시 분당구 황새울로 BS 타워 6 ~ 9층
홈페이지 : http://www.tmaxos.com

공고일자 : 2019년 8월 15일
시행일자 : 2019년 8월 15일

제품 TmaxOS 사용약관

제 1 조 목적 및 적용범위
1.1.	목적
제품 TmaxOS 약관은 ㈜티맥스OS와 협력사(이하 통칭하여 “회사”라 함)가 개발, 판매, 배포한 소프트웨어 제품에 대해 개인 또는 단일 업체와 회사 간에 체결하는 법적 구속력이 있는 계약입니다.
1.2. 적용범위
    1) 본 약관을 통해 제공되는 소프트웨어는 TmaxOS 홈페이지(www.tmaxos.com)에서 제공하는   
        모든 버전의 TmaxOS(이하 “제품”이라 함)를 의미합니다.
    2) 본 회사의 제품은 컴퓨터 소프트웨어뿐만 아니라, 제품에 부속된 자료, 매체, 파일, 
        데이터, 인쇄물 및 온라인 또는 전자문서 등이 포함될 수 있습니다.

제 2 조 정의
2.1 이용자라 함은 이 약관에 따라 제품이 제공하는 서비스를 받는 회원 및 비회원을 말합니다.
2.2. 회원이라 함은 Tmax Cloud 계정에 개인정보를 제공하여 회원등록을 한 자로서, 
제품의 정보를 지속적으로 제공 받으며, 회사가 제공하는 서비스를 계속적으로 이용할 수 있는 자를 말합니다.
2.3. 비회원이라 함은 회원에 가입하지 않고 제품이 제공하는 서비스를 이용하는 자를 
    말합니다.
2.4. 협력사라 함은 ㈜티맥스OS와 제휴 계약을 맺은 사업체를 말합니다.

제 3 조 약관의 효력
3.1. 본 약관의 내용은 통합서비스 화면에 게시하거나 개별 서비스 홈페이지에 공지하고, 
    본 약관에 동의한 모두에게 효력이 있습니다. 
3.2 본 약관에 규정되지 않은 사항에 대해서는 통합 약관, 관련 법령 및 상관례의 순서로 
    적용이 되며, 본 약관과 통합 약관이 충돌할 경우 본 약관의 효력이 우선합니다. 
    비회원의 경우 본 약관 이외에 관련 법령 및 상관례가 적용됩니다.

제 4 조 약관의 변경
4.1. 회사는 필요한 경우 관련 법령을 위배하지 않는 범위 내에서 본 약관을 변경할 수 있습니다.
    본 약관이 변경되는 경우 회사는 이용자를 위하여 변경사항을 시행 일자 15일 전부터 서비스 
    공지사항에서 공지 또는 통지하는 것을 원칙으로 하며, 회원에게 불리한 내용으로 변경될 
    경우에는 그 시행일자 30일 전부터 Tmax Cloud 계정에 등록된 이메일 주소로 전자메일을 
    발송하는 방법 등으로 개별적으로 공지하겠습니다. 비회원은 전자메일 정보가 별도로 
    저장되어 있지 않으므로 홈페이지 공지로 개별공지를 갈음하겠습니다.
4.2. 회사가 전 항에 따라 공지 또는 통지를 하면서 공지 또는 통지일로부터 개정약관 시행일 
    7일 후까지 거부의사를 표시하지 아니하면 승인한 것으로 본다는 뜻을 명확하게 
    고지하였음에도 반대의 의사표시가 없는 경우에는 변경된 약관을 승인한 것으로 봅니다.
4.3. 변경된 약관에 대하여 거부의사를 표시함으로써 이용계약의 해지를 선택할 수 있습니다. 
4.4. 본 약관에 동의한 날로부터 본 약관 제 7 조에 따른 이용계약의 해지 시까지 적용하는 
것을 원칙으로 합니다. 단, 본 약관의 일부 조항(개인정보 조항, 손해배상 조항 등)은 
이용계약의 해지 후에도 유효하게 적용될 수 있습니다.

제 5 조 회원가입 및 이용
5.1. 회원가입
    1) 제품 사용 계약은 회원이 되고자 하는 자가 회사가 제공하는 회원가입 방법에 따라   
        해당 절차를 완료함으로써 체결됩니다. 비회원의 경우 약관에 동의함으로써 제품 사용 
        계약 체결이 완료됩니다. 
        단, 아동의 보호를 위하여 만14세 미만의 회원가입이 제한됩니다.
    2) 소프트웨어센터를 이용하고자 하는 경우 일정 개인정보를 제공해야 합니다. 
비회원의 경우 별도의 회원가입 후 소프트웨어센터를 이용하실 수 있습니다.
5.2. 사용방법
    1) 이용자는 본 제품의 전부 또는 일부를 컴퓨터 또는 네트워크 상에서 설치, 복사하거나 
        실행함으로써 본 약관에 동의하고 사용하는 것으로 간주됩니다.
    2) 이용자는 본 약관 내용에 동의하지 않을 경우에는 제품을 사용할 수 없습니다.
3) 개인 사용자용 버전은 회사 정책에 따라 일정기간 무료로 사용할 수 있으나, 향후 별도로
    유료화를 명시하는 경우 해당 정보에 명시된 요금을 지불하여야 사용이 가능합니다.
    4) 아래에 명시된 대로 이 제품을 사용함으로써 귀하는 정품 인증 및 유효성 검사 단계에서 
        인터넷 기반 서비스를 위한 특정 표준 컴퓨터 정보의 전송에 동의하게 됩니다.
5.3. 사용 허가
    1) 본 약관에 동의하는 경우 본 제품을 정당하게 구매하거나 회사가 인정하는 경로를 
        통하여 무상 배포 버전을 다운로드한 이용자에게 기존에 합의 혹은 제품에 포함된 
        인증서에 명시된 사용범위 및 사용기간에 한하여 사용할 권리를 허용합니다.
    2) 본 약관은 회사가 배포하는 제품의 전체 버전에 공통적으로 적용됩니다. 
        각 제품의 Edition 및 버전 따라 별도의 라이선스 계약서가 있는 경우 해당 Edition 및 
        버전의 라이선스 계약서는 본 약관에 우선하여 적용되며, 규정되지 않은 내용은 
        본 약관이 적용됩니다.
    3) 제품의 사용범위에는 로컬 컴퓨터 및 중앙의 서버를 통해 사용자 별로 독립된 데스크탑 
        환경을 제공하는 데스크탑 가상화 환경(이하 “가상화 환경”이라 함)의 단말기가 모두 
        포함되며, 로컬 컴퓨터와 가상화 환경의 단말기를 포함한 총 사용 수량이 구매한 
        라이선스 수량을 초과할 수 없습니다.
    4) 이용자는 제품을 정당하게 사용하기 위하여 본 제품을 다운로드 하거나 복사할 수 있으며,백업이나 보관용으로 본 제품의 복사본을 만들 수 있습니다. 단, 네트워크 서버에 제품을 설치하고 스트리밍을 통해 다수의 컴퓨터에서 사용하는 경우 및 한 대의 PC를 다수의 
독립적인 사용자가 동시에 사용하는 경우는 원칙적으로 사용권이 허용되지 않습니다.
    5) 이용자는 본 약관의 준수에 대해 회사의 감사를 받을 수 있음에 동의합니다. 회사는 
        합리적인 기간 전에 사전 통지함으로써 통상적인 업무 시간 중에 고객의 본 계약 
        준수여부를 파악하기 위한 감사를 실시할 수 있습니다. 
        이용자는 회사 또는 그 수권대리인이 이용자의 시설, 작업장 및 서버에 접근할 수 있도록 
        하고, 회사의 조사에 최대한 협력하며, 회사가 이용자의 본 계약 준수여부를 정확하게 
        파악하는 것을 돕기 위하여 상업적으로 합리적인 모든 조치를 취하여야 합니다. 
        회사 및 그 수권대리인들은 이용자의 작업장내에서 고객의 합리적인 보안규정을 
        준수하여야 합니다.
    6) 감사는 제품에 대한 유효성 검사로 진행되며, 제품의 무단사용 방지를 위하여 
        의무적으로 진행해야 합니다.
        a. 유효성 검사를 통하여 아래와 같은 내용을 확인합니다.
            ① 정품 인증 여부
            ② 적법한 사용권 허가 여부,  
            ③ 제품의 유효성 검사, 사용권 또는 정품 인증 기능이 무단으로 변경되었는지 여부
            ④ 무단 변경 관련한 일부 악성 또는 무단 소프트웨어의 존부 
        b. 적법하게 사용권을 받았다는 사실이 유효성 검사에서 확인되면 귀하는 계속해서 제품 
            또는 제품의 특정 기능을 사용하거나 추가 혜택을 받을 수 있습니다. 
        c. 유효성 검사 결과 제품이 불법 복제본이거나 적법하게 사용권이 허가되지 않았거나 
            정품이 아닌 제품으로 나타나거나 또는 무단 변경 내용을 포함하는 것으로 나타날 경우   
            제품의 기능과 사용 환경에 영향을 미칠 수 있습니다. 
            아래 각 호의 예시를 참고하십시오.
            ① 회사는 제품을 수리하여 정품 인증 또는 유효성 검사 기능에 대한 회피를 
            포함하여 정상적인 사용을 방해하는 무단 변경 내용을 제거, 격리 또는 불능화할 
            수 있음
            ② 무단 변경 내용과 관련된 것으로 알려진 악성 또는 무단 소프트웨어를 확인하여 
            제거할 수 있음
            ③ 제품이 적법하게 사용권이 허가되지 않았거나 정품이 아닌 제품이라는 통지를 
            할 수 있음
            ④ 적법하게 사용권이 허가된 제품의 복사본을 취득하라는 메시지를 전달할 수 있음
            ⑤ 회사 지침에 따라 제품 사용권을 허가 받고 다시 정품 인증을 진행해야 할 수 
            있음
            ⑥ 제품 또는 제품의 일부 기능을 계속해서 사용할 수 없거나 회사로부터 특정 
            업데이트 또는 업그레이드를 받지 못할 수 있음
    7) 설치 및 사용 권한
        a. 컴퓨터 한 대에 제품 복사본 하나를 설치할 수 있습니다. 해당 컴퓨터가 사용권이 
            허가된 컴퓨터가 됩니다.
        b. 이 제품 사용 시 사용권이 허가된 컴퓨터에서 프로세서를 동시에 두 개까지 사용할 
            수 있습니다. 본 사용권 계약에 달리 명시되지 않는 한 다른 컴퓨터에서 제품을 사용
            할 수 없습니다.
        c. 본 사용권 계약에 달리 명시되지 않는 한, 한 번에 한 명의 사용자만 제품을 사용할 
            수 있습니다.
        d. 32비트 및 64비트와 같이 둘 이상의 버전을 포함하고 있는 제품도 있습니다. 한 번에 
            한 버전만 설치하여 사용할 수 있습니다.
    8) 추가 사용권 요구 사항 및 사용 권한
        a. 제품을 실행하는 동안 제품 글꼴을 사용하여 콘텐츠를 표시 및 인쇄할 수 있습니다. 
            다음과 같은 작업에 한해 수행할 수 있습니다.
            ① 글꼴의 포함 제한에 따라 허용되는 범위 내에서 콘텐츠에 글꼴 포함
            ② 콘텐츠를 인쇄하기 위해 프린터 또는 기타 출력 장치에 글꼴 임시 다운로드
        b. 제품을 실행하는 동안 제품의 아이콘, 이미지, 사운드 및 미디어를 사용할 수 있으나 
            공유할 수는 없습니다. 
            ① 이 제품과 함께 제공된 샘플 이미지, 사운드 및 미디어의 상업적 사용은 금지되어 
            있습니다.
        c. 의무적 정품 인증
            ① 정품 인증이란 제품을 특정 컴퓨터에서 사용하도록 연관시키는 것입니다. 
            ② 정품 인증이 진행되는 동안 제품에서 회사에 제품 및 컴퓨터에 대한 정보를 
            전송합니다. 
            ③ 이러한 정보에는 제품의 버전, 언어 및 제품 키, 컴퓨터의 인터넷 프로토콜 주소, 
            컴퓨터의 하드웨어 구성 정보 등이 포함됩니다. 
            ④ 제품을 사용함과 동시에 귀하는 이러한 정보의 전송에 동의하게 됩니다. 
            ⑤ 적법하게 사용권을 허가 받은 경우, 설치 과정 중에 설치된 제품 버전을 정품 인증 
            허용 기간 내에 사용할 수 있는 권한을 갖게 됩니다. 
            ⑥ 정품 인증은 무단 사용을 방지하기 위하여 필수사항이며 제품을 정품 인증하지 
            않으면 정품 인증 허용 기간 이후에는 제품을 사용할 수 없습니다. 
5.4. 회사의 권리 및 침해 금지
    1) 제품의 모든 정품 및 복사본에는 저작권 정보가 표시되어야 합니다.
    2) 제품과 제품 내에 포함된 모든 부속물, 부속 인쇄물 및 제품의 복사본들에 대한 
        저작권, 특허권, 상표권 등 지적재산권을 포함한 모든 권리는 회사에게 있습니다.
    3) 이용자는 제품의 사용과 관련하여 대한민국 저작권법, 국제 저작권 조약 및 기타 관련 
        법률을 준수해야 하며, 관련 법규에서 명시적으로 허용한 제한적인 범위 이외에 제품을 
        리버스엔지니어링, 디컴파일, 디스어셈블할 수 없으며, 제품의 구성 요소 전체 혹은 
        일부를 복사하거나 변형, 개작할 수 없고, 회사의 사전 승인 없이 임의로 제품 및 관련 
        인쇄물을 복제 혹은 복사하여 배포할 수 없습니다.
    4) 이용자가 전항의 행위로 회사의 권리를 침해하여 손해가 발생한 경우에 회사는 
        이용자에게 그로 인한 모든 손해에 대한 배상을 청구할 수 있습니다.
5.5. 사용권의 범위
    1) 본 약관은 사용권이 허가된 제품 버전에 포함된 기능을 사용할 수 있는 권한을 
        이용자에게 부여합니다. 기타 모든 권한은 회사가 보유합니다. 본 계약에서 명시적으로 
        허용되는 조건에 한해서만 제품을 사용할 수 있습니다. 
    2) 제품을 특정 방식으로만 사용할 수 있도록 하는 경우 모든 기술적 제한 사항을 준수해야 
        합니다. 다음과 같은 행위는 허용되지 않습니다.
        a. 제품의 기술적 제한 사항을 위반하는 행위
        b. 제품의 기술적 제한 사항에 따라 제품에 포함된 기술적 보호조치 및 보안시스템, 
            사용제한기능 등을 무력화시키거나 우회하는 일체의 행위나 시도
        c. 관련 법률에서 명시적으로 허용하는 경우 제외한 제품의 리버스 엔지니어링, 
            디컴파일 또는 디스어셈블 작업을 수행하는 행위
        d. 제품에서 실행되지 않는 응용 프로그램을 실행하기 위해 제품의 구성 요소를 
            사용하는 행위
        e. 본 계약서에 명시된 것보다 또는 관련 법률에서 허용하는 것보다 더 많은 수의 
            제품의 복사본을 만드는 행위
        f. 다른 사람이 복사할 수 있도록 제품을 게시하는 행위
        g. 제품을 임대, 대여 또는 대부하는 행위
        h. 상업용 소프트웨어 호스팅 서비스에 제품을 사용하는 행위
5.6. 보증의 범위
    1) 이용자가 제품을 CD로 구매한 경우 회사는 이 제품을 구입한 날로부터 30일간 CD를 
        비롯한 부속물에 대해 물리적인 결함이 없음을 보증합니다. 이 기간 중 제품 제작상의 
        실수로 결함이 발생한 경우에는 무료로 교환해 드립니다.
    2) 이용자가 교환을 원할 경우에는 구입 일로부터 30일이 지나지 않았다는 것을 증명하여야 
        하며, 이용자의 부주의나 실수, 취급 소홀에 의한 손상일 경우에는 교환해 드리지 
        않습니다.
5.7. 자동 업데이트
    1) 본 제품에는 업데이트를 위한 정상 작동의 일부로서 인터넷을 통해 통신을 실행 하는 
        기능이 내장되어 있습니다. 자동 업데이트에서는 필요에 따라 임의의 파일이 회원의 
        컴퓨터에 설치되며, 이 방식은 임의로 변경 될 수 있습니다. 
    2) 설치 전 이용자의 동의를 별도로 구하지 않으며 이 계약에 동의함으로써 갈음합니다.

제 6 조 개인정보의 보호 
6.1. 회사는 제품의 품질 개선을 위하여 이용자의 컴퓨터 운영 체제 정보, 제품의 버전, 
    에러 정보와 같이 개인을 식별할 수 없는 비개인 정보를 수집합니다.
6.2. 제품에 포함된 광고의 통계 수집을 위해 광고 노출 수와 광고 클릭 수를 수집합니다.
6.3. 수집한 데이터는 한시적 통계 자료로 활용되며 영구하게 회사에서 저장하지 않습니다.
6.4. 회사는 회원들이 회원가입 시 제공한 개인정보에 대하여 최소한도로 수집하고 적시한 목적 이외에는 사용하지 않습니다.
6.5. 회사의 개인정보 취급방침은 TmaxOS 홈페이지를 참고하시기 바랍니다.   
    (http://www.tmaxos.com)

제 7 조 계약서의 종료 및 해지
7.1. 이용자가 본 약관의 내용을 준수하지 않는 경우, 회사는 계약을 종료할 수 있습니다. 
7.2. 이용자가 개정된 약관에 동의하지 않을 경우, 제품을 삭제함으로써 본 이용계약을 해지할 수 
    있습니다.
7.3. 회원은 Tmax CloudSpace 및 TmaxOS 홈페이지에서 탈퇴가 가능합니다.

제 8 조 휴면계정
8.1. 회원이 통합서비스를 1년 동안 이용하지 않을 경우 휴면계정으로 분리됩니다. 4개의 하부 
서비스 중 Tmax CloudSpace, TmaxOS 홈페이지 및 소프트웨어센터를 1년 이내에 
한 번이라도 이용한 경우 본 조항은 적용되지 않습니다.
8.2. 휴면계정으로 분리하기 30일 전 회원이 회원가입시 기재한 이메일 주소로 전자우편을 보내
사전 공지를 할 예정입니다. 사전공지 이후 30일 동안 별다른 이의신청이 없으면 휴면계정으로 분리됩니다.
8.3. 휴면계정으로 분리되면 별도의 휴면해제절차를 거쳐야 서비스 사용이 가능합니다.
8.4. 수집된 회원들의 개인정보가 분리 보관됩니다.
8.5. 휴면계정으로 분리되면 Tmax CloudSpace의 경우 해당 회원의 데이터가 삭제될 수 있습니다. 이로 인한 회원들의 손해에 대하여 회사는 어떠한 책임도 지지 않습니다.

제 9 조 손해배상
9.1. 제품의 사용에 대한 결과는 전적으로 이용자의 책임하에 있으며, 회사는 제품에 대한 
    어떠한 명시적 보증도 하지 않습니다. 
9.2. 회사는 본 제품을 사용할 수 없거나 사용법을 제대로 인식하지 못해서 발생한 직접 또는 
    간접 손해에 대하여 어떠한 책임도 지지 않습니다.
9.3. 회사는 상업성, 특정 사용 목적에 대한 적합성, 비침해성과 관련된 명시적 또는 묵시적 
    보증을 배제합니다.
9.4. 회사는 아래와 같은 손해에 대해서는 책임을 부담하지 않습니다. 
        1) 천재지변 또는 이에 준하는 불가항력의 상태에서 발생한 손해
        2) 회원의 귀책사유로 제품 사용에 장애가 발생한 경우
        3) 제품 사용 관련하여 접속 또는 이용과정에서 발생하는 개인적인 손해
        4) 제 3자가 불법적으로 회사의 서버에 접속하거나 서버를 이용함으로써 발생하는 손해
        5) 제 3자가 회사 서버에 대한 전송 또는 회사 서버로부터의 전송을 방해함으로써 
        발생하는 손해
        6) 제 3자가 악성 프로그램을 전송 또는 유포함으로써 발생하는 손해
        7) 전송된 데이터의 생략, 누락, 파괴 등으로 발생한 손해, 명예훼손 등 제 3자가 서비스를 
        이용하는 과정에서 발생된 손해
        8) 서비스의 다운로드 및 이용 과정에서 발생하는 개인의 데이터 손실 또는 시스템 오작동 
        등의 손해
        9) 기타 회사의 고의 또는 과실이 없는 사유로 인해 발생한 손해
9.5. 회사는 간접 손해, 특별 손해, 결과적 손해, 징계적 손해, 및 징벌적 손해에 대한 책임을 
        부담하지 않습니다.
9.6. 회사는 회사의 과실이 없는 한 회원 상호간 또는 회원과 제 3자 상호간에 
        제품을 매개로 발생한 분쟁에 대해서는 개입할 의무가 없으며 이로 인한 손해를 
        배상할 책임도 없습니다.

제 10 조 회사의 통지
회사가 회원에게 고지할 내용이 있는 경우 회원가입 시 기재한 이메일 주소로 통지를 합니다. 비회원의 경우 TmaxOS홈페이지에 공지하는 것으로 개별공지를 갈음합니다.

제 11 조 기타
11.1. 분리가능
본 약관의 일부 조항이 관할 지역의 관련 법률에 의하여 무효로 해석되거나 법률에 위반하게 되는 경우 해당 조항은 관련 법률에 따라 합법적인 범위 내로 해석되거나 무효가 될 수 있습니다. 해당 조항의 무효 등은 본 약관의 유효성 및 다른 조항의 내용에 영향을 미치지 않습니다.
11.2. 계약서의 인정
이용자는 본 약관에 명시된 모든 내용을 읽고 이해하고 동의한 것임을 인정합니다.
11.3. 분쟁의 해결
본 약관은 대한민국의 법률에 따라 규율되며, 이 제품의 사용권에 관련된 소송 등 모든 법적인 문제에 대해서는 대한민국 서울중앙지방법원에 전속관할권이 있습니다.
11.4. 본 약관에 대한 문의 사항
본 약관에 대한 의문 사항은 아래 연락처를 참고하시기 바랍니다.
㈜티맥스OS
경기도 성남시 분당구 황새울로 BS 타워 6 ~ 9층
홈페이지: http://www.tmaxos.com

공고일자 : 2019년 8월 15일
시행일자 : 2019년 8월 15일

[소프트웨어센터 이용약관]

제 1 조 목적

TmaxOS 소프트웨어센터(이하 “소프트웨어센터”라 함)는 경기도 성남시 분당구 황새울로 BS 타워에 소재한 ㈜티맥스OS(이하 “회사”라 함)가 제공하는 서비스입니다. 이 약관은 회사와 서비스 이용자의 권리, 의무 및 책임사항 규정을 목적으로 합니다. 


제 2 조 정의

이 약관에서 사용하는 용어의 정의는 다음과 같습니다.
    
1) 소프트웨어센터: 앱스토어 이용 및 어플리케이션 업데이트를 주관하는 통합관리 프로그램으로, ToOffice 등의 프로그램을 다운로드 받을 수 있는 서비스
2) 회원: 제품 TmaxOS를 설치하여 사용하는 고객 및 Tmax Cloud 계정을 생성한 사용자
3) 콘텐츠: 제품 TmaxOS 및 소프트웨어 센터에서 사용자에게 제공하는 각종 정보 및 서비스를 통칭
4) 제공자: 제3의 콘텐츠 제공자 의미


제 3 조 약관 효력 및 변경

3.1. 약관 효력

1) 소프트웨어센터 및 소프트웨어센터에서 제공되는 앱 등의 디지털 콘텐츠나 서비스(이하 “콘텐츠”라 함) 사용에는 본 소프트웨어센터 서비스 이용약관, 제품 TmaxOS 약관 및 Tmax Cloud 약관(이하 “통합 약관”이라 함) (세 가지 약관을 통칭하여 “약관”이라 함)이 적용됩니다. 소프트웨어센터는 제품 TmaxOS 약관에 정의된 서비스에 해당됩니다. 내용이 상충될 경우 통합 약관, 소프트웨어 서비스 이용약관, 제품 TmaxOS 약관의 순서로 효력이 있습니다.
2) 앱 사용에는 회원과 제공자 간에 체결한 최종 사용자 라이선스 계약의 추가 이용약관이 적용될 수 있습니다.

3.2. 본 약관의 변경

1) 약관이 변경되는 경우 회사는 최소 15일 전에 회원에게 공지하며, 회원이 이 기간이 지난 후에도 소프트웨어센터를 계속 사용하면 새 약관에 동의한 것으로 간주합니다. 새 약관은 회원의 모든 콘텐츠(과거에 설치하거나 구매한 콘텐츠 포함) 사용과 향후의 모든 설치 및 구매에 적용됩니다.  
2) 변경 사항에 동의하지 않는 경우에는 이전에 구매하거나 설치한 콘텐츠를 다운로드하고 소프트웨어센터 사용을 해지할 수 있습니다. 회원이 마지막으로 수락한 약관에 따라 회원의 기기에서 이전에 다운로드한 콘텐츠를 계속 볼 수 있습니다.


제 4 조 회원 가입 및 서비스 이용

4.1. 회원 가입

1) 소프트웨어센터 이용계약은 회원이 되고자 하는 자가 회사가 제공하는 회원가입 방법에 따라 해당 절차를 완료함으로써 체결됩니다. 단 아동의 보호를 위하여 만 14세 미만의 회원가입이 제한됩니다.
2) 회원이 소프트웨어센터를 이용하고자 하는 경우 일정 개인정보를 제공해야 합니다.

4.2. 서비스 이용

1) 콘텐츠 사용

a. 회원은 소프트웨어센터에 접속하여 컴퓨터 등의 지원되는 기기(이하 “기기”라 함)에서 사용할 콘텐츠를 탐색하고 콘텐츠별로 다운로드할 수 있습니다. 콘텐츠에는 회사가 제공하는 콘텐츠와 회사와 관련이 없는 제 3자가 제공하는 콘텐츠가 있습니다. 
b. 소프트웨어센터에 접속하려면 관련 콘텐츠의 시스템 및 호환성 요건을 충족하는 기기, 인터넷 연결, 호환되는 소프트웨어가 필요합니다. 

2) 사용료

a. 소프트웨어 센터의 콘텐츠는 유료 표시가 없는 경우 무료로 사용 가능합니다. 다만 향후 유료화 정책에 따라 사전 고지후, 일부콘텐츠가 유료 서비스로 전환될 수 있습니다.
b. 콘텐츠 및 소프트웨어센터를 사용하거나 탐색하는 데 있어 제 3자(인터넷 공급업체 또는 기타 콘텐츠 제공 업체)의 접근 또는 데이터 수수료와 관련된 책임은 모두 회원에게 있습니다.

3) 업데이트

a. 본 약관에 동의하고 소프트웨어센터를 사용하면 업데이트 자동 수신에 동의하는 것으로 간주됩니다. 버그 수정, 향상된 기능, 누락된 플러그인 및 새 버전(이하 통칭하여 “업데이트”라 함) 등을 제공받으려면 소프트웨어센터 내, 관련 지원 라이브러리 또는 콘텐츠를 업데이트해야 합니다.
b. 특정 콘텐츠 관련 업데이트는 소프트웨어센터의 설정을 통해 관리할 수 있습니다. 
단, 업데이트가 콘텐츠와 관련된 심각한 보안 취약점을 해결할 수 있다고 판단되는 경우, 소프트웨어센터 또는 기기의 업데이트 설정과 관계없이 업데이트가 실행될 수 있습니다. 

4) 개인정보 보호

회원의 개인정보는 통합서비스의 원활한 제공을 위하여 회원이 동의한 목적과 범위 내에서만 이용됩니다. 관련 법령에 의하지 아니하는 한 회사가 개인정보를 제 3자에게 제공하는 경우 회원들의 별도 동의를 받아야 합니다. 
기타 자세한 사항은 Tmax Cloud 계정 개인정보처리방침 등을 참고해 주시기 바랍니다. 
        
5) 멀웨어 차단

a. 악의적인 제 3자 소프트웨어 및 기타 보안 문제로부터 회원을 보호하기 위해 회사는 회원의 기기에서 네트워크 연결, 잠재적으로 유해한 URL, 운영체제와 소프트웨어센터 또는 다른 출처를 통해 설치한 앱에 관한 정보를 수신할 수도 있습니다. 
b. 회사는 앱 또는 URL이 안전하지 않다고 판단하는 경우 경고 메시지를 표시할 수 있으며, 기기, 데이터, 사용자에게 유해한 것으로 알려진 앱은 기기에서 삭제하거나 설치를 차단할 수 있습니다.


제 5 조 권리 및 제한

5.1. 콘텐츠 사용 라이선스

회원은 본 약관에서 명시적으로 허용하는 방식으로만 해당 콘텐츠 사본을 저장하고 사용할 수 있으며, 개인적, 비상업적 목적에 한해 이용할 수 있는 비독점적 사용 권한을 갖게 됩니다. 약관에서 회원에게 명시적으로 부여하지 않은 소프트웨어센터 및 콘텐츠에 관한 모든 권리, 권한 및 이익은 회사 또는 제공자의 소유입니다. 

5.2. 라이선스 약관의 위반

회원이 아래 각 호의 사유 발생 및 약관 위반의 경우 본 라이선스에 따른 회원의 사용은 제한되며, 지속될 경우 권리를 상실하고, 회사는 소프트웨어센터, 콘텐츠 및 Tmax Cloud 통합 계정에 대한 사용을 종료할 수 있습니다. 일부 위반의 경우 위반 즉시 권리를 상실할 수 있습니다. 
사용 제한 시 회원은 Tmax Cloud 계정 세부정보 또는 계정을 사용하여 저장한 파일이나 기타 콘텐츠에 액세스하지 못할 수도 있습니다. 

1) 콘텐츠의 전체 또는 일부를 공연 또는 전시의 일환으로 표시해서는 안 됩니다. 
2) 소프트웨어센터를 통해 취득한 콘텐츠의 다운로드를 포함하여 콘텐츠를 제 3자에게 판매, 임대, 대여, 재배포, 방송, 전송, 전달, 수정, 라이선스 재판매, 이전 또는 양도할 수 없습니다. 
3) 콘텐츠를 공유, 대여, 다자 사용 또는 기타 기관의 목적을 위한 서비스의 일부로 사용할 수 없습니다. 
4) 콘텐츠 또는 소프트웨어센터에 대한 액세스 권한을 보호, 암호화 또는 제한하는 보안 기능이나 구성요소를 우회, 사용 중지 또는 파기하도록 시도하거나 다른 사용자에게 그러한 행위를 하도록 지원, 승인 또는 권장해서는 안 됩니다.
5) 콘텐츠에 포함된 워터마크, 라벨, 기타 법적 또는 독점 권리 고지를 삭제하거나, 콘텐츠의 소유권 또는 출처 표시를 위장하거나 변경할 목적의 수정을 포함하여 소프트웨어센터를 통해 얻은 콘텐츠를 수정하려 해서는 안 됩니다.
6) 사용자 이름이 Tmax Cloud 계정을 여러 개 갖고 있으면, 한 계정의 콘텐츠를 다른 계정으로 이전하는 것은 회원이 각 계정들의 소유자이고, 회사가 콘텐츠 이전을 가능하게 하는 관련 서비스의 기능을 활성화해 놓은 경우에 가능하며, 그러한 명시가 없는 경우는 불가합니다.
7) 어떠한 서비스 또는 콘텐츠도 핵 시설, 생명 유지 장치, 비상 통신, 항공 항법 또는 통신 시스템, 항공 교통 관제 시스템의 운영에 사용되거나 서비스 또는 콘텐츠 오류로 인해 사망, 신체적 상해, 심각한 물리적 또는 환경적 손상으로 이어질 수 있는 기타 다른 활동에 사용할 수 없습니다.

5.3. 회사의 책임제한

회사가 아닌 제 3자가 소프트웨어센터를 통해 제공한 콘텐츠에 관해 회사는 책임을 지지 않으며 어떠한 보증도 하지 않습니다.


제 6 조 회사의 통지

회사가 회원에게 고지할 내용이 있는 경우 회원가입 시 기재한 이메일로 통지를 합니다. 


제 7 조 계약의 종료 및 해지

7.1. 회원이 본 약관의 내용을 준수하지 않는 경우, 회사는 계약을 종료할 수 있습니다. 

7.2. 사용자는 원하는 경우 언제든지 서비스 내 제공되는 메뉴를 이용하여 해지 신청을 할 수 있습니다.

7.3. 회원은 Tmax CloudSpace 및 TmaxOS 홈페이지에서 탈퇴 가능합니다.


제 8 조 휴면계정

8.1. 통합서비스를 1년 동안 이용하지 않을 경우 휴면계정으로 분리됩니다. 4개의 하부 서비스 중 Tmax CloudSpace, TmaxOS 홈페이지 및 소프트웨어센터를 1년 이내에 한 번이라도 이용한 경우 본 조항은 적용되지 않습니다.

8.2. 휴면계정으로 분리하기 30일 전 회원의 전자메일로 사전 공지를 할 예정입니다. 사전공지 이후 30일 동안 별다른 이의신청이 없으면 휴면계정으로 분리됩니다.

8.3. 휴면계정으로 분리되면 별도의 휴면해제절차를 거쳐야 서비스 사용이 가능합니다.

8.4. 수집된 회원들의 개인정보가 분리 보관됩니다.

8.5. Tmax CloudSpace의 경우 해당 회원의 데이터가 삭제될 수 있습니다. 이로 인한 회원들의 손해에 대하여 회사는 어떠한 책임도 지지 않습니다.


제 9 조 손해배상

9.1. 회사는 본 약관에 따른 소프트웨어센터 이용과 관련하여, 회원에게 발생한 어떠한 손해에 대하여도 책임지지 않습니다. 

9.2. 회원은 자신의 소프트웨어센터의 이용과 관련하여 회사에 손해가 발생한 경우, 회사에게 발생한 모든 손실, 손해를 책임지고 배상하여야 합니다.


제 10 조 기타

10.1. 분리가능

본 약관의 일부 조항이 관할 지역의 관련 법률에 의하여 무효로 해석되거나 법률에 위반하게 되는 경우 해당 조항은 관련 법률에 따라 합법적인 범위 내로 해석되거나 무효가 될 수 있습니다. 해당 조항의 무효 등은 본 약관의 유효성 및 다른 조항의 내용에 영향을 미치지 않습니다.

10.2. 계약서의 인정

회원은 본 약관에 명시된 모든 내용을 읽고 이해하고 동의한 것임을 인정합니다.

10.3. 분쟁의 해결

본 약관은 대한민국의 법률에 따라 규율되며, 소프트웨어센터 사용에 관련된 소송 등 모든 법적인 문제에 대해서는 대한민국 서울중앙지방법원에 전속관할권이 있습니다.

10.4. 본 약관에 대한 문의 사항

본 약관에 대한 의문 사항은 아래 연락처를 참고하시기 바랍니다.


㈜티맥스OS

경기도 성남시 분당구 황새울로 BS 타워 6 ~ 9층

홈페이지: http://www.tmaxos.com


공고일자 : 2019년 8월 15일
시행일자 : 2019년 8월 15일

[Tmax CloudSpace 이용약관]


제 1 조 목적

본 약관은 ㈜티맥스OS(이하 “회사”라 함)가 제공하는 Tmax CloudSpace의 이용과 관련하여 회사와 회원과의 권리, 의무 및 책임사항, 기타 필요한 사항을 규정함을 목적으로 합니다.


제 2 조 정의

2.1. 본 약관에서 사용하는 용어의 의미는 다음과 같습니다. 

1) Tmax CloudSpace는 회사가 제공하는 메신저, 메일, 스케줄, 노트, 오피스, 파일저장소 등을 포함하는 Tmax CloudSpace 앱 서비스와 Tmax CloudSpace 이용을 위한 기술지원 등 관련 제반 서비스를 의미합니다.
2) 회원이라 함은 회사의 Tmax CloudSpace에 접속하여 본 약관에 따라 회사와 이용계약을 체결하고 회사로부터 Tmax CloudSpace 이용 자격을 부여 받은 개인을 의미합니다. 
3) Tmax CloudSpace 아이디(ID)라 함은 회원의 식별과 Tmax CloudSpace의 이용을 위하여 회원이 설정한 문자와 숫자, 특수문자의 조합을 의미합니다. 
4) Tmax CloudSpace 비밀번호라 함은 회원의 비밀보호를 위하여 Tmax CloudSpace 회원이 설정한 문자와 숫자, 특수문자의 조합을 말합니다. 
5) 통합 약관이라 함은 통합서비스를 제공하는 Tmax Cloud 계정 이용약관을 의미합니다.

2.2. 본 약관에서 사용하는 용어 중 본 조에서 정하지 아니한 것은 Tmax Cloud 계정 이용약관, 관계법령 및 상관례에 따릅니다.


제 3 조 약관의 효력 및 변경

3.1. 본 약관의 내용은 통합서비스 화면에 게시하거나 개별 서비스 홈페이지에 공지하고, 본 약관에 동의한 모두에게 효력이 있습니다.

3.2. 본 약관에 규정되지 않은 사항에 대해서는 통합 약관, 관련 법령 및 상관례의 순서로 적용이 되며, 본 약관과 통합 약관이 충돌할 경우 본 약관의 효력이 우선합니다.

3.3. 회사는 필요한 경우 관련 법령을 위배하지 않는 범위 내에서 본 약관을 변경할 수 있습니다. 본 약관이 변경되는 경우 회사는 변경사항을 시행 일자 15일 전부터 서비스 공지사항에서 공지 또는 통지하는 것을 원칙으로 하며, 회원에게 불리한 내용으로 변경될 경우에는 그 시행일자 30일 전부터 Tmax Cloud 계정에 등록된 이메일 주소로 전자메일(이메일 주소가 없는 경우 서비스 내 메일 및 알림 메시지를 띄우는 등의 별도의 전자적 수단)을 발송하는 방법 등으로 개별적으로 공지하겠습니다. 회원이 기간이 지난 후에도 Tmax CloudSpace를 계속 사용하면 새 약관에 동의한 것으로 간주합니다. 새 약관은 회원의 모든 콘텐츠(과거에 이용했거나 구매한 콘텐츠 포함) 이용과 향후의 모든 이용 및 구매에 적용됩니다. 

3.4. 변경 사항에 동의하지 않는 경우에는 Tmax CloudSpace 개인설정 메뉴 안의 계정 삭제를 통해 Tmax CloudSpace의 이용계약을 해지신청을 할 수 있습니다. 


제 4 조 회원가입 및 서비스 이용 방법

4.1. Tmax CloudSpace 이용계약은 Tmax CloudSpace 회원이 되고자 하는 자(이하 “Tmax CloudSpace 이용신청자”라 함)가 회사가 제공하는 회원가입 방법에 따라 Tmax CloudSpace 회원가입 절차를 완료함으로써 체결됩니다. 단 아동의 보호를 위하여 만14세 미만의 회원가입이 제한됩니다.

4.2. Tmax CloudSpace 이용신청자는 Tmax CloudSpace 회원가입 시 회사에서 요구하는 제반 정보(이름, 생년월일, 이메일 주소 등)를 제공하여야 합니다. 

4.3. Tmax CloudSpace 이용신청자가 회사가 제공하는 그 외 서비스를 제공받기 위해서는 별도의 이용신청을 거치거나 그 과정에서 추가적인 정보제공이 필요할 수 있습니다.

4.4. Tmax CloudSpace는 가입 후 무료로 사용 가능합니다. 단, 향후 별도로 유료임을 명시한 정보에 대해서는 해당 정보에 명시된 요금을 지불하여야 사용이 가능합니다.

4.5. Tmax CloudSpace 가입 후 서비스 이용 중 제 3자의 앱 서비스에 대해서는 별도의 약관 동의 및 정보제공이 필요할 수 있습니다.


제 5 조 개인정보의 보호 

회원의 개인정보는 통합서비스의 원활한 제공을 위하여 회원이 동의한 목적과 범위 내에서만 이용됩니다. 관련 법령에 의하지 아니하는 한 회사가 개인정보를 제 3자에게 제공하는 경우 회원의 별도 동의를 받아야 합니다.  기타 자세한 사항은 Tmax Cloud 계정 개인정보처리방침 등을 참고해 주시기 바랍니다. 


제 6 조 Tmax CloudSpace의 이용

6.1. Tmax CloudSpace는 회사가 정한 운영정책에 따라 제공되는 것이며, 회사는 운영, 기술 상의 필요에 따라 언제든 위 운영정책을 변경하거나 Tmax CloudSpace의 제공을 중단할 수 있습니다.

6.2. 전항에 따라 Tmax CloudSpace 이용이 중단될 경우 회사는 제 7 조에서 정한 방법으로 Tmax CloudSpace 회원에게 사전에 통지해야 합니다. 단, 불가항력적인 사유로 중단될 경우에는 통지하지 않을 수 있습니다. 

6.3. 회원이 본 약관에 기초하여 Tmax CloudSpace를 이용하던 중 회사가 정한 통합 약관 및 본 약관에서 정한 기준을 충족시키지 못하게 되는 경우, 회사는 즉시 해당 회원에게 제공하던 Tmax CloudSpace의 제공을 중단할 수 있습니다. 

6.4. 전항에 따라 이용이 중단된 회원이 계속하여 Tmax CloudSpace를 제공받기 위해서는 별도로 마련된 고객문의 이메일(cspsupport@tmax.co.kr)을 통해서 서비스 이용과 관련된 문의 절차를 거쳐야 합니다.


제 7 조 회사의 통지

회사가 회원에게 고지할 내용이 있는 경우 회원가입 시 기재한 이메일 주소로 통지를 합니다. 


제 8 조 회사의 Tmax CloudSpace 이용 계약 해지 등

8.1 회사는 다음 각 호의 경우 해당 회원에 관련된 일체의 데이터를 삭제하고 회원과의 Tmax CloudSpace 이용계약을 해지할 수 있습니다.  
1) 본 약관 상 의무를 위반하는 경우 
2) 회사의 정상적인 Tmax CloudSpace 운영을 방해한 경우 
3) 불법 혹은 부당한 방법으로 Tmax CloudSpace를 이용하는 경우 
4) 위 각 호에 준하는 사유로 Tmax CloudSpace 이용계약의 해지가 필요하다고 회사가 판단한 경우
    
8.2 본 조에 기초하여 회원이 Tmax CloudSpace를 이용하지 못하게 되는 경우에도 회사는 회원에게 어떠한 손해배상의무도 부담하지 아니합니다.


제 9 조 회원의 Tmax CloudSpace 이용 계약 해지

9.1. 회원은 언제든지 Tmax CloudSpace 이용 계약을 해지할 수 있습니다. 

9.2. Tmax CloudSpace 이용 계약 해지 시, 회원은 Tmax CloudSpace 이용기간 동안 사용한 모든 자원에 대하여 해지 전 직접 백업하여야 합니다. 

9.3. 제 1 항에 따라 회원이 Tmax CloudSpace 이용 계약을 해지할 경우 관계법령 및 개인정보처리방침에 따라 회사가 회원의 정보를 보유하는 경우를 제외하고는 회원에게 제공된 모든 자원이 회수되며 Tmax CloudSpace 상에 저장되어 있는 모든 정보가 삭제되고, 삭제된 자료는 어떠한 이유로도 복원되지 않습니다.

9.4. 회원은 Tmax CloundSpace와 TmaxOS 홈페이지에서 탈퇴가 가능합니다.


제 10 조 휴면계정

10.1. 통합서비스를 1년 동안 이용하지 않을 경우 휴면계정으로 분리됩니다. 4개의 하부 서비스 중 Tmax CloudSpace, TmaxOS 홈페이지 및 TmaxOS 소프트웨어센터를 1년 이내에 한 번이라도 이용한 경우 본 조항은 적용되지 않습니다.

10.2. 휴면계정으로 분리하기 30일 전 회원이 회원가입시 기재한 이메일 주소로 전자우편을 보내 사전 공지를 할 예정입니다. 사전공지 이후 30일 동안 별다른 이의신청이 없으면 휴면계정으로 분리됩니다.

10.3. 휴면계정으로 분리되면 별도의 휴면해제절차를 거쳐야 서비스 사용이 가능합니다.

10.4. 휴면계정으로 분리되면 수집된 회원들의 개인정보가 분리보관됩니다.

10.5. 휴면계정으로 분리되면 Tmax CloudSpace의 경우 해당 회원의 데이터가 삭제될 수 있습니다. 이로 인한 회원들의 손해에 대하여 회사는 어떠한 책임도 지지 않습니다.


제 11 조 손해배상

11.1. 회사는 본 약관에 따른 Tmax CloudSpace의 제공과 관련하여, 회원에게 발생한 어떠한 손해에 대하여도 책임지지 않습니다. 

11.2. 회원은 자신의 Tmax CloudSpace의 이용과 관련하여 회사에 손해가 발생한 경우, 회사에게 발생한 모든 손실, 손해를 책임지고 배상하여야 합니다.


제 12 조 기타

12.1. 분리가능
본 약관의 일부 조항이 관할 지역의 관련 법률에 의하여 무효로 해석되거나 법률에 위반하게 되는 경우 해당 조항은 관련 법률에 따라 합법적인 범위 내로 해석되거나 무효가 될 수 있습니다. 해당 조항의 무효 등은 본 약관의 유효성 및 다른 조항의 내용에 영향을 미치지 않습니다.

12.2. 계약서의 인정
회원은 본 약관에 명시된 모든 내용을 읽고 이해, 동의한 것임을 인정합니다.

12.3. 분쟁의 해결
본 약관은 대한민국의 법률에 따라 규율되며, Tmax CloudSpace 사용에 관련된 소송 등 모든 법적인 문제에 대해서는 대한민국 서울중앙지방법원에 전속관할권이 있습니다.

12.4. 본 약관에 대한 문의 사항
본 약관에 대한 의문 사항은 아래 연락처를 참고하시기 바랍니다.


㈜티맥스OS

경기도 성남시 분당구 황새울로 BS 타워 6 ~ 9층

홈페이지: http://www.tmaxos.com


<부칙> 본 약관은 2019년 8월 15일부터 적용됩니다.

TmaxOS 홈페이지 이용약관

제 1 조 목적
이 약관은 ㈜티맥스OS(이하 “회사”라 함)가 운영하는 웹사이트(이하 “사이트”라 함)에서 제공하는 인터넷관련 서비스(이하 "서비스"라 함)를 이용함에 있어 회사와 이용자의 권리, 의무 및 책임사항 규정을 목적으로 합니다.

제 2 조 정의
이 약관에서 사용하는 용어의 정의는 다음과 같습니다.
2.1. 사이트란 회사가 재화 또는 용역을 이용자에게 제공하기 위하여 컴퓨터 등 
    정보통신설비를 이용하여 재화 또는 용역을 거래할 수 있도록 설정한 가상의 영업장 또는 
    회사가 운영하는 웹사이트를 말하며 아래의 웹사이트를 포함합니다. 
    1) ㈜티맥스OS: http://www.tmaxos.com
    2) ㈜티맥스오에스 해외법인: http://www.tmaxsoft.com/us_en/home/
    3) 이외 ㈜티맥스OS가 운영하는 비영리 사이트 전체가 포함됩니다.
2.2. 이용자라 함은 사이트에 접속하여 이 약관에 따라 사이트가 제공하는 서비스를 받는 
    회원 및 비회원을 말합니다.
2.3. 회원이라 함은 사이트에 개인정보를 제공하여 회원등록을 한 자로서, 사이트의 정보를 
    지속적으로 제공 받으며 회사가 제공하는 서비스를 계속적으로 이용할 수 있는 자를 
    말합니다.
2.4. 비회원이라 함은 회원에 가입하지 않고 사이트가 제공하는 서비스를 이용하는 자를 
    말합니다.
2.5. ID라 함은 회원의 식별과 서비스 이용을 위하여 회원이 정하는 문자, 숫자 및 특수문자의
조합을 의미합니다.
2.6. 비밀번호라 함은 회원의 비밀보호를 위해 회원이 선정한 문자, 숫자 및 특수 문자의 조합을 말합니다.

제 3 조 약관의 효력과 변경
3.1. 이 약관은 회원에게 공시하고 이용자가 동의함으로써 효력이 발생됩니다. 단, 비회원의 경우
본 사이트를 사용하기 시작하는 때 약관의 효력이 발생합니다.  
3.2. 회사는 약관의 규제에 관한 법률, 전자서명법, 정보통신망이용촉진 및 정보보호 등에 관한 
    법률 등 관련법을 위반하지 않는 범위에서 이 약관을 변경할 수 있으며, 이 경우에는 
    적용일자 및 개정사유, 변경되는 내용을 명시하여 현행 약관과 함께 초기 서비스 화면에 그 
    적용일자 15일전부터 적용일자 전일까지 공지합니다.
3.3. 제2항에 의거하여 변경된 약관은 제1항과 같은 방법으로 효력이 발생됩니다.
3.4. 본 약관에 규정되지 않은 사항에 대해서는 Tmax Cloud 계정 이용약관(이하 “통합 약관”이라 함), 관련 법령 및 상관례의 순서로 적용이 되며, 본 약관과 통합 약관이 충돌할 경우 본 약관의 효력이 우선합니다.

제 4 조 서비스 이용 계약의 성립
4.1. 회사의 서비스 이용 계약은 이용자의 이용 신청 및 약관에 대한 동의로 성립됩니다. 
    단 아동의 보호를 위하여 만14세 미만의 회원가입이 제한됩니다.
4.2. 이용자가 회원에 가입하여 회사의 서비스를 이용하고자 하는 경우, 이용자는 회사에서 
    요청하는 개인 신상정보를 제공해야 합니다.
4.3. 아래 각 호에 해당하는 경우 가입이 제한될 수 있습니다.
    1) 다른 사람의 명의를 사용하여 신청한 때
    2) 이용 계약 신청서의 내용의 허위, 오기, 기재누락하여 신청한 때
    3) 사회의 안녕 질서 혹은 미풍양속을 저해할 목적으로 신청한 때
    4) 약관 제12조 4항 각호의 행위를 하여 회사에 의해 이용해지를 당하거나, 서비스 이용이 
        중지된 자가 다시 신청한 때

제 5 조 개인정보 보호
회원들의 개인정보는 통합서비스의 원활한 제공을 위하여 회원들이 동의한 목적과 범위 내에서만 이용됩니다. 관련 법령에 의하지 아니하는 한 회사가 개인정보를 제 3자에게 제공하는 경우 회원들의 별도의 동의를 받아야 합니다. 
기타 자세한 사항은 Tmax Cloud 계정 개인정보처리방침 등을 참고해 주시기 바랍니다. 

제 6 조 서비스 이용 및 제한
6.1. 회원은 회사를 통한 회원 가입 시 발급된 ID로 사이트의 서비스를 이용할 수 있습니다. 
    제 2 조 1항에 명시된 모든 사이트는 별도의 가입절차를 통하여 이용할 수 있으며, 이 경우  
    해당 사이트의 이용 시에는 해당 사이트의 특정서비스에 대한 이용약관이 이 약관보다 
    우선 적용됩니다.
6.2. 회원의 최초 서비스 이용신청 이후 사이트가 늘어난 경우에도 신규 사이트의 
    이용약관에서 달리 명시되지 않는 한 제 1 항의 내용이 마찬가지로 적용됩니다. 이 경우 
    신규 사이트의 서비스 개시에 대한 정보는 사이트에서의 공지 또는 회원들에 대한 이메일 
    통지 등으로 회원에게 전달됩니다.
6.3. 사이트의 서비스 이용은 사이트의 업무상 또는 기술상 특별한 지장이 없는 한 연중무휴, 
    1일 24시간을 원칙으로 합니다.
6.4. 회사는 정보통신 시스템 등의 정기점검, 보수, 교체, 고장, 통신두절 등의 사유가 발생한 
    경우에는 서비스의 제공을 일시 중단할 수 있습니다.
6.5. 제4항에 의한 서비스 중단의 경우에는 회사는 이용자에게 제17조의 방법으로 통지를 
    합니다. 단, 회사가 통제할 수 없는 사유로 인한 서비스 중단으로 사전 통지가 불가능한 
    경우에는 그러하지 아니합니다.
6.6. 회사에서 제공하는 서비스는 기본적으로 무료입니다. 단, 별도로 유료임을 명시한 정보에 
    대해서는 해당 정보에 명시된 요금을 지불하여야 사용이 가능합니다.
6.7. 회사가 제공하는 저작물에 대한 저작권, 기타 지적 재산권은 회사에 귀속합니다.
6.8. 이용자는 사이트를 이용함으로써 얻은 정보를 회사의 사전 승낙 없이 복제, 송신, 출판, 
    배포, 방송, 기타 방법에 의하여 영리목적으로 이용하거나 제 3자에게 이용하게 하여서는 
    안됩니다.
6.9. 회사는 서비스 요금이 무료인 서비스 이용과 관련하여 이용자에게 발생한 어떠한 손해에 
    대해서도 책임을 지지 않습니다.
6.10. 타인의 정보(ID나 이메일)를 도용하여 이용신청을 한 회원의 모든 ID는 삭제되며, 
        관계법령에 따라 처벌을 받을 수 있습니다.

제 7 조 회사의 의무
7.1. 회사는 특별한 사정이 없는 한 회원이 신청한 서비스에 대한 이용계약이 성립된 날에 
    서비스를 이용할 수 있도록 합니다.
7.2. 회사는 이 약관에서 정한 바에 따라 계속적, 안정적으로 서비스를 제공할 의무가 있습니다.
7.3. 회사는 이용자로부터 소정의 절차에 의해 제기되는 의견에 대해서는 적절한 절차를 거쳐 
    처리하며, 처리시 일정 기간이 소요될 경우 이용자에게 그 사유와 처리 일정을 알려주어야 
    합니다.
7.4. 회사는 회원의 정보를 철저히 보안 유지하며, 양질의 서비스를 운영하거나 개선하기 
    위하기 위한 경우 또는 제품소개 등 회사 내부 목적으로 이용하는 데만 사용하고, 이외의 
    다른 목적으로 타 기관 및 제 3자에게 양도하지 않습니다.

제 8 조 회원의 의무
8.1. 회원의 ID와 비밀번호에 관한 모든 관리의 책임은 회원에게 있습니다.
8.2. 회원은 ID 및 비밀번호를 제 3자에게 이용하게 해서는 안됩니다. 회원은 ID 및 비밀번호를 
    도난 당하거나 제3자가 사용하고 있음을 인지하는 경우에는 반드시 회사에 그 사실을 
    통보해야 합니다.
8.3. 회원을 포함한 이용자는 이 약관 및 관계 법령에서 규정한 사항을 준수하여야 합니다.
8.4. 회원을 포함한 이용자는 다음 행위를 하여서는 안됩니다.
    1) 신청 또는 변경 시 허위내용의 등록
    2) 사이트에 게시된 정보의 변경
    3) 사이트가 정한 정보 이외의 정보(컴퓨터 프로그램 등)의 송신 또는 게시
    4) 회사, 기타 제3자의 저작권 등 지적재산권에 대한 침해
    5) 회사, 기타 제3자의 명예를 손상시키거나 업무를 방해하는 행위
    6) 외설 또는 폭력적인 메시지, 화상, 음성 기타 미풍양속에 반하는 정보를 공개 또는 
        게시하는 행위
    7) 타인의 정보를 도용하는 행위
8.5. 회원이 등록한 회원 ID 및 비밀번호에 의하여 발생되는 사용상의 과실 또는 제3자에 의한 
    부정사용 등에 대한 모든 책임은 해당 이용고객에게 있습니다.

제 9 조 이용자의 게시물
회사는 이용자가 게시하는 내용물이 다음의 각 호에 해당하는 경우 사전 통지 없이 삭제할 수 있습니다.
1) 다른 이용자 또는 제 3자를 비방하거나 중상모략으로 명예를 손상시키는 내용인 경우
2) 공공질서 및 미풍양속에 위반되는 내용인 경우
3) 범죄적 행위에 결부된다고 인정되는 내용인 경우
4) 회사에서 규정한 게시기간을 초과한 내용인 경우
5) 기타 관계 법령을 위반한다고 판단한 경우
6) 기타 회사가 정한 이용조건에 위반한 경우

제 10 조 게시물의 저작권
10.1. 게시물에 대한 권리와 책임은 게시자에게 있으며, 회사는 게시자의 동의 없이는 해당 
        게시물을 사용할 수 없습니다.
10.2. 이용자는 서비스를 이용하여 얻은 정보를 가공, 판매하는 행위 등 서비스에 게재된 자료를 
        상업적으로 활용할 수 없습니다.

제 11 조 서비스 이용 계약 해지 및 이용 중지
11.1. 회원이 서비스 이용 계약을 해지하고자 하는 때에는 회원 본인이 직접 사이트에서 
        서비스 이용 계약 해지를 신청해야 합니다. 회원탈퇴는 Tmax CloudSpace, TmaxOS 홈페이지
        를 통해서 가능합니다.
11.2. 전항의 경우, 회원은 ID, 비밀번호를 입력하여 본인임을 확인한 후, 해지 안내사항 확인 및
비밀번호 재 입력을 통해 회원 가입 및 서비스 이용 계약이 해지됩니다.
11.3. 회원 가입 및 서비스 이용 계약의 해지 여부는 기존의 ID, 비밀번호로 로그인이 되지 
        않으면 해지된 것입니다.
11.4. 회사는 회원이 다음 사항에 해당하는 행위를 하였을 경우, 사전 통지 없이 서비스 이용   
        계약을 해지하거나 또는 상당한 기간을 정하여 서비스 이용을 중지할 수 있습니다.
    1) 공공 질서 및 미풍양속에 반하는 경우
        2) 범죄적 행위에 관련되는 경우
        3) 국익 또는 사회적 공익을 저해할 목적으로 서비스 이용을 계획 또는 실행할 경우
        4) 타인의 ID 및 비밀번호를 도용한 경우
        5) 타인의 명예를 손상시키거나 불이익을 주는 경우
        6) 서비스에 위해를 가하는 등 건전한 이용을 저해하는 경우
        7) 기타 관련 법령이나 회사가 정한 이용조건에 위배되는 경우

제 12 조 휴면계정
12.1. 회원이 통합서비스를 1년 동안 이용하지 않을 경우 휴면계정으로 분리됩니다. 4개의 하부 서비스 중 Tmax CloudSpace, TmaxOS 홈페이지 및 소프트웨어센터를 1년 이내에 한 번이라도 이용한 경우 본 조항은 적용되지 않습니다.
12.2. 휴면계정으로 분리하기 30일 전 통합계정에 등록된 이메일 주소로 사전 공지를 할 
예정입니다. 사전공지 이후 30일 동안 별다른 이의신청이 없으면 휴면계정으로 분리됩니다.
12.3. 휴면계정으로 분리되면 별도의 휴면해제절차를 거쳐야 서비스 사용이 가능합니다.
12.4. 수집된 회원들의 개인정보가 분리보관됩니다.
12.5. 휴면계정으로 분리되면 Tmax CloudSpace의 경우 해당 회원의 데이터가 삭제될 수 
있습니다. 이로 인한 회원들의 손해에 대하여 회사는 어떠한 책임도 지지 않습니다.

제 13 조 서비스 이용 중지의 해제 절차
13.1. 회사는 서비스 이용 중지를 하고자 하는 경우에는 그 사유, 일시 및 기간을 정하여 이메일 
        등의 방법을 이용하여 해당 회원 또는 대리인에게 통지합니다. 다만, 회사가 긴급하게 
        이용을 중지해야 할 필요가 있다고 인정하는 경우에는 본문의 과정 없이 서비스 이용을 
        중지할 수 있습니다.
13.2. 전항의 규정에 의하여 서비스 이용 중지를 통지 받은 회원 또는 그 대리인은 서비스 이용 
        중지에 대하여 이의신청을 할 수 있습니다.
13.3. 회사는 서비스 이용중지 기간 중에 그 이용중지 사유가 해소된 것이 확인된 경우에 
        한하여 이용중지 조치를 즉시 해제합니다.

제 14 조 정보의 제공
14.1. 회사는 회원이 서비스 이용 및 회사의 각종 행사 또는 정보서비스에 대해서는 이메일 
        등의 방법으로 회원들에게 제공할 수 있습니다.
14.2. 회사는 서비스 개선 및 회원 대상의 서비스 소개 등의 목적으로 회원의 동의 하에 
        추가적인 개인 정보를 요구할 수 있습니다.

제 15 조 광고의 제공
회사는 회원의 동의 하에 회사의 제품 광고를 이메일을 통해 회원들에게 제공할 수 
있습니다.

제 16 조 면책조항
16.1. 회사는 천재지변 또는 이에 준하는 불가항력으로 인하여 서비스를 제공할 수 없는 
        경우에는 서비스 제공에 관한 책임이 면제됩니다.
16.2. 회사는 이용자의 귀책 사유로 인한 서비스 이용 장애에 대하여 책임을 지지 않습니다.
15.3. 회사는 이용자가 서비스를 이용하여 기대하는 수익을 상실한 것이나 서비스를 통하여 
        얻은 자료로 인한 손해에 관하여 책임을 지지 않습니다.
16.4. 회사는 이용자가 서비스에 게재한 정보, 자료, 사실의 신뢰도, 정확성 등 내용에 관하여는 
        책임을 지지 않습니다.
16.5. 회사는 서비스 이용과 관련하여 이용자에게 발생한 손해 가운데 이용자의 고의, 과실에
    의한 손해에 대하여 책임을 지지 않습니다.

제 17 조 회원 및 이용자에 대한 통지
17.1. 회사는 회원에 대한 통지를 하는 경우에는 회원이 서비스 이용 신청 시 회사에 
        제출한 이메일 주소로 할 수 있습니다.
17.2. 회사는 불특정다수 회원에 대한 통지의 경우 1주일 이상 사이트 게시판에 
        게시함으로써 개별통지에 갈음할 수 있습니다.

제 18 조 기타
18.1. 분리가능
본 약관의 일부 조항이 관할 지역의 관련 법률에 의하여 무효로 해석되거나 법률에 위반하게 되는 경우 해당 조항은 관련 법률에 따라 합법적인 범위 내로 해석되거나 무효가 될 수 있습니다. 해당 조항의 무효 등은 본 약관의 유효성 및 다른 조항의 내용에 영향을 미치지 않습니다.
18.2. 계약서의 인정
이용자는 본 약관에 명시된 모든 내용을 읽고 이해하고 동의한 것임을 인정합니다.
18.3. 분쟁의 해결
본 약관은 대한민국의 법률에 따라 규율되며, 이 사이트와 관련된 소송 등 모든 법적인 문제에 대해서는 대한민국 서울중앙지방법원에 전속관할권이 있습니다.
18.4. 본 약관에 대한 문의 사항
본 약관에 대한 의문 사항은 아래 연락처를 참고하시기 바랍니다.
㈜티맥스OS
경기도 성남시 분당구 황새울로 BS 타워 6 ~ 9층
홈페이지: http://www.tmaxos.com

(부칙) 이 약관은 2019년 08월 15일부터 시행합니다.    
`}
                </PageBodyPre>
              </PageInnerContents>
            )}
          </PageInner>
        </PageBody>
      </PagePolicy>
    </>
  );
};

export default TermAndConditions;

import React, { useState, useEffect } from 'react';
import styled from 'styled-components';
import { useCoreStores, Loader } from 'teespace-core';
import { useParams } from 'react-router-dom';
import SpaceHeader from '../components/space/SpaceHeader';
import TermsFooter from '../components/TermsFooter';
import { useStores } from '../stores';
import AdminAccessDenyContent from '../components/space/AdminAccessDenyContent';
import InvalidSpaceUrlContent from '../components/space/InvalidSpaceUrlContent';
import NotAuthorizedSpaceContent from '../components/space/NotAuthorizedSpaceContent';
import NotFoundContent from '../components/NotFoundContent';

function DomainRedirectPage() {
  const { domain } = useParams();
  const { spaceStore } = useStores();
  const { waplUserStore } = useCoreStores();
  const [spaceNotExist, setSpaceNotExist] = useState(false);
  const [spaceNotAuthorized, setSpaceNotAuthorized] = useState(false);
  const [notFound, setNotFound] = useState(false);
  const [searchedSpace, setSearchedSpace] = useState({});
  const [hasError, setHasError] = useState(false);
  const query = new URLSearchParams(window.location.search);
  const authFail = !!query.get('admin');
  const [loader] = Loader.useLoader();

  useEffect(() => {
    const fetchSpaceInfo = async () => {
      loader.loading();
      try {
        await spaceStore.fetchSpaces({ userId: waplUserStore.myProfile.id });
        const space = await spaceStore.searchSpaceByDomain({ domain });
        setSearchedSpace(space);
        if (!space) {
          setSpaceNotExist(true);
        } else if (
          spaceStore.spaceList.findIndex(
            spaceElem => spaceElem.id === space.id,
          ) === -1
        ) {
          setNotFound(true);
          setSpaceNotAuthorized(true);
        } else if (!authFail) {
          setHasError(true);
        }
        loader.stop();
      } catch (error) {
        setHasError(true);
        loader.stop();
      }
    };
    fetchSpaceInfo();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <Loader loader={loader}>
      <Wrapper>
        {!notFound && !hasError && <SpaceHeader />}
        <Content>
          {!authFail && spaceNotExist && <InvalidSpaceUrlContent />}
          {!authFail && spaceNotAuthorized && (
            <NotAuthorizedSpaceContent spaceInfo={searchedSpace} />
          )}
          {authFail && <AdminAccessDenyContent spaceInfo={searchedSpace} />}
          {!authFail && hasError && <NotFoundContent />}
        </Content>
        <TermsFooter autoMarginTop />
      </Wrapper>
    </Loader>
  );
}

export default DomainRedirectPage;

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  min-height: 100%;
`;
const Content = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  flex-grow: 1;
`;

import React, { useCallback, useEffect, useState } from 'react';
import { Route, useHistory, useLocation } from 'react-router-dom';
import { ReactKeycloakProvider, useKeycloak } from '@react-keycloak/web';
import {
  useCoreStores,
  WaplAuthStore,
  Message,
  WaplUserStore,
} from 'teespace-core';
import Cookies from 'js-cookie';
import { useTranslation } from 'react-i18next';
import keycloakClient, {
  initOptions as keycloakInitOptions,
  logout,
} from './keycloak';
import LoadingComponent from '../components/Loading';

import SpaceStore from '../stores/SpaceStore';
import FriendStore from '../stores/FriendStore';

export const PrivateContainer = ({ children }) => {
  const { keycloak } = useKeycloak();
  const { waplAuthStore, waplUserStore } = useCoreStores();
  const history = useHistory();
  const path = window.location.href.split(window.location.host)[1];
  const query = new URLSearchParams(window.location.search);
  const redirectUri = query.get('redirect_uri')
    ? encodeURIComponent(query.get('redirect_uri'))
    : encodeURIComponent(path);

  const { i18n } = useTranslation();
  const isLocal = process.env.REACT_APP_ENV === 'local';
  const invalidUri = [
    '/',
    '/voucher',
    '/story',
    '/service',
    '/news',
    '/introduction',
    '/download',
    '/privacy-policy',
    '/term-and-conditions',
  ];
  const [messageVisible, setMessageVisible] = useState(false);
  const [blockMessageVisible, setBlockMessageVisible] = useState(true);
  const { t } = useTranslation('common');

  const handleCancelDormantAccountClick = useCallback(async () => {
    const success = await waplAuthStore.updateActiveUser();
    if (success) {
      setMessageVisible(false);
      // history.replace('/spaces');
    }
  }, []);

  const handleBlockMessageClose = () => {
    setBlockMessageVisible(false);
    keycloak.logout({ redirectUri: `${window.location.href}` });
  };

  useEffect(() => {
    if (
      waplAuthStore.sessionInfo?.isHyperAuthTermAgree === 'N' &&
      ssoType !== `keycloak`
    ) {
      history.replace(`/auth-first-login?redirect_uri=${redirectUri}`);
    } else if (waplAuthStore.sessionInfo?.isTermAgree === 'N') {
      history.replace(`/first-login?redirect_uri=${redirectUri}`);
    } else if (
      waplAuthStore.sessionInfo?.isTermAgree === 'Y' &&
      (window.location.href.includes('/first-login') ||
        window.location.href.includes('/invite-code'))
    ) {
      history.replace('/spaces');
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  if (keycloak.authenticated) {
    // superadmin block
    if (keycloak.realmAccess?.roles.includes('wapl-superadmin')) {
      return (
        <Message
          visible={blockMessageVisible}
          title={t('LOGIN_BLOCK_MESSAGE')}
          btns={[
            {
              type: 'solid',
              shape: 'round',
              text: `${t('CM_LOGIN_POLICY_03')}`,
              onClick: handleBlockMessageClose,
            },
          ]}
        />
      );
    }
    if (
      waplAuthStore.isAuthenticated &&
      keycloak.tokenParsed.preferred_username ===
      waplAuthStore.sessionInfo.loginId
    ) {
      // mobx store에 이미 정보가 있고 해당 정보가 keycloak과 동일한 경우
      if (children) {
        return children;
      }
      return null;
    }
    if (!messageVisible) {
      Promise.resolve()
        .then(() => {
          // 정보가 불일치하므로 로그아웃
          if (waplAuthStore.isAuthenticated) {
            Cookies.remove('isInvitedUser');
            return waplAuthStore.logout({ deviceType: 'PC' });
          }
          return null;
        })
        .then(() => {
          return waplAuthStore.login({
            accessToken: keycloak.token,
            deviceType: 'PC',
            isInvitedUser: !!(
              Cookies.get('inviteId') || Cookies.get('isInvitedUser')
            ),
          });
        })
        .then(async result => {
          if (!result) {
            setMessageVisible(true);
            // history.replace('/dormant-account');
            return null;
          }
          if (!result.language) {
            await waplUserStore.updateLanguage({ language: i18n.language });
          } else {
            i18n.changeLanguage(result.language);
          }
          Cookies.set(
            'DEVICE_TYPE',
            'PC',
            isLocal
              ? {}
              : {
                domain: `.${window.location.host}`,
              },
          );
          if (
            result.isHyperAuthTermAgree === 'N' &&
            !invalidUri.includes(query.get('redirect_uri') || path) &&
            ssoType !== `keycloak`
          ) {
            history.replace(`/auth-first-login?redirect_uri=${redirectUri}`);
          } else if (
            result.isTermAgree === 'N' &&
            !invalidUri.includes(query.get('redirect_uri') || path)
          ) {
            history.replace(`/first-login?redirect_uri=${redirectUri}`);
          } else {
            history.replace(path);
          }
        })
        .catch(e => {
          console.log(e.response);
        });
    }
  } else {
    waplAuthStore.logout({ deviceType: 'PC' }).then(() => {
      // update without re-rendering
      Cookies.remove('ACCESS_TOKEN');
      Cookies.remove('DEVICE_TYPE');
      SpaceStore.setSpaces([]);
      FriendStore.setFriends([]);
      keycloak.login({
        redirectUri: `${window.location.origin}${path}`,
        locale: i18n.language, // login page locale 설정. 'en' or 'ko' 설정.
      }); // keycloak login page로 redirect
    });
  }
  return (
    <>
      {!messageVisible && !blockMessageVisible && <LoadingComponent />}
      <Message
        visible={messageVisible}
        title={t('DORMANT_POPUP_TITLE')}
        btns={[
          {
            type: 'solid',
            shape: 'square',
            text: `${t('DORMANT_POPUP_BUTTON')}`,
            onClick: handleCancelDormantAccountClick,
          },
        ]}
      />
    </>
  );
};

export const PrivateRoute = ({ component: Component, ...rest }) => {
  const { children, ...restOptions } = rest;
  const location = useLocation();
  const isSupport = location.pathname.includes('/support');
  return isSupport ? (
    <Route
      {...restOptions}
      render={props => (
        <PrivateSupportContainer>
          <Component>{children}</Component>
        </PrivateSupportContainer>
      )}
    />
  ) : (
    <Route
      {...restOptions}
      render={props => (
        <PrivateContainer>
          <Component>{children}</Component>
        </PrivateContainer>
      )}
    />
  );
};

export const PrivateRouteProvider = ({ children }) => {
  const isLocal = process.env.REACT_APP_ENV === 'local';
  const keycloak = keycloakClient;
  // eslint-disable-next-line no-unused-vars
  const eventLogger = (event, error) => {
    switch (event) {
      case 'onAuthSuccess':
      case 'onAuthRefreshSuccess': {
        Cookies.set(
          'ACCESS_TOKEN',
          keycloak.token,
          isLocal
            ? {}
            : {
              domain: `.${window.location.host}`,
            },
        );
        break;
      }
      case 'onAuthLogout':
        window.location.href = '/';
        break;
      default:
        break;
    }
  };

  return (
    <ReactKeycloakProvider
      authClient={keycloakClient}
      initOptions={keycloakInitOptions}
      LoadingComponent={<LoadingComponent />}
      onEvent={eventLogger}
    >
      {children}
    </ReactKeycloakProvider>
  );
};

export const LogoutTimer = (() => {
  let flag = true;

  const timerConfig = {
    timer: null,
    limit: 1000 * 60 * 60 * 12, // 12시간 후 로그아웃   60 * 60 * 12

    async fnc() {
      try {
        await WaplAuthStore.logout({ deviceType: 'PC' }).then(() => {
          Cookies.remove('ACCESS_TOKEN');
          Cookies.remove('DEVICE_TYPE');
        });
        await logout({
          redirectUri: window.location.href,
          locale: WaplUserStore.myProfile.language,
        });
      } catch (error) {
        throw Error(error);
      }
    },
    start(inputflag) {
      flag = inputflag;
      this.timer = setTimeout(this.fnc.bind(this), this.limit);
    },
    reset() {
      window.clearTimeout(this.timer);
      this.start(false);
    },
    end() {
      console.log('###not use');
      window.clearTimeout(this.timer);
      flag = true;
    },
  };
  document.onmousemove = () => {
    if (!flag) {
      timerConfig.reset();
    }
  };
  return timerConfig;
})();

export const PrivateSupportContainer = ({ children }) => {
  const { keycloak } = useKeycloak();
  const { waplAuthStore, waplUserStore } = useCoreStores();
  const history = useHistory();
  const path = window.location.href.split(window.location.host)[1];

  const { i18n } = useTranslation();
  const isLocal = process.env.REACT_APP_ENV === 'local';

  if (keycloak.authenticated) {
    // keycloak 로그인이 되어있는 경우
    if (
      waplAuthStore.isAuthenticated &&
      keycloak.tokenParsed.preferred_username ===
      waplAuthStore.sessionInfo.loginId
    ) {
      // mobx store에 이미 정보가 있고 해당 정보가 keycloak과 동일한 경우
      if (children) {
        return children;
      }
      return null;
    }
    Promise.resolve()
      .then(() => {
        // 정보가 불일치하므로 로그아웃
        if (waplAuthStore.isAuthenticated) {
          Cookies.remove('isInvitedUser');
          return waplAuthStore.logout({ deviceType: 'PC' });
        }
        return null;
      })
      .then(() => {
        return waplAuthStore.login({
          accessToken: keycloak.token,
          deviceType: 'PC',
          isInvitedUser: !!(
            Cookies.get('inviteId') || Cookies.get('isInvitedUser')
          ),
        });
      })
      .then(async result => {
        if (!result.language) {
          await waplUserStore.updateLanguage({ language: i18n.language });
        } else {
          i18n.changeLanguage(result.language);
        }
        Cookies.set(
          'DEVICE_TYPE',
          'PC',
          isLocal
            ? {}
            : {
              domain: `.${window.location.host}`,
            },
        );

        history.replace(path);
      });
  } else if (children) {
    return children;
  }
  return <LoadingComponent />;
};
export const ssoType =
  window.env.REACT_APP_SSO_TYPE === '%SSO_TYPE%'
    ? process.env.REACT_APP_SSO_TYPE
    : window.env.REACT_APP_SSO_TYPE || process.env.REACT_APP_SSO_TYPE;

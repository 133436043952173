import React from 'react';
import styled from 'styled-components';
import { useObserver } from 'mobx-react';
import WorkspaceHeader from '../components/space/SpaceHeader';
import WorkspaceList from '../components/space/SpaceList';
import TermsFooter from '../components/TermsFooter';
import winterBG from '../assets/winterBG.png';
import winterBGDark from '../assets/winterBG_dark.png';

function SpaceListPage() {
  return useObserver(() => (
    <Wrapper>
      <WorkspaceHeader />
      <Content>
        <WorkspaceList />
      </Content>
      <TermsFooter autoMarginTop />
    </Wrapper>
  ));
}

export default SpaceListPage;

// 211213 겨울테마 on & 220118 off
const handleBGColor = ({ themeName }) => {
  if (themeName === 'dark') {
    return '#2D2F32';
  }
  return '#f9f8f7';
};
const handleBGImg = ({ themeName }) => {
  if (themeName === 'dark') {
    return winterBGDark;
  }
  return winterBG;
};

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  min-height: 100%;
  /* background-color: url('${({ theme }) =>
    handleBGColor({ themeName: theme.name })}');
  background-image: url('${({ theme }) =>
    handleBGImg({ themeName: theme.name })}');
  background-position: center;
  background-size: cover; */
`;
const Content = styled.div`
  display: flex;
  align-items: center;
  justify-content: flex-start;
  flex-direction: column;
  flex-grow: 1;
`;

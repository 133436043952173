import React, { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import styled, { css } from 'styled-components';
import { Swiper, SwiperSlide } from 'swiper/react';
import SwiperCore, { Pagination, Autoplay } from 'swiper';
import coworkMainImage from '../../assets/landing/tab/cowork/img/main.png';
import coworkMainBG from '../../assets/landing/tab/cowork/img/01_graphic.png';
import messengerMainImage from '../../assets/landing/tab/messenger/img/main.png';
import messengerMainBG from '../../assets/landing/tab/messenger/img/02_graphic.png';
import driveMainImage from '../../assets/landing/tab/drive/img/main.png';
import driveMainBG from '../../assets/landing/tab/drive/img/01_graphic.png';
import meetingMainImage from '../../assets/landing/tab/meeting/img/main.png';
import meetingMainBG from '../../assets/landing/tab/meeting/img/01_graphic.png';
import calendarMainImage from '../../assets/landing/tab/calendar/img/main.png';
import calendarMainBG from '../../assets/landing/tab/calendar/img/02_graphic.png';
import noteMainImage from '../../assets/landing/tab/note/img/main.png';
import noteMainBG from '../../assets/landing/tab/note/img/03_graphic.png';
import mailMainImage from '../../assets/landing/tab/mail/img/main.png';
import mailMainBG from '../../assets/landing/tab/mail/img/01_graphic.png';
import adminMainImage from '../../assets/landing/tab/admin/img/main.png';
import adminMainBG from '../../assets/landing/tab/admin/img/02_graphic.png';
import worksMainImage from '../../assets/landing/tab/works/img/main.png';
import worksMainBG from '../../assets/landing/tab/works/img/01_graphic.png';

import mobileCoworkText from '../../assets/landing/mobile/tab/cowork/main.png';
import mobileCoworkText_En from '../../assets/landingEn/mobile/tab/cowork/main.png';
import mobileCoworkSub from '../../assets/landing/mobile/tab/cowork/sub.png';
import mobileCoworkSub_En from '../../assets/landingEn/mobile/tab/cowork/sub.png';
import mobileMessengerText from '../../assets/landing/mobile/tab/messenger/main.png';
import mobileMessengerText_En from '../../assets/landingEn/mobile/tab/messenger/main.png';
import mobileMessengerSub from '../../assets/landing/mobile/tab/messenger/sub.png';
import mobileMessengerSub_En from '../../assets/landingEn/mobile/tab/messenger/sub.png';

import mobileDriveText from '../../assets/landing/mobile/tab/drive/main.png';
import mobileDriveText_En from '../../assets/landingEn/mobile/tab/drive/main.png';
import mobileDriveSub from '../../assets/landing/mobile/tab/drive/sub.png';
import mobileDriveSub_En from '../../assets/landingEn/mobile/tab/drive/sub.png';

import mobileMeetingText from '../../assets/landing/mobile/tab/meeting/main.png';
import mobileMeetingText_En from '../../assets/landingEn/mobile/tab/meeting/main.png';
import mobileMeetingSub from '../../assets/landing/mobile/tab/meeting/sub.png';
import mobileMeetingSub_En from '../../assets/landingEn/mobile/tab/meeting/sub.png';

import mobileCalendarText from '../../assets/landing/mobile/tab/calendar/main.png';
import mobileCalendarText_En from '../../assets/landingEn/mobile/tab/calendar/main.png';
import mobileCalendarSub from '../../assets/landing/mobile/tab/calendar/sub.png';
import mobileCalendarSub_En from '../../assets/landingEn/mobile/tab/calendar/sub.png';

import mobileNoteText from '../../assets/landing/mobile/tab/note/main.png';
import mobileNoteText_En from '../../assets/landingEn/mobile/tab/note/main.png';
import mobileNoteSub from '../../assets/landing/mobile/tab/note/sub.png';
import mobileNoteSub_En from '../../assets/landingEn/mobile/tab/note/sub.png';

import mobileMailText from '../../assets/landing/mobile/tab/mail/main.png';
import mobileMailText_En from '../../assets/landingEn/mobile/tab/mail/main.png';
import mobileMailSub from '../../assets/landing/mobile/tab/mail/sub.png';
import mobileMailSub_En from '../../assets/landingEn/mobile/tab/mail/sub.png';

import mobileAdminText from '../../assets/landing/mobile/tab/admin/main.png';
import mobileAdminText_En from '../../assets/landingEn/mobile/tab/admin/main.png';
import mobileAdminSub from '../../assets/landing/mobile/tab/admin/sub.png';
import mobileAdminSub_En from '../../assets/landingEn/mobile/tab/admin/sub.png';

import mobileWorksText from '../../assets/landing/mobile/tab/works/main.png';
import mobileWorksText_En from '../../assets/landingEn/mobile/tab/works/main.png';
import mobileWorksSub from '../../assets/landing/mobile/tab/works/sub.png';
import mobileWorksSub_En from '../../assets/landingEn/mobile/tab/works/sub.png';

SwiperCore.use([Pagination, Autoplay]);

export default function ServiceTabSwipeContent(props) {
  const { i18n } = useTranslation('service');
  const { refs, startService } = props;
  useEffect(() => {
    if (startService) {
      window.scrollTo({
        top:
          refs.introRef.current.offsetTop - refs.headerRef.current.clientHeight,
        left: 0,
        behavior: 'smooth',
      });
    }
  }, [startService, refs.introRef, refs.headerRef]);
  return (
    <SwiperWrapper ref={refs.introRef}>
      <Swiper
        pagination={{ clickable: true }}
        loop
        loopedSlides={5}
        style={{ height: '37.13rem' }}
      >
        <SwiperSlide key="slide0" tag="li">
          <CoworkMainImg src={coworkMainImage} />
          <CoworkMainBg src={coworkMainBG} />
          <CoworkTitle
            src={
              i18n.language === 'en' ? mobileCoworkText_En : mobileCoworkText
            }
          />
          <CoworkBoxTxt isen={i18n.language === 'en'}>
            <MobileSubImgWrapper
              src={
                i18n.language === 'en' ? mobileCoworkSub_En : mobileCoworkSub
              }
            />
          </CoworkBoxTxt>
        </SwiperSlide>
        <SwiperSlide key="slide1" tag="li">
          <MessengerMainImg src={messengerMainImage} />
          <MessengerMainBg src={messengerMainBG} />
          <MessengerTitle
            src={
              i18n.language === 'en'
                ? mobileMessengerText_En
                : mobileMessengerText
            }
          />
          <MessengerBoxTxt isen={i18n.language === 'en'}>
            <MobileSubImgWrapper
              src={
                i18n.language === 'en'
                  ? mobileMessengerSub_En
                  : mobileMessengerSub
              }
            />
          </MessengerBoxTxt>
        </SwiperSlide>
        <SwiperSlide key="slide2" tag="li">
          <DriveMainImg src={driveMainImage} />
          <DriveMainBg src={driveMainBG} />
          <DriveTitle
            src={i18n.language === 'en' ? mobileDriveText_En : mobileDriveText}
          />
          <DriveBoxTxt isen={i18n.language === 'en'}>
            <MobileSubImgWrapper
              src={i18n.language === 'en' ? mobileDriveSub_En : mobileDriveSub}
            />
          </DriveBoxTxt>
        </SwiperSlide>
        <SwiperSlide key="slide3" tag="li">
          <MeetingMainImg src={meetingMainImage} />
          <MeetingMainBg src={meetingMainBG} />
          <MeetingTitle
            src={
              i18n.language === 'en' ? mobileMeetingText_En : mobileMeetingText
            }
          />
          <MeetingBoxTxt isen={i18n.language === 'en'}>
            <MobileSubImgWrapper
              src={
                i18n.language === 'en' ? mobileMeetingSub_En : mobileMeetingSub
              }
            />
          </MeetingBoxTxt>
        </SwiperSlide>
        <SwiperSlide key="slide4" tag="li">
          <CalendarMainImg src={calendarMainImage} />
          <CalendarMainBg src={calendarMainBG} />
          <CalendarTitle
            src={
              i18n.language === 'en'
                ? mobileCalendarText_En
                : mobileCalendarText
            }
          />
          <CalendarBoxTxt isen={i18n.language === 'en'}>
            <MobileSubImgWrapper
              src={
                i18n.language === 'en'
                  ? mobileCalendarSub_En
                  : mobileCalendarSub
              }
            />
          </CalendarBoxTxt>
        </SwiperSlide>
        <SwiperSlide key="slide5" tag="li">
          <NoteMainImg src={noteMainImage} />
          <NoteMainBg src={noteMainBG} />
          <NoteTitle
            src={i18n.language === 'en' ? mobileNoteText_En : mobileNoteText}
          />
          <NoteBoxTxt isen={i18n.language === 'en'}>
            <MobileSubImgWrapper
              src={i18n.language === 'en' ? mobileNoteSub_En : mobileNoteSub}
            />
          </NoteBoxTxt>
        </SwiperSlide>
        <SwiperSlide key="slide6" tag="li">
          <MailMainImg src={mailMainImage} />
          <MailMainBg src={mailMainBG} />
          <MailTitle
            src={i18n.language === 'en' ? mobileMailText_En : mobileMailText}
          />
          <MailBoxTxt isen={i18n.language === 'en'}>
            <MobileSubImgWrapper
              src={i18n.language === 'en' ? mobileMailSub_En : mobileMailSub}
            />
          </MailBoxTxt>
        </SwiperSlide>
        <SwiperSlide key="slide7" tag="li">
          <AdminMainImg src={adminMainImage} />
          <AdminMainBg src={adminMainBG} />
          <AdminTitle
            src={i18n.language === 'en' ? mobileAdminText_En : mobileAdminText}
          />
          <AdminBoxTxt isen={i18n.language === 'en'}>
            <MobileSubImgWrapper
              src={i18n.language === 'en' ? mobileAdminSub_En : mobileAdminSub}
            />
          </AdminBoxTxt>
        </SwiperSlide>
        <SwiperSlide key="slide8" tag="li">
          <WorksMainImg src={worksMainImage} />
          <WorksMainBg src={worksMainBG} />
          <WorksTitle
            src={i18n.language === 'en' ? mobileWorksText_En : mobileWorksText}
            alt="workstitle"
            isen={i18n.language === 'en'}
          />
          <WorksBoxTxt isen={i18n.language === 'en'}>
            <MobileSubImgWrapper
              src={i18n.language === 'en' ? mobileWorksSub_En : mobileWorksSub}
              alt="worksimg"
            />
          </WorksBoxTxt>
        </SwiperSlide>
      </Swiper>
    </SwiperWrapper>
  );
}
const CoworkTitle = styled.img`
  position: absolute;
  top: 3.19rem;
  right: 1.44rem;
  font-size: 1rem;
  line-height: 1.5rem;
  color: #205855;
  font-weight: 500;
`;

const CoworkMainImg = styled.img`
  position: absolute;
  top: 13rem;
  width: 19.19rem;
  height: 14.69rem;
  left: 1rem;
  z-index: 1;
`;

const CoworkMainBg = styled.img`
  position: absolute;
  top: 4.44rem;
  right: 2.44rem;
  width: 8.81rem;
  height: 8.44rem;
`;

const CoworkBoxTxt = styled.div`
  position: absolute;
  top: 23.69rem;
  background: rgba(242, 239, 236, 0.65);
  color: #3b3b3b;
  width: calc(100vw - 2rem);
  left: 1rem;
  right: 1rem;
  z-index: 0;
  display: flex;
  justify-content: center;
  ${props =>
    props.isen
      ? css`
          padding-top: 4.13rem;
          padding-bottom: 2.63rem;
        `
      : css`
          padding-top: 4.88rem;
          padding-bottom: 3.13rem;
        `}
`;

const MessengerTitle = styled.img`
  position: absolute;
  top: 3.19rem;
  left: 1.44rem;
  font-size: 1rem;
  line-height: 1.5rem;
  color: #205855;
  font-weight: 500;
`;

const MessengerMainImg = styled.img`
  position: absolute;
  top: 13rem;
  width: 20.19rem;
  height: 14.69rem;
  right: 1rem;
  z-index: 1;
`;

const MessengerMainBg = styled.img`
  position: absolute;
  top: 4.75rem;
  left: 2.13rem;
  width: 10.06rem;
  height: 7.69rem;
`;

const MessengerBoxTxt = styled.div`
  position: absolute;
  top: 23.69rem;
  background: rgba(105, 140, 135, 0.1);
  color: #3b3b3b;
  width: calc(100vw - 2rem);
  left: 1rem;
  right: 1rem;

  z-index: 0;
  display: flex;
  justify-content: center;
  ${props =>
    props.isen
      ? css`
          padding-top: 4.13rem;
          padding-bottom: 3.06rem;
        `
      : css`
          padding-top: 4.88rem;
          padding-bottom: 3.13rem;
        `}
`;
const DriveTitle = styled.img`
  position: absolute;
  top: 3.19rem;
  right: 1.44rem;
  font-size: 1rem;
  line-height: 1.5rem;
  color: #205855;
  font-weight: 500;
`;

const DriveMainImg = styled.img`
  position: absolute;
  top: 13rem;
  width: 18.56rem;
  height: 14.69rem;
  left: 1rem;
  z-index: 1;
`;

const DriveMainBg = styled.img`
  position: absolute;
  top: 4.43rem;
  right: 1.75rem;
  width: 8.13rem;
  height: 8.44rem;
`;

const DriveBoxTxt = styled.div`
  position: absolute;
  top: 23.69rem;
  color: #3b3b3b;
  background: rgba(226, 146, 102, 0.07);
  width: calc(100vw - 2rem);
  left: 1rem;
  right: 1rem;

  z-index: 0;
  display: flex;
  justify-content: center;
  ${props =>
    props.isen
      ? css`
          padding-top: 4.14rem;
          padding-bottom: 1.26rem;
        `
      : css`
          padding-top: 4.75rem;
          padding-bottom: 3.13rem;
        `}
`;

const MeetingTitle = styled.img`
  position: absolute;
  top: 3.19rem;
  right: 1.44rem;
  font-size: 1rem;
  line-height: 1.5rem;
  color: #205855;
  font-weight: 500;
`;

const MeetingMainImg = styled.img`
  position: absolute;
  top: 13rem;
  height: 14.69rem;
  z-index: 1;
`;

const MeetingMainBg = styled.img`
  position: absolute;
  top: 4.44rem;
  right: 2.44rem;
  width: 8.81rem;
  height: 8.44rem;
`;

const MeetingBoxTxt = styled.div`
  position: absolute;
  top: 23.69rem;
  color: #3b3b3b;
  background: rgba(242, 239, 236, 0.65);
  width: calc(100vw - 2rem);
  left: 1rem;
  right: 1rem;

  z-index: 0;
  display: flex;
  justify-content: center;
  ${props =>
    props.isen
      ? css`
          padding-top: 4.13rem;
          padding-bottom: 2.63rem;
        `
      : css`
          padding-top: 4.75rem;
          padding-bottom: 3.19rem;
        `}
`;

const CalendarTitle = styled.img`
  position: absolute;
  top: 3.19rem;
  left: 1.44rem;
  font-size: 1rem;
  line-height: 1.5rem;
  color: #205855;
  font-weight: 500;
`;

const CalendarMainImg = styled.img`
  position: absolute;
  top: 13rem;
  width: 20.38rem;
  height: 14.69rem;
  right: 1rem;
  z-index: 1;
`;

const CalendarMainBg = styled.img`
  position: absolute;
  top: 4.75rem;
  left: 2.13rem;
  width: 10.06rem;
  height: 7.69rem;
`;

const CalendarBoxTxt = styled.div`
  position: absolute;
  top: 23.69rem;
  color: #3b3b3b;
  background: rgba(105, 140, 135, 0.1);
  width: calc(100vw - 2rem);
  left: 1rem;
  right: 1rem;

  z-index: 0;
  display: flex;
  justify-content: center;
  ${props =>
    props.isen
      ? css`
          padding-top: 4.25rem;
          padding-bottom: 3.81rem;
        `
      : css`
          padding-top: 4.88rem;
          padding-bottom: 3.13rem;
        `}
`;
const NoteTitle = styled.img`
  position: absolute;
  top: 3.19rem;
  right: 1.44rem;
  font-size: 1rem;
  line-height: 1.5rem;
  color: #205855;
  font-weight: 500;
`;

const NoteMainImg = styled.img`
  position: absolute;
  top: 12.99rem;
  width: 20.5rem;
  height: 14.69rem;
  left: 1rem;
  z-index: 1;
`;

const NoteMainBg = styled.img`
  position: absolute;
  top: 4.43rem;
  right: 1.75rem;
  width: 8.13rem;
  height: 8.44rem;
`;

const NoteBoxTxt = styled.div`
  position: absolute;
  top: 23.69rem;
  color: #3b3b3b;
  background: rgba(238, 146, 102, 0.07);
  width: calc(100vw - 2rem);
  left: 1rem;
  right: 1rem;

  z-index: 0;
  display: flex;
  justify-content: center;
  ${props =>
    props.isen
      ? css`
          padding-top: 4.13rem;
          padding-bottom: 2.56rem;
        `
      : css`
          padding-top: 4.75rem;
          padding-bottom: 3.13rem;
        `}
`;

const MailTitle = styled.img`
  position: absolute;
  top: 3.19rem;
  right: 1.44rem;
  font-size: 1rem;
  line-height: 1.5rem;
  color: #205855;
  font-weight: 500;
`;

const MailMainImg = styled.img`
  position: absolute;
  top: 13.83rem;
  width: 18.69rem;
  height: 13.25rem;
  left: 1rem;
  z-index: 1;
`;

const MailMainBg = styled.img`
  position: absolute;
  top: 4.43rem;
  right: 2.44em;
  width: 8.81rem;
  height: 8.44rem;
`;

const MailBoxTxt = styled.div`
  position: absolute;
  top: 23.69rem;
  color: #3b3b3b;
  background: rgba(242, 239, 236, 0.65);
  width: calc(100vw - 2rem);
  left: 1rem;
  right: 1rem;

  z-index: 0;
  display: flex;
  justify-content: center;
  ${props =>
    props.isen
      ? css`
          padding-top: 4.75rem;
          padding-bottom: 3.19rem;
        `
      : css`
          padding-top: 4.75rem;
          padding-bottom: 3.13rem;
        `}
`;

const AdminTitle = styled.img`
  position: absolute;
  top: 3.19rem;
  left: 1.44rem;
  font-size: 1rem;
  line-height: 1.5rem;
  color: #205855;
  font-weight: 500;
`;

const AdminMainImg = styled.img`
  position: absolute;
  top: 13rem;
  width: 20.38rem;
  height: 14.69rem;
  right: 1rem;
  z-index: 1;
`;

const AdminMainBg = styled.img`
  position: absolute;
  top: 4.75rem;
  left: 2.13rem;
  width: 10.06rem;
  height: 7.69rem;
`;

const AdminBoxTxt = styled.div`
  position: absolute;
  top: 23.69rem;
  color: #3b3b3b;
  background: rgba(105, 140, 135, 0.1);
  width: calc(100vw - 2rem);
  left: 1rem;
  right: 1rem;

  z-index: 0;
  display: flex;
  justify-content: center;
  ${props =>
    props.isen
      ? css`
          padding-top: 4.25rem;
          padding-bottom: 2.56rem;
        `
      : css`
          padding-top: 4.88rem;
          padding-bottom: 3.19rem;
        `}
`;

const WorksTitle = styled.img`
  position: absolute;

  top: 3.19rem;
  right: 1.44rem;
  font-size: 1rem;
  line-height: 1.5rem;
  color: #205855;
  font-weight: 500;
  ${props =>
    props.isen
      ? css`
          width: 14.313rem;
          height: 8.75rem;
        `
      : css`
          width: 18.625rem;
          height: 7.8125rem;
        `}
`;

const WorksMainImg = styled.img`
  position: absolute;
  top: 13rem;
  width: 19.875rem;
  height: 14.75rem;
  left: 1rem;
  z-index: 1;
`;

const WorksMainBg = styled.img`
  position: absolute;
  top: 4.43rem;
  right: 1.75rem;
  width: 8.13rem;
  height: 8.44rem;
`;

const WorksBoxTxt = styled.div`
  position: absolute;
  top: 23.69rem;
  color: #3b3b3b;
  background: rgba(226, 146, 102, 0.07);
  width: calc(100vw - 2rem);
  left: 1rem;
  right: 1rem;

  z-index: 0;
  display: flex;
  justify-content: center;
  ${props =>
    props.isen
      ? css`
          padding-top: 4.14rem;
          padding-bottom: 3.86rem;
        `
      : css`
          padding-top: 4.75rem;
          padding-bottom: 3.13rem;
        `}
`;

const SwiperWrapper = styled.div`
  margin-top: 0;
  height: 100%;
  & .swiper-pagination {
    margin-bottom: 1.5rem;
  }
  & .swiper-pagination-bullet-active {
    background-color: #205855;
  }
`;

const MobileSubImgWrapper = styled.img`
  ${props =>
    props.alt === 'worksimg' &&
    css`
      width: 17.813rem;
      height: 4.563rem;
    `}
`;

// @flow
/* eslint-disable class-methods-use-this */
import { ComAPI, hsmAPI } from 'teespace-core';
import {
  convertFromDtoItemToServiceItem,
  convertFromServiceItemToDtoItem,
} from '../models/ServiceModel';

export const convertFromDtoObjToServiceObj = (
  dtoObj: $Shape<ServiceInfoDto>,
): $Shape<ServiceInfo> => {
  const result: $Shape<ServiceInfo> = {};

  Object.keys(dtoObj).forEach((key: string) => {
    if (
      {}.hasOwnProperty.call(dtoObj, key) &&
      typeof dtoObj[key] !== 'undefined'
    ) {
      Object.assign(
        result,
        convertFromDtoItemToServiceItem({ [key]: dtoObj[key] }),
      );
    }
  });
  return result;
};

export const convertFromServiceObjToDtoObj = (
  dtoObj: $Shape<ServiceInfo>,
): $Shape<ServiceInfoDto> => {
  const result: $Shape<ServiceInfoDto> = {};

  Object.keys(dtoObj).forEach((key: string) => {
    if (
      {}.hasOwnProperty.call(dtoObj, key) &&
      typeof dtoObj[key] !== 'undefined'
    ) {
      Object.assign(
        result,
        convertFromServiceItemToDtoItem({ [key]: dtoObj[key] }),
      );
    }
  });
  return result;
};

class ServiceRepository {
  async createCorps({ corpsInfo }: { corpsInfo: Object }): Promise<boolean> {
    const queryObj = {
      dto: {
        USER_NAME: corpsInfo.name,
        USER_EMAIL: corpsInfo.email,
        USER_PHONE: corpsInfo.mobile,
        AVAILABLE_TIME: corpsInfo.time,
        CORP_NAME: corpsInfo.corpsName,
        CORP_RANK: corpsInfo.corpsRank,
        CORP_TYPE: corpsInfo.corpsType,
        CORP_NUMBER: corpsInfo.corpsNumber,
        CORP_COMMENT: corpsInfo.corpsComment,
        USER_ETC: corpsInfo.corpsEtc,
        TERM_OPTIONAL_DATA_YN: corpsInfo.optional,
      },
    };
    try {
      const response = await ComAPI.post(`common-api/corps`, queryObj);
      if (response.data.dto.USE_CODE === 'RST0001') {
        return true;
      }
      return false;
    } catch (error) {
      throw Error(JSON.stringify(error.response.data.exception));
    }
  }

  async getBlogContents() {
    try {
      const response = await ComAPI.get(`common-api/blog-contents`);

      if (response.data.dto.contents) {
        return response.data.dto.contents;
      }
    } catch (error) {
      throw Error(JSON.stringify(error.response.data.exception));
    }
  }

  // 공지관련
  async getActiveNotice() {
    try {
      const response = await hsmAPI.get(`superadmin-api/notice/on`);

      return {
        noticeList: response.data.dto.noticeList
          ? response.data.dto.noticeList.map(domainDto =>
              convertFromDtoObjToServiceObj(domainDto),
            )
          : [],
      };
    } catch (error) {
      throw Error(JSON.stringify(error.response.data.exception));
    }
  }
}

export default new ServiceRepository();

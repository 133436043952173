import React, { useState, useCallback } from 'react';
import { useTranslation } from 'react-i18next';
import styled from 'styled-components';
import { Button } from 'antd';
import { useKeycloak } from '@react-keycloak/web';
import { Form, Input, useCoreStores } from 'teespace-core';

import { checkNameValid } from '../../libs/Regex';

function AccountNameFormModal({
  defaultValue,
  onCancel,
  onSave,
  getPopupContainer,
}) {
  const { waplUserStore } = useCoreStores();
  const { keycloak } = useKeycloak();
  const [changed, setChanged] = useState(false);
  const { t } = useTranslation('common');

  const handleInputChange = useCallback(() => setChanged(true), []);
  const handleChangeName = useCallback(
    async values => {
      const { name } = values;
      const success = await waplUserStore.updateName({
        name,
      });
      if (success) {
        await keycloak.updateToken(50000);
        onSave();
      }
    },
    [onSave, waplUserStore, keycloak],
  );
  return (
    <Wrapper>
      <AccountInformationTitle>
        {t('CM_EDIT_MYPAGE_02')}
      </AccountInformationTitle>
      <StyledForm
        onFinish={handleChangeName}
        onValuesChange={handleInputChange}
        initialValues={{ name: defaultValue }}
      >
        <InputWrapper>
          <StyledFormItem
            name="name"
            noStyle
            validateTrigger={['onBlur']}
            rules={[
              {
                required: true,
                message: '이름을 입력해주세요',
                validateTrigger: 'onBlur',
              },

              {
                validateTrigger: 'onBlur',
                validator: (_, value) => {
                  if (!checkNameValid(value || '')) {
                    // eslint-disable-next-line prefer-promise-reject-errors
                    return Promise.reject(
                      '이름은 특수문자를 포함할 수 없으며 50자를 초과할 수 없습니다.',
                    );
                  }
                  return Promise.resolve();
                },
              },
            ]}
          >
            <StyledInput
              maxLength={50}
              getPopupContainer={getPopupContainer}
              placement="topRight"
            />
          </StyledFormItem>
        </InputWrapper>
        <StyledFormItem>
          <Button type="solid" htmlType="submit" disabled={!changed}>
            {t('CM_SAVE')}
          </Button>
        </StyledFormItem>
        <Button onClick={onCancel}>{t('CM_CANCEL')}</Button>
      </StyledForm>
    </Wrapper>
  );
}

export default AccountNameFormModal;

const Wrapper = styled.div`
  width: 100%;
  display: flex;
  flex-direction: row;
  align-items: center;
`;
const AccountInformationTitle = styled.div`
  font-size: 0.813rem;
  line-height: 1.188rem;
  min-width: 13.063rem;
  color: ${props => props.theme.TextSub};
`;

const StyledForm = styled(Form)`
  display: flex;
  flex: 0.75;
  flex-direction: row;

  button {
    min-width: 3.125rem !important;
    padding-left: 0.84375rem !important;
    padding-right: 0.84375rem !important;
    margin-left: 0.5rem;
    &[type='submit'] {
      margin-left: 0.94rem;
    }
  }
`;

const InputWrapper = styled.div`
  flex: 1;
`;

const StyledInput = styled(Input)`
  width: 12.5rem;
`;

const StyledFormItem = styled(Form.Item)`
  margin-bottom: 0;
`;

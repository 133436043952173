import React from 'react';
import styled from 'styled-components';
import InviteCodeContent from '../components/firstlogin/InviteCodeContent';
import InviteCodeHeader from '../components/firstlogin/InviteCodeHeader';
import TermsFooter from '../components/TermsFooter';

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  min-height: 100%;
`;
const Content = styled.div`
  display: flex;
  align-items: center;
  flex-direction: column;
  justify-content: center;
  flex-grow: 1;
`;

function InviteCodePage() {
  return (
    <Wrapper>
      <InviteCodeHeader />
      <Content>
        <InviteCodeContent />
      </Content>
      <TermsFooter />
    </Wrapper>
  );
}

export default InviteCodePage;

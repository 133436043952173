import React, { useEffect, useRef, useCallback } from 'react';
import { useTranslation } from 'react-i18next';
import styled, { css } from 'styled-components';
import { isMobile } from 'react-device-detect';
import waplLogoText from '../../assets/logo_Wapl_text.svg';
import arrowDownLineImg from '../../assets/arrow_down_line.svg';
import arrowDownLineWhiteImg from '../../assets/arrow_bottom_1_white.svg';
import Table from './TableTemplate2';

const PagePolicy = styled.div`
  ${props =>
    props.ismobile
      ? css`
          width: 100%;
          display: flex;
          flex-direction: column;
          overflow-x: hidden;
        `
      : css`
          width: 100%;
          display: flex;
          flex-direction: column;
        `}
`;

const PageHeader = styled.div`
  ${props =>
    props.ismobile
      ? css`
          background-color: #0a1d41;
          height: 4.69rem;
          color: #fff;
          display: flex;
          flex-shrink: 0;
          flex-flow: row wrap;
        `
      : css`
          background-color: #0a1d41;
          height: 5rem;
          color: #fff;
          display: flex;
          align-items: center;
          flex-shrink: 0;
        `}
`;

const PageHeaderImg = styled.img`
  ${props =>
    props.ismobile
      ? css`
          height: 1rem;
        `
      : css`
          width: 4.38rem;
          height: 100%;
          margin-right: 0.63rem;
        `}
`;

const PageHeaderLine = styled.h1`
  ${props =>
    props.ismobile
      ? css`
          font-size: 16px;
          color: #ffffff;
          letter-spacing: 0;
        `
      : css`
          margin: 0;
          font-size: 1.5rem;
          color: #ffffff;
          letter-spacing: 0;
          line-height: 2.25rem;
        `}
`;

const PageHeaderInner = styled.div`
  ${props =>
    props.ismobile
      ? css`
          width: 100%;
          height: 100%;
          display: flex;
          align-items: flex-start;
          justify-content: space-between;
        `
      : css`
          width: 59.31rem;
          margin: 0 auto;
          display: flex;
          align-items: center;
        `}
`;

const PageHeaderInnerWrapper = styled.div`
  ${props =>
    props.ismobile
      ? css`
          display: flex;
          flex-direction: column;
          font-size: 1rem;
          color: #ffffff;
          letter-spacing: 0;
          align-items: flex-start;
          padding-left: 1rem;
          padding-top: 1rem;
        `
      : css`
          display: flex;
          flex-direction: row;
          align-items: center;
        `}
`;

const HistoryContainer = styled.div`
  ${props =>
    props.ismobile
      ? css`
          margin-left: 0;
          display: flex;
          flex-direction: column-reverse;
          align-items: center;
          justify-content: space-between;
          font-size: 0.56rem;
          font-weight: 200;
          color: #ffffff;
          letter-spacing: 0;
          padding-top: 1rem;
          padding-right: 1rem;
        `
      : css`
          margin-left: auto;
          font-size: 0.75rem;
          display: flex;
          align-items: center;
        `}
`;

const HistoryContainerSpan = styled.span`
  ${props =>
    props.ismobile
      ? css`
          font-size: 0.56rem;
          font-weight: 200;
          color: #ffffff;
          letter-spacing: 0;
          text-decoration: underline;
        `
      : css`
          font-size: 0.56rem;
          font-weight: 200;
          color: #ffffff;
          letter-spacing: 0;
          text-decoration: underline;
        `}
`;

const HistoryContainerSelect = styled.select`
  cursor: pointer;
  ${props =>
    props.ismobile
      ? props.language === 'en'
        ? css`
            width: 9.75rem;
            height: 1.75rem;
            font-size: 10px;
            color: #ffffff;
            padding-left: 0.75rem;
            margin-left: 0rem;
            margin-bottom: 0.25rem;
            letter-spacing: 0;
            border: 1px solid #d0ccc7;
            border-radius: 4px;
            -webkit-appearance: none;
            -moz-appearance: none;
            appearance: none;
            outline: none;
            background: url(${arrowDownLineWhiteImg}) #0a1d41 no-repeat 95% 50%;
          `
        : css`
            width: 8.75rem;
            height: 1.75rem;
            font-size: 10px;
            color: #ffffff;
            padding-left: 0.75rem;
            margin-left: 0rem;
            margin-bottom: 0.25rem;
            letter-spacing: 0;
            border: 1px solid #d0ccc7;
            border-radius: 4px;
            -webkit-appearance: none;
            -moz-appearance: none;
            appearance: none;
            outline: none;
            background: url(${arrowDownLineWhiteImg}) #0a1d41 no-repeat 95% 50%;
          `
      : props.language === 'en'
        ? css`
          margin-left: 1rem;
          width: 10.38rem;
          height: 1.88rem;
          font-size: 0.75rem;
          color: #3b3b3b;
          padding-left: 0.75rem;
          border: 1px solid #c6ced6;
          border-radius: 1.56rem;
          -webkit-appearance: none;
          -moz-appearance: none;
          appearance: none;
          outline: none;
          background: url(${arrowDownLineImg}) #fff no-repeat 95% 50%;
        `
        : css`
          margin-left: 1rem;
          width: 8.88rem;
          height: 1.88rem;
          font-size: 0.75rem;
          color: #3b3b3b;
          padding-left: 0.75rem;
          border: 1px solid #c6ced6;
          border-radius: 1.56rem;
          -webkit-appearance: none;
          -moz-appearance: none;
          appearance: none;
          outline: none;
          background: url(${arrowDownLineImg}) #fff no-repeat 95% 50%;
        `}
`;

const PageBody = styled.div`
  flex: 1;
  overflow: hidden;
  display: flex;
`;

const PageBodyPre = styled.pre`
  overflow: auto;
  white-space: pre-wrap;
  font-size: 0.75rem;
  font-family: 'NotoSansKR', sans-serif;
  color: #232323;
  ${props =>
    props.ismobile
      ? css`
          margin: 20px;
        `
      : css`
          margin: 0;
        `}
`;

const PageInner = styled.div`
  ${props =>
    props.ismobile
      ? css`
          width: 59.31rem;
          margin: 0 0;
          overflow: hidden;
          padding: 0 auto;
        `
      : css`
          width: 59.31rem;
          margin: 0 auto;
          overflow: hidden;
          margin: 1.38rem auto 1.56rem auto;
        `}
`;

const PageInnerContents = styled.div`
  ${props =>
    props.language === 'en'
      ? css`
          overflow: auto;
          height: auto;
          padding-right: 0.5rem;
        `
      : css`
          overflow: auto;
          height: 100%;
          padding-right: 0.5rem;
        `}
`;

const TableAgree = styled.table`
  width: 100%;
  table-layout: auto;
  border-collapse: collapse;
  border-spacing: 0;
  border: 1px solid #ccc;
  background-color: transparent;
  max-height: inherit;
`;

const TableTh = styled.th`
  height: auto;
  font-size: 0.65rem;
  text-align: center;
  border: solid #ccc;
  border-width: 1px 0 0 1px;
  background-color: ${props => props.theme.LineSub};
`;

const TableTd = styled.td`
  padding: 0.5rem 1rem 0.5rem 1rem;
  border: solid #ccc;
  border-width: 1px 0 0 1px;
  font-size: 0.65rem;
  vertical-align: top;
  line-height: normal;
  ${props =>
    props.highlight &&
    css`
      font-size: 0.8rem;
      font-weight: 500;
      color: red;
    `}
  ${props =>
    props.center
      ? css`
          text-align: center;
        `
      : css`
          text-align: left;
        `}
`;

const CursorSpan = styled.div`
  cursor: pointer;
  width: fit-content;
  background-color: yellow;
`;

const PrivacyPolicy = () => {
  const defaultValue = '2022-04-10';
  const { t, i18n } = useTranslation('service');
  const dropdownRef = useRef([]);

  const setShowContent = Id => {
    document.getElementById(Id).style.display = 'block';
  };
  const setHideContent = Id => {
    document.getElementById(Id).style.display = 'none';
  };

  useEffect(() => {
    for (const date of dropdownRef.current) {
      if (date.value !== defaultValue) {
        document.getElementById(date.value).style.display = 'none';
      }
    }
  }, []);

  const onHandleChangeSelect = e => {
    const k = e.target.value;

    for (const res of dropdownRef.current) {
      res.value !== k ? setHideContent(res.value) : setShowContent(res.value);
    }
  };

  const handleChangeSelectOptionClick = useCallback(date => {
    dropdownRef.current.value = date;
    for (const res of dropdownRef.current) {
      res.value !== date
        ? setHideContent(res.value)
        : setShowContent(res.value);
    }
    window.scrollTo({ top: 0 });
  }, []);

  return (
    <>
      <PagePolicy ismobile={isMobile}>
        <PageHeader ismobile={isMobile}>
          <PageHeaderInner ismobile={isMobile}>
            <PageHeaderInnerWrapper ismobile={isMobile}>
              <PageHeaderImg
                alt="waplLogo"
                src={waplLogoText}
                ismobile={isMobile}
              />
              <PageHeaderLine ismobile={isMobile}>
                {t('SP_PRIVACY_POLICY_01')}
              </PageHeaderLine>
            </PageHeaderInnerWrapper>
            <HistoryContainer ismobile={isMobile}>
              <HistoryContainerSpan ismobile={isMobile}>
                {t('SP_PRIVACY_POLICY_02')}
              </HistoryContainerSpan>
              <HistoryContainerSelect
                defaultValue={defaultValue}
                onChange={onHandleChangeSelect}
                ismobile={isMobile}
                language={i18n.language}
                ref={dropdownRef}
              >
                <option value="2022-04-10">
                  {t('SP_PRIVACY_POLICY_03', { value: '2022.04.10' })}
                </option>
                <option value="2021-11-18">
                  {t('SP_PRIVACY_POLICY_03', { value: '2021.11.18' })}
                </option>
                <option value="2021-02-22">
                  {t('SP_PRIVACY_POLICY_03', { value: '2021.02.22' })}
                </option>
                <option value="2020-12-14">
                  {t('SP_PRIVACY_POLICY_03', { value: '2020.12.14' })}
                </option>
                <option value="2020-11-09">
                  {t('SP_PRIVACY_POLICY_03', { value: '2020.11.09' })}
                </option>
                <option value="2020-06-22">
                  {t('SP_PRIVACY_POLICY_03', { value: '2020.06.22' })}
                </option>
                <option value="2020-05-25">
                  {t('SP_PRIVACY_POLICY_03', { value: '2020.05.25' })}
                </option>
                <option value="2019-08-15">
                  {t('SP_PRIVACY_POLICY_03', { value: '2019.08.15' })}
                </option>
              </HistoryContainerSelect>
            </HistoryContainer>
          </PageHeaderInner>
        </PageHeader>
        <PageBody>
          <PageInner ismobile={isMobile}>
            {i18n.language === 'en' ? (
              <>
                <PageInnerContents id="2022-04-10" language={i18n.language}>
                  <PageBodyPre ismobile={isMobile}>
                    {`
TmaxA&C Co.,Ltd. and TmaxWAPL(the “Company”) places a high importance on your privacy and makes its best efforts to protect personal data that you, data subject, provide to the Company for the purpose of using its services. The Company thus complies with laws and regulations enacted to protect personal information, such as the Act on Promotion of Information and Communication Network Utilization and Information Protection, etc. and Personal Information Protection Act. 

This Privacy Policy is published on the front page of the Company’s website, so that you can easily find and read it at any time.

This Privacy Policy can be updated in compliance with applicable laws and the Company’s internal policies, in case of which the revised version will be released so that you can easily take notice of changes.

`}
                    <h5 style={{ fontWeight: `bold` }}>
                      Purpose of Processing Personal Data
                    </h5>
                    {`
The Company processes your personal information only for the following purposes. In case of any changes to those purposes, the Company will seek your prior consent and take other necessary actions in compliance with Article 18 of the Personal Information Protection Act. 
`}
                    <TableAgree>
                      <thead>
                        <tr>
                          <TableTh>Purpose</TableTh>
                          <TableTh>Description</TableTh>
                        </tr>
                      </thead>
                      <tbody>
                        <tr>
                          <TableTd center>Membership</TableTd>
                          <TableTd>
                            <div>· Providing membership services</div>
                          </TableTd>
                        </tr>
                        <tr>
                          <TableTd center>Customer Inquiry</TableTd>
                          <TableTd>
                            <div>· Responding to customer’s inquiries</div>
                          </TableTd>
                        </tr>
                      </tbody>
                    </TableAgree>
                    <br />
                    <h5 style={{ fontWeight: `bold` }}>
                      Retention Periods for Personal Data
                    </h5>
                    {`
The Company has made it a rule to retain and utilize your personal information for the duration of the service period stipulated in the Section(`}
                    <strong>
                      ‘3 Categories of Collected Data and Methods of Data
                      Collection’
                    </strong>
                    {`Any personal data whose purposes have been fulfilled will be immediately destroyed. However, the Company can retain certain types of information for a certain period of time as required by the applicable laws specified below.

                `}
                    <TableAgree>
                      <thead>
                        <tr>
                          <TableTh>Applicable Law</TableTh>
                          <TableTh>Subject Data</TableTh>
                          <TableTh>Period</TableTh>
                        </tr>
                      </thead>
                      <tbody>
                        <tr>
                          <TableTd center rowSpan="2">
                            Commercial law
                          </TableTd>
                          <TableTd>
                            Extinctive prescription of a commercial claim
                          </TableTd>
                          <TableTd center>5 years</TableTd>
                        </tr>
                        <tr>
                          <TableTd>
                            Critical sales documents including contracts,
                            requests, invoice and so on.
                          </TableTd>
                          <TableTd center>10 years</TableTd>
                        </tr>

                        <tr>
                          <TableTd center rowSpan="4">
                            Act on the consumer protection in electronic
                            commerce, etc.
                          </TableTd>
                          <TableTd>Record on sign/advertisement</TableTd>
                          <TableTd center>6 months</TableTd>
                        </tr>

                        <tr>
                          <TableTd>
                            Record on consumer complaint or dispute resolution
                          </TableTd>
                          <TableTd center>3 years</TableTd>
                        </tr>

                        <tr>
                          <TableTd>
                            Record regarding contract or withdrawal of
                            subscription
                          </TableTd>
                          <TableTd center>5 years</TableTd>
                        </tr>
                        <tr>
                          <TableTd>
                            Record on payment and supply of goods
                          </TableTd>
                          <TableTd center>5 years</TableTd>
                        </tr>

                        <tr>
                          <TableTd center>
                            Act on Use and Protection of Credit Information
                          </TableTd>
                          <TableTd>
                            Record on collection/process, and use of credit
                            information
                          </TableTd>
                          <TableTd center>3 years</TableTd>
                        </tr>

                        <tr>
                          <TableTd center rowSpan="2">
                            Protection of Communications Act
                          </TableTd>
                          <TableTd>
                            Log record of users such as internet/data detecting
                            the place of user connection
                          </TableTd>
                          <TableTd center>3 months</TableTd>
                        </tr>
                        <tr>
                          <TableTd>
                            Other factual records about communications including
                            date, starting/ending time and frequency of use
                          </TableTd>
                          <TableTd center>12 months</TableTd>
                        </tr>
                      </tbody>
                    </TableAgree>
                    <br />
                    <h5 style={{ fontWeight: `bold` }}>
                      Categories of Collected Data and Methods of Data
                      Collection
                    </h5>
                    The Company collects your personal data during the member
                    subscription process or the use of services.
                    <br />
                    <br />
                    <h5 style={{ fontWeight: `bold` }}>
                      Membership subscription
                    </h5>
                    The following personal data are collected during the process
                    of your member subscription.
                    <TableAgree>
                      <thead>
                        <tr>
                          <TableTh>division</TableTh>
                          <TableTh>Items to be collected</TableTh>
                          <TableTh>Collection channel</TableTh>
                          <TableTh>Period of retention</TableTh>
                        </tr>
                      </thead>
                      <tbody>
                        <tr>
                          <TableTd center>Required</TableTd>
                          <TableTd>
                            <div>
                              · User’s personal information ID (email),
                              password, name
                            </div>
                            <div>
                              · Usage history chat transcript data, calendar,
                              memo and other information stored during the use
                              of services
                            </div>
                          </TableTd>
                          <TableTd rowSpan="2">
                            <div>· Company’s website</div>
                            <div>
                              · Download and submission of “Add the entire
                              organizational chart” form
                            </div>
                          </TableTd>
                          <TableTd center rowSpan="2">
                            Until withdrawal of membership
                          </TableTd>
                        </tr>
                        <tr>
                          <TableTd center>Optional</TableTd>
                          <TableTd>
                            <div>
                              · Profile information (company phone number,
                              email),
                            </div>
                            <div>
                              · Personal information (date of birth,
                              company/department, position/title), profile
                              photo, friends list
                            </div>
                            <div>
                              · Linked email account information (email address
                              and password)
                            </div>
                          </TableTd>
                        </tr>
                      </tbody>
                    </TableAgree>
                    <br />
                    <br />
                    <h5 style={{ fontWeight: `bold` }}>Customer inquiry</h5>
                    The following personal data are collected during the
                    processing of a customer’s inquiry
                    <Table
                      titles={[
                        'division',
                        'Items to be collected',
                        'Collection channel',
                        'Period of retention',
                      ]}
                      rows={[
                        [
                          { text: 'Required', center: true },
                          {
                            text: '· Name, email address, contact details, available time',
                          },
                          {
                            text: '· Company’s website',
                            rowSpan: 2,
                          },
                          { text: '5 years', rowSpan: 2, center: true },
                        ],
                        [
                          { text: 'Optional', center: true },
                          {
                            text: '· Company or organization’s name, position/title, business type, estimated number of users and other inquiries ',
                          },
                        ],
                      ]}
                    />
                    <br />
                    <br />
                    <h5 style={{ fontWeight: `bold` }}>
                      Information collected automatically during the use of
                      services
                    </h5>
                    {`The following data can be automatically generated and collected during your use of services or Company’s operation.
- User behavior information including types of browser and OS, access history (IP address and connection time) and cookies`}
                    <br />
                    <br />
                    <h5 style={{ fontWeight: `bold` }}>
                      Sharing Personal Data with Third-Parties
                    </h5>
                    {`The Company processes your personal information strictly within the scope of the purposes specified in the Section `}
                    <strong>“1 Purpose of Processing Personal Data”</strong>
                    and may share the personal data with third-parties only in
                    accordance with Article 17 and 18 of the Personal
                    Information Protection Act.
                    <br />
                    <br />
                    <Table
                      titles={[
                        'Third-party',
                        'Use',
                        'Items to be shared',
                        'Period of retention',
                      ]}
                      rows={[
                        [
                          {
                            text: 'TmaxGroup (TmaxA&C, TmaxCloud, TmaxWAPL, TamxTivine, TmaxAI, TmaxEdu , TmaxMetaverse, Tmax AI Development Center, TmaxOS, TmaxOffice,  TmaxSoft, TmaxTibero)',
                          },
                          {
                            text: 'Promotional events, advertisements and marketing',
                          },
                          {
                            text: 'ID (email), name, password, record about subscription or withdrawal of membership',
                          },
                          {
                            text: 'Until withdrawal of membership, unless it is necessary to retain certain types of user data under applicable laws',
                          },
                        ],
                      ]}
                    />
                    <br />
                    <br />
                    <h5 style={{ fontWeight: `bold` }}>
                      Destruction of Personal Data
                    </h5>
                    The Company will immediately destroy any personal data of
                    which the retention period has expired or the purpose has
                    been fulfilled. In a case where it is necessary to retain
                    personal data of which the retention period agreed by the
                    data subject has expired or the purpose has been fulfilled,
                    the Company will transfer the data to another separate
                    database or some other storage systems.
                    <br />
                    <br />
                    The procedure and methods of personal data destruction are
                    as follows:
                    <br />
                    <br />
                    <h5 style={{ fontWeight: `bold` }}>
                      Destruction procedure
                    </h5>
                    The Company isolates data to be destroyed and seeks approval
                    from the personal information protection officer to proceed
                    to destruction.
                    <h5 style={{ fontWeight: `bold` }}>Destruction methods</h5>
                    The Company permanently destroys personal data recorded and
                    stored in an electronic file format to make them
                    unrecoverable. Paper documents containing personal
                    information are shredded or incinerated.
                    <br />
                    <br />
                    <h5 style={{ fontWeight: `bold` }}>
                      Outsourcing Data Processing
                    </h5>
                    {`The Company entrusts the processing of personal data to other specialized companies to conduct services. 
                     
In outsourcing the processing of personal data, the Company clearly specifies instructions about privacy protection, confidentiality obligations, restriction of sharing with third parties, liability for accident, outsourcing period and return/destruction of processed data, all of which must be strictly complied with by the outsourcee so that the entrusted data can be safely and securely processed.                    
                    `}
                    <br />
                    <br />
                    <Table
                      titles={['Outsourcee', 'Outsourced activities']}
                      rows={[
                        [
                          {
                            text: 'TmaxCloud',
                          },
                          {
                            text: 'User authentication and validation, operation and management of integrated membership, operation of SSO membership, operation and management of database system for member information',
                          },
                        ],
                        [
                          {
                            text: 'TmaxSoft',
                          },
                          {
                            text: 'Email advertising service',
                          },
                        ],
                      ]}
                    />
                    <br />
                    <br />
                    <h5 style={{ fontWeight: `bold` }}>
                      Data Subject’s Rights and Obligations
                    </h5>
                    {`You and your legal representative can exercise the following rights concerning your personal data at any time. 
   1) Right of Access
   2) Right to Rectification
   3) Right to Erasure 
   4) Right to Restriction of Processing  
You can exercise those rights by contacting at the number or via the email address specified in the Section `}
                    <strong>
                      ‘10 Personal Information Protection Officer’
                    </strong>{' '}
                    {`, and the Company will immediately process your request.
If you make a request for rectification or erasure of your personal information, the Company will not use or provide the information until it is completely rectified or erased.
You can exercise your rights through your legal representative or a delegate, in case of which a power of attorney written in accordance with the format no. 11 prescribed by the Personal Information Protection Act is required.
You, data subject, must comply with the Personal Information Protection Act and other relevant laws and regulations, and thus cannot breach another person’s privacy of data nor your own data being processed by the Company.
`}
                    <br />
                    <br />
                    <h5 style={{ fontWeight: `bold` }}>
                      Automatic Data Collection Tools
                    </h5>
                    <h5 style={{ fontWeight: `bold` }}>
                      {`  `}Data to be automatically collected
                    </h5>
                    The Company collects data on types of browser and OS, access
                    history (IP address, connection time…) and cookies to
                    understand user behavior and thus provide better services.
                    <br />
                    <br />
                    <h5 style={{ fontWeight: `bold` }}>
                      {`  `}Collection and use of cookies
                    </h5>
                    {`Cookies are small files of information that a website generates and sends to your computer browser (for example Internet Explorer).

) Purpose of cookies
The Company uses cookies to store user preferences in order to enhance your web experience and improve its services.

2) Data subject’s right to install, manage and disable cookies
You have the right to choose whether to install cookies. You are free to disable or delete these cookies.

3) Setting and disabling cookies
You have the right to choose whether to install cookies. Therefore, you can set options about cookies on your web browser to accept all cookies, check cookies each time they are stored, or reject all cookies.
If you disable all cookies, however, you will not be able to enjoy the full functionality of the website.

– How to set cookies
: Go to the Tools menu and select Internet Options. 
Click on the Privacy tab, and then under Settings, move the slider to set the cookies level as you prefer. 

– How to view cookies 
: Go to the Tools menu and select Internet Options. 
Click on the General tab and select Settings underneath “Browsing history”, and then click on View files to check your cookies. 

– How to reject all cookies 
: Go to the Tools menu and select Internet Options. 
Click on the Privacy tab, and then under Settings, move the slider to the top to “block all cookies”. `}
                    <br />
                    <br />
                    <h5 style={{ fontWeight: `bold` }}>
                      Security Measures to Ensure Privacy Protection
                    </h5>
                    The Company implements the following
                    administrative/technical/physical measures to prevent any
                    loss, theft, leakage, alteration or destruction of personal
                    data.
                    <br />
                    <br />
                    <h5 style={{ fontWeight: `bold` }}>
                      {`  `}Administrative measures
                    </h5>
                    Establishing and implementing internal management plan to
                    handle personal data, minimizing the number of persons who
                    handle personal data and conducting privacy education
                    programs.
                    <br />
                    <br />
                    <h5 style={{ fontWeight: `bold` }}>
                      {`  `}Technical measure
                    </h5>
                    Controlling authorization over access to the personal data
                    processing system, restricting access to personal data,
                    encrypting critical information (for example password) and
                    installing security programs
                    <br />
                    <br />
                    <h5 style={{ fontWeight: `bold` }}>
                      {`  `}Physical measure
                    </h5>
                    Restricting access to the server room and data storage
                    system.
                    <br />
                    <br />
                    <h5 style={{ fontWeight: `bold` }}>
                      Personal Information Protection Officer
                    </h5>
                    {`The following persons and department are responsible for protecting your personal data and handling privacy-related concerns.

Chief Privacy Officer: Yongchae Noh, CFO
Responsible department: TmaxA&C Security Team
Privacy Associate: Jeongyoon Lee, Manager
Telephone: 031) 8018-9494
Email: security@tmax.co.kr

Any privacy-related inquiries or complaints arising during your use of the Company’s services can be addressed to the responsible persons and department specified above. The Company will promptly and satisfactorily respond to your concern.
`}
                    <br />
                    <br />
                    <h5 style={{ fontWeight: `bold` }}>
                      Reporting a Privacy Breach
                    </h5>
                    {`If you have any concern about a breach of your personal data, please contact the following competent institutions to seek a resolution.

· Personal Information Dispute Mediation Committee (`}
                    <a
                      href="https://www.kopico.go.kr"
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      www.kopico.go.kr
                    </a>
                    {` / Call at 1833-6972)
· Personal Information Infringement Report Center (`}
                    <a
                      href="https://privacy.kisa.or.kr"
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      privacy.kisa.or.kr
                    </a>
                    {` / Call at 118)
· Cybercrime Investigation Division of Supreme Prosecutor’s Office (`}
                    <a
                      href="https://www.spo.go.kr"
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      www.spo.go.kr
                    </a>
                    {` / Call at 1301)
· Korean National Police Agency Cyber Bureau (`}
                    <a
                      href="https://cyberbureau.police.go.kr"
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      cyberbureau.police.go.kr
                    </a>
                    {` / Call at 182)
`}
                    <br />
                    <br />
                    <h5 style={{ fontWeight: `bold` }}>
                      Updates to Privacy Policy
                    </h5>
                    1. This Privacy Policy takes effect on 10 Apr, 2022
                    <br />
                    <CursorSpan
                      onClick={() =>
                        handleChangeSelectOptionClick('2021-11-18')
                      }
                    >
                      - 18 Nov 2021 ~ 10 Apr 2022 Privacy Policy (Click Here)
                    </CursorSpan>
                    <CursorSpan
                      onClick={() =>
                        handleChangeSelectOptionClick('2021-02-22')
                      }
                    >
                      - 22 Feb 2021 ~ 18 Nov 2021 Privacy Policy (Click Here)
                    </CursorSpan>
                    <CursorSpan
                      onClick={() =>
                        handleChangeSelectOptionClick('2020-12-14')
                      }
                    >
                      - 14 Dec 2020 ~ 22 Feb 2021 Privacy Policy (Click Here)
                    </CursorSpan>
                    <CursorSpan
                      onClick={() =>
                        handleChangeSelectOptionClick('2020-11-09')
                      }
                    >
                      - 09 Nov 2020 ~ 14 Dec 2020 Privacy Policy (Click Here)
                    </CursorSpan>
                    <CursorSpan
                      onClick={() =>
                        handleChangeSelectOptionClick('2020-06-22')
                      }
                    >
                      - 22 Jun 2020 ~ 09 Nov 2020 Privacy Policy (Click Here)
                    </CursorSpan>
                    <CursorSpan
                      onClick={() =>
                        handleChangeSelectOptionClick('2020-05-25')
                      }
                    >
                      - 25 May 2020 ~ 22 Jun 2020 Privacy Policy (Click Here)
                    </CursorSpan>
                    <CursorSpan
                      onClick={() =>
                        handleChangeSelectOptionClick('2019-08-15')
                      }
                    >
                      - 15 Aug 2019 ~ 25 May 2020 Privacy Policy (Click Here)
                    </CursorSpan>
                  </PageBodyPre>
                </PageInnerContents>
              </>
            ) : (
              <>
                <PageInnerContents id="2022-04-10">
                  <PageBodyPre ismobile={isMobile}>
                    {`
티맥스에이엔씨 및 티맥스와플(이하 ‘회사’)은 정보주체의 개인정보 보호를 매우 중요하게 생각하며, 정보주체가 회사의 서비스를 이용하기 위해 회사에 제공한 개인정보 보호에 최선을 다하고 있습니다. 이에 회사는 "정보통신망 이용촉진 및 정보보호 등에 관한 법률" 및 "개인정보 보호법" 등 개인정보 보호와 관련된 법령을 준수하고 있습니다.
  
회사는 본 개인정보처리방침을 사이트 첫 화면에 공개함으로써 정보주체가 언제든지 쉽게 확인할 수 있도록 하고 있습니다.
  
본 개인정보처리방침은 관계 법령 및 회사의 내부 방침에 따라 변경될 수 있으며, 개정 시 버전 관리를 통하여 개정사항을 쉽게 확인할 수 있도록 하고 있습니다.
  
  `}
                    <h5 style={{ fontWeight: `bold` }}>개인정보의 처리목적</h5>
                    {`
회사는 다음의 목적으로 정보주체의 개인정보를 처리합니다. 처리하고 있는 개인정보는 다음의 목적 이외의 용도로는 이용되지 않으며, 이용 목적이 변경되는 경우에는 개인정보 보호법 제18조에 따라 별도의 동의를 받는 등 필요한 조치를 이행할 예정입니다.
   
  `}
                    <TableAgree>
                      <thead>
                        <tr>
                          <TableTh>목적</TableTh>
                          <TableTh>자세한 내용</TableTh>
                        </tr>
                      </thead>
                      <tbody>
                        <tr>
                          <TableTd center>회원 가입</TableTd>
                          <TableTd>
                            <div>· 회원 가입 서비스 이용</div>
                          </TableTd>
                        </tr>
                        <tr>
                          <TableTd center>고객 문의</TableTd>
                          <TableTd>
                            <div>· 고객의 문의사항에 대한 대응</div>
                          </TableTd>
                        </tr>
                      </tbody>
                    </TableAgree>
                    <br />
                    <h5 style={{ fontWeight: `bold` }}>
                      개인정보의 보유 및 이용기간
                    </h5>
                    {`
원칙적으로, 회사는 개인정보 수집 및 이용목적이 달성된 후(`}
                    <strong>‘3. 수집하는 개인정보 항목 및 수집방법’</strong>
                    {`에서 명시된 보유기간)에는 해당 정보를 지체 없이 파기합니다. 단, 관계법령의 규정에 의하여 보존할 필요가 있는 경우, 회사는 아래와 같이 관계법령에서 정한 일정한 기간 동안 회원정보를 보관합니다.
                  `}
                    <TableAgree>
                      <thead>
                        <tr>
                          <TableTh>법적 근거</TableTh>
                          <TableTh>보유 항목</TableTh>
                          <TableTh>보유기간</TableTh>
                        </tr>
                      </thead>
                      <tbody>
                        <tr>
                          <TableTd center rowSpan="2">
                            상법
                          </TableTd>
                          <TableTd>상사채권 소멸시효</TableTd>
                          <TableTd center>5년</TableTd>
                        </tr>
                        <tr>
                          <TableTd>
                            계약서, 신청서, 청구서 등 영업 관련 중요 서류
                          </TableTd>
                          <TableTd center>10년</TableTd>
                        </tr>

                        <tr>
                          <TableTd center rowSpan="4">
                            전자상거래 등에서의 소비자보호에 관한 법률
                          </TableTd>
                          <TableTd>표시/광고에 관한 기록</TableTd>
                          <TableTd center>6개월</TableTd>
                        </tr>

                        <tr>
                          <TableTd>
                            소비자의 불만 또는 분쟁처리에 관한 기록
                          </TableTd>
                          <TableTd center>3년</TableTd>
                        </tr>

                        <tr>
                          <TableTd>계약 또는 청약철회 등에 관련 기록</TableTd>
                          <TableTd center>5년</TableTd>
                        </tr>
                        <tr>
                          <TableTd>
                            대금결제 및 재화 등의 공급에 관한 기록
                          </TableTd>
                          <TableTd center>5년</TableTd>
                        </tr>

                        <tr>
                          <TableTd center>
                            신용정보의 이용 및 보호에 관한 법률
                          </TableTd>
                          <TableTd>
                            신용정보의 수집/처리 및 이용 등에 관한 기록
                          </TableTd>
                          <TableTd center>3년</TableTd>
                        </tr>

                        <tr>
                          <TableTd center rowSpan="2">
                            통신비밀보호법
                          </TableTd>
                          <TableTd>
                            로그기록자료, 접속지의 추적자료 등 통신사실확인자료
                          </TableTd>
                          <TableTd center>3개월</TableTd>
                        </tr>
                        <tr>
                          <TableTd>
                            전기통신일시, 전기통신개시ㆍ종료시간, 사용도수 등
                            통신사실확인자료
                          </TableTd>
                          <TableTd center>12개월</TableTd>
                        </tr>
                      </tbody>
                    </TableAgree>
                    <br />
                    <h5 style={{ fontWeight: `bold` }}>
                      수집하는 개인정보 항목 및 수집방법
                    </h5>
                    회사는 회원가입 또는 서비스 이용 시 정보주체로부터 아래와
                    같은 개인정보를 수집하고 있습니다.
                    <br />
                    <br />
                    <h5 style={{ fontWeight: `bold` }}>회원 가입</h5>
                    회원 가입에 관하여 다음과 같은 개인정보를 수집합니다.
                    <TableAgree>
                      <thead>
                        <tr>
                          <TableTh>구분</TableTh>
                          <TableTh>수집 항목</TableTh>
                          <TableTh>수집 방법</TableTh>
                          <TableTh>보유 기간</TableTh>
                        </tr>
                      </thead>
                      <tbody>
                        <tr>
                          <TableTd center>필수항목</TableTd>
                          <TableTd>
                            <div>
                              · 고객 개인정보 아이디(이메일), 비밀번호, 성명
                            </div>
                            <div>
                              · 이용 내역 대화내역, 캘린더, 메모 등 서비스
                              이용시 입력되는 정보
                            </div>
                          </TableTd>
                          <TableTd rowSpan="2">
                            <div>· 회원가입 시, 홈페이지를 통해 수집</div>
                            <div>
                              · 홈페이지 내 조직도 일괄 추가 양식 다운로드 및
                              등록을 통한 수집
                            </div>
                          </TableTd>
                          <TableTd center rowSpan="2">
                            회원 탈퇴 시 까지
                          </TableTd>
                        </tr>
                        <tr>
                          <TableTd center>선택항목</TableTd>
                          <TableTd>
                            <div>· 프로필정보(회사 전화, 이메일),</div>
                            <div>
                              · 개인정보(생년월일, 소속회사/부서, 직위/직책),
                              프로필 사진, 친구목록
                            </div>
                            <div>
                              · 이메일 연동정보(이메일, 이메일 비밀번호)
                            </div>
                          </TableTd>
                        </tr>
                      </tbody>
                    </TableAgree>
                    <br />
                    <br />
                    <h5 style={{ fontWeight: `bold` }}>고객 문의</h5>
                    고객 문의에 관하여 다음과 같은 개인정보를 수집합니다.
                    <Table
                      titles={['구분', '수집 항목', '수집 방법', '보유 기간']}
                      rows={[
                        [
                          { text: '필수항목', center: true },
                          {
                            text: '· 이름, 이메일 주소, 연락처, 연락 가능 시간',
                          },
                          {
                            text: '· 고객 문의 시, 홈페이지를 통해 수집',
                            rowSpan: 2,
                          },
                          { text: '5년', rowSpan: 2, center: true },
                        ],
                        [
                          { text: '선택항목', center: true },
                          {
                            text: '· 회사 또는 단체명, 직책, 업종 정보, 사용 예상 인원, 기타 문의사항 ',
                          },
                        ],
                      ]}
                    />
                    <br />
                    <br />
                    <h5 style={{ fontWeight: `bold` }}>
                      서비스 이용 중 자동으로 수집되는 정보
                    </h5>
                    {`서비스 이용과정이나 사업처리 과정에서 아래와 같은 정보들이 자동으로 생성되어 수집 될 수 있습니다.
  - 브라우저 종류 및 OS, 방문기록(IP Address, 접속시간), 쿠키(cookie) 등을 통해 수집되는 행태 정보`}
                    <br />
                    <br />
                    <h5 style={{ fontWeight: `bold` }}>
                      개인정보의 제3자 제공
                    </h5>
                    {`회사는 정보주체의 개인정보를 `}
                    <strong>“1. 개인정보의 처리목적”</strong>
                    에서 명시한 범위 내에서만 처리하며, 개인정보 보호법 제17조
                    및 제18조에 해당하는 경우에만 개인정보를 제3자에게
                    제공합니다.
                    <br />
                    <br />
                    <Table
                      titles={[
                        '제공받는 자',
                        '제공 목적',
                        '제공하는 개인정보',
                        '보유 및 이용기간',
                      ]}
                      rows={[
                        [
                          {
                            text: '티맥스 그룹 (티맥스 에이엔씨, 티맥스 클라우드, 티맥스 와플, 티맥스 티바인, 티맥스 에이아이, 티맥스 에듀, 티맥스 메타버스, 티맥스 인공지능 개발센터, 티맥스 오에스, 티맥스 오피스, 티맥스 소프트, 티맥스 티베로)',
                          },
                          {
                            text: '이벤트 정보, 광고성 정보 제공 등 마케팅, 이벤트 및 프로모션의 목적',
                          },
                          {
                            text: '아이디(이메일), 이름, 비밀번호, 회원 가입 및 탈퇴 현황',
                          },
                          {
                            text: '관계 법령의 규정에 따라 이용자의 개인정보를 보존할 의무가 있는 경우가 아닌 한, 회원탈퇴 시까지',
                          },
                        ],
                      ]}
                    />
                    <br />
                    <br />
                    <h5 style={{ fontWeight: `bold` }}>개인정보의 파기</h5>
                    회사 개인정보 보유기간의 경과, 처리목적 달성 등 개인정보가
                    불필요하게 되었을 때에는 지체없이 해당 개인정보를
                    파기합니다. 정보주체로부터 동의받은 개인정보 보유기간이
                    경과하거나 처리목적이 달성되었음에도 불구하고 다른 법령에
                    따라 개인정보를 계속 보존하여야 하는 경우에는, 해당
                    개인정보를 별도의 데이터베이스(DB)로 옮기거나 보관장소를
                    달리하여 보존합니다.
                    <br />
                    <br />
                    개인정보 파기의 절차 및 방법은 다음과 같습니다.
                    <br />
                    <br />
                    <h5 style={{ fontWeight: `bold` }}>파기절차</h5>
                    회사는 파기 사유가 발생한 개인정보를 선정하고, 회사의
                    개인정보보호책임자의 승인을 받아 개인정보를 파기합니다.
                    <h5 style={{ fontWeight: `bold` }}>파기방법 </h5>
                    회사는 전자적 파일 형태로 기록ㆍ저장된 개인정보는 기록을
                    재생할 수 없도록 파기하며, 종이 문서에 기록․저장된
                    개인정보는 분쇄기로 분쇄하거나 소각하여 파기합니다.
                    <br />
                    <br />
                    <h5 style={{ fontWeight: `bold` }}>개인정보처리의 위탁</h5>
                    {`회사는 서비스 이행을 위해 아래와 같이 개인정보 처리 업무를 외부 전문업체에 위탁하여 처리하고 있습니다.
  
  개인정보의 처리를 위탁하는 경우에는 개인정보 보호의 안전을 기하기 위하여 개인정보보호 관련 지시엄수, 개인정보에 대한 비밀유지, 제3자 제공의 제한 및 사고시의 책임부담, 위탁기간, 처리 종료 후의 개인정보의 반환 또는 파기 등을 명확히 규정하고, 위탁업체가 개인정보를 안전하게 처리하도록 감독합니다.
  `}
                    <br />
                    <br />
                    <Table
                      titles={['수탁업체', '위탁하는 업무의 내용']}
                      rows={[
                        [
                          {
                            text: '티맥스 클라우드',
                          },
                          {
                            text: '본인 인증 및 확인, 통합회원제 운영 및 관리, SSO 회원제 운영, 회원 정보 DB시스템 운영/관리 업무',
                          },
                        ],
                        [
                          {
                            text: '㈜티맥스소프트',
                          },
                          {
                            text: '광고 이메일 발송 서비스',
                          },
                        ],
                      ]}
                    />
                    <br />
                    <br />
                    <h5 style={{ fontWeight: `bold` }}>
                      정보주체 및 법정대리인의 권리, 의무 및 행사방법
                    </h5>
                    {`정보주체 및 법정 대리인은 개인정보 처리에 대하여 언제든지 다음 각 호의 개인정보 보호 관련 권리를 행사할 수 있습니다. 
     1) 개인정보 열람 요구
     2) 오류 등이 있을 경우 정정 요구
     3) 삭제 요구 
     4) 처리 정지 요구  
  권리 행사는 `}
                    <strong>‘10 개인정보 보호 책임자 및 담당자’</strong>{' '}
                    {`에 공개된 연락처 또는 이메일주소를 통하여 하실 수 있으며 회사는 이에 대해 지체없이 조치하겠습니다. 
  정보주체가 개인정보의 오류 등에 대한 정정 또는 삭제를 요구한 경우, 회사는 정정 또는 삭제를 완료할 때까지 당해 개인정보를 이용하거나 제공하지 않습니다.
  권리 행사는 정보주체의 법정대리인이나 위임을 받은 자 등 대리인을 통하여 하실 수 있습니다. 이 경우 개인정보 보호법 시행규칙 별지 제11호 서식에 따른 위임장을 제출하셔야 합니다. 
  정보주체는 개인정보 보호법 등 관계법령을 위반하여 회사가 처리하고 있는 정보주체 본인이나 타인의 개인정보 및 사생활을 침해하여서는 아니됩니다.
  `}
                    <br />
                    <br />
                    <h5 style={{ fontWeight: `bold` }}>
                      개인정보 자동수집 장치의 설치·운영 및 그 거부에 관한 사항)
                    </h5>
                    <h5 style={{ fontWeight: `bold` }}>
                      {`  `}자동 수집되는 정보
                    </h5>
                    회사는 정보주체의 서비스 편의를 제공하기 위해 브라우저 종류
                    및 OS, 방문기록(IP Address, 접속시간), 쿠키(cookie) 등을
                    통해 수집되는 행태 정보 등을 수집합니다.
                    <br />
                    <br />
                    <h5 style={{ fontWeight: `bold` }}>
                      {`  `}쿠키 수집 및 이용에 관한 사항
                    </h5>
                    {`쿠키는 웹사이트가 고객의 컴퓨터 브라우저(인터넷 익스플로러 등)에 전송하는 소량의 정보입니다.
  1) 쿠키의 사용 목적
  쿠키를 통해 정보주체가 선호하는 설정 등을 저장하여 정보주체에게 더욱 빠른 웹 환경을 지원하며, 편리한 이용을 위해 서비스 개선에 활용합니다. 이를 통해 정보주체는 더욱 손쉽게 서비스를 이용할 수 있게 됩니다.
  
  2) 쿠키의 설치∙운영 및 거부
  정보주체는 쿠키 설치에 대한 선택권을 가지고 있으며, 언제든지 이러한 쿠키의 저장을 거부하거나 삭제할 수 있습니다.
  
  3) 쿠키 설정 거부 방법
  : 정보주체는 쿠키 설치에 대한 선택권을 가지고 있습니다. 따라서 웹브라우저에서 옵션을 설정함으로써 모든 쿠키를 허용하거나, 쿠키가 저장될 때마다 확인을 거치거나, 아니면 모든 쿠키의 저장을 거부할 수도 있습니다. 
  단, 정보주체께서 쿠키 설치를 거부하였을 경우 서비스 제공에 어려움이 있을 수 있습니다.
  
  – 쿠키설정 방법 
  :「도구」메뉴에서「인터넷옵션」을 선택합니다.「개인정보 탭」을 클릭합니다. 
  「설정」을 이용하여 본인에게 맞는 쿠키허용 수준을 설정하시면 됩니다. 
  
  – 받은 쿠키를 보는 방법 
  :「도구」 메뉴에서 「인터넷옵션」 을 선택합니다. 
  「일반」 탭을 클릭하여 ‘ 검색기록’ 의 「설정」 으로 들어가서, 「파일보기」 를 통해 확인합니다. 
  – 쿠키 설정 거부 방법 
  :「도구」 메뉴에서 「인터넷옵션」 을 선택합니다. 「개인정보 탭」 을 클릭합니다. 
  「설정」 을 이용하여 상위레벨로 하여 “ 모든 쿠키차단” 으로 설정하시면 됩니다. `}
                    <br />
                    <br />
                    <h5 style={{ fontWeight: `bold` }}>
                      개인정보의 안전성 확보조치에 관한 사항
                    </h5>
                    회사는 정보주체의 개인정보를 처리하면서 개인정보가 분실,
                    도난, 누출, 변조 또는 훼손되지 않도록 안전성 확보를 위하여
                    다음과 같은 기술적/관리적/물리적 방안을 마련하고 있습니다.
                    <br />
                    <br />
                    <h5 style={{ fontWeight: `bold` }}>{`  `}관리적 조치</h5>
                    내부관리계획 수립∙시행, 개인정보 취급자 지정 최소화,
                    개인정보보호 교육 시행
                    <br />
                    <br />
                    <h5 style={{ fontWeight: `bold` }}>{`  `}기술적 조치</h5>
                    개인정보처리시스템의 접근권한 관리, 접근통제, 비밀번호 등
                    중요정보의 암호화, 보안프로그램 설치 등
                    <br />
                    <br />
                    <h5 style={{ fontWeight: `bold` }}>{`  `}물리적 조치</h5>
                    전산실, 자료보관실 등의 접근통제 등
                    <br />
                    <br />
                    <h5 style={{ fontWeight: `bold` }}>
                      개인정보 보호 책임자 및 담당자
                    </h5>
                    {`회사는 정보주체의 개인정보를 보호하고 개인정보와 관련한 불만을 처리하기 위하여 아래와 같이 관련 부서 및 개인정보보호책임자를 지정하고 있습니다.
  
  개인정보보호 책임자 : 노용채 상무
  개인정보 담당 부서 : 티맥스에이엔씨 정보보호팀
  개인정보보호 담당자 : 이정윤 매니저
  연락처 : 031)8018-9494
  이메일 : security@tmax.co.kr
  
  회사의 서비스를 이용하시며 발생하는 모든 개인정보보호 관련 민원을 개인정보보호책임자 혹은 담당부서로 신고하실 수 있습니다. 회사는 신고사항에 대해 신속하게 충분한 답변을 드릴 것입니다. 
  `}
                    <br />
                    <br />
                    <h5 style={{ fontWeight: `bold` }}>권익침해 구제방법</h5>
                    {`기타 개인정보침해에 대한 신고나 상담이 필요하신 경우에는 아래 기관에 문의하여, 구제방법을 찾을 수 있습니다.
  
  · 개인정보분쟁조정위원회 (`}
                    <a
                      href="https://www.kopico.go.kr"
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      www.kopico.go.kr
                    </a>
                    {` / 국번없이 1833-6972)
  · 개인정보침해신고센터 (`}
                    <a
                      href="https://privacy.kisa.or.kr"
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      privacy.kisa.or.kr
                    </a>
                    {` / 국번없이 118)
  · 대검찰청 과학수사부 사이버수사과 (`}
                    <a
                      href="https://www.spo.go.kr"
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      www.spo.go.kr
                    </a>
                    {` / 국번없이 1301)
  · 경찰청 사이버안전국 (`}
                    <a
                      href="https://cyberbureau.police.go.kr"
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      cyberbureau.police.go.kr
                    </a>
                    {` / 국번없이 182)
  `}
                    <br />
                    <br />
                    <h5 style={{ fontWeight: `bold` }}>
                      개인정보 처리방침 변경
                    </h5>
                    {`1. 본 개인정보 처리방침은 2022년 04월 10일부터 시행됩니다.
2. 이전의 개인정보 처리방침은 아래에서 확인하실 수 있습니다.`}
                    <br />
                    <CursorSpan
                      onClick={() =>
                        handleChangeSelectOptionClick('2021-11-18')
                      }
                    >
                      - 2021. 11. 18 ~ 2022. 04. 10 개인정보처리방침 (클릭)
                    </CursorSpan>
                    <CursorSpan
                      onClick={() =>
                        handleChangeSelectOptionClick('2021-02-22')
                      }
                    >
                      - 2021. 02. 22 ~ 2021. 11. 18 개인정보처리방침 (클릭)
                    </CursorSpan>
                    <CursorSpan
                      onClick={() =>
                        handleChangeSelectOptionClick('2020-12-14')
                      }
                    >
                      - 2020. 12. 14 ~ 2021. 02. 22 개인정보처리방침 (클릭)
                    </CursorSpan>
                    <CursorSpan
                      onClick={() =>
                        handleChangeSelectOptionClick('2020-11-09')
                      }
                    >
                      - 2020. 11. 09 ~ 2020. 12. 14 개인정보처리방침 (클릭)
                    </CursorSpan>
                    <CursorSpan
                      onClick={() =>
                        handleChangeSelectOptionClick('2020-06-22')
                      }
                    >
                      - 2020. 06. 22 ~ 2020. 11. 09 개인정보처리방침 (클릭)
                    </CursorSpan>
                    <CursorSpan
                      onClick={() =>
                        handleChangeSelectOptionClick('2020-05-25')
                      }
                    >
                      - 2020. 05. 25 ~ 2020. 06. 22 개인정보처리방침 (클릭)
                    </CursorSpan>
                    <CursorSpan
                      onClick={() =>
                        handleChangeSelectOptionClick('2019-08-15')
                      }
                    >
                      - 2019. 08. 15 ~ 2020. 05. 25 개인정보처리방침 (클릭)
                    </CursorSpan>
                  </PageBodyPre>
                </PageInnerContents>
              </>
            )}

            {i18n.language === 'en' ? (
              <>
                <PageInnerContents id="2021-11-18" language={i18n.language}>
                  <PageBodyPre ismobile={isMobile}>
                    {`
TmaxA&C Co.,Ltd. (the “Company”) places a high importance on your privacy and makes its best efforts to protect personal data that you, data subject, provide to the Company for the purpose of using its services. The Company thus complies with laws and regulations enacted to protect personal information, such as the Act on Promotion of Information and Communication Network Utilization and Information Protection, etc. and Personal Information Protection Act. 

This Privacy Policy is published on the front page of the Company’s website, so that you can easily find and read it at any time.

This Privacy Policy can be updated in compliance with applicable laws and the Company’s internal policies, in case of which the revised version will be released so that you can easily take notice of changes.

`}
                    <h5 style={{ fontWeight: `bold` }}>
                      Purpose of Processing Personal Data
                    </h5>
                    {`
The Company processes your personal information only for the following purposes. In case of any changes to those purposes, the Company will seek your prior consent and take other necessary actions in compliance with Article 18 of the Personal Information Protection Act. 
`}
                    <TableAgree>
                      <thead>
                        <tr>
                          <TableTh>Purpose</TableTh>
                          <TableTh>Description</TableTh>
                        </tr>
                      </thead>
                      <tbody>
                        <tr>
                          <TableTd center>Membership</TableTd>
                          <TableTd>
                            <div>· Providing membership services</div>
                          </TableTd>
                        </tr>
                        <tr>
                          <TableTd center>Customer Inquiry</TableTd>
                          <TableTd>
                            <div>· Responding to customer’s inquiries</div>
                          </TableTd>
                        </tr>
                      </tbody>
                    </TableAgree>
                    <br />
                    <h5 style={{ fontWeight: `bold` }}>
                      Retention Periods for Personal Data
                    </h5>
                    {`
The Company has made it a rule to retain and utilize your personal information for the duration of the service period stipulated in the Section(`}
                    <strong>
                      ‘3 Categories of Collected Data and Methods of Data
                      Collection’
                    </strong>
                    {`Any personal data whose purposes have been fulfilled will be immediately destroyed. However, the Company can retain certain types of information for a certain period of time as required by the applicable laws specified below.

                `}
                    <TableAgree>
                      <thead>
                        <tr>
                          <TableTh>Applicable Law</TableTh>
                          <TableTh>Subject Data</TableTh>
                          <TableTh>Period</TableTh>
                        </tr>
                      </thead>
                      <tbody>
                        <tr>
                          <TableTd center rowSpan="2">
                            Commercial law
                          </TableTd>
                          <TableTd>
                            Extinctive prescription of a commercial claim
                          </TableTd>
                          <TableTd center>5 years</TableTd>
                        </tr>
                        <tr>
                          <TableTd>
                            Critical sales documents including contracts,
                            requests, invoice and so on.
                          </TableTd>
                          <TableTd center>10 years</TableTd>
                        </tr>

                        <tr>
                          <TableTd center rowSpan="4">
                            Act on the consumer protection in electronic
                            commerce, etc.
                          </TableTd>
                          <TableTd>Record on sign/advertisement</TableTd>
                          <TableTd center>6 months</TableTd>
                        </tr>

                        <tr>
                          <TableTd>
                            Record on consumer complaint or dispute resolution
                          </TableTd>
                          <TableTd center>3 years</TableTd>
                        </tr>

                        <tr>
                          <TableTd>
                            Record regarding contract or withdrawal of
                            subscription
                          </TableTd>
                          <TableTd center>5 years</TableTd>
                        </tr>
                        <tr>
                          <TableTd>
                            Record on payment and supply of goods
                          </TableTd>
                          <TableTd center>5 years</TableTd>
                        </tr>

                        <tr>
                          <TableTd center>
                            Act on Use and Protection of Credit Information
                          </TableTd>
                          <TableTd>
                            Record on collection/process, and use of credit
                            information
                          </TableTd>
                          <TableTd center>3 years</TableTd>
                        </tr>

                        <tr>
                          <TableTd center rowSpan="2">
                            Protection of Communications Act
                          </TableTd>
                          <TableTd>
                            Log record of users such as internet/data detecting
                            the place of user connection
                          </TableTd>
                          <TableTd center>3 months</TableTd>
                        </tr>
                        <tr>
                          <TableTd>
                            Other factual records about communications including
                            date, starting/ending time and frequency of use
                          </TableTd>
                          <TableTd center>12 months</TableTd>
                        </tr>
                      </tbody>
                    </TableAgree>
                    <br />
                    <h5 style={{ fontWeight: `bold` }}>
                      Categories of Collected Data and Methods of Data
                      Collection
                    </h5>
                    The Company collects your personal data during the member
                    subscription process or the use of services.
                    <br />
                    <br />
                    <h5 style={{ fontWeight: `bold` }}>
                      Membership subscription
                    </h5>
                    The following personal data are collected during the process
                    of your member subscription.
                    <TableAgree>
                      <thead>
                        <tr>
                          <TableTh>division</TableTh>
                          <TableTh>Items to be collected</TableTh>
                          <TableTh>Collection channel</TableTh>
                          <TableTh>Period of retention</TableTh>
                        </tr>
                      </thead>
                      <tbody>
                        <tr>
                          <TableTd center>Required</TableTd>
                          <TableTd>
                            <div>
                              · User’s personal information ID (email),
                              password, name
                            </div>
                            <div>
                              · Usage history chat transcript data, calendar,
                              memo and other information stored during the use
                              of services
                            </div>
                          </TableTd>
                          <TableTd rowSpan="2">
                            <div>· Company’s website</div>
                            <div>
                              · Download and submission of “Add the entire
                              organizational chart” form
                            </div>
                          </TableTd>
                          <TableTd center rowSpan="2">
                            Until withdrawal of membership
                          </TableTd>
                        </tr>
                        <tr>
                          <TableTd center>Optional</TableTd>
                          <TableTd>
                            <div>
                              · Profile information (company phone number,
                              email),
                            </div>
                            <div>
                              · Personal information (date of birth,
                              company/department, position/title), profile
                              photo, friends list
                            </div>
                            <div>
                              · Linked email account information (email address
                              and password)
                            </div>
                          </TableTd>
                        </tr>
                      </tbody>
                    </TableAgree>
                    <br />
                    <br />
                    <h5 style={{ fontWeight: `bold` }}>Customer inquiry</h5>
                    The following personal data are collected during the
                    processing of a customer’s inquiry
                    <Table
                      titles={[
                        'division',
                        'Items to be collected',
                        'Collection channel',
                        'Period of retention',
                      ]}
                      rows={[
                        [
                          { text: 'Required', center: true },
                          {
                            text: '· Name, email address, contact details, available time',
                          },
                          {
                            text: '· Company’s website',
                            rowSpan: 2,
                          },
                          { text: '5 years', rowSpan: 2, center: true },
                        ],
                        [
                          { text: 'Optional', center: true },
                          {
                            text: '· Company or organization’s name, position/title, business type, estimated number of users and other inquiries ',
                          },
                        ],
                      ]}
                    />
                    <br />
                    <br />
                    <h5 style={{ fontWeight: `bold` }}>
                      Information collected automatically during the use of
                      services
                    </h5>
                    {`The following data can be automatically generated and collected during your use of services or Company’s operation.
- User behavior information including types of browser and OS, access history (IP address and connection time) and cookies`}
                    <br />
                    <br />
                    <h5 style={{ fontWeight: `bold` }}>
                      Sharing Personal Data with Third-Parties
                    </h5>
                    {`The Company processes your personal information strictly within the scope of the purposes specified in the Section `}
                    <strong>“1 Purpose of Processing Personal Data”</strong>
                    and may share the personal data with third-parties only in
                    accordance with Article 17 and 18 of the Personal
                    Information Protection Act.
                    <br />
                    <br />
                    <Table
                      titles={[
                        'Third-party',
                        'Use',
                        'Items to be shared',
                        'Period of retention',
                      ]}
                      rows={[
                        [
                          {
                            text: 'TmaxGroup companies (TmaxSoft, TmaxData, TmaxTibero, TmaxAI, Tmax AI Development Center, TmaxEnterprise, TmaxCloud, TmaxOS, TmaxOffice, TmaxWAPL, TmaxMetaverse, tivine consulting)',
                          },
                          {
                            text: 'Promotional events, advertisements and marketing',
                          },
                          {
                            text: 'ID (email), name, password, record about subscription or withdrawal of membership',
                          },
                          {
                            text: 'Until withdrawal of membership, unless it is necessary to retain certain types of user data under applicable laws',
                          },
                        ],
                      ]}
                    />
                    <br />
                    <br />
                    <h5 style={{ fontWeight: `bold` }}>
                      Destruction of Personal Data
                    </h5>
                    The Company will immediately destroy any personal data of
                    which the retention period has expired or the purpose has
                    been fulfilled. In a case where it is necessary to retain
                    personal data of which the retention period agreed by the
                    data subject has expired or the purpose has been fulfilled,
                    the Company will transfer the data to another separate
                    database or some other storage systems.
                    <br />
                    <br />
                    The procedure and methods of personal data destruction are
                    as follows:
                    <br />
                    <br />
                    <h5 style={{ fontWeight: `bold` }}>
                      Destruction procedure
                    </h5>
                    The Company isolates data to be destroyed and seeks approval
                    from the personal information protection officer to proceed
                    to destruction.
                    <h5 style={{ fontWeight: `bold` }}>Destruction methods</h5>
                    The Company permanently destroys personal data recorded and
                    stored in an electronic file format to make them
                    unrecoverable. Paper documents containing personal
                    information are shredded or incinerated.
                    <br />
                    <br />
                    <h5 style={{ fontWeight: `bold` }}>
                      Outsourcing Data Processing
                    </h5>
                    {`The Company entrusts the processing of personal data to other specialized companies to conduct services. 
                    In outsourcing the processing of personal data, the Company clearly specifies instructions about privacy protection, confidentiality obligations, restriction of sharing with third parties, liability for accident, outsourcing period and return/destruction of processed data, all of which must be strictly complied with by the outsourcee so that the entrusted data can be safely and securely processed.                    
                    `}
                    <br />
                    <br />
                    <Table
                      titles={['Outsourcee', 'Outsourced activities']}
                      rows={[
                        [
                          {
                            text: 'TmaxCloud',
                          },
                          {
                            text: 'User authentication and validation, operation and management of integrated membership, operation of SSO membership, operation and management of database system for member information',
                          },
                        ],
                        [
                          {
                            text: 'TmaxSoft',
                          },
                          {
                            text: 'Email advertising service',
                          },
                        ],
                        [
                          {
                            text: 'TmaxWAPL',
                          },
                          {
                            text: 'Operation of customer center, data analytics, development of services and operational support',
                          },
                        ],
                      ]}
                    />
                    <br />
                    <br />
                    <h5 style={{ fontWeight: `bold` }}>
                      Data Subject’s Rights and Obligations
                    </h5>
                    {`You and your legal representative can exercise the following rights concerning your personal data at any time. 
   1) Right of Access
   2) Right to Rectification
   3) Right to Erasure 
   4) Right to Restriction of Processing  
You can exercise those rights by contacting at the number or via the email address specified in the Section `}
                    <strong>
                      ‘10 Personal Information Protection Officer’
                    </strong>{' '}
                    {`, and the Company will immediately process your request.
If you make a request for rectification or erasure of your personal information, the Company will not use or provide the information until it is completely rectified or erased.
You can exercise your rights through your legal representative or a delegate, in case of which a power of attorney written in accordance with the format no. 11 prescribed by the Personal Information Protection Act is required.
You, data subject, must comply with the Personal Information Protection Act and other relevant laws and regulations, and thus cannot breach another person’s privacy of data nor your own data being processed by the Company.
`}
                    <br />
                    <br />
                    <h5 style={{ fontWeight: `bold` }}>
                      Automatic Data Collection Tools
                    </h5>
                    <h5 style={{ fontWeight: `bold` }}>
                      {`  `}Data to be automatically collected
                    </h5>
                    The Company collects data on types of browser and OS, access
                    history (IP address, connection time…) and cookies to
                    understand user behavior and thus provide better services.
                    <br />
                    <br />
                    <h5 style={{ fontWeight: `bold` }}>
                      {`  `}Collection and use of cookies
                    </h5>
                    {`Cookies are small files of information that a website generates and sends to your computer browser (for example Internet Explorer).

) Purpose of cookies
The Company uses cookies to store user preferences in order to enhance your web experience and improve its services.

2) Data subject’s right to install, manage and disable cookies
You have the right to choose whether to install cookies. You are free to disable or delete these cookies.

3) Setting and disabling cookies
You have the right to choose whether to install cookies. Therefore, you can set options about cookies on your web browser to accept all cookies, check cookies each time they are stored, or reject all cookies.
If you disable all cookies, however, you will not be able to enjoy the full functionality of the website.

– How to set cookies
: Go to the Tools menu and select Internet Options. 
Click on the Privacy tab, and then under Settings, move the slider to set the cookies level as you prefer. 

– How to view cookies 
: Go to the Tools menu and select Internet Options. 
Click on the General tab and select Settings underneath “Browsing history”, and then click on View files to check your cookies. 

– How to reject all cookies 
: Go to the Tools menu and select Internet Options. 
Click on the Privacy tab, and then under Settings, move the slider to the top to “block all cookies”. `}
                    <br />
                    <br />
                    <h5 style={{ fontWeight: `bold` }}>
                      Security Measures to Ensure Privacy Protection
                    </h5>
                    The Company implements the following
                    administrative/technical/physical measures to prevent any
                    loss, theft, leakage, alteration or destruction of personal
                    data.
                    <br />
                    <br />
                    <h5 style={{ fontWeight: `bold` }}>
                      {`  `}Administrative measures
                    </h5>
                    Establishing and implementing internal management plan to
                    handle personal data, minimizing the number of persons who
                    handle personal data and conducting privacy education
                    programs.
                    <br />
                    <br />
                    <h5 style={{ fontWeight: `bold` }}>
                      {`  `}Technical measure
                    </h5>
                    Controlling authorization over access to the personal data
                    processing system, restricting access to personal data,
                    encrypting critical information (for example password) and
                    installing security programs
                    <br />
                    <br />
                    <h5 style={{ fontWeight: `bold` }}>
                      {`  `}Physical measure
                    </h5>
                    Restricting access to the server room and data storage
                    system.
                    <br />
                    <br />
                    <h5 style={{ fontWeight: `bold` }}>
                      Personal Information Protection Officer
                    </h5>
                    {`The following persons and department are responsible for protecting your personal data and handling privacy-related concerns.

Chief Privacy Officer: Jaehak Koo, CFO
Responsible department: TmaxA&C Security Team
Privacy Associate: Jeongyoon Lee, Manager
Telephone: 031) 8018-9494
Email: security@tmax.co.kr

Any privacy-related inquiries or complaints arising during your use of the Company’s services can be addressed to the responsible persons and department specified above. The Company will promptly and satisfactorily respond to your concern.
`}
                    <br />
                    <br />
                    <h5 style={{ fontWeight: `bold` }}>
                      Reporting a Privacy Breach
                    </h5>
                    {`If you have any concern about a breach of your personal data, please contact the following competent institutions to seek a resolution.

· Personal Information Dispute Mediation Committee (`}
                    <a
                      href="https://www.kopico.go.kr"
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      www.kopico.go.kr
                    </a>
                    {` / Call at 1833-6972)
· Personal Information Infringement Report Center (`}
                    <a
                      href="https://privacy.kisa.or.kr"
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      privacy.kisa.or.kr
                    </a>
                    {` / Call at 118)
· Cybercrime Investigation Division of Supreme Prosecutor’s Office (`}
                    <a
                      href="https://www.spo.go.kr"
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      www.spo.go.kr
                    </a>
                    {` / Call at 1301)
· Korean National Police Agency Cyber Bureau (`}
                    <a
                      href="https://cyberbureau.police.go.kr"
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      cyberbureau.police.go.kr
                    </a>
                    {` / Call at 182)
`}
                    <br />
                    <br />
                    <h5 style={{ fontWeight: `bold` }}>
                      Updates to Privacy Policy
                    </h5>
                    1. This Privacy Policy takes effect on 18 Nov, 2021
                    <br />
                    <CursorSpan
                      onClick={() =>
                        handleChangeSelectOptionClick('2021-02-22')
                      }
                    >
                      - 22 Feb 2021 ~ 18 Nov 2021 Privacy Policy (Click Here)
                    </CursorSpan>
                    <CursorSpan
                      onClick={() =>
                        handleChangeSelectOptionClick('2020-12-14')
                      }
                    >
                      - 14 Dec 2020 ~ 22 Feb 2021 Privacy Policy (Click Here)
                    </CursorSpan>
                    <CursorSpan
                      onClick={() =>
                        handleChangeSelectOptionClick('2020-11-09')
                      }
                    >
                      - 09 Nov 2020 ~ 14 Dec 2020 Privacy Policy (Click Here)
                    </CursorSpan>
                    <CursorSpan
                      onClick={() =>
                        handleChangeSelectOptionClick('2020-06-22')
                      }
                    >
                      - 22 Jun 2020 ~ 09 Nov 2020 Privacy Policy (Click Here)
                    </CursorSpan>
                    <CursorSpan
                      onClick={() =>
                        handleChangeSelectOptionClick('2020-05-25')
                      }
                    >
                      - 25 May 2020 ~ 22 Jun 2020 Privacy Policy (Click Here)
                    </CursorSpan>
                    <CursorSpan
                      onClick={() =>
                        handleChangeSelectOptionClick('2019-08-15')
                      }
                    >
                      - 15 Aug 2019 ~ 25 May 2020 Privacy Policy (Click Here)
                    </CursorSpan>
                  </PageBodyPre>
                </PageInnerContents>
              </>
            ) : (
              <>
                <PageInnerContents id="2021-11-18">
                  <PageBodyPre ismobile={isMobile}>
                    {`
티맥스에이엔씨(이하 ‘회사’)는 정보주체의 개인정보 보호를 매우 중요하게 생각하며, 정보주체가 회사의 서비스를 이용하기 위해 회사에 제공한 개인정보 보호에 최선을 다하고 있습니다. 이에 회사는 "정보통신망 이용촉진 및 정보보호 등에 관한 법률" 및 "개인정보 보호법" 등 개인정보 보호와 관련된 법령을 준수하고 있습니다.
  
회사는 본 개인정보처리방침을 사이트 첫 화면에 공개함으로써 정보주체가 언제든지 쉽게 확인할 수 있도록 하고 있습니다.
  
본 개인정보처리방침은 관계 법령 및 회사의 내부 방침에 따라 변경될 수 있으며, 개정 시 버전 관리를 통하여 개정사항을 쉽게 확인할 수 있도록 하고 있습니다.
  
  `}
                    <h5 style={{ fontWeight: `bold` }}>개인정보의 처리목적</h5>
                    {`
회사는 다음의 목적으로 정보주체의 개인정보를 처리합니다. 처리하고 있는 개인정보는 다음의 목적 이외의 용도로는 이용되지 않으며, 이용 목적이 변경되는 경우에는 개인정보 보호법 제18조에 따라 별도의 동의를 받는 등 필요한 조치를 이행할 예정입니다.
   
  `}
                    <TableAgree>
                      <thead>
                        <tr>
                          <TableTh>목적</TableTh>
                          <TableTh>자세한 내용</TableTh>
                        </tr>
                      </thead>
                      <tbody>
                        <tr>
                          <TableTd center>회원 가입</TableTd>
                          <TableTd>
                            <div>· 회원 가입 서비스 이용</div>
                          </TableTd>
                        </tr>
                        <tr>
                          <TableTd center>고객 문의</TableTd>
                          <TableTd>
                            <div>· 고객의 문의사항에 대한 대응</div>
                          </TableTd>
                        </tr>
                      </tbody>
                    </TableAgree>
                    <br />
                    <h5 style={{ fontWeight: `bold` }}>
                      개인정보의 보유 및 이용기간
                    </h5>
                    {`
원칙적으로, 회사는 개인정보 수집 및 이용목적이 달성된 후(`}
                    <strong>‘3. 수집하는 개인정보 항목 및 수집방법’</strong>
                    {`에서 명시된 보유기간)에는 해당 정보를 지체 없이 파기합니다. 단, 관계법령의 규정에 의하여 보존할 필요가 있는 경우, 회사는 아래와 같이 관계법령에서 정한 일정한 기간 동안 회원정보를 보관합니다.
                  `}
                    <TableAgree>
                      <thead>
                        <tr>
                          <TableTh>법적 근거</TableTh>
                          <TableTh>보유 항목</TableTh>
                          <TableTh>보유기간</TableTh>
                        </tr>
                      </thead>
                      <tbody>
                        <tr>
                          <TableTd center rowSpan="2">
                            상법
                          </TableTd>
                          <TableTd>상사채권 소멸시효</TableTd>
                          <TableTd center>5년</TableTd>
                        </tr>
                        <tr>
                          <TableTd>
                            계약서, 신청서, 청구서 등 영업 관련 중요 서류
                          </TableTd>
                          <TableTd center>10년</TableTd>
                        </tr>

                        <tr>
                          <TableTd center rowSpan="4">
                            전자상거래 등에서의 소비자보호에 관한 법률
                          </TableTd>
                          <TableTd>표시/광고에 관한 기록</TableTd>
                          <TableTd center>6개월</TableTd>
                        </tr>

                        <tr>
                          <TableTd>
                            소비자의 불만 또는 분쟁처리에 관한 기록
                          </TableTd>
                          <TableTd center>3년</TableTd>
                        </tr>

                        <tr>
                          <TableTd>계약 또는 청약철회 등에 관련 기록</TableTd>
                          <TableTd center>5년</TableTd>
                        </tr>
                        <tr>
                          <TableTd>
                            대금결제 및 재화 등의 공급에 관한 기록
                          </TableTd>
                          <TableTd center>5년</TableTd>
                        </tr>

                        <tr>
                          <TableTd center>
                            신용정보의 이용 및 보호에 관한 법률
                          </TableTd>
                          <TableTd>
                            신용정보의 수집/처리 및 이용 등에 관한 기록
                          </TableTd>
                          <TableTd center>3년</TableTd>
                        </tr>

                        <tr>
                          <TableTd center rowSpan="2">
                            통신비밀보호법
                          </TableTd>
                          <TableTd>
                            로그기록자료, 접속지의 추적자료 등 통신사실확인자료
                          </TableTd>
                          <TableTd center>3개월</TableTd>
                        </tr>
                        <tr>
                          <TableTd>
                            전기통신일시, 전기통신개시ㆍ종료시간, 사용도수 등
                            통신사실확인자료
                          </TableTd>
                          <TableTd center>12개월</TableTd>
                        </tr>
                      </tbody>
                    </TableAgree>
                    <br />
                    <h5 style={{ fontWeight: `bold` }}>
                      수집하는 개인정보 항목 및 수집방법
                    </h5>
                    회사는 회원가입 또는 서비스 이용 시 정보주체로부터 아래와
                    같은 개인정보를 수집하고 있습니다.
                    <br />
                    <br />
                    <h5 style={{ fontWeight: `bold` }}>회원 가입</h5>
                    회원 가입에 관하여 다음과 같은 개인정보를 수집합니다.
                    <TableAgree>
                      <thead>
                        <tr>
                          <TableTh>구분</TableTh>
                          <TableTh>수집 항목</TableTh>
                          <TableTh>수집 방법</TableTh>
                          <TableTh>보유 기간</TableTh>
                        </tr>
                      </thead>
                      <tbody>
                        <tr>
                          <TableTd center>필수항목</TableTd>
                          <TableTd>
                            <div>
                              · 고객 개인정보 아이디(이메일), 비밀번호, 성명
                            </div>
                            <div>
                              · 이용 내역 대화내역, 캘린더, 메모 등 서비스
                              이용시 입력되는 정보
                            </div>
                          </TableTd>
                          <TableTd rowSpan="2">
                            <div>· 회원가입 시, 홈페이지를 통해 수집</div>
                            <div>
                              · 홈페이지 내 조직도 일괄 추가 양식 다운로드 및
                              등록을 통한 수집
                            </div>
                          </TableTd>
                          <TableTd center rowSpan="2">
                            회원 탈퇴 시 까지
                          </TableTd>
                        </tr>
                        <tr>
                          <TableTd center>선택항목</TableTd>
                          <TableTd>
                            <div>· 프로필정보(회사 전화, 이메일),</div>
                            <div>
                              · 개인정보(생년월일, 소속회사/부서, 직위/직책),
                              프로필 사진, 친구목록
                            </div>
                            <div>
                              · 이메일 연동정보(이메일, 이메일 비밀번호)
                            </div>
                          </TableTd>
                        </tr>
                      </tbody>
                    </TableAgree>
                    <br />
                    <br />
                    <h5 style={{ fontWeight: `bold` }}>고객 문의</h5>
                    고객 문의에 관하여 다음과 같은 개인정보를 수집합니다.
                    <Table
                      titles={['구분', '수집 항목', '수집 방법', '보유 기간']}
                      rows={[
                        [
                          { text: '필수항목', center: true },
                          {
                            text: '· 이름, 이메일 주소, 연락처, 연락 가능 시간',
                          },
                          {
                            text: '· 고객 문의 시, 홈페이지를 통해 수집',
                            rowSpan: 2,
                          },
                          { text: '5년', rowSpan: 2, center: true },
                        ],
                        [
                          { text: '선택항목', center: true },
                          {
                            text: '· 회사 또는 단체명, 직책, 업종 정보, 사용 예상 인원, 기타 문의사항 ',
                          },
                        ],
                      ]}
                    />
                    <br />
                    <br />
                    <h5 style={{ fontWeight: `bold` }}>
                      서비스 이용 중 자동으로 수집되는 정보
                    </h5>
                    {`서비스 이용과정이나 사업처리 과정에서 아래와 같은 정보들이 자동으로 생성되어 수집 될 수 있습니다.
  - 브라우저 종류 및 OS, 방문기록(IP Address, 접속시간), 쿠키(cookie) 등을 통해 수집되는 행태 정보`}
                    <br />
                    <br />
                    <h5 style={{ fontWeight: `bold` }}>
                      개인정보의 제3자 제공
                    </h5>
                    {`회사는 정보주체의 개인정보를 `}
                    <strong>“1. 개인정보의 처리목적”</strong>
                    에서 명시한 범위 내에서만 처리하며, 개인정보 보호법 제17조
                    및 제18조에 해당하는 경우에만 개인정보를 제3자에게
                    제공합니다.
                    <br />
                    <br />
                    <Table
                      titles={[
                        '제공받는 자',
                        '제공 목적',
                        '제공하는 개인정보',
                        '보유 및 이용기간',
                      ]}
                      rows={[
                        [
                          {
                            text: '티맥스 그룹사 (㈜티맥스소프트, ㈜티맥스데이타, ㈜티맥스티베로, ㈜티맥스에이아이, ㈜티맥스인공지능개발센터, ㈜티맥스엔터프라이즈, ㈜티맥스클라우드, ㈜티맥스오에스, ㈜티맥스오피스, ㈜티맥스와플, ㈜티맥스메타버스, ㈜티맥스티바인 )',
                          },
                          {
                            text: '이벤트 정보, 광고성 정보 제공 등 마케팅, 이벤트 및 프로모션의 목적',
                          },
                          {
                            text: '아이디(이메일), 이름, 비밀번호, 회원 가입 및 탈퇴 현황',
                          },
                          {
                            text: '관계 법령의 규정에 따라 이용자의 개인정보를 보존할 의무가 있는 경우가 아닌 한, 회원탈퇴 시까지',
                          },
                        ],
                      ]}
                    />
                    <br />
                    <br />
                    <h5 style={{ fontWeight: `bold` }}>개인정보의 파기</h5>
                    회사 개인정보 보유기간의 경과, 처리목적 달성 등 개인정보가
                    불필요하게 되었을 때에는 지체없이 해당 개인정보를
                    파기합니다. 정보주체로부터 동의받은 개인정보 보유기간이
                    경과하거나 처리목적이 달성되었음에도 불구하고 다른 법령에
                    따라 개인정보를 계속 보존하여야 하는 경우에는, 해당
                    개인정보를 별도의 데이터베이스(DB)로 옮기거나 보관장소를
                    달리하여 보존합니다.
                    <br />
                    <br />
                    개인정보 파기의 절차 및 방법은 다음과 같습니다.
                    <br />
                    <br />
                    <h5 style={{ fontWeight: `bold` }}>파기절차</h5>
                    회사는 파기 사유가 발생한 개인정보를 선정하고, 회사의
                    개인정보보호책임자의 승인을 받아 개인정보를 파기합니다.
                    <h5 style={{ fontWeight: `bold` }}>파기방법 </h5>
                    회사는 전자적 파일 형태로 기록ㆍ저장된 개인정보는 기록을
                    재생할 수 없도록 파기하며, 종이 문서에 기록․저장된
                    개인정보는 분쇄기로 분쇄하거나 소각하여 파기합니다.
                    <br />
                    <br />
                    <h5 style={{ fontWeight: `bold` }}>개인정보처리의 위탁</h5>
                    {`회사는 서비스 이행을 위해 아래와 같이 개인정보 처리 업무를 외부 전문업체에 위탁하여 처리하고 있습니다.
  
  개인정보의 처리를 위탁하는 경우에는 개인정보 보호의 안전을 기하기 위하여 개인정보보호 관련 지시엄수, 개인정보에 대한 비밀유지, 제3자 제공의 제한 및 사고시의 책임부담, 위탁기간, 처리 종료 후의 개인정보의 반환 또는 파기 등을 명확히 규정하고, 위탁업체가 개인정보를 안전하게 처리하도록 감독합니다.
  `}
                    <br />
                    <br />
                    <Table
                      titles={['수탁업체', '위탁하는 업무의 내용']}
                      rows={[
                        [
                          {
                            text: '티맥스 클라우드',
                          },
                          {
                            text: '본인 인증 및 확인, 통합회원제 운영 및 관리, SSO 회원제 운영, 회원 정보 DB시스템 운영/관리 업무',
                          },
                        ],
                        [
                          {
                            text: '㈜티맥스소프트',
                          },
                          {
                            text: '광고 이메일 발송 서비스',
                          },
                        ],
                        [
                          {
                            text: '㈜티맥스와플',
                          },
                          {
                            text: '고객센터 운영, 데이터 분석 및 통계 처리, 서비스개발 및 운영지원',
                          },
                        ],
                      ]}
                    />
                    <br />
                    <br />
                    <h5 style={{ fontWeight: `bold` }}>
                      정보주체 및 법정대리인의 권리, 의무 및 행사방법
                    </h5>
                    {`정보주체 및 법정 대리인은 개인정보 처리에 대하여 언제든지 다음 각 호의 개인정보 보호 관련 권리를 행사할 수 있습니다. 
     1) 개인정보 열람 요구
     2) 오류 등이 있을 경우 정정 요구
     3) 삭제 요구 
     4) 처리 정지 요구  
  권리 행사는 `}
                    <strong>‘10 개인정보 보호 책임자 및 담당자’</strong>{' '}
                    {`에 공개된 연락처 또는 이메일주소를 통하여 하실 수 있으며 회사는 이에 대해 지체없이 조치하겠습니다. 
  정보주체가 개인정보의 오류 등에 대한 정정 또는 삭제를 요구한 경우, 회사는 정정 또는 삭제를 완료할 때까지 당해 개인정보를 이용하거나 제공하지 않습니다.
  권리 행사는 정보주체의 법정대리인이나 위임을 받은 자 등 대리인을 통하여 하실 수 있습니다. 이 경우 개인정보 보호법 시행규칙 별지 제11호 서식에 따른 위임장을 제출하셔야 합니다. 
  정보주체는 개인정보 보호법 등 관계법령을 위반하여 회사가 처리하고 있는 정보주체 본인이나 타인의 개인정보 및 사생활을 침해하여서는 아니됩니다.
  `}
                    <br />
                    <br />
                    <h5 style={{ fontWeight: `bold` }}>
                      개인정보 자동수집 장치의 설치·운영 및 그 거부에 관한 사항)
                    </h5>
                    <h5 style={{ fontWeight: `bold` }}>
                      {`  `}자동 수집되는 정보
                    </h5>
                    회사는 정보주체의 서비스 편의를 제공하기 위해 브라우저 종류
                    및 OS, 방문기록(IP Address, 접속시간), 쿠키(cookie) 등을
                    통해 수집되는 행태 정보 등을 수집합니다.
                    <br />
                    <br />
                    <h5 style={{ fontWeight: `bold` }}>
                      {`  `}쿠키 수집 및 이용에 관한 사항
                    </h5>
                    {`쿠키는 웹사이트가 고객의 컴퓨터 브라우저(인터넷 익스플로러 등)에 전송하는 소량의 정보입니다.
  1) 쿠키의 사용 목적
  쿠키를 통해 정보주체가 선호하는 설정 등을 저장하여 정보주체에게 더욱 빠른 웹 환경을 지원하며, 편리한 이용을 위해 서비스 개선에 활용합니다. 이를 통해 정보주체는 더욱 손쉽게 서비스를 이용할 수 있게 됩니다.
  
  2) 쿠키의 설치∙운영 및 거부
  정보주체는 쿠키 설치에 대한 선택권을 가지고 있으며, 언제든지 이러한 쿠키의 저장을 거부하거나 삭제할 수 있습니다.
  
  3) 쿠키 설정 거부 방법
  : 정보주체는 쿠키 설치에 대한 선택권을 가지고 있습니다. 따라서 웹브라우저에서 옵션을 설정함으로써 모든 쿠키를 허용하거나, 쿠키가 저장될 때마다 확인을 거치거나, 아니면 모든 쿠키의 저장을 거부할 수도 있습니다. 
  단, 정보주체께서 쿠키 설치를 거부하였을 경우 서비스 제공에 어려움이 있을 수 있습니다.
  
  – 쿠키설정 방법 
  :「도구」메뉴에서「인터넷옵션」을 선택합니다.「개인정보 탭」을 클릭합니다. 
  「설정」을 이용하여 본인에게 맞는 쿠키허용 수준을 설정하시면 됩니다. 
  
  – 받은 쿠키를 보는 방법 
  :「도구」 메뉴에서 「인터넷옵션」 을 선택합니다. 
  「일반」 탭을 클릭하여 ‘ 검색기록’ 의 「설정」 으로 들어가서, 「파일보기」 를 통해 확인합니다. 
  – 쿠키 설정 거부 방법 
  :「도구」 메뉴에서 「인터넷옵션」 을 선택합니다. 「개인정보 탭」 을 클릭합니다. 
  「설정」 을 이용하여 상위레벨로 하여 “ 모든 쿠키차단” 으로 설정하시면 됩니다. `}
                    <br />
                    <br />
                    <h5 style={{ fontWeight: `bold` }}>
                      개인정보의 안전성 확보조치에 관한 사항
                    </h5>
                    회사는 정보주체의 개인정보를 처리하면서 개인정보가 분실,
                    도난, 누출, 변조 또는 훼손되지 않도록 안전성 확보를 위하여
                    다음과 같은 기술적/관리적/물리적 방안을 마련하고 있습니다.
                    <br />
                    <br />
                    <h5 style={{ fontWeight: `bold` }}>{`  `}관리적 조치</h5>
                    내부관리계획 수립∙시행, 개인정보 취급자 지정 최소화,
                    개인정보보호 교육 시행
                    <br />
                    <br />
                    <h5 style={{ fontWeight: `bold` }}>{`  `}기술적 조치</h5>
                    개인정보처리시스템의 접근권한 관리, 접근통제, 비밀번호 등
                    중요정보의 암호화, 보안프로그램 설치 등
                    <br />
                    <br />
                    <h5 style={{ fontWeight: `bold` }}>{`  `}물리적 조치</h5>
                    전산실, 자료보관실 등의 접근통제 등
                    <br />
                    <br />
                    <h5 style={{ fontWeight: `bold` }}>
                      개인정보 보호 책임자 및 담당자
                    </h5>
                    {`회사는 정보주체의 개인정보를 보호하고 개인정보와 관련한 불만을 처리하기 위하여 아래와 같이 관련 부서 및 개인정보보호책임자를 지정하고 있습니다.
  
  개인정보보호 책임자 : 송영섭 전무
  개인정보 담당 부서 : 티맥스에이엔씨 정보보호팀
  개인정보보호 담당자 : 이정윤 매니저
  연락처 : 031)8018-9494
  이메일 : security@tmax.co.kr
  
  회사의 서비스를 이용하시며 발생하는 모든 개인정보보호 관련 민원을 개인정보보호책임자 혹은 담당부서로 신고하실 수 있습니다. 회사는 신고사항에 대해 신속하게 충분한 답변을 드릴 것입니다. 
  `}
                    <br />
                    <br />
                    <h5 style={{ fontWeight: `bold` }}>권익침해 구제방법</h5>
                    {`기타 개인정보침해에 대한 신고나 상담이 필요하신 경우에는 아래 기관에 문의하여, 구제방법을 찾을 수 있습니다.
  
  · 개인정보분쟁조정위원회 (`}
                    <a
                      href="https://www.kopico.go.kr"
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      www.kopico.go.kr
                    </a>
                    {` / 국번없이 1833-6972)
  · 개인정보침해신고센터 (`}
                    <a
                      href="https://privacy.kisa.or.kr"
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      privacy.kisa.or.kr
                    </a>
                    {` / 국번없이 118)
  · 대검찰청 과학수사부 사이버수사과 (`}
                    <a
                      href="https://www.spo.go.kr"
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      www.spo.go.kr
                    </a>
                    {` / 국번없이 1301)
  · 경찰청 사이버안전국 (`}
                    <a
                      href="https://cyberbureau.police.go.kr"
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      cyberbureau.police.go.kr
                    </a>
                    {` / 국번없이 182)
  `}
                    <br />
                    <br />
                    <h5 style={{ fontWeight: `bold` }}>
                      개인정보 처리방침 변경
                    </h5>
                    {`1. 본 개인정보 처리방침은 2021년 11월 18일부터 시행됩니다.
2. 이전의 개인정보 처리방침은 아래에서 확인하실 수 있습니다.`}
                    <br />
                    <CursorSpan
                      onClick={() =>
                        handleChangeSelectOptionClick('2021-02-22')
                      }
                    >
                      - 2021. 02. 22 ~ 2021. 11. 18 개인정보처리방침 (클릭)
                    </CursorSpan>
                    <CursorSpan
                      onClick={() =>
                        handleChangeSelectOptionClick('2020-12-14')
                      }
                    >
                      - 2020. 12. 14 ~ 2021. 02. 22 개인정보처리방침 (클릭)
                    </CursorSpan>
                    <CursorSpan
                      onClick={() =>
                        handleChangeSelectOptionClick('2020-11-09')
                      }
                    >
                      - 2020. 11. 09 ~ 2020. 12. 14 개인정보처리방침 (클릭)
                    </CursorSpan>
                    <CursorSpan
                      onClick={() =>
                        handleChangeSelectOptionClick('2020-06-22')
                      }
                    >
                      - 2020. 06. 22 ~ 2020. 11. 09 개인정보처리방침 (클릭)
                    </CursorSpan>
                    <CursorSpan
                      onClick={() =>
                        handleChangeSelectOptionClick('2020-05-25')
                      }
                    >
                      - 2020. 05. 25 ~ 2020. 06. 22 개인정보처리방침 (클릭)
                    </CursorSpan>
                    <CursorSpan
                      onClick={() =>
                        handleChangeSelectOptionClick('2019-08-15')
                      }
                    >
                      - 2019. 08. 15 ~ 2020. 05. 25 개인정보처리방침 (클릭)
                    </CursorSpan>
                  </PageBodyPre>
                </PageInnerContents>
              </>
            )}
            <PageInnerContents id="2021-02-22">
              <PageBodyPre ismobile={isMobile}>
                <br />
                {t('SP_PRIVACY_POLICY_02_22')}
                {i18n.language === 'en' ? (
                  <>
                    <CursorSpan
                      onClick={() =>
                        handleChangeSelectOptionClick('2020-12-14')
                      }
                    >
                      - 14 Dec 2020 ~ 22 Feb 2021 Privacy Policy (Click Here)
                    </CursorSpan>
                    <CursorSpan
                      onClick={() =>
                        handleChangeSelectOptionClick('2020-11-09')
                      }
                    >
                      - 09 Nov 2020 ~ 14 Dec 2020 Privacy Policy (Click Here)
                    </CursorSpan>
                    <CursorSpan
                      onClick={() =>
                        handleChangeSelectOptionClick('2020-06-22')
                      }
                    >
                      - 22 Jun 2020 ~ 09 Nov 2020 Privacy Policy (Click Here)
                    </CursorSpan>
                    <CursorSpan
                      onClick={() =>
                        handleChangeSelectOptionClick('2020-05-25')
                      }
                    >
                      - 25 May 2020 ~ 22 Jun 2020 Privacy Policy (Click Here)
                    </CursorSpan>
                    <CursorSpan
                      onClick={() =>
                        handleChangeSelectOptionClick('2019-08-15')
                      }
                    >
                      - 15 Aug 2019 ~ 25 May 2020 Privacy Policy (Click Here)
                    </CursorSpan>
                  </>
                ) : (
                  <>
                    <CursorSpan
                      onClick={() =>
                        handleChangeSelectOptionClick('2020-12-14')
                      }
                    >
                      - 2020. 12. 14 ~ 2021. 02. 22 개인정보처리방침 (클릭)
                    </CursorSpan>
                    <CursorSpan
                      onClick={() =>
                        handleChangeSelectOptionClick('2020-11-09')
                      }
                    >
                      - 2020. 11. 09 ~ 2020. 12. 14 개인정보처리방침 (클릭)
                    </CursorSpan>
                    <CursorSpan
                      onClick={() =>
                        handleChangeSelectOptionClick('2020-06-22')
                      }
                    >
                      - 2020. 06. 22 ~ 2020. 11. 09 개인정보처리방침 (클릭)
                    </CursorSpan>
                    <CursorSpan
                      onClick={() =>
                        handleChangeSelectOptionClick('2020-05-25')
                      }
                    >
                      - 2020. 05. 25 ~ 2020. 06. 22 개인정보처리방침 (클릭)
                    </CursorSpan>
                    <CursorSpan
                      onClick={() =>
                        handleChangeSelectOptionClick('2019-08-15')
                      }
                    >
                      - 2019. 08. 15 ~ 2020. 05. 25 개인정보처리방침 (클릭)
                    </CursorSpan>
                  </>
                )}
              </PageBodyPre>
            </PageInnerContents>
            {i18n.language === 'en' ? (
              <PageInnerContents id="2020-12-14">
                <PageBodyPre ismobile={isMobile}>
                  {` 
TmaxA&C Co., Ltd. (the “Company”) places a high importance on users’ privacy and thus have developed this privacy policy which applies to the processing of personal information of users of WAPL (www.wapl.ai). Please note that this privacy policy can be updated to comply with applicable laws and guidelines such as 『Personal Information Protection Act』 as well as to conform to changing policies of the Company. Users are therefore invited to visit the Company’s website periodically to take notice of any changes.

1. Purpose of Collecting and Using Personal Information
When applying for membership, a minimum amount of personal information is required for the Company to provide better services and user-adapted information.  
1.2.	 Under no circumstances will the Company request any sensitive information that may compromise users’ privacy (for example race, ideology/faith, political orientation, criminal records, or medical history). If obliged to do so, however, the Company will seek prior consent of users before collecting any such information.
1.3. The Company collects the following information.
1) User
	- Required: ID (email), password, name
2) Admin
	- Required: ID (email), password, name
3) Room Manager
	- Required: ID (email), password, name 
4) Member
- Required: ID (email), password, name
- Optional: Profile information (company phone number, email), personal information (date of birth, company/department, position/title), profile photo, list of friends
5) Information collected automatically during the use of services: IP address, cookies, date and time of visits, service usage record, inappropriate usage history, device information (for example terminal identification numbers and operating system).
1.4. Such collected information is used for the following purposes.
1) To identify and authenticate users
2) To fulfill the purposes of services
3) To provide specialized services (optional)
4) To provide customer support and consultation
5) To process complaints and deliver notifications
6) To send newsletters and promotional content (optional)
1.5. The Company may collect specific pieces of personal information that the Admin provides during:
- download and submission of “Add the entire organizational chart” form.

2. Retention Periods for Personal Information 
2.1 The Company makes it a rule to retain and utilize users’ information for the duration of the stipulated service period. All personal data will be immediately destroyed once their purposes are fulfilled or at the request of users. However, certain types of information shall be held for record retention purposes as required by the applicable laws specified below. In this case, subject information will not be used for any other purposes (such as marketing) than those imposed by legal requirements such as:

Records of contract or subscription withdrawal
In compliance with: Act on the Consumer Protection in Electronic Commerce, etc.
Retained for: 5 years
Records of payment and supply of goods
In compliance with: Act on the Consumer Protection in Electronic Commerce, etc.
Retained for: 5 years
Records of customer complaints and dispute resolution
In compliance with: Act on the Consumer Protection in Electronic Commerce, etc.
Retained for: 3 years
Records of website access
In compliance with: Protection of Communications Secrets Act
Retained for: 3 months
2.2. According to Article 10, Section 2 of the Terms of Use, any users who have not maintained an active account of the Company’s service for the past 1 year may be withdrawn from membership with prior notification. In this case, the Company will immediately delete the user and member data as required by applicable laws. 

3. Destruction of Personal Information
3.1. The Company will immediately destroy any information of which the retention period has expired or the purpose has been fulfilled.
3.2. All information of which the retention period has expired or the purpose has been fulfilled but needed to be held in accordance with certain legal requirements will be migrated to databases or other separate storage systems.
3.3. Personal information will be destroyed as follows:
1) Procedure of destruction
The Company selects the information needed to be destroyed and asks approval from the personal information protection officer before proceeding to destruction.
2) Methods of destruction
The data stored in electronic file format are destroyed in a manner that any related records cannot be recovered and those recorded in the form of written documentation are shredded or incinerated.
4. Sharing with Third Parties
4.1. The Company will process users’ personal information only within the scope specified in Article 1, “Purpose of Collecting and Using Personal Information”, and will not use any of the data beyond this scope without prior consent of the users. As a general rule, the Company does not provide users’ personal information to third parties.
4.2. However, the Company may exceptionally share certain personal information in the following cases.
1) When agreed by users
2) When required by applicable laws, or requested by authorities for investigation purposes.

5. Processing of Personal Information on Behalf of the Company
5.1. The Company may entrust the processing of personal information to a third party in order to provide better services to users. For this purpose, the Company has established basic requirements for ensuring the protection of users’ privacy, in compliance with applicable laws. 
The following third party can process personal information on behalf of
 the Company for the purposes specified below.
TmaxSpace: operation of customer service, data analytics and statistics processing, development of service and operation support

6. Rights of Users and Their Legal Representatives
6.1. Users can check and modify their personal information via “Account Settings” in the WAPL website at any time. If requested through email (wapl_support@tmax.co.kr), the Company will verify, correct, and delete the information as requested. Users have the rights to request the destruction, if there are any, of their personal data provided or entrusted to third parties/trustees. However, User ID cannot be modified. The modification or deletion of certain types of data may be prohibited or restricted if so required by some other legal requirements. In addition, any incorrect information requiring to be corrected at the request of users will remain inaccessible until the completion of correction, unless otherwise required by other legal requirements. If any inaccurate pieces of information have been already provided to third parties, the Company will notify them of the amendments.
6.2. Users can request the suspension of the processing of their personal information in the WAPL website at any time. In the following cases however, such request may be refused.
1) When bound by special provisions in legislation or certain legal obligations
2) When there exist some risks of causing harm to life or property and interests of other people
3) When it is impossible to provide the services specified in the use agreement without processing personal information, and in such a context the user has not clarified his/her intention to terminate the use agreement
6.3. Users can cancel their consent on the collection, use and sharing of personal information at any time. Requests for cancellation of such consent, data deletion and suspension of the processing of personal information shall be sent to WAPL Customer Support (wapl_support@tmax.co.kr). In certain cases, however, where it is necessary to retain user’s personal information according to applicable laws or provisions of the Terms of Use, there may be some restrictions in the processing of requests. In this case, users must present their IDs (email) to confirm identity, and may not be fully allowed to access the service. 

7. About Cookies Management
7.1. In order to provide user-adapted services, the Company stores service usage information and uses cookies on a regular basis. 
7.2. Cookies are small text files sent by the server of the website to the browser of the website user, and stored on the user’s computer.
1) Purpose of using cookies: The Company may provide customized services that are only available through the use of cookies, and the Company may use cookies to identify users and maintain users’ login status.
2) Allow or block cookies: Web browser Tools menu> Internet Options> Personal Information> Settings
3) Users can refuse to store cookies. In this case, there may be some restrictions in the customization of service.

8. Security Measures for Protecting Personal Information 
The Company takes the following measures to ensure the security of personal information.
8.1. Administrative measures: development and implementation of internal management plans, regular employee training, etc.
8.2. Technical measures: management of access rights for the personal information processing system, installation of access control system and security program, etc. 
8.3. Physical measures: access control in server room and data storage room, etc.

9. Contact Details of Personal Information Protection Officer
9.1. The Company has designated a personal information protection officer responsible for all privacy-related activities, handling users’ complaints and damage relief in relation to the processing of personal information.

[Personal Information Protection Officer]
Name: Jungmin Kim
Email: security@tmax.co.kr / Phone: 031-8018-9347

9.2. Users can contact this person for all privacy-related inquires, complaints and damage relief in relation to the services (or business) provided by the Company. The Company will provide a prompt response and action for users’ inquiries.

10. Remedies for Infringement of Rights and Interests
Users can contact the following organizations to inquire about possible damage relief in relation to any infringement of privacy. These organizations are public entities having no relation to the Company. Any users who are not satisfied with the Company's solutions to complaints or damage relief regarding their privacy concerns, or who seek further assistance may have recourse to these organizations. 
10.1. Personal Information Infringement Report Center
Website: privacy.kisa.or.kr
Phone: (without area code) 118
10.2. Personal Information Dispute Mediation Committee
Website: www.kopico.go.kr
Phone: (without area code) 1833-6972
10.3 Cyber Crime Investigation Division of the Supreme Prosecutors' Office 
Website: www.spo.go.kr
Phone: (without area code) 1301
10.4 Cyber Terror Response Center of the National Police Agency:
Website: http://cyberbureau.police.go.kr
Phone: (without area code) 182 

11. Privacy Policy Updates
This updated privacy policy takes effect on December 14, 2020.
`}
                  <CursorSpan
                    onClick={() => handleChangeSelectOptionClick('2020-11-09')}
                  >
                    - 09 Nov 2020 ~ 14 Dec 2020 Privacy Policy (Click Here)
                  </CursorSpan>
                  <CursorSpan
                    onClick={() => handleChangeSelectOptionClick('2020-06-22')}
                  >
                    - 22 Jun 2020 ~ 09 Nov 2020 Privacy Policy (Click Here)
                  </CursorSpan>
                  <CursorSpan
                    onClick={() => handleChangeSelectOptionClick('2020-05-25')}
                  >
                    - 25 May 2020 ~ 22 Jun 2020 Privacy Policy (Click Here)
                  </CursorSpan>
                  <CursorSpan
                    onClick={() => handleChangeSelectOptionClick('2019-08-15')}
                  >
                    - 15 Aug 2019 ~ 25 May 2020 Privacy Policy (Click Here)
                  </CursorSpan>
                </PageBodyPre>
              </PageInnerContents>
            ) : (
              <PageInnerContents id="2020-12-14">
                <PageBodyPre ismobile={isMobile}>
                  {` 
㈜티맥스에이앤씨 (이하 ‘회사)는 이용자님들의 개인정보를 매우 중요하게 생각하며  WAPL (www.wapl.ai)와 관련하여 아래와 같은 개인정보처리방침을 가지고 있습니다. 이 개인정보처리방침은 개인정보보호법 등 개인정보와 관련한 법령 또는 지침의 변경이 있는 경우 갱신되고, 회사 정책의 변화에 따라 달라질 수 있으니 이용자께서는 홈페이지에서 수시로 확인하여 주시기 바랍니다. 

1. 개인정보의 수집 및 이용 목적
1.1. 회사는 회원가입 시 서비스 제공과 편리하고 유익한 맞춤 정보를 제공하기 위해 필요한 최소한의 정보를 필수 사항으로 수집하고 있습니다.
1.2. 회사는 이용자의 소중한 인권을 침해할 우려가 있는 민감한 정보(인종, 사상 및 신조, 정치적 성향이나 범죄기록, 의료정보 등)는 어떠한 경우에도 수집하지 않으며, 만약 불가피하게 수집하는 경우에는 반드시 회원님에게 사전 동의를 구하겠습니다.
1.3. 회사가 수집하는 이용자의 개인정보는 다음과 같습니다.
1) 유저 정보
	-필수: 아이디(이메일), 비밀번호, 이름 
2) 어드민 정보
	-필수: 아이디(이메일), 비밀번호, 이름 
3) 룸관리자 정보
	-필수: 아이디(이메일), 비밀번호, 이름 
4) 멤버 정보
- 필수: 아이디(이메일), 비밀번호, 이름
- 선택: 프로필정보(회사 전화, 이메일), 
개인정보(생년월일, 소속회사/부서, 직위/직책), 프로필 사진, 친구목록
, 
5) 서비스 이용과정에서 자동 수집되는 정보: IP Address, 쿠키, 방문 일시, 서비스 이용 기록, 불량 이용 기록, 기기 정보(단말기 식별 번호, OS 정보 등)
1.4. 수집한 개인정보는 다음과 같은 목적으로 사용됩니다.
1) 개인 식별 및 본인확인
2) 서비스 본질적 기능 수행 
3) 특화 서비스 제공(선택)
4) 서비스 이용 상담 및 컨설팅
5) 민원 처리, 고지사항 전달
6) 뉴스레터, 프로모션 안내(선택)
1.5. 회사는 Admin으로부터 제공받아 ‘조직도 일괄추가’ 기능을 통해 개인정보를 수집하며, 경우에 따라 아래와 같은 방법으로 개인정보를 수집할 수 있습니다.
- 홈페이지 내 조직도 일괄 추가 양식 다운로드 및 등록을 통한 수집

2. 개인정보의 보유 및 이용기간
2.1 회사는 이용자의 개인정보를 원칙적으로 고지 및 약정한 기간 동안 보유 및 이용하며 개인정보의 수집 및 이용목적이 달성되거나 이용자의 파기 요청이 있는 경우 지체 없이 파기합니다. 단, 다음의 정보에 대해서는 아래의 이유로 명시한 기간 동안 보존합니다.
1) 관계법령의 규정에 의하여 보존할 필요가 있는 경우 법령에서 규정한 바에 따라 이용자의 개인정보를 보관하며 마케팅 등 다른 목적으로 이용하지 않습니다.
- 계약 또는 청약철회 등에 관한 기록
보존 이유: 전자상거래 등에서의 소비자보호에 관한 법률
보존 기간: 5 년
- 대금결제 및 재화 등의 공급에 관한 기록
보존 이유: 전자상거래 등에서의 소비자보호에 관한 법률
보존 기간: 5 년
- 소비자의 불만 또는 분쟁처리에 관한 기록
보존 이유: 전자상거래 등에서의 소비자보호에 관한 법률
보존 기간: 3 년
- 웹사이트 방문기록
보존 이유: 통신비밀보호법
보존 기간: 3 개월
2.2. 회사의 서비스를 1년 동안 이용(로그인)하지 않을 경우 이용자에게 사전통지하고 서비스 이용약관 제 10조 2항에 따라 탈퇴조치를 할 수 있습니다. 탈퇴조치를 취하는 경우, 회사는 관련 법령에 따라 회원을 포함한 멤버의 데이터를 즉시 삭제합니다.

3. 개인정보의 파기 절차 및 방법
3.1. 회사는 개인정보 보유기간의 경과, 처리 목적 달성 등 개인정보가 불필요하게 되었을 때에는 지체 없이 해당 개인정보를 파기합니다.
3.2. 이용자로부터 동의 받은 개인정보 보유기간이 경과하거나 처리 목적이 달성되었음에도 다른 법령에 따라 개인정보를 계속 보존하여야 하는 경우에는, 해당 개인정보를 데이터베이스(DB)로 옮기거나 보관장소를 달리하여 보존합니다.
3.3. 개인정보 파기의 절차 및 방법은 다음과 같습니다.
1) 파기절차
회사는 파기 사유가 발생한 개인정보를 선정하고, 회사의 개인정보 보호책임자의 승인을 받아 개인정보를 파기합니다.
2) 파기방법
회사는 전자적 파일 형태로 기록, 저장된 개인정보는 기록을 재생할 수 없도록 파기하며, 종이 문서에 기록, 저장된 개인정보는 분쇄기로 분쇄하거나 소각하여 파기합니다.

4. 개인정보의 제3자 제공
4.1. 회사는 이용자의 개인정보를 “제1조(개인정보의 수집 및 이용 목적)”에서 명시한 범위 내에서만 처리하며, 이용자의 사전 동의 없이 동 범위를 초과하여 이용하거나 원칙적으로 이용자의 개인정보를 제3자에게 제공하지 않습니다.
4.2. 다만, 아래의 경우에는 예외로 합니다.
1) 이용자들이 사전에 공개 또는 제3자 제공에 동의한 경우
2) 법령의 규정에 의거하거나, 수사 목적으로 법령에 정해진 절차와 방법에 따라 수사기관 및 감독당국의 요구가 있는 경우

5. 개인정보의 처리위탁
5.1. 회사는 원활하고 향상된 서비스를 위하여 개인정보 처리를 타인에게 위탁할 수 있습니다. 이 경우 회사는 관계 법령에 따라 위탁계약 시 개인정보가 안전하게 관리될 수 있도록 필요한 사항을 규정하고 있습니다.
회사의 개인정보 위탁처리 기관 및 위탁업무 내용은 아래와 같습니다.
2) ㈜티맥스스페이스: 고객센터 운영, 데이터 분석 및 통계 처리, 서비스개발 및 운영지원

6. 이용자 및 법정대리인의 권리와 그 행사방법
6.1. 이용자는 언제든지 WAPL 사이트의 “계정 설정”을 통하여 회원님의 개인정보를 열람, 정정 처리하실 수 있으며, 전자우편(wapl_support@tmax.co.kr) 로  요청하신 경우 열람, 정정, 삭제처리 해드리겠습니다. 이용자의 개인정보가 제3자에게 제공되거나 처리 위탁된 경우 이용자는 회사 또는 ‘제3자’/’수탁자’에게 파기를 요청할 수 있습니다. 단, 아이디는 정정이 불가능합니다. 기타 법률에 따라 정정 또는 삭제가 금지되거나 제한되어 있는 경우에는 해당 처리가 제한될 수 있습니다. 또한 개인정보 오류에 대한 정정 요청한 경우에는 다른 법률에 따라 개인정보의 제공을 요청 받는 경우가 아닌 한 정정을 완료하기 전까지 당해 개인정보를 이용 또는 제공하지 않습니다. 만약 잘못된 개인정보를 이미 제공한 경우에는 정정 처리 결과를 제 3자에게 통지하여 정정이 이루어지도록 하겠습니다.
6.2. 이용자는 언제든지 WAPL 사이트의 개인정보에 대하여 처리의 정지를 요구 할 수 있습니다. 다만 아래의 경우에 해당하는 경우 처리정지 요구를 거절할 수 있습니다.

1) 법률에 특별한 규정이 있거나 법령상 의무를 준수하기 위하여 불가피한 경우
2) 다른 사람의 생명·신체를 해할 우려가 있거나 다른 사람의 재산과 그 밖의 이익을 부당하게 침해할 우려가 있는 경우
3) 개인정보를 처리하지 아니하면 이용자와 약정한 서비스를 제공하지 못하는 등 계약의 이행이 곤란한 경우로서 이용자가 그 계약의 해지 의사를 명확하게 밝히지 아니한 경우
6.3. 회원가입 등을 통해 개인정보의 수집·이용·제공에 대해 회원님께서 동의하신 내용을 언제든지 철회할 수 있습니다. 개인정보 동의 철회 및 삭제, 처리 정지를 하고자 하는 경우 WAPL 고객센터 (wapl_support@tmax.co.kr)를 통해 요청할 수 있습니다.  다만 법률 또는 약관의 규정에 따라 회사가 이용자의 개인정보를 보존하여야 하는 경우에는 해당 처리가 제한될 수 있습니다. 이 경우 이용자는 본인 식별을 위하여 반드시 아이디(이메일) 등을 밝히셔야 하며, 철회로 인해 서비스에 다소 제약이 있거나 일부 서비스를 이용하지 못하실 수 있습니다

7. 쿠키(cookie)의 운영에 관한 사항
7.1. 회사는 이용자에게 개별적인 맞춤서비스를 제공하기 위해 이용정보를 저장하고 수시로 ‘쿠키(cookie)’를 사용합니다.
7.2. 쿠키는 웹사이트를 운영하는데 이용되는 서버(http)가 이용자의 컴퓨터 브라우저에게 보내는 소량의 정보이며 이용자들의 PC 컴퓨터 내의 하드디스크에 저장되기도 합니다.
1) 쿠키의 사용목적: 회사는 쿠키의 사용을 통해서만 가능한 특정된 맞춤형 서비스를 제공할 수 있으며, 회사는 회원을 식별하고 회원의 로그인 상태를 유지하기 위해 쿠키를 사용할 수 있습니다.
2) 쿠키의 설치∙운영 및 거부: 웹브라우저 상단의 도구>인터넷 옵션>개인정보 메뉴의 옵션 설정을 통해 쿠키 저장을 거부 할 수 있습니다.
3) 쿠키 저장을 거부할 경우 맞춤형 서비스 이용에 어려움이 발생할 수 있습니다.

8. 개인정보의 안전성 확보 조치
회사는 개인정보의 안전성 확보를 위해 다음과 같은 조치를 취하고 있습니다.
8.1. 관리적 조치: 내부관리계획 수립, 시행, 정기적 직원 교육 등
8.2. 기술적 조치: 개인정보처리시스템 등의 접근권한 관리, 접근통제시스템 설치, 보안프로그램 설치 등
8.3. 물리적 조치: 전산실, 자료보관실 등의 접근통제 등

9. 개인정보보호책임자의 연락처
9.1. 회사는 개인정보 처리에 관한 업무를 총괄해서 책임지고, 개인정보 처리와 관련한 이용자의 불만처리 및 피해구제 등을 위하여 아래와 같이 개인정보 보호책임자를 지정하고 있습니다.
[개인정보 보호책임자]
이름: 김정민
메일: security@tmax.co.kr / 전화번호: 031-8018-9347
9.2. 이용자께서는 회사의 서비스(또는 사업)을 이용하시면서 발생한 모든 개인정보 보호 관련 문의, 불만처리, 피해구제 등에 관한 사항을 개인정보 보호책임자에게 문의하실 수 있습니다. 회사는 이용자의 문의에 대해 지체 없이 답변 및 처리해드릴 것입니다.

10. 권익침해 구제방법
이용자는 아래의 기관에 대해 개인정보 침해에 대한 피해구제, 상담 등을 문의하실 수 있습니다. 아래의 기관은 회사와는 별개의 기관으로서, 회사의 자체적인 개인정보 불만처리, 피해구제 결과에 만족하지 못하시거나 보다 자세한 도움이 필요하시면 문의하여 주시기 바랍니다.
10.1. 개인정보 침해신고센터 
홈페이지: privacy.kisa.or.kr
전화: (국번없이) 118
10.2. 개인정보 분쟁조정위원회
홈페이지: www.kopico.go.kr
전화: (국번없이) 1833-6972 
10.3 대검찰청 사이버범죄수사단
홈페이지: www.spo.go.kr
전화: (국번없이) 1301
10.4 경찰청 사이버안전국
홈페이지: http://cyberbureau.police.go.kr
전화: (국번없이) 182 

11. 개인정보 처리방침 변경
이 개인정보 처리방침은 2020년 12월 14일부터 적용됩니다.`}
                  <CursorSpan
                    onClick={() => handleChangeSelectOptionClick('2020-11-09')}
                  >
                    - 2020. 11. 09 ~ 2020. 12. 14 개인정보처리방침 (클릭)
                  </CursorSpan>
                  <CursorSpan
                    onClick={() => handleChangeSelectOptionClick('2020-06-22')}
                  >
                    - 2020. 06. 22 ~ 2020. 11. 09 개인정보처리방침 (클릭)
                  </CursorSpan>
                  <CursorSpan
                    onClick={() => handleChangeSelectOptionClick('2020-05-25')}
                  >
                    - 2020. 05. 25 ~ 2020. 06. 22 개인정보처리방침 (클릭)
                  </CursorSpan>
                  <CursorSpan
                    onClick={() => handleChangeSelectOptionClick('2019-08-15')}
                  >
                    - 2019. 08. 15 ~ 2020. 05. 25 개인정보처리방침 (클릭)
                  </CursorSpan>
                </PageBodyPre>
              </PageInnerContents>
            )}
            {i18n.language === 'en' ? (
              <PageInnerContents id="2020-11-09">
                <PageBodyPre ismobile={isMobile}>
                  {`
TmaxA&C Co., Ltd. (the “Company”) places a high importance on users’ privacy and thus have developed this privacy policy which applies to the processing of personal information of users of TeeSpace (www.teespace.net). Please note that this privacy policy can be updated to comply with applicable laws and guidelines such as 『Personal Information Protection Act』 as well as to conform to changing policies of the Company. Users are therefore invited to visit the Company’s website periodically to take notice of any changes.

1. Purpose of Collecting and Using Personal Information
1.1. When applying for membership, a minimum amount of personal information is required for the Company to provide better services and user-adapted information.
1.2. Under no circumstances will the Company request any sensitive information that may compromise users’ privacy (for example race, ideology/faith, political orientation, criminal records, or medical history). If obliged to do so, however, the Company will seek prior consent of users before collecting any such information.
1.3. The Company collects the following information.
1) User
     - Required: ID (email), password, name
     - Optional: mobile phone number
2) Admin
     - Required: ID (email), password, name
     - Optional: mobile phone number
3) Room Manager
     - Required: ID (email), password, name
     - Optional: mobile phone number
4) Member
- Required: ID (email), password, name
- Optional: profile information (company phone number, mobile phone number, email), personal information (date of birth, company/department, position/title), profile photo, list of friends
5) Information collected automatically during the use of services: IP address, cookies, date and time of visits, service usage record, inappropriate usage history, device information (for example terminal identification numbers and operating system).
1.4. Such collected information is used for the following purposes.
1) To identify and authenticate users
2) To fulfill the purposes of services
3) To provide specialized services (optional)
4) To provide customer support and consultation
5) To process complaints and deliver notifications
6) To send newsletters and promotional content (optional)
1.5. The Company may collect specific pieces of personal information that the Admin provides during:
- download and submission of “Add the entire organizational chart” form.

2. Retention Periods for Personal Information 
2.1 The Company makes it a rule to retain and utilize users’ information for the duration of the stipulated service period. All personal data will be immediately destroyed once their purposes are fulfilled or at the request of users. However, certain types of information shall be held for record retention purposes as required by the applicable laws specified below. In this case, subject information will not be used for any other purposes (such as marketing) than those imposed by legal requirements such as:
1) Records of contract or subscription withdrawal
In compliance with: Act on the Consumer Protection in Electronic Commerce, etc.
Retained for: 5 years
2) Records of payment and supply of goods
In compliance with: Act on the Consumer Protection in Electronic Commerce, etc.
Retained for: 5 years
3) Records of customer complaints and dispute resolution
In compliance with: Act on the Consumer Protection in Electronic Commerce, etc.
Retained for: 3 years
4) Records of website access
In compliance with: Protection of Communications Secrets Act
Retained for: 3 months
2.2. According to Article 10, Section 2 of the Terms of Use, any users who have not maintained an active account (no login) of the Company’s service for the past 1 year may be withdrawn from membership with prior notification. In this case, the Company will immediately delete the user and member data as required by applicable laws.

3. Destruction of Personal Information
3.1. The Company will immediately destroy any information of which the retention period has expired or the purpose has been fulfilled.
3.2. All information of which the retention period has expired or the purpose has been fulfilled but needed to be held in accordance with certain legal requirements will be migrated to databases or other separate storage systems.
3.3. Personal information will be destroyed as follows:
1) Procedure of destruction
The Company selects the information needed to be destroyed and asks approval from the personal information protection officer before proceeding to destruction.
2) Methods of destruction
The data stored in electronic file format are destroyed in a manner that any related records cannot be recovered and those recorded in the form of written documentation are shredded or incinerated.

4. Sharing with Third Parties
4.1. The Company will process users’ personal information only within the scope specified in Article 1, “Purpose of Collecting and Using Personal Information”, and will not use any of the data beyond this scope without prior consent of the users. As a general rule, the Company does not provide users’ personal information to third parties.
4.2. However, the Company may exceptionally share certain personal information in the following cases.
1) When agreed by users
2) When required by applicable laws, or requested by authorities for investigation purposes.

5. Processing of Personal Information on Behalf of the Company
5.1. The Company may entrust the processing of personal information to a third party in order to provide better services to users. For this purpose, the Company has established basic requirements for ensuring the protection of users’ privacy, in compliance with applicable laws.
The following third parties can process personal information on behalf of the Company for the purposes specified below.
1) MTS Company: mobile authentication
2) TmaxSpace: operation of customer service, data analytics and statistics processing, development of service and operation support

6. Rights of Users and Their Legal Representatives
6.1. Users can check and modify their personal information via “Account Settings” in the TeeSpace website at any time. If requested through email (teesupport@tmax.co.kr), the Company will verify, correct, and delete the information as requested. Users have the rights to request the destruction, if there are any, of their personal data provided or entrusted to third parties/trustees. However, User ID cannot be modified. The modification or deletion of certain types of data may be prohibited or restricted if so required by some other legal requirements. In addition, any incorrect information requiring to be corrected at the request of users will remain inaccessible until the completion of correction, unless otherwise required by other legal requirements. If any inaccurate pieces of information have been already provided to third parties, the Company will notify them of the amendments.
6.2. Users can request the suspension of the processing of their personal information in the TeeSpace website at any time. In the following cases however, such request may be refused.
1) When bound by special provisions in legislation or certain legal obligations
2) When there exist some risks of causing harm to life or property and interests of other people
3) When it is impossible to provide the services specified in the use agreement without processing personal information, and in such a context the user has not clarified his/her intention to terminate the use agreement
6.3. Users can cancel their consent on the collection, use and sharing of personal information at any time. Requests for cancellation of such consent, data deletion and suspension of the processing of personal information shall be sent to TeeSpace Customer Support (teesupport@tmax.co.kr). In certain cases, however, where it is necessary to retain user’s personal information according to applicable laws or provisions of the Terms of Use, there may be some restrictions in the processing of requests. In this case, users must present their IDs (email) to confirm identity, and may not be fully allowed to access the service.

7. About Cookies Management
7.1. In order to provide user-adapted services, the Company stores service usage information and uses cookies on a regular basis. 
7.2. Cookies are small text files sent by the server of the website to the browser of the website user, and stored on the user’s computer.
1) Purpose of using cookies: The Company may provide customized services that are only available through the use of cookies, and the Company may use cookies to identify users and maintain users’ login status.
2) Allow or block cookies: Web browser Tools menu> Internet Options> Personal Information> Settings
3) Users can refuse to store cookies. In this case, there may be some restrictions in the customization of service.

8. Security Measures for Protecting Personal Information 
The Company takes the following measures to ensure the security of personal information.
8.1. Administrative measures: development and implementation of internal management plans, regular employee training, etc.
8.2. Technical measures: management of access rights for the personal information processing system, installation of access control system and security program, etc. 
8.3. Physical measures: access control in server room and data storage room, etc.

9. Contact Details of Personal Information Protection Officer
9.1. The Company has designated a personal information protection officer responsible for all privacy-related activities, handling users’ complaints and damage relief in relation to the processing of personal information.
 
[Personal Information Protection Officer]
Name: Jungmin Kim
Email: security@tmax.co.kr / Phone: 031-8018-9347

9.2. Users can contact this person for all privacy-related inquires, complaints and damage relief in relation to the services (or business) provided by the Company. The Company will provide a prompt response and action for users’ inquiries.

10. Remedies for Infringement of Rights and Interests
Users can contact the following organizations to inquire about possible damage relief in relation to any infringement of privacy. These organizations are public entities having no relation to the Company. Any users who are not satisfied with the Company's solutions to complaints or damage relief regarding their privacy concerns, or who seek further assistance may have recourse to these organizations. 
10.1. Personal Information Infringement Report Center
Website: privacy.kisa.or.kr
Phone: (without area code) 118
10.2. Personal Information Dispute Mediation Committee
Website: www.kopico.go.kr
Phone: (without area code) 1833-6972
10.3 Cyber Crime Investigation Division of the Supreme Prosecutors' Office 
Website: www.spo.go.kr
Phone: (without area code) 1301
10.4 Cyber Terror Response Center of the National Police Agency:
Website: http://cyberbureau.police.go.kr
Phone: (without area code) 182 

11. Privacy Policy Updates
This updated privacy policy takes effect on November 9, 2020.`}
                  <CursorSpan
                    onClick={() => handleChangeSelectOptionClick('2020-06-22')}
                  >
                    - 22 Jun 2020 ~ 09 Nov 2020 Privacy Policy (Click Here)
                  </CursorSpan>
                  <CursorSpan
                    onClick={() => handleChangeSelectOptionClick('2020-05-25')}
                  >
                    - 25 May 2020 ~ 22 Jun 2020 Privacy Policy (Click Here)
                  </CursorSpan>
                  <CursorSpan
                    onClick={() => handleChangeSelectOptionClick('2019-08-15')}
                  >
                    - 15 Aug 2019 ~ 25 May 2020 Privacy Policy (Click Here)
                  </CursorSpan>
                </PageBodyPre>
              </PageInnerContents>
            ) : (
              <PageInnerContents id="2020-11-09">
                <PageBodyPre ismobile={isMobile}>
                  {`
㈜티맥스에이앤씨 (이하 ‘회사)는 이용자님들의 개인정보를 매우 중요하게 생각하며 티스페이스(www.teespace.net)와 관련하여 아래와 같은 개인정보처리방침을 가지고 있습니다. 이 개인정보처리방침은 개인정보보호법 등 개인정보와 관련한 법령 또는 지침의 변경이 있는 경우 갱신되고, 회사 정책의 변화에 따라 달라질 수 있으니 이용자께서는 홈페이지에서 수시로 확인하여 주시기 바랍니다. 
1. 개인정보의 수집 및 이용 목적
1.1. 회사는 회원가입 시 서비스 제공과 편리하고 유익한 맞춤 정보를 제공하기 위해 필요한 최소한의 정보를 필수 사항으로 수집하고 있습니다.
1.2. 회사는 이용자의 소중한 인권을 침해할 우려가 있는 민감한 정보(인종, 사상 및 신조, 정치적 성향이나 범죄기록, 의료정보 등)는 어떠한 경우에도 수집하지 않으며, 만약 불가피하게 수집하는 경우에는 반드시 회원님에게 사전 동의를 구하겠습니다.
1.3. 회사가 수집하는 이용자의 개인정보는 다음과 같습니다.
1) 유저 정보
    -필수: 아이디(이메일), 비밀번호, 이름 
     -선택: 휴대폰 번호 
2) 어드민 정보
    -필수: 아이디(이메일), 비밀번호, 이름 
     -선택: 휴대폰 번호 
3) 룸관리자 정보
    -필수: 아이디(이메일), 비밀번호, 이름 
     -선택: 휴대폰 번호 
4) 멤버 정보
- 필수: 아이디(이메일), 비밀번호, 이름
- 선택: 프로필정보(회사 전화, 휴대폰 번호, 이메일), 
개인정보(생년월일, 소속회사/부서, 직위/직책), 프로필 사진, 친구목록
, 
5) 서비스 이용과정에서 자동 수집되는 정보: IP Address, 쿠키, 방문 일시, 서비스 이용 기록, 불량 이용 기록, 기기 정보(단말기 식별 번호, OS 정보 등)
1.4. 수집한 개인정보는 다음과 같은 목적으로 사용됩니다.
1) 개인 식별 및 본인확인
2) 서비스 본질적 기능 수행 
3) 특화 서비스 제공(선택)
4) 서비스 이용 상담 및 컨설팅
5) 민원 처리, 고지사항 전달
6) 뉴스레터, 프로모션 안내(선택)
1.5. 회사는 Admin으로부터 제공받아 ‘조직도 일괄추가’ 기능을 통해 개인정보를 수집하며, 경우에 따라 아래와 같은 방법으로 개인정보를 수집할 수 있습니다.
- 홈페이지 내 조직도 일괄 추가 양식 다운로드 및 등록을 통한 수집

2. 개인정보의 보유 및 이용기간
2.1 회사는 이용자의 개인정보를 원칙적으로 고지 및 약정한 기간 동안 보유 및 이용하며 개인정보의 수집 및 이용목적이 달성되거나 이용자의 파기 요청이 있는 경우 지체 없이 파기합니다. 단, 다음의 정보에 대해서는 아래의 이유로 명시한 기간 동안 보존합니다.
1) 관계법령의 규정에 의하여 보존할 필요가 있는 경우 법령에서 규정한 바에 따라 이용자의 개인정보를 보관하며 마케팅 등 다른 목적으로 이용하지 않습니다.
- 계약 또는 청약철회 등에 관한 기록
보존 이유: 전자상거래 등에서의 소비자보호에 관한 법률
보존 기간: 5 년
- 대금결제 및 재화 등의 공급에 관한 기록
보존 이유: 전자상거래 등에서의 소비자보호에 관한 법률
보존 기간: 5 년
- 소비자의 불만 또는 분쟁처리에 관한 기록
보존 이유: 전자상거래 등에서의 소비자보호에 관한 법률
보존 기간: 3 년
- 웹사이트 방문기록
보존 이유: 통신비밀보호법
보존 기간: 3 개월
2.2. 회사의 서비스를 1년 동안 이용(로그인)하지 않을 경우 이용자에게 사전통지하고 서비스 이용약관 제 10조 2항에 따라 탈퇴조치를 할 수 있습니다. 탈퇴조치를 취하는 경우, 회사는 관련 법령에 따라 회원을 포함한 멤버의 데이터를 즉시 삭제합니다.

3. 개인정보의 파기 절차 및 방법
3.1. 회사는 개인정보 보유기간의 경과, 처리 목적 달성 등 개인정보가 불필요하게 되었을 때에는 지체 없이 해당 개인정보를 파기합니다.
3.2. 이용자로부터 동의 받은 개인정보 보유기간이 경과하거나 처리 목적이 달성되었음에도 다른 법령에 따라 개인정보를 계속 보존하여야 하는 경우에는, 해당 개인정보를 데이터베이스(DB)로 옮기거나 보관장소를 달리하여 보존합니다.
3.3. 개인정보 파기의 절차 및 방법은 다음과 같습니다.
1) 파기절차
회사는 파기 사유가 발생한 개인정보를 선정하고, 회사의 개인정보 보호책임자의 승인을 받아 개인정보를 파기합니다.
2) 파기방법
회사는 전자적 파일 형태로 기록, 저장된 개인정보는 기록을 재생할 수 없도록 파기하며, 종이 문서에 기록, 저장된 개인정보는 분쇄기로 분쇄하거나 소각하여 파기합니다.

4. 개인정보의 제3자 제공
4.1. 회사는 이용자의 개인정보를 “제1조(개인정보의 수집 및 이용 목적)”에서 명시한 범위 내에서만 처리하며, 이용자의 사전 동의 없이 동 범위를 초과하여 이용하거나 원칙적으로 이용자의 개인정보를 제3자에게 제공하지 않습니다.
4.2. 다만, 아래의 경우에는 예외로 합니다.
1) 이용자들이 사전에 공개 또는 제3자 제공에 동의한 경우
2) 법령의 규정에 의거하거나, 수사 목적으로 법령에 정해진 절차와 방법에 따라 수사기관 및 감독당국의 요구가 있는 경우

5. 개인정보의 처리위탁
5.1. 회사는 원활하고 향상된 서비스를 위하여 개인정보 처리를 타인에게 위탁할 수 있습니다. 이 경우 회사는 관계 법령에 따라 위탁계약 시 개인정보가 안전하게 관리될 수 있도록 필요한 사항을 규정하고 있습니다.
회사의 개인정보 위탁처리 기관 및 위탁업무 내용은 아래와 같습니다.
1) ㈜엠티에스컴퍼니 :  휴대폰 본인 인증
2) ㈜티맥스스페이스: 고객센터 운영, 데이터 분석 및 통계 처리, 서비스개발 및 운영지원

6. 이용자 및 법정대리인의 권리와 그 행사방법
6.1. 이용자는 언제든지 티스페이스 사이트의 “계정 설정”을 통하여 회원님의 개인정보를 열람, 정정 처리하실 수 있으며, 전자우편(teesupport@tmax.co.kr)로  요청하신 경우 열람, 정정, 삭제처리 해드리겠습니다. 이용자의 개인정보가 제3자에게 제공되거나 처리 위탁된 경우 이용자는 회사 또는 ‘제3자’/’수탁자’에게 파기를 요청할 수 있습니다. 단, 아이디는 정정이 불가능합니다. 기타 법률에 따라 정정 또는 삭제가 금지되거나 제한되어 있는 경우에는 해당 처리가 제한될 수 있습니다. 또한 개인정보 오류에 대한 정정 요청한 경우에는 다른 법률에 따라 개인정보의 제공을 요청 받는 경우가 아닌 한 정정을 완료하기 전까지 당해 개인정보를 이용 또는 제공하지 않습니다. 만약 잘못된 개인정보를 이미 제공한 경우에는 정정 처리 결과를 제 3자에게 통지하여 정정이 이루어지도록 하겠습니다.
6.2. 이용자는 언제든지 티스페이스 사이트의 개인정보에 대하여 처리의 정지를 요구 할 수 있습니다. 다만 아래의 경우에 해당하는 경우 처리정지 요구를 거절할 수 있습니다.

1) 법률에 특별한 규정이 있거나 법령상 의무를 준수하기 위하여 불가피한 경우
2) 다른 사람의 생명·신체를 해할 우려가 있거나 다른 사람의 재산과 그 밖의 이익을 부당하게 침해할 우려가 있는 경우
3) 개인정보를 처리하지 아니하면 이용자와 약정한 서비스를 제공하지 못하는 등 계약의 이행이 곤란한 경우로서 이용자가 그 계약의 해지 의사를 명확하게 밝히지 아니한 경우
6.3. 회원가입 등을 통해 개인정보의 수집·이용·제공에 대해 회원님께서 동의하신 내용을 언제든지 철회할 수 있습니다. 개인정보 동의 철회 및 삭제, 처리 정지를 하고자 하는 경우 티스페이스 고객센터 (teesupport@tmax.co.kr)를 통해 요청할 수 있습니다.  다만 법률 또는 약관의 규정에 따라 회사가 이용자의 개인정보를 보존하여야 하는 경우에는 해당 처리가 제한될 수 있습니다. 이 경우 이용자는 본인 식별을 위하여 반드시 아이디(이메일) 등을 밝히셔야 하며, 철회로 인해 서비스에 다소 제약이 있거나 일부 서비스를 이용하지 못하실 수 있습니다

7. 쿠키(cookie)의 운영에 관한 사항
7.1. 회사는 이용자에게 개별적인 맞춤서비스를 제공하기 위해 이용정보를 저장하고 수시로 ‘쿠키(cookie)’를 사용합니다.
7.2. 쿠키는 웹사이트를 운영하는데 이용되는 서버(http)가 이용자의 컴퓨터 브라우저에게 보내는 소량의 정보이며 이용자들의 PC 컴퓨터 내의 하드디스크에 저장되기도 합니다.
1) 쿠키의 사용목적: 회사는 쿠키의 사용을 통해서만 가능한 특정된 맞춤형 서비스를 제공할 수 있으며, 회사는 회원을 식별하고 회원의 로그인 상태를 유지하기 위해 쿠키를 사용할 수 있습니다.
2) 쿠키의 설치∙운영 및 거부: 웹브라우저 상단의 도구>인터넷 옵션>개인정보 메뉴의 옵션 설정을 통해 쿠키 저장을 거부 할 수 있습니다.
3) 쿠키 저장을 거부할 경우 맞춤형 서비스 이용에 어려움이 발생할 수 있습니다.

8. 개인정보의 안전성 확보 조치
회사는 개인정보의 안전성 확보를 위해 다음과 같은 조치를 취하고 있습니다.
8.1. 관리적 조치: 내부관리계획 수립, 시행, 정기적 직원 교육 등
8.2. 기술적 조치: 개인정보처리시스템 등의 접근권한 관리, 접근통제시스템 설치, 보안프로그램 설치 등
8.3. 물리적 조치: 전산실, 자료보관실 등의 접근통제 등

9. 개인정보보호책임자의 연락처
9.1. 회사는 개인정보 처리에 관한 업무를 총괄해서 책임지고, 개인정보 처리와 관련한 이용자의 불만처리 및 피해구제 등을 위하여 아래와 같이 개인정보 보호책임자를 지정하고 있습니다.
[개인정보 보호책임자]
이름: 김정민
메일: security@tmax.co.kr / 전화번호: 031-8018-9347
9.2. 이용자께서는 회사의 서비스(또는 사업)을 이용하시면서 발생한 모든 개인정보 보호 관련 문의, 불만처리, 피해구제 등에 관한 사항을 개인정보 보호책임자에게 문의하실 수 있습니다. 회사는 이용자의 문의에 대해 지체 없이 답변 및 처리해드릴 것입니다.

10. 권익침해 구제방법
이용자는 아래의 기관에 대해 개인정보 침해에 대한 피해구제, 상담 등을 문의하실 수 있습니다. 아래의 기관은 회사와는 별개의 기관으로서, 회사의 자체적인 개인정보 불만처리, 피해구제 결과에 만족하지 못하시거나 보다 자세한 도움이 필요하시면 문의하여 주시기 바랍니다.
10.1. 개인정보 침해신고센터 
홈페이지: privacy.kisa.or.kr
전화: (국번없이) 118
10.2. 개인정보 분쟁조정위원회
홈페이지: www.kopico.go.kr
전화: (국번없이) 1833-6972 
10.3 대검찰청 사이버범죄수사단
홈페이지: www.spo.go.kr
전화: (국번없이) 1301
10.4 경찰청 사이버안전국
홈페이지: http://cyberbureau.police.go.kr
전화: (국번없이) 182 

11. 개인정보 처리방침 변경
이 개인정보 처리방침은 2020년 11월 09일부터 적용됩니다.
`}
                  <CursorSpan
                    onClick={() => handleChangeSelectOptionClick('2020-06-22')}
                  >
                    - 2020. 06. 22 ~ 2020. 11. 09 개인정보처리방침 (클릭)
                  </CursorSpan>
                  <CursorSpan
                    onClick={() => handleChangeSelectOptionClick('2020-05-25')}
                  >
                    - 2020. 05. 25 ~ 2020. 06. 22 개인정보처리방침 (클릭)
                  </CursorSpan>
                  <CursorSpan
                    onClick={() => handleChangeSelectOptionClick('2019-08-15')}
                  >
                    - 2019. 08. 15 ~ 2020. 05. 25 개인정보처리방침 (클릭)
                  </CursorSpan>
                </PageBodyPre>
              </PageInnerContents>
            )}
            {i18n.language === 'en' ? (
              <PageInnerContents id="2020-06-22">
                <PageBodyPre ismobile={isMobile}>
                  {`
TmaxA&C Co., Ltd. (the “Company”) places a high importance on users’ privacy and thus have developed this privacy policy which applies to the processing of personal information of users of TeeSpace (www.teespace.com). Please note that this privacy policy can be updated to comply with applicable laws and guidelines such as『Act on Promotion of Information and Communications Network Utilization and Information Protection, etc.』and『Personal Information Protection Act』as well as to conform to changing policies of the Company. Users are therefore invited to visit the Company’s website periodically to take notice of any changes.

1. Purpose of Collecting and Using Personal Information
1.1. When applying for membership, a minimum amount of personal information is required for the Company to provide better services and user-adapted information.
1.2. Under no circumstances will the Company request any sensitive information that may compromise users’ privacy (for example race, ideology/faith, political orientation, criminal records, or medical history). If obliged to do so, however, the Company will seek prior consent of users before collecting any such information. 
1.3. The Company collects the following information.
    1) When: applying for membership
      Which: 
        - Required: ID, password, name, and mobile phone number
        - Optional: email
    2) When: using services
      Which: 
        - Required: ID, password, name, mobile phone number, nickname (if any), profile photo, friends list, and service usage history
        - Optional: email, date of birth, sex, occupation, company, and address
    3) When: as needed
      Which: 
        - Information collected automatically during the use of services: IP address, cookies, date and time of visits, service usage records, inappropriate usage history, and device information
        - Customer support or dispute resolution-related information: consultation records
1.4. Such collected information is used:
    1) For: identifying users
      Which: ID and password
    2) For: authenticating users
      Which: email and mobile phone number
    3) For: fulfilling the fundamental functions of services 
Which: name, ID, password, phone number (of TeeSpace members only), nickname (if any), profile photo, friends list, service usage history
    4) For: providing specialized services (optional)
      Which: date of birth, sex, occupation, company, address
    5) For: communicating and delivering services, sending newsletters and promotional content, and handling complaints
      Which: ID, name, and email
* Examples: 
- Functions such as sending messages, and adding and recommending friends
- Functions such as browsing and adding users
- Developing and releasing new services, delivering notifications and handling inquiries
1.5. The Company collects personal information when users apply for membership, setting their accounts, paying membership fees, communicating with our customer support, etc.

2. Retention Periods for Personal Information 
2.1 The Company makes it a rule to retain and utilize users’ information for the duration of the stipulated service period. All personal data will be immediately destroyed once their purposes are fulfilled or at the request of users. However, certain types of information shall be held for record retention purposes as required by the applicable laws specified below. In this case, subject information will not be used for any other purposes (such as marketing) than those imposed by legal requirements such as:

1) Responding to a subpoena for investigation 
- In compliance with: Protection of Communications Secrets Act
   - Providing: log records, IP address, etc. 
   - Retained for: 3 months
2) Customer complaints and dispute resolution
- In compliance with: Act on the Consumer Protection in Electronic Commerce, etc.
- Providing: customer identification data, records of dispute resolution, etc.
- Retained for: 3 years 
3) Payment and supply of goods 
- In compliance with: Act on the Consumer Protection in Electronic Commerce, etc.
   - Providing: customer identification data, records of canceling contract/subscription, etc.  
- Retained for: 5 years
4) Canceling contract/subscription
- In compliance with: Act on the Consumer Protection in Electronic Commerce, etc.
   - Providing: customer identification data, records of canceling contract/subscription
   - Retained for: 5 years

2.2 Any user accounts of the Company’s service that have not been used (logged in) for the past 1 year will be deactivated with prior notification, and their personal data will be retained in a separate storage for 1 year.

3. Destruction of Personal Information
3.1. The Company will immediately destroy any information of which the retention period has expired or the purpose has been fulfilled.
3.2. All information of which the retention period has expired or the purpose has been fulfilled but needed to be held in accordance with certain legal requirements will be migrated to databases or other separate storage systems.
3.3. Personal information will be destroyed as follows:
1) Procedure of destruction
The Company selects the information needed to be destroyed and asks approval from the personal information protection officer before proceeding to destruction.
2) Methods of destruction
The data stored in electronic file format are destroyed in a manner that any related records cannot be recovered and those recorded in the form of written documentation are shredded or incinerated.

4. Sharing with Third Parties
4.1. The Company will process users’ personal information only within the scope specified in Article 1, “Purpose of Collecting and Using Personal Information”, and will not use any of the data beyond this scope without prior consent of the users. As a general rule, the Company does not provide users’ personal information to third parties.
4.2. However, the Company may exceptionally share certain personal information in the following cases.
1) When agreed by users
2) When required by applicable laws, or requested by authorities for investigation purposes.

5. Processing of Personal Information on Behalf of the Company
5.1. The Company may entrust the processing of personal information to a third party in order to provide better services to users. In this case, the Company provides users with each of the following information to seek their consent. The same applies when any of the following is modified.
1) The entity that will process personal information on behalf of the Company.
2) Details about processing of personal information performed on behalf of the Company. 
5.2. To comply with the contract on information and communication services and to improve the user experience, the Company may, when necessary, entrust the processing of personal data to a third party, by making each of the above information public, without prior notification and user consent.
5.3. The Company entrust the processing of personal information as follows, while taking necessary measures for the personal information to be securely managed in accordance with relevant laws and regulations. The Company evaluates the entrusted entity’s capability of securing personal information, and periodically checks whether the entity complies with its obligations to securely manage and destroy personal information. The scope of personal information that can be processed on behalf of the Company is limited to the minimum necessary for supporting smooth operations of services.
- Entrusted entity: MTS Company
- Entrusted operations: SMS authentication 

6. Rights of Users and Their Legal Representatives
6.1. Users can check and modify their personal information via “Account Settings” in the TeeSpace website at any time. When requested through an email or written document, the Company will check, modify or delete the information as requested. Users have the rights to request the destruction, if there are any, of their personal data provided or entrusted to third parties/trustees. User’s name and ID cannot be modified, but modifying user’s name can be exceptionally allowed if the name has been legally changed. The modification or deletion of certain types of data may be prohibited or restricted if so required by some other legal requirements. In addition, any incorrect information that needs to be corrected at the request of users will remain inaccessible until the completion of correction, unless otherwise required by other legal requirements. If any inaccurate pieces of information have been already provided to third parties, the Company will notify them of the amendments.
6.2. Users can request the suspension of the processing of their personal information in the TeeSpace website at any time. In the following cases, however, such request may be refused.
1) When bound by special provisions in legislation or certain legal obligations
2) When there exist some risks of causing harm to life or property and interests of other people
3) When it is impossible to provide the services specified in the use agreement without processing personal information, and in such a context the user has not clarified his/her intention to terminate the use agreement
6.3. Users can cancel their consent on the collection, use and sharing of personal information at any time. If you want to cancel your consent, use the “Canceling Services” menu in the Company’s website, or send a cancellation letter or email, to which the Company will promptly respond by deleting the user’s personal data and taking other necessary actions. In certain cases, however, where it is necessary to retain user’s personal information according to applicable laws or provisions of the Terms of Use, there may be some restrictions in the processing of such requests. In this case, users must present their IDs (email) to confirm identity, and may not be fully allowed to access the service. 

7. About Cookies Management
7.1. In order to provide user-adapted services, the Company stores service usage information and uses cookies on a regular basis. 
7.2. Cookies are small text files sent by the server of the website to the browser of the website user, and stored on the user’s computer.
1) Purpose of using cookies: The Company can provide customized services that are only available through the use of cookies, and the Company may use cookies to identify users and maintain users’ login status.
2) Allow or block cookies: Web browser Tools menu> Internet Options> Personal Information> Settings
3) Users can refuse to store cookies. In this case, there may be some restrictions in the customization of service.

8. Security Measures for Protecting Personal Information 
The Company takes the following measures to ensure the security of personal information.
8.1. Administrative measures: development and implementation of internal management plans, regular employee training, etc.
8.2. Technical measures: management of access rights for the personal information processing system, installation of access control system and security program, etc. 
8.3. Physical measures: access control to server room and data storage room, etc.

9. Contact Details of Personal Information Protection Officer
9.1. The Company has designated a personal information protection officer and manager who are responsible for all privacy-related activities, handling users’ complaints and damage relief in relation to the processing of personal information.
 
[Personal Information Protection Officer]
Name: Heedo Heo
Email: security@tmax.co.kr / Phone: 031-8018-9347

[Personal Information Protection Manager]
Name: Byunghwa Park
Email: security@tmax.co.kr / Phone: 031-8018-9347
9.2. Users can contact these persons for all privacy-related inquires, complaints and damage relief in relation to the services (or business) provided by the Company. The Company will provide a prompt response and action for users’ inquiries.

10. Remedies for Infringement of Rights and Interests
Users can contact the following organizations to inquire about possible damage relief in relation to any infringement of privacy. These organizations are public entities having no relation to the Company. Any users who are not satisfied with the Company's solutions to complaints or damage relief regarding their privacy concerns, or who seek further assistance may have recourse to these organizations. 

10.1. Personal Information Infringement Report Center
Website: privacy.kisa.or.kr
Phone: (without area code) 118
10.2. Personal Information Dispute Mediation Committee
Website: www.kopico.go.kr
Phone: (without area code) 1833-6972
10.3 Cyber Crime Investigation Division of the Supreme Prosecutors' Office 
Website: www.spo.go.kr
Phone: (without area code) 1301
10.4 Cyber Terror Response Center of the National Police Agency:
Website: http://cyberbureau.police.go.kr
Phone: (without area code) 182 

11. Privacy Policy Updates
This updated privacy policy takes effect on June 22, 2020.`}
                  <CursorSpan
                    onClick={() => handleChangeSelectOptionClick('2020-05-25')}
                  >
                    - 25 May 2020 ~ 22 Jun 2020 Privacy Policy (Click Here)
                  </CursorSpan>
                  <CursorSpan
                    onClick={() => handleChangeSelectOptionClick('2019-08-15')}
                  >
                    - 15 Aug 2019 ~ 25 May 2020 Privacy Policy (Click Here)
                  </CursorSpan>
                </PageBodyPre>
              </PageInnerContents>
            ) : (
              <PageInnerContents id="2020-06-22">
                <PageBodyPre ismobile={isMobile}>
                  {`
㈜티맥스에이앤씨(이하 ‘회사)는 이용자님들의 개인정보를 매우 중요하게 생각하며 티스페이스(www.teespace.com)와 관련하여 아래와 같은 개인정보처리방침을 가지고 있습니다. 이 개인정보처리방침은 정보통신망 이용촉진 및 정보보호 등에 관한 법률, 개인정보보호법 등 개인정보와 관련한 법령 또는 지침의 변경이 있는 경우 갱신되고, 회사 정책의 변화에 따라 달라질 수 있으니 이용자께서는 홈페이지에서 수시로 확인하여 주시기 바랍니다. 티스페이스의 개인정보처리방침은 다음과 같은 내용을 담고 있습니다.

1. 개인정보의 수집 및 이용 목적
1.1. 회사는 회원가입 시 서비스 제공과 편리하고 유익한 맞춤 정보를 제공하기 위해 필요한 최소한의 정보를 필수 사항으로 수집하고 있습니다.
1.2. 회사는 이용자의 소중한 인권을 침해할 우려가 있는 민감한 정보(인종, 사상 및 신조, 정치적 성향 이나 범죄기록, 의료정보 등)는 어떠한 경우에도 수집하지 않으며, 만약 불가피하게 수집하는 경우에는 반드시 회원님에게 사전 동의를 구하겠습니다.
1.3. 회사가 수집하는 이용자의 개인정보는 다음과 같습니다.
    1) 수집시기 : 회원가입 시
        수집항목 : 
        -필수: 아이디, 비밀번호, 이름, 휴대폰 번호
        -선택: 이메일
    2) 수집시기 : 서비스 이용 시
        수집항목 : 
        - 필수: 아이디, 비밀번호, 이름, 휴대폰번호, 별명(지정 시), 프로필 사진, 친구목록, 서비스 이용 내역
        - 선택: 이메일, 생년월일, 성별, 직업, 소속, 주소
    3) 수집시기 : 기타
        수집항목 : 
        - 서비스 이용과정에서 자동 수집되는 정보: IP Address, 쿠키, 방문 일시, 서비스 이용 기록, 불량 이용 기록, 기기 정보
        - 고객 분쟁처리 및 상담 진행 시: 상담 내역
1.4. 수집한 개인정보는 다음과 같은 목적으로 사용됩니다.
    1) 수집목적 : 개인 식별
        수집항목 : 아이디, 비밀번호
    2) 수집목적 : 본인확인
        수집항목 : 이메일, 휴대폰번호
    3) 수집목적 : 서비스 본질적 기능 수행 
        수집항목 : 이름, 아이디, 비밀번호, 전화번호(티스페이스 가입자의 경우에 한함), 별명(지정시), 프로필 사진, 친구 목록, 서비스 이용 내역
    4) 수집목적 : 특화 서비스 제공(선택)
        수집항목 : 생년월일, 성별, 직업, 소속, 주소
    5) 수집목적 : 서비스 제공을 위한 연락 및 안내, 뉴스레터, 프로모션 안내, 민원 처리
        수집항목 : 아이디, 이름, 이메일
*예시 : 
- 메시지 전송, 친구등록 및 추천 기능의 제공
- 이용자 검색, 등록 등의 기능 제공
- 신규 서비스 개발 및 제공, 공지사항 및 문의사항 등 처리 및 전달
1.5. 회사는 회원가입, 계정 설정, 멤버십 결제, 고객 상담 등을 통하여 개인정보를 수집합니다.

2. 개인정보의 보유 및 이용기간
2.1 회사는 이용자의 개인정보를 원칙적으로 고지 및 약정한 기간 동안 보유 및 이용하며 개인정보의 수집 및 이용목적이 달성되거나 이용자의 파기 요청이 있는 경우 지체 없이 파기합니다. 단, 다음의 정보에 대해서는 아래의 이유로 명시한 기간 동안 보존합니다.
1) 관계법령의 규정에 의하여 보존할 필요가 있는 경우 법령에서 규정한 바에 따라 이용자의 개인정보를 보관하며 마케팅 등 다른 목적으로 이용하지 않습니다.
- 관계 법률 : 통신비밀보호법
    목적 : 법원의 영장을 받아 수사기관이 요청 시
    수집항목 : 로그기록, IP 등 제공
    보유기간 : 3개월
- 관계 법률 : 전자상거래등에서의 소비자보호에 관한 법률
    목적 : 소비자의 불만 또는 분쟁처리에 관한 기록
    수집항목 : 소비자 식별정보, 분쟁처리 기록 등
    보유기간 : 3년 
- 관계 법률 : 전자상거래등에서의 소비자보호에 관한 법률
    목적 : 대금결제 및 재화 등의 공급에 관한 기록
    수집항목 : 소비자 식별정보, 계약/청약철회 기록 등 
    보유기간 : 5년
- 관계 법률 : 전자상거래등에서의 소비자보호에 관한 법률
    목적 : 계약 또는 청약철회 등에 관한 기록
    수집항목 : 소비자 식별정보, 계약/청약철회 기록 등 
    보유기간 : 5년
2.2 회사의 서비스를 1년 동안 이용(로그인)하지 않을 경우 이용자에게 사전통지하고 휴면계정으로 전환하고 개인정보 분리 저장한 뒤 1년이 경과하는 시점까지 보관됩니다. 

3. 개인정보의 파기 절차 및 방법
3.1. 회사는 개인정보 보유기간의 경과, 처리 목적 달성 등 개인정보가 불필요하게 되었을 때에는 지체 없이 해당 개인정보를 파기합니다.
3.2. 이용자로부터 동의 받은 개인정보 보유기간이 경과하거나 처리 목적이 달성되었음에도 다른 법령에 따라 개인정보를 계속 보존하여야 하는 경우에는, 해당 개인정보를 데이터베이스(DB)로 옮기거나 보관장소를 달리하여 보존합니다.
3.3. 개인정보 파기의 절차 및 방법은 다음과 같습니다.
1) 파기절차
회사는 파기 사유가 발생한 개인정보를 선정하고, 회사의 개인정보 보호책임자의 승인을 받아 개인정보를 파기합니다.
2) 파기방법
회사는 전자적 파일 형태로 기록, 저장된 개인정보는 기록을 재생할 수 없도록 파기하며, 종이 문서에 기록, 저장된 개인정보는 분쇄기로 분쇄하거나 소각하여 파기합니다.

4. 개인정보의 제3자 제공
4.1. 회사는 이용자의 개인정보를 “제1조(개인정보의 수집 및 이용 목적)”에서 명시한 범위 내에서만 처리하며, 이용자의 사전 동의 없이 동 범위를 초과하여 이용하거나 원칙적으로 이용자의 개인정보를 제3자에게 제공하지 않습니다.
4.2. 다만, 아래의 경우에는 예외로 합니다.
1) 이용자들이 사전에 공개 또는 제3자 제공에 동의한 경우
2) 법령의 규정에 의거하거나, 수사 목적으로 법령에 정해진 절차와 방법에 따라 수사기관 및 감독당국의 요구가 있는 경우

5. 개인정보의 처리위탁
5.1. 회사는 원활하고 향상된 서비스를 위하여 개인정보 처리를 타인에게 위탁할 수 있습니다. 이 경우 회사는 사전에 다음 각 호의 사항 모두를 이용자에게 미리 알리고 동의를 받습니다. 다음 각 호의 어느 하나의 사항이 변경되는 경우에도 같습니다.
1) 개인정보 처리위탁을 받는 자
2) 개인정보 처리위탁을 하는 업무의 내용
5.2. 회사는 정보통신서비스의 제공에 관한 계약을 이행하고 이용자 편의 증진 등을 위하여 필요한 경우 개인정보처리방침에 따라 각 사항을 공개함으로써 고지절차와 동의절차를 거치지 아니하고 개인정보 처리를 타인에게 위탁할 수 있습니다
5.3. 회사는 개인정보의 처리와 관련하여 아래와 같이 업무를 위탁하고 있으며, 관계법령에 따라 위탁 계약 시 개인정보가 안전하게 관리될 수 있도록 필요한 조치를 하고 있습니다. 회사는 위탁 계약 시 수탁자의 개인정보 보호조치 능력을 고려하고, 개인정보의 안전한 관리 및 파기 등 수탁자의 의무 이행 여부를 주기적으로 확인합니다. 또한 위탁처리하는 정보는 원활한 서비스를 제공하기 위하여 필요한 최소한의 정보에 국한됩니다.
- 수탁업체 : (주)엠티에스컴퍼니
- 위탁업무 내용 : 문자, 휴대폰 인증

6. 이용자 및 법정대리인의 권리와 그 행사방법
6.1. 이용자는 언제든지 티스페이스 사이트의 “계정 설정”을 통하여 회원님의 개인정보를 열람, 정정 처리하실 수 있으며, 전자우편 또는 서면으로 요청하신 경우 열람, 정정, 삭제처리 해드리겠습니다. 이용자의 개인정보가 제3자에게 제공되거나 처리위탁된 경우 이용자는 회사 또는 ‘제3자’/’수탁자’에게 파기를 요청할 수 있습니다. 단, 이름, 아이디는 정정이 불가능하며, 개명으로 인한 이름 변경은 예외적으로 허용 될 수 있습니다. 기타 법률에 따라 정정 또는 삭제가 금지되거나 제한되어 있는 경우에는 해당 처리가 제한될 수 있습니다. 또한 개인정보 오류에 대한 정정 요청한 경우에는 다른 법률에 따라 개인정보의 제공을 요청받는 경우가 아닌 한 정정을 완료하기 전까지 당해 개인정보를 이용 또는 제공하지 않습니다. 만약 잘못된 개인정보를 이미 제공한 경우에는 정정 처리 결과를 제 3자에게 통지하여 정정이 이루어지도록 하겠습니다.
6.2. 이용자는 언제든지 티스페이스 사이트의 개인정보에 대하여 처리의 정지를 요구 할 수 있습니다. 다만 아래의 경우에 해당하는 경우 처리정지 요구를 거절할 수 있습니다.
1) 법률에 특별한 규정이 있거나 법령상 의무를 준수하기 위하여 불가피한 경우
2) 다른 사람의 생명·신체를 해할 우려가 있거나 다른 사람의 재산과 그 밖의 이익을 부당하게 침해할 우려가 있는 경우
3) 개인정보를 처리하지 아니하면 이용자와 약정한 서비스를 제공하지 못하는 등 계약의 이행이 곤란한 경우로서 이용자가 그 계약의 해지 의사를 명확하게 밝히지 아니한 경우
6.3. 회원가입 등을 통해 개인정보의 수집·이용·제공에 대해 회원님께서 동의하신 내용을 언제든지 철회할 수 있습니다. 동의철회는 회사 사이트 내 “서비스 탈퇴”메뉴를 이용하거나 서면, 이메일 등으로 연락하시면 지체 없이 개인정보의 삭제 등 필요한 조치를 하겠습니다. 다만 법률 또는 약관의 규정에 따라 회사가 이용자의 개인정보를 보존하여야 하는 경우에는 해당 처리가 제한될 수 있습니다. 이 경우 이용자는 본인 식별을 위하여 반드시 아이디와 본인확인 식별정보를 밝히셔야 하며, 철회로 인해 서비스에 다소 제약이 있거나 일부 서비스를 이용하지 못하실 수 있습니다

7. 쿠키(cookie)의 운영에 관한 사항
7.1. 회사는 이용자에게 개별적인 맞춤서비스를 제공하기 위해 이용정보를 저장하고 수시로 ‘쿠키(cookie)’를 사용합니다.
7.2. 쿠키는 웹사이트를 운영하는데 이용되는 서버(http)가 이용자의 컴퓨터 브라우저에게 보내는 소량의 정보이며 이용자들의 PC 컴퓨터 내의 하드디스크에 저장되기도 합니다.
1) 쿠키의 사용목적: 회사는 쿠키의 사용을 통해서만 가능한 특정된 맞춤형 서비스를 제공할 수 있으며, 회사는 회원을 식별하고 회원의 로그인 상태를 유지하기 위해 쿠키를 사용할 수 있습니다.
2) 쿠키의 설치∙운영 및 거부: 웹브라우저 상단의 도구>인터넷 옵션>개인정보 메뉴의 옵션 설정을 통해 쿠키 저장을 거부 할 수 있습니다.
3) 쿠키 저장을 거부할 경우 맞춤형 서비스 이용에 어려움이 발생할 수 있습니다.

8. 개인정보의 안전성 확보 조치
회사는 개인정보의 안전성 확보를 위해 다음과 같은 조치를 취하고 있습니다.
8.1. 관리적 조치: 내부관리계획 수립, 시행, 정기적 직원 교육 등
8.2. 기술적 조치: 개인정보처리시스템 등의 접근권한 관리, 접근통제시스템 설치, 보안프로그램 설치 등
8.3. 물리적 조치: 전산실, 자료보관실 등의 접근통제 등

9. 개인정보보호책임자 및 담당자의 연락처
9.1. 회사는 개인정보 처리에 관한 업무를 총괄해서 책임지고, 개인정보 처리와 관련한 이용자의 불만처리 및 피해구제 등을 위하여 아래와 같이 개인정보 보호책임자를 지정하고 있습니다.
[개인정보 보호책임자]
성명: 허희도
메일: security@tmax.co.kr / 전화번호: 031-8018-9347
[개인정보 보호관리자]
성명: 박병화
메일: security@tmax.co.kr / 전화번호: 031-8018-9347
9.2. 이용자께서는 회사의 서비스(또는 사업)을 이용하시면서 발생한 모든 개인정보 보호 관련 문의, 불만처리, 피해구제 등에 관한 사항을 개인정보 보호책임자 및 보호관리자에게 문의하실 수 있습니다. 회사는 이용자의 문의에 대해 지체 없이 답변 및 처리해드릴 것입니다.

10. 권익침해 구제방법
이용자는 아래의 기관에 대해 개인정보 침해에 대한 피해구제, 상담 등을 문의하실 수 있습니다. 아래의 기관은 회사와는 별개의 기관으로서, 회사의 자체적인 개인정보 불만처리, 피해구제 결과에 만족하지 못하시거나 보다 자세한 도움이 필요하시면 문의하여 주시기 바랍니다.
10.1. 개인정보 침해신고센터 
홈페이지: privacy.kisa.or.kr
전화: (국번없이) 118
10.2. 개인정보 분쟁조정위원회
홈페이지: www.kopico.go.kr
전화: (국번없이) 1833-6972 
10.3 대검찰청 사이버범죄수사단
홈페이지: www.spo.go.kr
전화: (국번없이) 1301
10.4 경찰청 사이버안전국
홈페이지:  http://cyberbureau.police.go.kr
전화: (국번없이) 182 

11. 개인정보 처리방침 변경
이 개인정보 처리방침은 2020년 06월 22일부터 적용됩니다.`}
                  <CursorSpan
                    onClick={() => handleChangeSelectOptionClick('2020-05-25')}
                  >
                    - 2020. 05. 25 ~ 2020. 06. 22 개인정보처리방침 (클릭)
                  </CursorSpan>
                  <CursorSpan
                    onClick={() => handleChangeSelectOptionClick('2019-08-15')}
                  >
                    - 2019. 08. 15 ~ 2020. 05. 25 개인정보처리방침 (클릭)
                  </CursorSpan>
                </PageBodyPre>
              </PageInnerContents>
            )}
            {i18n.language === 'en' ? (
              <PageInnerContents id="2020-05-25">
                <PageBodyPre ismobile={isMobile}>
                  {`
TmaxA&C Co., Ltd. (the “Company”) places a high importance on users’ privacy and thus have developed this privacy policy which applies to the processing of personal information of users of TeeSpace (www.teespace.com). Please note that this privacy policy can be updated to comply with applicable laws and guidelines such as『Act on Promotion of Information and Communications Network Utilization and Information Protection, etc.』and『Personal Information Protection Act』as well as to conform to changing policies of the Company. Users are therefore invited to visit the Company’s website periodically to take notice of any changes.

1. Purpose of Collecting and Using Personal Information
1.1. When applying for membership, a minimum amount of personal information is required for the Company to provide better services and user-adapted information.  
1.2.	 Under no circumstances will the Company request any sensitive information that may compromise users’ privacy (for example race, ideology/faith, political orientation, criminal records, or medical history). If obliged to do so, however, the Company will seek prior consent of users before collecting any such information.
1.3. The Company collects the following information.
1) When: applying for membership
      Which: 
        - Required: ID, password, name, and mobile phone number
        - Optional: email
    2) When: using services
      Which: 
        - Required: ID, password, name, mobile phone number, nickname (if any), profile photo, friends list, and service usage history
        - Optional: email, date of birth, sex, occupation, company, and address
    3) When: as needed
      Which: 
        - Information collected automatically during the use of services: IP address, cookies, date and time of visits, service usage records, inappropriate usage history, and device information
        - Customer support or dispute resolution-related information: consultation records
1.4. Such collected information is used:
    1) For: identifying users
      Which: ID and password
    2) For: authenticating users
      Which: email and mobile phone number
    3) For: fulfilling the fundamental functions of services 
Which: name, ID, password, phone number, nickname (if any)
    4) For: providing specialized services (optional)
      Which: date of birth, sex, occupation, company, address
    5) For: communicating and delivering services, sending newsletters and promotional content, and handling complaints
      Which: ID, name, and email
1.5. The Company collects personal information during the process of membership application, account setting, membership fee payment, customer support service, etc.

2. Retention Periods for Personal Information 
2.1 The Company makes it a rule to retain and utilize users’ information for the duration of the stipulated service period. All personal data will be immediately destroyed once their purposes are fulfilled or at the request of users. However, certain types of information shall be held for record retention purposes as required by the applicable laws specified below. In this case, subject information will not be used for any other purposes (such as marketing) than those imposed by legal requirements such as:
1) Responding to a subpoena for investigation 
- In compliance with: Protection of Communications Secrets Act
   - Providing: log records, IP address, etc. 
   - Retained for: 3 months
2) Customer complaints and dispute resolution
- In compliance with: Act on the Consumer Protection in Electronic Commerce, etc.
- Providing: customer identification data, records of dispute resolution, etc.
- Retained for: 3 years 
3) Payment and supply of goods 
- In compliance with: Act on the Consumer Protection in Electronic Commerce, etc.
   - Providing: customer identification data, records of canceling contract/subscription, etc.  
- Retained for: 5 years
4) Canceling contract/subscription
- In compliance with: Act on the Consumer Protection in Electronic Commerce, etc.
   - Providing: customer identification data, records of canceling contract/subscription
   - Retained for: 5 years
2.2 Any user accounts of the Company’s service that have not been used (logged in) for the past 1 year will be deactivated with prior notification, and their personal data will be retained in a separate storage for 1 year.


3. Destruction of Personal Information
3.1. The Company will immediately destroy any information of which the retention period has expired or the purpose has been fulfilled.
3.2. All information of which the retention period has expired or the purpose has been fulfilled but needed to be held in accordance with certain legal requirements will be migrated to databases or other separate storage systems.
3.3. Personal information will be destroyed as follows:
1) Procedure of destruction
The Company selects the information needed to be destroyed and asks approval from the personal information protection officer before proceeding to destruction.
2) Methods of destruction
The data stored in electronic file format are destroyed in a manner that any related records cannot be recovered and those recorded in the form of written documentation are shredded or incinerated.

4. Sharing with Third Parties
4.1. The Company will process users’ personal information only within the scope specified in Article 1, “Purpose of Collecting and Using Personal Information”, and will not use any of the data beyond this scope without prior consent of the users. As a general rule, the Company does not provide users’ personal information to third parties.
4.2. However, the Company may exceptionally share certain personal information in the following cases.
1) When agreed by users
2) When required by applicable laws, or requested by authorities for investigation purposes.

5. Processing of Personal Information on Behalf of the Company
5.1. The Company may entrust the processing of personal information to a third party in order to provide better services to users. In this case, the Company provides users with each of the following information to seek their consent. The same applies when any of the following is modified.
1) The entity that will process personal information on behalf of the Company.
2) Details about processing of personal information performed on behalf of the Company.
5.2. To comply with the contract on information and communication services and to improve the user experience, the Company may, when necessary, entrust the processing of personal data to a third party, by making each of the above information public, without prior notification and user consent.
5.3. The Company entrust the processing of personal information as follows, while taking necessary measures for the personal information to be securely managed in accordance with relevant laws and regulations. The Company evaluates the entrusted entity’s capability of securing personal information, and periodically checks whether the entity complies with its obligations to securely manage and destroy personal information. The scope of personal information that can be processed on behalf of the Company is limited to the minimum necessary for supporting smooth operations of services.
- Entrusted entity: MTS Company
- Entrusted operations: SMS authentication

6. Rights of Users and Their Legal Representatives
6.1. Users can check and modify their personal information via “Account Settings” in the TeeSpace website at any time. When requested through an email or written document, the Company will check, modify or delete the information as requested. Users have the rights to request the destruction, if there are any, of their personal data provided or entrusted to third parties/trustees. User’s name and ID cannot be modified, but modifying user’s name can be exceptionally allowed if the name has been legally changed. The modification or deletion of certain types of data may be prohibited or restricted if so required by some other legal requirements. In addition, any incorrect information that needs to be corrected at the request of users will remain inaccessible until the completion of correction, unless otherwise required by other legal requirements. If any inaccurate pieces of information have been already provided to third parties, the Company will notify them of the amendments.
6.2. Users can request the suspension of the processing of their personal information in the TeeSpace website at any time. In the following cases however, such request may be refused.
1) When bound by special provisions in legislation or certain legal obligations
2) When there exist some risks of causing harm to life or property and interests of other people
3) When it is impossible to provide the services specified in the use agreement without processing personal information, and in such a context the user has not clarified his/her intention to terminate the use agreement
6.3. Users can cancel their consent on the collection, use and sharing of personal information at any time. Requests for cancellation of such consent, data deletion and suspension of the processing of personal information shall be sent to WAPL Customer Support (wapl_support@tmax.co.kr). In certain cases, however, where it is necessary to retain user’s personal information according to applicable laws or provisions of the Terms of Use, there may be some restrictions in the processing of requests. In this case, users must present their IDs (email) to confirm identity, and may not be fully allowed to access the service. 
6.3. Users can cancel their consent on the collection, use and sharing of personal information at any time. If you want to cancel your consent, use the “Canceling Services” menu in the Company’s website, or send a cancellation letter or email, to which the Company will promptly respond by deleting the user’s personal data and taking other necessary actions. In certain cases, however, where it is necessary to retain user’s personal information according to applicable laws or provisions of the Terms of Use, there may be some restrictions in the processing of such requests. In this case, users must present their ID to confirm identity, and may not be fully allowed to access the service. 

7. About Cookies Management
7.1. In order to provide user-adapted services, the Company stores service usage information and uses cookies on a regular basis. 
7.2. Cookies are small text files sent by the server of the website to the browser of the website user, and stored on the user’s computer.
1) Purpose of using cookies: The Company may provide customized services that are only available through the use of cookies, and the Company may use cookies to identify users and maintain users’ login status.
2) Allow or block cookies: Web browser Tools menu> Internet Options> Personal Information> Settings
3) Users can refuse to store cookies. In this case, there may be some restrictions in the customization of service.

8. Security Measures for Protecting Personal Information 
The Company takes the following measures to ensure the security of personal information.
8.1. Administrative measures: development and implementation of internal management plans, regular employee training, etc.
8.2. Technical measures: management of access rights for the personal information processing system, installation of access control system and security program, etc. 
8.3. Physical measures: access control in server room and data storage room, etc.

9. Contact Details of Personal Information Protection Officer
9.1. The Company has designated a personal information protection officer and manager who are responsible for all privacy-related activities, handling users’ complaints and damage relief in relation to the processing of personal information.
 
[Personal Information Protection Officer]
Name: Heedo Heo
Email: security@tmax.co.kr / Phone: 031-8018-9347

[Personal Information Protection Manager]
Name: Byunghwa Park
Email: security@tmax.co.kr / Phone: 031-8018-9347
9.2. Users can contact these persons for all privacy-related inquires, complaints and damage relief in relation to the services (or business) provided by the Company. The Company will provide a prompt response and action for users’ inquiries.

10. Remedies for Infringement of Rights and Interests
Users can contact the following organizations to inquire about possible damage relief in relation to any infringement of privacy. These organizations are public entities having no relation to the Company. Any users who are not satisfied with the Company's solutions to complaints or damage relief regarding their privacy concerns, or who seek further assistance may have recourse to these organizations. 
10.1. Personal Information Infringement Report Center
Website: privacy.kisa.or.kr
Phone: (without area code) 118
10.2. Personal Information Dispute Mediation Committee
Website: www.kopico.go.kr
Phone: (without area code) 1833-6972
10.3 Cyber Crime Investigation Division of the Supreme Prosecutors' Office 
Website: www.spo.go.kr
Phone: (without area code) 1301
10.4 Cyber Terror Response Center of the National Police Agency:
Website: http://cyberbureau.police.go.kr
Phone: (without area code) 182 

11. Privacy Policy Updates
This updated privacy policy takes effect on May 25, 2020.`}
                  <CursorSpan
                    onClick={() => handleChangeSelectOptionClick('2019-08-15')}
                  >
                    - 15 Aug 2019 ~ 25 May 2020 Privacy Policy (Click Here)
                  </CursorSpan>
                </PageBodyPre>
              </PageInnerContents>
            ) : (
              <PageInnerContents id="2020-05-25">
                <PageBodyPre ismobile={isMobile}>
                  {`
㈜티맥스에이앤씨(이하 ‘회사)는 이용자님들의 개인정보를 매우 중요하게 생각하며 티스페이스(www.teespace.com)와 관련하여 아래와 같은 개인정보처리방침을 가지고 있습니다. 이 개인정보처리방침은 정보통신망 이용촉진 및 정보보호 등에 관한 법률, 개인정보보호법 등 개인정보와 관련한 법령 또는 지침의 변경이 있는 경우 갱신되고, 회사 정책의 변화에 따라 달라질 수 있으니 이용자께서는 홈페이지에서 수시로 확인하여 주시기 바랍니다. 티스페이스의 개인정보처리방침은 다음과 같은 내용을 담고 있습니다.

1. 개인정보의 수집 및 이용 목적
1.1. 회사는 회원가입 시 서비스 제공과 편리하고 유익한 맞춤 정보를 제공하기 위해 필요한 최소한의 정보를 필수 사항으로 수집하고 있습니다.
1.2. 회사는 이용자의 소중한 인권을 침해할 우려가 있는 민감한 정보(인종, 사상 및 신조, 정치적 성향 이나 범죄기록, 의료정보 등)는 어떠한 경우에도 수집하지 않으며, 만약 불가피하게 수집하는 경우에는 반드시 회원님에게 사전 동의를 구하겠습니다.
1.3. 회사가 수집하는 이용자의 개인정보는 다음과 같습니다.
    1) 수집시기 : 회원가입 시
        수집항목 : 
        -필수: 아이디, 비밀번호, 이름, 휴대폰 번호
        -선택: 이메일
    2) 수집시기 : 서비스 이용 시
        수집항목 : 
        - 필수: 아이디, 비밀번호, 이름, 휴대폰번호, 별명(지정 시), 
        - 선택: 이메일, 생년월일, 성별, 직업, 소속, 주소
    3) 수집시기 : 기타
        수집항목 : 
        - 서비스 이용과정에서 자동 수집되는 정보: IP Address, 쿠키, 방문 일시, 서비스 이용 기록, 불량 이용 기록, 기기 정보
        - 고객 분쟁처리 및 상담 진행 시: 상담 내역
1.4. 수집한 개인정보는 다음과 같은 목적으로 사용됩니다.
    1) 수집목적 : 개인 식별
        수집항목 : 아이디, 비밀번호
    2) 수집목적 : 본인확인
        수집항목 : 이메일, 휴대폰번호
    3) 수집목적 : 서비스 본질적 기능 수행 
        수집항목 : 이름, 아이디, 비밀번호, 전화번호, 별명(지정시)
    4) 수집목적 : 특화 서비스 제공(선택)
        수집항목 : 생년월일, 성별, 직업, 소속, 주소
    5) 수집목적 : 서비스 제공을 위한 연락 및 안내, 뉴스레터, 프로모션 안내, 민원 처리
        수집항목 : 아이디, 이름, 이메일
1.5. 회사는 회원가입, 계정 설정, 멤버십 결제, 고객 상담 등을 통하여 개인정보를 수집합니다.

2. 개인정보의 보유 및 이용기간
2.1 회사는 이용자의 개인정보를 원칙적으로 고지 및 약정한 기간 동안 보유 및 이용하며 개인정보의 수집 및 이용목적이 달성되거나 이용자의 파기 요청이 있는 경우 지체 없이 파기합니다. 단, 다음의 정보에 대해서는 아래의 이유로 명시한 기간 동안 보존합니다.
1) 관계법령의 규정에 의하여 보존할 필요가 있는 경우 법령에서 규정한 바에 따라 이용자의 개인정보를 보관하며 마케팅 등 다른 목적으로 이용하지 않습니다.
- 관계 법률 : 통신비밀보호법
  목적 : 법원의 영장을 받아 수사기관이 요청 시
  수집항목 : 로그기록, IP 등 제공
  보유기간 : 3개월
- 관계 법률 : 전자상거래등에서의 소비자보호에 관한 법률
  목적 : 소비자의 불만 또는 분쟁처리에 관한 기록
  수집항목 : 소비자 식별정보, 분쟁처리 기록 등
  보유기간 : 3년 
- 관계 법률 : 전자상거래등에서의 소비자보호에 관한 법률
  목적 : 대금결제 및 재화 등의 공급에 관한 기록
  수집항목 : 소비자 식별정보, 계약/청약철회 기록 등 
  보유기간 : 5년
- 관계 법률 : 전자상거래등에서의 소비자보호에 관한 법률
  목적 : 계약 또는 청약철회 등에 관한 기록
  수집항목 : 소비자 식별정보, 계약/청약철회 기록 등 
  보유기간 : 5년
2.2 회사의 서비스를 1년 동안 이용(로그인)하지 않을 경우 이용자에게 사전통지하고 휴면계정으로 전환하고 개인정보 분리 저장한 뒤 1년이 경과하는 시점까지 보관됩니다. 

3. 개인정보의 파기 절차 및 방법
3.1. 회사는 개인정보 보유기간의 경과, 처리 목적 달성 등 개인정보가 불필요하게 되었을 때에는 지체 없이 해당 개인정보를 파기합니다.
3.2. 이용자로부터 동의 받은 개인정보 보유기간이 경과하거나 처리 목적이 달성되었음에도 다른 법령에 따라 개인정보를 계속 보존하여야 하는 경우에는, 해당 개인정보를 데이터베이스(DB)로 옮기거나 보관장소를 달리하여 보존합니다.
3.3. 개인정보 파기의 절차 및 방법은 다음과 같습니다.
1) 파기절차
회사는 파기 사유가 발생한 개인정보를 선정하고, 회사의 개인정보 보호책임자의 승인을 받아 개인정보를 파기합니다.
2) 파기방법
회사는 전자적 파일 형태로 기록, 저장된 개인정보는 기록을 재생할 수 없도록 파기하며, 종이 문서에 기록, 저장된 개인정보는 분쇄기로 분쇄하거나 소각하여 파기합니다.

4. 개인정보의 제3자 제공
4.1. 회사는 이용자의 개인정보를 “제1조(개인정보의 수집 및 이용 목적)”에서 명시한 범위 내에서만 처리하며, 이용자의 사전 동의 없이 동 범위를 초과하여 이용하거나 원칙적으로 이용자의 개인정보를 제3자에게 제공하지 않습니다.
4.2. 다만, 아래의 경우에는 예외로 합니다.
1) 이용자들이 사전에 공개 또는 제3자 제공에 동의한 경우
2) 법령의 규정에 의거하거나, 수사 목적으로 법령에 정해진 절차와 방법에 따라 수사기관 및 감독당국의 요구가 있는 경우

5. 개인정보의 처리위탁
5.1. 회사는 원활하고 향상된 서비스를 위하여 개인정보 처리를 타인에게 위탁할 수 있습니다. 이 경우 회사는 사전에 다음 각 호의 사항 모두를 이용자에게 미리 알리고 동의를 받습니다. 다음 각 호의 어느 하나의 사항이 변경되는 경우에도 같습니다.
1) 개인정보 처리위탁을 받는 자
2) 개인정보 처리위탁을 하는 업무의 내용
5.2. 회사는 정보통신서비스의 제공에 관한 계약을 이행하고 이용자 편의 증진 등을 위하여 필요한 경우 개인정보처리방침에 따라 각 사항을 공개함으로써 고지절차와 동의절차를 거치지 아니하고 개인정보 처리를 타인에게 위탁할 수 있습니다
5.3. 회사는 개인정보의 처리와 관련하여 아래와 같이 업무를 위탁하고 있으며, 관계법령에 따라 위탁 계약 시 개인정보가 안전하게 관리될 수 있도록 필요한 조치를 하고 있습니다. 회사는 위탁 계약 시 수탁자의 개인정보 보호조치 능력을 고려하고, 개인정보의 안전한 관리 및 파기 등 수탁자의 의무 이행 여부를 주기적으로 확인합니다. 또한 위탁처리하는 정보는 원활한 서비스를 제공하기 위하여 필요한 최소한의 정보에 국한됩니다.
- 수탁업체 : (주)엠티에스컴퍼니
- 위탁업무 내용 : 문자, 휴대폰 인증

6. 이용자 및 법정대리인의 권리와 그 행사방법
6.1. 이용자는 언제든지 티스페이스 사이트의 “계정 설정”을 통하여 회원님의 개인정보를 열람, 정정 처리하실 수 있으며, 전자우편 또는 서면으로 요청하신 경우 열람, 정정, 삭제처리 해드리겠습니다. 이용자의 개인정보가 제3자에게 제공되거나 처리위탁된 경우 이용자는 회사 또는 ‘제3자’/’수탁자’에게 파기를 요청할 수 있습니다. 단, 이름, 아이디는 정정이 불가능하며, 개명으로 인한 이름 변경은 예외적으로 허용 될 수 있습니다. 기타 법률에 따라 정정 또는 삭제가 금지되거나 제한되어 있는 경우에는 해당 처리가 제한될 수 있습니다. 또한 개인정보 오류에 대한 정정 요청한 경우에는 다른 법률에 따라 개인정보의 제공을 요청받는 경우가 아닌 한 정정을 완료하기 전까지 당해 개인정보를 이용 또는 제공하지 않습니다. 만약 잘못된 개인정보를 이미 제공한 경우에는 정정 처리 결과를 제 3자에게 통지하여 정정이 이루어지도록 하겠습니다.
6.2. 이용자는 언제든지 티스페이스 사이트의 개인정보에 대하여 처리의 정지를 요구 할 수 있습니다. 다만 아래의 경우에 해당하는 경우 처리정지 요구를 거절할 수 있습니다.
1) 법률에 특별한 규정이 있거나 법령상 의무를 준수하기 위하여 불가피한 경우
2) 다른 사람의 생명·신체를 해할 우려가 있거나 다른 사람의 재산과 그 밖의 이익을 부당하게 침해할 우려가 있는 경우
3) 개인정보를 처리하지 아니하면 이용자와 약정한 서비스를 제공하지 못하는 등 계약의 이행이 곤란한 경우로서 이용자가 그 계약의 해지 의사를 명확하게 밝히지 아니한 경우
6.3. 회원가입 등을 통해 개인정보의 수집·이용·제공에 대해 회원님께서 동의하신 내용을 언제든지 철회할 수 있습니다. 동의철회는 회사 사이트 내 “서비스 탈퇴”메뉴를 이용하거나 서면, 이메일 등으로 연락하시면 지체 없이 개인정보의 삭제 등 필요한 조치를 하겠습니다. 다만 법률 또는 약관의 규정에 따라 회사가 이용자의 개인정보를 보존하여야 하는 경우에는 해당 처리가 제한될 수 있습니다. 이 경우 이용자는 본인 식별을 위하여 반드시 아이디와 본인확인 식별정보를 밝히셔야 하며, 철회로 인해 서비스에 다소 제약이 있거나 일부 서비스를 이용하지 못하실 수 있습니다

7. 쿠키(cookie)의 운영에 관한 사항
7.1. 회사는 이용자에게 개별적인 맞춤서비스를 제공하기 위해 이용정보를 저장하고 수시로 ‘쿠키(cookie)’를 사용합니다.
7.2. 쿠키는 웹사이트를 운영하는데 이용되는 서버(http)가 이용자의 컴퓨터 브라우저에게 보내는 소량의 정보이며 이용자들의 PC 컴퓨터 내의 하드디스크에 저장되기도 합니다.
1) 쿠키의 사용목적: 회사는 쿠키의 사용을 통해서만 가능한 특정된 맞춤형 서비스를 제공할 수 있으며, 회사는 회원을 식별하고 회원의 로그인 상태를 유지하기 위해 쿠키를 사용할 수 있습니다.
2) 쿠키의 설치∙운영 및 거부: 웹브라우저 상단의 도구>인터넷 옵션>개인정보 메뉴의 옵션 설정을 통해 쿠키 저장을 거부 할 수 있습니다.
3) 쿠키 저장을 거부할 경우 맞춤형 서비스 이용에 어려움이 발생할 수 있습니다.

8. 개인정보의 안전성 확보 조치
회사는 개인정보의 안전성 확보를 위해 다음과 같은 조치를 취하고 있습니다.
8.1. 관리적 조치: 내부관리계획 수립, 시행, 정기적 직원 교육 등
8.2. 기술적 조치: 개인정보처리시스템 등의 접근권한 관리, 접근통제시스템 설치, 보안프로그램 설치 등
8.3. 물리적 조치: 전산실, 자료보관실 등의 접근통제 등

9. 개인정보보호책임자 및 담당자의 연락처
9.1. 회사는 개인정보 처리에 관한 업무를 총괄해서 책임지고, 개인정보 처리와 관련한 이용자의 불만처리 및 피해구제 등을 위하여 아래와 같이 개인정보 보호책임자를 지정하고 있습니다.
[개인정보 보호책임자]
성명: 허희도
메일: security@tmax.co.kr / 전화번호: 031-8018-9347
[개인정보 보호관리자]
성명: 박병화
메일: security@tmax.co.kr / 전화번호: 031-8018-9347
9.2. 이용자께서는 회사의 서비스(또는 사업)을 이용하시면서 발생한 모든 개인정보 보호 관련 문의, 불만처리, 피해구제 등에 관한 사항을 개인정보 보호책임자 및 보호관리자에게 문의하실 수 있습니다. 회사는 이용자의 문의에 대해 지체 없이 답변 및 처리해드릴 것입니다.

10. 권익침해 구제방법
이용자는 아래의 기관에 대해 개인정보 침해에 대한 피해구제, 상담 등을 문의하실 수 있습니다. 아래의 기관은 회사와는 별개의 기관으로서, 회사의 자체적인 개인정보 불만처리, 피해구제 결과에 만족하지 못하시거나 보다 자세한 도움이 필요하시면 문의하여 주시기 바랍니다.
10.1. 개인정보 침해신고센터 
홈페이지: privacy.kisa.or.kr
전화: (국번없이) 118
10.2. 개인정보 분쟁조정위원회
홈페이지: www.kopico.go.kr
전화: (국번없이) 1833-6972 
10.3 대검찰청 사이버범죄수사단
홈페이지: www.spo.go.kr
전화: (국번없이) 1301
10.4 경찰청 사이버안전국
홈페이지:  http://cyberbureau.police.go.kr
전화: (국번없이) 182 

11. 개인정보 처리방침 변경
이 개인정보 처리방침은 2020년 05월 25일부터 적용됩니다.`}
                  <CursorSpan
                    onClick={() => handleChangeSelectOptionClick('2019-08-15')}
                  >
                    - 2019. 08. 15 ~ 2020. 05. 25 개인정보처리방침 (클릭)
                  </CursorSpan>
                </PageBodyPre>
              </PageInnerContents>
            )}
            {i18n.language === 'en' ? (
              <PageInnerContents id="2019-08-15">
                <PageBodyPre ismobile={isMobile}>
                  {`
TmaxOS Co., Ltd. (hereinafter referred to as the "Company") establishes and discloses Tmax Cloud Account Privacy Policy (hereinafter referred to as “Privacy Policy”) as follows to protect the personal information of the subject of information (hereinafter referred to as “Information Subjects”) and to handle the problems related to it quickly and smoothly according to Article 30 of『Personal Information Protection Act』.
The Company restricts children under the age of fourteen (14) from applying for membership, to protect the privacy of children.
If you refuse to consent to the Company's collection of personal information, you may not be able to register as a member, or you may not use the services for members.
1. Purpose
The Company shall process personal information for the following purposes. The information will not be used for purposes other than the following, and if the purpose of use is changed, the Company will take necessary measures such as obtaining separate consent in accordance with Article 18 of the Personal Information Protection Act.
1.1 The Company shall use the personal information to provide the services, to verify the identity, to make purchases and payments, to deliver bills, and to deliver goods and services, that the users have agreed. 
1.2 Personal information is used for membership management, such as confirmation of intention to join or withdraw membership, identification of users, inquiry and complaint handling. 
1.3 Personal information can be used for user protection and service operation including, restrictions on members who violate the law and the Privacy Policy, prevention and sanctions against the unauthorized operation of the service or unauthorized activities, prevention of account theft and fraud, communication of notices, record keeping for mediation on disputes, etc. 
1.4 Personal information can be used for providing services based on demographic characteristics and new service offerings (including personalized product recommendation services, etc.) reflecting user's tendency on products purchase and service usage, interest, and usage record analysis based on access frequency analysis, function improvement, statistics on service use, service analysis and statistics.  
1.5 Personal information can be used for the purpose of marketing, events and promotions such as providing event information and promotional information. In this case, the Company shall acquire separate consent of members.

2. Retention Period of Personal Information
2.1 The Company shall retain the personal information of members until withdrawal. However, if the member does not use the integrated service for one year, the member’s account shall be divided into an inactive account and the collected personal information of the members shall be stored separately. In case of Tmax CloudSpace, the information of inactive members can be deleted. 
2.2 The Company shall process and retain personal information within the retention period pursuant to the law or agreed to when collecting personal information from the Information Subjects.
2.3 Each personal information retention period shall be as follows.
    a) Joining and managing membership on the website: Until the withdrawal from the website of the enterprise/group, provided, however, that if any of the following cases apply, until the termination of the following cases:
i. If an investigation is in progress due to a violation of related laws, until the investigation is over;
ii. If there is no record of the member's use of the service for one year, the member shall be notified in advance according to Paragraph 29.2 of the 『Law regarding the promotion of information and communication network use and protection of information』 and the member’s information shall be stored separately.
b) Utilization in marketing and advertising: Until achieving the purpose
Provided, however, that even after the above membership withdrawal and termination of service use, the information will be retained for the specified period for the following reasons.
Records of transactions, such as labeling, advertising, contract details and fulfillment, etc. under the 『Act on Consumer Protection in Electronic Commerce, etc.』
i. Record about display and advertising: six (6) months
ii. History of illegal use
- Reason: prevention of illegal use and response to customer consultation
- Period: one (1) year from collection date
2.4. Reasons for retention of information under relevant laws
    If it is necessary to retain the information under the relevant laws such as the 『Consumer Protection Act in the Commercial Law, Electronic Commerce, etc.』, the Company shall retain the member information for a certain period of time.
In this case, the Company shall use the information for the purpose of retaining only and the period shall be as follows:
a) Log record of service usage
i. Reason: 『Protection of Communications Secrets Act』
ii. Period: Three (3) months
b) Records of customer complaints or settlements of dispute
i. Reason: 『Act on Consumer Protection in Electronic Commerce, etc.』
ii. Period: Three (3) years
c) Records of contract or withdrawal of subscription
i. Reason: 『Act on Consumer Protection in Electronic Commerce, etc.』
ii. Period: Five (5) years
d) Records regarding payment and supply of goods, etc.
i. Reason: 『Act on Consumer Protection in Electronic Commerce, etc.』
ii. Period: Until the end of the reason 
Members shall have the right to refuse to consent to the collection and use of personal information. Provided, however, that if the members refuse to consent to the collection and use of personal information, the use of the service the members requested to join may be restricted or impossible.

3. Providing Third Party with Personal Information
3.1 The Company shall process personal information of Information Subjects only within the scope specified in Article 1 (Purpose of processing personal information), and only if it falls under Article 17 and 18 of the 『Personal Information Protection Act』, including the consent of the Information Subjects and special provisions of the law, the Company may provide personal information to third parties (including company affiliates and subsidiaries).
3.2. Provided, however, that exceptions are as follows.
a) If members agree in advance
b) In accordance with the provisions of the law or for the purpose of investigation, according to the procedures and methods prescribed by the law

4. Restriction on the Processing of Personal Information of Business Consignment
4.1 If the Company entrusts the processing of personal information to a third party, a document containing the following items shall be prepared.
a) Matters concerning the prohibition of the processing of personal information for the other than purpose of conducting consignment work;
b) Matters concerning the technical and administrative protection measures of personal information;
c) Other matters prescribed by Presidential Decree for the safe management of personal information.
4.2 In the case of entrusting the processing of personal information pursuant to Paragraph 4.1, the contents of the entrusting task and the trustee shall be disclosed on the online website so that the Information Subject can easily check at any time.
4.3 If the Company entrusts with the task of promoting or encouraging the sale of goods or services, the Information Subject shall be notified of the contents and the consignee of the consignment work by e-mail. The same applies if the contents of the consigned work or the trustee are changed.
4.4 The Company shall train the trustees in respect of privacy and supervise trustees for compliance with Paragraph 4.1.
4.5 The trustee shall be regarded as an employee of the Company for the liability for damages incurred in processing personal information related to the work entrusted to the trustee.

5. Rights, Responsibilities and Exercising Methods of Information Subjects and Legal Representatives
5.1 The Information Subjects can exercise the right to read, correct, delete and stop processing personal information at any time.
5.2 The exercise of rights under Paragraph 5.1 can be made to the Company through written, e-mail or fax in accordance with Paragraph 41.1 of the 『Enforcement Decree of the Personal Information Protection Act』.
5.3 Legal representative of the Information Subjects or agent party can exercise the rights under Paragraph 5.1. In this case, the party must submit a power of attorney according to Form 11. of the 『Enforcement Regulations of the Personal Information Protection Act』.
5.4 The request of suspension of access to personal information and suspension of processing may limit the rights of the Information Subjects under Paragraph 35.5 and 37.2 of the 『Personal Information Protection Act』.
5.5 The request for correction or deletion of personal information cannot be accepted if the personal information is specified for collection under other law.
5.6 The Company shall verify that the person who made the request, such as a request for viewing, correction or deletion, or request for suspension of processing according to the Information Subject's right, is the principal or a just representative.
5.7 If the Company provides information to a third party, the Company shall promptly notify the third party of the result of the correction so that the correction can be made.

6. Items of Personal Information
6.1. Items of personal information
a) The Company may collect the following personal information to join the integrated account.
i. (Required) Name, date of birth, ID, password, email, national/foreigner, individual/corporate business
ii. (Optional) Occupation, affiliation, address, membership path, etc.
b) The following information may be generated and collected in the process of using the services.
i. User ID, IP address, cookie, date of visit, service download record, service use record, unauthorized use record, terminal information (including unique information on terminal identification, terminal environment information, advertisement ID, basic statistics on service usage) when using mobile service, installation and usage history of installed applications in mobile terminal, purchased app list, payment information.

7. Destruction of Personal Information
7.1 The Company shall immediately destroy the personal information when the personal information becomes unnecessary from the reason such as the elapse of the retention period of personal information and the achievement of the processing purpose, etc.
7.2 Despite the retention period agreed to by the Information Subjects has passed or the purpose of processing has been achieved, and if the personal information must be kept according to other laws, the personal information shall be transferred to a separate database or stored in a different place. 
7.3 The procedures and methods of destroying personal information shall be as follows.
a) Procedures
The Company selects the personal information that has reason for destruction and destroys the personal information with the approval of the Company's personal information protection officer.
b) Methods
The Company destroys the personal information recorded and stored in the form of electronic files so that it cannot be reproduced. The personal information recorded and stored in paper documents is destroyed by shredding or incineration.

8. Measures to Ensure the Safety of Personal Information
The Company shall take the following measures to ensure the safety of personal information.
8.1 Administrative measures: Establishing and implementing internal management plans, training regular staff, etc.
8.2 Technical measures: Managing access authority of personal information processing system, installing access control system, encrypting unique identification information, installing security program.
8.3 Physical measures: Access control of computer room and archive.

9. Matters Regarding Installation, Operation and Rejection of Automatic Collection Device of Personal Information
9.1 The Company uses 'cookies' that store and retrieve usage information from time to time in order to provide personalized services to users.
9.2 A cookie is a small amount of information sent to the user’s computer browser by a server (http) used to operate a website and may also be stored on the hard disk on the user computer.
a) Purpose of use of cookies: It is used to provide optimized information to users by grasping visits and usage forms, popular search terms, secure access status, etc. for each service and websites visited by users.
b) Installation, operation and rejection of cookies: Users can refuse to save cookies through the option setting in the [Tools] > [Internet Options] > [Privacy] at the top of the web browser.
c) If a user refuses to store cookies, the user may have difficulty in using the customized service.

10. Personal Information Protection Officer
10.1 The Company shall be responsible for the handling of personal information, and appoints the person in charge of personal information protection in order to deal with complaints and damages of Information Subjects related to the processing of personal information.

Personal Information Protection Officer: Park Joung-kwon
Title: Managing Director
Department: CSP Marketing Team
Email: joungkwon_park@tmax.co.kr

10.2 The Information Subjects can contact the personal information protection officer and the department in charge for all personal information related inquiries, complaints, and remedies that have occurred while using the service (or business) of the company. The Company shall respond promptly to the inquiries.

11. Methods for Relieving from Infringement of Rights
Information Subjects can inquire about the following organizations for damage relief, consultation, etc. The following organizations are independent from the Company. If the Information Subjects are not satisfied with the Company's own privacy complaints or damage relief process, or need further assistance, please contact the organizations.

11.1 Privacy Infringement Report Center (operated by Korea Internet Security Agency)
     Responsibilities: Report personal information infringement, apply for consultation
      Website: privacy.kisa.or.kr
      Phone: (without dialing code) 118
     Address: Personal Information Infringement Report Center, 3F, 9, Jinheung-gil, Naju-si, Jeollanam-do, Korea
11.2 Personal Information Dispute Mediation Committee
      Jurisdiction: Application for mediation of personal information disputes, Group dispute mediation (civil resolution)
       Website: www.kopico.go.kr
     Phone: (without dialing code) 1833-6972
       Address: 4th floor, Seoul Government Office, 209, Sejong-daero, Jongro-gu, Seoul
11.3 Cyber Crime Investigation Division of the Supreme Prosecutors' Office: 02-3480-3573 (www.spo.go.kr)
11.4 Cyber Security Bureau of the National Police Agency: 182 (http://cyberbureau.police.go.kr)

12. Change of Privacy Policy
This Privacy Policy shall be effective from August 15, 2019.

`}
                </PageBodyPre>
              </PageInnerContents>
            ) : (
              <PageInnerContents id="2019-08-15">
                <PageBodyPre ismobile={isMobile}>
                  {`
㈜티맥스OS(이하 “회사”라 함)는 개인정보 보호법 제30조에 따라 정보주체의 개인정보를 보호하고 이와 관련한 고충을 신속하고 원활하게 처리할 수 있도록 하기 위하여 다음과 같이 개인정보처리방침을 수립, 공개합니다.
회사는 아동의 개인정보를 보호하기 위하여, 만 14세 미만 아동의 회원가입을 제한합니다.
회사의 개인정보 수집에 대해 동의를 거부하는 경우, 본인 확인이 되지 않아 회원가입이 불가하거나 회원서비스를 이용하지 못할 수 있습니다.

제1조(개인정보의 처리 목적)
회사는 다음의 목적을 위하여 개인정보를 처리합니다. 처리하고 있는 개인정보는 다음의 목적 이외의 용도로는 이용되지 않으며, 이용 목적이 변경되는 경우에는 개인정보 보호법 제18조에 따라 별도의 동의를 받는 등 필요한 조치를 이행할 예정입니다.
1.1. 이용자와 약속한 서비스 제공, 서비스 제공에 따른 본인인증, 구매 및 요금결제, 
    요금 명세서 전달, 상품 및 서비스의 배송을 위하여 개인정보를 이용합니다. 
1.2. 회원 가입 의사의 확인, 이용자 본인 확인 및 식별, 회원탈퇴 의사의 확인, 문의사항 또는 
    불만처리 등 회원관리를 위하여 개인정보를 이용합니다. 
1.3. 법령 및 이용약관을 위반하는 회원에 대한 이용 제한 조치, 부정 이용 행위를 포함하여 
    서비스의 원활한 운영에 지장을 주는 행위 및 비인가 행위에 대한 방지 및 제재, 계정도용     
    및 부정거래 방지, 고지사항 전달, 분쟁 조정을 위한 기록보존 등 이용자 보호 및 서비스 
    운영을 위하여 개인정보를 이용합니다. 
1.4. 인구통계학적 특성에 따른 서비스 제공, 접속 빈도 분석, 기능개선, 서비스 이용에 대한 통계,   
    서비스 분석 및 통계에 기반하여 이용자의 상품 구매 및 서비스 이용 성향, 관심, 이용기록 
    분석 등을 반영한 신규 서비스 제공(개인 맞춤형 상품 추천 서비스 등 포함)등에 개인정보를 
    이용합니다. 
1.5. 이벤트 정보, 광고성 정보 제공 등 마케팅, 이벤트 및 프로모션의 목적 등에 개인정보를 
    이용할 수 있습니다. 이 경우 회원들이 별도 동의가 필요합니다.

제2조(개인정보의 처리 및 보유기간)
2.1. 회사는 원칙적으로 회원의 개인정보를 회원탈퇴 시까지 보유합니다. 단, 통합서비스를 1년 
동안 이용하지 않을 경우 휴면계정으로 분리되며 수집된 회원들의 개인정보가 분리 보관됩니다. 통합서비스 중 Tmax CloudSpace의 경우 휴면화된 회원의 데이터가 삭제될 수 있습니다. 
2.2. 회사는 법령에 따른 개인정보 보유․이용기간 또는 정보주체로부터 개인정보를 수집 시에 
    동의 받은 개인정보 보유․이용기간 내에서 개인정보를 처리․보유합니다.
2.3. 각각의 개인정보 처리 및 보유 기간은 다음과 같습니다.
    1) 홈페이지 회원 가입 및 관리: 사업자/단체 홈페이지 탈퇴 시까지 다만, 다음의 사유에   
       해당하는 경우에는 해당 사유 종료 시까지 
       a. 관계 법령 위반에 따른 수사, 조사 등이 진행 중인 경우 해당 수사, 조사 종료 시까지 
       b. 1년 간 회원의 서비스 이용 기록이 없는 경우, 『정보통신망 이용촉진 및 정보보호 
         등에 관한 법률』 제29조 제2항에 근거하여 회원에게 사전 통지하고 개인정보를 
         별도로 분리하여 저장
    2) 마케팅 및 광고에 활용: 목적 달성 시까지
    다만, 상기 회원탈퇴 및 서비스 이용 종료처리 후에도 아래 각 항의 이유로 해당 정보는    
    명시한 기간 동안 보존합니다.
    「전자상거래 등에서의 소비자 보호에 관한 법률」에 따른 표시․광고, 계약내용 및 이행 등  
    거래에 관한 기록
       a. 표시, 광고에 관한 기록: 6개월
       b. 부정이용기록 
          ① 보존 이유: 부정이용 방지 및 고객 상담 대응 등 
          ② 보존 기간: 수집일로부터 1년
2.4. 관련법령에 의한 정보 보존 사유
    상법, 전자상거래 등에서의 소비자보호에 관한 법률 등 관계법령의 규정에 의하여 보존할 
    필요가 있는 경우 회사는 관계법령에서 정한 일정한 기간 동안 회원정보를 보존합니다. 
    이 경우 회사는 보존 정보를 그 보존 목적으로만 이용하며 보존기간은 아래와 같습니다. 
    1) 서비스 이용에 관한 로그기록 
       a. 보존 이유: 통신비밀보호법 
       b. 보존 기간: 3개월 
    2) 소비자의 불만 또는 분쟁처리에 관한 기록 
       a. 보존 이유: 전자상거래 등에서의 소비자보호에 관한 법률 
       b. 보존 기간: 3년 
    3) 계약 또는 청약철회 등에 관한 기록 
       a. 보존 이유: 전자상거래 등에서의 소비자보호에 관한 법률 
       b. 보존 기간: 5년 
    4) 대금결제 및 재화 등의 공급에 관한 기록 
       a. 보존 이유: 전자상거래 등에서의 소비자보호에 관한 법률
       b. 보존 기간: 해당 이유 종료 시
이용자는 개인정보 수집 및 이용 동의를 거부할 권리가 있습니다. 다만 개인정보 수집 및 이용 동의를 거부하실 경우 가입 요청하신 서비스 이용이 제한되거나 불가할 수 있습니다. 

제3조(개인정보의 제3자 제공)
3.1. 회사는 정보주체의 개인정보를 제1조(개인정보의 처리 목적)에서 명시한 범위 내에서만 
    처리하며, 정보주체의 동의, 법률의 특별한 규정 등 개인정보 보호법 제17조 및 제18조에 
    해당하는 경우에만 개인정보를 제3자(회사 계열사 및 자회사 포함)에게 제공합니다. 
3.2. 다만, 아래의 경우에는 예외로 합니다.
    1) 이용자들이 사전에 동의한 경우
    2) 법령의 규정에 의거하거나, 수사 목적으로 법령에 정해진 절차와 방법에 따라 수사기관의 
       요구가 있는 경우

제4조(업무 위탁에 따른 개인정보의 처리 제한)
4.1. 회사가 제3자에게 개인정보의 처리 업무를 위탁하는 경우에는 다음 각 호의 내용이    
    포함된 문서를 작성합니다.
    1) 위탁업무 수행 목적 외 개인정보의 처리 금지에 관한 사항
    2) 개인정보의 기술적, 관리적 보호조치에 관한 사항
    3) 그 밖에 개인 정보의 안전한 관리를 위하여 대통령령으로 정한 사항
4.2. 제1항에 따라 개인정보의 처리 업무를 위탁하는 경우 위탁하는 업무의 내용과 수탁자를  
    정보주체가 언제든지 쉽게 확인할 수 있도록 인터넷 홈페이지에 공개합니다.
4.3. 회사가 재화 또는 서비스를 홍보하거나 판매를 권유하는 업무를 위탁하는 경우에는   
    이메일로 위탁하는 업무의 내용과 수탁자를 정보주체에게 알립니다. 위탁하는 업무의 
    내용이나 수탁자가 변경된 경우에도 또한 같습니다.
4.4. 회사는 개인정보보호와 관련하여 수탁자를 교육하고, 수탁자가 제1항을 준수하는지  
    감독합니다.
4.5. 수탁자가 위탁 받은 업무와 관련하여 개인정보를 처리하는 과정에서 발생한 손해배상책임에 대하여 수탁자를 회사의 소속 직원으로 봅니다.

제5조(정보주체와 법정대리인의 권리, 의무 및 행사방법)
5.1. 정보주체는 회사에 대해 언제든지 개인정보 열람, 정정, 삭제, 처리정지 요구 등의 권리를   
    행사할 수 있습니다.
5.2. 제1항에 따른 권리 행사는 회사에 대해 개인정보보호법 시행령 제41조제1항에 따라   
    서면, 이메일, 모사 전송(FAX) 등을 통하여 하실 수 있으며, 회사는 이에 대해 지체 없이 
    조치하겠습니다.
5.3. 제1항에 따른 권리 행사는 정보주체의 법정대리인이나 위임을 받은 자 등 대리인을 통하여
    하실 수 있습니다. 이 경우 개인정보 보호법 시행규칙 별지 제11호 서식에 따른 위임장을 
    제출하셔야 합니다.
5.4. 개인정보 열람 및 처리 정지 요구는 개인정보보호법 제35조 제5항, 제37조 제2항에 
    의하여 정보주체의 권리가 제한 될 수 있습니다.
5.5. 개인정보의 정정 및 삭제 요구는 다른 법령에서 그 개인정보가 수집 대상으로 명시되어 있는 
    경우에는 그 삭제를 요구할 수 없습니다.
5.6. 회사는 정보주체 권리에 따른 열람의 요구, 정정·삭제의 요구, 처리정지의 요구 시 열람 등 
    요구를 한 자가 본인이거나 정당한 대리인인지를 확인합니다.
5.7. 회사가 제3자에게 정보를 제공한 경우, 정정처리결과를 제3자에게 지체 없이 통지하여 
    정정이 이루어지도록 조치하겠습니다.

제 6 조 (처리하는 개인정보 항목)
6.1. 수집하는 개인정보의 항목
    1) 통합 계정 회원가입을 위해 아래와 같은 개인정보를 수집합니다. 
       a. (필수) 이름, 생년월일, 아이디, 비밀번호, 이메일, 
         내국인/외국인, 개인사업자/법인사업자 
       b. (선택) 직업, 소속, 주소, 가입경로 등 
    2) 서비스 이용과정에서 아래와 같은 정보가 생성되어 수집될 수 있습니다. 
       a. 사용자ID, IP Address, 쿠키, 방문 일시, 서비스 다운로드 기록, 서비스 이용 기록, 
         부정 이용 기록, 모바일 서비스 이용 시 단말기 정보(단말장치 식별 고유정보, 
         단말기 환경정보, 광고 ID, 서비스 이용에 대한 기본 통계), 
         모바일 단말기 내 설치된 어플리케이션 설치 및 사용이력, 구매앱리스트, 결제정보

제7조(개인정보의 파기)
7.1. 회사는 개인정보 보유기간의 경과, 처리 목적 달성 등 개인정보가 불필요하게 되었을 때에는 지체 없이 해당 개인정보를 파기합니다.
7.2. 정보주체로부터 동의 받은 개인정보 보유기간이 경과하거나 처리 목적이 달성되었음에도 
    불구하고 다른 법령에 따라 개인정보를 계속 보존하여야 하는 경우에는, 해당 개인정보를 
    별도의 데이터베이스(DB)로 옮기거나 보관장소를 달리하여 보존합니다.
7.3. 개인정보 파기의 절차 및 방법은 다음과 같습니다.
    1) 파기절차
      회사는 파기 사유가 발생한 개인정보를 선정하고, 회사의 개인정보 보호책임자의 승인을   
      받아 개인정보를 파기합니다.
    2) 파기방법
      회사는 전자적 파일 형태로 기록, 저장된 개인정보는 기록을 재생할 수 없도록 파기하며,    
      종이 문서에 기록, 저장된 개인정보는 분쇄기로 분쇄하거나 소각하여 파기합니다.

제8조(개인정보의 안전성 확보 조치)
회사는 개인정보의 안전성 확보를 위해 다음과 같은 조치를 취하고 있습니다.
8.1. 관리적 조치: 내부관리계획 수립, 시행, 정기적 직원 교육 등
8.2. 기술적 조치: 개인정보처리시스템 등의 접근권한 관리, 접근통제시스템 설치,
고유식별정보 등의 암호화, 보안프로그램 설치
8.3. 물리적 조치: 전산실, 자료보관실 등의 접근통제

제9조(개인정보 자동 수집 장치의 설치∙운영 및 거부에 관한 사항)
9.1. 회사는 이용자에게 개별적인 맞춤서비스를 제공하기 위해 이용정보를 저장하고 수시로   
    불러오는 ‘쿠키(cookie)’를 사용합니다.
9.2. 쿠키는 웹사이트를 운영하는데 이용되는 서버(http)가 이용자의 컴퓨터 브라우저에게 보내는 
    소량의 정보이며 이용자들의 PC 컴퓨터 내의 하드디스크에 저장되기도 합니다.
    1) 쿠키의 사용목적: 이용자가 방문한 각 서비스와 웹 사이트들에 대한 방문 및 이용형태,
      인기검색어, 보안접속 여부, 등을 파악하여 이용자에게 최적화된 정보 제공을 위해
      사용됩니다.
    2) 쿠키의 설치∙운영 및 거부: 웹브라우저 상단의 도구>인터넷 옵션>개인정보 메뉴의 옵션   
      설정을 통해 쿠키 저장을 거부 할 수 있습니다.
    3) 쿠키 저장을 거부할 경우 맞춤형 서비스 이용에 어려움이 발생할 수 있습니다.

제10조(개인정보 보호책임자)
10.1. 회사는 개인정보 처리에 관한 업무를 총괄해서 책임지고, 개인정보 처리와 관련한   
     정보주체의 불만처리 및 피해구제 등을 위하여 아래와 같이 개인정보 보호책임자를 
     지정하고 있습니다.
책임자	박정권
직위	상무
부서	CSP마케팅팀
이메일	joungkwon_park@tmax.co.kr

10.2. 정보주체께서는 회사의 서비스(또는 사업)을 이용하시면서 발생한 모든 개인정보 보호 
     관련 문의, 불만처리, 피해구제 등에 관한 사항을 개인정보 보호책임자 및 담당부서로 
     문의하실 수 있습니다. 회사는 정보주체의 문의에 대해 지체 없이 답변 및 처리해드릴 
     것입니다.

제11조(권익침해 구제방법)
정보주체는 아래의 기관에 대해 개인정보 침해에 대한 피해구제, 상담 등을 문의하실 수 있습니다. 아래의 기관은 회사와는 별개의 기관으로서, 회사의 자체적인 개인정보 불만처리,
피해구제 결과에 만족하지 못하시거나 보다 자세한 도움이 필요하시면 문의하여 주시기 바랍니다.
11.1. 개인정보 침해신고센터 (한국인터넷진흥원 운영)
     소관업무: 개인정보 침해사실 신고, 상담 신청
     홈페이지: privacy.kisa.or.kr
     전화: (국번없이) 118
     주소: (58324) 전남 나주시 진흥길 9(빛가람동 301-2) 3층 개인정보침해신고센터
11.2. 개인정보 분쟁조정위원회
     소관업무: 개인정보 분쟁조정신청, 집단분쟁조정 (민사적 해결)
     홈페이지: www.kopico.go.kr
     전화: (국번없이) 1833-6972
     주소: (03171)서울특별시 종로구 세종대로 209 정부서울청사 4층
11.3 대검찰청 사이버범죄수사단 : 02-3480-3573 (www.spo.go.kr)
11.4 경찰청 사이버안전국 : 182 (http://cyberbureau.police.go.kr)

제12조(개인정보 처리방침 변경)
이 개인정보 처리방침은 2019년 08월 15일 부터 적용됩니다.`}
                </PageBodyPre>
              </PageInnerContents>
            )}
          </PageInner>
        </PageBody>
      </PagePolicy>
    </>
  );
};

export default PrivacyPolicy;

import React from 'react';
import styled from 'styled-components';

export default function ({
  icon,
  typeName,
  description,
  onClick,
  disabled = false,
}) {
  return (
    <WithdrawalTypeItem onClick={onClick} disabled={disabled}>
      {icon}
      <WithdrawalTypeName>{typeName}</WithdrawalTypeName>
      <WithdrawalTypeDescription>{description}</WithdrawalTypeDescription>
    </WithdrawalTypeItem>
  );
}

const WithdrawalTypeItem = styled.button`
  cursor: pointer;
  background: ${props => props.theme.StateNormal};
  width: 12.8125rem;
  border: 1px solid ${props => props.theme.LineMain};
  border-radius: 0.875rem;
  padding: 3.0625rem 0 3rem 0;
  display: flex;
  flex-direction: column;
  align-items: center;
  &:hover {
    background-color: ${props => props.theme.StateBright};
    border 1px solid ${props => props.theme.StateBright};
  }
  &:active,
  &:focus {
    border: 1px solid ${props => props.theme.StateDark};
    background-color: ${props => props.theme.StateDark};
  }
  &:not(:first-child) {
    margin-left: 1.875rem;
  }
  &:disabled {
    border: 1px solid ${props => props.theme.LineMain};
    box-shadow: none;
    cursor: not-allowed;
    background-color: ${props => props.theme.DisabledShape};
    pointer-events: all !important;
  }
`;

const WithdrawalTypeName = styled.h1`
  font-weight: 500;
  margin: 0 0 0.625rem 0;
  font-size: 0.9375rem;
  line-height: 1.375rem;
  color: ${props => props.theme.TextMain};
`;

const WithdrawalTypeDescription = styled.h1`
  margin: 0;
  font-size: 0.75rem;
  font-weight: 400;
  line-height: 1rem;
  text-align: center;
  color: #696969;
  padding: 1rem 0.5rem;
  white-space: pre-line;
  letter-spacing: -0.00625rem;
`;

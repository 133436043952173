// @flow
/* eslint-disable class-methods-use-this */
import { ComAPI } from 'teespace-core';

class EventRepository {
  async joinEvent({ eventInfo }: { eventInfo: Object }): Promise<boolean> {
    const queryObj = {
      dto: {
        EVENT_ID: eventInfo.eventId,
        USER_LOGIN_ID: eventInfo.userLoginId,
      },
    };
    const axiosConfig = {
      headers: {
        isWAPL: true,
      },
    };
    try {
      const response = await ComAPI.post(
        `superadmin-api/event-user`,
        queryObj,
        axiosConfig,
      );
      if (response.data.dto.RESULT_CD === 'RST0001') {
        return true;
      }
      return false;
    } catch (error) {
      throw Error(JSON.stringify(error.response.data.exception));
    }
  }

  async getJoinedEvent({ eventInfo }: { eventInfo: Object }): Promise<boolean> {
    const queryObj = {
      dto: {
        EVENT_ID: eventInfo.eventId,
        USER_LOGIN_ID: eventInfo.userLoginId,
      },
    };
    const axiosConfig = {
      headers: {
        isWAPL: true,
      },
    };
    try {
      const response = await ComAPI.post(
        `superadmin-api/user-join?action=Get`,
        queryObj,
        axiosConfig,
      );
      if (response.data.dto.RESULT_CD) {
        return response.data.dto.RESULT_CD;
      }
      return false;
    } catch (error) {
      throw Error(JSON.stringify(error.response.data.exception));
    }
  }
}

export default new EventRepository();

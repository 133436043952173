import React, { useState, useCallback } from 'react';
import { useTranslation } from 'react-i18next';
import styled from 'styled-components';
import { useHistory } from 'react-router-dom';
import { Message, useCoreStores } from 'teespace-core';
import { Radio, Button, Input, Modal as Dialog, Form } from 'antd';
import { useStores } from '../../stores';
import { logout } from '../../libs/keycloak';

const { TextArea } = Input;

function WithdrawalFormDialog({ visible, onCancel }) {
  const [form] = Form.useForm();
  const [textAreaDisabled, setTextAreaDisabled] = useState(true);
  const [messageTitle, setMessageTitle] = useState('');
  const [messageVisible, setMessageVisible] = useState(false);
  const [submitDisabled, setSubmitDisabled] = useState(true);
  const { waplUserStore, waplAuthStore } = useCoreStores();
  const { spaceStore } = useStores();
  const { t, i18n } = useTranslation('common');
  const history = useHistory();
  const footer = (
    <>
      <Button
        type="solid"
        onClick={() => form.submit()}
        disabled={!textAreaDisabled && submitDisabled}
      >
        {t('CM_DEL_WAPL_23')}
      </Button>
      <Button onClick={onCancel}>{t('CM_CANCEL')}</Button>
    </>
  );

  const handleValuesChange = useCallback((changedValues, values) => {
    if (values.code === 'RSN0007') {
      setTextAreaDisabled(false);
      if (values.textReason) {
        setSubmitDisabled(values.textReason.length < 10);
      } else {
        setSubmitDisabled(true);
      }
    } else {
      setTextAreaDisabled(true);
    }
  }, []);
  const handleWithdrawal = useCallback(
    async values => {
      const getReason = () => {
        switch (values.code) {
          case 'RSN0001':
            return t('CM_DEL_WAPL_13');
          case 'RSN0002':
            return t('CM_DEL_WAPL_14');
          case 'RSN0003':
            return t('CM_DEL_WAPL_15');
          case 'RSN0004':
            return t('CM_DEL_WAPL_16');
          case 'RSN0005':
            return t('CM_DEL_WAPL_17');
          case 'RSN0006':
            return t('CM_DEL_WAPL_18');
          default:
            return values.textReason;
        }
      };
      const mergedReason = getReason();
      try {
        if (
          spaceStore.spaceList.filter(
            space => space.adminLoginId === waplUserStore.myProfile.loginId,
          ).length > 0
        ) {
          setMessageTitle(t('CM_SELECT_DELETE_OPTION_05'));
          setMessageVisible(true);
          return;
        }
        await waplAuthStore.withdrawal({
          code: values.code,
          reason: mergedReason,
        });
        await waplAuthStore.logout({ deviceType: 'PC' });
        await logout({
          redirectUri: window.location.origin,
          locale: i18n.language,
        });
        history.replace('/?force=true');
      } catch (error) {
        const { name, message } = JSON.parse(error.message);
        if (name === 'java.lang.RuntimeException') {
          setMessageTitle(message);
          setMessageVisible(true);
        } else {
          throw Error(error);
        }
      }
    },
    [
      history,
      spaceStore.spaceList,
      t,
      waplAuthStore,
      waplUserStore.myProfile.loginId,
    ],
  );
  return (
    <StyledDialog
      visible={visible}
      onCancel={onCancel}
      title={t('CM_DEL_WAPL_12')}
      footer={footer}
    >
      <Wrapper>
        <Message
          title={messageTitle}
          visible={messageVisible}
          btns={[
            {
              type: 'solid',
              text: t('CM_LOGIN_POLICY_03'),
              onClick: () => setMessageVisible(false),
            },
          ]}
        />
        <Form
          form={form}
          onFinish={handleWithdrawal}
          onValuesChange={handleValuesChange}
        >
          <WithdrawalTitle>{t('CM_DEL_WAPL_24')}</WithdrawalTitle>
          <CheckboxWrapper>
            <StyledFormItem name="code">
              <Radio.Group>
                <StyledRadio value="RSN0001" name={t('CM_DEL_WAPL_13')}>
                  {t('CM_DEL_WAPL_13')}
                </StyledRadio>
                <StyledRadio value="RSN0002" name={t('CM_DEL_WAPL_14')}>
                  {t('CM_DEL_WAPL_14')}
                </StyledRadio>
                <StyledRadio value="RSN0003" name={t('CM_DEL_WAPL_15')}>
                  {t('CM_DEL_WAPL_15')}
                </StyledRadio>
                <StyledRadio value="RSN0004" name={t('CM_DEL_WAPL_16')}>
                  {t('CM_DEL_WAPL_16')}
                </StyledRadio>
                <StyledRadio value="RSN0005" name={t('CM_DEL_WAPL_17')}>
                  {t('CM_DEL_WAPL_17')}
                </StyledRadio>
                <StyledRadio value="RSN0006" name={t('CM_DEL_WAPL_18')}>
                  {t('CM_DEL_WAPL_18')}
                </StyledRadio>
                <StyledRadio value="RSN0007">{t('CM_DEL_WAPL_19')}</StyledRadio>
              </Radio.Group>
            </StyledFormItem>
          </CheckboxWrapper>
          <StyledFormItem name="textReason">
            <TextArea
              disabled={textAreaDisabled}
              placeholder={t('CM_DEL_WAPL_20')}
              rows={4}
              maxLength={300}
            />
          </StyledFormItem>
          <BottomText>{t('CM_DEL_WAPL_22')}</BottomText>
        </Form>
      </Wrapper>
    </StyledDialog>
  );
}

export default WithdrawalFormDialog;

const StyledDialog = styled(Dialog)`
  .ant-modal-body {
    padding: 0;
  }
  .ant-modal-footer {
    border-color: transparent;
  }
`;

const Wrapper = styled.div`
  padding: 1.5rem 1.875rem 1.25rem 1.875rem;
  background-color: ${props => props.theme.SubStateNormal};
`;

const WithdrawalTitle = styled.h1`
  font-size: 0.8125rem;
  line-height: 1.1875rem;
  font-weight: 400;
  margin-bottom: 1.25rem;
  color: ${props => props.theme.TextMain};
`;

const CheckboxWrapper = styled.div`
  display: flex;
  flex-direction: column;
  label.ant-radio-wrapper {
    margin-left: 0;
    margin-bottom: 0.625rem;
  }
`;

const StyledRadio = styled(Radio)`
  display: block;
  font-size: 0.75rem;
  line-height: 1.125rem;
  color: ${props => props.theme.TextSub};
  font-weight: 400;
`;

const BottomText = styled.h1`
  margin-top: 1.25rem;
  text-align: center;
  font-size: 0.8125rem;
  line-height: 1.1875rem;
  font-weight: 400;
  color: ${props => props.theme.TextMain};
`;

const StyledFormItem = styled(Form.Item)`
  margin-bottom: 0;
`;

import { useObserver } from 'mobx-react';
import React, { useState, useCallback } from 'react';
import styled from 'styled-components';
import { useCoreStores } from 'teespace-core';
import WorkspaceProfileDialog from './SpaceHeaderProfileDialog';

function SpaceHeaderProfile({ loader, support }) {
  const { waplUserStore } = useCoreStores();
  const [visibleDialog, setVisibleDialog] = useState(false);

  const handleDialogClose = useCallback(() => {
    setVisibleDialog(false);
  }, []);

  const handleDialogOpen = useCallback(() => {
    setVisibleDialog(true);
  }, []);

  return useObserver(() => (
    <>
      {waplUserStore.myProfile?.id && (
        <ProfileWrapper>
          <ProfileText>
            <ProfileTitle>{waplUserStore.myProfile?.displayName}</ProfileTitle>
            <ProfileEmail>{waplUserStore.myProfile?.loginId}</ProfileEmail>
          </ProfileText>
          <Profile
            src={waplUserStore.myProfile.defaultPhotoUrl}
            onClick={handleDialogOpen}
          />
          <WorkspaceProfileDialog
            visible={visibleDialog}
            onCancel={handleDialogClose}
            loader={loader}
            support={support}
          />
        </ProfileWrapper>
      )}
    </>
  ));
}

export default SpaceHeaderProfile;

const ProfileWrapper = styled.div`
  display: flex;
  flex-direction: row;
`;

const ProfileText = styled.div`
  display: flex;
  flex-direction: column;
  text-align: right;
  margin-right: 0.625rem;
`;

const ProfileTitle = styled.h1`
  font-size: 0.625rem;
  font-weight: 500;
  line-height: 0.9375rem;
  color: ${props => props.theme.TextMain};
`;

const ProfileEmail = styled.h2`
  color: ${props => props.theme.TextSub};
  font-weight: 400;
  line-height: 0.9375rem;
  font-size: 0.625rem;
`;

const Profile = styled.img`
  align-self: center;
  width: 1.875rem;
  height: 1.875rem;
  margin-right: 0.8125rem;
  cursor: pointer;
`;

import Keycloak from 'keycloak-js';
import Cookies from 'js-cookie';
import SpaceStore from '../stores/SpaceStore';
import FriendStore from '../stores/FriendStore';

export const keycloakConfig = {
  // url: 'http://localhost:8080/auth',
  // url: 'https://auth.teespace.com/auth',
  // url: 'http://220.90.208.182:8080/auth',
  // url : 'https://devauth.tmaxoneaccount.com/auth',
  // realm: 'tmax',
  // clientId: 'hyperspace_dev',
  url:
    window.env.REACT_APP_HYPERAUTH_URL === '%HYPERAUTH_URL%'
      ? process.env.REACT_APP_HYPERAUTH_URL
      : window.env.REACT_APP_HYPERAUTH_URL ||
        process.env.REACT_APP_HYPERAUTH_URL, // keycloak server 주소
  realm:
    window.env.REACT_APP_HYPERAUTH_REALM === '%HYPERAUTH_REALM%'
      ? process.env.REACT_APP_HYPERAUTH_REALM
      : window.env.REACT_APP_HYPERAUTH_REALM ||
        process.env.REACT_APP_HYPERAUTH_REALM,
  clientId:
    window.env.REACT_APP_HYPERAUTH_CLIENT_ID === '%HYPERAUTH_CLIENT_ID%'
      ? process.env.REACT_APP_HYPERAUTH_CLIENT_ID
      : window.env.REACT_APP_HYPERAUTH_CLIENT_ID ||
        process.env.REACT_APP_HYPERAUTH_CLIENT_ID,
};
const keycloak = new Keycloak(keycloakConfig);
export const initOptions = {
  onLoad: 'check-sso',
  silentCheckSsoRedirectUri: `${window.location.origin}/silent-check-sso.html`,
};

export const logout = (options = {}) => {
  Cookies.remove('ACCESS_TOKEN');
  Cookies.remove('DEVICE_TYPE');
  Cookies.remove('isInvitedUser');

  SpaceStore.setSpaces([]);
  FriendStore.setFriends([]);
  return keycloak.logout(options);
};

export default keycloak;

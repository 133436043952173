import React, { useState, useCallback } from 'react';
import styled, { css } from 'styled-components';
import { Message, useCoreStores, logEvent } from 'teespace-core';
import { Button, Checkbox, Input } from 'antd';
import { DownOutlined, UpOutlined } from '@ant-design/icons';
import { useHistory } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { logout } from '../../libs/keycloak';

const FirstLoginContent = () => {
  const { waplAuthStore, waplUserStore, waplEventListStore } = useCoreStores();
  const history = useHistory();
  const { t, i18n } = useTranslation(['common', 'service']);
  const CheckboxGroup = Checkbox.Group;
  const plainOptions = [
    'agreeService',
    'agreePrivacy',
    'agreeChoice',
    'agreeMarketing',
    'agreeAd',
  ];

  // Checkbox
  const [checkedList, setCheckedList] = useState('');
  const [checkAll, setCheckAll] = useState(
    checkedList.length === plainOptions.length,
  );
  // Accordian Tab
  const [clickService, setClickServcie] = useState(false);
  const [clickPrivacy, setClickPrivacy] = useState(false);
  const [clickChoice, setClickChoice] = useState(false);
  const [clickMarketing, setClickMarketing] = useState(false);

  // dialog
  const [messageVisible, setMessageVisible] = useState(false);

  const onHandleAccordionServiceClick = useCallback(() => {
    setClickServcie(!clickService);
  }, [clickService]);

  const onHandleAccordionPrivacyClick = useCallback(() => {
    setClickPrivacy(!clickPrivacy);
  }, [clickPrivacy]);

  const onHandleAccordionChoiceClick = useCallback(() => {
    setClickChoice(!clickChoice);
  }, [clickChoice]);

  const onHandleAccordionMarketingClick = useCallback(() => {
    setClickMarketing(!clickMarketing);
  }, [clickMarketing]);

  const onCheckAllChange = e => {
    setCheckedList(e.target.checked ? plainOptions : []);
    setCheckAll(e.target.checked);
  };
  const onChange = checkedList => {
    setCheckedList(checkedList);
    checkedList.length === plainOptions.length
      ? setCheckAll(true)
      : setCheckAll(false);
  };

  const onHandleAgreeClick = async () => {
    let isOptionalTermsAgree = 'N';
    let isAdvertiseAgree = 'N';
    let isMarketingAgree = 'N';
    if (checkedList.includes('agreeChoice')) {
      isOptionalTermsAgree = 'Y';
    }
    if (checkedList.includes('agreeAd')) {
      isAdvertiseAgree = 'Y';
    }
    if (checkedList.includes('agreeMarketing')) {
      isMarketingAgree = 'Y';
    }

    const query = new URLSearchParams(window.location.search);
    const redirectUri = query.get('redirect_uri');
    // 서비스
    const success = await waplAuthStore.updateTerms({
      isOptionalTermsAgree,
      isAdvertiseAgree,
      isMarketingAgree,
    });
    console.log(success);
    if (success) {
      logEvent('login', 'clickServiceAgreementBtn');
      const type = 'TYPE0002';
      const getActiveEvent = await waplEventListStore.getActiveEvent({ type });
      console.log(getActiveEvent);
      if (!getActiveEvent) {
        if (redirectUri) {
          history.replace(decodeURIComponent(redirectUri), { tutorial: true });
        } else {
          history.replace('/spaces', { tutorial: true });
        }
      } else if (redirectUri) {
        history.replace(
          `/invite-code?redirect_uri=${encodeURIComponent(redirectUri)}`,
        );
      } else {
        history.replace('/invite-code');
      }
    }
  };

  const onHandleCancelClick = () => {
    setMessageVisible(true);
  };

  const handleMessageClose = async () => {
    await waplAuthStore.logout({ deviceType: 'PC' });
    await logout({
      redirectUri: window.location.origin,
      locale: i18n.language,
    });

    setMessageVisible(false);
  };
  return (
    <>
      <Message
        visible={messageVisible}
        title={`${t('common:CM_AGREE_ALERT_01')}`}
        subtitle={`${t('common:CM_REDIRECTED_LOGIN_PAGE_02')}`}
        btns={[
          {
            type: 'solid',
            shape: 'round',
            text: `${t('common:CM_LOGIN_POLICY_03')}`,
            onClick: handleMessageClose,
          },
        ]}
      />
      <Content>
        <ContentHeader>{t(`common:CM_SERVICE_AREE_TITLE_01`)}</ContentHeader>
        <Text>{t(`common:CM_SERVICE_AREE_SUB_TITLE_01`)}</Text>

        <CheckboxWrapper>
          <CheckAll>
            <Checkbox checked={checkAll} onChange={onCheckAllChange}>
              {t(`common:CM_SERVICE_AREE_CHECKBOX_01`)}
            </Checkbox>
          </CheckAll>

          <CheckboxGroup value={checkedList} onChange={onChange}>
            <Accordion>
              <Checkbox value="agreeService">
                {t(`common:CM_SERVICE_AREE_CHECKBOX_02`)}{' '}
                <TextRed>{t(`common:CM_SERVICE_AREE_CHECKBOX_03`)}</TextRed>
              </Checkbox>
              <AccordianSpan>
                {clickService ? (
                  <UpOutlined onClick={onHandleAccordionServiceClick} />
                ) : (
                  <DownOutlined onClick={onHandleAccordionServiceClick} />
                )}
              </AccordianSpan>
            </Accordion>
            {clickService && (
              <AuthDiv>
                <TermContent>
                  <PreTag>{t('service:SP_USE_TERMS_02_22')}</PreTag>
                </TermContent>
              </AuthDiv>
            )}
            <Accordion>
              <Checkbox value="agreePrivacy">
                {t(`common:CM_SERVICE_AREE_CHECKBOX_04`)}{' '}
                <TextRed>{t(`common:CM_SERVICE_AREE_CHECKBOX_03`)}</TextRed>
              </Checkbox>
              <AccordianSpan>
                {clickPrivacy ? (
                  <UpOutlined onClick={onHandleAccordionPrivacyClick} />
                ) : (
                  <DownOutlined onClick={onHandleAccordionPrivacyClick} />
                )}
              </AccordianSpan>
            </Accordion>
            {clickPrivacy &&
              (i18n.language === 'en' ? (
                <AuthDiv>
                  <TermContent>
                    <PreTag>
                      <TableAgree>
                        <thead>
                          <tr>
                            <TableTh>Purpose</TableTh>
                            <TableTh>Items to be collected</TableTh>
                            <TableTh>Period of retention</TableTh>
                          </tr>
                        </thead>
                        <tbody>
                          <tr>
                            <TableTd>Website membership management</TableTd>
                            <TableTd>
                              <div>· User personal information: </div>
                              <div> ID (email), password, name</div>
                              <br />
                              <div>· Usage history:</div>
                              <div>
                                chat transcript data, calendar, memo and other
                                information stored during the use of services
                              </div>
                            </TableTd>
                            <TableTd highlight>
                              Until withdrawal of membership
                            </TableTd>
                          </tr>
                        </tbody>
                      </TableAgree>
                      You can refuse to provide the above information, in case
                      of which, however, you will not be able to enjoy the full
                      functionality of the services.
                    </PreTag>
                  </TermContent>
                </AuthDiv>
              ) : (
                <AuthDiv>
                  <TermContent>
                    <PreTag>
                      <TableAgree>
                        <thead>
                          <tr>
                            <TableTh>수집 목적</TableTh>
                            <TableTh>수집 항목</TableTh>
                            <TableTh>보유∙이용 기간</TableTh>
                          </tr>
                        </thead>
                        <tbody>
                          <tr>
                            <TableTd>홈페이지 회원 관리</TableTd>
                            <TableTd>
                              <div>· 고객 개인정보</div>
                              <div> 아이디(이메일), 비밀번호, 성명</div>
                              <br />
                              <div>· 이용 내역</div>
                              <div>
                                대화내역, 캘린더, 메모 등 서비스 이용시 입력되는
                                정보
                              </div>
                            </TableTd>
                            <TableTd highlight>회원 탈퇴시 까지</TableTd>
                          </tr>
                        </tbody>
                      </TableAgree>
                      위 사항에 대하여 동의를 거부할 권리가 있으나, 거부 시
                      서비스 이용이 제한됩니다.
                    </PreTag>
                  </TermContent>
                </AuthDiv>
              ))}
            <Accordion>
              <Checkbox value="agreeChoice">
                {t(`common:CM_SERVICE_AREE_CHECKBOX_05`)}{' '}
                <TextBlue>{t(`common:CM_SELECT`)}</TextBlue>
              </Checkbox>
              <AccordianSpan>
                {clickChoice ? (
                  <UpOutlined onClick={onHandleAccordionChoiceClick} />
                ) : (
                  <DownOutlined onClick={onHandleAccordionChoiceClick} />
                )}
              </AccordianSpan>
            </Accordion>
            {clickChoice &&
              (i18n.language === 'en' ? (
                <AuthDiv>
                  <TermContent>
                    <PreTag>
                      <TableAgree>
                        <thead>
                          <tr>
                            <TableTh>Purpose</TableTh>
                            <TableTh>Items to be collected</TableTh>
                            <TableTh>Period of retention</TableTh>
                          </tr>
                        </thead>
                        <tbody>
                          <tr>
                            <TableTd>Provision of the WAPL services</TableTd>
                            <TableTd>
                              <div>
                                · Profile information (company phone number,
                                email)
                              </div>
                              <br />
                              <div>
                                · Personal information (date of birth,
                                company/department, position/title), profile
                                photo, friends list
                              </div>
                              <br />
                              <div>
                                · Linked email account information (email
                                address and password)
                              </div>
                            </TableTd>
                            <TableTd highlight>
                              Until withdrawal of membership
                            </TableTd>
                          </tr>
                        </tbody>
                      </TableAgree>
                      <div>
                        You can refuse to provide the above information, in case
                        of which, however, you will not be able to enjoy the
                        full functionality of the services.
                      </div>
                      <TextLineBold>
                        Even if you do not consent to provide the above
                        information, entering the items above in the
                        subscription form is considered consent.
                      </TextLineBold>
                    </PreTag>
                  </TermContent>
                </AuthDiv>
              ) : (
                <AuthDiv>
                  <TermContent>
                    <PreTag>
                      <TableAgree>
                        <thead>
                          <tr>
                            <TableTh>수집 목적</TableTh>
                            <TableTh>수집 항목</TableTh>
                            <TableTh>보유∙이용 기간</TableTh>
                          </tr>
                        </thead>
                        <tbody>
                          <tr>
                            <TableTd>와플 서비스 이용</TableTd>
                            <TableTd>
                              <div>· 프로필정보(회사 전화, 이메일)</div>
                              <br />
                              <div>
                                · 개인정보(생년월일, 소속회사/부서, 직위/직책),
                                프로필 사진, 친구목록
                              </div>
                              <br />
                              <div>
                                · 이메일 연동정보(이메일, 이메일 비밀번호)
                              </div>
                            </TableTd>
                            <TableTd highlight>회원 탈퇴시 까지</TableTd>
                          </tr>
                        </tbody>
                      </TableAgree>
                      <div>
                        위 사항에 대하여 동의를 거부할 권리가 있으나, 거부 시
                        서비스 이용이 제한됩니다.
                      </div>
                      <TextLineBold>
                        위 사항에 동의하지 않더라도, 해당 수집 항목을 입력하시면
                        동의 한 것으로 간주됩니다.
                      </TextLineBold>
                    </PreTag>
                  </TermContent>
                </AuthDiv>
              ))}
            <Accordion>
              <Checkbox value="agreeMarketing" style={{ fontSize: `0.7rem` }}>
                {t(`common:CM_SERVICE_AREE_CHECKBOX_06`)}
                <TextBlue>{t(`common:CM_SELECT`)}</TextBlue>
              </Checkbox>
              <AccordianSpan>
                {clickMarketing ? (
                  <UpOutlined onClick={onHandleAccordionMarketingClick} />
                ) : (
                  <DownOutlined onClick={onHandleAccordionMarketingClick} />
                )}
              </AccordianSpan>
            </Accordion>
            {clickMarketing &&
              (i18n.language === 'en' ? (
                <AuthDiv>
                  <TermContent>
                    <PreTagSmall>
                      <TableAgree>
                        <thead>
                          <tr>
                            <TableThSmall>Third-party</TableThSmall>
                            <TableThSmall>Use</TableThSmall>
                            <TableThSmall>Items to be shared</TableThSmall>
                            <TableThSmall>Period of retention</TableThSmall>
                          </tr>
                        </thead>
                        <tbody>
                          <tr>
                            <TableTdSmall bold>
                              TmaxGroup (TmaxA&C, TmaxCloud, TmaxWAPL,
                              TamxTivine, TmaxAI, TmaxEdu , TmaxMetaverse, Tmax
                              AI Development Center, TmaxOS, TmaxOffice,
                              TmaxSoft, TmaxTibero)
                            </TableTdSmall>
                            <TableTdSmall bold>
                              <div>
                                Promotional events, advertisements and marketing
                              </div>
                            </TableTdSmall>
                            <TableTdSmall>
                              ID (email), name, password, record about
                              subscription or withdrawal of membership
                            </TableTdSmall>
                            <TableTdSmall bold>
                              Until withdrawal of membership, unless it is
                              necessary to retain certain types of user data
                              under applicable laws
                            </TableTdSmall>
                          </tr>
                        </tbody>
                      </TableAgree>
                      <TextLine>
                        If you consent to the above, you may receive event
                        information or newsletters via your email or mobile
                        phone.
                      </TextLine>
                      <div>
                        You can refuse to provide the above information, in case
                        of which you will still be able to enjoy the services.
                      </div>
                    </PreTagSmall>
                  </TermContent>
                </AuthDiv>
              ) : (
                <AuthDiv>
                  <TermContent>
                    <PreTagSmall>
                      <TableAgree>
                        <thead>
                          <tr>
                            <TableThSmall>제공받는 자</TableThSmall>
                            <TableThSmall>제공 목적</TableThSmall>
                            <TableThSmall>제공하는 개인정보 항목</TableThSmall>
                            <TableThSmall>보유 및 이용 기간</TableThSmall>
                          </tr>
                        </thead>
                        <tbody>
                          <tr>
                            <TableTdSmall bold>
                              티맥스 그룹 (티맥스 에이엔씨, 티맥스 클라우드,
                              티맥스 와플, 티맥스 티바인, 티맥스 에이아이,
                              티맥스 에듀, 티맥스 메타버스, 티맥스 인공지능
                              개발센터, 티맥스 오에스, 티맥스 오피스, 티맥스
                              소프트, 티맥스 티베로)
                            </TableTdSmall>
                            <TableTdSmall bold>
                              <div>
                                이벤트 정보, 광고성 정보 제공 등 마케팅, 이벤트
                                및 프로모션의 목적
                              </div>
                            </TableTdSmall>
                            <TableTdSmall>
                              아이디(이메일), 이름, 비밀번호, 회원 가입 및 탈퇴
                              현황
                            </TableTdSmall>
                            <TableTdSmall bold>
                              관계 법령의 규정에 따라 이용자의 개인정보를 보존할
                              의무가 있는 경우가 아닌 한, 회원탈퇴 시까지
                            </TableTdSmall>
                          </tr>
                        </tbody>
                      </TableAgree>
                      <TextLine>
                        위 사항에 대하여 동의를 하시는 경우, 이벤트 정보,
                        뉴스레터 발송 등을 위하여 이메일 또는 휴대폰으로 연락이
                        갈 수 있습니다.
                      </TextLine>
                      <div>
                        위 사항에 대하여 동의를 거부할 권리가 있으며,
                        거부하시더라도 서비스를 이용하실 수 있습니다
                      </div>
                    </PreTagSmall>
                  </TermContent>
                </AuthDiv>
              ))}
            <NoAccordian>
              <Checkbox value="agreeAd">
                {t(`common:CM_ADVERTISE_AGREE_POLICY`)}{' '}
                <TextBlue>{t(`common:CM_SELECT`)}</TextBlue>
              </Checkbox>
            </NoAccordian>
            <AdvertiseSpan>
              {t(`common:CM_SERVICE_AREE_CHECKBOX_07`)}
            </AdvertiseSpan>
          </CheckboxGroup>
        </CheckboxWrapper>
        <ButtonWrapper>
          <Buttons
            type="solid"
            disabled={
              !(
                checkedList.includes('agreeService') &&
                checkedList.includes('agreePrivacy')
              )
            }
            onClick={onHandleAgreeClick}
          >
            {t(`common:CM_AGREE_BUTTON_03`)}
          </Buttons>
          <Buttons type="outlined" onClick={onHandleCancelClick}>
            {t(`common:CM_CANCEL`)}
          </Buttons>
        </ButtonWrapper>
      </Content>
    </>
  );
};

export default FirstLoginContent;

const Content = styled.div`
  display: flex;
  align-items: center;
  flex-direction: column;
  background: ${props => props.theme.StateNormal};
  border: 1px solid #e1e1e2;
  border-radius: 15px;
  border-radius: 15px;
  padding: 2.5rem 3.81rem 3.13rem 3.75rem;
  width: 28.13rem;
`;

const ContentHeader = styled.div`
  font-size: 1.5rem;
  color: ${props => props.theme.TextMain};
  font-weight: bold;
  margin-bottom: 2.5rem;
`;

const Text = styled.div`
  margin-bottom: 0.56rem;
  font-size: 0.81rem;
  color: ${props => props.theme.TextSub};
`;

const TextRed = styled.span`
  color: #ff4646;
`;

const TextBlue = styled.span`
  color: #042a54;
`;

const CheckAll = styled.div`
  border-bottom: 1px solid #d3d3d3;
  padding-bottom: 1.25rem;
`;

const CheckboxWrapper = styled.div`
  display: flex;
  width: 100%;
  flex-direction: column;
  color: ${props => props.theme.TextMain};
  font-weight: 500;
`;

const Accordion = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  margin-top: 1.25rem;
  justify-content: space-between;
  width: 100%;
`;

const NoAccordian = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  margin-top: 1.25rem;
  justify-content: space-between;
  width: 100%;
`;

const AccordianSpan = styled.span`
  cursor: pointer;
  color: #696969;
`;

const ButtonWrapper = styled.div`
  display: flex;
  flex-direction: row;
  width: 100%;
  justify-content: space-between;
  margin-top: 1.88rem;
`;

const Buttons = styled(Button)`
  width: 10rem;
  height: 1.88rem;
`;

const AuthDiv = styled.div`
  margin-top: 0.625rem;
  height: auto;
  max-height: 11rem;

  width: 100%;
  border: 1px solid #d3d3d3;
  background: ${props => props.theme.StateNormal};
  resize: none;
  overflow-y: scroll;
  font-size: 0.75rem;
  line-height: 1.25rem;
`;

const PreTag = styled.pre`
  white-space: pre-line;
  margin: 0px;
  padding: 0.625rem;
  line-height: 1.25rem;
  font-size: 0.65rem;
  letter-spacing: 0;
  font-weight: 500;
`;

const TermContent = styled.div`
  text-align: left;
  font-weight: 300;
  color: ${props => props.theme.TextMain};
  letter-spacing: 0;
  font-size: 0.75rem;
`;

const AdvertiseSpan = styled.div`
  font-size: 0.65rem;
  color: ${props => props.theme.TextSub};
`;

const TableAgree = styled.table`
  width: 100%;
  table-layout: auto;
  border-collapse: collapse;
  border-spacing: 0;
  border: 1px solid #ccc;
  background-color: transparent;
  max-height: inherit;
`;

const TableTh = styled.th`
  height: auto;
  font-size: 0.65rem;
  text-align: center;
  border: solid #ccc;
  border-width: 1px 0 0 1px;
  background-color: ${props => props.theme.LineSub};
`;

const TableTd = styled.td`
  padding: 0.5rem 1rem 0.5rem 1rem;
  border: solid #ccc;
  border-width: 1px 0 0 1px;
  font-size: 0.65rem;
  vertical-align: top;
  text-align: left;
  line-height: 0.75rem;
  ${props =>
    props.highlight &&
    css`
      font-size: 0.8rem;
      font-weight: 500;
    `}
`;

const PreTagSmall = styled.pre`
  white-space: pre-line;
  margin: 0px;
  padding: 0.3rem;
  line-height: 1.25rem;
  font-size: 0.65rem;
  letter-spacing: 0;
  font-weight: 500;
`;

const TableThSmall = styled.th`
  height: auto;
  font-size: 0.35rem;
  text-align: center;
  border: solid #ccc;
  border-width: 1px 0 0 1px;
  background-color: ${props => props.theme.LineSub};
`;

const TableTdSmall = styled.td`
  padding: 0.5rem 0.4rem 0.5rem 0.4rem;
  border: solid #ccc;
  border-width: 1px 0 0 1px;
  font-size: 0.3rem;
  vertical-align: top;
  text-align: left;
  line-height: 0.85rem;
  ${props =>
    props.bold &&
    css`
      font-size: 0.6rem;
      font-weight: bold;
    `}
`;

const TextLine = styled.div`
  text-decoration: underline;
  font-weight: bold;
  font-size: 0.7rem;
  color: ${props => props.theme.TextMain};
`;

const TextLineBold = styled.div`
  text-decoration: underline;
  font-weight: bold;
`;

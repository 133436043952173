import React from 'react';
import { useTranslation } from 'react-i18next';
import styled, { css } from 'styled-components';
import SwiperCore, { Pagination, Navigation } from 'swiper';
import { isMobile } from 'react-device-detect';
import titleImg from '../../assets/landing/media/main.png';
import titleImg_En from '../../assets/landingEn/media/main_En.png';
import mobileTitle_En from '../../assets/landingEn/mobile/video/title.png';

SwiperCore.use([Pagination, Navigation]);
const ServiceYoutubeContent = () => {
  const { i18n } = useTranslation('service');

  if (i18n.language === 'en') {
    return null;
  }

  return (
    <>
      <ServiceYoutubeContentWrapper>
        <TitleImg
          src={
            i18n.language === 'en'
              ? isMobile
                ? mobileTitle_En
                : titleImg_En
              : isMobile
              ? titleImg
              : titleImg
          }
          isen={i18n.language}
          data-aos="fade-up"
          data-aos-offset="40"
          data-aos-duration="750"
          data-aos-easing="ease"
        />
        <VideoWrapper isMobile={isMobile}>
          <StyledVideo controls isMobile={isMobile}>
            <source src="/TMAX_COREAI_INTRO.mp4" type="video/mp4" />
            Your browser does not support the video tag.
          </StyledVideo>
        </VideoWrapper>
      </ServiceYoutubeContentWrapper>
    </>
  );
};

export default ServiceYoutubeContent;

const ServiceYoutubeContentWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  position: relative;
  background-color: #ffffff;
  ${isMobile
    ? css`
        height: 37.125rem;
      `
    : css`
        height: 45.875rem;
      `}
`;

const TitleImg = styled.img`
  ${props =>
    isMobile
      ? props.isen === 'en'
        ? css`
            width: 16.01rem;
            height: 1.61rem;
            margin-top: 6.31rem;
          `
        : css`
            width: 12.06rem;
            height: 2.1rem;
            margin-top: 9.13rem;
          `
      : props.isen === 'en'
      ? css`
          margin-top: 3.55rem;
          width: 11.16rem;
          height: 1.82rem;
        `
      : css`
          margin-top: 5rem;
          width: 15.625rem;
          height: 2.625rem;
        `}
`;

const VideoWrapper = styled.div`
  border-radius: 15px;
  margin-top: ${props => (props.isMobile ? '10px' : '50px')};
`;
const StyledVideo = styled.video`
  width: ${props => (props.isMobile ? '300px' : '700px')};
  overflow: hidden;
  border-radius: 15px;
`;

/* eslint-disable prefer-promise-reject-errors */
import React, { useEffect, useState, useCallback } from 'react';
import { useHistory, useLocation } from 'react-router-dom';
import styled from 'styled-components';
import { Form, Input, useCoreStores, logEvent, Message } from 'teespace-core';
import { Button } from 'antd';
import { useTranslation } from 'react-i18next';
import { useStores } from '../../stores';

import { checkURLValid, checkEngLength } from '../../libs/Regex';

function CreateSpaceContent() {
  const { spaceStore } = useStores();
  const { waplUserStore } = useCoreStores();
  const [isLoading, setIsLoading] = useState(false);
  const history = useHistory();
  const location = useLocation();
  const query = new URLSearchParams(location.search);
  const type = query.get('type');
  const [form] = Form.useForm();
  const { t } = useTranslation('common');
  const [errorMessageVisible, setErrorMessageVisible] = useState(false);

  useEffect(() => {
    const spaceCount = spaceStore.getAdminSpaces({
      loginId: waplUserStore.myProfile.loginId,
    }).length;

    const spaceB2BCount = spaceStore
      .getAdminSpaces({
        loginId: waplUserStore.myProfile.loginId,
      })
      .filter(space => space.type === 'B2B').length;
    if (
      !type || // type이 없는 경우
      (type !== 'B2C' && type !== 'B2B') || // type이 B2C/B2B가 아닌 경우
      (type === 'B2B' && spaceB2BCount >= 1) || // type이 B2B이면서 관리자로 있는 스페이스가 1개 이상인 경우
      (type === 'B2C' && spaceCount >= 3) // type이 B2C이면서 관리자로 있는 스페이스가 3개 이상인 경우
    ) {
      history.replace('/select-space-type');
    }
  }, [
    history,
    spaceStore,
    spaceStore.spaceList,
    type,
    waplUserStore.myProfile.id,
    waplUserStore.myProfile.loginId,
  ]);
  const handleCreateSpace = useCallback(
    async values => {
      setIsLoading(true);
      try {
        const spaceInfo = await spaceStore.createSpace({
          ...values,
          type,
          userLoginId: waplUserStore.myProfile.loginId,
          userName: waplUserStore.myProfile.name,
          userId: waplUserStore.myProfile.id,
        });
        logEvent('main', 'clickStartSpaceBtn');
        window.location.href = `${window.location.protocol}//${spaceInfo.domain}`;
      } catch (error) {
        setErrorMessageVisible(true);
        setIsLoading(false);
      }
    },
    [
      spaceStore,
      type,
      waplUserStore.myProfile.id,
      waplUserStore.myProfile.loginId,
      waplUserStore.myProfile.name,
    ],
  );
  const handleValuesChange = useCallback(
    (changedValues, values) => {
      if (changedValues.domain) {
        form.setFieldsValue({
          ...values,
          domain: changedValues.domain.toLowerCase(),
        });
      }
    },
    [form],
  );

  const getPopupContainer = useCallback(triggerNode => {
    if (triggerNode?.parentNode?.parentNode) {
      return triggerNode.parentNode.parentNode;
    }
    return document.body;
  }, []);
  return (
    <>
      <Message
        title={`오류가 발생하였습니다.\n다시 시도해 주세요.`}
        visible={errorMessageVisible}
        btns={[
          {
            type: 'solid',
            text: t('CM_LOGIN_POLICY_03'),
            onClick: () => {
              setErrorMessageVisible(false);
            },
          },
        ]}
      />
      <Content>
        <SpaceTitle>{t('CM_CREATE_CONTENTS_AREA_02')}</SpaceTitle>
        <WorkspaceInfo>
          <StyledForm
            form={form}
            onFinish={handleCreateSpace}
            onValuesChange={handleValuesChange}
          >
            <FormItemLabel>{t('CM_SPACE_NAME')}</FormItemLabel>
            <Form.Item
              noStyle
              name="name"
              validateTrigger={['onBlur']}
              rules={[
                {
                  required: true,
                  message: t('CM_ENTER_SPACE_NAME'),
                  validateTrigger: 'onBlur',
                },
              ]}
            >
              <StyledInput
                type="text"
                placement="topRight"
                placeholder={t('CM_COMPANY_GROUP_MEETING_NAME')}
                maxLength="30"
                isFocus
              />
            </Form.Item>
            <FormItemLabel>URL</FormItemLabel>
            <Form.Item
              noStyle
              name="domain"
              validateTrigger={['onBlur']}
              rules={[
                {
                  required: true,
                  message: t('CM_ENTER_SPACE_URL'),
                },
                {
                  validator: (_, value) => {
                    if (!checkURLValid(value)) {
                      return Promise.reject(t('CM_LOGIN_POLICY_HYPHEN'));
                    }
                    return Promise.resolve();
                  },
                },
                {
                  validator: (_, value) => {
                    if (!checkEngLength(value, 3)) {
                      return Promise.reject(t('CM_POLICY_ALPHABET'));
                    }
                    return Promise.resolve();
                  },
                },
              ]}
            >
              <StyledInput
                type="text"
                domain={`.${window.location.hostname}`}
                placement="topRight"
                placeholder={t('CM_INPUT_ALPHABET')}
                getPopupContainer={getPopupContainer}
                maxLength="200"
              />
            </Form.Item>
            <CreateButton
              type="solid"
              size="large"
              htmlType="submit"
              loading={isLoading}
            >
              {t('CM_START')}
            </CreateButton>
          </StyledForm>
        </WorkspaceInfo>
      </Content>
    </>
  );
}

export default CreateSpaceContent;

const SpaceTitle = styled.h1`
  display: inline-block;
  width: 35rem;
  margin: 6.125rem 0 2.5rem 0;
  font-size: 1.75rem;
  line-height: 2.5625rem;
  text-align: center;
  color: ${props => props.theme.TextMain};
`;
const StyledForm = styled(Form)`
  width: 100%;
`;

const StyledInput = styled(Input)`
  margin-bottom: 2.8125rem;
  & ~ div .ant-tooltip {
    max-width: 100% !important;
  }
`;
const FormItemLabel = styled.span`
  display: block;
  font-size: 0.8125rem;
  line-height: 1.1875rem;
  margin-bottom: 0.4375rem;
  color: ${props => props.theme.TextMain};
`;
const CreateButton = styled(Button)`
  width: 100%;
  margin-top: 0.8125rem;
  font-weight: 500;
  border-radius: 0.5rem !important;
`;

const Content = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
`;

const WorkspaceInfo = styled.div`
  display: flex;
  align-items: center;
  flex-direction: column;
  background: ${props => props.theme.StateNormal};
  border: 1px solid ${props => props.theme.LineMain};
  border-radius: 15px;
  border-radius: 15px;
  margin-top: 0;
  padding: 4.1875rem 5.5625rem 3.625rem 5.6875rem;
  width: 35rem;
`;

import React, { useCallback } from 'react';
import { Link, useHistory } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import styled from 'styled-components';
import { Button } from 'teespace-core';
import Cookies from 'js-cookie';
import { useKeycloak } from '@react-keycloak/web';
import WorkspaceItem from './SpaceItem';
import WorkspaceInvitationItem from './InvitationItem';
import WaplLogoComp from '../WaplLogo';

function InvitedSpaceNotLoggedInContent({ inviteId, inviterName, spaceInfo }) {
  const history = useHistory();
  const { t, i18n } = useTranslation('common');
  const { keycloak } = useKeycloak();

  const handleJoinSpace = useCallback(() => {
    const path = window.location.href
      .split(window.location.host)[1]
      .replace('invite-link', 'join-space');
    if (!keycloak.authenticated) {
      Cookies.set('inviteId', inviteId);
      Cookies.set('inviteType', 'invite-link');
      keycloak.login({
        redirectUri: `${window.location.origin}${path}`,
        locale: i18n.language, // login page locale 설정. 'en' or 'ko' 설정.
      }); // keycloak login page로 redirect
    } else {
      history.push(`/join-space/${inviteId}`);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [history, inviteId]);

  return (
    <>
      <Header>
        <WaplLogo />
      </Header>
      <Content>
        <WorkspaceInvitationItem
          title={t('CM_INVITE_PEOPLE_URL_01', {
            value: spaceInfo.name,
          })}
          subtitle={t('CM_B2C_ADD_FRIENDS_INVITE_FORM_03', {
            value: inviterName,
          })}
        >
          <SpaceWrapper>
            <WorkspaceItem
              workspace={spaceInfo}
              // {...spaceInfo}
              displayName={t('CM_INVITE_PEOPLE_MAIL_LINK_01', {
                value: spaceInfo.name,
              })}
              readOnly
            />
          </SpaceWrapper>
          <StartButton type="solid" size="large" onClick={handleJoinSpace}>
            {t('CM_START')}
          </StartButton>
        </WorkspaceInvitationItem>
        <IntroWrapper>
          <IntroTitle>{t('CM_WAPL_INTRO')}</IntroTitle>
          <GoToServiceLink to="/?force=true">
            {t('CM_SERVICE_INTRO')}
          </GoToServiceLink>
        </IntroWrapper>
      </Content>
    </>
  );
}

export default InvitedSpaceNotLoggedInContent;

const Header = styled.div`
  display: flex;
  flex-direction: column;
  height: 3.1875rem;
  justify-content: center;
  align-items: center;
`;

const Content = styled.div`
  display: flex;
  flex-direction: column;
  margin-top: 2.8125rem;
  justify-content: center;
  align-items: center;
`;

const StartButton = styled(Button)`
  width: 100%;
  margin-top: 3rem;
  border-radius: 0.5rem !important;
`;

const IntroWrapper = styled.div`
  align-self: flex-start;
  margin-left: 1.25rem;
  margin-top: 1.25rem;
`;
const IntroTitle = styled.h1`
  margin: 0;
  font-size: 0.8125rem;
  line-height: 1.1875rem;
`;
const GoToServiceLink = styled(Link)`
  font-size: 0.8125rem;
  line-height: 1.1875rem;
  color: ${props => props.theme.MyHomeTextLink};
  text-decoration: underline;
  margin-top: 0.125rem;
  &:hover {
    color: ${props => props.theme.MyHomeTextLink};
  }
`;

const WaplLogo = styled(WaplLogoComp)`
  flex: 1;
  width: 9.0625rem;
  height: 1.75rem;
`;

const SpaceWrapper = styled.div`
  max-width: 100%;
`;

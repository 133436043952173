import React, { useCallback, useState, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { useHistory } from 'react-router-dom';
import { Button, Form } from 'antd';
import { useCoreStores, Message, Input } from 'teespace-core';
import { useObserver } from 'mobx-react';
import styled from 'styled-components';
import WithdrawalFormDialog from './WithdrawalFormDialog';
import WithdrawalHOC from './WithdrawalHOC';

function WithdrawalTermsContent() {
  const { waplUserStore } = useCoreStores();
  const [form] = Form.useForm();
  const [, forceUpdate] = useState({});
  const [dialogVisible, setDialogVisible] = useState(false);
  const [messageVisible, setMessageVisible] = useState(false);
  const { t } = useTranslation('common');
  const history = useHistory();

  const handleDialogOpen = useCallback(
    async values => {
      const success = await waplUserStore.validatePassword({
        loginId: waplUserStore.myProfile.loginId,
        pw: values.password || '',
      });
      if (success) {
        setDialogVisible(true);
      } else {
        setMessageVisible(true);
      }
    },
    [waplUserStore],
  );
  const handleSubmit = useCallback(() => {
    form.submit();
  }, [form]);

  const handleDialogClose = useCallback(() => {
    setDialogVisible(false);
  }, []);

  // To disable submit button at the beginning.
  useEffect(() => {
    forceUpdate({});
  }, []);

  return useObserver(() => (
    <Content>
      <Message
        title={t('CM_INCORRECT_PWD')}
        visible={messageVisible}
        btns={[
          {
            type: 'solid',
            text: t('CM_LOGIN_POLICY_03'),
            onClick: () => setMessageVisible(false),
          },
        ]}
      />
      <WithdrawalFormDialog
        visible={dialogVisible}
        onCancel={handleDialogClose}
      />
      <WithdrawalTitle>{t('CM_CANCEL_SERVICE')}</WithdrawalTitle>
      <WithdrawalSubtitle>{t('CM_DEL_WAPL_02')}</WithdrawalSubtitle>
      <WithdrawalDivider />
      <Form form={form} onFinish={handleDialogOpen}>
        <WithdrawalContent>
          <ConfirmContent>
            <ConfirmTitle>{t('CM_DEL_WAPL_10')}</ConfirmTitle>
            <ConfirmSubtitle>{t('CM_DEL_WAPL_11')}</ConfirmSubtitle>
            <FormContent>
              <FormItemLabelWrapper>
                <FormItemLabel>{t('CM_ID')}</FormItemLabel>
                <FormItemLoginId>
                  {waplUserStore.myProfile.loginId}
                </FormItemLoginId>
              </FormItemLabelWrapper>
              <FormItemLabelWrapper>
                <FormItemLabel>{t('CM_PWD')}</FormItemLabel>
                <StyledFormItem
                  noStyle
                  name="password"
                  rules={[
                    {
                      required: true,
                      message: '비밀번호를 입력해 주세요.',
                    },
                    {
                      validator: (_, value) => {
                        if (value && value.length >= 9) {
                          return Promise.resolve();
                        }
                        return Promise.reject('');
                      },
                    },
                  ]}
                >
                  <StyledInput type="password" />
                </StyledFormItem>
              </FormItemLabelWrapper>
            </FormContent>
          </ConfirmContent>
        </WithdrawalContent>
        <ButtonWrapper>
          <Button type="outlined" onClick={() => history.goBack()}>
            {t('CM_BACK')}
          </Button>
          <InlineFormItem shouldUpdate>
            {() => (
              <Button
                type="solid"
                htmlType="submit"
                onClick={handleSubmit}
                disabled={
                  !form.isFieldsTouched(true) ||
                  !!form.getFieldsError().filter(({ errors }) => errors.length)
                    .length
                }
              >
                {t('CM_LOGIN_POLICY_03')}
              </Button>
            )}
          </InlineFormItem>
        </ButtonWrapper>
      </Form>
    </Content>
  ));
}

export default WithdrawalHOC(WithdrawalTermsContent);

const Content = styled.div`
  padding-bottom: 1.68125rem;
  margin-top: 4.3125rem;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
`;

const WithdrawalTitle = styled.h1`
  font-size: 1.75rem;
  line-height: 2.5625rem;
  color: ${props => props.theme.TextMain};
`;

const WithdrawalSubtitle = styled.h2`
  font-weight: 400;
  font-size: 0.8125rem;
  color: ${props => props.theme.TextSub};
  line-height: 1.1875rem;
  margin-top: 0.375rem;
`;

const WithdrawalDivider = styled.hr`
  width: 41.875rem;
  border: none;
  height: 1px;
  background-color: ${props => props.theme.LineSub};
  color: ${props => props.theme.LineSub};
  margin: 1.25rem 0;
`;

const WithdrawalContent = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 41.875rem;
`;

const ConfirmContent = styled.div`
  height: 15.125rem;
  border: 1px solid ${props => props.theme.LineMain};
  border-radius: 0.9375rem;
  padding: 2rem 0;
  width: 35rem;
  margin: 5rem 0 5.5rem 0;
  text-align: center;
`;

const FormContent = styled.div`
  margin-top: 3rem;
  display: inline-flex;
  flex-direction: column;
  justify-content: flex-start;
`;

const FormItemLabelWrapper = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  &:not(:first-child) {
    margin-top: 0.625rem;
  }
`;

const FormItemLabel = styled.span`
  min-width: 3.4375rem;
  text-align: right;
  padding-right: 0.625rem;
  color: ${props => props.theme.TextMain};
`;

const ConfirmTitle = styled.h1`
  font-weight: 500;
  font-size: 0.75rem;
  line-height: 1.125rem;
  color: ${props => props.theme.TextMain};
`;

const ConfirmSubtitle = styled.h1`
  font-weight: 400;
  font-size: 0.75rem;
  line-height: 1.125rem;
  margin-top: 0.625rem;
  color: ${props => props.theme.TextSub};
`;

const ButtonWrapper = styled.div`
  text-align: center;
  button {
    width: 7.5rem;
    &:first-child {
      margin-left: 0.45rem;
    }
  }
`;

const StyledFormItem = styled(Form.Item)`
  align-items: center;
  margin: 0;
`;

const InlineFormItem = styled(Form.Item)`
  display: inline-flex;
  margin: 0;
`;

const StyledInput = styled(Input)`
  width: 13.4375rem;
`;

const FormItemLoginId = styled.span`
  margin-left: auto;
  width: 13.4375rem;
  text-align: left;
  font-size: 0.75rem;
  line-height: 1.125rem;
  color: ${props => props.theme.TextMain};
`;

import React, { useState, useEffect, useRef } from 'react';
import styled, { css } from 'styled-components';
import { useTranslation } from 'react-i18next';
import mainImg from '../../assets/landing/story/main.png';
import mainMedia from '../../assets/landing/story/story.mp4';
import subImg from '../../assets/landing/story/sub.png';
import main_En from '../../assets/landingEn/story/main_En.png';
import sub_En from '../../assets/landingEn/story/sub_En.png';
import mobileTitle from '../../assets/landing/mobile/story/main.png';
import mobileSub from '../../assets/landing/mobile/story/sub.png';
import mobileSub_En from '../../assets/landingEn/mobile/story/sub02.png';
import mobileMain_En from '../../assets/landingEn/mobile/story/main.png';

const isScrolledIntoView = el => {
  const rect = el.getBoundingClientRect();
  const elemTop = rect.top;
  const elemBottom = rect.bottom;

  const isVisible =
    elemTop - 100 >= 0 &&
    (elemBottom - elemTop) / 2 + elemTop <= window.innerHeight;
  return isVisible;
};

const ServiceStoryContent = props => {
  const { refs, isMobile, startStory } = props;
  const { i18n } = useTranslation(['common', 'service']);
  const mediaRef = useRef(null);

  const onHandlePlayClick = () => {
    mediaRef.current.play();
  };

  useEffect(() => {
    if (startStory) {
      window.scrollTo({
        top:
          refs.storyRef.current.offsetTop - refs.headerRef.current.clientHeight,
        left: 0,
        behavior: 'smooth',
      });
    }
  }, [startStory, refs.storyRef, refs.headerRef]);

  useEffect(() => {
    const onHandleScrollStart = e => {
      if (isScrolledIntoView(mediaRef.current)) {
        mediaRef.current.play();
      }
    };
    window.addEventListener('scroll', onHandleScrollStart);
    return () => {
      window.removeEventListener('scroll', onHandleScrollStart);
    };
  }, []);

  return (
    <StoryWrapper ref={refs.storyRef} ismobile={isMobile}>
      <WaplStoryMainImage
        src={
          i18n.language === 'en'
            ? isMobile
              ? mobileMain_En
              : main_En
            : isMobile
            ? mobileTitle
            : mainImg
        }
        onClick={onHandlePlayClick}
        data-aos-offset="40"
        data-aos="fade-up"
        data-aos-duration="750"
        data-aos-easing="ease"
        ismobile={isMobile}
      />
      <WaplStoryMainMedia
        ismobile={isMobile}
        ref={mediaRef}
        src={mainMedia}
        autoPlay
        muted
        playsInline
      />
      <WaplStorySubImage
        src={
          i18n.language === 'en'
            ? isMobile
              ? mobileSub_En
              : sub_En
            : isMobile
            ? mobileSub
            : subImg
        }
        data-aos-offset="40"
        data-aos="fade-up"
        data-aos-duration="750"
        data-aos-easing="ease"
        ismobile={isMobile}
      />
    </StoryWrapper>
  );
};

export default ServiceStoryContent;

const StoryWrapper = styled.div`
  width: 100%;
  background: #f2ede6;
  display: flex;
  flex-direction: column;
  align-items: center;
  ${props =>
    props.ismobile
      ? css`
          height: 37.125rem;
        `
      : css`
          height: 57.375rem;
        `}
`;

const WaplStoryMainImage = styled.img`
  ${props =>
    props.ismobile
      ? css`
          width: 18.75rem;
          height: 6.69rem;
          margin-top: 4.63rem;
          cursor: pointer;
        `
      : css`
          width: 21.438rem;
          height: 7.75rem;
          margin-top: 5rem;
          cursor: pointer;
        `};
`;

const WaplStoryMainMedia = styled.video`
  ${props =>
    props.ismobile
      ? css`
          width: 20.5rem;
          height: 11.4rem;
          margin-top: 3.5rem;
        `
      : css`
          width: 45rem;
          height: 25.313rem;
          margin-top: 3.75rem;
        `}
`;

const WaplStorySubImage = styled.img`
  ${props =>
    props.ismobile
      ? css`
          width: 19.5rem;
          height: 3.69rem;
          margin-top: 2.25rem;
        `
      : css`
          width: 26.938rem;
          height: 5.563rem;
          margin-top: 5rem;
        `};
`;

import React, { useState, useCallback, useEffect, useContext } from 'react';
import { useHistory, useLocation, Link } from 'react-router-dom';
import { Button } from 'antd';
import { useTranslation } from 'react-i18next';
import { useCoreStores, Message, WaplSearch, logEvent } from 'teespace-core';
import { useObserver } from 'mobx-react';
import styled, { ThemeContext } from 'styled-components';
import { useStores } from '../../stores';
import InformationImg from '../../assets/sign_unknown.svg';
import WorkspaceItem from './SpaceItem';
import FavFriendList from './FavFriendList';
import SpaceListTitle from './SpaceListTitle';
import TutorialDialog from './TutorialDialog';

const EmptyWorkspace = () => {
  const { t } = useTranslation('common');
  const history = useHistory();
  const handleCreateSpace = useCallback(() => {
    logEvent('main', 'clickCreateSpaceBtn');
    history.push('/select-space-type');
  }, [history]);

  return (
    <>
      <EmptyWorkspaceInfo>
        <SpaceHeader empty>
          <SpaceTitle>{t('CM_SPACE_LIST')}</SpaceTitle>
        </SpaceHeader>
        <EmptySpaceWrapper>
          <WorkspaceTitle>
            {t('CM_SPACE_EMPTY_CONTENTS_AREA_01')}
          </WorkspaceTitle>
          <WorkspaceButton
            type="solid"
            size="large"
            onClick={handleCreateSpace}
          >
            {t('CM_CREATE_CONTENTS_AREA_02')}
          </WorkspaceButton>
        </EmptySpaceWrapper>
        <WorkspaceHelper>
          <WorkspaceHelperTitle>
            <InfoIconCircle>
              <InfoIcon />
            </InfoIconCircle>
            <span>{t('CM_SPACE_EMPTY_CONTENTS_AREA_03')}</span>
          </WorkspaceHelperTitle>
          <WorkspaceHelerDivider />
          <WorkspaceHelperDescription>
            {t('CM_SPACE_EMPTY_CONTENTS_AREA_04')}
          </WorkspaceHelperDescription>
        </WorkspaceHelper>
      </EmptyWorkspaceInfo>
    </>
  );
};

const NonEmptyWorkspace = ({ spaces }) => {
  const { spaceStore } = useStores();
  const { waplUserStore } = useCoreStores();
  const [visibleMessage, setVisibleMessage] = useState(false);
  const { t } = useTranslation('common');
  const theme = useContext(ThemeContext);
  const history = useHistory();
  const handleMessageClose = useCallback(() => setVisibleMessage(false), []);
  const [spaceSearchKeyword, setSpaceSearchKeyword] = useState('');

  useEffect(() => {
    const query = new URLSearchParams(window.location.search);
    const limited = Boolean(query.get('limited'));
    if (limited) {
      setVisibleMessage(true);
      history.replace('/spaces');
    }
  }, [history]);

  const handleCreateSpace = useCallback(() => {
    if (
      spaceStore.getAdminSpaces({ loginId: waplUserStore.myProfile.loginId })
        .length >= 3
    ) {
      setVisibleMessage(true);
    } else {
      logEvent('main', 'clickCreateSpaceBtn');
      history.push('/select-space-type');
    }
  }, [history, spaceStore, waplUserStore.myProfile.loginId]);

  const handleSpaceSearchChange = useCallback(value => {
    setSpaceSearchKeyword(value);
  }, []);

  const handleSpaceSearchClear = useCallback(() => {
    setSpaceSearchKeyword('');
  }, []);

  return useObserver(() => (
    <SpaceListInfo>
      <Message
        visible={visibleMessage}
        title={t('CM_SPACE_CREATE_OPTION_ERROR_03')}
        btns={[
          {
            type: 'solid',
            text: t('CM_LOGIN_POLICY_03'),
            onClick: handleMessageClose,
          },
        ]}
      />
      <SpaceHeader empty={false}>
        <SpaceTitle>{t('CM_SPACE_LIST')}</SpaceTitle>
        <SpaceButtonArea>
          <NonEmptySpaceCreateButton type="solid" onClick={handleCreateSpace}>
            {t('CM_CREATE_CONTENTS_AREA_02')}
          </NonEmptySpaceCreateButton>
        </SpaceButtonArea>
      </SpaceHeader>
      <SpaceSearch
        isCountExist={false}
        onChange={handleSpaceSearchChange}
        onClear={handleSpaceSearchClear}
        placeholder={t('CM_SEARCH_SPACE')}
      />
      <SpaceListWrapper>
        <StyledSpaceList>
          {spaces
            .filter(workspace => {
              return (
                spaceSearchKeyword === '' ||
                workspace.name
                  .toLowerCase()
                  .includes(spaceSearchKeyword.toLowerCase())
              );
            })
            .map(workspace => (
              <WorkspaceItem workspace={workspace} key={workspace.id} />
            ))}
        </StyledSpaceList>
      </SpaceListWrapper>
    </SpaceListInfo>
  ));
};

function SpaceList() {
  const { spaceStore } = useStores();
  const [tutorialVisible, setTutorialVisible] = useState(false);
  const history = useHistory();
  const location = useLocation();
  const handleTutorialClose = useCallback(() => {
    setTutorialVisible(false);
  }, []);

  useEffect(() => {
    const query = new URLSearchParams(window.location.search);
    const tutorial = Boolean(location.state?.tutorial || query.get('tutorial'));
    if (tutorial) {
      setTutorialVisible(true);
      history.replace('/spaces');
    }
  }, [history, location, location.state]);

  const ContentComponent = !spaceStore.spaceList.length
    ? // eslint-disable-next-line no-use-before-define
      EmptyContent
    : // eslint-disable-next-line no-use-before-define
      NonEmptyContent;
  return useObserver(() => (
    <>
      <TutorialDialog
        visible={tutorialVisible}
        onCancel={handleTutorialClose}
      />
      <ContentComponent>
        <SpaceListTitle />
        <Row>
          {!spaceStore.spaceList.length && <EmptyWorkspace />}
          {!!spaceStore.spaceList.length && (
            <NonEmptyWorkspace spaces={spaceStore.spaceList} />
          )}
          <FavFriendList />
        </Row>
      </ContentComponent>
    </>
  ));
}

export default SpaceList;

const EmptyContent = styled.div`
  display: flex;
  align-items: center;
  justify-content: flex-start;
  flex-direction: column;
  flex-grow: 1;
  padding: 4.125rem 0 3rem 0;
`;

const NonEmptyContent = styled.div`
  display: flex;
  align-items: center;
  justify-content: flex-start;
  flex-direction: column;
  flex-grow: 1;
  padding: 4.125rem 0 6.125rem 0;
`;

const WorkspaceInfo = styled.div`
  display: flex;
  align-items: center;
  flex-direction: column;
  background: ${props => props.theme.StateNormal};
  border: 1px solid ${props => props.theme.LineMain};
  border-radius: 15px;
  border-radius: 15px;
  margin-top: 2.5rem;
  padding: 1.5625rem 1.875rem 0 1.875rem;
  width: 21.25rem;
`;

const EmptyWorkspaceInfo = styled(WorkspaceInfo)`
  height: 23.125rem;
`;

const SpaceListInfo = styled(WorkspaceInfo)`
  align-items: flex-start;
  min-height: 28.125rem;
`;

const WorkspaceTitle = styled.h1`
  text-align: center;
  font-size: 0.8125rem;
  line-height: 1.3125rem;
  font-weight: 400;
  color: ${props => props.theme.TextMain};
  white-space: pre-line;
  margin: 0;
`;

const WorkspaceButton = styled(Button)`
  font-weight: 500;
  font-size: 0.875rem;
  line-height: 1.25rem;
  width: 12.5rem;
  margin-top: 1.0625rem;
  height: 2.125rem !important;
  margin-left: 2.625rem;
  border-radius: 0.25rem !important;
`;

const WorkspaceHelper = styled.div`
  margin-top: 1.875em;
  width: 100%;
  padding: 0 0.5rem;
`;

const WorkspaceHelperTitle = styled.h1`
  margin: 0;
  display: flex;
  align-items: center;
  span {
    font-size: 0.875rem;
    line-height: 1.25rem;
    font-weight: 500;
    margin-left: 0.6875rem;
    color: ${props => props.theme.TextMain};
  }
`;
const WorkspaceHelperDescription = styled.h2`
  margin: 0;
  white-space: pre-line;
  line-height: 1.25rem;
  font-size: 0.8125rem;
  font-weight: 400;
  color: ${props => props.theme.TextSub};
  letter-spacing: -0.02rem;
`;

const WorkspaceHelerDivider = styled.hr`
  border-top: 1px solid #ddd9d4;
  border-bottom: none;
  margin: 0.625rem 0;
`;

const InfoIconCircle = styled.div`
  width: 1.25rem;
  height: 1.25rem;
  border-radius: 50%;
  display: inline-flex;
  justify-content: center;
  align-items: center;
`;
const InfoIcon = styled.i`
  display: inline-block;
  background-color: ${props => props.theme.TextSub};
  mask-image: url('${InformationImg}');
  mask-repeat: no-repeat;
  mask-size: contain;
  width: 1.25rem;
  height: 1.25rem;
`;

const SpaceHeader = styled.div`
  align-items: center;
  flex: 1;
  width: 100%;
  display: flex;
  flex-direction: row;
  flex-grow: 0;
  min-height: ${props => (props.empty ? '1.5rem' : '1.88rem')}; // for toGate
`;

const SpaceButtonArea = styled.div`
  display: inline-block;
`;
const SpaceTitle = styled.span`
  font-size: 0.8125rem;
  flex: 1;
  font-weight: 500;
  color: ${props => props.theme.TextMain};
`;

const SpaceListWrapper = styled.div`
  width: calc(100% + 3.75rem);
  margin-left: -1.875rem;
  margin-top: 0.625rem;
  height: 22.125rem;
  flex-grow: 1;
  overflow-y: overlay;
`;
const StyledSpaceList = styled.div`
  display: flex;
  flex: 1;
  flex-direction: column;
  flex-grow: 1;
  width: 100%;
`;

const NonEmptySpaceCreateButton = styled(Button)`
  font-size: 0.75rem;
  font-weight: 500;
  color: #ffffff;
`;

const Row = styled.div`
  display: flex;
  flex-direction: row;
  flex-grow: 1;
`;

const SpaceSearch = styled(WaplSearch)`
  color: ${props => props.theme.TextMain};
  width: 100%;
  margin-top: 0.625rem;
  padding: 0;
  border-bottom: 0.125rem solid ${props => props.theme.CoreDark};
`;

const EmptySpaceWrapper = styled.div`
  margin-top: 1.5rem;
  background: ${props => props.theme.SubStateNormal};
  border-radius: 0.75rem;
  padding: 1.25rem 0 1.375rem 0;
  width: 100%;
`;

import React, { useState, useEffect, lazy, Suspense } from 'react';
import { Switch, Route, Redirect, useLocation } from 'react-router-dom';
import './App.less';
import { create } from 'mobx-persist';
import { useCoreStores, GlobalCommonStyles } from 'teespace-core';
import { createGlobalStyle, css, ThemeProvider } from 'styled-components';
import { useTranslation } from 'react-i18next';
import AOS from 'aos';
import { Observer } from 'mobx-react';
import 'aos/dist/aos.css';
import { isMobile } from 'react-device-detect';
import { PrivateRoute, PrivateRouteProvider } from './libs/auth';
import LandingPage from './pages/LandingPage';
import SpaceListPage from './pages/SpaceListPage';
import SpaceJoinPage from './pages/SpaceJoinPage';
import InviteLinkPage from './pages/InviteLinkPage';
import SpaceTypeSelectPage from './pages/SpaceTypeSelectPage';
import SpaceHeader from './components/space/SpaceHeader';
import SpaceCreatePage from './pages/SpaceCreatePage';
import AccountPage from './pages/AccountPage';
import MainPage from './pages/MainPage';
import DomainRedirectPage from './pages/DomainRedirectPage';
import WithdrawalTypeSelectPage from './pages/WithdrawalTypeSelectPage';
import WithdrawalTermsPage from './pages/WithdrawalTermsPage';
import WithdrawalConfirmPage from './pages/WithdrawalConfirmPage';
import TermAndConditions from './components/service/TermAndConditions';
import PrivacyPolicy from './components/service/PrivacyPolicy';
import FirstLogin from './pages/FirstLoginPage';
import AuthFirstLogin from './pages/AuthFirstLoginPage';
import InviteCode from './pages/InviteCodePage';
import LogoutPage from './pages/LogoutPage';

import LaunchAppPage from './pages/LaunchAppPage';
import EventJoinPage from './pages/EventJoinPage';
import GoogleMailPage from './pages/GoogleMailPage';
import validPersistedStore from './libs/validatePersistedStore';

import { useStores } from './stores';
import Loading from './components/Loading';

const hydrate = create();

const handleScreenStyle = (isSmall, isMobile) => {
  if (isSmall) {
    return css`
html {
  font-size: ${global.screen.width / 16}%}
}`;
  }
  if (isMobile) {
    return css`
      @media (max-width: 860px) {
        html,
        body {
          font-size: 16px;
        }
      }
    `;
  }
  return css`
    html {
      /* font-size: 100%; */
      font-size: clamp(9.16px, 0.8333vw, 400%);
    }
  `;
};
const GlobalStyle = createGlobalStyle`
body {
    background-color: ${props => props.theme.StateNormal};
}
${({ small, isMobile }) => handleScreenStyle(small, isMobile)}
`;

const SupportPage = () => {
  const SupportPageComponent = lazy(async () => {
    const SupportModule = await import('teespace-support-app');
    return { default: SupportModule.SupportApp };
  });
  const { i18n } = useTranslation();
  const { themeStore } = useCoreStores();

  useEffect(() => {
    themeStore.setTheme('white');
  }, [themeStore]);

  return (
    <>
      <SpaceHeader support />
      <Suspense fallback={<Loading />}>
        <SupportPageComponent language={i18n.language} />
      </Suspense>
    </>
  );
};
function App() {
  const [isHydrating, setIsHydrating] = useState(false);
  const { waplUserStore, themeStore } = useCoreStores();
  const [small, setSmall] = useState(false);
  const location = useLocation();
  // const { keycloak } = useKeycloak();
  const { i18n } = useTranslation();
  const { spaceStore, friendStore } = useStores();

  // global style 변경
  useEffect(() => {
    if (
      location.pathname === '/' ||
      location.pathname === '/voucher' ||
      location.pathname === '/story' ||
      location.pathname === '/service' ||
      location.pathname === '/news' ||
      location.pathname === '/introduction' ||
      location.pathname === '/download' ||
      location.pathname === '/privacy-policy' ||
      location.pathname === '/term-and-conditions'
    ) {
      setSmall(false);
    } else {
      setSmall(true);
    }
  }, [location.pathname]);

  useEffect(() => {
    validPersistedStore(['waplUser', 'waplSpace', 'waplFriend']);
    Promise.all([
      hydrate('waplUser', waplUserStore),
      hydrate('waplSpace', spaceStore),
      hydrate('waplFriend', friendStore),
    ])
      .then(() => {
        waplUserStore.initHydratedMyProfile({});
        spaceStore.initHydratedSpaceList({});
        friendStore.initHydratedFriendList({});
      })

      .then(() => {
        setIsHydrating(true);
        AOS.init();
      })
      .catch(e => {
        console.error(e);
      });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  if (!isHydrating) return null;

  return (
    <>
      <Observer>
        {() => (
          <ThemeProvider theme={themeStore.theme}>
            <GlobalStyle small={small} isMobile={isMobile} />
            <GlobalCommonStyles />
            <Switch>
              {i18n.language === 'en' && (
                <Route exact path="/:focus(voucher|news)">
                  <Redirect to="/" />
                </Route>
              )}
              <Route
                exact
                path={[
                  '/',
                  '/:focus(voucher|story|service|news|introduction|download)',
                ]}
                component={LandingPage}
              />
              <Route exact path="/privacy-policy" component={PrivacyPolicy} />
              <Route exact path="/InquiryComplete" component={null} />
              <Route exact path="/launchapp" component={LaunchAppPage} />
              <Route
                exact
                path="/term-and-conditions"
                component={TermAndConditions}
              />

              <PrivateRouteProvider>
                <Switch>
                  <Route
                    path="/invite-link/:inviteId"
                    component={InviteLinkPage}
                  />
                  <Route
                    path="/join-space/:inviteId"
                    component={SpaceJoinPage}
                  />
                  <Route path="/support/qna/write" component={SupportPage} />
                  <PrivateRoute path="/support/qna" component={SupportPage} />
                  <PrivateRoute path="/support" component={SupportPage} />
                  <Route
                    exact
                    path="/event-register"
                    component={EventJoinPage}
                  />
                  <PrivateRoute
                    path={[
                      '/domain/:domain',
                      '/spaces',
                      '/select-space-type',
                      '/create-space',
                      '/account',
                      '/withdrawal/(select-type|terms|confirm)',
                      '/first-login',
                      '/auth-first-login',
                      '/invite-code',
                      '/google-mail/:subDomain/:accountId?',
                      '/logout',
                    ]}
                    component={MainPage}
                  >
                    <Switch>
                      <PrivateRoute
                        path="/domain/:domain"
                        component={DomainRedirectPage}
                      />
                      <PrivateRoute path="/spaces" component={SpaceListPage} />
                      <PrivateRoute
                        path="/select-space-type"
                        component={SpaceTypeSelectPage}
                      />
                      <PrivateRoute
                        path="/create-space"
                        component={SpaceCreatePage}
                      />
                      {/* <PrivateRoute path="/account" component={AccountPage} /> */}
                      <PrivateRoute
                        path="/withdrawal/select-type"
                        component={WithdrawalTypeSelectPage}
                      />
                      <PrivateRoute
                        path="/withdrawal/terms"
                        component={WithdrawalTermsPage}
                      />
                      <PrivateRoute
                        path="/withdrawal/confirm"
                        component={WithdrawalConfirmPage}
                      />
                      <PrivateRoute
                        path="/first-login"
                        component={FirstLogin}
                      />
                      <PrivateRoute
                        path="/auth-first-login"
                        component={AuthFirstLogin}
                      />
                      <PrivateRoute
                        path="/invite-code"
                        component={InviteCode}
                      />
                      <PrivateRoute
                        path="/google-mail/:subDomain/:accountId?"
                        component={GoogleMailPage}
                      />
                      <PrivateRoute path="/logout" component={LogoutPage} />
                    </Switch>
                  </PrivateRoute>
                  <Redirect to="/" />
                </Switch>
              </PrivateRouteProvider>
            </Switch>
          </ThemeProvider>
        )}
      </Observer>
    </>
  );
}

export default App;

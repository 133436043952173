import React, { useCallback } from 'react';
import { useTranslation } from 'react-i18next';
import styled from 'styled-components';
import { Button } from 'antd';
import WorkspaceInvitationItem from './InvitationItem';
import { getDomain } from '../../libs/domain';
import NoUrlImg from '../../assets/no url.svg';

function AdminAccessDenyContent({ spaceInfo }) {
  const { t } = useTranslation('common');
  const handleSpaceMove = useCallback(() => {
    window.location.href = getDomain(spaceInfo.domain);
  }, [spaceInfo.domain]);
  return (
    <>
      <Content>
        <StyledWorkspaceInvitationItem
          title={t('ADMIN_SUBS_MANAGECORP_CORPINFO_SETTING_39')}
        >
          <NoUrlIcon />
          <Button type="solid" onClick={handleSpaceMove}>
            {t('ADMIN_SUBS_MANAGECORP_CORPINFO_SETTING_40', {
              value: spaceInfo.name,
            })}
          </Button>
        </StyledWorkspaceInvitationItem>
      </Content>
    </>
  );
}

export default AdminAccessDenyContent;

const StyledWorkspaceInvitationItem = styled(WorkspaceInvitationItem)`
  padding: 1.875rem 0;
  .title {
    font-size: 1.125rem;
    color: ${props => props.theme.TextMain};
    font-weight: 500;
  }
  .subtitle {
    display: none;
  }
  .divider {
    display: none;
  }
`;
const Content = styled.div`
  display: flex;
  flex-direction: column;
  margin: 2.8125rem 0;
  justify-content: center;
  align-items: center;
`;

const NoUrlIcon = styled.div`
  margin-top: 1.25rem;
  margin-bottom: 1.875rem;
  width: 8.125rem;
  height: 8.125rem;
  background: url('${NoUrlImg}') 0 0 no-repeat;
  background-size: contain;
`;

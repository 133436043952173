import React, { useState, useCallback } from 'react';
import styled from 'styled-components';
import { Message, useCoreStores, logEvent, Form, Input } from 'teespace-core';
import { Button, Checkbox } from 'antd';
import { DownOutlined, UpOutlined } from '@ant-design/icons';
import { useHistory } from 'react-router-dom';
import { useTranslation } from 'react-i18next';

const InviteCodeContent = () => {
  const { waplAuthStore, waplEventListStore } = useCoreStores();
  const history = useHistory();
  const { t } = useTranslation(['common', 'service']);
  const CheckboxGroup = Checkbox.Group;
  const plainOptions = [
    'agreeInviteCode1',
    'agreeInviteCode2',
    'agreeInviteCode3',
  ];

  // Checkbox
  const [checkedList, setCheckedList] = useState('');
  const [checkAll, setCheckAll] = useState(
    checkedList.length === plainOptions.length,
  );
  // Accordian Tab
  const [clickService, setClickServcie] = useState(false);
  const [clickPrivacy, setClickPrivacy] = useState(false);
  const [clickChoice, setClickChoice] = useState(false);
  const [changed, setChanged] = useState(false);
  // dialog
  const [messageVisible, setMessageVisible] = useState(false);
  const [error, setError] = useState(false);

  const onHandleAccordionServiceClick = useCallback(() => {
    setClickServcie(!clickService);
  }, [clickService]);

  const onHandleAccordionPrivacyClick = useCallback(() => {
    setClickPrivacy(!clickPrivacy);
  }, [clickPrivacy]);

  const onHandleAccordionChoiceClick = useCallback(() => {
    setClickChoice(!clickChoice);
  }, [clickChoice]);

  const onCheckAllChange = e => {
    setCheckedList(e.target.checked ? plainOptions : []);
    setCheckAll(e.target.checked);
  };
  const onChange = checkedList => {
    setCheckedList(checkedList);
    checkedList.length === plainOptions.length
      ? setCheckAll(true)
      : setCheckAll(false);
  };

  const handleInputChange = useCallback(values => {
    values.inviteCode ? setChanged(true) : setChanged(false);
  }, []);

  const handleEnterDraw = values => {
    const query = new URLSearchParams(window.location.search);
    const redirectUri = query.get('redirect_uri');
    const recommenderCode = values.inviteCode;
    const { eventId } = waplEventListStore.eventInfo;
    waplAuthStore
      .updateRecommenderCode({
        recommenderCode,
        eventId,
      })
      .then(result => {
        if (result) {
          setError(false);
          if (redirectUri) {
            history.replace(decodeURIComponent(redirectUri), {
              tutorial: true,
            });
          } else {
            history.replace('/spaces', { tutorial: true });
          }
        } else {
          setError(true);
          return false;
        }
      });
  };
  const onHandleCancelClick = () => {
    setMessageVisible(true);
  };

  const handleMessageClose = useCallback(() => {
    setMessageVisible(false);
  }, []);

  const handleEnterSpace = useCallback(() => {
    history.replace('/spaces', { tutorial: true });
  }, [history]);
  return (
    <>
      <Message
        visible={messageVisible}
        title={`${t('common:CM_SERVICE_AREE_EVENT_11')}`}
        subtitle={`${t('common:CM_SERVICE_AREE_EVENT_04')}`}
        btns={[
          {
            type: 'solid',
            shape: 'round',
            text: `${t('common:CM_SERVICE_AREE_EVENT_12')}`,
            onClick: handleEnterSpace,
          },
          {
            type: 'outlined',
            shape: 'round',
            text: `${t('common:CM_SERVICE_AREE_EVENT_13')}`,
            onClick: handleMessageClose,
          },
        ]}
      />
      <Content>
        <ContentHeader>{t(`common:CM_SERVICE_AREE_EVENT_01`)}</ContentHeader>
        <Text>{t(`common:CM_SERVICE_AREE_EVENT_02`)}</Text>
        <Subtext>{t(`common:CM_SERVICE_AREE_EVENT_05`)}</Subtext>
        <StyledForm
          onFinish={handleEnterDraw}
          onValuesChange={handleInputChange}
        >
          <StyledFormItem
            name="inviteCode"
            noStyle
            validateTrigger={['onBlur']}
            rules={[
              {
                validateTrigger: 'onBlur',
                validator: (_, value) => {
                  if (!value) {
                    // eslint-disable-next-line prefer-promise-reject-errors
                    return Promise.reject(
                      `${t('common:CM_SETTING_INVITE_CODE_09')}`,
                    );
                  }
                  return Promise.resolve();
                },
              },
            ]}
          >
            <StyledInput
              placement="topRight"
              placeholder={t(`common:CM_SERVICE_AREE_EVENT_07`)}
              alert={error ? `${t('common:CM_SETTING_INVITE_CODE_09')}` : ''}
            />
          </StyledFormItem>
          <CheckboxWrapper>
            <CheckAll>
              <Checkbox checked={checkAll} onChange={onCheckAllChange}>
                {t(`common:CM_SERVICE_AREE_EVENT_03`)}
              </Checkbox>
              <TextRed>{t(`common:CM_SERVICE_AREE_CHECKBOX_03`)}</TextRed>
            </CheckAll>

            <CheckboxGroup value={checkedList} onChange={onChange}>
              <Accordion>
                <Checkbox value="agreeInviteCode1">
                  {t(`common:CM_SERVICE_AREE_EVENT_08`)}{' '}
                  <TextRed>{t(`common:CM_SERVICE_AREE_CHECKBOX_03`)}</TextRed>
                </Checkbox>
                <AccordianSpan>
                  {clickService ? (
                    <UpOutlined onClick={onHandleAccordionServiceClick} />
                  ) : (
                    <DownOutlined onClick={onHandleAccordionServiceClick} />
                  )}
                </AccordianSpan>
              </Accordion>
              {clickService && (
                <AuthDiv>
                  <TermContent>
                    <PreTag
                      dangerouslySetInnerHTML={{
                        __html: t('common:CM_SERVICE_AGREE_EVENT_11'),
                      }}
                    />
                  </TermContent>
                </AuthDiv>
              )}
              <Accordion>
                <Checkbox value="agreeInviteCode2">
                  {t(`common:CM_SERVICE_AREE_EVENT_09`)}{' '}
                  <TextRed>{t(`common:CM_SERVICE_AREE_CHECKBOX_03`)}</TextRed>
                </Checkbox>
                <AccordianSpan>
                  {clickPrivacy ? (
                    <UpOutlined onClick={onHandleAccordionPrivacyClick} />
                  ) : (
                    <DownOutlined onClick={onHandleAccordionPrivacyClick} />
                  )}
                </AccordianSpan>
              </Accordion>
              {clickPrivacy && (
                <AuthDiv>
                  <TermContent>
                    <PreTag
                      dangerouslySetInnerHTML={{
                        __html: t('common:CM_SERVICE_AGREE_EVENT_12'),
                      }}
                    />
                  </TermContent>
                </AuthDiv>
              )}
              <Accordion>
                <Checkbox value="agreeInviteCode3">
                  {t(`common:CM_SERVICE_AREE_EVENT_10`)}{' '}
                  <TextRed>{t(`common:CM_SERVICE_AREE_CHECKBOX_03`)}</TextRed>
                </Checkbox>
                <AccordianSpan>
                  {clickChoice ? (
                    <UpOutlined onClick={onHandleAccordionChoiceClick} />
                  ) : (
                    <DownOutlined onClick={onHandleAccordionChoiceClick} />
                  )}
                </AccordianSpan>
              </Accordion>
              {clickChoice && (
                <AuthDiv>
                  <TermContent>
                    <PreTag
                      dangerouslySetInnerHTML={{
                        __html: t('common:CM_SERVICE_AGREE_EVENT_13'),
                      }}
                    />
                  </TermContent>
                </AuthDiv>
              )}
            </CheckboxGroup>
          </CheckboxWrapper>
          <ButtonWrapper>
            <Buttons
              type="solid"
              disabled={
                !(
                  checkedList.includes('agreeInviteCode1') &&
                  checkedList.includes('agreeInviteCode2') &&
                  checkedList.includes('agreeInviteCode3') &&
                  changed
                )
              }
              htmlType="submit"
            >
              {t(`common:CM_SERVICE_AREE_EVENT_06`)}
            </Buttons>
            <Buttons type="outlined" onClick={onHandleCancelClick}>
              {t(`common:CM_CANCEL`)}
            </Buttons>
          </ButtonWrapper>
        </StyledForm>
      </Content>
    </>
  );
};

export default InviteCodeContent;

const Content = styled.div`
  display: flex;
  align-items: center;
  flex-direction: column;
  background: ${props => props.theme.StateNormal};
  border: 1px solid #e1e1e2;
  border-radius: 15px;
  border-radius: 15px;
  padding: 2.5rem 3.81rem 3.13rem 3.75rem;
  width: 28.13rem;
`;

const ContentHeader = styled.div`
  font-size: 1.5rem;
  color: ${props => props.theme.TextMain};
  font-weight: bold;
  margin-bottom: 2.5rem;
`;

const Text = styled.div`
  margin-bottom: 0.125rem;
  font-size: 0.81rem;
  color: ${props => props.theme.TextSub};
`;
const Subtext = styled.div`
  margin-bottom: 1.25rem;
  font-size: 0.67rem;
  color: ${props => props.theme.TextSub};
`;

const TextRed = styled.span`
  color: #ff4646;
  font-weight: 500;
  font-size: 0.81rem;
`;

const CheckAll = styled.div`
  border-bottom: 1px solid #d3d3d3;
  padding-bottom: 1.25rem;
  margin-top: 1.25rem;
  font-weight: bold;
  & .ant-checkbox-wrapper {
    font-size: 0.875rem;
  }
`;

const CheckboxWrapper = styled.div`
  display: flex;
  width: 100%;
  flex-direction: column;
  color: #3e3e3e;
  font-weight: 500;
  & .ant-checkbox-wrapper {
    font-size: 0.875rem;
  }
`;

const Accordion = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  margin-top: 1.56rem;
  justify-content: space-between;
  width: 100%;
`;

const AccordianSpan = styled.span`
  cursor: pointer;
  color: #696969;
`;

const ButtonWrapper = styled.div`
  display: flex;
  flex-direction: row;
  width: 100%;
  justify-content: space-between;
  margin-top: 1.88rem;
`;

const Buttons = styled(Button)`
  width: 10rem;
  height: 1.88rem;
`;

const AuthDiv = styled.div`
  margin-top: 10px;
  max-width: 380px;
  height: 200px;
  width: 100%;
  border: 1px solid #d3d3d3;
  background: ${props => props.theme.StateNormal};
  padding: 7px 0px 7px 7px;
  resize: none;
  overflow-y: scroll;
  font-size: 0.75rem;
  line-height: 1.25rem;
`;

const PreTag = styled.pre`
  white-space: pre-line;
  margin: 0px;
  padding: 0.625rem;
  line-height: 1.25rem;
  font-size: 0.6rem;
  letter-spacing: 0px;
  font-weight: 500;
`;

const TermContent = styled.div`
  text-align: left;
  font-weight: 300;
  color: ${props => props.theme.TextMain};
  letter-spacing: 0;
  font-size: 12px;
`;

const StyledForm = styled(Form)`
  display: flex;
  flex-direction: column;

  button {
    min-width: 3.125rem !important;
    padding-left: 0.84375rem !important;
    padding-right: 0.84375rem !important;
    margin-left: 0.5rem;
    &[type='submit'] {
      margin-left: 0.94rem;
    }
  }
`;

const StyledFormItem = styled(Form.Item)`
  margin-bottom: 0;
`;
const StyledInput = styled(Input)`
  width: 100%;
`;

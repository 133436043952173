import React from 'react';
import styled, { css } from 'styled-components';
import { Modal as Dialog } from 'antd';
import { useTranslation } from 'react-i18next';

function TermsDialog({
  visible,
  onCancel,
  isMobile,
  getContainer = () => document.body,
}) {
  const { t, i18n } = useTranslation('service');
  return (
    <TermsDialogWrapper
      getContainer={getContainer}
      visible={visible}
      onCancel={onCancel}
      closable
      mask
      maskClosable
      centered
      transitionName=""
      maskTransitionName=""
      width="auto"
      title={t('SP_INTRO_INFO_31')}
      footer={null}
    >
      <>
        <TermsContent ismobile={isMobile}>
          <MainSpan>{t(`SP_INTRO_INFO_32`)}</MainSpan>
          <SubSpan>{t(`SP_INTRO_INFO_33`)}</SubSpan>
          <MainSpan>{t(`SP_INTRO_INFO_34`)}</MainSpan>
          <SubSpan>{t(`SP_INTRO_INFO_35`)}</SubSpan>
          <MainSpan>{t(`SP_INTRO_INFO_36`)}</MainSpan>
          <SubSpan>{t(`SP_INTRO_INFO_37`)}</SubSpan>
        </TermsContent>
      </>
    </TermsDialogWrapper>
  );
}

export default TermsDialog;

const TermsDialogWrapper = styled(Dialog)`
  margin: 0;
  padding: 0;
  min-width: 0 !important;

  div.ant-modal-content {
    box-shadow: 0 5px 10px 0 rgba(0, 0, 0, 0.3);
    border-radius: 4px;

    .ant-modal-title {
      font-size: 0.875rem;
      color: #000000;
      letter-spacing: 0;
      text-align: center;
      font-weight: 500;
    }

    .ant-modal-body {
      display: flex;
      padding: 0px 0px 0px 0px;
      flex-direction: column;
      justify-content: center;
      align-items: center;
    }
  }
`;

const TermsContent = styled.div`
  ${props =>
    props.ismobile
      ? css`
          width: 95vw;
          height: auto;
          padding: 0 1rem 1rem 1rem;
        `
      : css`
          width: 41.25rem;
          height: 15.25rem;
          padding: 0.063rem 1rem 2.625rem 1rem;
        `}
`;

const MainSpan = styled.div`
  font-size: 0.875rem;
  color: #000000;
  letter-spacing: 0;
  font-weight: 500;
  margin-top: 1.25rem;
`;

const SubSpan = styled.div`
  font-size: 0.875rem;
  color: #000000;
  letter-spacing: 0;
  font-weight: 300;
`;

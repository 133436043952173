import React from 'react';
import styled, { css } from 'styled-components';

const Table = React.memo(({ titles = [], rows = [], descriptions = [] }) => {
  return (
    <>
      <TableAgree>
        <thead>
          <tr>
            {titles.map(title => (
              <TableTh key={title}>{title}</TableTh>
            ))}
          </tr>
        </thead>
        <tbody>
          {rows.map((row, idx) => (
            <tr key={row + idx}>
              {row.map((content, idx) => (
                <TableTd
                  key={content.text + idx}
                  rowSpan={content.rowSpan || 1}
                  columnSpan={content.columnSpan || 1}
                  highlight={content.highlight || false}
                >
                  {content.text}
                </TableTd>
              ))}
            </tr>
          ))}
        </tbody>
      </TableAgree>
      {descriptions.map(description => (
        <div key={description}>
          {description}
          <br />
        </div>
      ))}
    </>
  );
});

export default Table;

const TableAgree = styled.table`
  width: 100%;
  table-layout: auto;
  border-collapse: collapse;
  border-spacing: 0;
  border: 1px solid #ccc;
  background-color: transparent;
  max-height: inherit;
`;

const TableTh = styled.th`
  height: auto;
  font-size: 0.875rem;
  text-align: center;
  border: solid #ccc;
  border-width: 1px 0 0 1px;
  background-color: #fafafa;
`;

const TableTd = styled.td`
  padding: 0.5rem 1rem 0.5rem 1rem;
  border: solid #ccc;
  border-width: 1px 0 0 1px;
  font-size: 0.75rem;
  vertical-align: top;
  text-align: left;

  ${props =>
    props.highlight &&
    css`
      font-size: 1rem;
      font-weight: 500;
      color: red;
    `}
`;

// @flow
/* eslint-disable no-underscore-dangle */
import { observable, values } from 'mobx';
import { persist } from 'mobx-persist';
import FriendModel from './models/FriendModel';
import FriendRepo from './repositories/FriendRepository';

const spaceNameComparator = (a, b) => {
  if (a.spaceName > b.spaceName) {
    return 1;
  }
  if (b.spaceName > a.spaceName) {
    return -1;
  }
  return 0;
};

const friendNameComparator = (a, b) => {
  if (a.name > b.name) {
    return 1;
  }
  if (b.name > a.name) {
    return -1;
  }
  return spaceNameComparator(a, b);
};

const sortFriends = friendObj => {
  return values(friendObj).sort(friendNameComparator);
};

const store = observable({
  __friends: observable.map({}),
  get friendList() {
    return sortFriends(this.__friends);
  },
  initHydratedFriendList() {
    const hydration = this.friendList.map(friend => {
      return new FriendModel(friend);
    });
    this.setFriends(hydration);
  },
  async fetchFriends({
    userId,
  }: {
    userId: string,
  }): Promise<Array<FriendModel>> {
    try {
      const friends = await FriendRepo.listFriends({ userId });
      this.setFriends(friends);
      return this.friendList;
    } catch (err) {
      throw Error(err.message);
    }
  },
  setFriends(friendList: Array<FriendModel>): void {
    const obj = {};
    friendList.forEach(friend => {
      obj[friend.id] = new FriendModel(friend);
    });
    if (Object.keys(obj).length === 0) {
      this.__friends = observable.map({});
    } else {
      this.__friends.replace(obj);
    }
  },
});

const schema = {
  __friends: {
    type: 'map',
    schema: {
      id: true,
      loginId: true,
      name: true,
      domain: true,
      modiDate: true,
      domainKey: true,
      thumbPhoto: true,
      status: true,
    },
  },
};

const FriendStore = persist(schema)(store);
export default FriendStore;

const patternURL1 = /^[-0-9a-z]{0,200}$/;
const patternURL2 = /^([^a-z]*)[a-z]([^a-z]*)[a-z]([^a-z]*)[a-z]([^a-z]*).*/;
const patternName = /^[^[{}[\]/?.,$;:|)*~`!^\-_+<>@#$%&\\=('"]{1,50}$/;
const patternEmail = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
const patternPassword = /^(?:(?=.*[a-z])(?=.*[A-Z])(?=.*\d)|(?=.*[a-z])(?=.*[A-Z])(?=.*[!@#$%^&*()<>?])|(?=.*[a-z])(?=.*\d)(?=.*[!@#$%^&*()<>?])|(?=.*[A-Z])(?=.*\d)(?=.*[!@#$%^&*()<>?]))[A-Za-z\d!@#$%^&*()<>?]{9,20}$/;
const patternPhone = /^[0-9]*$/;

export const checkNameValid = value => {
  return patternName.test(value);
};
export const checkPhoneValid = value => {
  return patternPhone.test(value);
};

export const checkURLValid = value => {
  return patternURL1.test(value);
};

export const checkEngLength = (value, length) => {
  return value && patternURL2.test(value);
};
// email validation
export const checkEmailValid = value => {
  return typeof value === 'string' && patternEmail.test(value);
};

export const checkPasswordValid = pwdValue => {
  return patternPassword.test(pwdValue);
};

export default {
  checkURLValid,
  checkEngLength,
  checkEmailValid,
  checkPhoneValid,
  checkPasswordValid,
};

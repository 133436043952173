import React from 'react';
import styled from 'styled-components';

const Header = styled.div`
  height: 3.88rem;
`;

const AuthFirstLoginHeader = () => {
  return <Header />;
};

export default AuthFirstLoginHeader;

import React from 'react';
import styled from 'styled-components';

function InvitationItem({ title, subtitle, children, className }) {
  return (
    <WorkspaceInfo className={className}>
      <InvitationTitle className="title">{title}</InvitationTitle>
      {subtitle && (
        <InvitationSubtitle className="subtitle">{subtitle}</InvitationSubtitle>
      )}
      <StyledDivider className="divider" />
      {children}
    </WorkspaceInfo>
  );
}

export default InvitationItem;

const WorkspaceInfo = styled.div`
  display: flex;
  align-items: center;
  flex-direction: column;
  background: ${props => props.theme.StateNormal};
  border: 1px solid ${props => props.theme.LineMain};
  border-radius: 0.9375rem;
  padding: 2rem 5rem 2.875rem 5rem;
  width: 35rem;
`;

const InvitationTitle = styled.h1`
  margin: 0;
  font-size: 1.5rem;
  line-height: 2.25rem;
  width: 100%;
  text-align: center;
  word-break: break-word;
  font-weight: 400;
  white-space: pre-line;
  color: ${props => props.theme.TextMain};
`;

const InvitationSubtitle = styled.h2`
  margin: 0 0 0.625rem 0;
  font-size: 0.8125rem;
  line-height: 1.1875rem;
  font-weight: 400;
  color: ${props => props.theme.TextSub};
  width: 100%;
  word-break: break-word;
  text-align: center;
`;

const StyledDivider = styled.hr`
  width: 100%;
  border: 1px solid ${props => props.theme.LineSub};
  margin: 0.625rem 0 2.25rem 0;
`;

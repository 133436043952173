import React, { useState } from 'react';
import styled, { css } from 'styled-components';
import { useTranslation } from 'react-i18next';
import { Button, Modal, Menu, Dropdown } from 'antd';
import { useCoreStores } from 'teespace-core';
import mobileLogo from '../../assets/landing/header/MO_MOCKUP.png';
import CloseImg from '../../assets/close.svg';
import exportImg from '../../assets/landing/header/export.svg';
import arrowBottom from '../../assets/arrow_bottom_1.svg';
import arrowTop from '../../assets/arrow_top_1.svg';

const DialogWrapper = styled(Modal)`
  div.ant-modal-content {
    position: fixed;

    overflow: hidden;

    top: 0px;

    left: 0px;

    padding: 0px 0px 0px 0px;
    border-radius: 0;

    .ant-modal-body {
      display: flex;

      flex-direction: row;

      justify-content: center;

      align-items: center;

      border-radius: 0.625rem;

      background-color: #0b1d41;

      padding: 1.25rem 1.25rem 1rem 1.25rem;
    }

    .ant-modal-footer {
      border-top: none;

      border-radius: 0 0 0.625rem 0.625rem;

      background-color: #ffffff;
    }
  }
`;

const HeaderWrapper = styled.div`
  height: 2.88rem;
  padding: 0.63rem 0;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
`;

const ServiceHeaderMobileWrapper = styled.div`
  display: flex;
  flex-direction: row;
`;

const DropdownWrapper = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
  .ant-dropdown {
    z-index: 100000;
  }
`;

const LanguageSelect = styled.span`
  color: #6c635b;
  font-size: 0.81rem;
  line-height: 0.78rem;
  cursor: pointer;
  font-weight: 500;
  display: inline-flex;
  align-items: center;
  margin-left: auto;
  right: 0;

  &.ant-dropdown-trigger span.anticon {
    font-size: 0.55rem;
    margin-top: 0.125rem;
    margin-left: 0.40625rem;
  }
`;
const DownIcon = styled.i`
  display: inline-block;
  mask-image: url('${arrowBottom}');
  mask-repeat: no-repeat;
  mask-size 100%;
  background-color:#959089;
  width: 0.875rem;
  height: 0.875rem;
  margin-left: 0.25rem;
`;

const UpIcon = styled.i`
  display: inline-block;
  mask-image: url('${arrowTop}');
  mask-repeat: no-repeat;
  mask-size 100%;
  background-color:#959089;
  width: 0.875rem;
  height: 0.875rem;
  margin-left: 0.25rem;
`;

const CloseIcon = styled.i`
  width: 1.25rem;
  height: 1.25rem;
  display: inline-block;
  background-color: #666666;
  mask-image: url('${CloseImg}');
  mask-size: auto;
  mask-repeat: no-repeat;
  margin: 0 1rem 0 1.25rem;
`;

const StartButton = styled(Button)`
  width: 9.8rem;
  height: 2.5rem;
  font-size: 1.25rem;
  line-height: 1.81rem;
  color: #ffffff;
`;

const HamburgerMenu = styled.div`
  margin-top: 2.25rem;
  font-size: 1.25rem;
  line-height: 1.81rem;
  color: #232d3b;
  font-weight: 500;
  &:active,
  &:focus {
    font-weight: bold;
    color: #ec6222;
  }
`;

const SupportDiv = styled.div`
  display: flex;
  flex-direction: column;
`;

const ExportImg = styled.img`
  width: 1.13rem;
  height: 1.13rem;
  position: relative;
  left: 5.4rem;
  top: -1.4rem;
  ${props =>
    props.isen === 'en'
      ? css`
          display: none;
        `
      : css``}
`;

const ServiceHamburger = props => {
  const { visible, onCancel, refs } = props;
  const { t, i18n } = useTranslation('service');
  const { waplAuthStore, waplUserStore } = useCoreStores();
  const isLocal = process.env.REACT_APP_ENV === 'local';
  const [dropdownVisible, setDropdownVisible] = useState(false);

  const handleDropdownVisibleChange = visible => {
    setDropdownVisible(visible);
  };
  const handleChangeLanguage = async language => {
    if (waplAuthStore.isAuthenticated) {
      await waplUserStore.updateLanguage({ language });
    }
    i18n.changeLanguage(language);
  };

  const menu = (
    <Menu onClick={() => setDropdownVisible(false)}>
      <Menu.Item key="1" onClick={() => handleChangeLanguage('ko')}>
        {t('common:CM_KOREAN')}
      </Menu.Item>
      <Menu.Item key="2" onClick={() => handleChangeLanguage('en')}>
        {t('common:CM_ENGLISH')}
      </Menu.Item>
    </Menu>
  );

  const onHandleClickStory = () => {
    onCancel();
    console.log(`hamburRef`, refs.storyRef.current.offsetTop);
    console.log(`Ref`, refs.headerRef.current.clientHeight);
    window.scrollTo({
      top:
        refs.storyRef.current.offsetTop - refs.headerRef.current.clientHeight,
      left: 0,
      behavior: 'smooth',
    });
  };
  const onHandleClickIntro = () => {
    onCancel();
    window.scrollTo({
      top:
        refs.introRef.current.offsetTop - refs.headerRef.current.clientHeight,
      left: 0,
      behavior: 'smooth',
    });
  };
  const onHandleClickNews = () => {
    onCancel();
    window.scrollTo({
      top: refs.newsRef.current.offsetTop - refs.headerRef.current.clientHeight,
      left: 0,
      behavior: 'smooth',
    });
  };

  const onHandleClickInquiry = () => {
    onCancel();
    window.scrollTo({
      top:
        refs.inquiryRef.current.offsetTop - refs.headerRef.current.clientHeight,
      left: 0,
      behavior: 'smooth',
    });
  };
  const onHandleClickDownload = () => {
    onCancel();
    window.scrollTo({
      top:
        refs.downloadRef.current.offsetTop -
        refs.headerRef.current.clientHeight,
      left: 0,
      behavior: 'smooth',
    });
  };

  const onHandleClickSupport = () => {
    let targetQnA = '';
    if (isLocal) {
      targetQnA = `${window.location.protocol}//${window.location.host}/support`;
    } else {
      targetQnA = `${window.location.protocol}//${window.location.hostname}/support`;
    }
    window.open(targetQnA);
  };

  // const onHandleClickVoucher = () => {
  //   onCancel();
  //   window.scrollTo({
  //     top:
  //       refs.voucherRef.current.offsetTop - refs.headerRef.current.clientHeight,
  //     left: 0,
  //     behavior: 'smooth',
  //   });
  // };

  const goMobileStart = () => {
    window.location.href = `https://waplai.page.link/main`;
  };

  return (
    <DialogWrapper
      visible={visible}
      onCancel={onCancel}
      transitionName=""
      maskTransitionName=""
      size="large"
      closable={false}
      footer={null}
      bodyStyle={{
        height: '100vh',
        width: '100vw',
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'flex-start',
        alignItems: 'flex-start',
        backgroundColor: '#ffffff',
        padding: 0,
      }}
    >
      <HeaderWrapper>
        <img
          id="mobileTeelogo"
          alt="logo"
          src={mobileLogo}
          style={{ width: '6.1rem', height: '1.5rem', marginLeft: '0.5rem' }}
        />
        <ServiceHeaderMobileWrapper>
          <DropdownWrapper>
            <Dropdown
              overlay={menu}
              visible={dropdownVisible}
              onVisibleChange={handleDropdownVisibleChange}
              trigger={['click']}
              getPopupContainer={triggerNode => triggerNode.parentNode}
            >
              <LanguageSelect>
                Language
                {dropdownVisible ? <DownIcon /> : <UpIcon />}
              </LanguageSelect>
            </Dropdown>
          </DropdownWrapper>
          <CloseIcon onClick={onCancel} />
        </ServiceHeaderMobileWrapper>
      </HeaderWrapper>
      <div
        style={{
          width: '100%',
          background: '#F2EDE6',
          display: 'flex',
          flexGrow: 1,
          alignItems: 'center',
          justifyContent: 'center',
          flexDirection: 'column',
        }}
      >
        <StartButton type="solid" onClick={goMobileStart}>
          {t('SP_HOME_08')}
        </StartButton>

        <HamburgerMenu onClick={onHandleClickIntro}>
          {t('SP_HOME_01')}
        </HamburgerMenu>
        {i18n.language !== 'en' && (
          <HamburgerMenu onClick={onHandleClickNews}>
            {t('SP_NEWS_01')}
          </HamburgerMenu>
        )}
        <HamburgerMenu onClick={onHandleClickInquiry}>
          {t('SP_HOME_02')}
        </HamburgerMenu>
        <HamburgerMenu onClick={onHandleClickDownload}>
          {t('SP_HOME_03')}
        </HamburgerMenu>
        <SupportDiv>
          <HamburgerMenu onClick={onHandleClickSupport}>
            {t('SP_HOME_06')}
          </HamburgerMenu>
          <ExportImg src={exportImg} isen={i18n.language} />
        </SupportDiv>
      </div>
    </DialogWrapper>
  );
};

export default ServiceHamburger;

import React from 'react';
import styled from 'styled-components';
import { Modal } from 'antd';
import { useTranslation } from 'react-i18next';
import { Swiper, SwiperSlide } from 'swiper/react';
import prevArrowImg from '../../assets/landing/media/arrow_back_1.svg';
import nextArrowImg from '../../assets/landing/media/arrow_front_1.svg';
import TutorialItem from './TutorialItem';

import img00 from '../../assets/tutorial/00_image_wapl.png';
import img01 from '../../assets/tutorial/01_image_cowork.png';
import img02 from '../../assets/tutorial/02_image_talk.png';
import img03 from '../../assets/tutorial/03_image_meeting.png';
import img04 from '../../assets/tutorial/03_image_various function.png';

export default function ({ visible, onCancel }) {
  const { t } = useTranslation('common');
  return (
    <StyledModal
      visible={visible}
      footer={null}
      width="24.375rem"
      onCancel={onCancel}
      maskClosable={false}
    >
      <StyledSwiper
        slidesPerView={1}
        navigation
        pagination={{ clickable: true }}
      >
        <SwiperSlide>
          <TutorialItem
            title={t('CM_MY_HOME_TUTORIAL_09')}
            subtitle={t('CM_MY_HOME_TUTORIAL_10')}
          >
            <TutorialImage00 src={img00} />
          </TutorialItem>
        </SwiperSlide>
        <SwiperSlide>
          <TutorialItem
            title={t('CM_MY_HOME_TUTORIAL_01')}
            subtitle={t('CM_MY_HOME_TUTORIAL_02')}
          >
            <TutorialImage01 src={img01} />
          </TutorialItem>
        </SwiperSlide>
        <SwiperSlide>
          <TutorialItem
            title={t('CM_MY_HOME_TUTORIAL_03')}
            subtitle={t('CM_MY_HOME_TUTORIAL_04')}
          >
            <TutorialImage01 src={img02} />
          </TutorialItem>
        </SwiperSlide>
        <SwiperSlide>
          <TutorialItem
            title={t('CM_MY_HOME_TUTORIAL_05')}
            subtitle={t('CM_MY_HOME_TUTORIAL_06')}
          >
            <TutorialImage01 src={img03} />
          </TutorialItem>
        </SwiperSlide>
        <SwiperSlide>
          <TutorialItem
            title={t('CM_MY_HOME_TUTORIAL_07')}
            subtitle={t('CM_MY_HOME_TUTORIAL_08')}
          >
            <TutorialImage01 src={img04} />
          </TutorialItem>
        </SwiperSlide>
      </StyledSwiper>
    </StyledModal>
  );
}

const StyledModal = styled(Modal)`
  top: calc(50% - 13.1875rem);
  .ant-modal-body {
    height: 26.375rem;
  }
`;
const StyledSwiper = styled(Swiper)`
  height: 100%;
  .swiper-button-prev,
  .swiper-container-rtl .swiper-button-next {
    right: 0.875rem;
    right: auto;
    top: 14.5rem;
  }
  .swiper-button-next,
  .swiper-container-rtl .swiper-button-prev {
    right: 0.875rem;
    left: auto;
    top: 14.5rem;
  }
  .swiper-button-prev:after {
    content: '';
    background-image: url('${prevArrowImg}');
    background-size: 1.5rem 1.5rem;
    width: 1.5rem;
    height: 1.5rem;
  }
  .swiper-button-next:after {
    content: '';
    background-image: url('${nextArrowImg}');
    background-size: 1.5rem 1.5rem;
    width: 1.5rem;
    height: 1.5rem;
  }
  .swiper-pagination-bullets {
    bottom: 1.5rem;
    .swiper-pagination-bullet {
      background: #205855;
      bottom: 0;
    }
  }
`;

const TutorialImage00 = styled.img`
  margin-top: 3.3125rem;
  width: 8rem;
  height: 9.5rem;
`;

const TutorialImage01 = styled.img`
  margin-top: 1.125rem;
  width: 12.5rem;
  height: 12.5rem;
`;

const TutorialImage02 = styled.img`
  margin-top: 1.125rem;
  width: 12.5rem;
  height: 12.5rem;
`;

const TutorialImage03 = styled.img`
  margin-top: 1.125rem;
  width: 12.5rem;
  height: 12.5rem;
`;

const TutorialImage04 = styled.img`
  margin-top: 1.125rem;
  width: 12.5rem;
  height: 12.5rem;
`;

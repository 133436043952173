import React, { useState, useEffect } from 'react';
import styled, { css } from 'styled-components';
import { Checkbox, Button, Divider } from 'antd';
import { CaretRightOutlined } from '@ant-design/icons';
import { useCoreStores } from 'teespace-core';
import { isMobile } from 'react-device-detect';
import waplLogoText from '../assets/logo_Wapl_text.svg';
import EventStore from '../stores/EventStore';

const EventJoinPage = () => {
  const query = new URLSearchParams(window.location.search);
  const eventId = query.get('event_id');
  const mobileToken = query.get('at'); // 수정 필요

  const { waplUserStore } = useCoreStores();

  const plainOptions = [
    'collectingUsingAgree',
    'thirdPartyProvidedAgree',
    'consignAgree',
  ];

  const parseJwt = token => {
    var base64Url = token.split('.')[1];
    var base64 = base64Url.replace(/-/g, '+').replace(/_/g, '/');
    var jsonPayload = decodeURIComponent(
      atob(base64)
        .split('')
        .map(function (c) {
          return '%' + ('00' + c.charCodeAt(0).toString(16)).slice(-2);
        })
        .join(''),
    );

    return JSON.parse(jsonPayload);
  };

  const [isLoading, setIsLoading] = useState(true);
  const [buttonLoading, setButtonLoading] = useState(false);
  const [applySuccess, setApplySuccess] = useState(false);
  const [applySuccessText, setApplySuccessText] =
    useState('이벤트 응모가 완료되었습니다.');
  const [checkedList, setCheckedList] = useState([]);
  const [checkAll, setCheckAll] = useState(false);
  const [loginId, setLoginId] = useState('');

  const onCheckboxChange = checkedList => {
    setCheckedList(checkedList);
    if (checkedList.length === plainOptions.length) setCheckAll(true);
    else setCheckAll(false);
  };
  const onCheckAllChange = e => {
    setCheckedList(e.target.checked ? plainOptions : []);
    setCheckAll(e.target.checked);
  };

  const onClickApply = async () => {
    console.log('이벤트 참여');
    setButtonLoading(true);

    if (!eventId || !loginId) {
      alert('잘못된 접근입니다.');
    }

    const eventInfoObj = {
      eventId,
      userLoginId: loginId,
    };
    const res = await EventStore.joinEvent({
      eventInfo: eventInfoObj,
    });

    if (res) {
      console.log('응모 성공');
      setButtonLoading(false);
      setApplySuccessText('이벤트 응모가 완료되었습니다.');
      setApplySuccess(true);
    } else {
      alert('응모 실패');
      setButtonLoading(false);
      setApplySuccessText('응모 실패');
    }
  };

  useEffect(() => {
    const checkJoinedEvent = async (eventId, userLoginId) => {
      const eventInfoObj = {
        eventId,
        userLoginId,
      };
      const res = await EventStore.getJoinedEvent({
        eventInfo: eventInfoObj,
      });
      if (res === 'RST0001') {
        setApplySuccessText('이미 응모한 이벤트입니다.');
        setApplySuccess(true);
      } else if (res === 'RST0003') {
        setApplySuccessText('유효하지 않은 이벤트입니다.');
        setApplySuccess(true);
      }
      setIsLoading(false);
    };

    if (mobileToken && mobileToken !== undefined) {
      const decodedJwt = parseJwt(mobileToken);
      console.log('mobile :', decodedJwt.preferred_username, eventId);
      setLoginId(decodedJwt.preferred_username);
      if (isLoading) {
        checkJoinedEvent(parseInt(eventId, 10), decodedJwt.preferred_username);
      }
    } else if (waplUserStore.myProfile?.loginId) {
      console.log('web: ', waplUserStore.myProfile?.loginId, eventId);
      setLoginId(waplUserStore.myProfile?.loginId);
      if (isLoading) {
        checkJoinedEvent(
          parseInt(eventId, 10),
          waplUserStore.myProfile?.loginId,
        );
      }
    }
  });

  //   useEffect(() => {
  //     console.log('isMobile:', isMobile);
  //   }, [isMobile]);

  if (isLoading) {
    return null;
  }

  return (
    <>
      {applySuccess ? (
        <EventRegisterSuccess ismobile={isMobile}>
          <div>WAPL</div>
          <div>{applySuccessText}</div>
        </EventRegisterSuccess>
      ) : (
        <EventJoinWrapper ismobile={isMobile}>
          <PageHeader ismobile={isMobile}>
            <PageHeaderInner ismobile={isMobile}>
              <PageHeaderInnerWrapper ismobile={isMobile}>
                <PageHeaderImg
                  alt="waplLogo"
                  src={waplLogoText}
                  ismobile={isMobile}
                />
                <PageHeaderLine ismobile={isMobile}>
                  이벤트 개인정보 수집 · 이용 동의
                </PageHeaderLine>
              </PageHeaderInnerWrapper>
            </PageHeaderInner>
          </PageHeader>
          <PageBody>
            <PageInner ismobile={isMobile}>
              <Checkbox checked={checkAll} onChange={onCheckAllChange}>
                <EventTermText ismobile={isMobile}>
                  Tmax WAPL 서비스 이용약관 및 정보활용 전체 동의
                </EventTermText>
              </Checkbox>
              <Divider style={{ background: '#d3d3d3' }} />
              <Checkbox.Group
                value={checkedList}
                onChange={onCheckboxChange}
                style={{ width: '100%' }}
              >
                <EventTermWrapper ismobile={isMobile}>
                  <EventTermText ismobile={isMobile}>
                    개인정보 수집·이용 동의
                  </EventTermText>
                  <EventTermDetail ismobile={isMobile}>
                    {
                      '1. 개인정보의 수집 및 이용 목적 : 이벤트 진행 및 경품 배송\n'
                    }
                    {'2. 수집하는 개인정보의 항목 : 이름, 이메일 주소\n'}
                    {'3. 개인정보의 보유 이용 기간 : '}
                    <EventTermBold>
                      {'이벤트 종료 후 즉시 파기\n'}
                    </EventTermBold>
                    {
                      '4. 개인정보 수집 및 이용에 대한 동의를 거부할 수 있으며, 이경우 이벤트 참여가 제한됩니다.\n'
                    }
                    <EventTermBold>
                       위 사항에 대하여 동의를 하시는 경우, 이벤트 정보, 경품
                      발송 등을 위하여 이메일로 연락이 갈 수 있습니다.
                    </EventTermBold>
                  </EventTermDetail>
                  <Checkbox value="collectingUsingAgree">
                    <EventTermText ismobile={isMobile}>
                      동의합니다 <EventTermNeeded>(필수)</EventTermNeeded>
                    </EventTermText>
                  </Checkbox>
                </EventTermWrapper>
                <EventTermWrapper ismobile={isMobile}>
                  <EventTermText ismobile={isMobile}>
                    개인정보 제3자 제공 시 동의
                  </EventTermText>
                  <EventTermDetail ismobile={isMobile}>
                    {'1. 개인정보를 제공받는 자 :'}{' '}
                    <EventTermBold>{'티맥스 와플 사\n'}</EventTermBold>
                    {'2. 개인정보를 제공 받는 자의 이용 목적 : '}
                    <EventTermBold>
                      {'이벤트 진행 및 경품 배송\n'}
                    </EventTermBold>
                    {'3. 제공하는 개인정보의 항목 : 이름, 이메일 주소\n'}
                    {'4. 개인정보를 제공받는 자의 보유 이용 기간: '}
                    <EventTermBold>
                      {'이벤트 종료 시 즉시 파기\n'}
                    </EventTermBold>
                    5. 개인정보의 제3자 제공에 대한 동의를 거부할 수 있으며, 이
                    경우 이벤트 참여가 제한됩니다.
                  </EventTermDetail>
                  <Checkbox value="thirdPartyProvidedAgree">
                    <EventTermText ismobile={isMobile}>
                      동의합니다 <EventTermNeeded>(필수)</EventTermNeeded>
                    </EventTermText>
                  </Checkbox>
                </EventTermWrapper>
                <EventTermWrapper ismobile={isMobile}>
                  <EventTermText ismobile={isMobile}>
                    개인정보 처리 위탁 동의
                  </EventTermText>
                  <EventTermDetail ismobile={isMobile}>
                    {'1. 개인정보를 처리위탁 받는 자 : '}
                    <EventTermBold>{'㈜케이티알파\n'}</EventTermBold>
                    {'2. 개인정보를 처리위탁 받는 자의 이용 목적 : '}
                    <EventTermBold>{'이벤트 경품 배송\n'}</EventTermBold>
                    3. 개인정보의 처리위탁에 대한 동의를 거부할 수 있으며, 이
                    경우 이벤트 참여가 제한됩니다.
                  </EventTermDetail>
                  <Checkbox value="consignAgree">
                    <EventTermText ismobile={isMobile}>
                      동의합니다 <EventTermNeeded>(필수)</EventTermNeeded>
                    </EventTermText>
                  </Checkbox>
                </EventTermWrapper>
              </Checkbox.Group>
              <ButtonWrapper ismobile={isMobile}>
                <Button
                  disabled={!checkAll}
                  loading={buttonLoading}
                  onClick={onClickApply}
                >
                  응모하기
                </Button>
              </ButtonWrapper>
            </PageInner>
          </PageBody>
        </EventJoinWrapper>
      )}
    </>
  );
};

const EventJoinWrapper = styled.div`
  ${props =>
    props.ismobile
      ? css`
          width: 100%;
          display: flex;
          flex-direction: column;
          overflow-x: hidden;
        `
      : css`
          width: 100%;
          display: flex;
          flex-direction: column;
        `}
`;

const PageHeader = styled.div`
  ${props =>
    props.ismobile
      ? css`
          background-color: #0a1d41;
          ${'' /* height: 4.69rem; */}
          color: #fff;
          display: flex;
          flex-shrink: 0;
          flex-flow: row wrap;
          height: 23rem;
        `
      : css`
          background-color: #0a1d41;
          height: 5rem;
          color: #fff;
          display: flex;
          align-items: center;
          flex-shrink: 0;
        `}
`;

const PageHeaderImg = styled.img`
  ${props =>
    props.ismobile
      ? css`
          ${'' /* height: 1rem; */}
          height: 5rem;
        `
      : css`
          width: 4.38rem;
          height: 100%;
          margin-right: 0.63rem;
        `}
`;

const PageHeaderLine = styled.h1`
  ${props =>
    props.ismobile
      ? css`
          font-size: 16px;
          color: #ffffff;
          letter-spacing: 0;
        `
      : css`
          margin: 0;
          font-size: 1.5rem;
          color: #ffffff;
          letter-spacing: 0;
          line-height: 2.25rem;
        `}
`;

const PageHeaderInner = styled.div`
  ${props =>
    props.ismobile
      ? css`
          ${'' /* width: 100%; */}
          height: 100%;
          display: flex;
          align-items: flex-start;
          justify-content: space-between;
        `
      : css`
          width: 59.31rem;
          margin: 0 auto;
          display: flex;
          align-items: center;
        `}
`;

const PageHeaderInnerWrapper = styled.div`
  ${props =>
    props.ismobile
      ? css`
          display: flex;
          flex-direction: column;
          font-size: 1rem;
          color: #ffffff;
          letter-spacing: 0;
          align-items: flex-start;
          ${
            '' /* padding-left: 1rem;
          padding-top: 1rem; */
          }
          padding-left: 5rem;
          padding-top: 5rem;
        `
      : css`
          display: flex;
          flex-direction: row;
          align-items: center;
        `}
`;

const PageBody = styled.div`
  flex: 1;
  overflow: hidden;
  display: flex;
`;

const PageInner = styled.div`
  ${props =>
    props.ismobile
      ? css`
          margin: 20px;
          overflow: hidden;
          padding: 0 auto;
          .ant-checkbox-inner {
            border-radius: 4px;
            width: 4.6rem;
            height: 4.6rem;
          }
          .ant-checkbox-inner::after {
            width: 1.5rem;
            height: 2.5rem;
          }
        `
      : css`
          width: 59.31rem;
          margin: 0 auto;
          overflow: hidden;
          margin: 1.38rem auto 1.56rem auto;
        `}
`;

const EventTermWrapper = styled.div`
  ${props =>
    props.ismobile
      ? css`
          margin-bottom: 8.7rem;
        `
      : css`
          margin-bottom: 1.75rem;
        `}
`;

const EventTermText = styled.div`
  font-weight: 500;
  ${props =>
    props.ismobile
      ? css`
          font-size: 4.3rem;
        `
      : css`
          font-size: 0.875rem;
        `}
`;

const EventTermDetail = styled.div`
  margin: 1rem 0;
  padding: 0.625rem;
  border-radius: 4px;
  border: 1px solid #d0ccc7;
  width: 100%;
  overflow: scroll;

  white-space: pre-wrap;
  font-family: 'NotoSansKR', sans-serif;
  ${props =>
    props.ismobile
      ? css`
          height: 33rem;
        `
      : css`
          height: 6.625rem;
        `}
`;

const EventTermBold = styled.span`
  font-weight: bold;
`;

const EventTermNeeded = styled.span`
  color: #ff4646;
`;

const ButtonWrapper = styled.div`
  margin: 1rem;
  text-align: center;
  color: #232d3b;

  button {
    color: #ffffff;
    background: #232d3b;
    border-color: #232d3b;
    ${props =>
      props.ismobile
        ? css`
            width: 25rem;
            height: 10rem;
            font-size: 4rem;
            border-radius: 4px;
          `
        : css`
            width: 10rem;
            height: 1.874rem;
          `}
  }
`;

const EventRegisterSuccess = styled.div`
  fontweight: 700;
  text-align: center;
  ${props =>
    props.ismobile
      ? css`
          padding: 5rem;
          font-size: 5rem;
        `
      : css`
          padding: 1rem;
          font-size: 1.5rem;
        `}
`;

export default EventJoinPage;

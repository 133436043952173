import { observable } from 'mobx';
import ServiceRepo from './repositories/ServiceRepository';
import ServiceModel from './models/ServiceModel';

const ServiceStore = observable({
  __noticeList: observable.map({}),

  get noticeList() {
    return [...this.__noticeList.values()];
  },

  async createCorps({ corpsInfo }) {
    try {
      const res = await ServiceRepo.createCorps({ corpsInfo });
      return res;
    } catch (error) {
      throw Error(error);
    }
  },
  async getActiveNotice(): Promise<Array<ServiceModel>> {
    try {
      const res = await ServiceRepo.getActiveNotice();
      this.setNoticeList(res.noticeList);
      return this.noticeList;
    } catch (error) {
      throw Error(error);
    }
  },

  setNoticeList(noticeList: Array<ServiceModel>): void {
    const obj = {};

    noticeList.forEach(notice => {
      obj[notice.noticeId] = new ServiceModel(notice);
    });
    this.__noticeList.replace(obj);
  },
});
export default ServiceStore;

import React, { useState, useEffect } from 'react';
import { Menu } from 'antd';
import { useTranslation } from 'react-i18next';
import styled from 'styled-components';
import { Modal, useCoreStores, WaplUserStore } from 'teespace-core';
import AccountModalContent from './AccountModalContent';
import LanguageModalContent from './LanguageModalContent';
import ThemeModalContent from './ThemeModalContent';

const SettingModal = ({ visible, onCancel }) => {
  const { waplAuthStore, waplUserStore } = useCoreStores();
  const { t } = useTranslation(['common']);
  const [selectedKey, setSelectedKey] = useState('account');

  const selectedTarget = target => {
    switch (target) {
      case 'account':
        return <AccountModalContent />;
      case 'language':
        return <LanguageModalContent />;
      case 'theme':
        return <ThemeModalContent />;
      default:
        return null;
    }
  };

  const onHandleMenuClick = key => {
    waplUserStore.fetchUserProfile({
      loginId: waplUserStore.myProfile.loginId,
    });
    setSelectedKey(key);
  };

  return (
    <Modal
      onCancel={onCancel}
      visible={visible}
      centered
      title={t(`CM_SETTING_05`)}
      width="59.375rem"
      style={{ minWidth: '50rem' }}
      footer={null}
    >
      <FlexWrapper>
        <MenuArea>
          <MenuContent
            selectedKeys={selectedKey}
            onClick={({ key }) => onHandleMenuClick(key)}
          >
            <Menu.ItemGroup key="basic" title={t(`CM_SETTING_06`)}>
              <Menu.Item key="account">{t(`CM_EDIT_MYPAGE_01`)}</Menu.Item>
              <Menu.Item key="language">{t(`CM_SETTING_GENERAL_04`)}</Menu.Item>
              <Menu.Item key="theme">{t(`CM_SETTING_GENERAL_01`)}</Menu.Item>
            </Menu.ItemGroup>
          </MenuContent>
        </MenuArea>
        <ContentArea>{selectedTarget(selectedKey)}</ContentArea>
      </FlexWrapper>
    </Modal>
  );
};

export default SettingModal;

const FlexWrapper = styled.div`
  display: flex;
`;

const MenuArea = styled.div`
  width: 10.938rem;
  background-color: ${props => props.theme.SubStateNormal};
`;

const ContentArea = styled.div`
  display: flex;
  flex-direction: column;
  flex: 1;
  height: 35.625rem;
  padding-left: 1.25rem;
`;

const MenuContent = styled(Menu)`
  background-color: ${props => props.theme.SubStateNormal};
  border: 0;
  .ant-menu-item-group-title {
    padding: 1.25rem 1.25rem 0.625rem;
    font-size: 0.75rem;
    line-height: 1.125rem;
    color: #717171;
  }
  .ant-menu-item {
    height: 2.38rem;
    margin: 0 0.25rem !important;
    padding: 0 2.5rem;
    border-radius: 0.25rem;
    font-size: 0.81rem;
    line-height: 2.38rem;
    color: ${props => props.theme.TextMain};
    &.ant-menu-item-selected {
      background-color: ${props => props.theme.SubStateDark};
    }
    &.ant-menu-item-active {
      color: ${props => props.theme.TextMain};
    }
    &:active {
      background-color: ${props => props.theme.SubStateDark};
    }
  }
`;

import React, { useState, useCallback } from 'react';
import { Form, Input, useCoreStores } from 'teespace-core';
import { useTranslation } from 'react-i18next';
import { Button } from 'antd';
import styled, { css } from 'styled-components';
import CheckImg from '../../assets/check.svg';
import { checkPasswordValid } from '../../libs/Regex';

const ValidationText = ({ checked, text }) => {
  return (
    <StyledValidationText checked={checked}>
      <StyledCheckOutlined />
      {text}
    </StyledValidationText>
  );
};

function AccountPasswordForm({ onCancel, onSave, getPopupContainer }) {
  const [validPWLength, setValidPWLength] = useState(false);
  const [validPWChar, setValidPWChar] = useState(false);
  const { waplUserStore } = useCoreStores();
  const { t } = useTranslation('common');
  const [changed, setChanged] = useState(false);

  const handleChangePassword = useCallback(
    async values => {
      const pw = values.newPassword;
      const success = await waplUserStore.updatePassword({
        pw,
      });
      if (success) {
        onSave();
      }
    },
    [onSave, waplUserStore],
  );

  const handleInputChange = useCallback(() => setChanged(true), []);
  return (
    <Wrapper>
      <AccountInformationTitle>{t('CM_PWD')}</AccountInformationTitle>
      <PasswordFormWrapper>
        <Form onFinish={handleChangePassword}>
          <FormItemTitle>
            {t('CM_LOGIN_POLICY_CHANGE_PW_GUIDE_03')}
          </FormItemTitle>
          <Form.Item
            noStyle
            name="password"
            validateTrigger={['onBlur']}
            rules={[
              {
                required: true,
                message: '비밀번호를 입력해 주세요.',
                validateTrigger: 'onBlur',
              },
              {
                validateTrigger: 'onBlur',
                validator: async (_, value) => {
                  const success = await waplUserStore.validatePassword({
                    loginId: waplUserStore.myProfile.loginId,
                    pw: value || '',
                  });
                  if (!success) {
                    // eslint-disable-next-line prefer-promise-reject-errors
                    return Promise.reject('비밀번호가 일치하지 않습니다.');
                  }
                  return Promise.resolve();
                },
              },
            ]}
          >
            <FormInput
              type="password"
              name="password"
              getPopupContainer={getPopupContainer}
              placement="topRight"
            />
          </Form.Item>
          <FormItemTitle>{t('CM_NEW_PWD')}</FormItemTitle>
          <Form.Item
            name="newPassword"
            validateTrigger={['onBlur']}
            rules={[
              {
                required: true,
                message: '새 비밀번호를 입력해 주세요.',
                validateTrigger: 'onBlur',
              },
              {
                validator: (_, value) => {
                  if (value && value.length >= 9 && value.length <= 20) {
                    setValidPWLength(true);
                    return Promise.resolve();
                  }
                  setValidPWLength(false);
                  // eslint-disable-next-line prefer-promise-reject-errors
                  return Promise.reject(
                    '새 비밀번호는 9자 이상 20자 이하로 입력해 주세요.',
                  );
                },
              },
              {
                validator: (_, value) => {
                  if (value && checkPasswordValid(value)) {
                    setValidPWChar(true);
                    return Promise.resolve();
                  }
                  setValidPWChar(false);
                  // eslint-disable-next-line prefer-promise-reject-errors
                  return Promise.reject(
                    '새 비밀번호는 영문 대/소문자, 숫자, 특수문자(!@#$%^&*()<>?) 모두 조합하여 입력해 주세요.',
                  );
                },
              },
            ]}
            noStyle
          >
            <StyledInput
              type="password"
              getPopupContainer={getPopupContainer}
              onChange={handleInputChange}
              placement="topRight"
            />
          </Form.Item>
          <ValidationTextWrapper>
            <ValidationText
              text={t('CM_LOGIN_POLICY_CHANGE_PW_03')}
              checked={validPWLength}
            />
            <ValidationText
              text={t('CM_LOGIN_POLICY_CHANGE_PW')}
              checked={validPWChar}
            />
          </ValidationTextWrapper>
          <FormItemTitle>{t('CM_NEW_PWD_CONFIRM')}</FormItemTitle>
          <Form.Item
            name="newPasswordConfirm"
            dependencies={[['newPasswordConfirm', 'newPassword']]}
            validateTrigger={['onBlur']}
            rules={[
              {
                required: true,
                message: '새 비밀번호 확인을 입력해 주세요.',
                validateTrigger: 'onBlur',
              },
              ({ getFieldValue }) => ({
                validator(rule, value) {
                  if (!value || getFieldValue('newPassword') === value) {
                    return Promise.resolve();
                  }
                  // eslint-disable-next-line prefer-promise-reject-errors
                  return Promise.reject('새 비밀번호가 일치하지 않습니다.');
                },
              }),
            ]}
            noStyle
          >
            <FormInput
              type="password"
              getPopupContainer={getPopupContainer}
              placement="topRight"
            />
          </Form.Item>
          <SaveButtonWrapper>
            <Form.Item noStyle>
              <Button htmlType="submit" type="solid" disabled={!changed}>
                {t('CM_SAVE')}
              </Button>
            </Form.Item>
          </SaveButtonWrapper>
        </Form>
      </PasswordFormWrapper>
      <CancelWrapper>
        <StyledButton onClick={onCancel}>{t('CM_CANCEL')}</StyledButton>
      </CancelWrapper>
    </Wrapper>
  );
}

export default AccountPasswordForm;

const Wrapper = styled.div`
  width: 100%;
  display: flex;
  align-items: center;
  flex-direction: row;
  background: ${props => props.theme.StateBright};
  padding: 1.25rem 0.9375rem;
  align-items: stretch;
`;
const AccountInformationTitle = styled.h1`
  font-weight: 400;
  line-height: 1.1875rem;
  display: inline-block;
  font-size: 0.8125rem;
  color: ${props => props.theme.TextSub};
`;

const PasswordFormWrapper = styled.div`
  display: flex;
  flex-direction: column;
  flex-grow: 1;
  margin-left: 1.25rem;
`;

const FormItemTitle = styled.h1`
  margin: 0 0 0.6875rem 0;
  font-size: 0.75rem;
  color: ${props => props.theme.TextSub};
  line-height: 1.125rem;
  font-weight: 400;
`;

const StyledInput = styled(Input)``;
const FormInput = styled(StyledInput)`
  margin-bottom: 1.25rem;
`;

const CancelWrapper = styled.div`
  display: flex;
  align-items: flex-end;
`;

const SaveButtonWrapper = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
  margin-top: 0.625rem;
  margin-right: 0.5rem;
  button {
    min-width: 0 !important;
    padding-left: 0.84375rem !important;
    padding-right: 0.84375rem !important;
  }
`;

const StyledButton = styled(Button)`
  padding-left: 0.84375rem !important;
  padding-right: 0.84375rem !important;
  min-width: 0 !important;
`;

const StyledValidationText = styled.div`
  margin-top: 0.3125rem;
  color: ${({ theme, checked }) => (checked ? '#16ac66' : theme.TextSub2)};
  display: inline-flex;
  font-size: 0.75rem;
  line-height: 1.125rem;
  align-items: center;
`;

const ValidationTextWrapper = styled.div`
  margin-bottom: 1.25rem;
  div:first-child {
    margin-right: 1rem;
  }
`;

const StyledCheckOutlined = styled.i`
  width: 0.75rem;
  min-width: 0.75rem;
  height: 0.75rem;
  background-color: #888888;
  mask-image: url('${CheckImg}');
  mask-size: 100% 100%;
  mask-repeat: no-repeat;
  display: inline-block;
  margin-right: 0.25rem;
`;

import React, { useState, useCallback } from 'react';
import { useCoreStores } from 'teespace-core';
import { Button, Dropdown, Menu } from 'antd';
import styled from 'styled-components';
import { useTranslation } from 'react-i18next';
import arrowBottom from '../assets/arrow_bottom_1.svg';
import arrowTop from '../assets/arrow_top_1.svg';
import facebookImage from '../assets/landing/footer/facebook.png';
import youtubeImage from '../assets/landing/footer/youtube.png';
import linkedinImage from '../assets/landing/footer/linkedin.png';
import naverblogImage from '../assets/landing/footer/naverblog.png';

const TermsFooter = ({
  isService,
  autoMarginTop,
  isHiddenText = true,
  isAdvertise = false,
}) => {
  const [dropdownVisible, setDropdownVisible] = useState(false);
  const { t, i18n } = useTranslation('common');
  const { waplUserStore } = useCoreStores();

  const onHandleTermConditionsClick = () => {
    window.open('/term-and-conditions');
  };

  const onHandlePrivacyPolicyClick = () => {
    window.open('/privacy-policy');
  };

  const handleDropdownVisibleChange = visible => {
    setDropdownVisible(visible);
  };

  const onHandleFacebookClick = useCallback(() => {
    window.open(`https://www.facebook.com/TmaxHQ`);
  }, []);

  const onHandleYoutubeClick = useCallback(() => {
    window.open(
      `https://www.youtube.com/channel/UCGxa230hX8qiBsaHLoQU4Bw/featured`,
    );
  }, []);

  const onHandleLinkedInClick = useCallback(() => {
    window.open(`https://www.linkedin.com/company/wapl-ai`);
  }, []);

  const onHandleNaverblogClick = useCallback(() => {
    window.open(`https://blog.naver.com/wapl_ai`);
  }, []);

  const handleChangeLanguage = async language => {
    await waplUserStore.updateLanguage({ language });
    i18n.changeLanguage(language);
  };
  const menu = (
    <Menu onClick={() => setDropdownVisible(false)}>
      <Menu.Item key="1" onClick={() => handleChangeLanguage('ko')}>
        {t('CM_KOREAN')}
      </Menu.Item>
      <Menu.Item key="2" onClick={() => handleChangeLanguage('en')}>
        {t('CM_ENGLISH')}
      </Menu.Item>
    </Menu>
  );
  return (
    <CorpArea automargintop={autoMarginTop}>
      {isAdvertise && <ForAdText>와플 WAPL</ForAdText>}
      {isHiddenText && (
        <FlexRow>
          <TextLink onClick={onHandleTermConditionsClick}>
            {t('CM_TERMS_OF_USE')}
          </TextLink>
          <LineBar />
          <PrivacyTextLink onClick={onHandlePrivacyPolicyClick}>
            {t('CM_PRIVACY_POLICY')}
          </PrivacyTextLink>
          <Dropdown
            overlay={menu}
            visible={dropdownVisible}
            onVisibleChange={handleDropdownVisibleChange}
            trigger={['click']}
          >
            <LanguageSelect>
              Language
              {dropdownVisible ? <DownIcon /> : <UpIcon />}
            </LanguageSelect>
          </Dropdown>
          {isService && (
            <>
              <LineBar />
              <Button id="serviceInfo" type="text">
                서비스 소개
              </Button>
            </>
          )}
        </FlexRow>
      )}
      <Copyright>
        <TmaxIcon />
        ©copyright 2021. TmaxWAPL Corp. All rights reserved
      </Copyright>
      {isAdvertise && (
        <SnsWrapper>
          <NaverblogImage
            src={naverblogImage}
            onClick={onHandleNaverblogClick}
          />
          <SnsText onClick={onHandleNaverblogClick}>blog</SnsText>
          <LineBar />
          <YoutubeImage src={youtubeImage} onClick={onHandleYoutubeClick} />
          <SnsText onClick={onHandleYoutubeClick}>youtube</SnsText>
          <LineBar />
          <LinkedInImage src={linkedinImage} onClick={onHandleLinkedInClick} />
          <SnsText onClick={onHandleLinkedInClick}>linkedin</SnsText>
          <LineBar />
          <FacebookImage src={facebookImage} onClick={onHandleFacebookClick} />
          <SnsText onClick={onHandleFacebookClick}>facebook</SnsText>
        </SnsWrapper>
      )}
    </CorpArea>
  );
};

export default TermsFooter;

const CorpArea = styled.div`
  width: 35rem;
  height: 2.3125rem;
  margin: ${props => (props.automargintop ? '0' : '3rem')} auto 1.25rem auto;
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  position: relative;

  @media (max-width: 767px) {
    width: 100%;
  }
`;
const FlexRow = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  .ant-btn {
    font-size: 0.75rem;
    color: ${props => props.theme.TextSub2};
    padding: 0;
    height: auto;
  }
`;
const Copyright = styled.p`
  text-align: center;
  font-size: 0.6875rem;
  color: ${props => props.theme.TextSub2};
  letter-spacing: 0;

  @media (max-width: 767px) {
    font-size: 16px;
  }
`;
const LineBar = styled.span`
  width: 1px;
  height: 12px;
  background-color: ${props => props.theme.TextSub2};
  margin: auto 0.5rem;
  display: inline-block;
`;
const TmaxIcon = styled.i`
  background: url('/logo_footer_copyright.png') 0 0 no-repeat;
  background-size: cover;
  width: 6rem;
  height: 1rem;
  display: inline-block;
  margin-right: 0.625rem;
`;

const LanguageSelect = styled.span`
  color: ${props => props.theme.TextSub};
  font-size: 0.75rem;
  line-height: 1.125rem;
  cursor: pointer;
  font-weight: 500;
  display: inline-flex;
  align-items: center;
  margin-left: auto;
  position: absolute;
  right: 0;

  &.ant-dropdown-trigger span.anticon {
    font-size: 0.55rem;
    margin-top: 0.125rem;
    margin-left: 0.40625rem;
  }
`;

const TextLink = styled.span`
  display: block;
  font-size: 0.75rem;
  color: ${props => props.theme.FooterLink};
  font-weight: 500;
  cursor: pointer;

  @media (max-width: 767px) {
    font-size: 16px;
  }
`;

const PrivacyTextLink = styled(TextLink)`
  font-weight: bold;

  @media (max-width: 767px) {
    font-size: 16px;
  }
`;

const ForAdText = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  font-size: 0.75rem;
  color: ${props => props.theme.FooterLink};
  font-weight: 500;

  @media (max-width: 767px) {
    font-size: 16px;
  }
`;

const SnsWrapper = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  margin-top: 0.125rem;

  @media (max-width: 767px) {
    font-size: 16px;
  }
`;

const SnsText = styled.div`
  line-height: 1.2rem;
  cursor: pointer;
  padding-left: 0.125rem;
`;

const FacebookImage = styled.img`
  width: 0.8rem;
  height: 0.8rem;
  cursor: pointer;
`;

const YoutubeImage = styled.img`
  width: 1.2rem;
  height: 1.2rem;
  cursor: pointer;
`;

const LinkedInImage = styled.img`
  width: 0.8rem;
  height: 0.8rem;
  cursor: pointer;
`;

const NaverblogImage = styled.img`
  width: 1.2rem;
  height: 1.2rem;
  cursor: pointer;
`;

const DownIcon = styled.i`
  display: inline-block;
  mask-image: url('${arrowBottom}');
  mask-repeat: no-repeat;
  mask-size 100%;
  background-color:${props => props.theme.TextSub2};
  width: 0.875rem;
  height: 0.875rem;
  margin-left: 0.25rem;
`;

const UpIcon = styled.i`
  display: inline-block;
  mask-image: url('${arrowTop}');
  mask-repeat: no-repeat;
  mask-size 100%;
  background-color:${props => props.theme.TextSub2};
  width: 0.875rem;
  height: 0.875rem;
  margin-left: 0.25rem;
`;

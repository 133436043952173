import React, { useCallback, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useCoreStores } from 'teespace-core';
import { Radio } from 'antd';
import styled from 'styled-components';
import SettingModalContent from './SettingModalContent';
import darkModeImg from '../../assets/theme/Theme_Dark.svg';
import lightModeImg from '../../assets/theme/Theme_Light.svg';
import systemModeImg from '../../assets/theme/Theme_System.svg';
import useColorScheme from '../../libs/theme';

const LanguageModalContent = () => {
  const { waplUserStore, themeStore } = useCoreStores();
  const { t } = useTranslation(['common']);
  const [value, setValue] = useState(waplUserStore.myProfile.theme || 'system');
  const isDark = useColorScheme('dark');

  const onChange = async e => {
    if (e.target.value === 'system') {
      await waplUserStore.updateTheme({ theme: e.target.value });
      const themeName = isDark ? 'dark' : 'white';
      themeStore.setTheme(themeName);
    } else {
      await waplUserStore.updateTheme({ theme: e.target.value });
      themeStore.setTheme(waplUserStore.myProfile.theme);
    }
    setValue(e.target.value);
  };

  return (
    <>
      <SettingModalContent
        title={t(`CM_SETTING_GENERAL_01`)}
        subTitle={t(`CM_SETTING_GENERAL_02`)}
      />
      <RadioGroupWrapper>
        <Radio.Group onChange={onChange} value={value}>
          <Wrapper>
            <ThemeImg src={systemModeImg} />
            <Radio value="system">{t(`CM_SETTING_09`)}</Radio>
            <SystemSubText>{t(`CM_SETTING_10`)}</SystemSubText>
          </Wrapper>
          <Wrapper>
            <ThemeImg src={lightModeImg} />
            <Radio value="white">{t(`CM_SETTING_11`)}</Radio>
          </Wrapper>
          <Wrapper>
            <ThemeImg src={darkModeImg} />
            <Radio value="dark">{t(`CM_SETTING_12`)}</Radio>
          </Wrapper>
        </Radio.Group>
      </RadioGroupWrapper>
    </>
  );
};

export default LanguageModalContent;

const RadioGroupWrapper = styled.div`
  & .ant-radio-group {
    display: flex;
    flex-direction: row;
  }
`;

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  margin-right: 3.31rem;
  width: 9.375rem;
  .ant-radio-wrapper {
    color: ${props => props.theme.TextMain};
    font-size: 0.75rem;
  }
`;

const ThemeImg = styled.img`
  width: 9.375rem;
  margin-bottom: 0.63rem;
  border-radius: 0.63rem;
  border: 1px solid rgb(215, 215, 215);
`;

export const SystemSubText = styled.span`
  margin-top: 0.5rem;
  font-size: 0.69rem;
  color: ${props => props.theme.TextSub2};
`;

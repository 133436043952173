/* eslint-disable */
import { useState, useEffect, useRef, useMemo } from 'react';

const COLOR_SCHEMES = ['no-preference', 'dark', 'light'];
const DEFAULT_TARGET_COLOR_SCHEME = 'light';

function resolveTargetColorScheme(scheme) {
  if (
    !(
      COLOR_SCHEMES.includes((scheme = String(scheme).toLowerCase())) ||
      scheme === 'no-preference'
    )
  )
    scheme = DEFAULT_TARGET_COLOR_SCHEME;

  return scheme;
}

function getCurrentColorScheme() {
  const QUERIES = {};

  return (getCurrentColorScheme = function () {
    for (const scheme of COLOR_SCHEMES) {
      const query = QUERIES.hasOwnProperty(scheme)
        ? QUERIES[scheme]
        : (QUERIES[scheme] = matchMedia(`(prefers-color-scheme: ${scheme})`));

      if (query.matches) return { query, scheme };
    }
    return { query: null, scheme: DEFAULT_TARGET_COLOR_SCHEME };
  })();
}

export default function useColorScheme(targetColorScheme) {
  const isMounted = useRef();
  const colorScheme = useRef();

  const targetScheme = useMemo(
    () => resolveTargetColorScheme(targetColorScheme),
    [targetColorScheme],
  );

  const [scheme, setColorScheme] = useState(() => {
    const { scheme } = (colorScheme.current = getCurrentColorScheme());
    return scheme;
  });

  useEffect(() => {
    const { query } = colorScheme.current;

    if (query) {
      query.addEventListener('change', schemeChangeHandler);
    }
    isMounted.current = true;

    function schemeChangeHandler(evt) {
      if (!evt.matches) {
        this.removeEventListener('change', schemeChangeHandler);
        const {
          query,
          scheme,
        } = (colorScheme.current = getCurrentColorScheme());

        isMounted.current && setColorScheme(scheme);
        query.addEventListener('change', schemeChangeHandler);
      }
    }

    return () => {
      const { query } = colorScheme.current;
      if (query) {
        query.removeEventListener('change', schemeChangeHandler);
      }
      isMounted.current = false;
    };
  }, []);

  return scheme === targetScheme;
}

import React, { useState, useCallback, useEffect } from 'react';
import { useObserver } from 'mobx-react';
import { Message, useCoreStores } from 'teespace-core';
import { useTranslation } from 'react-i18next';

import styled from 'styled-components';
import { useHistory } from 'react-router-dom';
import { useStores } from '../../stores';
import SpaceTypeItem from './SpaceTypeItem';
import friendsImg from '../../assets/friends.svg';
import officeImg from '../../assets/office.svg';

function SpaceTypeSelectContent() {
  const history = useHistory();
  const [messageVisible, setMessageVisible] = useState(false);
  const { spaceStore } = useStores();
  const { waplUserStore } = useCoreStores();
  const { t } = useTranslation('common');

  useEffect(() => {
    if (
      spaceStore.getAdminSpaces({ loginId: waplUserStore.myProfile.loginId })
        .length >= 3
    ) {
      history.replace('/spaces?limited=true');
    }
  }, [
    history,
    spaceStore,
    spaceStore.spaceList,
    spaceStore.spaceList.length,
    waplUserStore.myProfile.id,
    waplUserStore.myProfile.loginId,
  ]);

  const handleCreateEnterprise = useCallback(() => {
    if (
      spaceStore
        .getAdminSpaces({ loginId: waplUserStore.myProfile.loginId })
        .filter(space => space.type === 'B2B').length >= 1
    ) {
      setMessageVisible(true);
    } else {
      history.push('/create-space?type=B2B');
    }
  }, [history, spaceStore, waplUserStore.myProfile.loginId]);

  const handleMessageClose = useCallback(() => setMessageVisible(false), []);
  return useObserver(() => (
    <>
      <Message
        visible={messageVisible}
        title={t('CM_SPACE_CREATE_OPTION_ERROR_01')}
        subtitle={t('CM_SPACE_CREATE_OPTION_ERROR_02')}
        btns={[
          {
            type: 'solid',
            text: t('CM_LOGIN_POLICY_03'),
            onClick: handleMessageClose,
          },
        ]}
      />
      <SpaceTypeTitle>{t('CM_SPACE_CREATE_OPTION_01')}</SpaceTypeTitle>
      <SpaceTypeSubtitle>{t('CM_SPACE_CREATE_OPTION_02')}</SpaceTypeSubtitle>
      <SpaceTypeWrapper>
        <SpaceTypeItem
          icon={<FriendsIcon />}
          onClick={() => history.push('/create-space?type=B2C')}
          typeName={t('CM_SPACE_CREATE_OPTION_03')}
          title={t('CM_SPACE_CREATE_OPTION_04')}
          description={t('CM_SPACE_CREATE_OPTION_05')}
        />
        <SpaceTypeItem
          onClick={handleCreateEnterprise}
          icon={<OfficeIcon />}
          typeName={t('CM_SPACE_CREATE_OPTION_06')}
          title={t('CM_SPACE_CREATE_OPTION_07')}
          description={t('CM_SPACE_CREATE_OPTION_08')}
        />
      </SpaceTypeWrapper>
    </>
  ));
}

export default SpaceTypeSelectContent;

const SpaceTypeTitle = styled.div`
  font-weight: 500;
  margin-bottom: 0.125rem;
  margin: 6.12rem 0 0.375rem 0;
  font-size: 1.5rem;
  line-height: 2.25rem;
  text-align: center;
  color: ${props => props.theme.TextMain};
`;

const SpaceTypeSubtitle = styled.div`
  font-weight: 400;
  font-size: 0.8125rem;
  line-height: 1.1875rem;
  color: ${props => props.theme.TextSub};
  margin-bottom: 5.8125rem;
`;

const SpaceTypeWrapper = styled.div`
  display: flex;
  flex-direction: row;
`;

const FriendsIcon = styled.i`
  width: 1.875rem;
  height: 1.875rem;
  background-color: ${props => props.theme.IconNormal};

  button:active > &,
  button:focus > & {
    background-color: ${props => props.theme.IconActive};
  }
  mask-repeat: no-repeat;
  mask-size: cover;
  mask-image: url('${friendsImg}');
`;

const OfficeIcon = styled.i`
  width: 1.875rem;
  height: 1.875rem;
  background-color: ${props => props.theme.IconNormal};

  button:active > &,
  button:focus > & {
    background-color: ${props => props.theme.IconActive};
  }
  mask-repeat: no-repeat;
  mask-size: cover;
  mask-image: url('${officeImg}');
`;

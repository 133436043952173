import React, { useCallback } from 'react';
import styled, { css } from 'styled-components';
import { useCoreStores } from 'teespace-core';
import { useTranslation } from 'react-i18next';
import { useObserver } from 'mobx-react';
import FriendsImg from '../../assets/group.svg';
import OfficeImg from '../../assets/office.svg';
import EllipsisableTitle from '../EllipsisableTitle';
import { getDomain, getDomainWithoutProtocol } from '../../libs/domain';

const getSpaceAbbr = name => {
  return name ? name.slice(0, 1).toUpperCase() : '(이름 없음)';
};

function SpaceItem({
  workspace,
  // name,
  displayName,
  // domain,
  // type,
  // userCount,
  // adminId,
  // unreadSpaceCount,
  // // userId,
  // // userLoginId,
  // // userName,
  // // plan,
  readOnly,
  // ...rest
}) {
  const { waplUserStore } = useCoreStores();
  const { t } = useTranslation('common');

  const handleClick = useCallback(() => {
    if (!readOnly) {
      window.location.href = getDomain(workspace.domain);
    }
  }, [readOnly, workspace.domain]);
  return useObserver(() => (
    <StyledSpaceItem
      workspace={workspace}
      readonly={readOnly}
      onClick={handleClick}
    >
      <SpaceProfile className="space-profile">
        {workspace.unreadSpaceCount > 0 && (
          <UnReadCountBadge>
            {workspace.unreadSpaceCount > 99
              ? '99+'
              : workspace.unreadSpaceCount}
          </UnReadCountBadge>
        )}
        {workspace.profilePhotoURL ? (
          <SpaceProfilePhoto src={workspace.profilePhotoURL} />
        ) : (
          <SpaceProfileText>{getSpaceAbbr(workspace.name)}</SpaceProfileText>
        )}
      </SpaceProfile>
      <SpaceContent className="space-content">
        <SpaceContentTop>
          <FriendsIcon />
          <span>{workspace.userCount || 0}</span>
          {workspace.memGrade === 'admin' && <span>{t('CM_ADMIN')}</span>}
          {/* {workspace.adminId === waplUserStore.myProfile.id && (
            <span>{t('CM_ADMIN')}</span>
          )} */}
        </SpaceContentTop>
        <SpaceTitle className="space-title">
          <EllipsisableTitle title={workspace.name}>
            <>
              {workspace.type === 'B2B' && <OfficeIcon />}
              {displayName || workspace.displayName || workspace.name}
            </>
          </EllipsisableTitle>
        </SpaceTitle>
        <SpaceDomain className="space-domain">
          <EllipsisableTitle
            title={getDomainWithoutProtocol(workspace.domain)}
            placement="topLeft"
          />
        </SpaceDomain>
      </SpaceContent>
    </StyledSpaceItem>
  ));
}

export default SpaceItem;

const handleSpaceItemReadonlyStyle = ({ readonly, theme }) => {
  if (!readonly) {
    return css`
      margin-left: 1.875rem;
      margin-right: 1.875rem;
      position: relative;
      z-index: 1;

      &:before {
        position: absolute;
        top: 0;
        bottom: 0;
        left: -1.25rem;
        right: -1.25rem;
        content: '';
        background-size: cover;
        background-position: center;
        border-radius: 1.0625rem;
        z-index: -1;
      }
      &:hover:before {
        background-color: ${theme.StateBright};
        border-radius: 1.0625rem;
      }

      &:active:before {
        background-color: ${theme.StateDark};
        border-radius: 1rem;
      }
    `;
  }
};
const StyledSpaceItem = styled.div`
  display: flex;
  align-items: center;
  flex-direction: row;
  cursor: ${props => (props.readonly ? 'initial' : 'pointer')};

  ${({ readonly, theme }) => handleSpaceItemReadonlyStyle({ readonly, theme })}
`;

const SpaceProfile = styled.div`
  position: relative;
  margin: 0.9375rem 1.25rem 0.9375rem 0;
  background-color: ${props => props.theme.SubStateBright};
  width: 3.75rem;
  height: 3.75rem;
  border-radius: 0.75rem;
  display: flex;
  justify-content: center;
  align-items: center;
`;

const SpaceProfilePhoto = styled.img`
  width: 100%;
  height: 100%;
  object-fit: cover;
  border-radius: 0.75rem;
`;
const SpaceProfileText = styled.div`
  font-weight: 500;
  display: inline-block;
  line-height: 2.25rem;
  font-size: 1.375rem;
  color: ${props => props.theme.TextSub4};
  text-align: center;
`;

const SpaceContent = styled.div`
  width: calc(100% - 5rem);
  flex: 1;
  flex-grow: 1;
  align-items: flex-start;
`;
const SpaceTitle = styled.h1`
  margin: 0;
  color: ${props => props.theme.TextMain};
  font-size: 0.9375rem;
  line-height: 1.3125rem;
  font-weight: 500;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
`;

const SpaceDomain = styled.h2`
  color: ${props => props.theme.TextSub};
  font-size: 0.75rem;
  line-height: 1.125rem;
  margin: 0;
  font-weight: 400;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
`;

const SpaceContentTop = styled.div`
  display: flex;
  justify-content: flex-start;
  align-items: center;
  margin-bottom: 0.125rem;

  span {
    color: ${props => props.theme.TextSub};
    font-weight: 400;
    margin-left: 0.3125rem;
    &:last-child {
      margin-right: 0.3125rem;
    }
    &:not(:last-child)::after {
      content: '·';
      top: 0;
      margin-left: 0.3125rem;
    }
  }
`;
const FriendsIcon = styled.i`
  display: inline-block;
  mask-image: url('${FriendsImg}');
  mask-repeat: no-repeat;
  mask-size: cover;
  width: 0.75rem;
  height: 0.75rem;
  background-color: ${props => props.theme.TextSub};
`;

const UnReadCountBadge = styled.span`
  letter-spacing: 0.025rem;
  background-color: #dc4547;
  border-radius: 1.25rem;
  position: absolute;
  top: 0.18rem;
  right: 0.18rem;
  padding: 0.0125rem 0.3rem;
  color: #fff;
  font-weight: 500;
  text-align: center;
  font-size: 0.6875rem;
`;
const OfficeIcon = styled.i`
  width: 1rem;
  height: 1rem;
  background-color: ${props => props.theme.IconActive};
  margin-right: 0.375rem;
  opacity: 0.8;

  button:active > &,
  button:focus > & {
    background-color: ${props => props.theme.TextMain};
  }
  mask-repeat: no-repeat;
  mask-size: cover;
  mask-image: url('${OfficeImg}');
  display: inline-block;
`;

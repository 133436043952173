import i18next from 'i18next';
import { initReactI18next } from 'react-i18next';
import LanguageDetector from 'i18next-browser-languagedetector';
// import Backend from 'i18next-http-backend';
import CommonKO from '../src/assets/locales/ko/common.json';
import CommonEN from '../src/assets/locales/en/common.json';
import ServiceKO from '../src/assets/locales/ko/service.json';
import ServiceEN from '../src/assets/locales/en/service.json';

const resources = {
  ko: {
    common: CommonKO,
    service: ServiceKO,
  },
  en: {
    common: CommonEN,
    service: ServiceEN,
  },
};

const i18n = i18next.createInstance();

i18n
  // .use(Backend)
  .use(initReactI18next)
  .use(LanguageDetector)
  .init(
    {
      resources,
      order: ['localStorage', 'cookie', ''],
      caches: ['localStorage', 'cookie'],
      lookupCookie: 'i18next',
      lookupLocalStorage: ' i18nextLng',
      debug: true,
      fallbackLng: code => {
        if (code === 'en') return 'en';
        if (code === 'ko') return 'ko';
        if (code.startsWith('en-')) {
          i18n.changeLanguage('en');
          return 'en';
        }
        i18n.changeLanguage('ko');
        return 'ko';
      },
      ns: ['common', 'service'],
      defaultNS: 'common',
      keySeparator: false,
      interpolation: { escapeValue: false },
      // backend: {
      //   loadPath: '/locales/{{lng}}/{{ns}}.json',
      // },
      react: {
        useSuspense: false,
      },
    },
    (err, t) => {
      if (err) {
        return console.error(err);
      }
    },
  );

export default i18n;

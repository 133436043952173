import { WWMS } from 'teespace-core';
import { getEnv } from '../env';
import { updateDefaultStatus, addWebsocketDefaultHandlers } from '../handlers';

const wwms = {
  connect: (userId, connectionId, deviceType = 'PC', loginUserId = '') => {
    const { websocketURL } = getEnv();

    WWMS.setConfig({
      useDefaultHandler: false,
      url: `${websocketURL}?USER_ID=${userId}&USER_LOGIN_ID=${loginUserId}&action=&CONNECTION_ID=${connectionId}`,
      isDebug: true,

      useInterval: false,
      intervalTime: 1000,

      useReconnect: true,
      reconnectInterval: 2000,

      intervalFunction: () => {
        console.log('send ping.');
      },
      onerror: null,
      onmessage: null,
      onclose: null,
      onopen: () => {
        updateDefaultStatus(WWMS, deviceType);
        addWebsocketDefaultHandlers(WWMS);
      },
    });

    WWMS.connect();
  },
  disconnect: () => {
    WWMS.disconnect();
  },
  isConnected: WWMS.isConnected,
};

export default wwms;

import React, { useState } from 'react';
import styled from 'styled-components';

const Header = styled.div`
  height: 6.313rem;
`;

const FirstLoginHeader = () => {
  return <Header />;
};

export default FirstLoginHeader;

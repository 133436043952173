import React from 'react';
import styled from 'styled-components';
import AuthFirstLoginContent from '../components/firstlogin/AuthFirstLoginContent';
import AuthFirstLoginHeader from '../components/firstlogin/AuthFirstLoginHeader';
import TermsFooter from '../components/TermsFooter';

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  min-height: 100%;
`;
const Content = styled.div`
  display: flex;
  align-items: center;
  flex-direction: column;
  justify-content: center;
  flex-grow: 1;
`;

function AuthFirstLoginPage() {
  return (
    <Wrapper>
      <AuthFirstLoginHeader />
      <Content>
        <AuthFirstLoginContent />
      </Content>
      <TermsFooter />
    </Wrapper>
  );
}

export default AuthFirstLoginPage;

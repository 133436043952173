import React, { useEffect } from 'react';
import styled, { css } from 'styled-components';
import { useTranslation } from 'react-i18next';
import { Tabs } from 'antd';
import coworkMainImage from '../../assets/landing/tab/cowork/img/main.png';
import coworkMainBG from '../../assets/landing/tab/cowork/img/01_graphic.png';
import coworkMainText from '../../assets/landing/tab/cowork/txt/main.png';
import coworkSubImage1 from '../../assets/landing/tab/cowork/txt/group.svg';
import coworkSubImage2 from '../../assets/landing/tab/cowork/txt/private_room.svg';
import coworkSubImage3 from '../../assets/landing/tab/cowork/txt/invite.svg';
import messengerMainImage from '../../assets/landing/tab/messenger/img/main.png';
import messengerMainBG from '../../assets/landing/tab/messenger/img/02_graphic.png';
import messengerMainText from '../../assets/landing/tab/messenger/txt/main.png';
import messengerSubImage1 from '../../assets/landing/tab/messenger/txt/mention.svg';
import messengerSubImage2 from '../../assets/landing/tab/messenger/txt/minichat.svg';
import messengerSubImage3 from '../../assets/landing/tab/messenger/txt/emoticon.svg';
import driveMainImage from '../../assets/landing/tab/drive/img/main.png';
import driveMainBG from '../../assets/landing/tab/drive/img/01_graphic.png';
import driveMainText from '../../assets/landing/tab/drive/txt/main.png';
import driveSubImage1 from '../../assets/landing/tab/drive/txt/folder.svg';
import driveSubImage2 from '../../assets/landing/tab/drive/txt/topoint.svg';
import driveSubImage3 from '../../assets/landing/tab/drive/txt/share.svg';
import meetingMainImage from '../../assets/landing/tab/meeting/img/main.png';
import meetingMainBG from '../../assets/landing/tab/meeting/img/01_graphic.png';
import meetingMainText from '../../assets/landing/tab/meeting/txt/main.png';
import meetingSubImage1 from '../../assets/landing/tab/meeting/txt/meeting.svg';
import meetingSubImage2 from '../../assets/landing/tab/meeting/txt/screen_share.svg';
import meetingSubImage3 from '../../assets/landing/tab/meeting/txt/view_mode.svg';
import calendarMainImage from '../../assets/landing/tab/calendar/img/main.png';
import calendarMainBG from '../../assets/landing/tab/calendar/img/02_graphic.png';
import calendarMainText from '../../assets/landing/tab/calendar/txt/main.png';
import calendarSubImage1 from '../../assets/landing/tab/calendar/txt/color.svg';
import calendarSubImage2 from '../../assets/landing/tab/calendar/txt/share_calendar.svg';
import calendarSubImage3 from '../../assets/landing/tab/calendar/txt/schedule.svg';
import noteMainImage from '../../assets/landing/tab/note/img/main.png';
import noteMainBG from '../../assets/landing/tab/note/img/03_graphic.png';
import noteMainText from '../../assets/landing/tab/note/txt/main.png';
import noteSubImage1 from '../../assets/landing/tab/note/txt/note.svg';
import noteSubImage2 from '../../assets/landing/tab/note/txt/note_2.svg';
import noteSubImage3 from '../../assets/landing/tab/note/txt/tag.svg';
import mailMainImage from '../../assets/landing/tab/mail/img/main.png';
import mailMainBG from '../../assets/landing/tab/mail/img/01_graphic.png';
import mailMainText from '../../assets/landing/tab/mail/txt/main.png';
import mailSubImage1 from '../../assets/landing/tab/mail/txt/mail.svg';
import mailSubImage2 from '../../assets/landing/tab/mail/txt/mail_2.svg';
import mailSubImage3 from '../../assets/landing/tab/mail/txt/add_account.svg';
import adminMainImage from '../../assets/landing/tab/admin/img/main.png';
import adminMainBG from '../../assets/landing/tab/admin/img/02_graphic.png';
import adminMainText from '../../assets/landing/tab/admin/txt/main.png';
import adminSubImage1 from '../../assets/landing/tab/admin/txt/admin.svg';
import adminSubImage2 from '../../assets/landing/tab/admin/txt/office.svg';
import adminSubImage3 from '../../assets/landing/tab/admin/txt/eye.svg';
import worksMainImage from '../../assets/landing/tab/works/img/main.png';
import worksMainImage2 from '../../assets/landing/tab/works/img/main_2.png';
import worksMainBG from '../../assets/landing/tab/works/img/01_graphic.png';
import worksMainText from '../../assets/landing/tab/works/txt/main.png';
import worksSubImage1 from '../../assets/landing/tab/works/txt/works_sub_1.png';
import worksSubImage2 from '../../assets/landing/tab/works/txt/works_sub_2.png';
import worksSubImage3 from '../../assets/landing/tab/works/txt/works_sub_3.png';
import coworkMainText_En from '../../assets/landingEn/tab/cowork/main.png';
import messengerMainText_En from '../../assets/landingEn/tab/messenger/main.png';
import driveMainText_En from '../../assets/landingEn/tab/drive/main.png';
import meetingMainText_En from '../../assets/landingEn/tab/meeting/main.png';
import calendarMainText_En from '../../assets/landingEn/tab/calendar/main.png';
import noteMainText_En from '../../assets/landingEn/tab/note/main.png';
import mailMainText_En from '../../assets/landingEn/tab/mail/main.png';
import adminMainText_En from '../../assets/landingEn/tab/admin/main.png';
import worksMainText_En from '../../assets/landingEn/tab/works/main.png';

const { TabPane } = Tabs;

const ServiceTabContent = props => {
  const { refs, startService } = props;
  const { t, i18n } = useTranslation('service');

  useEffect(() => {
    if (startService) {
      window.scrollTo({
        top:
          refs.introRef.current.offsetTop - refs.headerRef.current.clientHeight,
        left: 0,
        behavior: 'smooth',
      });
    }
  }, [startService, refs.introRef, refs.headerRef]);

  const tabList = [
    t('SP_SP_INTRO_TAP_06'),
    t('SP_SP_INTRO_TAP_07'),
    t('SP_SP_INTRO_TAP_08'),
    t('SP_SP_INTRO_TAP_09'),
    t('SP_SP_INTRO_TAP_10'),
    t('SP_SP_INTRO_TAP_11'),
    t('SP_SP_INTRO_TAP_12'),
    t('SP_SP_INTRO_TAP_13'),
    t('SP_SP_INTRO_TAP_14'),
    /*
    `협업공간`,
    `메신저`,
    `드라이브`,
    `화상회의`,
    `캘린더`,
    `노트`,
    `메일`,
    `어드민`,
    `웍스` */
  ];

  const MainComponent = ({ params }) => {
    switch (params) {
      case t('SP_SP_INTRO_TAP_06'): // `협업공간`:
        return (
          <MainWrapper>
            <CustomDiv>
              <CoworkMainImg src={coworkMainImage} />
              <CoworkMainBg
                src={coworkMainBG}
                data-aos-offset="40"
                data-aos="fade-up"
                data-aos-duration="750"
                data-aos-easing="ease"
              />
              <CoworkMainTxt
                src={
                  i18n.language === 'en' ? coworkMainText_En : coworkMainText
                }
                data-aos-offset="40"
                data-aos-delay="400"
                data-aos="fade-up"
                data-aos-duration="750"
                data-aos-easing="ease"
              />
            </CustomDiv>
          </MainWrapper>
        );
      case t('SP_SP_INTRO_TAP_07'): // `메신저`:
        return (
          <MainWrapper>
            <CustomDiv>
              <MessengerMainBg
                src={messengerMainBG}
                data-aos-offset="40"
                data-aos="fade-up"
                data-aos-duration="750"
                data-aos-easing="ease"
              />
              <MessengerMainTxt
                src={
                  i18n.language === 'en'
                    ? messengerMainText_En
                    : messengerMainText
                }
                data-aos-offset="40"
                data-aos="fade-up"
                data-aos-duration="750"
                data-aos-delay="400"
                data-aos-easing="ease"
              />
              <MessengerMainImg src={messengerMainImage} />
            </CustomDiv>
          </MainWrapper>
        );
      case t('SP_SP_INTRO_TAP_08'): // `드라이브`:
        return (
          <MainWrapper>
            <CustomDiv>
              <DriveMainImg src={driveMainImage} />
              <DriveMainBg
                src={driveMainBG}
                data-aos-offset="40"
                data-aos="fade-up"
                data-aos-duration="750"
                data-aos-easing="ease"
              />
              <DriveMainTxt
                src={i18n.language === 'en' ? driveMainText_En : driveMainText}
                data-aos-offset="40"
                data-aos="fade-up"
                data-aos-duration="750"
                data-aos-delay="400"
                data-aos-easing="ease"
              />
            </CustomDiv>
          </MainWrapper>
        );
      case t('SP_SP_INTRO_TAP_09'): // `화상회의`:
        return (
          <MainWrapper>
            <CustomDiv>
              <MeetingMainImg src={meetingMainImage} />
              <MeetingMainBg
                src={meetingMainBG}
                data-aos-offset="40"
                data-aos="fade-up"
                data-aos-duration="750"
                data-aos-easing="ease"
              />
              <MeetingMainTxt
                src={
                  i18n.language === 'en' ? meetingMainText_En : meetingMainText
                }
                data-aos-offset="40"
                data-aos="fade-up"
                data-aos-duration="750"
                data-aos-delay="400"
                data-aos-easing="ease"
              />
            </CustomDiv>
          </MainWrapper>
        );
      case t('SP_SP_INTRO_TAP_10'): // `캘린더`:
        return (
          <MainWrapper>
            <CustomDiv>
              <CalendarMainBg
                src={calendarMainBG}
                data-aos-offset="40"
                data-aos="fade-up"
                data-aos-duration="750"
                data-aos-easing="ease"
              />
              <CalendarMainTxt
                src={
                  i18n.language === 'en'
                    ? calendarMainText_En
                    : calendarMainText
                }
                data-aos-offset="40"
                data-aos="fade-up"
                data-aos-duration="750"
                data-aos-delay="400"
                data-aos-easing="ease"
              />
              <CalendarMainImg src={calendarMainImage} />
            </CustomDiv>
          </MainWrapper>
        );
      case t('SP_SP_INTRO_TAP_11'): // `노트`:
        return (
          <MainWrapper>
            <CustomDiv>
              <NoteMainImg src={noteMainImage} />
              <NoteMainBg
                src={noteMainBG}
                data-aos-offset="40"
                data-aos="fade-up"
                data-aos-duration="750"
                data-aos-easing="ease"
              />
              <NoteMainTxt
                src={i18n.language === 'en' ? noteMainText_En : noteMainText}
                data-aos-offset="40"
                data-aos="fade-up"
                data-aos-duration="750"
                data-aos-delay="400"
                data-aos-easing="ease"
              />
            </CustomDiv>
          </MainWrapper>
        );
      case t('SP_SP_INTRO_TAP_12'): // `메일`:
        return (
          <MainWrapper>
            <CustomDiv>
              <MailMainImg src={mailMainImage} />
              <MailMainBg
                src={mailMainBG}
                data-aos-offset="40"
                data-aos="fade-up"
                data-aos-duration="750"
                data-aos-easing="ease"
              />
              <MailMainTxt
                src={i18n.language === 'en' ? mailMainText_En : mailMainText}
                data-aos-offset="40"
                data-aos="fade-up"
                data-aos-duration="750"
                data-aos-delay="400"
                data-aos-easing="ease"
              />
            </CustomDiv>
          </MainWrapper>
        );
      case t('SP_SP_INTRO_TAP_13'): // `어드민`:
        return (
          <MainWrapper>
            <CustomDiv>
              <AdminMainBg
                src={adminMainBG}
                data-aos-offset="40"
                data-aos="fade-up"
                data-aos-duration="750"
                data-aos-easing="ease"
              />
              <AdminMainTxt
                src={i18n.language === 'en' ? adminMainText_En : adminMainText}
                data-aos-offset="40"
                data-aos="fade-up"
                data-aos-duration="750"
                data-aos-delay="400"
                data-aos-easing="ease"
              />
              <AdminMainImg src={adminMainImage} />
            </CustomDiv>
          </MainWrapper>
        );
      case t('SP_SP_INTRO_TAP_14'): // `웍스`:
        return (
          <MainWrapper>
            <CustomDiv>
              <WorksMainImg src={worksMainImage} />
              <WorksMainImg2 src={worksMainImage2} />
              <WorksMainBg
                src={worksMainBG}
                data-aos-offset="40"
                data-aos="fade-up"
                data-aos-duration="750"
                data-aos-easing="ease"
              />
              <WorksMainTxt
                src={i18n.language === 'en' ? worksMainText_En : worksMainText}
                data-aos-offset="40"
                data-aos="fade-up"
                data-aos-duration="750"
                data-aos-delay="400"
                data-aos-easing="ease"
                isen={i18n.language === 'en'}
              />
            </CustomDiv>
          </MainWrapper>
        );
      default:
        return '';
    }
  };

  const SubComponent = ({ params }) => {
    switch (params) {
      case t('SP_SP_INTRO_TAP_06'): // `협업공간`:
        const coWorkItems = [
          {
            image: coworkSubImage1,
            title: t(`SP_COLLABO_CONTENTS_01`),
            content: t(`SP_COLLABO_CONTENTS_02`),
          },
          {
            image: coworkSubImage2,
            title: t(`SP_COLLABO_CONTENTS_03`),
            content: t(`SP_COLLABO_CONTENTS_04`),
          },
          {
            image: coworkSubImage3,
            title: t(`SP_COLLABO_CONTENTS_05`),
            content: t(`SP_COLLABO_CONTENTS_06`),
          },
        ];

        return (
          <>
            {coWorkItems.map(item => (
              <SubContentWrapperComponent {...item} key={item.title} />
            ))}
          </>
        );
      case t('SP_SP_INTRO_TAP_07'): // `메신저`:
        const messengerItems = [
          {
            image: messengerSubImage1,
            title: t(`SP_MSG_CONTENTS_01`),
            content: t(`SP_MSG_CONTENTS_02`),
          },
          {
            image: messengerSubImage2,
            title: t(`SP_MSG_CONTENTS_03`),
            content: t(`SP_MSG_CONTENTS_04`),
          },
          {
            image: messengerSubImage3,
            title: t(`SP_MSG_CONTENTS_05`),
            content: t(`SP_MSG_CONTENTS_06`),
          },
        ];

        return (
          <>
            {messengerItems.map(item => (
              <SubContentWrapperComponent {...item} key={item.title} />
            ))}
          </>
        );
      case t('SP_SP_INTRO_TAP_08'): // `드라이브`:
        const driveItems = [
          {
            image: driveSubImage1,
            title: t(`SP_DRIVE_CONTENTS_01`),
            content: t(`SP_DRIVE_CONTENTS_02`),
          },
          {
            image: driveSubImage2,
            title: t(`SP_DRIVE_CONTENTS_03`),
            content: t(`SP_DRIVE_CONTENTS_04`),
          },
          {
            image: driveSubImage3,
            title: t(`SP_DRIVE_CONTENTS_05`),
            content: t(`SP_DRIVE_CONTENTS_06`),
          },
        ];

        return (
          <>
            {driveItems.map(item => (
              <SubContentWrapperComponent {...item} key={item.title} />
            ))}
          </>
        );
      case t('SP_SP_INTRO_TAP_09'): // `화상회의`:
        const meetingItems = [
          {
            image: meetingSubImage1,
            title: t(`SP_MEETING_CONTENTS_01`),
            content: t(`SP_MEETING_CONTENTS_02`),
          },
          {
            image: meetingSubImage2,
            title: t(`SP_MEETING_CONTENTS_03`),
            content: t(`SP_MEETING_CONTENTS_04`),
          },
          {
            image: meetingSubImage3,
            title: t(`SP_MEETING_CONTENTS_05`),
            content: t(`SP_MEETING_CONTENTS_06`),
          },
        ];

        return (
          <>
            {meetingItems.map(item => (
              <SubContentWrapperComponent {...item} key={item.title} />
            ))}
          </>
        );
      case t('SP_SP_INTRO_TAP_10'): // `캘린더`:
        const calendarItems = [
          {
            image: calendarSubImage1,
            title: t(`SP_CAL_CONTENTS_01`),
            content: t(`SP_CAL_CONTENTS_02`),
          },
          {
            image: calendarSubImage2,
            title: t(`SP_CAL_CONTENTS_03`),
            content: t(`SP_CAL_CONTENTS_04`),
          },
          {
            image: calendarSubImage3,
            title: t(`SP_CAL_CONTENTS_05`),
            content: t(`SP_CAL_CONTENTS_06`),
          },
        ];

        return (
          <>
            {calendarItems.map(item => (
              <SubContentWrapperComponent {...item} key={item.title} />
            ))}
          </>
        );
      case t('SP_SP_INTRO_TAP_11'): // `노트`:
        const noteItems = [
          {
            image: noteSubImage1,
            title: t(`SP_NOTE_CONTENTS_01`),
            content: t(`SP_NOTE_CONTENTS_02`),
          },
          {
            image: noteSubImage2,
            title: t(`SP_NOTE_CONTENTS_03`),
            content: t(`SP_NOTE_CONTENTS_04`),
          },
          {
            image: noteSubImage3,
            title: t(`SP_NOTE_CONTENTS_05`),
            content: t(`SP_NOTE_CONTENTS_06`),
          },
        ];

        return (
          <>
            {noteItems.map(item => (
              <SubContentWrapperComponent {...item} key={item.title} />
            ))}
          </>
        );
      case t('SP_SP_INTRO_TAP_12'): // `메일`:
        const mailItems = [
          {
            image: mailSubImage1,
            title: t(`SP_MAIL_CONTENTS_01`),
            content: t(`SP_MAIL_CONTENTS_02`),
          },
          {
            image: mailSubImage2,
            title: t(`SP_MAIL_CONTENTS_03`),
            content: t(`SP_MAIL_CONTENTS_04`),
          },
          {
            image: mailSubImage3,
            title: t(`SP_MAIL_CONTENTS_05`),
            content: t(`SP_MAIL_CONTENTS_06`),
          },
        ];

        return (
          <>
            {mailItems.map(item => (
              <SubContentWrapperComponent {...item} key={item.title} />
            ))}
          </>
        );
      case t('SP_SP_INTRO_TAP_13'): // `어드민`:
        const adminItems = [
          {
            image: adminSubImage1,
            title: t(`SP_ADMIN_CONTENTS_01`),
            content: t(`SP_ADMIN_CONTENTS_02`),
          },
          {
            image: adminSubImage2,
            title: t(`SP_ADMIN_CONTENTS_03`),
            content: t(`SP_ADMIN_CONTENTS_04`),
          },
          {
            image: adminSubImage3,
            title: t(`SP_ADMIN_CONTENTS_05`),
            content: t(`SP_ADMIN_CONTENTS_06`),
          },
        ];

        return (
          <>
            {adminItems.map(item => (
              <SubContentWrapperComponent {...item} key={item.title} />
            ))}
          </>
        );

      case t('SP_SP_INTRO_TAP_14'): // `웍스`:
        const worksItems = [
          {
            image: worksSubImage1,
            title: t(`SP_WORKS_06`),
            content: t(`SP_WORKS_07`),
          },
          {
            image: worksSubImage2,
            title: t(`SP_WORKS_08`),
            content: t(`SP_WORKS_09`),
          },
          {
            image: worksSubImage3,
            title: t(`SP_WORKS_10`),
            content: t(`SP_WORKS_11`),
          },
        ];
        return (
          <>
            {worksItems.map(item => (
              <SubContentWrapperComponent {...item} key={item.title} />
            ))}
          </>
        );
      default:
        return '';
    }
  };
  const handleColorType = apps => {
    switch (apps) {
      case t('SP_SP_INTRO_TAP_06'): // `협업공간`:
        return 'rgba(242,239,236,0.6)';
      case t('SP_SP_INTRO_TAP_07'): // `메신저`:
        return 'rgba(105,140,135,0.1)';
      case t('SP_SP_INTRO_TAP_08'): // `드라이브`:
        return 'rgba(238,146,102,0.1)';
      case t('SP_SP_INTRO_TAP_09'): // `화상회의`:
        return 'rgba(242,239,236,0.6)';
      case t('SP_SP_INTRO_TAP_10'): // `캘린더`:
        return 'rgba(105,140,135,0.1)';
      case t('SP_SP_INTRO_TAP_11'): // `노트`:
        return 'rgba(238,146,102,0.1)';
      case t('SP_SP_INTRO_TAP_12'): // `메일`:
        return 'rgba(242,239,236,0.65)';
      case t('SP_SP_INTRO_TAP_13'): // `어드민`:
        return 'rgba(105,140,135,0.1)';
      case t('SP_SP_INTRO_TAP_14'): // `웍스`:
        return 'rgba(238,146,102,0.1)';
      default:
        return '#fff';
    }
  };
  return (
    <TabWrapper ref={refs.introRef}>
      <HeaderTab defaultActiveKey="0">
        {tabList.map(name => (
          <TabPane tab={name} key={name}>
            <MainComponent params={name} />
            <SubWrapper>
              <SubInner apps={name} handleColorType={handleColorType}>
                <SubComponent params={name} />
              </SubInner>
            </SubWrapper>
          </TabPane>
        ))}
      </HeaderTab>
    </TabWrapper>
  );
};

export default ServiceTabContent;

const SubContentWrapperComponent = ({ image, title, content }) => {
  return (
    <SubContentWrapper
      data-aos-offset="40"
      data-aos="fade-up"
      data-aos-duration="750"
      data-aos-easing="ease"
    >
      <SubImg src={image} />
      <SubTxtWrapper>
        <TitleDiv>{title}</TitleDiv>
        <ContentDiv>{content}</ContentDiv>
      </SubTxtWrapper>
    </SubContentWrapper>
  );
};

const TabWrapper = styled.div`
  width: 100%;
  height: 75.813rem;
  background: #fff;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
`;

const HeaderTab = styled(Tabs)`
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  .ant-tabs-nav {
    height: 3.13rem;
    margin-bottom: 0;
    width: 80rem;
    .ant-tabs-ink-bar {
      border-radius: 0.09rem;
      height: 3px;
      background: #232d3b;
    }
    &:before {
      border-bottom-color: #e3e7eb;
    }
  }

  // hide 3-dots button
  .ant-tabs-nav-operations {
    display: none !important;
  }

  .ant-tabs-nav-list {
    flex: 1;
  }

  .ant-tabs-tab {
    flex: 1;
    justify-content: center;
    // padding:  auto;
    margin: 0;
    background: transparent;
    font-size: 1.063rem;
    line-height: 1.13rem;

    .ant-tabs-tab-btn {
      color: #777777;
      font-size: 1.063rem;
    }

    &.ant-tabs-tab-active .ant-tabs-tab-btn {
      color: #232d3b;
    }
    &.ant-tabs-content-holder .ant-tabs-tabpane .ant-tabs-tabpane-active {
      display: felx;
      justify-content: center;
      align-items: cent;
    }
  }
`;

const MainWrapper = styled.div`
  height: 31.875rem;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
`;

const SubWrapper = styled.div`
  background: #fff;
  height: 39.5rem;
  display: flex;
  justify-content: center;
`;

const handleOpacityType = apps => {
  switch (apps) {
    case `협업공간`:
      return '0.65';
    case `메신저`:
      return '0.1';
    case `드라이브`:
      return '0.07';
    case `화상회의`:
      return '0.65';
    case `캘린더`:
      return '0.1';
    case `노트`:
      return '0.07';
    case `메일`:
      return '0.65';
    case `어드민`:
      return '0.1';
    case `웍스`:
      return '0.07';
    default:
      return '0.01';
  }
};

const SubInner = styled.div`
  background: ${({ apps, handleColorType }) => handleColorType(apps)};
  width: 80rem;
  height: 34.5rem;
  //   opacity: ${({ apps }) => handleOpacityType(apps)};
  border-radius: 1rem;
  display: flex;
  justify-content: space-evenly;
  flex-direction: column;
  padding-left: 24.063rem;
`;

const CoworkMainImg = styled.img`
  width: 31.438rem;
  height: 21.875rem;
`;

const CoworkMainBg = styled.img`
  position: relative;
  left: 11rem;
  width: 15.938rem;
  height: 15.313rem;
`;

const CoworkMainTxt = styled.img`
  position: relative;
  left: -15rem;
  width: 25.813rem;
  height: 6.875rem;
`;

const MessengerMainImg = styled.img`
  position: relative;
  left: -22rem;
  width: 35.438rem;
  height: 21.875rem;
`;

const MessengerMainBg = styled.img`
  width: 19.6rem;
  height: 15rem;
`;

const MessengerMainTxt = styled.img`
  position: relative;
  left: -19rem;
  width: 26.25rem;
  height: 6.875rem;
`;

const DriveMainImg = styled.img`
  width: 27.75rem;
  height: 21.875rem;
`;

const DriveMainBg = styled.img`
  position: relative;
  left: 17rem;
  width: 13.562rem;
  height: 14.115rem;
`;

const DriveMainTxt = styled.img`
  position: relative;
  left: -10rem;
  width: 27.25rem;
  height: 6.875rem;
`;

const MeetingMainImg = styled.img`
  width: 39.125rem;
  height: 21.875rem;
`;

const MeetingMainBg = styled.img`
  position: relative;
  left: 3.5rem;
  width: 15.938rem;
  height: 15.301rem;
`;

const MeetingMainTxt = styled.img`
  position: relative;
  left: -15.5rem;
  width: 18.813rem;
  height: 6.875rem;
`;

const CalendarMainImg = styled.img`
  position: relative;
  left: -17rem;
  width: 33.438rem;
  height: 21.875rem;
`;

const CalendarMainBg = styled.img`
  width: 19.6rem;
  height: 14.989rem;
`;

const CalendarMainTxt = styled.img`
  position: relative;
  left: -20rem;
  width: 24.5rem;
  height: 6.875rem;
`;

const NoteMainImg = styled.img`
  width: 34.438rem;
  height: 21.875rem;
`;

const NoteMainBg = styled.img`
  position: relative;
  left: 10.5rem;
  width: 13.562rem;
  height: 14.115rem;
`;

const NoteMainTxt = styled.img`
  position: relative;
  left: -6.5rem;
  width: 16.688rem;
  height: 6.875rem;
`;

const MailMainImg = styled.img`
  width: 32.438rem;
  height: 21.875rem;
`;

const MailMainBg = styled.img`
  position: relative;
  left: 10.5rem;
  width: 15.938rem;
  height: 15.301rem;
`;

const MailMainTxt = styled.img`
  position: relative;
  left: -15rem;
  width: 29.5em;
  height: 6.875rem;
`;

const AdminMainImg = styled.img`
  position: relative;
  left: -16rem;
  width: 33.625rem;
  height: 21.875rem;
`;

const AdminMainBg = styled.img`
  width: 19.6rem;
  height: 14.989rem;
`;

const AdminMainTxt = styled.img`
  position: relative;
  left: -20rem;
  width: 21.938rem;
  height: 6.875rem;
`;

const WorksMainImg = styled.img`
  width: 31.624rem;
  height: 21.875rem;
`;

const WorksMainImg2 = styled.img`
  position: relative;
  width: 3.236rem;
  height: 6.172rem;
  top: 7.8rem;
`;

const WorksMainBg = styled.img`
  position: relative;
  left: 17rem;
  width: 13.562rem;
  height: 14.115rem;
`;

const WorksMainTxt = styled.img`
  position: relative;
  left: -10rem;
  width: 27.25rem;
  height: 6.875rem;
  ${props =>
    props.isen &&
    css`
      width: 26.438rem;
      height: 10.25rem;
    `}
`;

const SubImg = styled.img`
  width: 3rem;
  height: 3rem;
`;

const CustomDiv = styled.div`
  display: flex;
  align-items: center;
  height: auto;
  width: 58.75rem;
`;

const SubContentWrapper = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
`;

const SubTxtWrapper = styled.div`
  display: flex;
  flex-direction: column;
  height: 4.633rem;
  justify-content: space-evenly;
  padding-left: 3.13rem;
`;

const TitleDiv = styled.div`
  font-size: 1.625rem;
  color: #232d3b;
  letter-spacing: -0.87px;
  font-weight: 500;
`;

const ContentDiv = styled.div`
  white-space: pre;
  font-size: 1.125rem;
  color: #757575;
  letter-spacing: 0;
  font-weight: 300;
  height: 3.375rem;
`;
